import React from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { BASE_URL, formatNumber } from "../../shared/constants";
import {
  Row,
  Col,
  Skeleton,
  Radio,
  Table
} from 'antd';
import sortBy from "lodash/fp/sortBy";
import get from "lodash/fp/get";
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ContentWrapper } from '../../shared/styles'
const { ColumnGroup, Column } = Table
var prevFinYear


class InsightsDashboard extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    
    this.state = {
      activeIndex: 0, loading: false, insightsData: [], view: 'table'
    }

    var temp_year = this.props.finYear.split('-');

    temp_year[0] = parseInt(temp_year[0]) - 1

    temp_year[1] =  parseInt(temp_year[1]) - 1 

    prevFinYear = temp_year.join('-')
   
  }

componentDidMount () {
  this.setState({ loading: true });
    setTimeout(() => {
      this.fetchStats()
      
    }, 3000);

    

}

onPieEnter(data, index) {
this.setState({
  activeIndex: index,
});
}

fetchStats = () => {

  var downloaddate = this.props.finMonth + "/" + this.props.finYear;

  fetch(
        `${BASE_URL}/export/fetchInsights?companyId=${this.props.companyId}&downloaddate=${downloaddate}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(async result => {
          console.log(result);
          this.setState({ loading: false, insightsData: sortBy(['key'], result) });
        });
}

handleViewChange = () => {
  if(this.state.view === 'table'){
    this.setState({ view: 'graph' });  
  }
  if(this.state.view === 'graph'){
    this.setState({ view: 'table' });  
  }
  
}


render () {

  console.log(this.props);
  
  
return (
  <ContentWrapper>
  
  <Row span={24}>
  <Col span={24}>
  <p style={{ fontSize: "20px"}}>FY {prevFinYear} vs FY {this.props.finYear}</p>
  <br />
  
  <Radio.Group onChange={this.handleViewChange} value={this.state.view} style={{ marginBottom: 8 }}>
          <Radio.Button value="table">Tabular View</Radio.Button>
          <Radio.Button value="graph">Graphical View</Radio.Button>
        </Radio.Group>
  <Skeleton loading={this.state.loading}>
  {this.state.view === 'graph' &&
  <BarChart
        width={1300}
        height={500}
        data={this.state.insightsData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={(value, name, props) =>( formatNumber(value) )} itemSorter={() => 0} />
        <Legend />
        
        <Bar dataKey="b2b 2017-18" stackId="a" fill="#8884d8"  />
        <Bar dataKey="b2b 2018-19" stackId="b" fill="#8884d8" />

        <Bar dataKey="b2cl 2017-18" stackId="a" fill="#82ca9d" />
        <Bar dataKey="b2cl 2018-19" stackId="b" fill="#82ca9d" />

        <Bar dataKey="b2cs 2017-18" stackId="a" fill="#0d8484" />
        <Bar dataKey="b2cs 2018-19" stackId="b" fill="#0d8484" />

        <Bar dataKey="exp 2017-18" stackId="a" fill="#0d8484" />
        <Bar dataKey="exp 2018-19" stackId="a" fill="#0d8484" />
        
      </BarChart>
    }
    {this.state.view === 'table' &&

    <Table dataSource={this.state.insightsData} bordered title={() => 'Sales Timeline'} pagination={{ defaultPageSize: 15, hideOnSinglePage: true }}>
    <Column title='Month' dataIndex="name" key="name" />
      <ColumnGroup title="B2B">
        <Column title={prevFinYear} dataIndex="b2b 2017-18" key="b2b 2017-18" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
        <Column title={this.props.finYear} dataIndex="b2b 2018-19" key="b2b 2018-19" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
      </ColumnGroup>
      <ColumnGroup title="B2CL">
        <Column title={prevFinYear} dataIndex="b2cl 2017-18" key="b2cl 2017-18" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
        <Column title={this.props.finYear} dataIndex="b2cl 2018-19" key="b2cl 2018-19" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
      </ColumnGroup>
      <ColumnGroup title="B2CS">
        <Column title={prevFinYear} dataIndex="b2cs 2017-18" key="b2cs 2017-18" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
        <Column title={this.props.finYear} dataIndex="b2cs 2018-19" key="b2cs 2018-19" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
      </ColumnGroup>
      <ColumnGroup title="EXPORT">
        <Column title={prevFinYear} dataIndex="exp 2017-18" key="exp 2017-18" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
        <Column title={this.props.finYear} dataIndex="exp 2018-19" key="exp 2018-19" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>} />
      </ColumnGroup>
    </Table>
  }
      </Skeleton>
  </Col>
  </Row>
   </ContentWrapper>
);
}
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};



export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(InsightsDashboard)