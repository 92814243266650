import React from 'react'
import { Route } from 'react-router-dom'
import GSTR3BDashboard from './GSTR3BDashboard'
import PayTax from './PayTax'
import EnterGSTR3B from './EnterGSTR3B'

import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'


export default () =>
    <div>
      <Route exact path={ROUTES.GST_FILINGS_GSTR3B} component={GSTR3BDashboard} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR3B_INPUT} component={EnterGSTR3B} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR3B_PAYTAX} component={PayTax} />
    </div>
