import React from "react";
import { Row, Col, Card, Steps, Collapse, Button, Icon, Popconfirm, message, notification, Modal } from "antd";
import get from "lodash/fp/get";
import reduce from "lodash/fp/reduce";
import flow from "lodash/fp/flow";
import flatMap from "lodash/fp/flatMap";
import pick from "lodash/fp/pick";
import toString from "lodash/fp/toString";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { BASE_URL, formatNumber } from "../../shared/constants";
import { withRouter } from "react-router-dom";
import { GSTR2_Query, CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import { ContentWrapper } from "../../shared/styles";
import { ROUTES } from '../../shared/constants'
import { Link } from 'react-router-dom'
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import gspAuth from "../../services/GSPService";
const add_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.5', padding: '0 15px', fontSize: '13.5px', borderRadius: '5px', height: '34px', mozUserSelect: 'none', position: 'relative', color: '#000000a6', backgroundColor: 'rgb(35, 130, 199)', borderColor: '#d9d9d9', paddingTop: '5px'};
const Step = Steps.Step;
const Panel = Collapse.Panel;
const errorModal = Modal.error;
const button_style = { width: "200px" };

class GSTR2Dashboard extends React.Component {

  constructor(props) {
    super(props);
    console.log(props);
    console.log(this.props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null
      };
    } else {
      this.gsp = {};
    }
    // this.props.gstr2summary.refetch();
  }

  componentDidUpdate = () => {
    console.log(this.props);
    console.log(this.prevProps);
    console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    this.setState({ requestOTP: false });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.uploadGSTR2Data();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visible: false,
    downloadLoading: false,
    requestOTP: false,
    otp: null,
    period: null,
    submitVisible: true,
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  uploadGSTR2Data = async () => {
    message.destroy();
    message.loading('Uploading to GSTN...',0)

    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }
    var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;



    return fetch(
      `${BASE_URL}/export/gstr2json?from=purchase&companyId=${
        this.props.companyId
      }&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    ).then(async result => {
      const body = await result.json();
      console.log(body);

      const companyDetails = flow(
        pick(["gstin", "id", "gstnUserName"]),
        this.otherGSTNInformation
      )(this.props.company);
      const reqData = {
        companyDetails: companyDetails,
        gspDetails: {
          gspToken: this.gsp.access_token,
          otp: this.gsp.otp
        },
        period: period,
        body: body
      };
      this.gsp.response = await gspAuth.saveGSTR2(reqData);

      console.log(this.gsp.response);

      // if (
      //   !this.gsp.response.data.success &&
      //   this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      // ) {
      //   message.destroy();
      //   this.setState({ requestOTP: true });
      // }else if (
      //   this.gsp.response.data.success
      // ) {
      //   message.destroy();
      //   message.success("GSTR2 saved successfully!")
      // }


      message.destroy();
      if (
        !this.gsp.response.data.success &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        this.setState({ requestOTP: true });
      }else if(!this.gsp.response.data.success){
        errorModal({
          title: 'Save Unsuccessful',
          content: <p>{this.gsp.response.data.errorCode ? this.gsp.response.data.errorCode : "Unable to process your request, please try again later."}</p>,
          iconType: "close-circle",
        });
      }
      if(this.gsp.response.data.success) {
        if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "P")
        {
          this.setState({ submitVisible: false });
          notification.destroy();
          notification.open({
            message: 'Success',
            description: "GSTR2 Saved Successfully!!",
            duration: 0,
            icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
          });
        }else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "REC"){
          notification.destroy();
          notification.open({
            message: 'GSTN',
            description: "GSTR2 Save-Status REC.",
            duration: 0,
            icon: <Icon type="close-circle" style={{ color: '#e6122e' }} />,
          });
        }
        else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "ER") {
          errorModal({
            title: 'GSTR2 Saved Failed!!',
            content: <p>Enter Correct GSTR1 details and try again.</p>,
            iconType: "close-circle",
          });
        }else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "IP") {
          notification.destroy();
          notification.open({
            message: 'In Progress...',
            description: "GSTR2 save in progress...",
            duration: 0,
            icon: <Icon type="exclamation-circle" style={{ color: '#008000' }} />,
          });
        }
    }});

  }

  render() {
    console.log(this.props);
    return (
      <ContentWrapper>
        <Steps current={[1, 2, 3]}>
          <Step
            title={<a style={add_button}>
              <Icon type="file-excel" /> &nbsp; <Link to={ROUTES.PURCHASE_DASHBOARD}>Upload Purchase Invoices</Link>
            </a>}
            description="Purchase Invoices can also be uploaded under Upload Purchase in Purchase Register"
          />
          <Step
          title={
          <Popconfirm placement="bottomRight" title="Clicking this button will SAVE your GSTR2 info in GSTN!" onConfirm={this.uploadGSTR2Data} okText="Yes" cancelText="No">
            <Button
              style={{ backgroundColor: '#04880fe0', fontSize: '13px', color: 'white'}}
              icon="cloud-upload"
            >
              Save GSTR2 Data
            </Button>
          </Popconfirm>
          }
          description="Clicking this button will save your GSTR2 info to GST network."
          />
          <Step
          title={<Button
            style={{ backgroundColor: '#a01208de', fontSize: '13px', color: 'white'}}
            icon="cloud-upload"
            disabled='true'
          >
            Submit GSTR2 Data
          </Button>}
          description="Clicking this button will submit your GSTR2 info to GST network."
          />
        </Steps>
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Panel header={<b>Invoices and Credit/Debit Notes</b>} key="1">
            <Row gutter={16}>
              <Col>
                <Card bordered={true}>
                  <Row>
                    <Col span={6}>
                      <b>Type</b>
                    </Col>
                    <Col span={2} />
                    <Col span={3}>
                      <b>Document Count</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Value</b>
                    </Col>
                    <Col span={3}>
                      <b>Total Taxable Value</b>
                    </Col>
                    <Col span={3}>
                      <b>Total Tax Liability</b>
                    </Col>
                    <Col span={3}>
                      <b>ITC Available</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={6}>Inward Supplies received from Registered person including reverse charge supplies(3, 4A)</Col>
                    <Col span={2} />
                    <Col span={3}>
                    <p>{this.props.gstr2_b2b ? this.props.gstr2_b2b.pageInfo.total: "-"}</p>
                    </Col>
                    <Col span={4}>
                    {formatNumber(reduce((curr, next) => curr + parseFloat(next.invoice_value, 10),0)(this.props.gstr2_b2b ? this.props.gstr2_b2b.edges : []))}
                    </Col>
                    <Col span={3}>
                    {formatNumber(flow(flatMap(get("pr_b2b_items.edges")),reduce((curr, next) => curr + parseFloat(next.taxable_value, 10),0))(this.props.gstr2_b2b ? this.props.gstr2_b2b.edges : []))}
                    </Col>
                    <Col span={3}>
                    {formatNumber(
                      flow(
                        flatMap(get("pr_b2b_items.edges")),
                        reduce(
                          (curr, next) =>
                            curr +
                            parseFloat(next.igst ? next.igst : "0", 10),
                          0
                        )
                      )(
                        this.props.gstr2_b2b ? this.props.gstr2_b2b.edges : []
                      ) +
                        flow(
                          flatMap(get("pr_b2b_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.cgst ? next.cgst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_b2b
                            ? this.props.gstr2_b2b.edges
                            : []
                        ) +
                        flow(
                          flatMap(get("pr_b2b_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.sgst ? next.sgst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_b2b
                            ? this.props.gstr2_b2b.edges
                            : []
                        ) +
                        flow(
                          flatMap(get("pr_b2b_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.cess ? next.cess : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_b2b
                            ? this.props.gstr2_b2b.edges
                            : []
                        )
                    )}
                    </Col>
                    <Col span={3}>
                      <p>-</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={6}>Import of Inputs/Capital Goods and Supplies received from SEZ(5)</Col>
                    <Col span={2} />
                    <Col span={3}>
                      <p>{this.props.gstr2_impg ? this.props.gstr2_impg.pageInfo.total: "-"}</p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(reduce((curr, next) => curr + parseFloat(next.boe_value, 10),0)(this.props.gstr2_impg ? this.props.gstr2_impg.edges : []))}
                    </Col>
                    <Col span={3}>
                      {formatNumber(flow(flatMap(get("pr_impg_items.edges")),reduce((curr, next) => curr + parseFloat(next.taxable_value, 10),0))(this.props.gstr2_impg ? this.props.gstr2_impg.edges : []))}
                    </Col>
                    <Col span={3}>
                    {formatNumber(
                      flow(
                        flatMap(get("pr_impg_items.edges")),
                        reduce(
                          (curr, next) =>
                            curr +
                            parseFloat(next.igst ? next.igst : "0", 10),
                          0
                        )
                      )(
                        this.props.gstr2_impg ? this.props.gstr2_impg.edges : []
                      ) +
                        flow(
                          flatMap(get("pr_impg_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.cess ? next.cess : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_impg
                            ? this.props.gstr2_impg.edges
                            : []
                        )
                    )}
                    </Col>
                    <Col span={3}>
                      <p>-</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={6}>Import of Service (Reverse charge)(4C)</Col>
                    <Col span={2} />
                    <Col span={3}>
                      <p>{this.props.gstr2_imps ? this.props.gstr2_imps.pageInfo.total: "-"}</p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(reduce((curr, next) => curr + parseFloat(next.invoice_value, 10),0)(this.props.gstr2_imps ? this.props.gstr2_imps.edges : []))}
                    </Col>
                    <Col span={3}>
                      {formatNumber(flow(flatMap(get("pr_imps_items.edges")),reduce((curr, next) => curr + parseFloat(next.taxable_value, 10),0))(this.props.gstr2_imps ? this.props.gstr2_imps.edges : []))}
                    </Col>
                    <Col span={3}>
                    {formatNumber(
                      flow(
                        flatMap(get("pr_imps_items.edges")),
                        reduce(
                          (curr, next) =>
                            curr +
                            parseFloat(next.igst ? next.igst : "0", 10),
                          0
                        )
                      )(
                        this.props.gstr2_imps ? this.props.gstr2_imps.edges : []
                      ) +
                        flow(
                          flatMap(get("pr_imps_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.cess ? next.cess : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_imps
                            ? this.props.gstr2_imps.edges
                            : []
                        )
                    )}
                    </Col>
                    <Col span={3}>
                      <p>-</p>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col span={6}>Debit/Credit Notes for supplies from Registered person(6C)</Col>
                    <Col span={2} />
                    <Col span={3}>
                      <p>
                        {this.props.gstr2_cdnr
                          ? this.props.gstr2_cdnr.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.cdnr_value, 10),
                          0
                        )(
                          this.props.gstr2_cdnr_d
                            ? this.props.gstr2_cdnr_d.edges
                            : []
                        ) -
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.cdnr_value, 10),
                            0
                          )(
                            this.props.gstr2_cdnr_c
                              ? this.props.gstr2_cdnr_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={3}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_cdnr_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr2_cdnr_d
                            ? this.props.gstr2_cdnr_d.edges
                            : []
                        ) -
                          flow(
                            flatMap(get("pr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr + parseFloat(next.taxable_value, 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnr_c
                              ? this.props.gstr2_cdnr_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={3}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_cdnr_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_cdnr_d
                            ? this.props.gstr2_cdnr_d.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("pr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnr_d
                              ? this.props.gstr2_cdnr_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnr_d
                              ? this.props.gstr2_cdnr_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnr_d
                              ? this.props.gstr2_cdnr_d.edges
                              : []
                          ) -
                          (flow(
                            flatMap(get("pr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.igst ? next.igst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnr_c
                              ? this.props.gstr2_cdnr_c.edges
                              : []
                          ) +
                            flow(
                              flatMap(get("pr_cdnr_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cgst ? next.cgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr2_cdnr_c
                                ? this.props.gstr2_cdnr_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("pr_cdnr_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.sgst ? next.sgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr2_cdnr_c
                                ? this.props.gstr2_cdnr_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("pr_cdnr_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cess ? next.cess : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr2_cdnr_c
                                ? this.props.gstr2_cdnr_c.edges
                                : []
                            ))
                      )}
                    </Col>
                    <Col span={3}>
                      <p>-</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={6}>Inward Supplies from Unregistered supplier (Reverse charge)(4B)</Col>
                    <Col span={2} />
                    <Col span={3}>
                      <p>{this.props.gstr2_b2bur ? this.props.gstr2_b2bur.pageInfo.total: "-"}</p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(reduce((curr, next) => curr + parseFloat(next.invoice_value, 10),0)(this.props.gstr2_b2bur ? this.props.gstr2_b2bur.edges : []))}
                    </Col>
                    <Col span={3}>
                      {formatNumber(flow(flatMap(get("pr_b2bur_items.edges")),reduce((curr, next) => curr + parseFloat(next.taxable_value, 10),0))(this.props.gstr2_b2bur ? this.props.gstr2_b2bur.edges : []))}
                    </Col>
                    <Col span={3}>
                    {formatNumber(
                      flow(
                        flatMap(get("pr_b2bur_items.edges")),
                        reduce(
                          (curr, next) =>
                            curr +
                            parseFloat(next.igst ? next.igst : "0", 10),
                          0
                        )
                      )(
                        this.props.gstr2_b2bur ? this.props.gstr2_b2bur.edges : []
                      ) +
                        flow(
                          flatMap(get("pr_b2bur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.cgst ? next.cgst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_b2bur
                            ? this.props.gstr2_b2bur.edges
                            : []
                        ) +
                        flow(
                          flatMap(get("pr_b2bur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.sgst ? next.sgst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_b2bur
                            ? this.props.gstr2_b2bur.edges
                            : []
                        ) +
                        flow(
                          flatMap(get("pr_b2bur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.cess ? next.cess : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_b2bur
                            ? this.props.gstr2_b2bur.edges
                            : []
                        )
                    )}
                    </Col>
                    <Col span={3}>
                      <p>-</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={6}>Debit/Credit Notes for Unregistered supplier(6C)</Col>
                    <Col span={2} />
                    <Col span={3}>
                      {this.props.gstr2_cdnur
                          ? this.props.gstr2_cdnur.pageInfo.total
                          : "-"}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.cdnur_value, 10),
                          0
                        )(
                          this.props.gstr2_cdnur_d
                            ? this.props.gstr2_cdnur_d.edges
                            : []
                        ) -
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.cdnur_value, 10),
                            0
                          )(
                            this.props.gstr2_cdnur_c
                              ? this.props.gstr2_cdnur_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={3}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_cdnur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr2_cdnur_d
                            ? this.props.gstr2_cdnur_d.edges
                            : []
                        ) -
                          flow(
                            flatMap(get("pr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr + parseFloat(next.taxable_value, 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnur_c
                              ? this.props.gstr2_cdnur_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={3}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_cdnur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_cdnur_d
                            ? this.props.gstr2_cdnur_d.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("pr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnur_d
                              ? this.props.gstr2_cdnur_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnur_d
                              ? this.props.gstr2_cdnur_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnur_d
                              ? this.props.gstr2_cdnur_d.edges
                              : []
                          ) -
                          (flow(
                            flatMap(get("pr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.igst ? next.igst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_cdnur_c
                              ? this.props.gstr2_cdnur_c.edges
                              : []
                          ) +
                            flow(
                              flatMap(get("pr_cdnur_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cgst ? next.cgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr2_cdnur_c
                                ? this.props.gstr2_cdnur_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("pr_cdnur_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.sgst ? next.sgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr2_cdnur_c
                                ? this.props.gstr2_cdnur_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("pr_cdnur_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cess ? next.cess : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr2_cdnur_c
                                ? this.props.gstr2_cdnur_c.edges
                                : []
                            ))
                      )}
                    </Col>
                    <Col span={3}>
                      <p>-</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Panel>

          <br />

          <Panel header={<b>Summary Level Details</b>} key="2">
            <Row gutter={16}>
              <Col>
                <Card bordered={true}>
                  <Row>
                    <Col span={9}>
                      <b>Type</b>
                    </Col>
                    <Col span={2} />
                    <Col span={4}>
                      <b>Total Value</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Taxable Value</b>
                    </Col>
                    <Col span={5}>
                      <b>Total Tax Liability</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={9}>Supplies from composition taxable person and other exempt/nil rated/non GST supplies(7)</Col>
                    <Col span={2} />
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) => curr + parseFloat((next.nil_amt ? next.nil_amt : 0), 10),
                          0
                        )(
                          this.props.gstr2_nil ? this.props.gstr2_nil.edges : []
                        ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.expt_amt ? next.expt_amt : 0), 10),
                            0
                          )(
                            this.props.gstr2_nil
                              ? this.props.gstr2_nil.edges
                              : []
                          ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.ngsup_amt ? next.ngsup_amt : 0), 10),
                            0
                          )(
                            this.props.gstr2_nil
                              ? this.props.gstr2_nil.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) => curr + parseFloat((next.nil_amt ? next.nil_amt : 0), 10),
                          0
                        )(
                          this.props.gstr2_nil ? this.props.gstr2_nil.edges : []
                        ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.expt_amt ? next.expt_amt : 0), 10),
                            0
                          )(
                            this.props.gstr2_nil
                              ? this.props.gstr2_nil.edges
                              : []
                          ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.ngsup_amt ? next.ngsup_amt : 0), 10),
                            0
                          )(
                            this.props.gstr2_nil
                              ? this.props.gstr2_nil.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={5}>
                      <p>₹ 0</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={9}>Advance amount paid for reverse charge supplies(10A)</Col>
                    <Col span={2} />
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_at_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(
                                next.gross_received ? next.gross_received : "0",
                                10
                              ),
                            0
                          )
                        )(this.props.gstr2_ar ? this.props.gstr2_ar.edges : [])
                      )}
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_at_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_ar ? this.props.gstr2_ar.edges : []
                        ) +
                          flow(
                            flatMap(get("pr_at_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_ar ? this.props.gstr2_ar.edges : []
                          ) +
                          flow(
                            flatMap(get("pr_at_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_ar ? this.props.gstr2_ar.edges : []
                          ) +
                          flow(
                            flatMap(get("pr_at_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_ar ? this.props.gstr2_ar.edges : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={9}>Adjustment of advance amount paid earlier for reverse charge supplies(10B)</Col>
                    <Col span={2} />
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_atadj_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(
                                next.gross_adjusted ? next.gross_adjusted : "0",
                                10
                              ),
                            0
                          )
                        )(this.props.gstr2_aa ? this.props.gstr2_aa.edges : [])
                      )}
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_atadj_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_aa ? this.props.gstr2_aa.edges : []
                        ) +
                          flow(
                            flatMap(get("pr_atadj_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_aa ? this.props.gstr2_aa.edges : []
                          ) +
                          flow(
                            flatMap(get("pr_atadj_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_aa ? this.props.gstr2_aa.edges : []
                          ) +
                          flow(
                            flatMap(get("pr_atadj_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_aa ? this.props.gstr2_aa.edges : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col span={9}>Input Tax Credit Reversal/Reclaim(11)</Col>
                    <Col span={2} />
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_itc_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_itc ? this.props.gstr2_itc.edges : []
                        ) +
                          flow(
                            flatMap(get("pr_itc_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_itc
                              ? this.props.gstr2_itc.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_itc_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_itc
                              ? this.props.gstr2_itc.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_itc_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_itc
                              ? this.props.gstr2_itc.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={9}>HSN summary of outward supplies(13)</Col>
                    <Col span={2} />
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.total_value, 10),
                          0
                        )(
                          this.props.gstr2_hsn ? this.props.gstr2_hsn.edges : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_hsn_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr2_hsn ? this.props.gstr2_hsn.edges : []
                        )
                      )}
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("pr_hsn_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr2_hsn ? this.props.gstr2_hsn.edges : []
                        ) +
                          flow(
                            flatMap(get("pr_hsn_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_hsn
                              ? this.props.gstr2_hsn.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_hsn_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_hsn
                              ? this.props.gstr2_hsn.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("pr_hsn_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr2_hsn
                              ? this.props.gstr2_hsn.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                </Card>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
        />
      </ContentWrapper>
    );
  }
}


const config = {
  name: "gstr2summary",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({gstr2summary, ownProps}) => {
    return {
      gstr2summary: gstr2summary,
      gstr2_b2b: get("viewer.pr_b2b_masters", gstr2summary),
      gstr2_b2bur: get("viewer.pr_b2bur_masters", gstr2summary),
      gstr2_cdnr: get("viewer.pr_cdnr_masters", gstr2summary),
      gstr2_cdnur: get("viewer.pr_cdnur_masters", gstr2summary),
      gstr2_nil: get("viewer.pr_exemp_masters", gstr2summary),
      gstr2_ar: get("viewer.pr_at_masters", gstr2summary),
      gstr2_aa: get("viewer.pr_atadj_masters", gstr2summary),
      gstr2_impg: get("viewer.pr_impg_masters", gstr2summary),
      gstr2_imps: get("viewer.pr_imps_masters", gstr2summary),
      gstr2_hsn: get("viewer.pr_hsn_masters", gstr2summary),
      gstr2_itc: get("viewer.pr_itc_masters", gstr2summary),
      gstr2_isd: get("viewer.pr_isd_masters", gstr2summary),

      gstr2_cdnr_c: get("viewer.pr_cdnr_masters_c", gstr2summary),
      gstr2_cdnur_c: get("viewer.pr_cdnur_masters_c", gstr2summary),
      gstr2_cdnr_d: get("viewer.pr_cdnr_masters_d", gstr2summary),
      gstr2_cdnur_d: get("viewer.pr_cdnur_masters_d", gstr2summary),
    };
  }
};

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(GSTR2_Query), config),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(GSTR2Dashboard)
