import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Steps,
  message,
  Popconfirm,
  Icon,
  notification,
  Modal
} from "antd";
import get from "lodash/fp/get";
import { ROUTES } from "../../shared/constants";
import flow from "lodash/fp/flow";
import toString from "lodash/fp/toString";
import pick from "lodash/fp/pick";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  GSTR3B_Summary_Query,
  CompanyByIdQuery
} from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import gspAuth from "../../services/GSPService";
import { BASE_URL, formatNumber } from "../../shared/constants";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../shared/styles";
const Step = Steps.Step;
const errorModal = Modal.error;
const add_button = {
  outline: "0",
  display: "inline-block",
  marginBottom: "0",
  fontWeight: "500",
  textAlign: "center",
  touchAction: "manipulation",
  cursor: "pointer",
  backgroundImage: "none",
  border: "1px solid transparent",
  whiteSpace: "nowrap",
  lineHeight: "1.5",
  padding: "0 15px",
  fontSize: "13.5px",
  borderRadius: "5px",
  height: "34px",
  MozUserSelect: "none",
  position: "relative",
  color: "#000000a6",
  backgroundColor: "rgb(35, 130, 199)",
  borderColor: "#d9d9d9",
  paddingTop: "5px"
};
const card_styles = {
  height: 240
};

class GSTR3BDashboard extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null
      };
    } else {
      this.gsp = {};
    }
    this.props.gstr3bsummarydata.refetch();
  }

  componentDidUpdate = () => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp =
        this.gsp.otp !== undefined && this.gsp.otp !== null
          ? this.gsp.otp
          : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    this.setState({ requestOTP: false });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.uploadGSTR3BData();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visible: false,
    downloadLoading: false,
    popupConfirmReset: false,
    requestOTP: false,
    otp: null,
    period: null,
    run: false
  };

  popupConfirmResetVisibleChange = e => {
    if (!e) {
      this.setState({ popupConfirmReset: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset: true });
      } else {
        this.setState({ popupConfirmReset: false });
      }
    }
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  uploadGSTR3BData = async () => {
    message.destroy();
    message.loading("Uploading to GSTN...", 0);
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }
    // console.log(this.props);
    var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    return fetch(
      `${BASE_URL}/export/jsonGSTR3B?from=gstr3b&companyId=${
        this.props.companyId
      }&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    ).then(async result => {
      // console.log(result);
      const body = await result.json();
      // console.log(body);
      // console.log(this.gsp);
      const companyDetails = flow(
        pick(["gstin", "id", "gstnUserName"]),
        this.otherGSTNInformation
      )(this.props.company);
      const reqData = {
        companyDetails: companyDetails,
        gspDetails: {
          gspToken: this.gsp.access_token,
          otp: this.gsp.otp
        },
        period: period,
        body: body
      };
      // console.log(reqData);
      this.gsp.response = await gspAuth.saveGSTR3B(reqData);
      // console.log(this.gsp);
      message.destroy();
      if (
        !this.gsp.response.data.success &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        this.setState({ requestOTP: true });
      } else if (!this.gsp.response.data.success) {
        errorModal({
          title: "Save Unsuccessful",
          content: (
            <p>
              {this.gsp.response.data.errorCode
                ? this.gsp.response.data.errorCode
                : "Unable to process your request, please try again later."}
            </p>
          ),
          iconType: "close-circle"
        });
      }
      if (this.gsp.response.data.success) {
        if (
          this.gsp.response.retstatus &&
          this.gsp.response.retstatus.status_cd === "P"
        ) {
          notification.open({
            message: "Success",
            description: "GSTR3B Saved Successfully!!",
            duration: 0,
            icon: <Icon type="check-circle" style={{ color: "#008000" }} />
          });
        } else if (
          this.gsp.response.retstatus &&
          this.gsp.response.retstatus.status_cd === "PE"
        ) {
          const key = `open${Date.now()}`;
          const btn = (
            <Button
              type="primary"
              size="small"
              onClick={() => notification.close(key)}
            >
              Download Error Report
            </Button>
          );
          notification.open({
            message: "Check the error report!",
            description: "GSTR3B data partially saved!!..",
            btn,
            key,
            duration: 0,
            icon: (
              <Icon type="exclamation-circle" style={{ color: "#FFA500" }} />
            )
          });
        } else if (
          this.gsp.response.retstatus &&
          this.gsp.response.retstatus.status_cd === "ER"
        ) {
          errorModal({
            title: "GSTR3B Save Failed",
            content: (
              <p>{this.gsp.response.retstatus.error_report.error_msg}</p>
            ),
            iconType: "close-circle"
          });
        }
      }
    });
  };

  resetGSTR3B = () => {
    var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    fetch(
      `${BASE_URL}/export/resetGSTR3B?from=sales&companyId=${
        this.props.companyId
      }&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    ).then(result => {
      message.success("GSTR-3B Data been reset for the selected tax period");
    });
  };

  downloadGSTR3B = () => {
    var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    window.location.href = `${BASE_URL}/export/jsonGSTR3B?from=gstr3b&companyId=${
      this.props.companyId
    }&downloaddate=${downloaddate}`;
  };



  handleClickStart = e => {
    
    e.preventDefault();

    this.setState({
      run: true
    });
  };


  render() {
    return (
      <div style={{ backgroundColor: '#fff', padding: '5px' }}>
      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
        <Button  size='small' type="primary" shape="circle" icon="question" onClick={this.handleClickStart} />
      </div>
        <Steps current={[1, 2, 3]}>
          <Step
            title="Preview GSTR3B"
            description="Summarized data of your 3B data."
          />
          <Step
            title={
              <a style={add_button} className="edit_3b">
                <Icon
                  style={{ fontSize: "13.5px", color: "#FFFFFF" }}
                  type="edit"
                />{" "}
                &nbsp;
                <Link to={ROUTES.GST_FILINGS_GSTR3B_INPUT}>
                  Edit GSTR3B Data
                </Link>
              </a>
            }
            description="Fill all GSTR-3B data"
          />
          <Step
            title={
              <Popconfirm
                placement="bottomRight"
                title="Clicking this button will SAVE your GSTR3B info in GSTN!"
                onVisibleChange={this.popupConfirmResetVisibleChange}
                visible={this.state.popupConfirmReset}
                onConfirm={this.uploadGSTR3BData}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    backgroundColor: "#04880fe0",
                    fontSize: "13px",
                    color: "white"
                  }}
                  icon="cloud-upload"
                  disabled={
                    this.props.company && this.props.company.role === "reviewer"
                  }
                >
                  Save GSTR3B
                </Button>
              </Popconfirm>
            }
            className="save_gstr3b"
            description="Clicking this button will save your GSTR3B info to GST network."
          />
          {/* <Step
          title={
            <a style={add_button}>
                <Icon
                  style={{ fontSize: "13.5px", color: "#FFFFFF" }}
                  type="edit"
                />{" "}
                &nbsp;
                <Link to={ROUTES.GST_FILINGS_GSTR3B_PAYTAX}>
                  Pay Taxes / Adjust Credit
                </Link>
              </a>
          }
          description="Pay Taxes / Adjust Credit"
          >
          </Step> */}

          
        </Steps>
        <br />
        <br />
        <div className="threeb_summary">
        <Row>
          <Col span={12}>
            <Card
              title="3.1 Details of outward supplies and inward supplies liable to reverse charge"
              bordered={true}
              style={card_styles}
            >
              <Row>
                <Col span={12}>
                  <p>Integrated Tax</p>
                  <p>Central Tax</p>
                  <p>State UT Tax</p>
                  <p>Cess Tax</p>
                </Col>

                <Col span={12}>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Three_Point_One_query.iamt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Three_Point_One_query.camt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Three_Point_One_query.samt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Three_Point_One_query.csamt
                        : 0
                    )}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="3.2 Of the supplies shown in 3.1 (a) above, details of inter-State supplies made to unregistered persons, composition taxable persons and UIN holders"
              bordered={true}
              style={card_styles}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <div>
                    <br />
                    <p>Taxable Value</p>
                    <p>Integrated Tax</p>
                    <br />
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <br />
                    <p>
                      {formatNumber(
                        this.props.gstr3b_summary
                          ? this.props.gstr3b_summary
                              .three_point_two_summary_query.txval === null
                            ? 0
                            : this.props.gstr3b_summary
                                .three_point_two_summary_query.txval
                          : 0
                      )}
                    </p>
                    <p>
                      {formatNumber(
                        this.props.gstr3b_summary
                          ? this.props.gstr3b_summary
                              .three_point_two_summary_query.iamt === null
                            ? 0
                            : this.props.gstr3b_summary
                                .three_point_two_summary_query.iamt
                          : 0
                      )}
                    </p>
                    <br />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={8}>
            <Card title="4. Eligible ITC" bordered={true} style={card_styles}>
              <Row>
                <Col span={12}>
                  <p>Integrated Tax</p>
                  <p>Central Tax</p>
                  <p>State UT Tax</p>
                  <p>Cess Tax</p>
                </Col>
                <Col span={12}>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Four_query.iamt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Four_query.camt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Four_query.samt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Four_query.csamt
                        : 0
                    )}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="5. Values of exempt, nil-rated and non-GST inward supplies"
              bordered={true}
              style={card_styles}
            >
              <Row>
                <Col span={12}>
                  <br />
                  <p>Inter State Supplies</p>
                  <p>Intra State Supplies</p>
                  <br />
                </Col>
                <Col span={12}>
                  <br />
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Five_query.inter
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Five_query.intra
                        : 0
                    )}
                  </p>
                  <br />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={8}>
            <Card
              title="5.1 Interest and late fee payable"
              bordered={true}
              style={card_styles}
            >
              <Row>
                <Col span={12}>
                  <p>Integrated Tax</p>
                  <p>Central Tax</p>
                  <p>State UT Tax</p>
                  <p>Cess Tax</p>
                </Col>
                <Col span={12}>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Five_Point_One_query.iamt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Five_Point_One_query.camt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Five_Point_One_query.samt
                        : 0
                    )}
                  </p>
                  <p>
                    {formatNumber(
                      this.props.gstr3b_summary
                        ? this.props.gstr3b_summary.Five_Point_One_query.csamt
                        : 0
                    )}
                  </p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        </div>
        <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state);
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear
    }
  }),
  props: ({ company }) => {
    return {
      company: get("viewer.company", company),
      gspDetails: get("viewer.gspInfo", company)
    };
  }
};

const config = {
  name: "gstr3bsummarydata",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({ gstr3bsummarydata, ownProps }) => {
    return {
      gstr3bsummarydata: gstr3bsummarydata,
      gstr3b_summary: get("viewer", gstr3bsummarydata)
    };
  }
};

export default compose(
  withRouter,
  withApollo,
  connect(
    mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }
  ),
  graphql(gql(GSTR3B_Summary_Query), config),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(GSTR3BDashboard);

// <SRow style={row_style}>
//   <Col span={8}>
//     <DropZone style={{}} multiple={false}>
//       <Popover title="Upload GSTR3B Excel">
//         <Button style={button_style} icon="cloud-upload" type="primary" >
//           Upload GSTR3B Excel
//         </Button>
//       </Popover>
//     </DropZone>
//   </Col>
//   <Col span={8}>
//   <Popover title="Use this JSON to upload directly in GST Network">
//     <Button icon="safety" type="primary" onClick={this.downloadGSTR3B}>Download GSTR3B JSON</Button>
//   </Popover>
//   </Col>
//   <Col span={8}>
//     <Button icon="safety" type="danger" onClick={this.resetGSTR3B}> Reset</Button>
//   </Col>
// </SRow>
// <br />
// <br />
//

// GST3B_Upload = async acceptedFiles => {
//   var el = document.body,
//   elChild = document.createElement('div');
//   elChild.className = "root_div";
//   elChild.innerHTML = ' <div class="custom_loading loader"></div>';
//   el.insertBefore(elChild, el.firstChild);
//   document.getElementById('root').classList.add("blur");
//   const result = await GSTR3B.upload({
//     file: acceptedFiles[0],
//     type: 'gstr3b',
//     companyId: this.state.companyId,
//     finMonth: this.state.finMonth,
//     finYear: this.state.finYear,
//   })
//   document.body.getElementsByClassName("root_div")[0].remove();
//   document.getElementById('root').classList.remove('blur');
//   console.log('DATA FROM UPLOAD');
//   console.log(result);
//   if (result.filestatus == true)
//   {
//     if (result.flag == true)
//     {
//       var dialogue_msg = '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + result.total_errors + ' Invoices Failed out of ' + result.total + ' Invoices</b></span>) Sales data upload Failed!</h4></p><br><p>Error Excel Will be Downloaded. Please look at the Error Excel to correct the Errors!</p></div>';
//       var div = document.getElementById('dialogue_append1');
//       if (document.getElementById('dialogue_append1') != null)
//       {
//         div.innerHTML += dialogue_msg;
//       }
//     }
//     /************************************************************************************/
//     if(result.total_errors > 0)
//     {
//       //message.error('(' + total_negative + '/' + result.total + ') GSTR3B data upload Failed!')
//     }
//     else
//     {
//       message.success('(' + result.total + '/' + result.total + ') GSTR3B Excel Data uploaded successfully!', 8)
//     }
//     this.props.data.refetch()
//   }
//   else
//   {
//     message.error('Upload File Type is Not Allowed', 10)
//   }
// }

// <Step title="Preview GSTR3B" description="Summarized data of your 3B data." />

// <Step title={<a style={add_button_one}>
//   <Icon type="edit" /> &nbsp;<Link to={ROUTES.GST_FILINGS_GSTR3B_PAYTAX}>Pay Tax</Link>
// </a>} />

// <SRow style={row_style}>
//           <Col span={10}>
//           <Popover title="Use this JSON to upload directly in GST Network">
//             <Button icon="safety" type="dashed" onClick={this.downloadGSTR3B}>Download GSTR3B JSON</Button>
//           </Popover>
//           </Col>
//         </SRow>
