import React from "react";
import { Table, InputNumber, Input, Tag, Button } from "antd";
import GSTR9Pt3Sec7OtherReversals from "./GSTR9Pt3Sec7OtherReversals"

const GSTR9Pt3Sec7 = props => {
  const columns = [
    {
      title: "Pt. III",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title:
        "7. Details of ITC Reversed and Ineligible ITC for the financial year",
      children: [
        {
          title: "Description",
          dataIndex: "det",
          key: "det",
          width: "30%",
          render: (text, record, index) => {
            if(record.key === "8"){
              return {
                children: <GSTR9Pt3Sec7OtherReversals {...props} items={record.items} otherClassName="gstr9pt3sec7otherreversals" />,
                props:{
                  colSpan: 5
                }
              }
            }else{
              return text
            }
          }
        },
        {
          title: "Integrated Tax (₹)",
          dataIndex: "iamt",
          key: "iamt",
          width: "15%",
          render: (text, record) => {
            if(record.key === "8") { return {children:null, props:{colSpan:0}}}
            return record.key === "9" ||
              record.key === "10" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec7-${record.key}-iamt-tbl7`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "Central Tax (₹)",
          dataIndex: "camt",
          key: "camt",
          width: "15%",
          render: (text, record) => {
            if(record.key === "8") { return {children:null, props:{colSpan:0}}}
            return record.key === "9" ||
              record.key === "10" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec7-${record.key}-camt-tbl7`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "State/UT Tax (₹)",
          dataIndex: "samt",
          key: "samt",
          width: "15%",
          render: (text, record) => {
            if(record.key === "8") { return {children:null, props:{colSpan:0}}}
            return record.key === "9" ||
              record.key === "10" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec7-${record.key}-samt-tbl7`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "CESS (₹)",
          dataIndex: "cess",
          key: "cess",
          width: "15%",
          render: (text, record) => {
            if(record.key === "8") { return {children:null, props:{colSpan:0}}}
            return record.key === "9" ||
              record.key === "10" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec7-${record.key}-cess-tbl7`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        }
      ]
    }
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "Details of ITC as declared in returns filed during the financial year"
      }
      size="small"
      dataSource={props.pt3sec7.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt3Sec7;