import React from 'react'
import GSTR9Pt2Sec4 from './GSTR9Pt2Sec4'
import GSTR9Pt2Sec5 from './GSTR9Pt2Sec5'

const GSTR9Pt2 = props => {
    return (
      <div>
        {props.pt2sec4.dataSource && props.pt2sec4.dataSource.length > 0 ? (<GSTR9Pt2Sec4 {...props}></GSTR9Pt2Sec4>) : null}
        {props.pt2sec5.dataSource && props.pt2sec5.dataSource.length > 0 ? (<GSTR9Pt2Sec5 {...props}></GSTR9Pt2Sec5>) : null}
      </div>
    )
  }

  export default GSTR9Pt2