import React from "react";
import Table from 'antd/lib/table'
import glamorous from 'glamorous'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import { Link } from 'react-router-dom'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import Col from 'antd/lib/col'
import { ContactsDetails, CompanyByIdQuery, ContactDeleteMutation } from 'qgst-gql-queries/web/data.json'
import { ROUTES } from '../../shared/constants'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import get from 'lodash/fp/get'
import Popconfirm from 'antd/lib/popconfirm'
import { Button as B } from '../../shared/styles'
import message from 'antd/lib/message'

const { Column } = Table
const edit_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.80', padding: '0 15px', fontSize: '12px', borderRadius: '5px', height: '33px', mozUserSelect: 'none', position: 'relative', color: '#000000a6', backgroundColor: 'rgb(35, 130, 199)', borderColor: '#d9d9d9', paddingTop: '5px' };
const scroll = { x: 2650 };

const ContactDetails = props => {
  return (
    <Root>
      <Content>
        <Row>
          <Col span="5">
            <Button size="large" icon="add" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}>
              <Link to={ROUTES.CONTACT_UPLOAD}>
                Add Contact
                            </Link>
            </Button>
          </Col>
          <Col span="5">
            <Button size="large" icon="add" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}>
              Upload Contacts
                    </Button>
          </Col>
        </Row>
        <Table
          scroll={scroll}
          dataSource={props.contact}
          bordered title={() => 'Contact Details'}
          pagination={{ className: 'my-pagination' }}
        >
          <Column title="Company Name" dataIndex="companyName"></Column>
          <Column title="GSTIN" dataIndex="gstin" ></Column>
          <Column title="Email id" key="" dataIndex="email1" ></Column>
          <Column title="Email id-2" key="" dataIndex="email2" ></Column>
          <Column title="Contact Person Name" key="" dataIndex="contactName" ></Column>
          <Column title="Mobile Number" key="" dataIndex="mobile" ></Column>
          <Column title="Contact Person Name-2" key="pos_id" dataIndex="contactName2"></Column>
          <Column title="Mobile Number-2" key="" dataIndex="mobile2" ></Column>
          <Column title="Landline number" key="" dataIndex="landline" ></Column>
          <Column title="Landline number-2" key="" dataIndex="landline2" ></Column>
          <Column title="PAN" key="" dataIndex="pan" ></Column>
          <Column title="Address" key="" dataIndex="address" ></Column>
          <Column title="City" key="" dataIndex="city" ></Column>
          <Column title="Pincode" key="" dataIndex="pincode" ></Column>
          <Column title="State" key="" dataIndex="state" ></Column>
          <Column title="Country" key="" dataIndex="country" ></Column>
          <Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            className="alignCenter"
            render={(text, record) => {
              return (
                <span>
                  <a style={edit_button} type="primary">
                    <Link to={ROUTES.CONTACT_UPDATE.replace(':id', record.id)}>
                      Edit
                    </Link>
                  </a>
                  <Popconfirm
                    title="Are you sure delete this contact?"
                    onConfirm={() => props.deleteContact(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <B style={{ backgroundColor: '#a01208de', fontSize: '13px', color: 'white' }}>Delete</B>
                  </Popconfirm>
                </span>
              )
            }}
          />
        </Table>
      </Content>
    </Root>
  )
}

class ContactDetailsContainer extends React.Component {

  deleteContact = async id => {
    try {
      await this.props.mutate(id)
      message.success('Contact has been deleted!')

    } catch (e) {

    }
  }

  render() {
    return (
      <div style={{ flex: 1 }}>
        <ContactDetails
          contact={get('viewer.contact.edges')(this.props.data)}
          deleteContact={this.deleteContact}
        />
      </div>
    )
  }

}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
}

const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      gspDetails: get("viewer.gspInfo", data),
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
  }
}

const contactDeleteMutationConfig = {
  props: ({ mutate }) => {
    return {
      mutate: async id => {
        await mutate({
          variables: { id },
          refetchQueries: [
            {
              query: gql(ContactsDetails),
            },
          ],
        })
      },
    }
  },
}

export default compose(
  connect(mapStateToProps),
  graphql(gql(ContactsDetails), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
  graphql(gql(ContactDeleteMutation), contactDeleteMutationConfig)
)(ContactDetailsContainer)
