import React from 'react'
import glamorous from 'glamorous'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Tooltip from 'antd/lib/tooltip'
import get from 'lodash/fp/get'
import reduce from 'lodash/fp/reduce'
import flow from 'lodash/fp/flow'
import { formatNumber } from "../../shared/constants";
import flatMap from 'lodash/fp/flatMap'
import { PR_IMPS_PurchaseQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PrIMPSItems from './PR_IMPS_Items'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import moment from 'moment'
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const { ColumnGroup, Column } = Table
const SRow = glamorous(Row)({ margin: '15px 0',})

const PurchaseRegister = props => {
  function compareByAlph(a, b) {
    if (a < b) {
      return -1;
    } if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
  return (
    <Root>
      <Content>
        <SRow gutter={24} style={row_style}>
          <Col span="6">
            Total Purchase Value:{' '}
            {props.formatNumber(reduce((curr, next) => {
              curr = parseFloat(curr) + parseFloat(next.invoice_value, 2);
              var temp = parseFloat(curr);
              temp = parseFloat(temp).toFixed(2);
              return(temp)}, 0)(props.pr_imps_masters))}
          </Col>
          <Col span="6">
            Total Taxable Value:{' '}
            {props.formatNumber(flow(
              flatMap(get('pr_imps_items.edges')),
              reduce((curr, next) => {
                curr = parseFloat(curr) + parseFloat(next.taxable_value, 2);
                var temp = parseFloat(curr);
                temp = parseFloat(temp).toFixed(2);
                return(temp)}, 0))(props.pr_imps_masters))}
          </Col>
          <Col span="12">
          </Col>
        </SRow>
        <Table dataSource={props.pr_imps_masters} bordered rowKey="id" loading={props.loading} title={() => 'IMPS Purchases'} pagination={{ className: 'my-pagination' }}>
          <ColumnGroup title="Invoice Details">
            <Column title="No." dataIndex="invoice_number" key="invoice_number" sorter={(a, b) => compareByAlph(a.invoice_number, b.invoice_number)} />
            <Column title="Date" dataIndex="invoice_date" key="invoice_date" render={text => moment(text, "DD-MM-YYYY").format('DD/MM/YYYY')}/>
            <Column title="Value" dataIndex="invoice_value" key="invoice_value" render={val => <p style={{ width: '115px'}}>{props.formatNumber(val === null ? 0 : val)}</p>}  />
          </ColumnGroup>
          <Column title="Place of Supply" dataIndex="pos_id" key="pos_id" />
          <Column title="Supply Type" dataIndex="supply_type" key="supply_type" />
          <Column title="Items" key="pr_imps_items"
            render={(text, record) => {
              return (
                <Tooltip title="View Item(s)">
                  <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                    {record.pr_imps_items.pageInfo.total} Item(s)

                  </Button>
                  <PrIMPSItems isVisible={props.visibleModal === record.id} onCancel={props.hideModal} pr_imps_items={record.pr_imps_items.edges}/>
                </Tooltip>
              )
            }}
          />
        </Table>
      </Content>
    </Root>
  )
}

class PurchaseRegisterContainer extends React.Component {
  constructor(props) {
    super(props)
    console.log(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year =  curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n+1,
      finYear: year,
      curFinMonth: n+1,
      curFinYear: year,
    }
    this.props.data.refetch()
  }
  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }
  changeSelectedFinancialMonth = name => {
    this.props.changeSelectedFinancialMonth(name)
  }
  render() {
    var tableLoading = false;
    if (this.props.data.networkStatus === 4) { tableLoading = false; }
    else if (this.props.data.networkStatus === 7) { tableLoading = false; }
    else if (this.props.data.networkStatus === 1) { tableLoading = true; }
    return (
      <PurchaseRegister
        pr_imps_masters={get('viewer.pr_imps_masters.edges')(this.props.data)}
        role={get('company.role', this.props)}
        finMonth={this.state.finMonth}
        formatNumber={formatNumber}
		    finYear={this.state.finYear}
        loading={tableLoading}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
        isSR={this.props.isSR}
      />
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      // isSR: props.isSR,
      type: 'primps',
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}
const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}
export default compose(
  connect(mapStateToProps),
  graphql(gql(PR_IMPS_PurchaseQuery), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(PurchaseRegisterContainer)
