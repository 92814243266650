import React from "react";
import Modal from "antd/lib/modal";
class Anx2MissingDocuments extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
        console.log("rendering")
        return (<Modal
            title="Testing Details"
            width="90%"
          >
            <div>test</div>
          </Modal>)
    }
}

export default Anx2MissingDocuments;