import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { FormItem } from '../../shared/utils'
import { ContentWrapper } from '../../shared/styles'

export default (props) =>
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR9}>
    <br />
    <br />
    <h2 style={{ textAlign: "center"}}>Details of tax paid as declared in returns filed during the financial year</h2>
    <br />
    <br />
    <br />
    <style>
          {`
            table tr td {
              border: 0.1px solid #dcd0d0;
              text-align: center;
              width: 300px;
              height: 45px;
              padding: 14px;
            }
      `}
    </style>
    <table style={{ marginLeft: "100px"}}>

            <tr>
              <td rowSpan = {2}>Description</td>
              <td rowSpan = {2}>Tax Payable</td>
              <td rowSpan = {2}>Paid through cash</td>
              <td colSpan = {4}>Paid through ITC</td>
            </tr>
            <tr>
                <td>IGST(₹)</td>
                <td>CGST(₹)</td>
                <td>SGST(₹)</td>
                <td>CESS(₹)</td>
            </tr>
              <tr>
                <td>Integrated Tax</td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('iamt_a', {
                    
                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('iamt_b', {
                  })(
                    
                      <InputNumber  step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('iamt_c', {

                  })(
                    
                      <InputNumber  step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('iamt_d', {

                  })(
                    
                      <InputNumber onBlur={value => props.currentITCBalance(value, 'samt')}  step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('iamt_e', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('iamt_f', {

                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                
                

              </tr>
              <tr>
                <td>Central Tax</td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('camt_a', {
                    
                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('camt_b', {

                  })(
                    
                      <InputNumber  step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('camt_c', {

                  })(
                    
                      <InputNumber step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('camt_d', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('camt_e', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('camt_f', {

                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                

              </tr>
              <tr>
                <td>States/UT Tax</td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('samt_a', {
                    
                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('samt_b', {

                  })(

                      <InputNumber step={0.01} style={{ width: 130 }}    />

                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('samt_c', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('samt_d', {

                  })(
                    
                      <InputNumber step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('samt_e', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('samt_f', {

                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                

              </tr>
              <tr>
                <td>CESS</td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('cess_a', {
                   
                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('cess_b', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('cess_c', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('cess_d', {

                  })(<InputNumber style={{ width: 130 }}  />)}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('cess_e', {

                  })(
                    
                      <InputNumber id='cessInput'  step={0.01} style={{ width: 130 }}    />
                    
                  )}
                </FormItem>
                </td>
                <td>
                <FormItem>
                  {props.getFieldDecorator('cess_f', {

                  })(<InputNumber  step={0.01} style={{ width: 130 }}    />)}
                </FormItem>
                </td>
                
                

              </tr>
        </table>
  </Form>
  </ContentWrapper>

