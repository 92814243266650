/**
 * @flow
 */
import React from 'react'
import glamorous from 'glamorous'
import Icon from 'antd/lib/icon'
import Menu from 'antd/lib/menu'
import { withRouter } from 'react-router-dom'

import NavLink from './NavLink'
import { ROUTES } from '../../shared/constants'

const { Item, SubMenu } = Menu
const SubmenuSpan = glamorous.span({fontSize: 14,})


type CProps = {location: { pathname: string, },}


const NavMenu = (props: props) => {
  const { location } = props
  const defaultOpenKeys = location.pathname.split('/')
  const selectedKeys = location.pathname.length > 1 ? [location.pathname] : ['/dashboard']

  // console.log(props);
  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      defaultselectedkeys={["d"]}
      defaultOpenKeys={["gstfiling", "sales", "purchase"]}
    // inlineCollapsed={true}
    >
      <Item key="d">
        <NavLink iconType="schedule" linkTo={ROUTES.DASHBOARD} linkText="Dashboard" />
      </Item>
      <Item key={ROUTES.COMPANY} className="tour_company">
        <NavLink iconType="database" linkTo={ROUTES.COMPANY} linkText="Company" />
      </Item>
      <SubMenu key="gstfiling" title={<SubmenuSpan><Icon type="appstore" /><span>GST Filings</span></SubmenuSpan>}>

        <Item key={ROUTES.GST_FILINGS_GSTR1} className="tour_gstr1">
          <NavLink iconType="tags-o" linkTo={ROUTES.GST_FILINGS_GSTR1} linkText="GSTR-1" />
        </Item>

        <Item key={ROUTES.GST_FILINGS_GSTR3B} className="tour_gstr3b">
          <NavLink iconType="tags" linkTo={ROUTES.GST_FILINGS_GSTR3B} linkText="GSTR-3B" />
        </Item>

        <Item key={ROUTES.GST_FILINGS_GSTR9} className="tour_gstr9">
          <NavLink iconType="tags" linkTo={ROUTES.GST_FILINGS_GSTR9} linkText="GSTR-9" />
        </Item>

      </SubMenu>



      <Item key={ROUTES.ITC} className="itccorner">
        <NavLink iconType="calculator" linkTo={ROUTES.ITC} linkText="ITC Corner" />
      </Item>


      <SubMenu key="anx-1" title={<SubmenuSpan><Icon type="book" /><span>Anx-1</span></SubmenuSpan>}>
        <SubMenu key="anx1_regular" title={
          <SubmenuSpan className="tour_anx1_regular"><Icon type="check-circle-o" /><span>Regular</span></SubmenuSpan>
        }>
          <Item key={ROUTES.ANX1_B2B}>
            <NavLink iconType="file-add" linkTo={ROUTES.ANX1_B2B} linkText="B2B " />
          </Item>
          <Item key={ROUTES.ANX1_B2C}>
            <NavLink iconType="file-add" linkTo={ROUTES.ANX1_B2C} linkText="B2C" />
          </Item>
        </SubMenu>
      </SubMenu>


      <SubMenu key="sales" title={<SubmenuSpan><Icon type="book" /><span>Sales Register</span></SubmenuSpan>}>
        <Item key={ROUTES.SALES_DASHBOARD} className="tour_upload_sales">
          <NavLink iconType="cloud-upload" linkTo={ROUTES.SALES_DASHBOARD} linkText="Upload Sales" />
        </Item>
        <SubMenu key="sales_regular" title={
          <SubmenuSpan className="tour_sales_regular"><Icon type="check-circle-o" /><span>Regular</span></SubmenuSpan>
        }>
          <Item key={ROUTES.SALES_B2B}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_B2B} linkText="B2B Invoices" />
          </Item>
          <Item key={ROUTES.SALES_B2C}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_B2C} linkText="B2CL Invoices" />
          </Item>
          <Item key={ROUTES.SALES_B2CS}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_B2CS} linkText="B2CS Invoices" />
          </Item>
          <Item key={ROUTES.SALES_CDNR}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_CDNR} linkText="CDNR" />
          </Item>
          <Item key={ROUTES.SALES_CDNUR}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_CDNUR} linkText="CDNUR" />
          </Item>
          <Item key={ROUTES.SALES_EXPORTS}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_EXPORTS} linkText="Exports" />
          </Item>
          <Item key={ROUTES.SALES_AT}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_AT} linkText="Advanced Received" />
          </Item>
          <Item key={ROUTES.SALES_ATADJ}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_ATADJ} linkText="Advanced Adjusted" />
          </Item>
          <Item key={ROUTES.SALES_HSN}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_HSN} linkText="HSN Summary" />
          </Item>
          <Item key={ROUTES.SALES_EXEMP}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_EXEMP} linkText="Exempt/Nil/Non-GST" />
          </Item>
          <Item key={ROUTES.SALES_DOC}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_DOC} linkText="DOCS" />
          </Item>
        </SubMenu>
        <SubMenu key="sales_amended" title={<SubmenuSpan className="tour_sales_amended"><Icon type="check-circle" /><span>Amended</span></SubmenuSpan>}>
          <Item key={ROUTES.SALES_B2BA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_B2BA} linkText="B2B-A Invoices" />
          </Item>

          <Item key={ROUTES.SALES_B2CA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_B2CA} linkText="B2CL-A Invoices" />
          </Item>

          <Item key={ROUTES.SALES_B2CSA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_B2CSA} linkText="B2CS-A Invoices" />
          </Item>

          <Item key={ROUTES.SALES_CDNRA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_CDNRA} linkText="CDNR-A" />
          </Item>

          <Item key={ROUTES.SALES_CDNURA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_CDNURA} linkText="CDNUR-A" />
          </Item>

          <Item key={ROUTES.SALES_EXPORTSA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_EXPORTSA} linkText="Exports-A" />
          </Item>

          <Item key={ROUTES.SALES_ATA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_ATA} linkText="Advanced Received-A" />
          </Item>
          <Item key={ROUTES.SALES_ATADJA}>
            <NavLink iconType="file-add" linkTo={ROUTES.SALES_ATADJA} linkText="Advanced Adjusted-A" />
          </Item>
        </SubMenu>
      </SubMenu>
      <SubMenu key="purchase" title={<SubmenuSpan className="tour_purchase"><Icon type="wallet" /><span>Purchase Register</span></SubmenuSpan>}>
        <Item key={ROUTES.PURCHASE_DASHBOARD}>
          <NavLink iconType="cloud-upload" linkTo={ROUTES.PURCHASE_DASHBOARD} linkText="Upload Purchase" />
        </Item>
        <SubMenu key="purchase_regular" title={<SubmenuSpan><Icon type="check-circle-o" /><span>Regular</span></SubmenuSpan>}>

          <Item key={ROUTES.PURCHASE_B2B}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_B2B} linkText="B2B Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_B2BUR}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_B2BUR} linkText="B2BUR Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_CDNR}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_CDNR} linkText="CDNR Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_CDNUR}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_CDNUR} linkText="CDNUR Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_EXEMP}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_EXEMP} linkText="EXEMP Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_AT}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_AT} linkText="AT Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_ATADJ}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_ATADJ} linkText="ATADJ Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_ITC}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_ITC} linkText="ITC Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_ISD}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_ISD} linkText="ISD Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_IMPG}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_IMPG} linkText="IMPG Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_IMPS}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_IMPS} linkText="IMPS Purchase" />
          </Item>
          <Item key={ROUTES.PURCHASE_HSN}>
            <NavLink iconType="file-add" linkTo={ROUTES.PURCHASE_HSN} linkText="HSN Purchase" />
          </Item>
        </SubMenu>
      </SubMenu>
      <Item key={ROUTES.SUPPLIER_FILING_TRENDS} className="sft">
        <NavLink iconType="team" linkTo={ROUTES.SUPPLIER_FILING_TRENDS} linkText="Return Filing Trends" />
      </Item>

      <Item key={ROUTES.TG_UTILITIES_LEDGERS}>
        <NavLink iconType="profile" linkTo={ROUTES.TG_UTILITIES_LEDGERS} linkText="Ledgers" />
      </Item>
      {/*<Item key={ROUTES.INSIGHTS_SALES} className="sales_insights">
        <NavLink iconType="area-chart" linkTo={ROUTES.INSIGHTS_SALES} linkText="Insights"/>
      </Item>*/}
      {/* <SubMenu key="utilities" title={<SubmenuSpan><div className="tour_purchase"><Icon type="wallet" /><span>TG Utilities</span></div></SubmenuSpan>}>
      <Item key={ROUTES.SUPPLIER_FILING_TRENDS}>
        <div className="sft">
        <NavLink iconType="team" linkTo={ROUTES.SUPPLIER_FILING_TRENDS} linkText="Return Filing Trends"/>
        </div>
      </Item>
      <Item key={ROUTES.TG_UTILITIES_LEDGERS}>
        <div className="sft">
        <NavLink iconType="profile" linkTo={ROUTES.TG_UTILITIES_LEDGERS} linkText="Ledgers"/>
        </div>
      </Item>
      
      </SubMenu> */}

    </Menu >
  )
}

export default withRouter(NavMenu)
// export {NavMenu}




// // <SubMenu key="gstnsync" title={<SubmenuSpan><Icon type="sync" /><span>GSTN Sync</span></SubmenuSpan>}>

//       // <Item key={ROUTES.GSTN_SYNC_FETCH_ALL}>
//       //   <NavLink iconType="file-add" linkTo={ROUTES.GSTN_SYNC_FETCH_ALL} linkText="Fetch Archives"/>
//       // </Item>
//       // <Item key={ROUTES.GSTN_SYNC_B2B}>
//       //   <NavLink iconType="file-add" linkTo={ROUTES.GSTN_SYNC_B2B} linkText="Regular"/>
//       // </Item>
//       // <Item key={ROUTES.GSTN_SYNC_B2CL}>
//       //   <NavLink iconType="file-add" linkTo={ROUTES.GSTN_SYNC_B2CL} linkText="Amendments"/>
//       // </Item>

//       // </SubMenu>

//       // <Item key={ROUTES.GST_FILINGS_GSTR9}>
//         // <div className="tour_gstr9">
//         //   <NavLink iconType="tags" linkTo={ROUTES.GST_FILINGS_GSTR9} linkText="GSTR-9"/>
//         // </div>
//         // </Item>

// // <Item key={ROUTES.GST_FILINGS_GSTR2}>
// //         <div className="tour_gstr1">
// //           <NavLink iconType="tags-o" linkTo={ROUTES.GST_FILINGS_GSTR2} linkText="GSTR-2"/>
// //         </div>
// //         </Item>



// <Item key={ROUTES.RECONCILE} className="recon">
//         <NavLink iconType="fork" linkTo={ROUTES.RECONCILE} linkText="S.M.A.R.T RECON"/>
//       </Item>