import React from "react";
import { Table, InputNumber, Input, Tag } from "antd";
const GSTR9Pt5Sec14 = props => {
  const columns = [
    {
      title: "Pt. V",
      dataIndex: "sno",
      key: "sno",
      width: "5%"
    },
    {
      title: "Description",
      dataIndex: "det",
      key: "det",
      width: "35%"
    },
    {
      title: "Payable (₹)",
      dataIndex: "txpyble",
      key: "txpyble",
      width: "30%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt5sec14-${record.key}-txpyble-tbl14`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Paid (₹)",
      dataIndex: "txpaid",
      key: "txpaid",
      width: "30%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt5sec14-${record.key}-txpaid-tbl14`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "14. Differential tax paid on account of declaration in table no. 10 & 11"
      }
      size="small"
      dataSource={props.pt5sec14.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt5Sec14;