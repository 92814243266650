/**
 * @flow
 */
import React from 'react'
import Modal from 'antd/lib/modal'
import Table from 'antd/lib/table'
import { formatNumber } from "../../shared/constants";
const { ColumnGroup, Column } = Table

type props = {
  isVisible: boolean,
  onCancel: Function,
  pr_isd_items: Array,
}

export default (props: props) =>
  <Modal title="Purchase Items" visible={props.isVisible} onCancel={props.onCancel} footer={null} width="90%">
    <Table dataSource={props.pr_isd_items} bordered rowKey="gstin" pagination={{ className: 'itc-items-pagination' }}>
      <ColumnGroup title="Amount of Tax">
        <Column title="Integrated GST" dataIndex="igst" key="igst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="Central GST" dataIndex="cgst" key="cgst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="State GST" dataIndex="sgst" key="sgst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="Cess" dataIndex="cess" key="cess" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC IGST" dataIndex="itc_igst" key="itc_igst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC CGST" dataIndex="itc_cgst" key="itc_cgst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC SGST" dataIndex="itc_sgst" key="itc_sgst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC CESS" dataIndex="itc_cess" key="itc_cess" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC ELIGIBLE" dataIndex="itc_eligible" key="itc_eligible" />
      </ColumnGroup>
      />
    </Table>
  </Modal>
