import { stateArray } from "../../shared/constants";
var moment = require('moment');
const {
  flow,
  map,
  flattenDeep,
  find,
  omit,
  groupBy,
  entries,
  pick,
  findIndex,
  toString,
  get,
  filter,
} = require("lodash/fp");

const formatData = {

  formatB2B: (b2b) => {
    console.log(b2b);
      const sr_b2b_masters = flow(
          map(d => {
            console.log(d);
              const inv = map(i => {
                console.log(i);
                return {
                  type: "srb2b",
                  invoice_number: i.inum,
                  invoice_date: i.idt,
                  invoice_value: parseFloat(i.val).toFixed(2),
                  atr: i.diff_percent ? i.diff_percent : null,
                  invoice_type: i.inv_typ,
                  pos_id: i.pos,
                  reverse_charge: i.rchrg,
                  status: d.cfs,
                  receiver_gstin: d.ctin,
                  items: map(inv_items => {
                    return {
                      gst_rate: inv_items.itm_det.rt,
                      taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                      igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                      cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                      sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                      cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                    };
                  })(i.itms)
                };
              })(d.inv);
              return inv;
          }),
          flattenDeep
      )(b2b.data.b2b);

    return sr_b2b_masters;
  },

  formatB2CL: (b2cl) => {
    console.log(b2cl);

    const sr_b2cl_masters = flow(
      map(d => {
        console.log(d);
          const inv = map(i => {
            console.log(i);
            return {
              type: "srb2cl",
              invoice_number: i.inum,
              invoice_date: i.idt,
              invoice_value: parseFloat(i.val).toFixed(2),
              atr: i.diff_percent ? i.diff_percent : null,
              pos_id: d.pos,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.itm_det.rt,
                  taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                  igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                  cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                };
              })(i.itms)
            };
          })(d.inv);
          return inv;
      }),
      flattenDeep
    )(b2cl.data.b2cl);

    return sr_b2cl_masters;

  },

  formatB2CS: (b2cs) => {
    console.log(b2cs);

    const sr_b2cs_masters = flow(
      map(d => {
        return {
          type: "srb2cs",
          invoice_value: parseFloat(d.val).toFixed(2),
          pos_id: d.pos,
          atr: d.diff_percent ? d.diff_percent : null,
          invoice_type: d.typ,
          supply_type: d.sply_ty,
          items:
            {
              gst_rate: d.rt,
              taxable_value: parseFloat(d.txval).toFixed(2),
              igst: d.iamt ? parseFloat(d.iamt).toFixed(2) : null,
              cgst: d.camt ? parseFloat(d.camt).toFixed(2) : null,
              sgst: d.samt ? parseFloat(d.samt).toFixed(2) : null,
              cess: d.csamt ? parseFloat(d.csamt).toFixed(2) : null
            }
        }
      }),
      flattenDeep
    )(b2cs.data.b2cs);

    return sr_b2cs_masters;

  },

  formatCDNR: (cdnr) => {
    console.log(cdnr);
      const sr_cdnr_masters = flow(
          map(d => {
            console.log(d);
              const inv = map(i => {
                console.log(i);
                return {
                  type: "srcdnr",
                  invoice_number: i.inum,
                  invoice_date: i.idt,
                  cdnr_value: parseFloat(i.val).toFixed(2),
                  pos_id: i.pos,
                  reverse_charge: i.rchrg,
                  status: d.cfs,
                  receiver_gstin: d.ctin,
                  cdnr_type: i.ntty,
                  cdnr_number: i.nt_num,
                  cdnr_date: i.nt_dt,
                  atr: i.diff_percent ? i.diff_percent : null,
                  pre_gst: i.p_gst,
                  items: map(inv_items => {
                    return {
                      gst_rate: inv_items.itm_det.rt,
                      taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                      igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                      cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                      sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                      cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                    };
                  })(i.itms)
                };
              })(d.nt);
              return inv;
          }),
          flattenDeep
      )(cdnr.data.cdnr);

    return sr_cdnr_masters;
  },
  formatCDNUR: (cdnur) => {
    console.log(cdnur);
      const sr_cdnur_masters = flow(
          map(d => {
            console.log(d);
                return {
                  type: "srcdnur",
                  invoice_number: d.inum,
                  invoice_date: d.idt,
                  cdnur_value: parseFloat(d.val).toFixed(2),
                  invoice_type: d.typ,
                  pos_id: d.pos,
                  reverse_charge: d.rchrg,
                  status: d.cfs,
                  cdnur_type: d.ntty,
                  cdnur_number: d.nt_num,
                  cdnur_date: d.nt_dt,
                  atr: d.diff_percent ? d.diff_percent : null,
                  pre_gst: d.p_gst,
                  items: map(inv_items => {
                    return {
                      gst_rate: inv_items.itm_det.rt,
                      taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                      igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                      cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                      sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                      cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                    };
                  })(d.itms)
                };

          }),
          flattenDeep
      )(cdnur.data.cdnur);

    return sr_cdnur_masters;
  },
  formatEXP: (exp) => {
    console.log(exp);
    const sr_exp_masters = flow(
      map(d => {
        console.log(d);
          const inv = map(i => {
            console.log(i);
            return {
              type: "srexport",
              invoice_number: i.inum,
              invoice_date: i.idt,
              invoice_value: parseFloat(i.val).toFixed(2),
              exp_type: d.exp_typ,
              sb_port_code: i.sbpcode,
              atr: i.diff_percent ? i.diff_percent : null,
              sb_number: i.sbnum,
              sb_date: i.sbdt,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.rt,
                  taxable_value: parseFloat(inv_items.txval).toFixed(2),
                  igst: inv_items.iamt ? parseFloat(inv_items.iamt).toFixed(2) : null,
                  cess: inv_items.csamt ? parseFloat(inv_items.csamt).toFixed(2) : null
                };
              })(i.itms)
            };
          })(d.inv);
          return inv;
      }),
      flattenDeep
    )(exp.data.exp);

    return sr_exp_masters;
  },
  formatAT: (at) => {
    console.log(at);
    const sr_at_masters = flow(
      map(d => {
        console.log(d);
            return {
              atr: d.diff_percent ? d.diff_percent : null,
              pos_id: d.pos,
              supply_type: d.sply_ty,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.rt,
                  gross_received: parseFloat(inv_items.ad_amt).toFixed(2),
                  igst: inv_items.iamt ? parseFloat(inv_items.iamt).toFixed(2) : null,
                  cgst: inv_items.iamt ? parseFloat(inv_items.camt).toFixed(2) : null,
                  sgst: inv_items.iamt ? parseFloat(inv_items.samt).toFixed(2) : null,
                  cess: inv_items.csamt ? parseFloat(inv_items.csamt).toFixed(2) : null
                };
              })(d.itms)
            };
      }),
      flattenDeep
    )(at.data.at);
    return sr_at_masters;
  },
  formatTXP: (atadj) => {
    console.log(atadj);
    const sr_atadj_masters = flow(
      map(d => {
        console.log(d);
            return {
              atr: d.diff_percent ? d.diff_percent : null,
              pos_id: d.pos,
              supply_type: d.sply_ty,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.rt,
                  gross_adjusted: parseFloat(inv_items.ad_amt).toFixed(2),
                  igst: inv_items.iamt ? parseFloat(inv_items.iamt).toFixed(2) : null,
                  cgst: inv_items.iamt ? parseFloat(inv_items.camt).toFixed(2) : null,
                  sgst: inv_items.iamt ? parseFloat(inv_items.samt).toFixed(2) : null,
                  cess: inv_items.csamt ? parseFloat(inv_items.csamt).toFixed(2) : null
                };
              })(d.itms)
            };
      }),
      flattenDeep
    )(atadj.data.txpd);
    return sr_atadj_masters;
  },
  formatB2BA: (b2ba) => {
    console.log(b2ba);
      const sr_b2ba_masters = flow(
          map(d => {
            console.log(d);
              const inv = map(i => {
                console.log(i);
                return {
                  type: "srb2ba",
                  invoice_number: i.inum,
                  invoice_date: i.idt,
                  o_invoice_number: i.oinum,
                  o_invoice_date: i.oidt,
                  invoice_value: parseFloat(i.val).toFixed(2),
                  atr: i.diff_percent ? i.diff_percent : null,
                  invoice_type: i.inv_typ,
                  pos_id: i.pos,
                  reverse_charge: i.rchrg,
                  status: d.cfs,
                  receiver_gstin: d.ctin,
                  items: map(inv_items => {
                    return {
                      gst_rate: inv_items.itm_det.rt,
                      taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                      igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                      cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                      sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                      cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                    };
                  })(i.itms)
                };
              })(d.inv);
              return inv;
          }),
          flattenDeep
      )(b2ba.data.b2ba);

    return sr_b2ba_masters;
  },

  formatB2CLA: (b2cla) => {
    console.log(b2cla);

    const sr_b2cla_masters = flow(
      map(d => {
        console.log(d);
          const inv = map(i => {
            console.log(i);
            return {
              type: "srb2cla",
              invoice_number: i.inum,
              invoice_date: i.idt,
              o_invoice_number: i.oinum,
              o_invoice_date: i.oidt,
              invoice_value: parseFloat(i.val).toFixed(2),
              atr: i.diff_percent ? i.diff_percent : null,
              pos_id: d.pos,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.itm_det.rt,
                  taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                  igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                  cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                };
              })(i.itms)
            };
          })(d.inv);
          return inv;
      }),
      flattenDeep
    )(b2cla.data.b2cla);

    return sr_b2cla_masters;

  },

  formatB2CSA: (b2csa) => {
    console.log(b2csa);

    const sr_b2csa_masters = flow(
      map(d => {
        console.log(d);
          const inv = map(i => {
            console.log(i);
            return {
              type: "srb2csa",
              original_month: i.omon,
              atr: i.diff_percent ? i.diff_percent : null,
              invoice_value: parseFloat(i.val).toFixed(2),
              supply_type: i.sply_ty,
              pos_id: i.pos,
              invoice_type: i.typ,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.itm_det.rt,
                  taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                  igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                  cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                  sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                  cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                };
              })(i.itms)
            };
          })(d.inv);
          return inv;
      }),
      flattenDeep
    )(b2csa.data.b2csa);

    return sr_b2csa_masters;

  },

  formatCDNRA: (cdnra) => {
    console.log(cdnra);
      const sr_cdnra_masters = flow(
          map(d => {
            console.log(d);
              const inv = map(i => {
                console.log(i);
                return {
                  type: "srcdnra",
                  invoice_number: i.inum,
                  invoice_date: i.idt,
                  cdnr_value: parseFloat(i.val).toFixed(2),
                  pos_id: i.pos,
                  reverse_charge: i.rchrg,
                  status: d.cfs,
                  receiver_gstin: d.ctin,
                  cdnr_type: i.ntty,
                  cdnr_number: i.nt_num,
                  cdnr_date: i.nt_dt,
                  o_cdnr_number: i.ont_num,
                  o_cdnr_date: i.ont_dt,
                  atr: i.diff_percent ? i.diff_percent : null,
                  pre_gst: i.p_gst,
                  items: map(inv_items => {
                    return {
                      gst_rate: inv_items.itm_det.rt,
                      taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                      igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                      cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                      sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                      cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                    };
                  })(i.itms)
                };
              })(d.nt);
              return inv;
          }),
          flattenDeep
      )(cdnra.data.cdnra);

    return sr_cdnra_masters;
  },
  formatCDNURA: (cdnura) => {
    console.log(cdnura);
      const sr_cdnura_masters = flow(
          map(d => {
            console.log(d);
                return {
                  type: "srcdnura",
                  invoice_number: d.inum,
                  invoice_date: d.idt,
                  cdnur_value: parseFloat(d.val).toFixed(2),
                  invoice_type: d.typ,
                  pos_id: d.pos,
                  reverse_charge: d.rchrg,
                  status: d.cfs,
                  cdnur_type: d.ntty,
                  cdnur_number: d.nt_num,
                  cdnur_date: d.nt_dt,
                  o_cdnr_number: d.ont_num,
                  o_cdnr_date: d.ont_dt,
                  atr: d.diff_percent ? d.diff_percent : null,
                  pre_gst: d.p_gst,
                  items: map(inv_items => {
                    return {
                      gst_rate: inv_items.itm_det.rt,
                      taxable_value: parseFloat(inv_items.itm_det.txval).toFixed(2),
                      igst: inv_items.itm_det.iamt ? parseFloat(inv_items.itm_det.iamt).toFixed(2) : null,
                      cgst: inv_items.itm_det.camt ? parseFloat(inv_items.itm_det.camt).toFixed(2) : null,
                      sgst: inv_items.itm_det.samt ? parseFloat(inv_items.itm_det.samt).toFixed(2) : null,
                      cess: inv_items.itm_det.csamt ? parseFloat(inv_items.itm_det.csamt).toFixed(2) : null
                    };
                  })(d.itms)
                };

          }),
          flattenDeep
      )(cdnura.data.cdnura);

    return sr_cdnura_masters;
  },
  formatEXPA: (expa) => {
    console.log(expa);
    const sr_expa_masters = flow(
      map(d => {
        console.log(d);
          const inv = map(i => {
            console.log(i);
            return {
              type: "srexport",
              invoice_number: i.inum,
              invoice_date: i.idt,
              o_invoice_number: i.oinum,
              o_invoice_date: i.oidt,
              invoice_value: parseFloat(i.val).toFixed(2),
              exp_type: d.exp_typ,
              sb_port_code: i.sbpcode,
              atr: i.diff_percent ? i.diff_percent : null,
              sb_number: i.sbnum,
              sb_date: i.sbdt,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.rt,
                  taxable_value: parseFloat(inv_items.txval).toFixed(2),
                  igst: inv_items.iamt ? parseFloat(inv_items.iamt).toFixed(2) : null,
                  cess: inv_items.csamt ? parseFloat(inv_items.csamt).toFixed(2) : null
                };
              })(i.itms)
            };
          })(d.inv);
          return inv;
      }),
      flattenDeep
    )(expa.data.expa);

    return sr_expa_masters;
  },
  formatATA: (ata) => {
    console.log(ata);
    const sr_ata_masters = flow(
      map(d => {
        console.log(d);
            return {
              atr: d.diff_percent ? d.diff_percent : null,
              pos_id: d.pos,
              supply_type: d.sply_ty,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.rt,
                  gross_received: parseFloat(inv_items.ad_amt).toFixed(2),
                  igst: inv_items.iamt ? parseFloat(inv_items.iamt).toFixed(2) : null,
                  cgst: inv_items.iamt ? parseFloat(inv_items.camt).toFixed(2) : null,
                  sgst: inv_items.iamt ? parseFloat(inv_items.samt).toFixed(2) : null,
                  cess: inv_items.csamt ? parseFloat(inv_items.csamt).toFixed(2) : null
                };
              })(d.itms)
            };
      }),
      flattenDeep
    )(ata.data.ata);
    return sr_ata_masters;
  },
  formatTXPA: (atadja) => {
    console.log(atadja);
    const sr_atadja_masters = flow(
      map(d => {
        console.log(d);
            return {
              atr: d.diff_percent ? d.diff_percent : null,
              pos_id: d.pos,
              supply_type: d.sply_ty,
              items: map(inv_items => {
                return {
                  gst_rate: inv_items.rt,
                  gross_adjusted: parseFloat(inv_items.ad_amt).toFixed(2),
                  igst: inv_items.iamt ? parseFloat(inv_items.iamt).toFixed(2) : null,
                  cgst: inv_items.iamt ? parseFloat(inv_items.camt).toFixed(2) : null,
                  sgst: inv_items.iamt ? parseFloat(inv_items.samt).toFixed(2) : null,
                  cess: inv_items.csamt ? parseFloat(inv_items.csamt).toFixed(2) : null
                };
              })(d.itms)
            };
      }),
      flattenDeep
    )(atadja.data.txpda);
    return sr_atadja_masters;
  },
  formatHSNSUM: (hsn) => {
    const sr_hsn_masters = flow(
      map(d => {
        console.log(d);
            return {
              hsn_sac_code: d.hsn_sc,
              hsn_sac_desc: d.desc,
              uqc_code: d.uqc,
              total_qty: d.qty,
              total_value : parseFloat(parseFloat(d.val).toFixed(2)),
              items: {
                taxable_value: parseFloat(d.txval).toFixed(2),
                igst: d.iamt ? parseFloat(d.iamt).toFixed(2) : null,
                cgst: d.camt ? parseFloat(d.camt).toFixed(2) : null,
                sgst: d.samt ? parseFloat(d.samt).toFixed(2) : null,
                cess: d.csamt ? parseFloat(d.csamt).toFixed(2) : null
                }

      }}),
      flattenDeep
    )(hsn.data.hsn.data);
    return sr_hsn_masters;
  },
  formatNIL: (nil) => {
    const sr_nil_masters = flow(
      map(d => {
        console.log(d);
            return {
              supply_type: d.sply_ty,
              nil_amt: d.nil_amt,
              expt_amt: d.expt_amt,
              ngsup_amt: d.ngsup_amt
      }}),
      flattenDeep
    )(nil.data.nil.inv);
    return sr_nil_masters;
  },
}

export default formatData;
