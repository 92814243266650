import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { FormItem } from '../../shared/utils'
import { ContentWrapper } from '../../shared/styles'

export default (props) =>
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR9}>
    <h2 style={{ textAlign: "center"}}>Details of Outward and inward supplies declared during the financial year</h2>
    <br />
    <br />
    <h3 style={{ marginLeft: "35"}} id="link1">4. Details of advances, inward and outward supplies on which tax is payable as declared in returns filed during the financial year</h3>
    <br />
    <br />
    <br />
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "100%", height: "100px" }}><h4><b>Nature Of Supplies</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Total Taxable Value</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Integrated Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Central Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>State/UT Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Cess</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>A)</b> Supplies made to un-registered persons (B2C)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_A_ttxv', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_A_iamt', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_A_camt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_A_samt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_A_cess', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td><b>B)</b> Supplies made to registered persons (B2B)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_B_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_B_iamt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_B_camt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_B_samt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_B_cess', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>C)</b> Zero rated supply (Export) on payment of tax (except supplies to SEZs)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_C_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_C_iamt', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_C_camt', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_C_samt', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_C_cess', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>D)</b> Supply to SEZs on payment of tax</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_D_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_D_iamt', {
               
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_D_camt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_D_samt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_D_cess', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>E)</b> Deemed Exports</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_E_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_E_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_E_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_E_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_E_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>F)</b> Advances on which tax has been paid but invoice has not been issued (not covered under (A) to (E) above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_F_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_F_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_F_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_F_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_F_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>G)</b> Inward supplies on which tax is to be paid on reverse charge basis</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_G_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_G_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_G_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_G_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_G_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>H)</b> Sub-total (A to G above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_H_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_H_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_H_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>

            <FormItem>
            {props.getFieldDecorator('four_H_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_H_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>I)</b> Credit Notes issued in respect of transactions specified in (B) to (E) above (-)</td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_I_ttxv', {
              
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_I_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_I_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_I_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_I_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>J)</b> Debit Notes issued in respect of transactions specified in (B) to (E) above (+)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_J_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
        
            <FormItem>
            {props.getFieldDecorator('four_J_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_J_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_J_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_J_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>K)</b> Supplies / tax declared through Amendments (+)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_K_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_K_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_K_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_K_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_K_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>L)</b> Supplies / tax reduced through Amendments (-)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_L_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_L_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_L_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_L_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_L_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>M)</b> Sub-total (I to L above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_M_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_M_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_M_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_M_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_M_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>N)</b> Supplies and advances on which tax is to be paid (H + M) above</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('four_N_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_N_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_N_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_N_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('four_N_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


    </tbody>
    </table>
    <br />
    <br />
    <h3 style={{ marginLeft: "35"}} id="link1">5. Details of Outward supplies on which tax is not payable as declared in returns filed during the financial year</h3>
    <br />
    <br />
    <br />
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "100%", height: "100px" }}><h4><b>Nature Of Supplies</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Total Taxable Value</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Integrated Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Central Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>State/UT Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Cess</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>A)</b> Zero rated supply (Export) without payment of tax</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_A_ttxv', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_A_iamt', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_A_camt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_A_samt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_A_cess', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td><b>B)</b> Supply to SEZs without payment of tax</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_B_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_B_iamt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_B_camt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_B_samt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_B_cess', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>C)</b> Supplies on which tax is to be paid by the recipient on reverse charge basis</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_C_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_C_iamt', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_C_camt', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_C_samt', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_C_cess', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>D)</b> Exempted</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_D_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_D_iamt', {
               
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_D_camt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_D_samt', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_D_cess', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>E)</b> Nil Rated</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_E_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_E_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_E_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_E_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_E_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>F)</b> Non-GST supply</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_F_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_F_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_F_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_F_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_F_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>G)</b> Sub-total (A to F above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_G_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_G_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_G_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_G_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_G_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>H)</b> Credit Notes issued in respect of transactions specified in A to F above (-)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_H_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_H_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_H_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>

            <FormItem>
            {props.getFieldDecorator('five_H_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_H_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>I)</b> Debit Notes issued in respect of transactions specified in A to F above (+)</td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_I_ttxv', {
              
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_I_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_I_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_I_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_I_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>J)</b> Supplies declared through Amendments (+)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_J_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
        
            <FormItem>
            {props.getFieldDecorator('five_J_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_J_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_J_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_J_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>K)</b> Supplies reduced through Amendments (-)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_K_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_K_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_K_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_K_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_K_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>L)</b> Sub-Total (H to K above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_L_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_L_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_L_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_L_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_L_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>M)</b> Turnover on which tax is not to be paid (G + L above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_M_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_M_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_M_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_M_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_M_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>N)</b> Total Turnover (including advances) (4N + 5M - 4G above)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('five_N_ttxv', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_N_iamt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_N_camt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_N_samt', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
            {props.getFieldDecorator('five_N_cess', {
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


    </tbody>
    </table>
  </Form>
  </ContentWrapper>

