import React from 'react'
import { Table, InputNumber, Input, Tag } from 'antd';
const GSTR9Pt2Sec5 = props => {
    const columns = [
      {
          title: 'Pt. II',
          dataIndex: 'sno',
          key: 'sno',
          width: '5%',
      },
      {
          title: '5. Details of Outward supplies made during the financial year on which tax is not payable',
          children: [
            {
              title: 'Nature of Supplies',
              dataIndex: 'det',
              key: 'det',
              width: '20%',
          },
          {
              title: 'Taxable Value (₹)',
              dataIndex: 'val',
              key: 'val',
              width: '15%',
              render: (text, record) => {
                return (text !== undefined) ? (record.key === "7" || record.key === "12" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (
                  <Input
                    id={`pt2sec5-${record.key}-val-tbl5`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'Integrated Tax (₹)',
              dataIndex: 'iamt',
              key: 'iamt',
              width: '15%',
              render: (text, record) => {
                return (text !== undefined) ? (record.key === "7" || record.key === "12" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (
                  <Input
                    id={`pt2sec5-${record.key}-iamt-tbl5`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'Central Tax (₹)',
              dataIndex: 'camt',
              key: 'camt',
              width: '15%',
              render: (text, record) => {
                return (text !== undefined) ? (record.key === "7" || record.key === "12" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (
                  <Input
                    id={`pt2sec5-${record.key}-camt-tbl5`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'State/UT Tax (₹)',
              dataIndex: 'samt',
              key: 'samt',
              width: '15%',
              render: (text, record) => {
                return (text !== undefined) ? (record.key === "7" || record.key === "12" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (
                  <Input
                    id={`pt2sec5-${record.key}-samt-tbl5`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'CESS (₹)',
              dataIndex: 'cess',
              key: 'cess',
              width: '15%',
              render: (text, record) => {
                return (text !== undefined) ? (record.key === "7" || record.key === "12" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (
                  <Input
                    id={`pt2sec5-${record.key}-cess-tbl5`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          }
          ]
      },
    ]
    return (
        <Table pagination={false} className={props.className} bordered title={() => "Details of Outward and Inward supplies declared during the financial year"} size="small" dataSource={props.pt2sec5.dataSource} columns={columns} />
    )
  }

  export default GSTR9Pt2Sec5