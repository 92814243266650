import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { FormItem } from '../../shared/utils'
import { ContentWrapper } from '../../shared/styles'

export default (props) =>
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR9}>
    <br />
    <br />
    <h3 style={{ marginLeft: "35"}} id="link1">8. Other ITC related information</h3>
    <br />
    <br />
    <br />
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "100%", height: "100px" }}><h4><b>Nature Of Supplies</b></h4></th>
        
        <th style={{ width: "70%" }}><h4><b>Integrated Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Central Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>State/UT Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Cess</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>A)</b> ITC as per GSTR-2A (Table 3 & 5 thereof)</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td><b>B)</b> ITC as per sum total of 6(B) and 6(H) above</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>C)</b> ITC on inward supplies (other than imports and inward supplies liable to reverse charge but includes services received from SEZs) received during 2017-18 but availed during April to September, 2018</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_b', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_e', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>D)</b> Difference [A-(B+C)]</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_b', {
               
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>E)</b> ITC available but not availed (out of D)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>F)</b> ITC available but ineligible (out of D)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>G)</b> IGST paid on import of goods (including supplies from SEZ)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>H)</b> IGST credit availed on import of goods (as per 6(E) above)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>I)</b> Difference (G-H)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>J)</b> ITC available but not availed on import of goods (Equal to I)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>K)</b> Total ITC to be lapsed in current financial year (E + F + J)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      
    </tbody>
    </table>
  </Form>
  </ContentWrapper>

