import React from "react";
import { Tabs, Icon } from 'antd';
import InsightsMonthly from './InsightsDashboardSALES'
import InsightsTimeline from './InsightsDashboardITC'
import InsightsStatistics from './InsightsStatistics'
import ProductWise from './ProductWise'
import PlaceOfSupply from './PlaceOfSupply'
import AdvancedAnalytics from './AdvancedAnalytics'
import TaxInsights from './TaxInsights'
import { Root, ContentWrapper as Content } from "../../shared/styles";
const TabPane = Tabs.TabPane;

class Insights_UI extends React.Component {
  
  render() {
    
    const maxWidth = window.innerWidth - 215 + "px";

    return (
      <div style={{ width: maxWidth}}>
        <Root>
          <Content>
          <Tabs defaultActiveKey="1">
            <TabPane tab={<span><Icon type="diff" />Timeline</span>} key="1">
              <InsightsTimeline />
            </TabPane>
            <TabPane tab={<span><Icon type="line-chart" />Customer Ranking</span>} key="3">
              <InsightsStatistics />
            </TabPane>
            <TabPane tab={<span><Icon type="line-chart" />Product Ranking</span>} key="4">
              <ProductWise />
            </TabPane>
            <TabPane tab={<span><Icon type="line-chart" />Demography Ranking</span>} key="5">
              <PlaceOfSupply />
            </TabPane>
            <TabPane tab={<span><Icon type="line-chart" />Advanced Analytics</span>} key="6">
              <AdvancedAnalytics />
            </TabPane>
            <TabPane tab={<span><Icon type="snippets" />Monthly</span>} key="2">
              <InsightsMonthly />
            </TabPane>
            <TabPane tab={<span><Icon type="snippets" />Tax Stats</span>} key="7">
              <TaxInsights />
            </TabPane>
          </Tabs>
          </Content>
        </Root>
        </div>
      
    );
  }
}

export default Insights_UI