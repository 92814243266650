import React from 'react'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Popover from 'antd/lib/popover'
import Tooltip from 'antd/lib/tooltip'
import Select from 'antd/lib/select'
import glamorous from 'glamorous'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { CompanyQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, withApollo } from 'react-apollo'
import map from 'lodash/fp/map'
import get from 'lodash/get'
import find from 'lodash/fp/find'
import NavMenu from '../NavMenu'
import { CenterAlignWrapper } from '../../../shared/styles'
import { ROUTES, LOCAL_STORAGE, stateArray } from '../../../shared/constants'
import Notifications from './Notifications'
import Auth from '../../../services/AuthService'
import { actions } from '../../../redux'
import { IconWrapperDiv, IconWrapper } from './Icon'
import { gspActions } from "../../../gspRedux";

const { Div } = glamorous
const Root = glamorous.div({ height: 47, display: 'flex', 'borderBottom':'solid 1px gainsboro'})
const SRow = glamorous(Row)({ margin: '15px 0', })
const col_margin = { margin: '-2px 0px 0px 0px' };
const col_margin1 = { margin: '0px 15px 0px 15px', paddingLeft: "42px" };
const NavMenuWrapper = glamorous.div({ '& .ant-menu-inline': {border: 0, minWidth: 200,},})

const Header = (props) => {
  console.log(props)
  return (
    <Root>
      {props.isMobile && <Popover visible={props.isMenuVisible} onVisibleChange={props.onVisibleChange} content={<NavMenuWrapper> <NavMenu /> </NavMenuWrapper>} trigger="click" placement="bottomRight"> <IconWrapper type={'bars'} /> </Popover>}
      <Div display="flex" flex="1" justifyContent="flex-end" marginRight="25px">
        {/*{props.company &&
          <CenterAlignWrapper style={{ marginRight: 10 }}>
             Role: {capitalize(props.company.role)} 
          </CenterAlignWrapper>}*/}
      <div className="tour_select_company" style={{ marginTop: '7px' }}>
        <CenterAlignWrapper>
          <Select showSearch style={{ minWidth: '200px' }} placeholder="Select Company" optionFilterProp="children" onSelect={props.changeSelectedCompany} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} defaultValue={props.cn}>
            {map(company =>
              <Select.Option key={company.id} value={company.id} gstin={company.gstin} gstnUN={company.gstnUserName} sub_type={company.sub_type}>
                {`${find({pos_id:company.gstin.substr(0,2)})(stateArray).short_name} - ${company.name}`}
                </Select.Option>
            )(props.companies)}
          </Select>
        </CenterAlignWrapper>
      </div>
        <Tooltip title="Profile" overlay="">
          <IconWrapperDiv onClick={props.profile}>
            <Icon type="user" />
          </IconWrapperDiv>
        </Tooltip>
        <Tooltip title="Logout" overlay="">
          <IconWrapperDiv onClick={props.logout}>
            <Icon type="logout" />
          </IconWrapperDiv>
        </Tooltip>
      </Div>
    </Root>
  )
}

type state = {
  isMenuVisible: false,
}

type cProps = {
  history:
  {
    listen: Function,
    push: Function,
  },
  isMobile: boolean,
  companies: Array,
  changeSelectedCompany: Function,
  client:
  {
    resetStore: Function,
  },
}

class HeaderContainer extends React.Component
{
  state: state
  props: cProps
  constructor(props)
  {
    super(props)
    this.state = {
      cn:localStorage.getItem(LOCAL_STORAGE.CN),
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.companies){
      const selectedCompany = find({id:this.state.cn})(this.props.companies)
      if(selectedCompany){
        console.log(this.state.selectedCompany)
        if(this.state.selectedCompany === undefined) {
          this.changeSelectedCompany(selectedCompany.id,{props: {
            gstin: selectedCompany.gstin,
            gstnUN: selectedCompany.gstnUserName,
            sub_type: selectedCompany.sub_type
          }})
        }
      }
    }
  }

 
  onVisibleChange = () => {
    this.setState(prevState => ({
      isMenuVisible: !prevState.isMenuVisible,
    }))
  }

  logout = async () => {
    await Auth.logout()
    this.props.client.resetStore()
    this.props.history.push(ROUTES.LOGIN)
  }

  profile = () => {
    this.props.history.push(ROUTES.COMPANY)
  }

  changeSelectedCompany = (name,option) => {
    const gstin = option.props.gstin;
    const sub_type = option.props.sub_type;
    var d = new Date();
    var n = d.getMonth() + 1;
    var y = d.getYear() + 1900;

    localStorage.setItem(LOCAL_STORAGE.CN, name)
    this.props.changeSelectedCompany(
      name, 
      gstin, 
      );
      this.props.changeCompany(sub_type)
    this.props.setUsername(option.props.gstnUN);
    this.setState({ selectedCompany: name, selectedGSTIN: gstin });
  }

  render() {
    return (
      <Header
        {...this.props}
        isMenuVisible={this.state.isMenuVisible}
        onVisibleChange={this.onVisibleChange}
        getTaxPeriod={this.getTaxPeriod}
        getToTaxPeriod={this.getToTaxPeriod}
        getFinYear={this.getFinYear}
        finMonth={this.state.finMonth}
        finYear={this.state.finYear}
        logout={this.logout}
        profile={this.profile}
        changeSelectedCompany={this.changeSelectedCompany}
        cn={this.state.cn}
        
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    sub_type: state.local.sub_type
  }
}

const companyQueryConfig = {
  options: { variables: {} },
  props: ({ data }) => ({ companies: get(data, 'viewer.companies.edges'), finMonth: '1', finYear: '2016-17' }),
}

const companyByIdQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
  props: ({ data }) => {
    return {
      company: get(data, 'viewer.company'),
      finMonth: '1',
      finYear: '2016-17'
    }
  },
  skip: props => !props.selectedCompany,
}

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps, {
    changeSelectedCompany: actions.changeSelectedCompany,
    changeCompany:actions.changeCompany,
    setUsername: gspActions.setUsername
  }),
  graphql(gql(CompanyQuery), companyQueryConfig),
  graphql(gql(CompanyByIdQuery), companyByIdQueryConfig)
)(HeaderContainer)

export { Header }