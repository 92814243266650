import React from "react";
import { Form, InputNumber, Icon, Button, Row, Col, Select } from "antd";
import filter from "lodash/fp/filter";

const FormItem = Form.Item;
let uuid = 0;

class ThreePointTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formItems: props.queryFields ? props.queryFields : []
    };
  }
  remove = (k, index) => {
    // console.log(k);
    // console.log(index);
    // console.log(this.state.formItems);
    // console.log(this.props.inputRef.names);
    // console.log(filter(d => {
    //   return d.id !== k.id;
    // })(this.state.formItems))
    // console.log(k.id);
    // console.log(this.props.inputRef.names[index]);
    // this.props.inputRef.names = [];

    this.props.inputRef.splice(index, 1);
    const formItems = this.state.formItems.slice();
    this.setState({ formItems: [] }, function() {
      this.setState({
        formItems: filter(d => {
          return d.id !== k.id;
        })(formItems)
      });
    });

    // this.setState({
    //   formItems: filter(d => {
    //     return d.id !== k.id;
    //   })(this.state.formItems)
    // });
  };

  add = () => {
    this.setState({ formItems: this.state.formItems.concat({ id: uuid++ }) });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.queryFields !== prevProps.queryFields) {
      this.setState({ formItems: this.props.queryFields });
    }
  }

  render() {
    const { getFieldDecorator } = this.props;

    const formItems = this.state.formItems.map((k, index) => {
      console.log(k);
      return (
        <Row>
          <Col span={8}>
            <FormItem required={false}>
              {getFieldDecorator(`names${index}0`, {
                initialValue: k.sup_made ? k.sup_made : "",
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please Select the Supplies made DropDown."
                  }
                ]
              })(
                <Select
                  style={{ width: "99%" }}
                  ref={c => this.props.insertThreePointTwoData(index, 0, c)}
                >
                  <Select.Option value="smup">
                    Supplies made to Unregistered Persons
                  </Select.Option>
                  <Select.Option value="smcp">
                    Supplies made to Composition Taxable Persons
                  </Select.Option>
                  <Select.Option value="smuh">
                    Supplies made to UIN holders
                  </Select.Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem required={false}>
              {getFieldDecorator(`names${index}1`, {
                initialValue: k.pos ? k.pos : "",
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input Place of Supply."
                  }
                ]
              })(
                <Select
                  style={{ width: "99%" }}
                  ref={c => this.props.insertThreePointTwoData(index, 1, c)}
                >
                  <Select.Option value="">---Select State---</Select.Option>
                  <Select.Option value="35">
                    Andaman and Nicobar Islands
                  </Select.Option>
                  <Select.Option value="37">Andhra Pradesh</Select.Option>
                  <Select.Option value="12">Arunachal Pradesh</Select.Option>
                  <Select.Option value="18">Assam</Select.Option>
                  <Select.Option value="10">Bihar</Select.Option>
                  <Select.Option value="04">Chandigarh</Select.Option>
                  <Select.Option value="22">Chhattisgarh</Select.Option>
                  <Select.Option value="26">
                    Dadra and Nagar Haveli
                  </Select.Option>
                  <Select.Option value="25">Daman and Diu</Select.Option>
                  <Select.Option value="07">Delhi</Select.Option>
                  <Select.Option value="30">Goa</Select.Option>
                  <Select.Option value="24">Gujarat</Select.Option>
                  <Select.Option value="06">Haryana</Select.Option>
                  <Select.Option value="02">Himachal Pradesh</Select.Option>
                  <Select.Option value="01">Jammu and Kashmir</Select.Option>
                  <Select.Option value="20">Jharkhand</Select.Option>
                  <Select.Option value="29">Karnataka</Select.Option>
                  <Select.Option value="32">Kerala</Select.Option>
                  <Select.Option value="31">Lakshadweep</Select.Option>
                  <Select.Option value="23">Madhya Pradesh</Select.Option>
                  <Select.Option value="27">Maharashtra</Select.Option>
                  <Select.Option value="14">Manipur</Select.Option>
                  <Select.Option value="17">Meghalaya</Select.Option>
                  <Select.Option value="15">Mizoram</Select.Option>
                  <Select.Option value="13">Nagaland</Select.Option>
                  <Select.Option value="21">Odisha</Select.Option>
                  <Select.Option value="34">Puducherry</Select.Option>
                  <Select.Option value="03">Punjab</Select.Option>
                  <Select.Option value="08">Rajasthan</Select.Option>
                  <Select.Option value="11">Sikkim</Select.Option>
                  <Select.Option value="33">Tamil Nadu</Select.Option>
                  <Select.Option value="36">Telangana</Select.Option>
                  <Select.Option value="16">Tripura</Select.Option>
                  <Select.Option value="05">Uttaranchal</Select.Option>
                  <Select.Option value="09">Uttar Pradesh</Select.Option>
                  <Select.Option value="19">West Bengal</Select.Option>
                  <Select.Option value="97">Other Territory</Select.Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem required={false}>
              {/* {getFieldDecorator(`names[${index}][2]`, {
                initialValue: k.txval ? k.txval : 0.0
              })( */}
              <InputNumber
                ref={c => this.props.insertThreePointTwoData(index, 2, c)}
                step={0.01}
                onFocus={this.props.onFocusEvent}
                onBlur={this.props.onBlurEvent}
                style={{ width: "99%" }}
                id={`names${index}2`}
                formatter={this.props.formatNumber}
                defaultValue={k.txval ? k.txval : 0.0}
              />
              {/* )} */}
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem required={false}>
              {/* {getFieldDecorator(`names[${index}][3]`, {
                initialValue: k.iamt ? k.iamt : 0.0
              })( */}
              <InputNumber
                ref={c => this.props.insertThreePointTwoData(index, 3, c)}
                step={0.01}
                onFocus={this.props.onFocusEvent}
                onBlur={this.props.onBlurEvent}
                style={{ width: "90%" }}
                id={`names${index}3`}
                formatter={this.props.formatNumber}
                defaultValue={k.iamt ? k.iamt : 0.0}
              />
              {/* )} */}
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => this.remove(k, index)}
              />
            </FormItem>
          </Col>
        </Row>
      );
    });

    return (
      <div style={{ marginLeft: "8%" }}>
        <Row>
          <Col span={8}>
            <b>Supplies Made</b>
          </Col>
          <Col span={6}>
            <b>Place Of Supply</b>
          </Col>
          <Col span={4}>
            <b>Total Taxable Value</b>
          </Col>
          <Col span={6}>
            <b>Amount of Integrated Tax</b>
          </Col>
        </Row>
        <Row>
          <Col>{formItems}</Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col>
            <Button 
              style={{
                    backgroundColor: "#fff",
                    fontSize: "13px",
                    color: "#000"
                  }} 
                  onClick={this.add}>
              <Icon type="plus" /> Add field
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ThreePointTwo;
