import React from "react";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";
import Table from "antd/lib/table";
import Row from "antd/lib/row";
import Input from "antd/lib/input";
import Popconfirm from "antd/lib/popconfirm";
import find from "lodash/fp/find";
import moment from "moment";
import { stateArray } from "../../shared/constants";
import { StickyContainer } from 'react-sticky';
import Tabs from "antd/lib/tabs";
import ViewB2BSupplierData from './ViewB2BSupplierData';
import ViewB2BASupplierData from './ViewB2BASupplierData';
import ViewCDNRSupplierData from './ViewCDNRSupplierData';
import ViewCDNRASupplierData from './ViewCDNRASupplierData';
const { TabPane } = Tabs;

const { Column } = Table;
const scroll = { x: 1600 };

class ViewSupplierData extends React.Component {
  render() {
    const props = this.props;
    const maxHeight = Math.floor((window.innerHeight - 250) / 83);
    return (
      <Modal
        title="Supplier Data Only (2A)"
        visible={props.visibleCP_Modal}
        onCancel={props.hideCPModal}
        footer={null}
        width="96%"
      >
        <StickyContainer>
          <Tabs defaultActiveKey="1" size="medium" >
            <TabPane tab="B2B" key="1">
              <ViewB2BSupplierData {...props} />
            </TabPane>
            <TabPane tab="CDNR" key="2">
              <ViewCDNRSupplierData {...props}/>
            </TabPane>
            <TabPane tab="B2BA" key="3">
              <ViewB2BASupplierData {...props}/>
            </TabPane>
            <TabPane tab="CDNRA" key="4">
              <ViewCDNRASupplierData {...props} />
            </TabPane>
          </Tabs>
        </StickyContainer>
        <Button
          icon="file-excel"
          style={{
            backgroundColor: "#2382c7",
            color: "white"
          }}
          onClick={() => props.cpExcelDownload()}
        >
          Download as Excel
              </Button>

        <Popconfirm
          placement="bottomRight"
          onVisibleChange={props.popupConfirmResetVisibleChange_CP}
          visible={props.popupConfirmReset_CP}
          title="Clicking this button will reset all Supplier for mentioned Tax Period!"
          onConfirm={props.resetCPData}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon="close-circle"
            style={{
              backgroundColor: "#923011",
              color: "white"
            }}
          >
            {"Reset Supplier data for " + props.finYear}
          </Button>
        </Popconfirm>
      </Modal>
    );
  }
}
export default ViewSupplierData;
