import React from "react";
import { Table, InputNumber, Input, Tag } from "antd";
const GSTR9Pt6Sec16 = props => {
  const columns = [
    {
      title: "Pt. VI",
      dataIndex: "sno",
      key: "sno",
      width: "5%"
    },
    {
      title: "Details",
      dataIndex: "det",
      key: "det",
      width: "20%"
    },
    {
      title: "Taxable Value (₹)",
      dataIndex: "val",
      key: "val",
      width: "15%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec16-${record.key}-val-tbl16`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Integrated Tax (₹)",
      dataIndex: "iamt",
      key: "iamt",
      width: "15%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec16-${record.key}-iamt-tbl16`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Central Tax (₹)",
      dataIndex: "camt",
      key: "camt",
      width: "15%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec16-${record.key}-camt-tbl16`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "State/UT Tax (₹)",
      dataIndex: "samt",
      key: "samt",
      width: "15%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec16-${record.key}-samt-tbl16`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "CESS (₹)",
      dataIndex: "cess",
      key: "cess",
      width: "15%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec16-${record.key}-cess-tbl16`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "16. Supplies received from Composition taxpayers, deemed supply by job worker and goods sent on approval basis"
      }
      size="small"
      dataSource={props.pt6sec16.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt6Sec16