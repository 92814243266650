import React from "react";
import Modal from "antd/lib/modal";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import moment from "moment";

const { Column } = Table;
const scroll = { x: 1600 };

class Reconcile_CDNA_PurchaseOnlyDetails extends React.Component {
  render() {
    const props = this.props;
    const maxHeight = Math.floor((window.innerHeight - 250) / 83);
    return (
      <Modal
        title="Only in Purchase Register, but not in GST System (CDNA)"
        visible={props.visibleOneModal_cdna}
        onCancel={props.hideOneModal_cdna}
        footer={null}
        width="96%"
      >
        <Table
          dataSource={props.dataset_popup_details}
          bordered
          rowSelection={props.rowSelection}
          rowKey="id"
          title={() => (
            <span style={{ marginLeft: 8 }}>
              {props.hasSelected
                ? `Selected ${props.selectedRowKeys.length} Invoices`
                : ""}
            </span>
          )}
          footer={() => (
            <div>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#2382c7",
                  color: "white",
                }}
                onClick={() => props.fetchMissingNames(this.props.m_id)}
              >
                Fetch missing Supplier Name(s)
              </Button>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#921c26",
                  color: "white",
                }}
                onClick={() => props.markAsIneligible()}
              >
                Mark as Ineligible
              </Button>
            </div>
          )}
          pagination={{
            className: "my-pagination",
            defaultPageSize: maxHeight,
          }}
        >
          <Column
            title="GSTIN"
            dataIndex="supplier_gstin"
            className="alignCenter"
            key="supplier_gstin"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
                <div className="custom-filter-dropdown">
                  <Input
                    placeholder="Search GSTIN Number"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={props.handleSearch(selectedKeys, confirm)}
                  />
                  <Button
                    type="primary"
                    onClick={props.handleSearch(selectedKeys, confirm)}
                  >
                    Search
                </Button>
                  <Button
                    id="gstnSearch_opr_cdna"
                    onClick={props.handleReset(clearFilters)}
                  >
                    Reset
                </Button>
                </div>
              )}
            filterIcon={(filtered) => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa",
                }}
              />
            )}
            onFilter={(value, record) =>
              record.supplier_gstin.toLowerCase().includes(value.toLowerCase())
            }
            render={(text) => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                          props.searchText.toLowerCase() ? (
                            <span key={i} className="highlight">
                              {fragment}
                            </span>
                          ) : (
                            fragment
                          ) // eslint-disable-line
                    )}
                </span>
              ) : (
                  text
                );
            }}
          />
          <Column
            title="Supplier Name"
            dataIndex="supplier_name"
            className="alignCenter"
            key="supplier_name"
          />

          <ColumnGroup title="Original Details">
            <Column title="Note Number" dataIndex="og_note_num"></Column>
            <Column title="Note Date" dataIndex="og_note_dt"></Column>
          </ColumnGroup>
          <ColumnGroup title="Revised Details">
            <Column
              title="Note Type"
              dataIndex="note_type"
              className="alignCenter"
              key="note_type"
              render={val => (
                <p>{val === "C" ? "Credit" : val === "D" ? "Debit" : ""}</p>
              )}
            />

            <Column
              title="Note Number"
              dataIndex="note_num"
              className="alignCenter"
              key="note_num"
              filterDropdown={({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
              }) => (
                  <span>
                    <div className="custom-filter-dropdown">
                      <Input
                        placeholder="Search CDNA Number"
                        value={selectedKeys[0]}
                        onChange={e =>
                          setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={props.handleSearch(selectedKeys, confirm)}
                      />
                      <Button
                        type="primary"
                        onClick={props.handleSearch(selectedKeys, confirm)}
                      >
                        Search
                  </Button>
                      <Button
                        id="likelyCDNASearch_cdna"
                        onClick={props.handleReset(clearFilters)}
                      >
                        Reset
                  </Button>
                    </div>
                  </span>
                )}
              filterIcon={filtered => (
                <Icon
                  type="search"
                  style={{
                    color: filtered ? "#108ee9" : "#aaa"
                  }}
                />
              )}
              onFilter={(value, record) =>
                record.cdna_number.toLowerCase().includes(value.toLowerCase())
              }
              render={text => {
                return props.searchText ? (
                  <span>
                    {text
                      .split(
                        new RegExp(
                          `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                          "i"
                        )
                      )
                      .map(
                        (fragment, i) =>
                          fragment.toLowerCase() ===
                            props.searchText.toLowerCase() ? (
                              <span key={i} className="highlight">
                                {fragment}
                              </span>
                            ) : (
                              fragment
                            ) // eslint-disable-line
                      )}
                  </span>
                ) : (
                    text
                  );
              }}
            />
            <Column
              title="Note Date"
              dataIndex="note_dt"
              className="alignCenter"
              key="note_dt"
            // defaultSortOrder='descend'
            // sorter={(a, b) => props.sortDate(a.cdna_date, b.cdna_date)}
            // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
            />
            <Column
              title="Invoice Number"
              dataIndex="invoice_number"
              className="alignCenter"
              key="invoice_number"
              filterDropdown={({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => (
                  <div className="custom-filter-dropdown">
                    <Input
                      placeholder="Search Invoice Number"
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={props.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                      type="primary"
                      onClick={props.handleSearch(selectedKeys, confirm)}
                    >
                      Search
                </Button>
                    <Button
                      id="prInvoiceSearch_cdna"
                      onClick={props.handleReset(clearFilters)}
                    >
                      Reset
                </Button>
                  </div>
                )}
              filterIcon={(filtered) => (
                <Icon
                  type="search"
                  style={{
                    color: filtered ? "#108ee9" : "#aaa",
                  }}
                />
              )}
              onFilter={(value, record) =>
                record.invoice_number.toLowerCase().includes(value.toLowerCase())
              }
              render={(text) => {
                return props.searchText ? (
                  <span>
                    {text
                      .split(
                        new RegExp(
                          `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                          "i"
                        )
                      )
                      .map(
                        (fragment, i) =>
                          fragment.toLowerCase() ===
                            props.searchText.toLowerCase() ? (
                              <span key={i} className="highlight">
                                {fragment}
                              </span>
                            ) : (
                              fragment
                            ) // eslint-disable-line
                      )}
                  </span>
                ) : (
                    text
                  );
              }}
            />

            <Column
              title="Invoice Date"
              dataIndex="invoice_date"
              className="alignCenter"
              key="invoice_date"
              sorter={(a, b) => props.sortDate(a.invoice_date, b.invoice_date)}
              // defaultSortOrder='descend'
              render={(text) =>
                text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
              }
            />
            <Column
              title="Note Value"
              dataIndex="note_val"
              className="alignCenter"
              key="note_val"
              render={(val) => (
                <p>{props.formatNumber(val === null ? 0 : val)}</p>
              )}
            />
          </ColumnGroup>
          {/* <Column
              title="Pre-GST"
              dataIndex="pre_gst"
              className="alignCenter"
              key="pre_gst"
            /> */}

          <Column
            title="Items"
            key="action"
            className="alignCenter"
            rowSpan="2"
            render={(text, record, index) => {
              var but = (
                <Row gutter={24}>
                  <Col span="8">
                    <Button
                      icon="bars"
                      type="primary"
                      onClick={() => props.showItemModal(record)}
                    >
                      Items
                    </Button>
                    <Modal
                      title="List Items Summary - Purchase Register Invoices (CDNA)"
                      visible={
                        props.visibleItemModal === record.id ? true : false
                      }
                      onCancel={props.hideItemModal}
                      footer={null}
                      width="920"
                    >
                      <Table
                        dataSource={record.pr_cdna_items}
                        bordered
                        loading={props.loading}
                        scroll={scroll}
                        pagination={false}
                      >
                        <Column
                          title="Rate"
                          dataIndex="gst_rate"
                          className="alignCenter"
                          key="gst_rate"
                        />
                        <Column
                          title="Taxable Value"
                          className="alignCenter"
                          dataIndex="taxable_value"
                          key="taxable_value"
                          render={(val) => (
                            <p>{props.formatNumber(val === null ? 0 : val)}</p>
                          )}
                        />
                        <Column
                          title="IGST"
                          dataIndex="igst"
                          className="alignCenter"
                          key="igst"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="CGST"
                          dataIndex="cgst"
                          className="alignCenter"
                          key="cgst"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="SGST"
                          dataIndex="sgst"
                          className="alignCenter"
                          key="sgst"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="Cess"
                          dataIndex="cess"
                          className="alignCenter"
                          key="cess"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                      </Table>
                    </Modal>
                  </Col>
                </Row>
              );
              return but;
            }}
          />
          <Column
            title="Contact"
            key="contact"
            className="alignCenter"
            render={(text, record, index) => {
              var but = (
                <Row gutter={24}>
                  <Col span="8">
                    <Button
                      icon="mail"
                      style={{
                        backgroundColor: "#148a5a",
                        color: "white",
                      }}
                      onClick={() => props.notifyViaEmail_CDNA(record)}
                    >
                      {" "}
                      Email{" "}
                    </Button>
                  </Col>{" "}
                </Row>
              );
              return but;
            }}
          />
        </Table>
      </Modal>
    );
  }
}
export default Reconcile_CDNA_PurchaseOnlyDetails;
