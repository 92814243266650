import {
    ContactUpdateMutation,
    CompanyByIdQuery,
    ContactByIdQuery,
} from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import get from 'lodash/fp/get'
import Upload_Contacts from './Upload_Contacts'
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        selectedCompany: state.local.selectedCompany,
    };
};

const companyQueryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
        },
    }),
}

const queryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            contact_id: get('match.params.id', props)
        },
    }),
    props: ({ data }) => {
        const contact = get('viewer.contact_by_id', data);
        return {
            contact,
            isEdit: true,
        }
    },
}

const contactUpdateConfig = {
    props: ({ mutate, ownProps }) => {
        return {
            mutate: values => {
                return mutate({
                    variables: {
                        id: get('match.params.id', ownProps),
                        ...values,
                    },
                    refetchQueries: [
                        {
                            query: gql(ContactUpdateMutation),
                        },
                    ],
                })
            },
        }
    },
}
export default compose(
    connect(mapStateToProps),
    graphql(gql(CompanyByIdQuery), companyQueryConfig),
    graphql(gql(ContactByIdQuery), queryConfig),
    graphql(gql(ContactUpdateMutation), contactUpdateConfig),
)(Upload_Contacts)
