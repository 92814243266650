import React from "react";
import { Anchor, Layout, Form } from "antd";
import Table from 'antd/lib/table';
import Sider from "antd/lib/layout/Sider";
import Button from 'antd/lib/button';
import Col from 'antd/lib/col'
import glamorous from 'glamorous'
import { Root, ContentWrapper as Content } from "../../shared/styles";
import ButtonGroup from "antd/lib/button/button-group";
import Row from 'antd/lib/row';
import { Tabs } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';

const { ColumnGroup, Column } = Table;
const { Link } = Anchor;
const SRow = glamorous(Row)({ margin: '15px 0', })
const { TabPane } = Tabs;

const latefee = [
    {
        sno: 1,
        desc: "Interest and late fee due to late filing of return for the tax period",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
    {
        sno: 2,
        desc: "Late reporting of documents of previous tax periods",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
    {
        sno: 3,
        desc: "Rejection of accepted documents by the recipient",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
    {
        sno: 4,
        desc: "Interest on account of reversal of input tax credit",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
    {
        sno: 5,
        desc: "Interest on account of late reporting of supplies attracting reverse charge",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
    {
        sno: 6,
        desc: "Other interest liability",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
    {
        sno: 7,
        desc: "Total [sum of 1 to 6]",
        txval: 3000,
        igst: 200,
        cgst: 100,
        sgst: 100,
        cess: 50,
        cgst_lt: 300,
        sgst_lt: 300,
    },
]


const ReturnOne6 = props => {
    return (
        <Root>
            <Content>
                <h2>6. Interest and late fee liability details</h2>
                <Table dataSource={latefee} bordered title={() => '6. Interest and late fee liability details'}>
                    <Column title="Sr No." dataIndex="sno"></Column>
                    <Column title="Description" dataIndex="desc"></Column>
                    <ColumnGroup title="Interest (₹)">
                        <Column title='Integrated tax' dataIndex='igst' />
                        <Column title='Central tax' dataIndex='cgst' />
                        <Column title='State/UT tax' dataIndex='sgst' />
                        <Column title='Cess' dataIndex='cess' />
                    </ColumnGroup>
                    <ColumnGroup title="Late fee (₹)">
                        <Column title='Central tax' dataIndex='cgst_lt' />
                        <Column title='State/UT tax' dataIndex='sgst_lt' />
                    </ColumnGroup>
                </Table>
            </Content>
        </Root>
    )
}

class ReturnOne6Container extends React.Component {

    render() {
        return (
            <ReturnOne6 />
        )
    }
}
export default ReturnOne6Container;