import React from 'react'
import g from 'glamorous'
import { Card, Button } from 'antd'
import { ContentWrapper } from '../../shared/styles'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../shared/constants'
import { NavMenu } from "./NavMenu";
import { Header } from "./Header/index";

const add_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.15', padding: '0 15px', fontSize: '12px', borderRadius: '4px', height: '28px', MozUserSelect: 'none', position: 'relative', color: '#fff', backgroundColor: 'rgb(35, 130, 199)', borderColor: '#d9d9d9', paddingTop: '5px'};

const Content = g(ContentWrapper)({
  margin: '20px 20px',
})

class SelectCompanyError extends React.Component {
    state = {
    run: false
  };



  handleClickStart = e => {
    e.preventDefault();

    this.setState({
      run: true
    });
  };


  render() {
    const { run } = this.state;

    return (
  <Content>
    <Card title="Welcome to ElixirGST" extra={
          <Link style={add_button} to={ROUTES.COMPANY_ADD}>Add<span> Company</span></Link>
        }>
      <p>
        Please select your company from dropdown at top of this page.
      </p>
    </Card>
    <br />
    <br />
    <Button onClick={this.handleClickStart}>Click here for a guided tour of ElixirGST Platform!</Button>
  </Content>
)}

}

export default SelectCompanyError
