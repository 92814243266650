import React from 'react'
import Glamorous from "glamorous";
import Modal from 'antd/lib/modal'
import { Form, Row, Col, Input, Button, Icon } from 'antd'
import { Radio } from 'antd';
import message from 'antd/lib/message'
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import flow from "lodash/fp/flow";
import pick from "lodash/fp/pick";
import { gspActions } from "../../../gspRedux";
import get from "lodash/fp/get";
import toString from "lodash/fp/toString";

import gspAuth from '../../../services/GSPService'
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";

const B = Glamorous(Button)({
  width: "100%"
});

const ProfileSelModal = (props) => {
  // console.log(props)
  return (
    <Modal title="Profile Updation" footer={null} visible={props.isVisible} onCancel={props.onCancel} width="90%">
      <div>
        <Form onSubmit={props.submitProfileSelection}>
          <Row gutter="8">
            <Col span="2">Sr. No.</Col>
            <Col span="14"><b>Description</b></Col>
            <Col span="8"><b>Option</b></Col>
          </Row>
          <Row gutter="8">
            <Col span="2">1</Col>
            <Col span="14">Was your aggregate turnover during the preceding financial year upto Rs. 5.00 Cr.?</Col>
            <Col span="8">
              <Form.Item>
                  {/* {
                    props.form.getFieldDecorator('fiveCrRadio', {
                      rules: [{ required: true, message: 'Please select an option' }],
                    })
                      ()
                  } */}
                  <Radio.Group name='fiveCrRadio' 
                      disabled={props.disable5CrRadio}                
                      value={props.value5CrRadio} 
                      onChange={props.onChange5CrRadio}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                  </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter="8">
            <Col span="2">2</Col>
            <Col span="14">If reply is ‘Yes’ at Sr. No. 1, do you intend to file return on quarterly basis?</Col>
            <Col span="8">
              <Form.Item>
                  {/* {
                    props.form.getFieldDecorator('quarterlyRadio')
                      ()
                  } */}
                  <Radio.Group name='quarterlyRadio'                         
                          disabled={props.disableIntendQuarterlyRadio}
                          value={props.valueIntendQuartelyRadio}
                          onChange={props.onChangeIntendQuarterlyRadio}>
                              <Radio value={1}>Yes</Radio>
                              <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
            </Col>
          </Row>
          <Row gutter="8">
            <Col span="2">3</Col>
            <Col span="14">If reply is ‘Yes” at Sr. No. 2, choose your return - </Col>
            <Col span="8">
              <Row>
                  <Form.Item>
                    <Radio.Group name='ssqnRadio'
                          disabled={props.disableSsqnRadio}
                          value={props.valueSsqnRadio}
                          onChange={props.onChangeSsqnRadio}
                          >
                              <Radio value={1}>Sahaj</Radio>
                              <Radio value={2}>Sugam</Radio>
                              <Radio value={3}>Quarterly Normal</Radio>
                    </Radio.Group>
                  </Form.Item>
              </Row>
            </Col>
          </Row>
          <Row gutter="16">
            <Col span="8"></Col>
            <Col span="4"><B type="primary" size="large" htmlType="submit">Submit Profile</B></Col>
            <Col span="4"><B style={{ backgroundColor: 'grey', color: 'white'}} size="large" onClick={props.onCancel}>Cancel</B></Col>
            <Col span="8"></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}

//HARD CORDINGS ARE STILL PRESENT
class ProfileSelModalContainer extends React.Component {
  constructor(props) {
      super(props)      
      this.callGSPProfileAPI = this.callGSPProfileAPI.bind(this)      
      this.submitProfileSelection = this.submitProfileSelection.bind(this)

      this.disableRadiosOn5CrChange = this.disableRadiosOn5CrChange.bind(this)
      this.disableRadioOnIntendQuarterlyChange = this.disableRadioOnIntendQuarterlyChange.bind(this)

      this.onChange5CrRadio = this.onChange5CrRadio.bind(this)
      this.onChangeIntendQuarterlyRadio = this.onChangeIntendQuarterlyRadio.bind(this)  
      this.onChangeSsqnRadio = this.onChangeSsqnRadio.bind(this)

      this.setValue5CrRadioAndChangeOtherRadios = this.setValue5CrRadioAndChangeOtherRadios.bind(this)
      this.setValueIntendQuarterlyRadioAndChangeOtherRadios = this.setValueIntendQuarterlyRadioAndChangeOtherRadios.bind(this)
      this.setValueSsqnRadioAndChangeOtherRadios = this.setValueSsqnRadioAndChangeOtherRadios.bind(this)

      this.state = {
        disable5CrRadio:false, disableIntendQuarterlyRadio:true, disableSsqnRadio:true,
        value5CrRadio:null, valueIntendQuartelyRadio:null, valueSsqnRadio:null
            
      }
  }

  componentDidUpdate(prevProps) {

    //console.log("componentDidUpdate before return")
    if (this.props.company === prevProps.company) return;
    if(!this.props.company) return;
    //console.log("componentDidUpdate after return")
    this.callGSPProfileAPI(this.props)
  }  

  onChange5CrRadio(e){
    this.setValue5CrRadioAndChangeOtherRadios(e.target.value)
    this.disableRadiosOn5CrChange(e.target.value) 
  }
 
  onChangeIntendQuarterlyRadio(e){
    this.setValueIntendQuarterlyRadioAndChangeOtherRadios(e.target.value)
    this.disableRadioOnIntendQuarterlyChange(e.target.value)
  }

  onChangeSsqnRadio(e){
    this.setValueSsqnRadioAndChangeOtherRadios(e.target.value)
  }

  setValue5CrRadioAndChangeOtherRadios = val => {
    this.setState({     
      value5CrRadio: val, valueIntendQuartelyRadio: null, valueSsqnRadio:null
    })
  }

  setValueIntendQuarterlyRadioAndChangeOtherRadios = val =>{
    this.setState({
      valueIntendQuartelyRadio: val, valueSsqnRadio:null
    })
  }

  setValueSsqnRadioAndChangeOtherRadios = val =>{
    this.setState({
      valueSsqnRadio: val      
    })
  }

  disableRadiosOn5CrChange(val){
    if(val==1){
      this.setState({ disableIntendQuarterlyRadio:false, disableSsqnRadio:false})
    }
    else{     
      this.setState({ disableIntendQuarterlyRadio:true, disableSsqnRadio:true})
    }    
  }

  disableRadioOnIntendQuarterlyChange(val){
    if(val==1){
      this.setState({ disableSsqnRadio:false})
    }
    else{     
      this.setState({ disableSsqnRadio:true})
    }    
  }


  async callGSPProfileAPI(props) {

    const gsp = companyDetails => {        
        if (props.gspDetails !== undefined && props.gspDetails !== null) {
          return {
            companyDetails,
            gsp: {
              access_token: props.gspDetails.gspToken,
              otp: props.gspDetails.otp,
              response: null
            }
          }
        } 
        return {companyDetails};
    }  
 
    const otherGSTNInformation = companyDetails => {
      const state_cd = companyDetails["gstin"].substr(0, 2);
      companyDetails["state"] = state_cd;
      return companyDetails;
    };

    //HARDCODING HERE
    const prepareReqData = ({companyDetails, gsp}) => {

          
          const financialYear = "2019-20";
          const financialMonth = "12";

          const year_trim = financialYear.split("-");
            var fy = "";
            if (financialMonth < 4) {
              fy = "20" + year_trim[1];
            } else {
              fy = year_trim[0];
            }
          
          const period = toString(financialMonth).padStart(2, "0") + "" + fy;
        
          const reqData1 = {
            companyDetails: companyDetails,
            gspDetails: {
              gspToken: gsp.access_token,
              otp: gsp.otp
            },
            period: null// current period set in nodejs before sending to gsp
          };

      return reqData1
    }

    //HARD CODING HERE
    //Take current FY, profile value latest by sorting stdt, enddt
    const setRadios = gspResponse => {
        //const val5CrRadio = gspResponse.data.prohis["201920"]["0"].profile
        
        //const val5CrRadio = "MN"
        //const val5CrRadio = "SJ"
        //const val5CrRadio = "SM"
        const val5CrRadio = "QN"
        
        if(val5CrRadio=="MN"){
          this.setValue5CrRadioAndChangeOtherRadios(1)
          this.setValueIntendQuarterlyRadioAndChangeOtherRadios(0)
          this.disableRadiosOn5CrChange(1)
          this.disableRadioOnIntendQuarterlyChange(0)
        } 
        else if(val5CrRadio=="SJ" || val5CrRadio=="SM" || val5CrRadio=="QN"){
            this.setValue5CrRadioAndChangeOtherRadios(1)
            this.setValueIntendQuarterlyRadioAndChangeOtherRadios(1)   
            this.disableRadiosOn5CrChange(1)
            this.disableRadioOnIntendQuarterlyChange(1)       
          
            if(val5CrRadio=="SJ"){            
              this.setValueSsqnRadioAndChangeOtherRadios(1)
            } 
            else if(val5CrRadio=="SM"){           
              this.setValueSsqnRadioAndChangeOtherRadios(2)
            } 
            else if(val5CrRadio=="QN"){
              this.setValueSsqnRadioAndChangeOtherRadios(3)
            }  
        }
        
    }
      
    const reqData = flow(      
      pick(["gstin", "id", "gstnUserName"]),
      otherGSTNInformation,
      gsp,
      prepareReqData       
    )(props.company);

    gspAuth.getNewFormsProfile(reqData)
      .then(response=> {        
          if(response.success==true){            
            setRadios(response)
          }
          else{
            message.error('Unable to fetch your profile, please try again later',5)
          }        
      })
      .catch(excep => 
        message.error('problem fetching your profile, please try again later',5)
      )
  }

  async submitProfileSelection(e) {
    e.preventDefault();    

    const gsp = companyDetails => {        
      if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
        return {
          companyDetails,
          gsp: {
            access_token: this.props.gspDetails.gspToken,
            otp: this.props.gspDetails.otp,
            response: null
          }
        }
      } 
      return {companyDetails};
    }  

    const otherGSTNInformation = companyDetails => {
      const state_cd = companyDetails["gstin"].substr(0, 2);
      companyDetails["state"] = state_cd;
      return companyDetails;
    };

    //HARDCODING HERE
    const preparePeriod = ({companyDetails, gsp}) => {
          const financialYear = "2019-20";
          const financialMonth = "12";

          const year_trim = financialYear.split("-");
            var fy = "";
            if (financialMonth < 4) {
              fy = "20" + year_trim[1];
            } else {
              fy = year_trim[0];
            }
          
          //const period = toString(financialMonth).padStart(2, "0") + "" + fy;
          const period=null;

      return {companyDetails, gsp, period}
    }

    //HARDCODING HERE
    const prepareReqData = ({companyDetails, gsp, period}) =>{
          const reqData1 = {
            companyDetails: companyDetails,
            gspDetails: {
              gspToken: gsp.access_token,
              otp: gsp.otp
            },
            period: null,// current period set in nodejs before sending to gsp
            body: {
              "gstin": companyDetails.gstin,
              "rtnprd": period,
              profile: "SJ"//this.state.valueSsqnRadio //change this based on requirement
            }
          };
          return reqData1
    }

    const reqData = flow(      
      pick(["gstin", "id", "gstnUserName"]),
      otherGSTNInformation,
      gsp,
      preparePeriod,
      prepareReqData       
    )(this.props.company);

    /*
    message.success(" upto 5Cr " + this.state.value5CrRadio)
    message.success(" Intend Quarterly" + this.state.valueIntendQuartelyRadio)
    message.success(" SSQN " + this.state.valueSsqnRadio)
    */

    gspAuth.submitNewFormsProfile(reqData);

    //this.props.onCancel();

    message.success("Profile saved successfully", 5)

    //this.props.form.validateFields(async (err, values) => {
      //if (err) return;
      //gspAuth.submitNewFormsProfile({})
      // const result = values;//await Auth.register(values);
      // console.log(result.fiveCrRadio);
      
    //});
  }
  
  render() {
    return (
      <ProfileSelModal 
          onChange5CrRadio={this.onChange5CrRadio}
          onChangeIntendQuarterlyRadio={this.onChangeIntendQuarterlyRadio}
          onChangeSsqnRadio={this.onChangeSsqnRadio}

          value5CrRadio={this.state.value5CrRadio}
          valueIntendQuartelyRadio={this.state.valueIntendQuartelyRadio}
          valueSsqnRadio={this.state.valueSsqnRadio}

          disable5CrRadio={this.state.disable5CrRadio}
          disableIntendQuarterlyRadio={this.state.disableIntendQuarterlyRadio}
          disableSsqnRadio={this.state.disableSsqnRadio}

          {...this.props}  
          
          submitProfileSelection={this.submitProfileSelection.bind(this)} />
    )
  }
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {    
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  Form.create(),
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(ProfileSelModalContainer)