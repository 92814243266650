import {
  CompanyUpdateMutation,
  CompanyByIdQuery,
  CompanyQuery,
} from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import get from 'lodash/fp/get'
import CompanyForm from './CompanyForm'

const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: get('match.params.id', props),
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}
const companyUpdateConfig = {
  props: ({ mutate, ownProps }) => {
    return {
      mutate: values => {
        return mutate({
          variables: {
            id: get('match.params.id', ownProps),
            ...values,
          },
          refetchQueries: [
            {
              query: gql(CompanyQuery),
            },
          ],
        })
      },
    }
  },
}
export default compose(
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
  graphql(gql(CompanyUpdateMutation), companyUpdateConfig)
)(CompanyForm)
