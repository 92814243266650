import React from 'react'
import Col from 'antd/lib/col'
import { ContentWrapper, MarginedRow as Row } from '../../shared/styles'
import Glamorous from 'glamorous'

const Content = Glamorous(ContentWrapper)({
  margin: 8,
})

const alignRight = { textAlign: 'right' }
export default props =>
  <Content>
    <Row gutter={24} type="flex" align="middle">
      <Col span={12} style={{ textAlign: 'center' }}>
        <b>GSTIN: </b>&nbsp;{props.company.gstin}
      </Col>
      <Col span={12} style={{ textAlign: 'center' }}>
        <b>Company Name: </b>&nbsp;{props.company.name}
      </Col>
    </Row>
  </Content>
