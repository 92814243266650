import { stateArray } from "../../shared/constants";
var moment = require('moment');
const {
  flow,
  map,
  flattenDeep,
  find,
  omit,
  groupBy,
  entries,
  pick,
  findIndex,
  toString,
  get,
  filter,
  reduce
} = require("lodash/fp");

const deleteData = {

  formatDeletePayload: (data, action) => {
    console.log(data);
    console.log(action);

    if(action === 'b2b'){

      const b2barray = flow(
        map(inv => ({
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          val: parseFloat(inv.invoice_value),
          pos: inv.pos_id.substring(0, 2),
          etin: inv.receiver_gstin,
          rchrg: inv.reverse_charge,
          inv_typ: inv.invoice_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          flag: "D",
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'B2B' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          inv: map(omit('etin'))(r[1]),
        }))
      )(data);
      console.log(b2barray);
      return b2barray;

    }else if(action === 'b2cl'){

      const b2clarray = flow(
        map(inv => ({
          pos: inv.pos_id.substring(0, 2),
          inv:
            [{
              flag: "D",
              inum: inv.invoice_number,
              idt: inv.invoice_date,
              inv_typ: inv.invoice_type,
              diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
              val: parseFloat(inv.invoice_value),
              itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'B2CL' }),
            }],
        })),
      )(data)
      console.log(b2clarray);
      return b2clarray;

    }else if(action === 'b2cs'){

      const b2csarray = flow(
        map(inv => {
          var itemsArray = [];
          itemsArray.push(inv.items);
          const InvoiceItems = map(InvoiceItems => {
           return {
             flag: "D",
              sply_ty: inv.supply_type,
              typ: inv.invoice_type,
              diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
              pos: inv.pos_id.substring(0, 2),
              txval: ((InvoiceItems.taxable_value === null || InvoiceItems.taxable_value === undefined || InvoiceItems.taxable_value === '') ? 0 : parseFloat(parseFloat(InvoiceItems.taxable_value).toFixed(2))),
              rt: ((InvoiceItems.gst_rate === null || InvoiceItems.gst_rate === undefined || InvoiceItems.gst_rate === '') ? 0 : parseFloat(parseFloat(InvoiceItems.gst_rate).toFixed(2))),
              iamt: ((InvoiceItems.igst === null || InvoiceItems.igst === undefined || InvoiceItems.igst === '') ? 0 : parseFloat(parseFloat(InvoiceItems.igst).toFixed(2))),
              camt: ((InvoiceItems.cgst === null || InvoiceItems.cgst === undefined || InvoiceItems.cgst === '') ? 0 : parseFloat(parseFloat(InvoiceItems.cgst).toFixed(2))),
              samt: ((InvoiceItems.sgst === null || InvoiceItems.sgst === undefined || InvoiceItems.sgst === '') ? 0 : parseFloat(parseFloat(InvoiceItems.sgst).toFixed(2))),
              csamt: ((InvoiceItems.cess === null || InvoiceItems.cess === undefined || InvoiceItems.cess === '') ? 0 : parseFloat(parseFloat(InvoiceItems.cess).toFixed(2))),
              etin: inv.ecom_gstin === null ? '' : inv.ecom_gstin
           }
        })(itemsArray)
        return InvoiceItems;
        }),
        flattenDeep
      )(data)

      console.log(b2csarray);
      return b2csarray;

    }else if(action === 'cdnr'){

      const cdnrarray = flow(
        map(inv => ({
          flag: "D",
          ntty: inv.cdnr_type,
          nt_num: inv.cdnr_number,
          nt_dt: inv.cdnr_date,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.cdnr_value),
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          etin: inv.receiver_gstin,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'CDNR' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          nt: map(omit('etin'))(r[1]),
        }))
      )(data)

      console.log(cdnrarray);

      return cdnrarray;

    }else if(action === 'cdnur'){

      const cdnurarray = flow(
        map(inv => ({
          flag: "D",
          typ: inv.invoice_type,
          ntty: inv.cdnur_type,
          nt_num: inv.cdnur_number,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          nt_dt: inv.cdnur_date,
          /*rsn: inv.cdnur_reason_id,*/
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          val: parseFloat(inv.cdnur_value),
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'CDNUR' }),
        })),
      )(data)

      console.log(cdnurarray);

      return cdnurarray;
    }else if(action === 'exp'){

      const exparray = reduce((c,n) => {
        const inv = n;
        const inv_details = {
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          flag: "D",
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.invoice_value),
          sbpcode: inv.sb_port_code === null ? undefined : inv.sb_port_code,
          sbnum: inv.sb_number === null ? undefined : inv.sb_number,
          sbdt: inv.sb_date === null ? undefined : inv.sb_date,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'EXP'  }),
        }
        const expGroup = find({exp_typ: inv.exp_type})(c.exp);
        if(expGroup === undefined) {
          c.exp.push({exp_typ: inv.exp_type, inv: [inv_details]})
        }else {
          expGroup.inv.push(inv_details);
        }
        return c;
      },{"exp":[]})(data);

      console.log(exparray);

      return exparray;

    }else if(action === 'at'){
      const atarray = flow(
        map(inv => ({
          flag: "D",
          pos: inv.pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'AT' }),
        })),
      )(data)

      console.log(atarray);
      return atarray;
    }else if(action === 'txp'){
      const atadjarray = flow(
        map(inv => ({
          flag: "D",
          pos: inv.pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'ATADJ' }),
        })),
      )(data)

      console.log(atadjarray);
      return atadjarray;
    }else if(action === 'hsn'){

      var hsnSerialNo = 0;

      const hsnarray = flow(
        map(inv => {
          hsnSerialNo = hsnSerialNo + 1;
          return {
            flag: "D",
            num: hsnSerialNo,
            hsn_sc: inv.hsn_sac_code,
            desc: inv.hsn_sac_desc,
            uqc: inv.uqc_code,
            qty: parseFloat(inv.total_qty),
            val: parseFloat(parseFloat(inv.total_value).toFixed(2)),
            txval: inv.sr_hsn_items.map(mapSalesItem, { type: 'TXVAL' })[0],
            iamt: inv.sr_hsn_items.map(mapSalesItem, { type: 'IGST' })[0],
            csamt: inv.sr_hsn_items.map(mapSalesItem, { type: 'CSGST' })[0],
            camt: inv.sr_hsn_items.map(mapSalesItem, { type: 'CGST' })[0],
            samt: inv.sr_hsn_items.map(mapSalesItem, { type: 'SGST' })[0]
          }
        }),
      )(data)

      console.log(hsnarray);

      return hsnarray;

    }else if(action === 'nil'){
      const nilarray = flow(
        map(inv => ({
          sply_ty: inv.supply_type.split("-")[0].trim(),
          nil_amt: inv.nil_amt ? parseFloat(inv.nil_amt) : 0,
          expt_amt: inv.expt_amt ? parseFloat(inv.expt_amt) : 0,
          ngsup_amt: inv.ngsup_amt ? parseFloat(inv.ngsup_amt) : 0,

        })),
      )(data)
      console.log(nilarray);
      return nilarray;
    }else if(action === 'b2ba'){

      const b2baarray = flow(
        map(inv => ({
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          oinum: inv.o_invoice_number,
          oidt: inv.o_invoice_date,
          val: parseFloat(inv.invoice_value),
          pos: inv.pos_id.substring(0, 2),
          etin: inv.receiver_gstin,
          rchrg: inv.reverse_charge,
          inv_typ: inv.invoice_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          flag: "D",
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'B2BA' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          inv: map(omit('etin'))(r[1]),
        }))
      )(data);
      console.log(b2baarray);
      return b2baarray;

    }else if(action === 'b2cla'){

      const b2claarray = flow(
        map(inv => ({
          pos: inv.pos_id.substring(0, 2),
          inv:
            [{
              flag: "D",
              oinum: inv.o_invoice_number,
              oidt: inv.o_invoice_date,
              inum: inv.invoice_number,
              idt: inv.invoice_date,
              inv_typ: inv.invoice_type,
              diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
              val: parseFloat(inv.invoice_value),
              itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'B2CLA' }),
            }],
        })),
      )(data)
      console.log(b2claarray);
      return b2claarray;

    }else if(action === 'b2csa'){

      const b2csaarray = flow(
        map(inv => ({
          omon:  inv.original_month,
          sply_ty: inv.supply_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          typ: inv.invoice_type,
          pos: inv.r_pos_id.substring(0, 2),
          etin: inv.ecom_gstin === null ? '' : inv.ecom_gstin,
          itms: inv.sr_b2csa_items.map(mapSalesItems, { length: inv.sr_b2csa_items.length, type: 'B2CSA' }),
        })),
      )(data)

      console.log(b2csaarray);
      return b2csaarray;

    }else if(action === 'cdnra'){

      const cdnraarray = flow(
        map(inv => ({
          flag: "D",
          ntty: inv.cdnr_type,
          nt_num: inv.cdnr_number,
          nt_dt: inv.cdnr_date,
          ont_num: inv.o_cdnr_number,
          ont_dt: inv.o_cdnr_date,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.cdnr_value),
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          etin: inv.receiver_gstin,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'CDNRA' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          nt: map(omit('etin'))(r[1]),
        }))
      )(data)

      console.log(cdnraarray);

      return cdnraarray;

    }else if(action === 'cdnura'){

      const cdnuraarray = flow(
        map(inv => ({
          flag: "D",
          typ: inv.invoice_type,
          ntty: inv.cdnur_type,
          nt_num: inv.cdnur_number,
          ont_num: inv.o_cdnur_number,
          ont_dt: inv.o_cdnur_date,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          nt_dt: inv.cdnur_date,
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          val: parseFloat(inv.cdnur_value),
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'CDNURA' }),
        })),
      )(data)

      console.log(cdnuraarray);

      return cdnuraarray;
    }else if(action === 'expa'){

      const expaarray = reduce((c,n) => {
        const inv = n;
        const inv_details = {
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          oinum: inv.o_invoice_number,
          oidt: inv.o_invoice_date,
          flag: "D",
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.invoice_value),
          sbpcode: inv.sb_port_code === null ? undefined : inv.sb_port_code,
          sbnum: inv.sb_number === null ? undefined : inv.sb_number,
          sbdt: inv.sb_date === null ? undefined : inv.sb_date,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'EXPA'  }),
        }
        const expGroup = find({exp_typ: inv.exp_type})(c.exp);
        if(expGroup === undefined) {
          c.exp.push({exp_typ: inv.exp_type, inv: [inv_details]})
        }else {
          expGroup.inv.push(inv_details);
        }
        return c;
      },{"exp":[]})(data);

      console.log(expaarray);

      return expaarray;

    }else if(action === 'ata'){
      const ataarray = flow(
        map(inv => ({
          flag: "D",
          omon: inv.original_month,
          pos: inv.pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'ATA' }),
        })),
      )(data)

      console.log(ataarray);
      return ataarray;
    }else if(action === 'txpa'){
      const atadjaarray = flow(
        map(inv => ({
          flag: "D",
          omon: inv.original_month,
          pos: inv.pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.items.map(mapSalesItems, { length: inv.items.length, type: 'ATADJA' }),
        })),
      )(data)

      console.log(atadjaarray);
      return atadjaarray;
    }

    function mapSalesItems(item)
    {
      if ((this.type == 'B2B') || (this.type == 'B2CL') || (this.type == 'CDNR') || (this.type == 'CDNUR') || (this.type == 'B2BA') || (this.type == 'B2CLA') || (this.type == 'CDNRA') || (this.type == 'CDNURA'))
      {
        var rate_value = parseInt(parseInt(item.gst_rate) + '0' + this.length);
        if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN'))
        {
          return {
            num: rate_value,
            itm_det:
            {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
              iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
        else if ((parseFloat(item.cgst).toFixed(2) != NaN) && (parseFloat(item.cgst).toFixed(2) != null) && (parseFloat(item.cgst).toFixed(2) != 'NaN'))
        {
          return {
            num: rate_value,
            itm_det:
            {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
              camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
              samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
      } else if ((this.type == 'B2CSA'))
      {

        if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN'))
        {
          return {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
              iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
          }
        }
        else if ((parseFloat(item.cgst).toFixed(2) != NaN) && (parseFloat(item.cgst).toFixed(2) != null) && (parseFloat(item.cgst).toFixed(2) != 'NaN'))
        {
          return {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
              camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
              samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
          }
        }
      }
      else if ((this.type == "AT") || (this.type == "ATADJ") || (this.type == "ATA") || (this.type == "ATADJA"))
      {
        if ((this.type == "AT") || (this.type == "ATA"))
        {

          if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN')) {
            return {
                  ad_amt: (item.gross_received === null || item.gross_received === undefined || item.gross_received === '') ? 0 : parseFloat(parseFloat(item.gross_received).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//rate
                  iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
          else {
            return {
                  ad_amt: (item.gross_received === null || item.gross_received === undefined || item.gross_received === '') ? 0 : parseFloat(parseFloat(item.gross_received).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
                  camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
                  samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
        else
        {

          if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN')) {
            return {
                  ad_amt: (item.gross_adjusted === null || item.gross_adjusted === undefined || item.gross_adjusted === '') ? 0 : parseFloat(parseFloat(item.gross_adjusted).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//rate
                  iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
          else {
            return {
                  ad_amt: (item.gross_adjusted === null || item.gross_adjusted === undefined || item.gross_adjusted === '') ? 0 : parseFloat(parseFloat(item.gross_adjusted).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
                  camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
                  samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
      }else if ((this.type == 'EXP') || (this.type == 'EXPA')){
        return {
          txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
          rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
          iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)),
          csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
        }
      }
    }
    function mapSalesItem(item)
    {
      //console.log("--------------------------------item-----------------------------")
      //console.log(item)
      //console.log("--------------------------------item-----------------------------")
      if (this.type == 'TXVAL')
      {
        return item.taxable_value === null ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2))
      }
      else if (this.type == "RT")
      {
        return item.gst_rate === null ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2))
      }
      else if (this.type == 'IGST')
      {
        return item.igst === null ? 0 : parseFloat(parseFloat(item.igst).toFixed(2))
      }
      else if (this.type == "CGST")
      {
        return item.cgst === null ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2))
      }
      else if (this.type == "SGST")
      {
        return item.sgst === null ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2))
      }
      else if (this.type == "CSGST")
      {
        return item.cess === null ? 0 : parseFloat(parseFloat(item.cess).toFixed(2))
      }
    }

  }

}


export default deleteData;
