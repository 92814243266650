import React from 'react'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import get from 'lodash/fp/get'
import { PR_ISD_PurchaseQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PrISDItems from './PR_ISD_Items'
import { formatNumber } from "../../shared/constants";
import { Root, ContentWrapper as Content } from '../../shared/styles'
import moment from 'moment'
const { ColumnGroup, Column } = Table

const PurchaseRegister = props => {
  function compareByAlph(a, b) {
    if (a < b) {
      return -1;
    } if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
  return (
    <Root>
      <Content>
        <Table dataSource={props.pr_isd_masters} bordered rowKey="id" loading={props.loading} title={() => 'ISD Purchases'} pagination={{ className: 'my-pagination' }}>
        <Column title="Supplier GSTIN" dataIndex="supplier_gstin" key="supplier_gstin" />
          <Column title="Supplier Name" dataIndex="supplier_name" key="supplier_name" />
          <ColumnGroup title="Invoice Details">
            <Column title="No." dataIndex="invoice_number" key="invoice_number" sorter={(a, b) => compareByAlph(a.invoice_number, b.invoice_number)} />
            <Column title="Date" dataIndex="invoice_date" key="invoice_date" render={text => moment(text, "DD-MM-YYYY").format('DD/MM/YYYY')}/>
          </ColumnGroup>
          <ColumnGroup title="HSN Details">
            <Column title="Code" dataIndex="sac_code" key="sac_code" />
            <Column title="Description" dataIndex="sac_desc" key="sac_desc" />
          </ColumnGroup>
          <Column title="Items" key="pr_isd_items"
            render={(text, record) => {
              return (
                <Tooltip title="View Item(s)">
                  <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                    {record.pr_isd_items.pageInfo.total} Item(s)

                  </Button>
                  <PrISDItems isVisible={props.visibleModal === record.id} onCancel={props.hideModal} pr_isd_items={record.pr_isd_items.edges}/>
                </Tooltip>
              )
            }}
          />
        </Table>
      </Content>
    </Root>
  )
}

class PurchaseRegisterContainer extends React.Component {
  constructor(props) {
    super(props)
    console.log(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year =  curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n+1,
      finYear: year,
      curFinMonth: n+1,
      curFinYear: year,
    }
    this.props.data.refetch()
  }
  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }
  changeSelectedFinancialMonth = name => {
    this.props.changeSelectedFinancialMonth(name)
  }
  render() {
    var tableLoading = false;
    if (this.props.data.networkStatus === 4) { tableLoading = false; }
    else if (this.props.data.networkStatus === 7) { tableLoading = false; }
    else if (this.props.data.networkStatus === 1) { tableLoading = true; }
    return (
      <PurchaseRegister
        pr_isd_masters={get('viewer.pr_isd_masters.edges')(this.props.data)}
        role={get('company.role', this.props)}
        finMonth={this.state.finMonth}
		    finYear={this.state.finYear}
        loading={tableLoading}
        formatNumber={formatNumber}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
        isSR={this.props.isSR}
      />
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      // isSR: props.isSR,
      type: 'prisd',
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}
const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}
export default compose(
  connect(mapStateToProps),
  graphql(gql(PR_ISD_PurchaseQuery), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(PurchaseRegisterContainer)
