import React from "react";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import CheckBox from "antd/lib/checkbox";
import Radio from "antd/lib/radio";
import InputNumber from "antd/lib/input-number";
import RadioGroup from "antd/lib/radio/group";

class ReconcileAdvanceModal extends React.Component {
  render() {
    const props = this.props;
    return (
      <Modal
        title="Advanced Reconcilation"
        okText="Reconcile"
        width="34%"
        visible={props.showModalPrompt}
        onOk={props.compareSales}
        okButtonProps={{ type: "success" }}
        onCancel={props.handleCancelPrompt}
      >
        <div>
          <Row>
            <style>
              {`
        .ant-checkbox-group-item {
          display:block;
        }
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin:0;
        }
      `}
            </style>

            <div style={{ marginTop: "10px" }}>

              <RadioGroup
                onChange={props.onCheckSelect2Aor2B}  >

                <Radio value={1}>2A</Radio>
                <Radio value={2}>2B</Radio>
              </RadioGroup>

              {/* <Option
                    indeterminate={props.indeterminate_Select2Aor2B}
                    onChange={props.onCheckSelect2Aor2B}
                    checked={props.check_2Aor2B_b2b}
                  >
                    2A
                  </Option> */}
              {/* <CheckBox
                    indeterminate={props.indeterminate_Select2Aor2B}
                    onChange={props.onCheckSelect2Aor2B}
                    checked={props.check_2Aor2B_b2b}
                  >
                    2B
                  </CheckBox> */}
            </div>
            <div style={{ marginTop: "10px" }}>
              <Col span={12}>
                <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                  <p style={{ textAlign: "left" }}>
                    <b>B2B</b>
                  </p>
                  <br />




                  <CheckBox
                    indeterminate={props.indeterminate_b2b}
                    onChange={props.onCheckAllChange_b2b}
                    checked={props.checkAll_b2b}
                  >
                    Select all
                  </CheckBox>

                  <br />
                  <br />
                  <CheckBox.Group
                    options={props.plainOptions_b2b}
                    value={props.checkedList_b2b}
                    onChange={props.onChangeCB_b2b}
                  >
                    <CheckBox value="Invoice Date">Invoice Date</CheckBox>
                    <CheckBox value="Invoice Value">Invoice Value</CheckBox>
                    <CheckBox value="Taxable Value">Taxable Value</CheckBox>
                    <CheckBox value="Taxes (IGST, CGST, SGST, CESS)">
                      Taxes (IGST, CGST, SGST, CESS)
                    </CheckBox>
                  </CheckBox.Group>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                  <p style={{ textAlign: "left" }}>
                    <b>CDNR</b>
                  </p>
                  <br />
                  <CheckBox
                    indeterminate={props.indeterminate_cdnr}
                    onChange={props.onCheckAllChange_cdnr}
                    checked={props.checkAll_cdnr}
                  >
                    Select all
                  </CheckBox>
                  <br />
                  <br />
                  <CheckBox.Group
                    options={props.plainOptions_cdnr}
                    value={props.checkedList_cdnr}
                    onChange={props.onChangeCB_cdnr}
                  >
                    <CheckBox value="CDNR Value">CDNR Value</CheckBox>
                    <CheckBox value="CDNR Date">CDNR Date</CheckBox>
                    <CheckBox value="Taxable Value">Taxable Value</CheckBox>
                    <CheckBox value="Taxes (IGST, CGST, SGST, CESS)">
                      Taxes (IGST, CGST, SGST, CESS)
                    </CheckBox>
                  </CheckBox.Group>
                </div>
              </Col>
            </div>
          </Row>
          <br />
          <p>
            <b>Roundoff</b>
          </p>
          <br />
          <div>
            <Row>
              <Col>
                <Radio.Group
                  defaultValue="1"
                  buttonStyle="solid"
                  onChange={(e) => props.onRoundOffChange(e)}
                >
                  <Radio.Button value="0">Exact</Radio.Button>
                  <Radio.Button value="1">Roundoff to ₹ 1</Radio.Button>
                  <Radio.Button value="n">Enter Value</Radio.Button>
                </Radio.Group>
                <InputNumber
                  id="roundOffInput"
                  style={{ marginLeft: "5px" }}
                  disabled={props.disableRoundOffInput}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ReconcileAdvanceModal;
