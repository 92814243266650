import fetch from 'isomorphic-fetch'
import { BASE_URL } from '../shared/constants'
const XLSX = require('xlsx')
var fileSaver = require('file-saver')
var blob = require('blob-util')
const {
    flow,
    map,
    flattenDeep,
} = require("lodash/fp");

const Return1 = {
    genRet1: (data) => {
        return fetch(`${BASE_URL}/gsp/gen-ret1`, {
            headers: {
                'Content-Type': 'application/json',
                'period': data.period,
                'gsptoken': data.gspDetails.gspToken,
                'otp': data.gspDetails.otp,
                'gstnusername': data.companyDetails.gstnUserName,
                'state': data.companyDetails.state,
                'gstin': data.companyDetails.gstin,
                'id': data.companyDetails.id,
            },
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}
export default Return1;