import React from 'react'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import Popover from 'antd/lib/popover'
import Tooltip from 'antd/lib/tooltip'
import Select from 'antd/lib/select'
import glamorous from 'glamorous'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { CompanyQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, withApollo } from 'react-apollo'
import map from 'lodash/fp/map'
import get from 'lodash/get'
import find from 'lodash/fp/find'
import NavMenu from '../NavMenu'
import { CenterAlignWrapper } from '../../../shared/styles'
import { ROUTES, LOCAL_STORAGE } from '../../../shared/constants'
import Notifications from './Notifications'
import Auth from '../../../services/AuthService'

import { actions } from '../../../redux'
import { IconWrapperDiv, IconWrapper } from './Icon'
import { gspActions } from "../../../gspRedux";

const { Div } = glamorous
const Root = glamorous.div({ height: 47, display: 'flex', background: 'white', paddingLeft: '20px'})
const SRow = glamorous(Row)({ margin: '5px 0', })
const col_margin = { margin: '-2px 0px 0px 0px' };
const col_margin1 = { margin: '0px 15px 0px 15px', paddingLeft: "42px" };
const NavMenuWrapper = glamorous.div({ '& .ant-menu-inline': {border: 0, minWidth: 200,},})
const verticalCenter = {height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}


const Header = (props) => {
  return (
    <Root style={{marginBottom:'10px'}}>
      {props.isMobile && <Popover visible={props.isMenuVisible} onVisibleChange={props.onVisibleChange} content={<NavMenuWrapper> <NavMenu /> </NavMenuWrapper>} trigger="click" placement="bottomRight"> <IconWrapper type={'bars'} /> </Popover>}

      {props.isFullRender && <Row style={{width:'100%'}}>
        <Col span={3} style={verticalCenter}>Financial Year</Col>
        <Col span={3} style={verticalCenter} >
          <Select value={props.finYear}  id="year" name="year" onChange={props.handleYearChange} onLoad={props.handleYearChange} style={{ width: '120px' }}>
            <Select.Option value="2017-18">2017-18</Select.Option>
            <Select.Option value="2018-19">2018-19</Select.Option>
            <Select.Option value="2019-20">2019-20</Select.Option>
            <Select.Option value="2020-21">2020-21</Select.Option>
            <Select.Option value="2021-22">2021-22</Select.Option>
			<Select.Option value="2022-23">2022-23</Select.Option>
            <Select.Option value="2023-24">2023-24</Select.Option>
          </Select>
        </Col>
        <Col span={4} style={verticalCenter}><span style={{paddingLeft:'10px'}}>Return Filing Period</span></Col>
        <Col span={14} style={verticalCenter} >
          <Select value={props.finMonth.toString()} style={{ width: '120px' }} id="financialMonth" name="financialMonth" onChange={props.handleMonthChange} onLoad={props.handleMonthChange}>
            <Select.Option value='4'>April</Select.Option>
            <Select.Option value='5'>May</Select.Option>
            <Select.Option value='6'>June</Select.Option>
            <Select.Option value='7'>July</Select.Option>
            <Select.Option value='8'>August</Select.Option>
            <Select.Option value='9'>September</Select.Option>
            <Select.Option value='10'>October</Select.Option>
            <Select.Option value='11'>November</Select.Option>
            <Select.Option value='12'>December</Select.Option>
            <Select.Option value='1'>January</Select.Option>
            <Select.Option value='2'>Feburary</Select.Option>
            <Select.Option value='3'>March</Select.Option>
          </Select>
        </Col>
      </Row>}

      {props.isYearRender && <Row style={{width:'100%'}}>
        <Col span={3} style={verticalCenter}>Financial Year</Col>
        <Col span={21} style={verticalCenter}>
          <Select value={props.finYear} id="year" name="year" onChange={props.handleYearChange} onLoad={props.handleYearChange} style={{ width: '120px' }}>
            <Select.Option value="2017-18">2017-18</Select.Option>
            <Select.Option value="2018-19">2018-19</Select.Option>
            <Select.Option value="2019-20">2019-20</Select.Option>
            <Select.Option value="2020-21">2020-21</Select.Option>
            <Select.Option value="2021-22">2021-22</Select.Option>
			<Select.Option value="2022-23">2022-23</Select.Option>
            <Select.Option value="2023-24">2023-24</Select.Option>
            <Select.Option value="2024-25">2024-25</Select.Option>
          </Select>
        </Col>
      </Row>}
    </Root>
  )
}

type state = {
  isMenuVisible: false,
}

type cProps = {
  history:
  {
    listen: Function,
    push: Function,
  },
  isMobile: boolean,
  companies: Array,
  changeSelectedPeriod: Function,
  handleMonthChange: Function,
  handleYearChange: Function,
  client:
  {
    resetStore: Function,
  },
}

class HeaderContainer extends React.Component
{
  state: state
  props: cProps
  constructor(props)
  {
    super(props)
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    
    var nextyear = curyear - 2000 + 1;
    if (n < 3)
    {
      curyear--;
      nextyear--;
    }
    var year = curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n + 1,
      finYear: year,
      curFinMonth: n + 1,
      curFinYear: year,
      isFullRender: false,
      isYearRender: true,
      cn:localStorage.getItem(LOCAL_STORAGE.CN),
    }

    this.props.history.listen((location, action) => {
      if (location.pathname.indexOf('secured') !== -1)
      {
        this.setState({
          isMenuVisible: false,
          isFullRender: true,
          isYearRender: false,
        })
      }
      if (location.pathname.indexOf('secured/dashboard') !== -1 || location.pathname.indexOf('secured/thougtgst/gstfilings/gstr9') !== -1){
        this.setState({
          isYearRender: true,
          isFullRender: false,
        })
      }
      if ((location.pathname.indexOf('secured/company') !== -1) ||
          (location.pathname.indexOf('secured/itc_corner') !== -1) ||
          // (location.pathname.indexOf('secured/thougtgst/gstfilings/gstr9') !== -1) ||
          (location.pathname.indexOf('secured/thoughtgst_utilities/electronic_ledgers') !== -1) ||
          (location.pathname.indexOf('secured/contacts/details') !== -1) ||
          (location.pathname.indexOf('secured/contacts/add') !== -1) ||
          (location.pathname.indexOf('secured/contacts/update') !== -1) ||
          (location.pathname.indexOf('secured/analytics') !== -1) ||
          (location.pathname.indexOf('return_filing_trends') !== -1)){
        this.setState({
          isFullRender: false,
          isYearRender: false,
        })
      }
    })

    this.props.changeSelectedPeriod(
      this.state.finMonth,
      this.state.finYear
    );


  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.companies){
      const selectedCompany = find({id:this.state.cn})(this.props.companies)
      if(selectedCompany){
        if(this.state.finMonth !== prevState.finMonth){
          this.props.changeSelectedPeriod(
            this.state.finMonth,
            this.state.finYear
          );
        }
        if(this.state.finYear !== prevState.finYear){
          this.props.changeSelectedPeriod(
            this.state.finMonth,
            this.state.finYear
          );
        }
      }
    }
  }

  getTaxPeriod = () => {
    return this.state.finMonth;
  }


  getFinYear = () => {
    return this.state.finYear;
  }


  handleMonthChange = (monthVal) => {
    var temp1 = this.state.finYear;
    var x1 = temp1.split('-');
    var temp2 = this.state.curFinYear;
    var x2 = temp2.split('-');
    console.log(x2)

    console.log(parseInt(x1[1],10),parseInt(x2[1],10))
    if (parseInt(x1[1],10) === parseInt(x2[1],10)) {
      if (monthVal < 4) {
        if (parseInt(x1[1],10) <= parseInt(x2[1],10)) {
          if ((monthVal - 1) <= this.state.curFinMonth){
            this.setState({finMonth: monthVal});
          }
          else {
            this.setState({finMonth: this.state.curFinMonth});
          }
        }
        else if (parseInt(x1[1],10) > parseInt(x2[1],10)) {
          this.setState({finMonth: this.state.curFinMonth});
        }
      }
      else if (monthVal >= 4) {
        if (parseInt(x1[0],10) <= parseInt(x2[0],10)) {
          this.setState({finMonth: monthVal});
        }
        else if (parseInt(x1[1],10) > parseInt(x2[1],10)) {
          this.setState({finMonth: this.state.curFinMonth});
        }
      }
    }
    else if (parseInt(x1[1],10) < parseInt(x2[1],10)){
      this.setState({finMonth: monthVal});
    }
    this.props.changeSelectedPeriod(
      this.state.finMonth,
      this.state.finYear
    );
  }

  handleYearChange = (yearVal) => {
    var temp1 = yearVal
    var x1 = temp1.split('-');
    var temp2 = this.state.curFinYear;
    var x2 = temp2.split('-');
    console.log("  ",parseInt(x1[0],10),parseInt(x2[0],10),(parseInt(x1[0],10) <= parseInt(x2[0],10)),(parseInt(x1[0],10) >= parseInt(x2[0],10)))
    if (parseInt(x1[0],10) <= parseInt(x2[0],10) || parseInt(x1[0],10) >= parseInt(x2[0],10))
    {
      this.setState({finYear: yearVal});
    }
    else
    {
      this.setState({finYear: this.state.curFinYear});
    }

    this.props.changeSelectedPeriod(
      this.state.finMonth,
      this.state.finYear
    );
    document.getElementById('year').value = this.state.finYear;
  }

  onVisibleChange = () => {
    this.setState(prevState => ({
      isMenuVisible: !prevState.isMenuVisible,
    }))
  }

  logout = async () => {
    await Auth.logout()
    this.props.client.resetStore()
    this.props.history.push(ROUTES.LOGIN)
  }

  profile = () => {
    this.props.history.push(ROUTES.COMPANY)
  }


  render() {
    console.log(actions);
    if(!this.state.isFullRender && !this.state.isYearRender){
      return false
    }else{
    return (
      <Header
        {...this.props}
        isFullRender={this.state.isFullRender}
        isYearRender={this.state.isYearRender}
        isMenuVisible={this.state.isMenuVisible}
        onVisibleChange={this.onVisibleChange}
        getTaxPeriod={this.getTaxPeriod}
        getFinYear={this.getFinYear}
        handleMonthChange={this.handleMonthChange}
        handleYearChange={this.handleYearChange}
        finMonth={this.state.finMonth}
        finYear={this.state.finYear}
        logout={this.logout}
        profile={this.profile}
        cn={this.state.cn}
      />
    )
  }
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}

const companyQueryConfig = {
  options: { variables: {} },
  props: ({ data }) => ({ companies: get(data, 'viewer.companies.edges'), finMonth: '1', finYear: '2016-17' }),
}

const companyByIdQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
  props: ({ data }) => {
    return {
      company: get(data, 'viewer.company'),
      finMonth: '1',
      finYear: '2016-17'
    }
  },
  skip: props => !props.selectedCompany,
}

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps, {
    changeSelectedPeriod: actions.changeSelectedPeriod,
  }),
  graphql(gql(CompanyQuery), companyQueryConfig),
  graphql(gql(CompanyByIdQuery), companyByIdQueryConfig)
)(HeaderContainer)

export { Header }