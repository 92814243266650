import React from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import { Button, ContentWrapper as Content } from "../../shared/styles";
import { ROUTES } from "../../shared/constants";
import { FormItem } from "../../shared/utils";
import { gql, graphql, compose } from 'react-apollo'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { prop } from "lodash/fp";
import { CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import get from 'lodash/fp/get'
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 14,
            offset: 6,
        },
    },
};

const add_button = {
    outline: "0",
    display: "inline-block",
    marginBottom: "0",
    fontWeight: "500",
    textAlign: "center",
    touchAction: "manipulation",
    cursor: "pointer",
    backgroundImage: "none",
    border: "1px solid transparent",
    whiteSpace: "nowrap",
    lineHeight: "1.45",
    padding: "0 15px",
    fontSize: "14px",
    borderRadius: "4px",
    height: "35px",
    mozUserSelect: "none",
    position: "relative",
    color: "#000000a6",
    backgroundColor: "rgb(16, 142, 233)",
    borderColor: "#d9d9d9",
    paddingTop: "6px",
};

const ContactAdd = (props) => (
    < Content >
        <Form onSubmit={props.addContact}>
            <FormItem label="GSTIN">
                {props.getFieldDecorator("gstin", {
                    initialValue: props.contact ? props.contact.gstin : "",
                })(
                    <Input
                        id="gstin"
                        placeholder="GSTIN"
                        type="text"
                        required
                    />
                )}
            </FormItem>
            <FormItem label="Company Name">
                {props.getFieldDecorator("companyName", {
                    initialValue: props.contact ? props.contact.companyName : "",
                })(
                    <Input
                        id="companyName"
                        placeholder="Company Name"
                    />
                )}
            </FormItem>
            <FormItem label="Email id">
                {props.getFieldDecorator("email1", {
                    initialValue: props.contact ? props.contact.email1 : "",
                })(
                    <Input
                        id="email1"
                        placeholder="Email id"
                        type="text"
                        required
                    />
                )}
            </FormItem>
            <FormItem label="Email id-2">
                {props.getFieldDecorator("email2", {
                    initialValue: props.contact ? props.contact.email2 : "",
                })(
                    <Input
                        id="email2"
                        placeholder="Email id-2"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Contact Person Name">
                {props.getFieldDecorator("contactName", {
                    initialValue: props.contact ? props.contact.contactName : "",
                })(
                    <Input
                        id="contactName"
                        placeholder="Contact Person name"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Mobile Number">
                {props.getFieldDecorator("mobile", {
                    initialValue: props.contact ? props.contact.mobile : "",
                })(
                    <Input
                        id="mobile"
                        placeholder="Mobile Number"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Contact Person Name-2">
                {props.getFieldDecorator("contactName2", {
                    initialValue: props.contact ? props.contact.contactName2 : "",
                })(
                    <Input
                        id="contactName2"
                        placeholder="Contact Person Name-2"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Mobile Number-2">
                {props.getFieldDecorator("mobile2", {
                    initialValue: props.contact ? props.contact.mobile2 : "",
                })(
                    <Input
                        id="mobile2"
                        placeholder="Mobile Number-2"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Landline Number">
                {props.getFieldDecorator("landline", {
                    initialValue: props.contact ? props.contact.landline : "",
                })(
                    <Input
                        id="landline"
                        placeholder="Landline Number"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Landline Number-2">
                {props.getFieldDecorator("landline2", {
                    initialValue: props.contact ? props.contact.landline2 : "",
                })(
                    <Input
                        id="landline2"
                        placeholder="Landline number-2"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="PAN">
                {props.getFieldDecorator("pan", {
                    initialValue: props.contact ? props.contact.pan : "",
                })(
                    <Input
                        id="pan"
                        placeholder="PAN"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Address">
                {props.getFieldDecorator("address", {
                    initialValue: props.contact ? props.contact.address : "",
                })(

                    <Input
                        id="address"
                        placeholder="Address"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="City">
                {props.getFieldDecorator("city", {
                    initialValue: props.contact ? props.contact.city : "",
                })(
                    <Input
                        id="city"
                        placeholder="City"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Pin Code">
                {props.getFieldDecorator("pincode", {
                    initialValue: props.contact ? props.contact.pincode : "",
                })(
                    <Input
                        id="pincode"
                        placeholder="Pincode"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="State">
                {props.getFieldDecorator("state", {
                    initialValue: props.contact ? props.contact.state : "",
                })(
                    <Input
                        id="state"
                        placeholder="State"
                        type="text"
                    />
                )}
            </FormItem>
            <FormItem label="Country">
                {props.getFieldDecorator("country", {
                    initialValue: props.contact ? props.contact.country : "",
                })(
                    <Input
                        id="country"
                        placeholder="Country"
                        type="text"
                    />
                )}
            </FormItem>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    {props.isEdit ? "Update" : "Add"} Contact
                </Button>
                <a style={add_button} id="back">
                    <Link to={ROUTES.CONTACT_DETAILS}>Back</Link>
                </a>
            </Form.Item>
        </Form>
    </Content >

);

function validateGSTN(gstn) {
    var rx = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9a-zA-Z]{3})+$/;
    if (rx.test(gstn) === false) {
        if (gstn.substr(0, 2) !== "97") {
            return false;
        }
    }
    if (gstn.substr(0, 2) === "28") {
        return false;
    }
    return true;
}

class AddContactContainer extends React.Component {
    addContact = async (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            values.companyId = this.props.selectedCompany;
            values.gstin = values.gstin.toUpperCase();
            var gstn = values.gstin;
            var gstnlen = gstn.length;
            var pincode = values.pincode;
            var mob = values.mobile;
            var mob2 = values.mobile2;
            var landln = values.landline;
            var landln2 = values.landline2;
            var pan = values.pan;
            var mail = values.email1;
            var mail2 = values.email2;

            var panpatt = new RegExp("[A-Z]{5}[0-9]{4}[A-Z]{1}");
            var pinpatt = new RegExp("^[0-9]{6}");
            var mobpatt = new RegExp("^[0-9]{10}");
            var landpatt = new RegExp("^[0-9]{11}");
            var mailpatt = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}")

            var resPan = panpatt.test(pan);
            var resPin = pinpatt.test(pincode);
            var resMob = mobpatt.test(mob)
            var resMob2 = mobpatt.test(mob2)
            var resLand = landpatt.test(landln)
            var resLand2 = landpatt.test(landln2)
            var resEmail = mailpatt.test(mail)
            var resEmail2 = mailpatt.test(mail2)

            if (pincode !== "" && pincode !== null && pincode !== undefined) {
                if (pincode.length !== 6) {
                    message.error("Enter Valid Pincode");
                    return;
                } else if (!resPin) {
                    message.error("Enter Valid Pincode");
                    return;
                }
            }

            if (mail !== "" && mail !== null && mail !== undefined) {
                if (!resEmail) {
                    message.error("Enter Valid Email Address")
                    return
                }
            }

            if (mail2 !== "" && mail2 !== null && mail2 !== undefined) {
                if (!resEmail2) {
                    message.error("Enter Valid Email Address")
                    return
                }
            }

            if (pan !== "" && pan !== null && pan !== undefined) {
                if (pan.length !== 10) {
                    message.error("Enter Valid PAN");
                    return;
                } else if (!resPan) {
                    message.error("Enter Valid PAN");
                    return;
                }
            }

            if (mob !== "" && mob !== null && mob !== undefined) {
                if (mob.length !== 10) {
                    message.error("Enter Valid Mobile Number");
                    return;
                } else if (!resMob) {
                    message.error("Enter Valid Mobile Number");
                    return
                }
            }

            if (mob2 !== "" && mob2 !== null && mob2 !== undefined) {
                if (mob2.length !== 10) {
                    message.error("Enter Valid Mobile Number");
                    return;
                } else if (!resMob2) {
                    message.error("Enter Valid Mobile Number");
                    return
                }
            }

            if (landln !== "" && landln !== null && landln !== undefined) {

                if (landln.length !== 11) {
                    message.error("Enter Valid Landline Number");
                    return;
                } else if (!resLand) {
                    message.error("Enter Valid Landline Number");
                    return;
                }
            }

            if (landln2 !== "" && landln2 !== null && landln2 !== undefined) {

                if (landln2.length !== 11) {
                    message.error("Enter Valid Landline Number");
                    return;
                } else if (!resLand2) {
                    message.error("Enter Valid Landline Number");
                    return;
                }
            }

            if (gstnlen !== 15) {
                message.error("Entered GSTIN should contain 15 characters.");
                return;
            } else if (validateGSTN(gstn) === false) {
                message.error("GSTIN entered is Invalid");
                return;
            } else {
                try {
                    await this.props.mutate(values);
                    !this.props.isEdit && this.props.form.resetFields();
                    message.success("Contact saved successfully!");
                    window.history.back();

                } catch (e) {

                }
            }
        })

    }
    render() {
        return (
            <ContactAdd
                addContact={this.addContact}
                getFieldDecorator={this.props.form.getFieldDecorator}
                isEdit={this.props.isEdit}
                contact={this.props.contact}

            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedCompany: state.local.selectedCompany,
    };
};

const companyQueryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
        },
    }),
    props: ({ data }) => {
        return {
            company: get('viewer.company', data),
            gspDetails: get("viewer.gspInfo", data),
        }
    },
}

export default compose(
    Form.create(),
    connect(mapStateToProps),
    graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(AddContactContainer)