import { handleActions, createActions } from 'redux-actions'

const initialState = {
  selectedCompany: null,
  finYear: null,
  finMonth: null,
  selectedCompanyGSTIN:null,
  sub_type:null,
}

const actions = createActions({
  CHANGE_SELECTED_COMPANY: (companyName, companyGSTIN) => ({ companyName, companyGSTIN }),
  CHANGE_SELECTED_PERIOD: (finMonth, finYear) => ({ finMonth, finYear }),
  CHANGE_COMPANY: (sub_type) => ({ sub_type }),
})

const reducers = handleActions(
{
  CHANGE_SELECTED_COMPANY: (state, { payload: { companyName, companyGSTIN } }) => ({
    ...state,
    selectedCompany: companyName,
    selectedCompanyGSTIN: companyGSTIN,
  }),
  CHANGE_SELECTED_PERIOD: (state, { payload: { finMonth, finYear } }) => ({
    ...state,
    finMonth: finMonth,
    finYear: finYear,
  }),
  CHANGE_COMPANY: (state, { payload: { sub_type } }) => ({
    ...state,
    sub_type:sub_type
  })
},initialState)

export { actions, reducers }