import React from 'react'
import glamorous from 'glamorous'
import Tooltip from 'antd/lib/tooltip'
import Button from 'antd/lib/button'
import { Table, Radio, Popconfirm, Input } from 'antd';
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import pick from 'lodash/fp/pick'
import { V2_SR_AMENDED_TABLEWISE_SUMMARY_QUERY, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import DropZone from 'react-dropzone';
import { Root, ContentWrapper as Content } from '../../shared/styles'
import { BASE_URL } from '../../shared/constants'
import { gspActions } from "../../gspRedux";
import toString from "lodash/fp/toString";
import Column from 'antd/lib/table/Column';
import ANX1_A_B2CA from './ANX1_A_B2CA';
import ANX1_A_EXPWPA from './ANX1_A_EXPWPA';
import ANX1_A_EXPWOPA from './ANX1_A_EXPWOPA';
import ANX1_A_REVA from './ANX1_A_REVA';
import ANX1_A_IMPSA from './ANX1_A_IMPSA';
import ANX1_A_IMPGA from './ANX1_A_IMPGA';
import ANX1_A_IMPGSEZA from './ANX1_A_IMPGSEZA';
import ANX1_A_ECOMA from './ANX1_A_ECOMA';
var comp_id = '...'
const SRow = glamorous(Row)({ margin: '15px 0', })
const confirm = Modal.confirm;
const errorModal = Modal.error;

const Anx1_A_Dashboard = props => {
    return (
        <Root>
            <Content>
                <SRow></SRow>
                <Row>
                    <Table pagination={false} dataSource={props.v2_sr_amended_tablewise_summary ? get('viewer.v2_sr_amended_tablewise_summary.edges')(props.v2_sr_amended_tablewise_summary) : []} bordered title={() => 'Anx1 Amended Summary'}>
                        <Column title="Category" dataIndex='category'></Column>
                        <Column title="Number of Records" dataIndex='records'
                            key="records" render={(text, record) => {
                                return ((record.category) ?
                                    <Button size="large" id={record.category} style={{ width: "3em", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}
                                        onClick={props.showModal}
                                    >
                                        {text}
                                    </Button> :
                                    <span>{text}</span>
                                )
                            }}
                        ></Column>
                        <Column title="Invoice Value" dataIndex='invoiceValue'></Column>
                        <Column title="Taxable Value" dataIndex='taxableValue'
                            key="taxableVAlue" render={(text, record) => {
                                return ((record.category != "ECOM-A")) ? (<div>{text}</div>) : {
                                    props: {
                                        style: { background: "#d9d9d9" }
                                    },
                                    children: (<span></span>)
                                }
                            }}
                        ></Column>
                        <Column title="Integrated Tax" dataIndex="igst"
                            key="igst" render={(text, record) => {
                                return (record.category != "SEZWOP-A" ? (<div>{text}</div>) : {
                                    props: {
                                        style: { background: "#d9d9d9" }
                                    },
                                    children: (<span></span>)
                                })
                            }}
                        ></Column>
                        <Column title="Central Tax" dataIndex="cgst"
                            key="cgst" render={(text, record) => {
                                return ((record.category != "EXPWP-A" && record.category != "EXPWOP-A" && record.category != "RCM-A" && record.category != "IMPS-A" && record.category != "IMPG-A" && record.category != "IMPGSEZ-A") ? (<div>{text}</div>) : {
                                    props: {
                                        style: { background: "#d9d9d9" }
                                    },
                                    children: (<span></span>)
                                })
                            }}
                        >
                        </Column>
                        <Column title="State Tax" dataIndex="sgst"
                            key="sgst" render={(text, record) => {
                                return ((record.category != "EXPWP-A" && record.category != "EXPWOP-A" && record.category != "RCM-A" && record.category != "IMPS-A" && record.category != "IMPG-A" && record.category != "IMPGSEZ-A") ? (<div>{text}</div>) : {
                                    props: {
                                        style: { background: "#d9d9d9" }
                                    },
                                    children: (<span></span>)
                                })
                            }}
                        ></Column>
                        <Column title="Cess" dataIndex="cess"
                            key="cess" render={(text, record) => {
                                return ((record.category != "EXPWOP-A" && record.category != "EXPWP-A" && record.category != "EXPWP-A" && record.category != "EXPWP-A") ? <div>{text}</div> : {
                                    props: {
                                        style: { background: "#d9d9d9" }
                                    },
                                    children: (<span></span>)
                                })
                            }}
                        ></Column>
                    </Table>
                </Row>
                <Modal
                    centered
                    width={"90%"}
                    visible={props.summary_modal_visible}
                    onCancel={props.handleCancel}
                    footer={<Button onClick={props.handleCancel}
                        style={{ width: "5em", fontSize: '14px', padding: '0 10px', backgroundColor: '#108ee9', color: 'white' }} >
                        OK
                        </Button>}
                >
                    {props.summary_modal_source === "b2c-a" && <ANX1_A_B2CA />}
                    {props.summary_modal_source === "expwp-a" && <ANX1_A_EXPWPA />}
                    {props.summary_modal_source === "expwop-a" && <ANX1_A_EXPWOPA />}
                    {props.summary_modal_source === "rcm-a" && <ANX1_A_REVA />}
                    {props.summary_modal_source === "imps-a" && <ANX1_A_IMPSA />}
                    {props.summary_modal_source === "impg-a" && <ANX1_A_IMPGA />}
                    {props.summary_modal_source === "impgsez-a" && <ANX1_A_IMPGSEZA />}
                    {props.summary_modal_source === "ecom-a" && <ANX1_A_ECOMA />}
                </Modal>
            </Content>
        </Root >
    )
}

class Anx1_A_DashboardContainer extends React.Component {

    constructor(props) {
        super(props);
        var d = new Date();
        var n = d.getMonth();
        var y = d.getYear();
        var curyear = 1900 + y;
        var nextyear = curyear + 1 - 2000;
        var year = curyear + '-' + nextyear;
        if (props.gspDetails !== undefined && props.gspDetails !== null) {
            this.gsp = {
                access_token: props.gspDetails.gspToken,
                otp: props.gspDetails.otp,
                response: null,
                response_delete: null,
            };
        } else {
            this.gsp = {};
        }

        message.config({
            maxCount: 1,
        });
        this.state = {
            finMonth: props.finMonth,
            finYear: props.finYear,
            curFinMonth: n + 1,
            curFinYear: year,
            companyId: props.selectedCompany,
            resetLoading: false,
            popupConfirmReset: false,
            visible: false,
            period: null,
            selectedCategory: "tw",
            summary_modal_source: null,
            summary_modal_visible: false
        }
        comp_id = props.selectedCompany;
    }

    showModal = (e) => {
        this.setState({ "summary_modal_source": e.target.id.toLowerCase(), "summary_modal_visible": true })
    };

    handleCancel = e => {
        this.setState({
            summary_modal_visible: false,
        });
    }

    componentDidUpdate = () => {
        if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
            this.gsp.access_token = this.props.gspDetails.gspToken;
            this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
        }
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    render() {
        return (
            <div style={{ backgroundColor: '#fff', padding: '5px' }}>
                <Anx1_A_Dashboard
                    v2_sr_amended_tablewise_summary={this.props.v2_sr_amended_tablewise_summary}
                    role={get('company.role', this.props)}
                    finMonth={this.state.finMonth}
                    finYear={this.state.finYear}
                    onSyncConfirm={this.onSyncConfirm}
                    isSR={this.props.isSR}
                    resetLoading={this.state.resetLoading}
                    popupConfirmReset={this.state.popupConfirmReset}
                    externalInterfaceInfo={get('externalInterfaceInfo', this.props)}
                    selectedCategory={this.state.selectedCategory}
                    summary_modal_visible={this.state.summary_modal_visible}
                    summary_modal_source={this.state.summary_modal_source}
                    showModal={this.showModal}
                    handleCancel={this.handleCancel}
                />
            </div>
        )
    }
}

const tablewiseConfig = {
    name: "v2_sr_amended_tablewise_summary",
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finmonth: props.finMonth.toString(),
            finyear: props.finYear,
        },
    }),
}

const companyQueryConfig = {
    name: "company",
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finYear: props.finYear,
            finMonth: props.finMonth,
        },
    }),
    props: ({ company }) => {
        return {
            company: get('viewer.company', company),
            gspDetails: get("viewer.gspInfo", company),
            externalInterfaceInfo: get("viewer.externalInterfaceInfo", company)
        }
    },
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany,
        finMonth: state.local.finMonth,
        finYear: state.local.finYear,
        gspDetails: state.gsp
    }
}

export default compose(
    connect(mapStateToProps,
        {
            setGspToken: gspActions.setGspToken,
            setGspOtp: gspActions.setGspOtp,
            setGspDetails: gspActions.setGspDetails
        }),
    graphql(gql(V2_SR_AMENDED_TABLEWISE_SUMMARY_QUERY), tablewiseConfig),
    graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(Anx1_A_DashboardContainer)
