import React from "react";
import { Anchor, Layout, Form } from "antd";
import Table from 'antd/lib/table';
import Sider from "antd/lib/layout/Sider";
import Button from 'antd/lib/button';
import Col from 'antd/lib/col'
import glamorous from 'glamorous'
import { Root, ContentWrapper as Content } from "../../shared/styles";
import ButtonGroup from "antd/lib/button/button-group";
import Row from 'antd/lib/row';
import { Tabs } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import RET1_6 from './RET1_6';
import { ROUTES } from '../../shared/constants';
import { Link } from "react-router-dom";

const { ColumnGroup, Column } = Table;
const SRow = glamorous(Row)({ margin: '15px 0', })
const { TabPane } = Tabs;

class ReturnOne4A extends React.Component {
    render() {
        return (
            <Root>
                <Content>
                    <StickyContainer>
                        <Table pagination={false} dataSource={this.props.auto_population} bordered title={() => '4A. Details Of ITC Based On Auto-Population From FORM GST ANX-1, Action Taken In FORM GST ANX-2 And Other Claims'} footer={() =>
                            <div style={{ overflow: "auto" }}>
                                <Button size="default" icon="save" style={{ float: "right", width: "150px", fontSize: '14px', padding: '0 10px', backgroundColor: 'rgba(4, 136, 15, 0.88)', color: 'white' }}>
                                    Save</Button>
                                <Button size="default" icon="caret-right" id="next" style={{ float: "right", width: "150px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}
                                    onClick={this.props.navigateNext}
                                >
                                    Next</Button>
                            </div>
                        }>
                            <Column title="Sr No." dataIndex='sno'></Column>
                            <Column title="Description" dataIndex='type'></Column>
                            <Column title="Value (₹)" dataIndex='txval'></Column>
                            <ColumnGroup title="Input Tax Credit (ITC) (₹)">
                                <Column title='Integrated tax' dataIndex='igst' />
                                <Column title='Central tax' dataIndex='cgst' />
                                <Column title='State/UT tax' dataIndex='sgst' />
                                <Column title='Cess' dataIndex='cess' />
                            </ColumnGroup>
                        </Table>
                    </StickyContainer>
                </Content>
            </Root>
        )
    }
}
export default ReturnOne4A;