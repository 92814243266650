/* eslint-disable */
import { Col, Empty, Row } from 'antd';
import get from 'lodash/fp/get';
import { Analytics_SR_ATA_SalesQuery } from 'qgst-gql-queries/web/data.json';
import React, { useEffect, useState } from 'react';
import { compose, gql, graphql } from 'react-apollo';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';

const Category_ATA = props => {

    const { selectedDates, selectedPlaceOfSupplyArr, sr_ata_master_data } = props;
    const [salesVolume, setSalesVolume] = useState({});

    useEffect(() => {
        if (sr_ata_master_data) {
            const SR_ATA_Master_Data = {};
            sr_ata_master_data?.forEach(tempSR_ATA_Master_Data => {
                const finMonth = parseInt(tempSR_ATA_Master_Data?.finmonth);
                const finYear = parseInt(finMonth) >= 12 ? parseInt(tempSR_ATA_Master_Data?.finyear?.split("-")?.[1]) : parseInt(tempSR_ATA_Master_Data?.finyear?.split("-")?.[0]);
                const startMonth = parseInt(selectedDates?.[0]?.format('M'));
                const startYear = parseInt(selectedDates?.[0]?.format('YYYY'));
                const endMonth = parseInt(selectedDates?.[1]?.format('M'));
                const endYear = parseInt(selectedDates?.[1]?.format('YYYY'));
                if (((finMonth >= startMonth && finYear == startYear) || (finYear > startYear)) && ((finMonth <= endMonth && finYear == endYear) || (finYear < endYear)) && selectedPlaceOfSupplyArr.includes(tempSR_ATA_Master_Data?.o_pos_id?.split("-")?.[1])) {
                    tempSR_ATA_Master_Data?.sr_ata_items?.edges?.forEach(tempSR_ATA_Item => {
                        if (SR_ATA_Master_Data[parseFloat(tempSR_ATA_Item?.gst_rate)?.toFixed(2)]) {
                            SR_ATA_Master_Data[parseFloat(tempSR_ATA_Item?.gst_rate)?.toFixed(2)] = {
                                noOfInvoices: SR_ATA_Master_Data[parseFloat(tempSR_ATA_Item?.gst_rate)?.toFixed(2)]?.noOfInvoices + 1,
                                invoiceValue: calculateInvoiceValueForItem(SR_ATA_Master_Data[parseFloat(tempSR_ATA_Item?.gst_rate)?.toFixed(2)]?.invoiceValue, tempSR_ATA_Item)
                            };
                        } else {
                            SR_ATA_Master_Data[parseFloat(tempSR_ATA_Item?.gst_rate)?.toFixed(2)] = {
                                noOfInvoices: 1,
                                invoiceValue: calculateInvoiceValueForItem(0, tempSR_ATA_Item)
                            };
                        }
                    });
                }
            });
            setSalesVolume(SR_ATA_Master_Data);
        } else {
            setSalesVolume({});
        }
    }, [selectedDates, selectedPlaceOfSupplyArr]);

    function calculateInvoiceValueForItem(currentValue, ata_item) {
        currentValue = parseFloat(currentValue);
        if (ata_item?.taxable_value) {
            currentValue += parseFloat(ata_item?.taxable_value)
        }
        if (ata_item?.igst) {
            currentValue += parseFloat(ata_item?.igst)
        }
        if (ata_item?.cgst) {
            currentValue += parseFloat(ata_item?.cgst)
        }
        if (ata_item?.sgst) {
            currentValue += parseFloat(ata_item?.sgst)
        }
        if (ata_item?.cess) {
            currentValue += parseFloat(ata_item?.cess)
        }
        return currentValue.toFixed(2);
    }

    function getValues() {
        const valueArr = [];
        if (salesVolume) {
            Object.keys(salesVolume)?.forEach(data => {
                valueArr.push(salesVolume?.[data]?.invoiceValue);
            })
        }
        return valueArr;
    }

    function getCustomData() {
        const customDataArr = [];
        if (salesVolume) {
            Object.keys(salesVolume)?.forEach(data => {
                customDataArr.push(salesVolume?.[data]?.noOfInvoices);
            })
        }
        return customDataArr;
    }

    var data = [
        {
            type: 'pie',
            values: getValues(),
            labels: salesVolume ? Object.keys(salesVolume) : [],
            hole: 0.40,
            pull: [0.1, 0, 0],
            direction: 'clockwise',
            customdata: getCustomData(),
            hovertemplate: "<b>Number of Invoices: </b>%{customdata[0]} <br> <b>Total Invoice Amount (In Rupees): </b> %{value}<extra></extra>",
            marker: {
                colors: ['#CDDC39', '#673AB7', '#F44336'],
                line: {
                    color: 'black',
                    width: 2
                }
            },
            textinfo: "none",
            textfont: {
                family: 'Lato',
                color: 'white',
                size: 16
            },
            hoverlabel: {
                bgcolor: 'black',
                bordercolor: 'black',
                font: {
                    family: 'Lato',
                    color: 'white',
                    size: 16
                }
            }
        }
    ];

    if (salesVolume && Object.keys(salesVolume)?.length > 0) {
        return (
            <Row justify="space-around" style={{ height: "calc(100vh - 350px)" }}>
                <Col span={24} style={{ marginLeft: "20%", marginRight: "20%" }}>
                    <Plot
                        data={data}
                    />
                </Col>
            </Row>
        )
    } else {
        return (
            <Row justify="space-around" style={{ height: "calc(100vh - 350px)", marginTop: "calc(35vh - 175px)" }}>
                <Col span={24}>
                    <Empty
                        imageStyle={{
                            height: "20vh",
                        }}
                        description={
                            <span>
                                No Sales Record found
                            </span>
                        }
                    />
                </Col>
            </Row>
        )
    }
}

const queryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            type: 'srat'
        },
    }),
    props: ({ data }) => {
        return {
            sr_ata_master_data: get('viewer.analytics_sr_ata_masters.edges', data),
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany
    }
}

export default compose(
    connect(mapStateToProps),
    graphql(gql(Analytics_SR_ATA_SalesQuery), queryConfig)
)(Category_ATA)