import React from "react";
import { Anchor, Layout } from "antd";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import RET1_4A from './RET1_4A';
import RET1_4B from './RET1_4B';
import RET1_4C from './RET1_4C';

const { Sider } = Layout;
const { Link } = Anchor;
class ReturnOne4 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            auto_population: [{
                sno: 1,
                type: "Credit on all documents which have been rejected in FORM GST ANX-2 (net of debit/credit notes)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 2,
                type: "Credit on all documents which have been kept pending in FORM GST ANX-2 (net of debit/credit notes)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 3,
                type: "Credit on all documents which have been accepted (including deemed accepted) in FORM GST ANX-2 (net of debit/credit notes)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 4,
                type: "Eligible credit, not availed prior to the introduction of this return but admissible as per Law (transition to new return system)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 5,
                type: "Inward supplies attracting reverse charge (net of debit/credit notes and advances paid, if any)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 6,
                type: "Import of services (net of debit/credit notes and advances paid, if any and excluding services received from SEZ units)",
                txval: 3000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 7,
                type: "	Import of goods",
                txval: 3000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 8,
                type: "Import of goods from SEZ units/developers",
                txval: 3000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 9,
                type: "ISD Credit (net of ISD credit notes)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 10,
                type: "Provisional input tax credit on documents not uploaded by the suppliers [net of ineligible credit]",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 11,
                type: "Upward adjustment in input tax credit due to receipt of credit notes and all other adjustments and reclaims",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 12,
                type: "Sub-total (4A) [sum of 3 to 11]",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            }
            ],
            reversals: [
                {
                    sno: 1,
                    type: "Credit on documents which have been accepted in previous returns but rejected in current tax period (net of debit/credit notes)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 2,
                    type: "Supplies not eligible for credit (including ISD credit) [out of net credit available in table 4A above]",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 3,
                    type: "Reversal of credit in respect of supplies on which provisional credit has already been claimed in the previous tax periods but documents have been uploaded by the supplier in the current tax period (net of ineligible credit)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 4,
                    type: "Reversal of input tax credit as per law (Rule 37, 39, 42 & 43)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 5,
                    type: "	Other reversals including downward adjustment of ITC on account of transition from composition levy to normal levy, if any",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 6,
                    type: "Sub-total (4B) [sum of 1 to 5]",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },

            ],
            net_reversals: [
                {
                    type: "4C. ITC available (net of reversals) (4A- 4B)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    type: "Input tax credit on capital goods (out of 4C)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    type: " Input tax credit on services (out of 4C)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                }
            ]
        }
    }
    navigateNext = () => {
        this.setState({
            currentPage: this.state.currentPage + 1,
        });
    }
    navigatePrev = () => {
        this.setState({
            currentPage: this.state.currentPage - 1,
        });
    }
    navigateTo = (e, link) => {
        e.preventDefault();
        switch (link.title) {
            case "4A":
                this.setState({ currentPage: 1 })
                break;
            case "4B":
                this.setState({ currentPage: 2 })
                break;
            case "4C":
                this.setState({ currentPage: 3 })
                break;
            default:

                break;
        }
    }
    render() {
        return (
            <Layout>
                <Root>
                    <Content>
                        <h2>4. Summary of inward supplies for claiming input tax credit (ITC)</h2>
                        {function (s, navigateNext, navigatePrev) {
                            switch (s.currentPage) {
                                case 1:
                                    return <RET1_4A navigateNext={navigateNext} navigatePrev={navigatePrev} auto_population={s.auto_population} />;
                                    break;
                                case 2:
                                    return <RET1_4B navigateNext={navigateNext} navigatePrev={navigatePrev} reversals={s.reversals} />;
                                    break;
                                case 3:
                                    return <RET1_4C navigatePrev={navigatePrev} net_reversals={s.net_reversals} />;
                                    break;
                                default:
                                    return null;
                            }
                        }(this.state, this.navigateNext, this.navigatePrev)}
                    </Content>
                </Root>
                <Sider
                    style={{ backgroundColor: "##D3D3D3" }}
                    theme="light"
                    width="100"
                >
                    <Anchor style={{ backgroundColor: "##D3D3D3" }} offsetTop={100} onClick={this.navigateTo}>
                        <Link href="4A" title="4A" />
                        <br />
                        <Link href="4B" title="4B" />
                        <br />
                        <Link href="4C" title="4C" />
                        <br />
                    </Anchor>
                </Sider>
            </Layout>
        )
    }
}
export default ReturnOne4;