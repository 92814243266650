import React from "react";
import { Table, InputNumber, Input, Tag, Button } from "antd";

const GSTR9Pt3Sec7OtherReversals = props => {
    const columns = [
        {
            dataIndex: "det",
            key: "det",
            width: "35%",
            render: (text, record, index) => {
                return ( <Input
                id={`pt3sec7-${record.key}-det-tbl7-other`}
                defaultValue={text}
                onBlur={props.otherReversalDescription}
                /> )
            }
        },
        {
            dataIndex: "iamt",
            key: "iamt",
            width: "15%",
            render: (text, record, index) => {
                return ( <Input
                id={`pt3sec7-${record.key}-iamt-tbl7-other`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.otherReversalDescription}
                addonBefore="₹"
                /> )
            }
        },
        {
            dataIndex: "camt",
            key: "camt",
            width: "15%",
            render: (text, record, index) => {
                return ( <Input
                id={`pt3sec7-${record.key}-camt-tbl7-other`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.otherReversalDescription}
                addonBefore="₹"
                /> )
            }
        },
        {
            dataIndex: "samt",
            key: "samt",
            width: "15%",
            render: (text, record, index) => {
                return ( <Input
                id={`pt3sec7-${record.key}-samt-tbl7-other`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.otherReversalDescription}
                addonBefore="₹"
                /> )
            }
        },
        {
            dataIndex: "cess",
            key: "cess",
            width: "15%",
            render: (text, record, index) => {
                return ( <Input
                id={`pt3sec7-${record.key}-cess-tbl7-other`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.otherReversalDescription}
                addonBefore="₹"
                /> )
            }
        },
        {
            width:"5%",
            render: (text, record) => {
              return text !== undefined ? (
                <Button type="primary" shape="circle" icon="delete" 
                id={`pt3sec7-${record.key}-delete-tbl7-other`}
                onClick={props.deleteOtherReversal}
                />
              ) : null;
            }
          }
    ]
    return (
        <Table
          pagination={false}
          size="small"
          dataSource={props.items}
          columns={columns}
          className={props.otherClassName}
          footer={(d) => {
            return (
              <Button type="primary" onClick={props.addOtherReversal}
              id={`pt3sec7-otherreversal-add-tbl7-other`}
              >
                Add other reversal
              </Button>
            )
          }}
        />
      );
}
export default GSTR9Pt3Sec7OtherReversals;