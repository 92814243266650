import React from 'react'
import GSTR9Pt3Sec6 from './GSTR9Pt3Sec6'
import GSTR9Pt3Sec7 from './GSTR9Pt3Sec7'
import GSTR9Pt3Sec8 from './GSTR9Pt3Sec8'

const GSTR9Pt3 = props => {
    return (
      <div>
        {props.pt3sec6.dataSource && props.pt3sec6.dataSource.length > 0 ? (<GSTR9Pt3Sec6 {...props}></GSTR9Pt3Sec6>) : null}
        {props.pt3sec7.dataSource && props.pt3sec7.dataSource.length > 0 ? (<GSTR9Pt3Sec7 {...props}></GSTR9Pt3Sec7>) : null}
        {props.pt3sec8.dataSource && props.pt3sec8.dataSource.length > 0 ? (<GSTR9Pt3Sec8 {...props}></GSTR9Pt3Sec8>) : null}
      </div>
    )
  }

  export default GSTR9Pt3