import React from "react";
import { PageHeader, Button } from "antd";
const GSTR9Header = (props) => {
  const columns = [
    {
      title: "Pt. I",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "",
      dataIndex: "det",
      key: "det",
      width: "15%",
    },
    {
      title: "",
      dataIndex: "value",
      key: "value",
      width: "80%",
    },
  ];
  return (
    <PageHeader
      title={"Annual Return" + " " + props.finYear}
      extra={[
        <div>
          <Button
            icon="sync"
            style={{ backgroundColor: "#2382c7", color: "white" }}
            onClick={
              props.pullGSTRData
            } /*disabled={this.props.company && this.props.company.role === 'reviewer'}*/
          >
            Populate GSTR9 Data
          </Button>
          <Button
            style={{
              backgroundColor: "#04880fe0",
              fontSize: "13px",
              color: "white",
            }}
            icon="cloud-upload"
            onClick={
              props.showData
            } /*disabled={this.props.company && this.props.company.role === 'reviewer'}*/
          >
            Save GSTR9
          </Button>
        </div>,
      ]}
    ></PageHeader>
  );
};

export default GSTR9Header;
