import React from "react";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";
import Tag from "antd/lib/tag";
import Tooltip from "antd/lib/tooltip";
// import Input from "antd/lib/input";

const { TextArea } = Input;
class ReconcileMismatchEmail extends React.Component {
  render() {
    const props = this.props;
    return (
      <Modal
        title={`Notify for GSTN (${props.mailPayload &&
          props.mailPayload[0].supplier_gstin})`}
        okText="Send Mail"
        width="45%"
        destroyOnClose={true}
        visible={props.showModalMail_Mismatch}
        onOk={props.notifyViaEmailSend}
        okButtonProps={{ backgroundColor: "#8a241c" }}
        onCancel={props.handleCancelEmail}
      >
        <div>
          {/*<p>Enter To Mail (Maximum 3 Mail-ID)</p>*/}
          <div>
            {props.emailTags &&
              props.emailTags.map((tag, index) => {
                const isLongTag = tag.length > 20;
                const tagElem = (
                  <Tag
                    key={tag}
                    closable
                    afterClose={() => props.handleCloseEmail(tag)}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </Tag>
                );
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                );
              })}
            {props.inputVisibleEmail && (
              <Input
                ref={props.saveInputRefEmail}
                type="text"
                size="small"
                style={{ width: 300 }}
                value={props.inputValueEmail}
                onChange={props.handleInputChangeEmail}
                onBlur={props.handleInputConfirmEmail}
                onPressEnter={props.handleInputConfirmEmail}
              />
            )}
            {!props.inputVisibleEmail &&
              props.emailTags &&
              props.emailTags.length !== 5 && (
                <Tag
                  onClick={props.showInputEmail}
                  style={{ background: "#fff", borderStyle: "dashed" }}
                >
                  <Icon type="plus" /> Add MailID
                </Tag>
              )}
          </div>
          <br />
          {/*
            <Input
              style={{ marginTop: "5px" }}
              id="mailIDcc"
              placeholder="Enter CC Mail ID"
            />
            <br />
            <Input
              style={{ marginTop: "5px" }}
              id="mailID_ReplyTo"
              placeholder="Receive Replies in Mail ID"
            />
            <br /> */}
          <TextArea
            style={{ marginTop: "10px" }}
            id="mailMessage"
            rows={5}
            placeholder="Message"
            defaultValue={props.mailTextBox}
            // value={props.mailTextBox}
          />
          {/* <br />
            <br />
            <Tag>{props.mailPayload && props.mailPayload.invoice_number}</Tag> */}
          <br />
          <br />
          {props.invoiceTags &&
            props.invoiceTags.map((tag, index) => {
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={tag}
                  closable={index !== 0}
                  afterClose={() => props.handleCloseInvoiceTags(tag)}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}

          <br />

          <Button
            style={{
              marginTop: "10px",
              backgroundColor: "#255c83",
              color: "white"
            }}
            // type="primary"
            onClick={() => props.mailReportDownload(props.mailPayload, 1)}
          >
            Attached Reconciliation Report
          </Button>
          <Button
            style={{
              marginTop: "10px",
              marginLeft: "10px",
              backgroundColor: "#06560e",
              color: "white"
            }}
            // type="success"
            onClick={() => props.addSimilar(props.mailPayload)}
          >
            Add other records from this GSTN
          </Button>
          <br />
          <br />
          <p>
            You will receive replies @{" "}
            <Tag>{props.userMailID && props.userMailID.email}</Tag>
          </p>
        </div>
      </Modal>
    );
  }
}
export default ReconcileMismatchEmail;