import React from "react";
import Button from 'antd/lib/button'
import glamorous from 'glamorous'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Modal from 'antd/lib/modal'
import Table from 'antd/lib/table'
import { gql, graphql, compose } from 'react-apollo'
import { CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gspActions } from "../../gspRedux";
import { connect } from 'react-redux'
import { Root, ContentWrapper as Content } from '../../shared/styles';
import Tooltip from 'antd/lib/tooltip';
import { ROUTES } from '../../shared/constants'
import { Link } from "react-router-dom";
import Return1 from '../../services/Ret1Services';
import flow from 'lodash/fp/flow';
import pick from 'lodash/fp/pick';
import get from 'lodash/fp/get';
import toString from "lodash/fp/toString";

const { ColumnGroup, Column } = Table;
const SRow = glamorous(Row)({ margin: '15px 0', })

const dataSource = [
    {
        desc: '3E. Total value and tax liability (3A + 3B + 3C + 3D)',
        value: '5,114.74',
        igst: '100',
        cgst: '0.00',
        sgst: '0.00',
        cess: '10.00',
    },
    {
        desc: '4C. ITC Available (net of reversals) (4A - 4B)',
        value: '2,751.00',
        igst: '225.00',
        cgst: '0.00',
        sgst: '0.00',
        cess: '76.00',
    },
]

const columns = [
    {
        title: "Description",
        dataIndex: 'desc',
    },
    {
        title: "Value (₹)",
        dataIndex: 'value'
    },
    {
        title: "Integrated tax",
        dataIndex: 'igst'
    },
    {
        title: "Central tax",
        dataIndex: 'cgst'
    },
    {
        title: "State/UT tax",
        dataIndex: 'sgst'
    },
    {
        title: "Cess",
        dataIndex: 'cess'
    },
]

const Ret1Dashboard = props => {
    return (
        <Root>
            <Content>
                <SRow>
                    <Col span="5">
                        <Tooltip title="Select Category" overlay="">
                            <Button size="large" icon="edit" style={{
                                width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white'
                            }}
                            >
                                <Link to={ROUTES.ANX1_RET_PARENT}
                                >
                                    Edit
                                </Link>
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span="5">
                        <Tooltip title="Generate Summary" overlay="">
                            <Button size="large" icon="create" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: 'rgba(4, 136, 15, 0.88)', color: 'white' }}
                                onClick={props.genRet1}
                            >
                                Generate Summary
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span="5">
                        <Button size="large" icon="create" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}
                            onClick={props.showModal}
                        >
                            Proceed to File
                            </Button>
                        <Modal
                            centered
                            width={1200}
                            title="Are you sure ?"
                            visible={props.visible}
                            // onOk={""}
                            okText={"Confirm"}
                            onCancel={props.handleCancel}
                        >
                            <div>
                                <Table bordered title={() => 'RETURN1 3E Summary'}
                                    dataSource={dataSource}
                                    columns={columns}>
                                    <Column title='Description' dataIndex='desc'></Column>
                                    <Column title='Value (₹)' dataIndex='value'></Column>
                                    <ColumnGroup title="Amount of Tax (₹)">
                                        <Column title='Integrated tax' dataIndex='igst' />
                                        <Column title='Central tax' dataIndex='cgst' />
                                        <Column title='State/UT tax' dataIndex='sgst' />
                                        <Column title='Cess' dataIndex='cess' />
                                    </ColumnGroup>
                                </Table>
                                <Table bordered title={() => 'RETURN1 ITC Summary'}
                                    dataSource={dataSource}
                                    columns={columns}>
                                    <Column title='Description' dataIndex='desc'></Column>
                                    <Column title='Value (₹)' dataIndex='value'></Column>
                                    <ColumnGroup title="Amount of Tax (₹)">
                                        <Column title='Integrated tax' dataIndex='igst' />
                                        <Column title='Central tax' dataIndex='cgst' />
                                        <Column title='State/UT tax' dataIndex='sgst' />
                                        <Column title='Cess' dataIndex='cess' />
                                    </ColumnGroup>
                                </Table>
                                <h3>
                                    Clicking 'Confirm' Will File All Sales And Purchases For Selected Tax Period To GSTN
                                </h3>
                            </div>
                        </Modal>
                    </Col>
                </SRow>

                <Table bordered title={() => 'RETURN1 3E Summary'}
                    dataSource={dataSource}
                    columns={columns}>
                    <Column title='Description' dataIndex='desc'></Column>
                    <Column title='Value (₹)' dataIndex='value'></Column>
                    <ColumnGroup title="Amount of Tax (₹)">
                        <Column title='Integrated tax' dataIndex='igst' />
                        <Column title='Central tax' dataIndex='cgst' />
                        <Column title='State/UT tax' dataIndex='sgst' />
                        <Column title='Cess' dataIndex='cess' />
                    </ColumnGroup>
                </Table>
                <Table bordered title={() => 'RETURN1 ITC Summary'}
                    dataSource={dataSource}
                    columns={columns}>
                    <Column title='Description' dataIndex='desc'></Column>
                    <Column title='Value (₹)' dataIndex='value'></Column>
                    <ColumnGroup title="Amount of Tax (₹)">
                        <Column title='Integrated tax' dataIndex='igst' />
                        <Column title='Central tax' dataIndex='cgst' />
                        <Column title='State/UT tax' dataIndex='sgst' />
                        <Column title='Cess' dataIndex='cess' />
                    </ColumnGroup>
                </Table>
            </Content>
        </Root>
    )
}

class Ret1DashboardContainer extends React.Component {

    constructor(props) {
        super(props);
        if (props.gspDetails !== undefined && props.gspDetails !== null) {
            this.gsp = {
                access_token: props.gspDetails.gspToken,
                otp: props.gspDetails.otp,
                response: null,
                response_delete: null,
            };
        } else {
            this.gsp = {};
        }
        this.state = {
            finMonth: props.finMonth,
            finYear: props.finYear,
            period: null,
            visible: false,
        }
        this.genRet1 = this.genRet1.bind(this);
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };
    handleCancel = e => {
        this.setState({
            visible: false,
        });
    }

    otherGSTNInformation = companyDetails => {
        const state_cd = companyDetails["gstin"].substr(0, 2);
        companyDetails["state"] = state_cd;
        return companyDetails;
    };

    componentDidUpdate = () => {
        if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
            this.gsp.access_token = this.props.gspDetails.gspToken;
            this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
        }
    };

    genRet1 = async () => {
        var year_trim = this.props.finYear.split("-");
        var fy = "";
        if (this.props.finMonth < 4) {
            fy = "20" + year_trim[1];
        } else {
            fy = year_trim[0];
        }

        var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

        const companyDetails = flow(
            pick(["gstin", "id", "gstnUserName"]),
            this.otherGSTNInformation
        )(this.props.company);

        const postData = {
            company_id: this.props.selectedCompany,
            finYear: this.state.finYear,
            finMonth: this.state.finMonth.toString(),
            period,
            gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp
            },
            companyDetails
        }
        const a = await Return1.genRet1(postData);
    }
    render() {
        return (
            <Ret1Dashboard
                genRet1={this.genRet1}
                finMonth={this.state.finMonth}
                finYear={this.state.finYear}
                visible={this.state.visible}
                showModal={this.showModal}
                handleCancel={this.handleCancel}
            />
        )
    }
}
const companyQueryConfig = {
    name: "company",
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finYear: props.finYear,
            finMonth: props.finMonth,
        },
    }),
    props: ({ company }) => {
        return {
            company: get('viewer.company', company),
            gspDetails: get("viewer.gspInfo", company),
            externalInterfaceInfo: get("viewer.externalInterfaceInfo", company)
        }
    },
}
const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany,
        finMonth: state.local.finMonth,
        finYear: state.local.finYear,
        gspDetails: state.gsp
    }
}
export default compose(
    connect(mapStateToProps,
        {
            setGspToken: gspActions.setGspToken,
            setGspOtp: gspActions.setGspOtp,
            setGspDetails: gspActions.setGspDetails
        }),
    graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(Ret1DashboardContainer);