import React from 'react'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import get from 'lodash/fp/get'
import { PR_ITC_PurchaseQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PrITCItems from './PR_ITC_Items'
import { Root, ContentWrapper as Content } from '../../shared/styles'
const { Column } = Table

const PurchaseRegister = props => {

  return (
    <Root>
      <Content>

        <Table dataSource={props.pr_itc_masters} bordered rowKey="id" loading={props.loading} title={() => 'ITC Purchases'} pagination={{ className: 'my-pagination' }}>
          <Column title="Description" dataIndex="doc_num" key="doc_num" />
          {/* <Column title="Sequence Number" dataIndex="sequence_num" key="sequence_num" sorter={(a, b) => compareByAlph(a.sequence_num, b.sequence_num)} /> */}
          <Column title="Add/Reduce from output liability" dataIndex="add_red_ind" key="add_red_ind" />
          <Column title="Items" key="pr_itc_items"
            render={(text, record) => {
              return (
                <Tooltip title="View Item(s)">
                  <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                    {record.pr_itc_items.pageInfo.total} Item(s)

                  </Button>
                  <PrITCItems isVisible={props.visibleModal === record.id} onCancel={props.hideModal} pr_itc_items={record.pr_itc_items.edges}/>
                </Tooltip>
              )
            }}
          />
        </Table>
      </Content>
    </Root>
  )
}

class PurchaseRegisterContainer extends React.Component {
  constructor(props) {
    super(props)
    console.log(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year =  curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n+1,
      finYear: year,
      curFinMonth: n+1,
      curFinYear: year,
    }
    this.props.data.refetch()
  }
  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }
  changeSelectedFinancialMonth = name => {
    this.props.changeSelectedFinancialMonth(name)
  }
  render() {
    var tableLoading = false;
    if (this.props.data.networkStatus === 4) { tableLoading = false; }
    else if (this.props.data.networkStatus === 7) { tableLoading = false; }
    else if (this.props.data.networkStatus === 1) { tableLoading = true; }
    return (
      <PurchaseRegister
        pr_itc_masters={get('viewer.pr_itc_masters.edges')(this.props.data)}
        role={get('company.role', this.props)}
        finMonth={this.state.finMonth}
		    finYear={this.state.finYear}
        loading={tableLoading}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
        isSR={this.props.isSR}
      />
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      // isSR: props.isSR,
      type: 'pritc',
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}
const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}
export default compose(
  connect(mapStateToProps),
  graphql(gql(PR_ITC_PurchaseQuery), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(PurchaseRegisterContainer)
