import React from "react";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Radio from "antd/lib/radio";
import Checkbox from "antd/lib/checkbox";
import Input from "antd/lib/input";
import Anx2MissingDocuments from "./Anx2MissingDocuments";

const Anx2Reconcile = props => {
  return (
    <Root>
      <Content>
        {props.recon_results && (
          <Table
            pagination={false}
            columns={props.columns}
            dataSource={props.recon_results}
            bordered
            align="center"
            title={d => "Summary"}
            style={{ width: "90%" }}
          ></Table>
        )}
      </Content>
    </Root>
  );
};

class Anx2ReconcileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_visible: false,
      missing_docs_visible: false,
      options: [
        { label: "Accept", value: "A" },
        { label: "Reject", value: "R" },
        { label: "Pending", value: "P" },
        { label: "Reset", value: "N" }
      ],
      columns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "key"
        },
        {
          title: "B2B",
          dataIndex: "count",
          key: "count",
          render: (text, record) => (
            <Button
              type="primary"
              size="small"
              onClick={this.showModal}
              id={record.key}
            >
              {text}
            </Button>
          )
        }
      ],
      modal_source: [],
      modalColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          render: (text, record) => {
            return text
              ? text === "P"
                ? "Purchase Register"
                : text === "G"
                ? "ANX2"
                : ""
              : "";
          }
        },
        {
          title: "Supplier GSTIN",
          dataIndex: "supplier_gstin",
          key: "supplier_gstin"
        },
        {
          title: "Invoice Type",
          dataIndex: "invoice_type",
          key: "invoice_type"
        },
        {
          title: "Invoice Number",
          dataIndex: "invoice_number",
          key: "invoice_number"
        },
        {
          title: "Taxable Value",
          dataIndex: "txval",
          key: "txval",
          render: (text, record) => {
            const mismatch_string = record.mm || "";
            return text === undefined ? (
              mismatch_string.indexOf("txval") >= 0 ? (
                <span style={{ color: "red" }}>{record.taxable_value}</span>
              ) : (
                record.taxable_value
              )
            ) : mismatch_string.indexOf("txval") >= 0 ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              text
            );
          }
        },
        {
          title: "IGST",
          dataIndex: "igst",
          key: "igst",
          render: (text, record) => {
            const mismatch_string = record.mm || "";
            return text === undefined ? (
              mismatch_string.indexOf("igst") >= 0 ? (
                <span style={{ color: "red" }}>{record.igst}</span>
              ) : (
                record.igst
              )
            ) : mismatch_string.indexOf("igst") >= 0 ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              text
            );
          }
        },
        {
          title: "CGST",
          dataIndex: "cgst",
          key: "cgst",
          render: (text, record) => {
            const mismatch_string = record.mm || "";
            return text === undefined ? (
              mismatch_string.indexOf("cgst") >= 0 ? (
                <span style={{ color: "red" }}>{record.cgst}</span>
              ) : (
                record.cgst
              )
            ) : mismatch_string.indexOf("cgst") >= 0 ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              text
            );
          }
        },
        {
          title: "SGST",
          dataIndex: "sgst",
          key: "sgst",
          render: (text, record) => {
            const mismatch_string = record.mm || "";
            return text === undefined ? (
              mismatch_string.indexOf("sgst") >= 0 ? (
                <span style={{ color: "red" }}>{record.sgst}</span>
              ) : (
                record.sgst
              )
            ) : mismatch_string.indexOf("sgst") >= 0 ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              text
            );
          }
        },
        {
          title: "Cess",
          dataIndex: "cess",
          key: "cess",
          render: (text, record) => {
            const mismatch_string = record.mm || "";
            return text === undefined ? (
              mismatch_string.indexOf("cess") >= 0 ? (
                <span style={{ color: "red" }}>{record.cess}</span>
              ) : (
                record.cess
              )
            ) : mismatch_string.indexOf("cess") >= 0 ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              text
            );
          }
        },
        {
          title: "Action",
          render: (text, record) => {
            console.table(record);
            return this.state.modal_parent === "2" ? (
              <Button type="primary" onClick={this.setMissingDocsModalVisible}>
                Add to Missing Documents
              </Button>
            ) : (
              <Radio.Group
                options={this.state.options}
                defaultValue={record.status}
                onChange={this.onChange}
              />
            );
          }
        }
      ]
    };
  }
  onChange = e => {
    console.log("radio checked", e.target.value);
    // this.setState({
    //   value: e.target.value,
    // });
    if (e.target.value === "R") {
      this.setState({ modal_reject_confirm_visible: true });
    }
  };
  hideModal = () => {
    this.setState({ modal_visible: false, modal_source: [] });
  };
  setMissingDocsModalVisible = () => {
    this.setState({ missing_docs_visible: !this.state.missing_docs_visible });
  };
  showModal = e => {
    this.setState({
      modal_visible: true,
      modal_source: this.props.recon_results[parseInt(e.target.id)].results,
      modal_parent: e.target.id
    });
  };
  cancelRejectAction = () => {
    this.setState({ modal_reject_confirm_visible: false });
  };
  notifySupplierChange = e => {
    this.setState({ notifySuppliers: e.target.checked });
  };
  render() {
    return (
      <span>
        <Anx2Reconcile
          {...this.props}
          columns={this.state.columns}
          showModal={this.showModal}
        />
        {this.state.modal_source.length > 0 && (
          <Modal
            visible={this.state.modal_visible}
            my_parent={this.state.modal_parent}
            onCancel={this.hideModal}
            title="Details"
            width="95%"
          >
            <Table
              defaultExpandAllRows={true}
              bordered
              columns={this.state.modalColumns}
              dataSource={this.state.modal_source}
            ></Table>
          </Modal>
        )}
        {this.state.missing_docs_visible && (
          <Anx2MissingDocuments {...this.state.modal_source} />
        )}
        {this.state.modal_reject_confirm_visible && (
          <Modal
            title="Please confirm"
            visible={this.state.modal_reject_confirm_visible}
            onOk={this.cancelRejectAction}
            onCancel={this.cancelRejectAction}
            footer={[
              <Button key="cancel" onClick={this.cancelRejectAction}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.cancelRejectAction}
              >
                Reject
              </Button>
            ]}
          >
            <p>Are you sure you want to reject this invoice ?</p><br />
            <Checkbox onChange={this.notifySupplierChange}>
              Notify Supplier ?
            </Checkbox>
            {this.state.notifySuppliers && (
              <p>
                <br />
                <Input placeholder="Enter E-Mail ID of the supplier" />
              </p>
            )}
          </Modal>
        )}
      </span>
    );
  }
}

export default Anx2ReconcileContainer;
