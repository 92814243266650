import { gql, graphql,compose } from 'react-apollo'
import {
  CompanyCreateMutation,
  CompanyQuery,
} from 'qgst-gql-queries/web/data.json'

import CompanyForm from './CompanyForm'

const config = {
  props: ownProps => {
    return {
      mutate: (values) => {
        return ownProps.mutate({
          variables: values,
          refetchQueries: [
            {
              query: gql(CompanyQuery),
            },
          ],
        })
      }
    }
  }
}

export default compose(
  graphql(gql(CompanyCreateMutation), config)
)(CompanyForm)