import React from 'react'
import { Route } from 'react-router-dom'

import CustomerAdd from './CustomerAdd'
import CustomerList from './CustomerList'

import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'

export default () =>
  <Root>
    <Route exact path={ROUTES.CUSTOMER} component={CustomerList} />
    <Route exact path={ROUTES.CUSTOMER_ADD} component={CustomerAdd} />
  </Root>

export { CustomerAdd, CustomerList }
