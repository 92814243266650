import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { FormItem } from '../../shared/utils'
import { ContentWrapper } from '../../shared/styles'

export default (props) =>
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR9}>
    <h2 style={{ textAlign: "center"}}>Details of ITC as declared in returns filed during the financial year</h2>
    <br />
    <br />
    <h3 style={{ marginLeft: "35"}} id="link1">6. Details of ITC availed as declared in returns filed during the financial year</h3>
    <br />
    <br />
    <br />
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "100%", height: "100px" }}><h4><b>Nature Of Supplies</b></h4></th>
        
        <th style={{ width: "70%" }}><h4><b>Integrated Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Central Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>State/UT Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Cess</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>A)</b> Total amount of input tax credit availed through FORM GSTR-3B (sum total of Table 4A of FORM GSTR-3B)</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>
      <p><b>B)</b> Inward supplies (other than imports and inward supplies liable to reverse charge but includes services received from SEZs)</p>
      <tr>

        <td style={{ paddingLeft: '100px' }}>Inputs</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td style={{ paddingLeft: '100px' }}>Capital Goods</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td style={{ paddingLeft: '100px' }}>Input Services</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_b', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_e', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <p><b>C)</b> Inward supplies received from unregistered persons liable to reverse charge (other than B above) on which tax is paid & ITC availed</p>
      <tr>

        <td style={{ paddingLeft: '100px' }}>Inputs</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td style={{ paddingLeft: '100px' }}>Capital Goods</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td style={{ paddingLeft: '100px' }}>Input Services</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_b', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_e', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <p><b>D)</b> Inward supplies received from registered persons liable to reverse charge (other than B above) on which tax is paid and ITC availed</p>
      <tr>

        <td style={{ paddingLeft: '100px' }}>Inputs</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td style={{ paddingLeft: '100px' }}>Capital Goods</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td style={{ paddingLeft: '100px' }}>Input Services</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_b', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_e', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <p><b>E)</b> Import of goods (including supplies from SEZs)</p>
      <tr>

        <td style={{ paddingLeft: '100px' }}>Inputs</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td style={{ paddingLeft: '100px' }}>Capital Goods</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>F)</b> Import of services (excluding inward supplies from SEZs)</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_b', {
               
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>G)</b> Input Tax credit received from ISD</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>H)</b> Amount of ITC reclaimed (other than B above) under the provisions of the Act</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>I)</b> Sub-total (B to H above)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>J)</b> Difference (I - A above)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>K)</b> Transition Credit through TRAN-I (including revisions if any)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>L)</b> Transition Credit through TRAN-II</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>M)</b> Any other ITC availed but not specified above</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>N)</b> Sub-total (K to M above)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>O)</b> Total ITC availed (I + N above)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

    </tbody>
    </table>
  </Form>
  </ContentWrapper>

