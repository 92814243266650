import { Tabs } from 'antd';
import React from 'react';
import { ContentWrapper as Content, Root } from '../../../shared/styles';
import VolumeByCustomer from './VolumeByCustomer';
import VolumeByGSTRate from './VolumeByGSTRate';
import VolumeByPlaceOfSupply from './VolumeByPlaceOfSupply';
const { TabPane } = Tabs;

const SalesAnalytics = props => {

    return (
        <Root>
            <Content>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Sales Volume By Customer" key="1">
                        <VolumeByCustomer />
                    </TabPane>
                    <TabPane tab="Sales Volume By GST Rate" key="2">
                        <VolumeByGSTRate />
                    </TabPane>
                    <TabPane tab="Sales Volume By Place of Supply" key="3">
                        <VolumeByPlaceOfSupply />
                    </TabPane>
                </Tabs>
            </Content>
        </Root>
    )
}

export default SalesAnalytics;