import React from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import Radio from "antd/lib/radio";
import { Link } from "react-router-dom";
import gspAuth from "../../services/GSPService";
import { compose } from "redux";
import { connect } from "react-redux";

import { Button, ContentWrapper as Content } from "../../shared/styles";
import { ROUTES } from "../../shared/constants";
import { FormItem } from "../../shared/utils";
import { actions } from "../../redux";

const add_button = {
  outline: "0",
  display: "inline-block",
  marginBottom: "0",
  fontWeight: "500",
  textAlign: "center",
  touchAction: "manipulation",
  cursor: "pointer",
  backgroundImage: "none",
  border: "1px solid transparent",
  whiteSpace: "nowrap",
  lineHeight: "1.45",
  padding: "0 15px",
  fontSize: "14px",
  borderRadius: "4px",
  height: "35px",
  mozUserSelect: "none",
  position: "relative",
  color: "#000000a6",
  backgroundColor: "rgb(16, 142, 233)",
  borderColor: "#d9d9d9",
  paddingTop: "6px",
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 6,
    },
  },
};

const CompanyAdd = (props) => (
  <Content>
    <Form onSubmit={props.saveCompany}>
      <FormItem label="GSTIN">
        {props.getFieldDecorator("gstin", {
          //rules: [{ required: true, message: 'Please input GSTIN!' }],
          initialValue: props.company ? props.company.gstin : "",
        })(
          <Input
            id="gstId"
            onBlur={props.getCompanyName}
            placeholder="GSTIN"
            disabled={props.isEdit}
            type="text"
            maxLength="15"
            required
          />
        )}
      </FormItem>
      <FormItem label="Company Name">
        {props.getFieldDecorator("name", {
          //rules: [{ required: true, message: 'Please input Company Name!' }],
          initialValue: props.company ? props.company.name : "",
        })(
          <Input
            disabled="true"
            placeholder="Company Name"
            maxLength="70"
            required
          />
        )}
      </FormItem>
      <FormItem label="GSTN UserName">
        {props.getFieldDecorator("gstnUserName", {
          //rules: [{ required: true, message: 'Please input GSTN UserName!' }],
          initialValue: props.company ? props.company.gstnUserName : "",
        })(
          <Input
            id="gstnUserName"
            placeholder="GSTN UserName"
            type="text"
            maxLength="15"
            required
          />
        )}
      </FormItem>
      <FormItem label="Subscription Type">
        {props.getFieldDecorator("sub_type", {
          // rules: [{ required: true, message: 'Please input GSTN UserName!' }],
          initialValue: props.company
            ? props.company.sub_type
              ? props.company.sub_type
              : 1
            : 1,
        })(
          <Radio.Group>
            <Radio value={1}>Regular</Radio>
            <Radio value={2}>Reconcilation Only</Radio>
          </Radio.Group>
        )}
      </FormItem>
      {/* <FormItem label="Address1">
        {props.getFieldDecorator('address1', {
          initialValue: props.company ? props.company.address1 : '',
        })(<Input placeholder="Address1" maxLength="100"/>)}
      </FormItem>
      <FormItem label="Address2">
        {props.getFieldDecorator('address2', {
          initialValue: props.company ? props.company.address2 : '',
        })(<Input placeholder="Address2" maxLength="100"/>)}
      </FormItem>
      <FormItem label="City">
        {props.getFieldDecorator('city', {
          initialValue: props.company ? props.company.city : '',
        })(<Input placeholder="City" maxLength="30"/>)}
      </FormItem>
      <FormItem label="State">
        {props.getFieldDecorator('state', {
          initialValue: props.company ? props.company.state : '',
        })(<Input placeholder="State" maxLength="30"/>)}
      </FormItem>
      <FormItem label="Pincode">
        {props.getFieldDecorator('pincode', {
          initialValue: props.company ? props.company.pincode : '',
        })(<Input placeholder="Pincode" maxLength="6"/>)}
      </FormItem>
      <FormItem label="Contact Person Name">
        {props.getFieldDecorator('contactPersonName', {
          initialValue: props.company ? props.company.contactPersonName : '',
        })(<Input placeholder="Contact Person Name" maxLength="30"/>)}
      </FormItem>
      <FormItem label="Contact Person Phone">
        {props.getFieldDecorator('contactPersonPhone', {
          initialValue: props.company ? props.company.contactPersonPhone : '',
        })(<Input placeholder="Contact Person Phone" maxLength="15"/>)}
      </FormItem>
      <FormItem label="Constitution Of Business">
        {props.getFieldDecorator('cob', {
          initialValue: props.company ? props.company.cob : '',
        })(<Input placeholder="Constitution Of Business" maxLength="45"/>)}
      </FormItem>

      <FormItem label="TURNOVER_2017">
        {props.getFieldDecorator('turnover_2016', {
          initialValue: props.company ? props.company.turnover_2016 : '',
        })(<Input placeholder="100.00" />)}
      </FormItem>
      <FormItem label="TURNOVER (APR-17 - JUN-17)">
        {props.getFieldDecorator('turnover_2017', {
          initialValue: props.company ? props.company.turnover_2017 : '',
        })(<Input placeholder="100.00" />)}
      </FormItem> */}
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          {props.isEdit ? "Update" : "Add"} Company
        </Button>
        <a style={add_button} id="back">
          <Link to={ROUTES.COMPANY}>Back</Link>
        </a>
      </Form.Item>
    </Form>
  </Content>
);

function validateGSTN(gstn) {
  // var rx = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9a-zA-Z]{3})+$/;
  // if (rx.test(gstn) === false) {
  //   if (gstn.substr(0, 2) !== "97") {
  //     return false;
  //   }
  // }
  // if (gstn.substr(0, 2) === "28") {
  //   return false;
  // }
  // return true;

  // New Validation for GSTIN
  const rx1 = /[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}/;
  const rx2 = /[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1}/;
  const rx3 = /[0-9]{2}[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}[0-9]{1}[Z]{1}[0-9]{1}/;
  const rx4 = /[0-9]{4}[a-zA-Z]{3}[0-9]{5}[N][R][0-9a-zA-Z]{1}/;
  const rx5 = /[0-9]{2}[a-zA-Z]{4}[a-zA-Z0-9]{1}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[D]{1}[0-9a-zA-Z]{1}/;
  const rx6 = /[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[C]{1}[0-9a-zA-Z]{1}/;
  const rx7 = /[9][9][0-9]{2}[a-zA-Z]{3}[0-9]{5}[O][S][0-9a-zA-Z]{1}/;
  return (rx1.test(gstn) || rx2.test(gstn) || rx3.test(gstn) || rx4.test(gstn) || rx5.test(gstn) || rx6.test(gstn) || rx7.test(gstn))
}

class CompanyAddContainer extends React.Component {
  saveCompany = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      values.gstin = values.gstin.toUpperCase();
      var gstn = values.gstin;
      var turnover_2016 = values.turnover_2016;
      var turnover_2017 = values.turnover_2017;
      var pincode = values.pincode;
      var len = gstn.length;

      var turnpatt = new RegExp("^[0-9][0-9]*.[0-9]{2}$");
      var pattern = new RegExp("^[0-9][0-9]*$");
      var pinpatt = new RegExp("^[0-9]{6}");

      var res2016 = pattern.test(turnover_2016);
      var res2017 = pattern.test(turnover_2017);
      var resPin = pinpatt.test(pincode);
      if (res2016) {
        turnover_2016 = turnover_2016 + ".00";
      }
      if (res2017) {
        turnover_2017 = turnover_2017 + ".00";
      }

      var val2016 = parseFloat(turnover_2016);
      var val2017 = parseFloat(turnover_2017);

      var turnres2016 = turnpatt.test(turnover_2016);
      var turnres2017 = turnpatt.test(turnover_2017);
      if (pincode !== "" && pincode !== null && pincode !== undefined) {
        if (pincode.length !== 6) {
          message.error("Enter Valid Pincode");
          return;
        } else if (!resPin) {
          message.error("Enter Valid Pincode");
          return;
        }
      }
      if (len !== 15) {
        message.error("Entered GSTIN should contain 15 characters.");
        return;
      } else if (validateGSTN(gstn) === false) {
        message.error("GSTIN entered is Invalid");
        return;
      } else {
        try {
          await this.props.mutate(values);
          // Reset fields only when adding new company
          !this.props.isEdit && this.props.form.resetFields();
          message.success("Company saved successfully!");
          window.history.back();
        } catch (e) {
          message.error(e.graphQLErrors[0].message);
        }
      }
    });
  };
  getCompanyName = async (e) => {
    console.log(e.target.value);
    const gstin = e.target.value.toUpperCase();
    e.preventDefault();
    if (gstin.length === 15) {
      const resp = await gspAuth.getCompanyName(gstin);
      if (!resp || resp.data.success === false) {
        return false;
      }
      this.props.form.setFieldsValue({
        name: resp.data.resp,
        gstin: gstin
      });
    }
  };
  render() {
    console.log("------Company Form-----");
    console.log(this.props);
    if (this.props.company) {
      if (this.props.selectedCompany === this.props.company.id) {
        this.props.changeCompany(this.props.company.sub_type);
      }
    }

    return (
      <CompanyAdd
        handleChange={this.handleChange}
        saveCompany={this.saveCompany}
        getFieldDecorator={this.props.form.getFieldDecorator}
        company={this.props.company}
        getCompanyName={this.getCompanyName}
        isEdit={this.props.isEdit}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    sub_type: state.local.sub_type,
  };
};

export default compose(
  Form.create(),
  connect(mapStateToProps, {
    changeSelectedCompany: actions.changeSelectedCompany,
    changeCompany: actions.changeCompany,
  })
)(CompanyAddContainer);
