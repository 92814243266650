import { Tabs } from 'antd';
import React from 'react';
import { ContentWrapper as Content, Root } from '../../../shared/styles';
import VolumeBySupplier from './VolumeBySupplier';
import VolumeByGSTRate from './VolumeByGSTRate';
import VolumeByPlaceOfSupply from './VolumeByPlaceOfSupply';
const { TabPane } = Tabs;

const PurchaseAnalytics = props => {

    return (
        <Root>
            <Content>
                <Tabs defaultActiveKey="1" type="card">
                    <TabPane tab="Purchase Volume By Supplier" key="1">
                        <VolumeBySupplier />
                    </TabPane>
                    <TabPane tab="Purchase Volume By GST Rate" key="2">
                        <VolumeByGSTRate />
                    </TabPane>
                    <TabPane tab="Purchase Volume By Place of Supply" key="3">
                        <VolumeByPlaceOfSupply />
                    </TabPane>
                </Tabs>
            </Content>
        </Root>
    )
}

export default PurchaseAnalytics;