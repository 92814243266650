import React from 'react'
import glamorous from 'glamorous'
import { Table, Tooltip, Button, Popconfirm, Modal  } from 'antd'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import get from 'lodash/fp/get'
import { message, notification, Icon } from "antd";
import reduce from 'lodash/fp/reduce'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import uniq from 'lodash/fp/uniq'
import find from 'lodash/fp/find'
import pick from 'lodash/fp/pick'
import filter from 'lodash/fp/filter'
import OTPForm from "../GSP/OTPForm";
import flatMap from 'lodash/fp/flatMap'
import { SR_B2B_SalesQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import SrB2BItems from './SR_B2B_Items'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import { formatNumber, BASE_URL } from "../../shared/constants";
import moment from 'moment'
import gspAuth from "../../services/GSPService";
import deleteData from "../../services/DeleteService";
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const row_text_style = { border: 'solid 1px #e8e8e8', margin: '1px'};
const { ColumnGroup, Column } = Table
const SRow = glamorous(Row)({margin: '15px 0',})
const errorModal = Modal.error;
const SalesRegister = props => {
  function compareByAlph(a, b) {
    if (a < b) {
      return -1;
    } if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
  console.log(props);
  // document.getElementsByClassName("anticon-caret-up")[0].addEventListener("click", function()
  // {
  //   console.log("UP SORT CLICKED");
  //   document.getElementsByClassName('anticon-caret-down')[0].click();
  // });
   return (
    <Root>
      <Content>
        <SRow gutter={24} style={row_style}>
          <Col span="6">
            Total Sales Value:{' '}
            {props.formatNumber(reduce((curr, next) => {
                curr = parseFloat(curr) + parseFloat(next.invoice_value, 2);
                var temp = parseFloat(curr);
                temp = parseFloat(temp).toFixed(2);
                return(temp)}, 0)(props.sr_b2b_masters))}
          </Col>
          <Col span="6">
            Total Taxable Value:{' '}
            {props.formatNumber(flow(
              flatMap(get('sr_b2b_items.edges')),
              reduce((curr, next) => {
                curr = parseFloat(curr) + parseFloat(next.taxable_value, 2);
                var temp = parseFloat(curr);
                temp = parseFloat(temp).toFixed(2);
                return(temp)}, 0))(props.sr_b2b_masters
            ))}
          </Col>
          <Col span="6" >
            <Tooltip title="Fetch missing Receiver Name(s)">
              <Button style={{ backgroundColor: '#2382c7', fontSize: '13px', color: 'white'}} icon="sync" onClick={() => props.fetchMissingNames(props.sr_b2b_masters)}>
                Fetch missing Receiver Name(s)
              </Button>
            </Tooltip>
          </Col>
          <Col span="6" >
            <Tooltip title="Delete">
              <Button style={{ backgroundColor: '#a01208de', fontSize: '13px', color: 'white'}} icon="delete" onClick={() => props.delInvoiceFromGSTN()} disabled={props.role === 'reviewer'}>
                Delete Record(s)
              </Button>
            </Tooltip>
          </Col>
        </SRow>
         <Table dataSource={props.sr_b2b_masters} bordered rowSelection={props.rowSelection} rowKey="id" loading={props.loading} title={() => 'B2B Sales'} pagination={{ className: 'my-pagination' }}>
          <Column title="Receiver GSTIN" dataIndex="receiver_gstin" key="receiver_gstin" />
          <Column title="Receiver Name" dataIndex="receiver_name" key="receiver_name"/>
          <ColumnGroup title="Invoice Details">
            <Column title="No." dataIndex="invoice_number" key="invoice_number" sorter={(a, b) => compareByAlph(a.invoice_number, b.invoice_number)} />
            <Column title="Date" dataIndex="invoice_date" key="invoice_date" render={text => <p style={{ width: '100px'}}>{moment(text, "DD-MM-YYYY").format('DD-MMM-YY')}</p>} />
            <Column title="Value" dataIndex="invoice_value" key="invoice_value" render={val => <p style={{ width: '115px'}}>{props.formatNumber(val === null ? 0 : val)}</p>} />
            <Column title="Invoice Type" dataIndex="invoice_type" key="invoice_type"
              render={(text, record) => {

                var invoice_type_conversion;
                if(text === 'R')
                {
                  invoice_type_conversion = "Regular";
                }
                else
                {
                  invoice_type_conversion = text;
                }
                return (invoice_type_conversion)
              }}
              />
          </ColumnGroup>
          <Column title="Place Of Supply" dataIndex="pos_id" key="pos_id" />
          <Column title="Supply Type" dataIndex="supply_type" key="supply_type" />
          <Column title="Applicable % of Tax Rate" dataIndex="atr" key="atr" />
          <Column title="Reverse Charge" dataIndex="reverse_charge" key="reverse_charge" />
          <Column title="E-Com GSTIN" dataIndex="ecom_gstin" key="ecom_gstin" />
          <Column title="Items" key="sr_b2b_items"
            render={(text, record) => {
              return (
                <Tooltip title="View Item(s)">
                  <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                    {record.sr_b2b_items.pageInfo.total} Item(s)
                  </Button>
                  <SrB2BItems isVisible={props.visibleModal === record.id} onCancel={props.hideModal} sr_b2b_items={record.sr_b2b_items.edges} />
                </Tooltip>
              )
            }}
          />
        </Table>
        <SRow gutter={24} style={row_text_style}>
          <Col span="24">
            <h3 style={{padding: '10px'}}>Invoice Types : Regular-Regular B2B Invoices, SEWP-SEZ Supplies with payment, SEWOP-SEZ Supplies without payment, DE-Deemed Exports, SBWH-Sale from Bonded Warehouse</h3>
          </Col>
        </SRow>
        <script type="text/javascript" src="FileSaver.js"></script>
      </Content>
    </Root>
  )
}
class SalesRegisterContainer extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null
      };
    } else {
      this.gsp = {};
    }

    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year =  curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n+1,
      finYear: year,
      curFinMonth: n+1,
      curFinYear: year,

      visible: false,
      requestOTP: false,
      otp: null,
      period: null,
      selectedRowKeys: []
    }
    this.props.data.refetch()
  }

  componentDidUpdate = (prevState) => {
    console.log(this.state);
    console.log(this.props);
    console.log(prevState);
    console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    message.destroy();
    this.setState({ requestOTP: false });
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.delInvoiceFromGSTN();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  delInvoiceFromGSTN = async () => {

    message.destroy();
    message.loading('Deleting Invoice/Invoices from ElixirGST & GSTN...',0)

    console.log(this.state.selectedRowKeys);

    var sendDeleteData = [];

    var downloaddate = this.props.finMonth + "/" + this.props.finYear;


    if(this.state.selectedRowKeys.length === 0){
      message.destroy();
      message.warning('Kindly select the invoices for deletion..!',2)
    }else{
    const filterItemArray = map(g => {
        const filterItemArray = filter({ id : g })(get('viewer.sr_b2b_masters.edges')(this.props.data));
        sendDeleteData = sendDeleteData.concat(filterItemArray)
      })(this.state.selectedRowKeys)

    const deleteDataFormat = deleteData.formatDeletePayload(sendDeleteData, 'b2b');

    const GSPData = deleteData.formatGSPPayload(
      this.props.finYear,
      this.props.finMonth,
      this.props.company,
      this.otherGSTNInformation,
      this.gsp,
      deleteDataFormat,
      'B2B'
      );

    this.gsp.response = await   gspAuth.saveGSTR1(GSPData);

    console.log(this.gsp.response);
      if (
        !this.gsp.response.data.success &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({ requestOTP: true });
      }else if(
        !this.gsp.response.data.success &&
        this.gsp.response.data.errorCode === "GSTR1 is already filed for current period"
      ){
        message.destroy();
        errorModal({
          title: 'GSTN Delete Unsuccessful',
          content: <p>GSTR-1 is already filed, record(s) cannot be deleted!</p>,
          iconType: "close-circle",
        });

      }else if(this.gsp.response.data.success) {
        message.destroy();
        if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "P")
        {
          const deleteDB = fetch(
            `${BASE_URL}/export/deleteGSTR1_DB?companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}&action=B2B`,
            {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(this.state.selectedRowKeys)
            }
          )
          message.destroy();
          message.success('Deleted Successfully',1);
        }
        if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "PE"){
          errorModal({
          title: 'GSTN Delete Unsuccessful',
          content: <p>Please check the downloaded error report!</p>,
          iconType: "close-circle",
        });
        }
        if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "ER"){
          errorModal({
          title: 'GSTN Delete Unsuccessful',
          content: <p>Please validate your record</p>,
          iconType: "close-circle",
        });
        }
      }

    this.props.data.refetch()

  }

  }

  showModal = id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null });
    }

  changeSelectedFinancialMonth = name => {
    this.props.changeSelectedFinancialMonth(name)
  }
  fetchMissingNames = async missingCompanyName => {
      notification.destroy();
      message.destroy();
      message.loading('Fetching Names...',0);
      const listMissingCompanyName = filter({ receiver_name: '...' })(missingCompanyName);

      const gstinList = flow(
        map(d => {
          return pick(['receiver_gstin'])(d).receiver_gstin;
        }),
        uniq
      )(listMissingCompanyName);

      const isSuccess = await gspAuth.getListOfCompanyNames(gstinList, this.props.selectedCompany);

      if(isSuccess.data.success === true){
        this.props.data.refetch();
        message.destroy();
        message.success('Fetched');
      } else if(isSuccess.data.success === false){
        message.destroy();
        notification.destroy();
        notification.open({
          message: 'Fetch Unsuccessful',
          description: "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: '#db0f20' }} />,
        });
      }
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    console.log(selectedRowKeys);
    this.setState({ selectedRowKeys });
  }
  render() {
    console.log(this.props);
    console.log(this.state);
    var tableLoading = false;
    if (this.props.data.networkStatus === 4) { tableLoading = false; }
    else if (this.props.data.networkStatus === 7) { tableLoading = false; }
    else if (this.props.data.networkStatus === 1) { tableLoading = true; }

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      selections: [{
        key: 'all-data',
        text: 'Select All Invoices',
        onSelect: () => {

            this.setState({
            selectedRowKeys: get('viewer.sr_b2b_masters.edges')(this.props.data).map(a => a.id),

          })
        },
      }]
    };

    return (
      <div>
      <SalesRegister
        sr_b2b_masters={get('viewer.sr_b2b_masters.edges')(this.props.data)}
        role={get('company.role', this.props)}
        finMonth={this.state.finMonth}
		    finYear={this.state.finYear}
        loading={tableLoading}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
        isSR={this.props.isSR}
        fetchMissingNames={this.fetchMissingNames}
        formatNumber={formatNumber}
        role={get('company.role', this.props)}
        delInvoiceFromGSTN={this.delInvoiceFromGSTN}
        rowSelection={rowSelection}
      />
      <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
      />
      </div>
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      // isSR: props.isSR,
      type: 'srb2b',
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}
const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      gspDetails: get("viewer.gspInfo", data),
      // isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}

export default compose(
  connect(mapStateToProps),
  graphql(gql(SR_B2B_SalesQuery), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(SalesRegisterContainer)
