import React from "react";
import Glamorous from "glamorous";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import { Redirect, Link } from "react-router-dom";

import { ROUTES, i18n } from "../../shared/constants";
import Auth from "../../services/AuthService";

const B = Glamorous(Button)({
  width: "100%"
});

const Login = props => (
  <Form onSubmit={props.login}>
    <Form.Item>
      {props.getFieldDecorator("email", {
        rules: [
          { required: true, message: i18n.en.login.email_required_message }
        ]
      })(<Input placeholder={i18n.en.login.email_placeholder_message} />)}
    </Form.Item>
    <Form.Item>
      {props.getFieldDecorator("password", {
        rules: [
          { required: true, message: i18n.en.login.password_required_message }
        ]
      })(
        <Input
          placeholder={i18n.en.login.password_placeholder_message}
          type="password"
        />
      )}
    </Form.Item>
    <B type="primary" size="large" htmlType="submit">
      {i18n.en.login.login_button_text}
    </B>
    <p style={{ paddingTop: "20px" }}>
      <Link style={{ color: "#108ee9" }} to={ROUTES.FORGOT_PASSWORD}>
        Forgot Password
      </Link>
    </p>
    <p style={{ paddingTop: "20px", textAlign:"center" }}>{i18n.en.common.or}</p>
    <p style={{ paddingTop: "20px", textAlign:"center" }}>
      <Link to={ROUTES.REGISTER} className="ant-btn ant-btn-primary ant-btn-lg" style={{width:"80%"}}>
        {i18n.en.login.register_now_text}
      </Link>
    </p>
  </Form>
);

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToDashboard: Auth.isLoggedIn()
    };
  }
  login = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      const result = await Auth.login(values);
      if (result.success) {
        this.setState({
          redirectToDashboard: true
        });
      } else {
        message.error(result.error.message);
      }
    });
  };
  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to={ROUTES.DASHBOARD} />;
    } else {
      return (
        <Login
          login={this.login}
          getFieldDecorator={this.props.form.getFieldDecorator}
        />
      );
    }
  }
}

export default Form.create()(LoginContainer);
