import React from "react";
import { Table, InputNumber, Input, Tag } from "antd";
const GSTR9Pt4Sec9 = props => {
  const columns = [
    {
      title: "Pt. IV",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title: "Description",
      dataIndex: "det",
      key: "det",
      width: "11%",
    },
    {
      title: "Tax payable (₹)",
      dataIndex: "txpyble",
      key: "txpyble",
      width: "14%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt4sec9-${record.key}-txpyble-tbl9`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Paid Through Cash(₹)",
      dataIndex: "txpaid_cash",
      key: "txpaid_cash",
      width: "14%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt4sec9-${record.key}-txpaid_cash-tbl9`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
            disabled={true}
          />
        ) : null;
      }
    },
    {
      title:
        "Paid through ITC(₹)",
      children: [
        {
          title: "Integrated Tax (₹)",
          dataIndex: "iamt",
          key: "iamt",
          width: "14%",
          render: (text, record) => {
            return text !== undefined ? (
              <Input
                id={`pt4sec9-${record.key}-iamt-tbl9`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
                disabled={true}
              />
            ) : null;
          }
        },
        {
          title: "Central Tax (₹)",
          dataIndex: "camt",
          key: "camt",
          width: "14%",
          render: (text, record) => {
            return text !== undefined ? (
              <Input
                id={`pt4sec9-${record.key}-camt-tbl9`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
                disabled={true}
              />
            ) : null;
          }
        },
        {
          title: "State/UT Tax (₹)",
          dataIndex: "samt",
          key: "samt",
          width: "14%",
          render: (text, record) => {
            return text !== undefined ? (
              <Input
                id={`pt4sec9-${record.key}-samt-tbl9`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
                disabled={true}
              />
            ) : null;
          }
        },
        {
          title: "CESS (₹)",
          dataIndex: "cess",
          key: "cess",
          width: "14%",
          render: (text, record) => {
            return text !== undefined ? (
              <Input
                id={`pt4sec9-${record.key}-cess-tbl9`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
                disabled={true}
              />
            ) : null;
          }
        }
      ]
    }
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "9. Details of tax paid as declared in returns filed during the financial year"
      }
      size="small"
      dataSource={props.pt4sec9.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt4Sec9;