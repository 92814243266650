import React from 'react'
import { Route } from 'react-router-dom'

import SupplierAdd from './SupplierAdd'
import SupplierList from './SupplierList'

import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'

export default () =>
  <Root>
    <Route exact path={ROUTES.SUPPLIER} component={SupplierList} />
    <Route exact path={ROUTES.SUPPLIER_ADD} component={SupplierAdd} />
  </Root>

export { SupplierAdd, SupplierList }
