import { handleActions, createActions } from 'redux-actions'

const initialState = {
  gspToken: null,
  userName: null,
  state_cd: 33,
  otp: 662482,
}

const gspActions = createActions({
  SET_GSP_TOKEN: (accessToken) => ({ accessToken }),
  SET_GSP_OTP :  (otp) => ({ otp }),
  SET_USERNAME : (userName) => ({userName}),
  SET_GSP_DETAILS: ({gspToken, otp, userName, state_cd}) => ({gspToken, otp, userName, state_cd})
})

const gspReducers = handleActions(
{
  SET_GSP_DETAILS: (state, {payload: {gspToken, otp, userName, state_cd}}) => ({
    ...state,
    gspToken,
    otp,
    userName,
    state_cd
  }),
  SET_GSP_TOKEN: (state, { payload: { accessToken } }) => ({
    ...state,
    gspToken: accessToken,
  }),
  SET_GSP_OTP : (state, { payload: { otp } }) => ({
    ...state,
    otp: otp,
  }),
  SET_USERNAME : (state, {payload : {userName}}) => ({
    ...state,
    userName: userName
  })
},initialState)

export { gspActions, gspReducers }
