import React from 'react'
import Glamorous from 'glamorous'
import { Route } from 'react-router-dom'
import Modal from 'antd/lib/modal'

import Login from './Login'
import Register from './Register'
import ForgotPassword from './ForgotPassword'
import { ROUTES, i18n } from '../../shared/constants'

const Root = Glamorous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: '#f8f8f8',
})

const LoginElement = Glamorous.div({
  boxShadow: '0 0 100px rgba(0,0,0,.08)',
  width: 350,
  height: 500,
  padding: 15,
})

const Logo = Glamorous.div({
  textAlign: 'center',
  marginBottom: 10,
})

export default props =>
  <Root>
    <LoginElement>
      <Logo>
        <Glamorous.Span fontSize="16">{i18n.en.product_name}</Glamorous.Span>
      </Logo>
      <Route exact path="/" component={Login} />
      <Route exact path="/auth" component={Login} />
      <Route path={ROUTES.LOGIN} component={Login} />
      <Route path={ROUTES.REGISTER} component={Register} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <br />
      <Glamorous.P textAlign="center">
        {i18n.en.powered_by}
      </Glamorous.P>
      <br />
      <Glamorous.P
        textAlign="center"
        onClick={() => {
          Modal.info({
            title: i18n.en.privacy_policy_text,
            width: '90%',
            content: (
              <div>
                <h2>I. Objective</h2>
                <p>
                  ThoughtBit Technologies (the “Company”, also identified as
                  “we”) is sensitive to privacy issues. We are committed to
                  respect your choices and privacy while using our website. This
                  privacy policy has been compiled to provide clarity on how we
                  collect, use and protect ‘Personal Information’ as well as
                  ‘Personally Identifiable Information’ in accordance with our
                  website.
                </p>
                <h2>II. Collection of Personal Information</h2>
                <p>
                  ‘Personal Information’ is any data which (1) is about, or
                  pertains to a specific individual; (2) can be used to identify
                  a specific individual; and (3) is recorded in any form by the
                  Company.
                </p>

                <p>
                  In general, you can browse our website without telling us who
                  you are or revealing any information about yourself. However,
                  certain information such as your IP address, demographics,
                  your operating system and browser type may be collected in
                  order to provide better usability, troubleshooting and site
                  maintenance. On occasions, we may need Personal Information
                  from you, such as your name, physical address, email address,
                  telephone number and employer, for the following purposes:
                  user ID creation, identifying you and providing the amenities
                  that you require to perform using our products or services.
                </p>

                <p>
                  If you choose not to provide your Personal Information, we may
                  not be able to provide the corresponding service.
                </p>

                <h2>III. Use of Cookies</h2>
                <p>
                  Cookies are small files that we transfer to your computer's
                  hard drive through your browser that enables us to recognize
                  your browser and capture and remember certain information. For
                  instance, we use cookies to recognize you and your device
                  during a session or in your future visits to our website,
                  primarily in order to provide better user experience.
                </p>

                <h2>IV. Data Security</h2>
                <p>
                  The Company adopts reasonable and appropriate security
                  policies, procedures and practices that include physical,
                  electronic and managerial controls to safeguard your Personal
                  Information.
                </p>

                <h2>V. Data Retention</h2>
                <p>
                  The Company may retain your Personal Information as long as
                  there is a business requirement, or if otherwise required
                  under applicable laws.
                </p>

                <h2>VI. Data Integrity</h2>
                <p>
                  The Company shall take reasonable steps to ensure that
                  Personal Information is accurate, complete, current and
                  reliable for its intended use. The Company shall only process
                  Personal Information for the purpose for which it was
                  collected or authorized by you.
                </p>

                <h2>VII. Data Access</h2>
                <p>
                  You may access, update, or correct your Personal Information
                  in the corresponding sections where such information was
                  provided.
                </p>

                <h2>VIII. Linked Websites</h2>
                <p>
                  The Company may provide links to third-party websites and
                  services. However, we are not responsible for the privacy
                  statements, practices, or the contents of such third-party
                  websites.
                </p>

                <h2>IX. Amendments</h2>
                <p>
                  The Company may amend the data privacy practices and update
                  this privacy statement as and when the need arises, and the
                  same will be made available on the website in reasonable
                  duration.
                </p>

                <h2>X. Contact Information</h2>
                <p>
                  If you have any questions regarding our privacy policy, please
                  write to us at: sid@thoughtbit.in.
                </p>
              </div>
            ),
          })
        }}
      >
        <a style={{color: '#108ee9'}} >{i18n.en.privacy_policy_text}</a>
      </Glamorous.P>
    </LoginElement>
  </Root>
