import React from "react";
import message from "antd/lib/message";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Table from "antd/lib/table";
import Modal from "antd/lib/modal";
import Tooltip from "antd/lib/tooltip";
import Popconfirm from "antd/lib/popconfirm";
import get from "lodash/fp/get";
import { gql, graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { gspActions } from "../../gspRedux";
import Get2AForm from "../GSP/Get2AForm";
import gspAuth from "../../services/GSPService";
import moment from "moment";
import toString from "lodash/fp/toString";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import notification from "antd/lib/notification";
import Icon from "antd/lib/icon";
import pick from "lodash/fp/pick";
import {
  CompanyByIdQuery,
  V2_PR_Summary_Query
} from "qgst-gql-queries/web/data.json";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import DropZone from "react-dropzone";
import Purchase from "../../services/V2PurchaseService";
import Anx2Reconcile from "./Anx2Reconcile";
import { BASE_URL } from "../../shared/constants";
import filter from "lodash/fp/filter";
// import ANX2_PR_B2B from './ANX2_PR_B2B';
import ANX2B2B from "./ANX2B2B";
import ANX2DE from "./ANX2DE";
import ANX2SEZWP from "./ANX2SEZWP";
import ANX2SEZWOP from "./ANX2SEZWOP";
import Items from "../GSTN_Sync/Items";
var comp_id = "...";

const Anx2Register = props => {
  return (
    <Root>
      <Content>
        <Row>
          <Button
            id="btn_1"
            size="large"
            icon="cloud-upload"
            style={{
              backgroundColor: "#2382c7",
              color: "white"
            }}
            onClick={props.reconcile}
          >
            ANX2 Summary
          </Button>
          <DropZone
            onDrop={props.onDropAnx2}
            multiple={false}
            disabled={props.role === "reviewer"}
            style={{ marginLeft: "5px" }}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ display: "inline-block" }} {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  id="btn_2"
                  size="large"
                  icon="cloud-upload"
                  style={{
                    backgroundColor: "#2382c7",
                    color: "white",
                    marginLeft: "5px"
                  }}
                  disabled={props.role === "reviewer"}
                >
                  Upload Purchase Register
                </Button>
              </div>
            )}
          </DropZone>
          <Button
            id="btn_3"
            size="large"
            icon="cloud-upload"
            onClick={props.onAnx2}
            style={{
              backgroundColor: "#2382c7",
              color: "white",
              marginLeft: "5px"
            }}
            disabled={props.role === "reviewer"}
          >
            Get ANX2
          </Button>
          <Button
            id="btn_4"
            size="large"
            icon="cloud-upload"
            style={{
              backgroundColor: "#2382c7",
              color: "white",
              marginLeft: "5px"
            }}
            onClick={props.reconcile}
          >
            Match Now
          </Button>
          <Tooltip
            title="Clear all Purchases for selected Tax Period"
            overlay=""
          >
            <Popconfirm
              placement="bottomRight"
              onVisibleChange={props.popupConfirmResetVisibleChange}
              visible={props.popupConfirmReset}
              title="Clicking Yes will reset all Purchases for selected Tax Period!"
              onConfirm={props.resetPurchase}
              okText="Yes"
              cancelText="No"
            >
              <Button
                style={{
                  width: "200px",
                  fontSize: "14px",
                  backgroundColor: "#a01208de",
                  color: "white",
                  padding: "0 10px"
                }}
                size="large"
                icon="safety"
                disabled={props.role === "reviewer"}
              >
                Reset Uploaded Invoices
              </Button>
            </Popconfirm>
          </Tooltip>
        </Row>
        {props.summary && props.pageNumber === 1 && (
          <Row>
            <Table
              style={{ marginTop: "15px" }}
              bordered
              title={() => "Summary of Uploaded Purchases"}
              columns={props.summary.columns}
              dataSource={props.summary.data}
            ></Table>
          </Row>
        )}
        <Modal
          centered
          width={"90%"}
          visible={props.summary_modal_visible}
          onCancel={props.handleCancel}
          onOk={props.handleCancel}
        >
          {/* {props.summary_modal_source === "b2b" && <ANX2_PR_B2B />} */}
          {/* {props.summary_modal_source === "de" && <ANX2_PR_DE />} */}
          {props.summary_modal_source === "anx2-b2b" && <ANX2B2B />}
          {props.summary_modal_source === "anx2-de" && <ANX2DE />}
          {props.summary_modal_source === "anx2-sezwp" && <ANX2SEZWP />}
          {props.summary_modal_source === "anx2-sezwop" && <ANX2SEZWOP />}
        </Modal>
        {props.recon_summary && props.pageNumber === 1 && (
          <Row>
            <Table
              style={{ marginTop: "15px" }}
              bordered
              title={() => "Summary of Purchases from ANX2"}
              columns={props.recon_summary.columns}
              dataSource={props.recon_summary.data}
            ></Table>
          </Row>
        )}
        {props.summary && props.pageNumber === 4 && (
          <Row>
            <Anx2Reconcile {...props} recon_results={props.recon_results} />
          </Row>
        )}
      </Content>
    </Root>
  );
};

class Anx2DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year = curyear + "-" + nextyear;

    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
        response_delete: null
      };
    } else {
      this.gsp = {};
    }

    message.config({
      maxCount: 1
    });

    this.state = {
      pull2A: false,
      finMonth: props.finMonth,
      finYear: props.finYear,
      pageNumber: 1,
      companyId: props.selectedCompany,
      popupConfirmReset: false,
      summary_columns: [
        {
          title: "TABLE",
          dataIndex: "type",
          key: "type"
        },
        {
          title: "Documents",
          dataIndex: "record_count",
          key: "record_count",
          render: (text, record) => (
            <span>
              <a
                href="javascript:void(0);"
                id={record.type}
                style={{ textDecoration: "underline", color: "blue" }}
                onClick={this.showModal}
              >
                {record.record_count}
              </a>
            </span>
          )
        },
        {
          title: "Total Tax",
          dataIndex: "total_tax",
          key: "total_tax"
        },
        {
          title: "IGST",
          dataIndex: "igst",
          key: "igst"
        },
        {
          title: "CGST",
          dataIndex: "cgst",
          key: "cgst"
        },
        {
          title: "SGST",
          dataIndex: "sgst",
          key: "sgst"
        },
        {
          title: "CESS",
          dataIndex: "cess",
          key: "cess"
        }
      ],
      summary_modal_source: null,
      summary_modal_visible: false
    };
    comp_id = props.selectedCompany;
  }

  popupConfirmResetVisibleChange = e => {
    if (!e) {
      this.setState({ popupConfirmReset: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset: true });
      } else {
        this.setState({ popupConfirmReset: false });
      }
    }
  };

  resetPurchase = () => {
    var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    fetch(
      `${BASE_URL}/export/V2_ResetPurchase?companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    ).then(result => {
      console.log(result);
      this.props.summary_refetch();
      message.success("All Purchases been reset for selected tax period");
    });
  };

  componentDidUpdate = () => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp =
        this.gsp.otp !== undefined && this.gsp.otp !== null
          ? this.gsp.otp
          : this.props.gspDetails.otp;
    }
  };

  showModal = e => {
    console.log(e.target.id.toLowerCase());
    this.setState({
      summary_modal_source: e.target.id.toLowerCase(),
      summary_modal_visible: true
    });
  };

  handleCancel = e => {
    this.setState({
      summary_modal_visible: false
    });
  };
  reconcile = e => {
    const page_number = parseInt(e.target.id.split("_")[1]);
    if (page_number === 4) {
      message.loading("Matching records.. please wait!!", 0);
      var downloaddate = this.props.finMonth + "/" + this.props.finYear;
      fetch(
        `${BASE_URL}/export/reconcile?companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(async result => {
          const recon_results = [
            {
              key: 0,
              type: "Matched Invoices",
              results: result.match,
              count: result.match ? result.match.length : 0
            },
            {
              key: 1,
              type: "MisMatched Invoices",
              results: result.mismatch,
              count: result.mismatch ? result.mismatch.length : 0
            },
            {
              key: 2,
              type: "Only In Purchase Register",
              results: result.onlyInPR,
              count: result.onlyInPR ? result.onlyInPR.length : 0
            },
            {
              key: 3,
              type: "Only In Anx2",
              results: result.onlyInRecon,
              count: result.onlyInRecon ? result.onlyInRecon.length : 0
            },
            {
              key: 4,
              type: "Likely Matches",
              results: result.likely,
              count: result.likely ? result.likely.length : 0
            }
          ];
          this.setState({
            pageNumber: page_number,
            recon_results: recon_results
          });
          message.destroy();
        });
    } else {
      this.setState({ pageNumber: page_number });
    }
  };

  onDropAnx2 = async acceptedFiles => {
    var el = document.body,
      elChild = document.createElement("div");
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById("root").classList.add("blur");
    const result = await Purchase.upload({
      file: acceptedFiles[0],
      type: "pranx2",
      companyId: this.props.selectedCompany,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear
    });
    document.body.getElementsByClassName("root_div")[0].remove(); // Get the <ul> element with id="myList"
    document.getElementById("root").classList.remove("blur");
    this.props.summary_refetch();

    // if ((result.error_status !== '') && (result.error_status !== undefined)) {
    //     //document.getElementById('sales_error_status').click();
    //     console.log('-----------------ERROR REPORT----------------');
    //     console.log(result.error_status);
    //     console.log('---------------------------------------------');
    // }
    // if (result.filestatus === true) {
    //     if (result.flag === true) {
    //         var dialogue_msg = '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + result.total_errors + ' Invoices Failed out of ' + result.total + ' Invoices</b></span>) Anx1 Sales Register partially saved!</h4></p><br><p>Error Excel will be downloaded. Please check and correct the Errors!</p></div>';
    //         var div = document.getElementById('dialogue_append1');
    //         if (document.getElementById('dialogue_append1') !== null) {
    //             div.innerHTML += dialogue_msg;
    //         }
    //     }
    //     /************************************************************************************/
    //     if (result.total_errors > 0) {
    //         //message.error('(' + total_negative + '/' + result.total + ') Purchase data upload Failed!')
    //     }
    //     else {
    //         message.success('(' + result.total + '/' + result.total + ') Sales Invoices uploaded successfully!', 8)
    //     }
    //     // this.props.data.refetch()
    //     // this.props.gstr1summary.refetch()
    // } else if (result.file === false) {
    //     message.error('Uploaded file is not in Tally Excel format!', 5)
    // } else {
    //     message.error('Upload File Type is Not Allowed', 10)
    // }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  save2AFormRef = formRef => {
    this.form2ARef = formRef;
  };

  onAnx2 = () => {
    this.setState({ pull2A: true });
  };

  handleGet2ACancel = () => {
    this.setState({ pull2A: false });
  };

  handleGet2ASubmit = () => {
    const form = this.form2ARef.props.form;
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      form.resetFields();
      
      const fromDate = fieldsValue["from_month"].format("MMYYYY");
      const toDate = fieldsValue["to_month"].format("MMYYYY");
      this.setState({ pull2A: false, call2A: true });
      message.loading("Getting records... please wait!!", 0);
      const data = this.getCounterPartyData(fromDate, toDate);
    });
  };

  periodArrayValues = (a, b) => {
    var dateStart = moment(a, "MMYYYY");
    var dateEnd = moment(b, "MMYYYY");
    // console.log("dateStart :" + dateStart + "---" + "dateEnd :" + dateEnd);
    var period = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("M") === dateEnd.format("M")
    ) {
      period.push(dateStart.format("MMYYYY"));
      dateStart.add(1, "month");
    }
    return period;
  };

  getCounterPartyData = async (fromPeriod, toPeriod) => {
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const periodArrayValuesfor2A = this.periodArrayValues(fromPeriod, toPeriod);

    // console.log(periodArrayValuesfor2A);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      period: period
    };

    console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    console.log(this.gsp.response);

    // message.destroy();

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({
          requestOTP: true,
          fromPeriod: fromPeriod,
          toPeriod: toPeriod,
          fromPullData: true,
          fromReconcile: null
        });
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      // console.log('Done Ledger');
      const gstr2A_B2B_Loop_Action = map(async d => {
        // console.log(d);
        const reqData = {
          companyDetails: companyDetails,
          gspDetails: {
            gspToken: this.gsp.response.gspAuth.access_token,
            otp: this.gsp.otp
          },
          toPeriod: toPeriod,
          period: d
        };
        this.gsp.response = await gspAuth.getCounterPartyData(
          reqData,
          "B2B",
          2
        );

        if (this.gsp.response.status === true) {
          message.destroy();
          this.props.summary_refetch();

          notification.open({
            message: "Success",
            description: "ANX2 fetched successfully!!",
            duration: 100,
            icon: <Icon type="check-circle" style={{ color: "#008000" }} />
          });
        }
      })(periodArrayValuesfor2A);
    }
  };

  render() {
    // console.log(this.props);
    return (
      <span>
        <Anx2Register
          onDropAnx2={this.onDropAnx2}
          onAnx2={this.onAnx2}
          companyId={this.props.selectedCompany}
          finMonth={this.props.finMonth}
          finYear={this.props.finYear}
          handleCancel={this.handleCancel}
          pageNumber={this.state.pageNumber}
          reconcile={this.reconcile}
          recon_results={this.state.recon_results}
          summary={{
            data: this.props.summary,
            columns: this.state.summary_columns
          }}
          recon_summary={{
            data: this.props.recon_summary,
            columns: this.state.summary_columns
          }}
          summary_modal_visible={this.state.summary_modal_visible}
          summary_modal_source={this.state.summary_modal_source}
          popupConfirmResetVisibleChange={this.popupConfirmResetVisibleChange}
          popupConfirmReset={this.state.popupConfirmReset}
          resetPurchase={this.resetPurchase}
        />
        <Get2AForm
          wrappedComponentRef={this.save2AFormRef}
          visible={this.state.pull2A}
          onSubmit={this.handleGet2ASubmit}
          onCancel={this.handleGet2ACancel}
        />
      </span>
    );
  }
}

const prSummaryConfig = {
  name: "summary",
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finyear: props.finYear,
      finmonth: props.finMonth + ""
    }
  }),
  props: ({ summary }) => {
    return {
      summary: get("viewer.v2_pr_summary.edges", summary),
      recon_summary: get("viewer.v2_recon_pr_summary.edges", summary),
      summary_refetch: get("refetch", summary)
    };
  }
};

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth
    }
  }),
  props: ({ company }) => {
    return {
      company: get("viewer.company", company),
      gspDetails: get("viewer.gspInfo", company),
      externalInterfaceInfo: get("viewer.externalInterfaceInfo", company)
    };
  }
};

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  connect(mapStateToProps, {
    setGspToken: gspActions.setGspToken,
    setGspOtp: gspActions.setGspOtp,
    setGspDetails: gspActions.setGspDetails
  }),
  graphql(gql(V2_PR_Summary_Query), prSummaryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(Anx2DashboardContainer);
