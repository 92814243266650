import React from 'react'
import { Table } from 'antd';
const GSTR9Pt1 = props => {
    const columns = [
      {
          title: 'Pt. I',
          dataIndex: 'sno',
          key: 'sno',
          width: '5%',
      },
      {
          title: '',
          dataIndex: 'det',
          key: 'det',
          width: '15%',
      },
      {
          title: '',
          dataIndex: 'value',
          key: 'value',
          width: '80%',
      }
  ]
    return (
        <Table bordered title={() => "Basic Details"} size="small" dataSource={props.pt1.dataSource} columns={columns} pagination={false} />
    )
}

export default GSTR9Pt1