import React from "react";
import { Tabs, Icon } from 'antd';
import LedgerBalance from './LedgerBalance'
import TransactionDetails from './TransactionDetails'
import { Root, ContentWrapper as Content } from "../../shared/styles";
const TabPane = Tabs.TabPane;

class TG_Utility_UI extends React.Component {
  
  render() {
    
    const maxWidth = window.innerWidth - 215 + "px";

    return (
      
        <Root>
          <Content>
          <Tabs defaultActiveKey="1">
            <TabPane tab={<span><Icon type="diff" />Ledger Balance</span>} key="1">
              <LedgerBalance />
            </TabPane>
            <TabPane tab={<span><Icon type="snippets" />Transactions</span>} key="2">
              <TransactionDetails />
            </TabPane>
          </Tabs>
          </Content>
        </Root>
      
    );
  }
}

export default TG_Utility_UI

