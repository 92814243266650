import React from 'react'
import { Route } from 'react-router-dom'
import GSTR2Dashboard from './GSTR2Dashboard'
import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'


export default () =>
    <Root>
      <Route exact path={ROUTES.GST_FILINGS_GSTR2} component={GSTR2Dashboard} />
    </Root>
