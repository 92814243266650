import React from 'react'
import GSTR9Pt6Sec15 from './GSTR9Pt6Sec15'
import GSTR9Pt6Sec16 from './GSTR9Pt6Sec16'
import GSTR9Pt6Sec17 from './GSTR9Pt6Sec17'
import GSTR9Pt6Sec18 from './GSTR9Pt6Sec18'
const GSTR9Pt6 = props => {
    return (
      <div>
        {props.pt6sec15.dataSource && props.pt6sec15.dataSource.length > 0 ? (<GSTR9Pt6Sec15 {...props}></GSTR9Pt6Sec15>) : null}
        {props.pt6sec16.dataSource && props.pt6sec16.dataSource.length > 0 ? (<GSTR9Pt6Sec16 {...props}></GSTR9Pt6Sec16>) : null}
        {props.pt6sec17.dataSource ? (<GSTR9Pt6Sec17 {...props}></GSTR9Pt6Sec17>) : null}
        {props.pt6sec18.dataSource ? (<GSTR9Pt6Sec18 {...props}></GSTR9Pt6Sec18>) : null}
        {/* {props.pt6sec14.dataSource && props.pt6sec14.dataSource.length > 0 ? (<GSTR9Pt6Sec14 {...props}></GSTR9Pt6Sec14>) : null} */}
      </div>
    )
  }

  export default GSTR9Pt6