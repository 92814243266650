import { cat } from "shelljs";

const XLSX = require("xlsx");
const moment = require("moment");
const { getPlaceOfSupply } = require("../shared/constants");
var fileSaver = require("file-saver");
var blob = require("blob-util");

const {
  flow,
  map,
  flattenDeep,
  find,
  findIndex,
  filter,
  omit,
} = require("lodash/fp");

const excelUtility = {
  reconPR: (
    match,
    miss,
    only_pr,
    recon_pr,
    likely_b2b,
    ineligible_b2b,

    match_2,
    miss_2,
    only_pr_2,
    recon_pr_2,
    likely_b2b_2,
    ineligible_b2b_2,

    match_cdnr,
    miss_cdnr,
    only_pr_cdnr,
    recon_pr_cdnr,
    likely_cdnr,
    ineligible_cdnr,

    match_cdnr_2,
    miss_cdnr_2,
    only_pr_cdnr_2,
    recon_pr_cdnr_2,
    likely_cdnr_2,
    ineligible_cdnr_2,

    match_b2ba,
    miss_b2ba,
    recon_pr_b2ba,

    match_b2ba_2,
    miss_b2ba_2,
    recon_pr_b2ba_2,

    match_cdna,
    miss_cdna,
    recon_pr_cdna,

    match_cdna_2,
    miss_cdna_2,
    recon_pr_cdna_2,

    finYear,
    nextFY
  ) => {
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    const getGSTNData = (source, item, invoice) => {
      if (item.item_type !== "RECONPRB2B") return invoice;
      const value = invoice.supplier_invoicenumber
        ? invoice.supplier_invoicenumber
        : invoice.invoice_number;
      return source[
        findIndex(
          (d) =>
            d.invoice_number.toLowerCase() === value.toLowerCase() &&
            (d.type.toLowerCase() === "reconprb2b" ||
              d.type.toLowerCase() === "reconprb2ba"),
          source
        )
      ];
    };
    const getGSTNDataCDNR = (source, item, invoice) => {
      if (item.item_type !== "RECONPRCDNR") return invoice;
      const value = invoice.supplier_invoicenumber
        ? invoice.supplier_invoicenumber
        : invoice.cdnr_number;
      return source[
        findIndex(
          (d) =>
            d.cdnr_number.toLowerCase() === value.toLowerCase() &&
            (d.type.toLowerCase() === "reconprcdnr" ||
              d.type.toLowerCase() === "reconprcdna"),
          source
        )
      ];
    };
    var wb_new = XLSX.utils.book_new();

    let summary_report_b2b = [];
    let summary_report_cdnr = [];

    // For Summary sheet
    try {
      wb_new.SheetNames.push("Summary Report");
    } catch (e) {
      console.log(e);
    }


    try {
      if (match !== undefined && match !== null && match.length !== 0) {
        wb_new.SheetNames.push("Matched - B2B - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (match_2 !== undefined && match_2 !== null && match_2.length !== 0) {
        wb_new.SheetNames.push("Matched - B2B - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (miss !== undefined && miss !== null && miss.length !== 0) {
        wb_new.SheetNames.push("Mismatched - B2B - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (miss_2 !== undefined && miss_2 !== null && miss_2.length !== 0) {
        wb_new.SheetNames.push("Mismatched - B2B - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (only_pr !== undefined && only_pr !== null && only_pr.length !== 0) {
        wb_new.SheetNames.push("Standalone - B2B - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        only_pr_2 !== undefined &&
        only_pr_2 !== null &&
        only_pr_2.length !== 0
      ) {
        wb_new.SheetNames.push("Standalone - B2B - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr !== undefined &&
        recon_pr !== null &&
        recon_pr.length !== 0
      ) {
        wb_new.SheetNames.push("GST - B2B - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_2 !== undefined &&
        recon_pr_2 !== null &&
        recon_pr_2.length !== 0
      ) {
        wb_new.SheetNames.push("GST - B2B - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_b2b !== undefined &&
        likely_b2b !== null &&
        likely_b2b.length !== 0
      ) {
        wb_new.SheetNames.push("Likely - B2B - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_b2b_2 !== undefined &&
        likely_b2b_2 !== null &&
        likely_b2b_2.length !== 0
      ) {
        wb_new.SheetNames.push("Likely - B2B - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_b2b !== undefined &&
        ineligible_b2b !== null &&
        ineligible_b2b.length !== 0
      ) {
        wb_new.SheetNames.push("Ineligible - B2B - " + finYear);
      }
    } catch (e) {
      if (
        ineligible_b2b_2 !== undefined &&
        ineligible_b2b_2 !== null &&
        ineligible_b2b_2.length !== 0
      ) {
        wb_new.SheetNames.push("Ineligible - B2B - " + nextFY);
      }
    }

    //b2ba

    try {
      if (
        match_b2ba !== undefined &&
        match_b2ba !== null &&
        match_b2ba.length !== 0
      ) {
        wb_new.SheetNames.push("Matched - B2BA - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        match_b2ba_2 !== undefined &&
        match_b2ba_2 !== null &&
        match_b2ba_2.length !== 0
      ) {
        wb_new.SheetNames.push("Matched - B2BA - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_b2ba !== undefined &&
        miss_b2ba !== null &&
        miss_b2ba.length !== 0
      ) {
        wb_new.SheetNames.push("Mismatched - B2BA - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_b2ba_2 !== undefined &&
        miss_b2ba_2 !== null &&
        miss_b2ba_2.length !== 0
      ) {
        wb_new.SheetNames.push("Mismatched - B2BA - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_b2ba !== undefined &&
        recon_pr_b2ba !== null &&
        recon_pr_b2ba.length !== 0
      ) {
        wb_new.SheetNames.push("GST - B2BA - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_b2ba_2 !== undefined &&
        recon_pr_b2ba_2 !== null &&
        recon_pr_b2ba_2.length !== 0
      ) {
        wb_new.SheetNames.push("GST - B2BA - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    //cdnr
    try {
      if (
        match_cdnr !== undefined &&
        match_cdnr !== null &&
        match_cdnr.length !== 0
      ) {
        wb_new.SheetNames.push("Matched - CDNR - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        match_cdnr_2 !== undefined &&
        match_cdnr_2 !== null &&
        match_cdnr_2.length !== 0
      ) {
        wb_new.SheetNames.push("Matched - CDNR - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdnr !== undefined &&
        miss_cdnr !== null &&
        miss_cdnr.length !== 0
      ) {
        wb_new.SheetNames.push("Mismatched - CDNR - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdnr_2 !== undefined &&
        miss_cdnr_2 !== null &&
        miss_cdnr_2.length !== 0
      ) {
        wb_new.SheetNames.push("Mismatched - CDNR - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        only_pr_cdnr !== undefined &&
        only_pr_cdnr !== null &&
        only_pr_cdnr.length !== 0
      ) {
        wb_new.SheetNames.push("Standalone - CDNR - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        only_pr_cdnr_2 !== undefined &&
        only_pr_cdnr_2 !== null &&
        only_pr_cdnr_2.length !== 0
      ) {
        wb_new.SheetNames.push("Standalone - CDNR - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdnr !== undefined &&
        recon_pr_cdnr !== null &&
        recon_pr_cdnr.length !== 0
      ) {
        wb_new.SheetNames.push("GST - CDNR - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdnr_2 !== undefined &&
        recon_pr_cdnr_2 !== null &&
        recon_pr_cdnr_2.length !== 0
      ) {
        wb_new.SheetNames.push("GST - CDNR - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_cdnr !== undefined &&
        likely_cdnr !== null &&
        likely_cdnr.length !== 0
      ) {
        wb_new.SheetNames.push("Likely - CDNR - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_cdnr_2 !== undefined &&
        likely_cdnr_2 !== null &&
        likely_cdnr_2.length !== 0
      ) {
        wb_new.SheetNames.push("Likely - CDNR - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_cdnr !== undefined &&
        ineligible_cdnr !== null &&
        ineligible_cdnr.length !== 0
      ) {
        wb_new.SheetNames.push("Ineligible - CDNR - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_cdnr_2 !== undefined &&
        ineligible_cdnr_2 !== null &&
        ineligible_cdnr_2.length !== 0
      ) {
        wb_new.SheetNames.push("Ineligible - CDNR - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    //cdna
    try {
      if (
        match_cdna !== undefined &&
        match_cdna !== null &&
        match_cdna.length !== 0
      ) {
        wb_new.SheetNames.push("Matched - CDNA - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        match_cdna_2 !== undefined &&
        match_cdna_2 !== null &&
        match_cdna_2.length !== 0
      ) {
        wb_new.SheetNames.push("Matched - CDNA - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdna !== undefined &&
        miss_cdna !== null &&
        miss_cdna.length !== 0
      ) {
        wb_new.SheetNames.push("Mismatched - CDNA - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdna_2 !== undefined &&
        miss_cdna_2 !== null &&
        miss_cdna_2.length !== 0
      ) {
        wb_new.SheetNames.push("Mismatched - CDNA - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdna !== undefined &&
        recon_pr_cdna !== null &&
        recon_pr_cdna.length !== 0
      ) {
        wb_new.SheetNames.push("GST - CDNA - " + finYear);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdna_2 !== undefined &&
        recon_pr_cdna_2 !== null &&
        recon_pr_cdna_2.length !== 0
      ) {
        wb_new.SheetNames.push("GST - CDNA - " + nextFY);
      }
    } catch (e) {
      console.log(e);
    }

    //Ineligible CDNR
    var ineligibleCdnrDataList = [];
    try {
      if (ineligible_cdnr !== undefined && ineligible_cdnr !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,
                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : InvoiceItems.igst === null
                      ? ""
                      : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : InvoiceItems.cgst === null
                      ? ""
                      : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : InvoiceItems.sgst === null
                      ? ""
                      : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : InvoiceItems.cess === null
                      ? ""
                      : parseFloat(InvoiceItems.cess),
              };
            })(
              Inv.ineligible_cdnr_invoice_items
                ? Inv.ineligible_cdnr_invoice_items
                : Inv.pr_cdnr_items
                  ? Inv.pr_cdnr_items
                  : Inv.recon_pr_cdnr_items
            );
            return InvoiceItems;
          }),
          flattenDeep
        )(ineligible_cdnr);

        ineligibleCdnrDataList = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var ineligibleCdnrDataList_2 = [];
    try {
      if (ineligible_cdnr_2 !== undefined && ineligible_cdnr_2 !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,
                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : InvoiceItems.igst === null
                      ? ""
                      : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : InvoiceItems.cgst === null
                      ? ""
                      : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : InvoiceItems.sgst === null
                      ? ""
                      : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : InvoiceItems.cess === null
                      ? ""
                      : parseFloat(InvoiceItems.cess),
              };
            })(
              Inv.ineligible_cdnr_invoice_items
                ? Inv.ineligible_cdnr_invoice_items
                : Inv.pr_cdnr_items
                  ? Inv.pr_cdnr_items
                  : Inv.recon_pr_cdnr_items
            );
            return InvoiceItems;
          }),
          flattenDeep
        )(ineligible_cdnr_2);

        ineligibleCdnrDataList_2 = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Matched Purchase Transactions
    var MatchcdnrDataList = [];
    try {
      if (match_cdnr !== undefined && match_cdnr !== null) {
        let summary_line_item_matched_cdnr = {
          "Particulars": "Matched Purchase Transactions",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              summary_line_item_matched_cdnr["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
              summary_line_item_matched_cdnr["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
              summary_line_item_matched_cdnr["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
              summary_line_item_matched_cdnr["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
              summary_line_item_matched_cdnr["Cess"] += parseFloat(InvoiceItems.cess ?? 0);

              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Supplier Voucher Number": Inv.supplier_invoicenumber,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_cdnr);

        MatchcdnrDataList = MatchTransOnly;
        summary_report_cdnr.push(summary_line_item_matched_cdnr);
      }
    } catch (e) {
      console.log(e);
    }

    var MatchcdnrDataList_2 = [];
    try {
      if (match_cdnr_2 !== undefined && match_cdnr_2 !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Supplier Voucher Number": Inv.supplier_invoicenumber,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Invoice Value': parseFloat(Inv.invoice_value),
                // 'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
                // 'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_cdnr_2);

        MatchcdnrDataList_2 = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Mismatched Purchase Transactions
    var MissMatchPRcdnrDataList = [];
    try {
      if (miss_cdnr !== undefined && miss_cdnr !== null) {
        let summary_line_item_mismatched_cdnr = {
          "Particulars": "Mismatched Purchase Transactions",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              const i =
                InvoiceItems.item_type === "RECONPRCDNR"
                  ? getGSTNDataCDNR(miss_cdnr, InvoiceItems, Inv)
                  : Inv;
              if (InvoiceItems.item_type === "RECONPRCDNR") {
                summary_line_item_mismatched_cdnr["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
                summary_line_item_mismatched_cdnr["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
                summary_line_item_mismatched_cdnr["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
                summary_line_item_mismatched_cdnr["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
                summary_line_item_mismatched_cdnr["Cess"] += parseFloat(InvoiceItems.cess ?? 0);
              }

              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Invoice/Advance Payment Voucher Number": i.invoice_number,
                "Invoice/Advance Payment Voucher date": i.invoice_date,

                "Note/Refund Voucher Number": i.cdnr_number,
                "Note/Refund Voucher date": i.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(i.cdnr_value),
                "Document Type":
                  i.cdnr_type === "D"
                    ? "Debit"
                    : i.cdnr_type === "C"
                      ? "Credit"
                      : i.cdnr_type,

                // 'Place Of Supply': find({pos_id: i.pos_id+""})(stateArray).state_name,
                "Pre GST": i.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_cdnr);
        MissMatchPRcdnrDataList = MisMatchTransOnly;
        summary_report_cdnr.push(summary_line_item_mismatched_cdnr);
      }
    } catch (e) {
      console.log(e);
    }

    var MissMatchPRcdnrDataList_2 = [];
    try {
      if (miss_cdnr_2 !== undefined && miss_cdnr_2 !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              const i =
                InvoiceItems.item_type === "RECONPRCDNR"
                  ? getGSTNDataCDNR(miss_cdnr_2, InvoiceItems, Inv)
                  : Inv;
              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Invoice/Advance Payment Voucher Number": i.invoice_number,
                "Invoice/Advance Payment Voucher date": i.invoice_date,

                "Note/Refund Voucher Number": i.cdnr_number,
                "Note/Refund Voucher date": i.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(i.cdnr_value),
                "Document Type":
                  i.cdnr_type === "D"
                    ? "Debit"
                    : i.cdnr_type === "C"
                      ? "Credit"
                      : i.cdnr_type,

                // 'Place Of Supply': find({pos_id: i.pos_id+""})(stateArray).state_name,
                "Pre GST": i.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_cdnr_2);
        MissMatchPRcdnrDataList_2 = MisMatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Likely Purchase Transactions
    var LikelyMatchPRcdnrDataList = [];
    try {
      if (likely_cdnr !== undefined && likely_cdnr !== null) {
        const LikelyTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
                tempID: Inv.tempID,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prcdnr" })(likely_cdnr));

        console.log(LikelyTransOnly);

        const LikelyTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                tempID: Inv.tempID,
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                From: "GSTN",
              })(LikelyTransOnly);
              console.log(prInvoice);
              map((d) => {
                d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin;
                d["Note/Refund Voucher Number"] = Inv.cdnr_number;
                // d['Invoice Value'] = parseFloat(Inv.invoice_value);
                // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

                d["Note/Refund Voucher date"] = Inv.cdnr_date;
                d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
                d["Document Type"] =
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type;

                // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
                d["Pre GST"] = Inv.pre_gst;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprcdnr" })(likely_cdnr));

        LikelyMatchPRcdnrDataList = map((d) => {
          return omit("tempID")(d);
        })(LikelyTransOnly);
      }
    } catch (e) {
      console.log(e);
    }

    var LikelyMatchPRcdnrDataList_2 = [];
    try {
      if (likely_cdnr_2 !== undefined && likely_cdnr_2 !== null) {
        const LikelyTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
                tempID: Inv.tempID,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prcdnr" })(likely_cdnr_2));

        const LikelyTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                tempID: Inv.tempID,
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                From: "GSTN",
              })(LikelyTransOnly);
              console.log(prInvoice);
              map((d) => {
                d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin;
                d["Note/Refund Voucher Number"] = Inv.cdnr_number;
                // d['Invoice Value'] = parseFloat(Inv.invoice_value);
                // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

                d["Note/Refund Voucher date"] = Inv.cdnr_date;
                d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
                d["Document Type"] =
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type;

                // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
                d["Pre GST"] = Inv.pre_gst;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprcdnr" })(likely_cdnr_2));

        LikelyMatchPRcdnrDataList_2 = map((d) => {
          return omit("tempID")(d);
        })(LikelyTransOnly);
      }
    } catch (e) {
      console.log(e);
    }

    //Transactions from GST System
    var reconPRcdnrDataList = [];
    try {
      if (recon_pr_cdnr !== undefined && recon_pr_cdnr !== null) {
        let summary_line_item_recon_pr_cdnr = {
          "Particulars": "Not Reflected in Books",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              summary_line_item_recon_pr_cdnr["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
              summary_line_item_recon_pr_cdnr["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
              summary_line_item_recon_pr_cdnr["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
              summary_line_item_recon_pr_cdnr["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
              summary_line_item_recon_pr_cdnr["Cess"] += parseFloat(InvoiceItems.cess ?? 0);

              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id ? Inv.pos_id+"" : '33'})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_cdnr);
        reconPRcdnrDataList = PRTransOnly;
        summary_report_cdnr.push(summary_line_item_recon_pr_cdnr);
      }
    } catch (e) {
      console.log(e);
    }

    var reconPRcdnrDataList_2 = [];
    try {
      if (recon_pr_cdnr_2 !== undefined && recon_pr_cdnr_2 !== null) {
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id ? Inv.pos_id+"" : '33'})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_cdnr_2);

        reconPRcdnrDataList_2 = PRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Standalone Purchase Transactions
    var onlyPRPRcdnrDataList = [];
    try {
      if (only_pr_cdnr !== undefined && only_pr_cdnr !== null) {
        let summary_line_item_onlyPR_cdnr = {
          "Particulars": "Not Reflected in GSTR",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              summary_line_item_onlyPR_cdnr["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
                summary_line_item_onlyPR_cdnr["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
                summary_line_item_onlyPR_cdnr["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
                summary_line_item_onlyPR_cdnr["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
                summary_line_item_onlyPR_cdnr["Cess"] += parseFloat(InvoiceItems.cess ?? 0);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Invoice Value': parseFloat(Inv.invoice_value),
                // 'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
                // 'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(only_pr_cdnr);

        onlyPRPRcdnrDataList = StandAlonePRTransOnly;
        summary_report_cdnr.push(summary_line_item_onlyPR_cdnr);
      }
    } catch (e) {
      console.log(e);
    }

    var onlyPRPRcdnrDataList_2 = [];
    try {
      if (only_pr_cdnr_2 !== undefined && only_pr_cdnr_2 !== null) {
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(only_pr_cdnr_2);
        onlyPRPRcdnrDataList_2 = StandAlonePRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    

    //CDNA

    var MatchcdnaDataList = [];
    try {
      if (match_cdna !== undefined && match_cdna !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Note Number": Inv.og_note_num,
                "Original Note Date": Inv.og_note_dt,
                "Revised Note Type":
                  Inv.note_type === "D"
                    ? "Debit"
                    : Inv.note_type === "C"
                      ? "Credit"
                      : Inv.note_type,
                "Revised Note Number": Inv.cdnr_number,
                "Revised Note Date": Inv.cdnr_date,
                "Revised Invoice Number": Inv.invoice_number,
                "Revised Invoice date": Inv.invoice_date,
                "Revised Note Value": parseFloat(Inv.cdnr_value),

                // 'Invoice Value': parseFloat(Inv.invoice_value),
                // 'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
                // 'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
                // "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_cdna);

        MatchcdnaDataList = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var MatchcdnaDataList_2 = [];
    try {
      if (match_cdna_2 !== undefined && match_cdna_2 !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Note Number": Inv.og_note_num,
                "Original Note Date": Inv.og_note_dt,
                "Revised Note Type":
                  Inv.note_type === "D"
                    ? "Debit"
                    : Inv.note_type === "C"
                      ? "Credit"
                      : Inv.note_type,
                "Revised Note Number": Inv.cdnr_number,
                "Revised Note Date": Inv.cdnr_date,
                "Revised Invoice Number": Inv.invoice_number,
                "Revised Invoice date": Inv.invoice_date,
                "Revised Note Value": parseFloat(Inv.cdnr_value),
                // "Supplier Voucher Number": Inv.supplier_invoicenumber,
                // 'Invoice Value': parseFloat(Inv.invoice_value),
                // 'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
                // 'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
                // "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_cdna_2);

        MatchcdnaDataList_2 = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var MissMatchPRcdnaDataList = [];
    try {
      if (miss_cdna !== undefined && miss_cdna !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              const i =
                InvoiceItems.item_type === "RECONPRCDNR"
                  ? getGSTNDataCDNR(miss_cdna, InvoiceItems, Inv)
                  : Inv;
              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Original Note Number": i.og_note_num,
                "Original Note Date": i.og_note_dt,
                "Revised Note Type":
                  i.note_type === "D"
                    ? "Debit"
                    : i.note_type === "C"
                      ? "Credit"
                      : i.note_type,
                "Revised Note Number": i.cdnr_number,
                "Revised Note Date": i.cdnr_date,
                "Revised Invoice Number": i.invoice_number,
                "Revised Invoice date": i.invoice_date,
                "Revised Note Value": parseFloat(i.cdnr_value),

                // 'Place Of Supply': find({pos_id: i.pos_id+""})(stateArray).state_name,
                // "Pre GST": i.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_cdna);
        MissMatchPRcdnaDataList = MisMatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var MissMatchPRcdnaDataList_2 = [];
    try {
      if (miss_cdna_2 !== undefined && miss_cdna_2 !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              const i =
                InvoiceItems.item_type === "RECONPRCDNR"
                  ? getGSTNDataCDNR(miss_cdna_2, InvoiceItems, Inv)
                  : Inv;
              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Original Note Number": i.og_note_num,
                "Original Note Date": i.og_note_dt,
                "Revised Note Type":
                  i.note_type === "D"
                    ? "Debit"
                    : i.note_type === "C"
                      ? "Credit"
                      : i.note_type,
                "Revised Note Number": i.cdnr_number,
                "Revised Note Date": i.cdnr_date,
                "Revised Invoice Number": i.invoice_number,
                "Revised Invoice date": i.invoice_date,
                "Revised Note Value": parseFloat(i.cdnr_value),

                // 'Place Of Supply': find({pos_id: i.pos_id+""})(stateArray).state_name,
                // "Pre GST": i.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_cdna_2);
        MissMatchPRcdnaDataList_2 = MisMatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var reconPRcdnaDataList = [];
    try {
      if (recon_pr_cdna !== undefined && recon_pr_cdna !== null) {
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Note Number": Inv.og_note_num,
                "Original Note Date": Inv.og_note_dt,
                "Revised Note Type":
                  Inv.note_type === "D"
                    ? "Debit"
                    : Inv.note_type === "C"
                      ? "Credit"
                      : Inv.note_type,
                "Revised Note Number": Inv.note_num,
                "Revised Note Date": Inv.note_dt,
                "Revised Invoice Number": Inv.invoice_num,
                "Revised Invoice date": Inv.invoice_dt,
                "Revised Note Value": parseFloat(Inv.note_val),

                // 'Place Of Supply': find({pos_id: Inv.pos_id ? Inv.pos_id+"" : '33'})(stateArray).state_name,
                // "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_cdna_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_cdna);

        console.log(PRTransOnly);

        reconPRcdnaDataList = PRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var reconPRcdnaDataList_2 = [];
    try {
      if (recon_pr_cdna_2 !== undefined && recon_pr_cdna_2 !== null) {
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Note Number": Inv.og_note_num,
                "Original Note Date": Inv.og_note_dt,
                "Revised Note Type":
                  Inv.note_type === "D"
                    ? "Debit"
                    : Inv.note_type === "C"
                      ? "Credit"
                      : Inv.note_type,
                "Revised Note Number": Inv.note_num,
                "Revised Note Date": Inv.note_dt,
                "Revised Invoice Number": Inv.invoice_num,
                "Revised Invoice date": Inv.invoice_dt,
                "Revised Note Value": parseFloat(Inv.note_val),
                // 'Place Of Supply': find({pos_id: Inv.pos_id ? Inv.pos_id+"" : '33'})(stateArray).state_name,
                // "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_cdna_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_cdna_2);

        reconPRcdnaDataList_2 = PRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //B2B
    //Ineligible B2B Purchase Transactions
    var ineligibleb2bDataList = [];
    try {
      if (ineligible_b2b !== undefined && ineligible_b2b !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : InvoiceItems.igst === null
                      ? ""
                      : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : InvoiceItems.cgst === null
                      ? ""
                      : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : InvoiceItems.sgst === null
                      ? ""
                      : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : InvoiceItems.cess === null
                      ? ""
                      : parseFloat(InvoiceItems.cess),
              };
            })(
              Inv.ineligible_invoice_items
                ? Inv.ineligible_invoice_items
                : Inv.pr_b2b_items
                  ? Inv.pr_b2b_items
                  : Inv.recon_pr_b2b_items
            );
            return InvoiceItems;
          }),
          flattenDeep
        )(ineligible_b2b);

        ineligibleb2bDataList = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var ineligibleb2bDataList_2 = [];
    try {
      if (ineligible_b2b_2 !== undefined && ineligible_b2b_2 !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : InvoiceItems.igst === null
                      ? ""
                      : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : InvoiceItems.cgst === null
                      ? ""
                      : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : InvoiceItems.sgst === null
                      ? ""
                      : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : InvoiceItems.cess === null
                      ? ""
                      : parseFloat(InvoiceItems.cess),
              };
            })(
              Inv.ineligible_invoice_items
                ? Inv.ineligible_invoice_items
                : Inv.pr_b2b_items
                  ? Inv.pr_b2b_items
                  : Inv.recon_pr_b2b_items
            );
            return InvoiceItems;
          }),
          flattenDeep
        )(ineligible_b2b_2);

        ineligibleb2bDataList_2 = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Matched Purchase Transactions
    var Matchb2bDataList = [];
    try {
      if (match !== undefined && match !== null) {
        let summary_line_item_matched_b2b = {
          "Particulars": "Matched Purchase Transactions",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              summary_line_item_matched_b2b["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
              summary_line_item_matched_b2b["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
              summary_line_item_matched_b2b["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
              summary_line_item_matched_b2b["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
              summary_line_item_matched_b2b["Cess"] += parseFloat(InvoiceItems.cess ?? 0);

              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Supplier Invoice Number": Inv.supplier_invoicenumber,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match);
        Matchb2bDataList = MatchTransOnly;
        summary_report_b2b.push(summary_line_item_matched_b2b);
      }
    } catch (e) {
      console.log(e);
    }

    var Matchb2bDataList_2 = [];
    try {
      if (match_2 !== undefined && match_2 !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Supplier Invoice Number": Inv.supplier_invoicenumber,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_2);

        Matchb2bDataList_2 = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Mismatched Purchase Transactions
    var MissMatchPRb2bDataList = [];
    try {
      if (miss !== undefined && miss !== null) {
        let summary_line_item_mismatched_b2b = {
          "Particulars": "Mismatched Purchase Transactions",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              const i =
                InvoiceItems.item_type === "RECONPRB2B"
                  ? getGSTNData(miss, InvoiceItems, Inv)
                  : Inv;
              if (InvoiceItems.item_type === "RECONPRB2B") {
                summary_line_item_mismatched_b2b["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
                summary_line_item_mismatched_b2b["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
                summary_line_item_mismatched_b2b["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
                summary_line_item_mismatched_b2b["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
                summary_line_item_mismatched_b2b["Cess"] += parseFloat(InvoiceItems.cess ?? 0);
              }
              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Invoice Number": i.invoice_number,
                "Invoice date": i.invoice_date,
                "Invoice Value": parseFloat(i.invoice_value),
                "Invoice Type":
                  i.invoice_type === "R" ? "Regular" : i.invoice_type,
                "Place Of Supply": i.pos_id
                  ? getPlaceOfSupply(i.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": i.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRB2B"
                    ? "Purchase Register"
                    : "GSTN",
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRB2B' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss);
        MissMatchPRb2bDataList = MisMatchTransOnly;
        summary_report_b2b.push(summary_line_item_mismatched_b2b);
      }
    } catch (e) {
      console.log(e);
    }

    var MissMatchPRb2bDataList_2 = [];
    try {
      if (miss_2 !== undefined && miss_2 !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              console.log(Inv);
              console.log(InvoiceItems);
              const i =
                InvoiceItems.item_type === "RECONPRB2B"
                  ? getGSTNData(miss_2, InvoiceItems, Inv)
                  : Inv;

              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Invoice Number": i.invoice_number,
                "Invoice date": i.invoice_date,
                "Invoice Value": parseFloat(i.invoice_value),
                "Invoice Type":
                  i.invoice_type === "R" ? "Regular" : i.invoice_type,
                "Place Of Supply": i.pos_id
                  ? getPlaceOfSupply(i.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": i.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRB2B"
                    ? "Purchase Register"
                    : "GSTN",
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRB2B' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_2);
        MissMatchPRb2bDataList_2 = MisMatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Likely Purchase Transactions
    var likelyPRb2bDataList = [];
    try {
      if (likely_b2b !== undefined && likely_b2b !== null) {
        const LikelyTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRB2B"
                    ? "Purchase Register"
                    : "GSTN",
                tempID: Inv.tempID,
                "Match Percentile": Inv.threshold,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRB2B' ? '***' : '')),
              };
            })(filter({ item_type: Inv.type.toUpperCase() })(Inv.items));
            return InvoiceItems;
          }),
          flattenDeep
        )(likely_b2b);

        // console.log(LikelyTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                tempID: Inv.tempID,
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                From: "GSTN",
              })(LikelyTransOnly);
              map((d) => {
                d["Invoice Number"] = Inv.invoice_number;
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Place Of Supply"] = Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "";
                d["Reverse Charge"] = Inv.reverse_charge;
                d["Match Percentile"] = Inv.threshold;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(likely_b2b));

        likelyPRb2bDataList = map((d) => {
          return omit("tempID")(d);
        })(LikelyTransOnly);
      }
    } catch (e) {
      console.log(e);
    }

    var likelyPRb2bDataList_2 = [];
    try {
      if (likely_b2b_2 !== undefined && likely_b2b_2 !== null) {
        const LikelyTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRB2B"
                    ? "Purchase Register"
                    : "GSTN",
                tempID: Inv.tempID,
                "Match Percentile": Inv.threshold,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRB2B' ? '***' : '')),
              };
            })(filter({ item_type: Inv.type.toUpperCase() })(Inv.items));
            return InvoiceItems;
          }),
          flattenDeep
        )(likely_b2b_2);

        // console.log(LikelyTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                tempID: Inv.tempID,
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                From: "GSTN",
              })(LikelyTransOnly);
              map((d) => {
                d["Invoice Number"] = Inv.invoice_number;
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Place Of Supply"] = Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "";
                d["Reverse Charge"] = Inv.reverse_charge;
                d["Match Percentile"] = Inv.threshold;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(likely_b2b_2));

        likelyPRb2bDataList_2 = map((d) => {
          return omit("tempID")(d);
        })(LikelyTransOnly);
      }
    } catch (e) {
      console.log(e);
    }

    //Transactions from GST System
    var reconPRb2bDataList = [];
    try {
      if (recon_pr !== undefined && recon_pr !== null) {
        let summary_line_item_recon_pr_b2b = {
          "Particulars": "Not Reflected in Books",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              summary_line_item_recon_pr_b2b["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
              summary_line_item_recon_pr_b2b["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
              summary_line_item_recon_pr_b2b["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
              summary_line_item_recon_pr_b2b["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
              summary_line_item_recon_pr_b2b["Cess"] += parseFloat(InvoiceItems.cess ?? 0);

              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": getPlaceOfSupply(
                  Inv.pos_id ? Inv.pos_id.substring(0, 2) : "33"
                ),
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_b2b_items || Inv.recon_pr_b2b_2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr);

        console.log(PRTransOnly);

        reconPRb2bDataList = PRTransOnly;
        summary_report_b2b.push(summary_line_item_recon_pr_b2b);
      }
    } catch (e) {
      console.log(e);
    }

    var reconPRb2bDataList_2 = [];
    try {
      if (recon_pr_2 !== undefined && recon_pr_2 !== null) {
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": getPlaceOfSupply(
                  Inv.pos_id ? Inv.pos_id.substring(0, 2) : "33"
                ),
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_b2b_items || Inv.recon_pr_b2b_2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_2);

        reconPRb2bDataList_2 = PRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Standalone Purchase Transactions
    var onlyPRPRb2bDataList = [];
    try {
      if (only_pr !== undefined && only_pr !== null) {
        let summary_line_item_onlyPR_b2b = {
          "Particulars": "Not Reflected in GSTR",
          "Taxable Value": 0,
          "IGST": 0,
          "CGST": 0,
          "SGST": 0,
          "Cess": 0
        }
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              summary_line_item_onlyPR_b2b["Taxable Value"] += parseFloat(InvoiceItems.taxable_value ?? 0);
              summary_line_item_onlyPR_b2b["IGST"] += parseFloat(InvoiceItems.igst ?? 0);
              summary_line_item_onlyPR_b2b["CGST"] += parseFloat(InvoiceItems.cgst ?? 0);
              summary_line_item_onlyPR_b2b["SGST"] += parseFloat(InvoiceItems.sgst ?? 0);
              summary_line_item_onlyPR_b2b["Cess"] += parseFloat(InvoiceItems.cess ?? 0);

              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(only_pr);

        onlyPRPRb2bDataList = StandAlonePRTransOnly;
        summary_report_b2b.push(summary_line_item_onlyPR_b2b);
      }
    } catch (e) {
      console.log(e);
    }

    var onlyPRPRb2bDataList_2 = [];
    try {
      if (only_pr_2 !== undefined && only_pr_2 !== null) {
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(only_pr_2);

        onlyPRPRb2bDataList_2 = StandAlonePRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //B2BA
    //Matched Purchase Transactions
    var Matchb2baDataList = [];
    try {
      if (match_b2ba !== undefined && match_b2ba !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Invoice Number": Inv.og_sup_inv_num,
                "Original Invoice date": Inv.og_sup_inv_dt,
                "Revised Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Revised Invoice Number": Inv.invoice_number,
                "Revised Invoice date": Inv.invoice_date,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Revised Reverse Charge": Inv.reverse_charge,
                "Revised Invoice Value": parseFloat(Inv.invoice_value),
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : InvoiceItems.igst === null
                      ? ""
                      : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : InvoiceItems.cgst === null
                      ? ""
                      : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : InvoiceItems.sgst === null
                      ? ""
                      : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : InvoiceItems.cess === null
                      ? ""
                      : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_b2ba);

        Matchb2baDataList = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var Matchb2baDataList_2 = [];
    try {
      if (match_b2ba_2 !== undefined && match_b2ba_2 !== null) {
        const MatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Invoice Number": Inv.og_sup_inv_num,
                "Original Invoice date": Inv.og_sup_inv_dt,
                "Revised Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Revised Invoice Number": Inv.invoice_number,
                "Revised Invoice date": Inv.invoice_date,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Revised Reverse Charge": Inv.reverse_charge,
                "Revised Invoice Value": parseFloat(Inv.invoice_value),
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : InvoiceItems.igst === null
                      ? ""
                      : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : InvoiceItems.cgst === null
                      ? ""
                      : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : InvoiceItems.sgst === null
                      ? ""
                      : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : InvoiceItems.cess === null
                      ? ""
                      : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(match_b2ba_2);

        Matchb2baDataList_2 = MatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Mismatched Purchase Transactions
    var MissMatchPRb2baDataList = [];
    try {
      if (miss_b2ba !== undefined && miss_b2ba !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              const i =
                InvoiceItems.item_type === "RECONPRB2B"
                  ? getGSTNData(miss_b2ba, InvoiceItems, Inv)
                  : Inv;
              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Original Invoice Number": i.og_sup_inv_num,
                "Original Invoice date": i.og_sup_inv_dt,
                "Revised Invoice Type":
                  i.invoice_type === "R" ? "Regular" : i.invoice_type,
                "Revised Invoice Number": i.invoice_number,
                "Revised Invoice date": i.invoice_date,
                "Place Of Supply": i.pos_id
                  ? getPlaceOfSupply(i.pos_id.substring(0, 2))
                  : "",
                "Revised Reverse Charge": i.reverse_charge,
                "Revised Invoice Value": parseFloat(i.invoice_value),
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRB2B"
                    ? "Purchase Register"
                    : "GSTN",
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_b2ba);
        MissMatchPRb2baDataList = MisMatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var MissMatchPRb2baDataList_2 = [];
    try {
      if (miss_b2ba_2 !== undefined && miss_b2ba_2 !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              //
              const i =
                InvoiceItems.item_type === "RECONPRB2B"
                  ? getGSTNData(miss_b2ba_2, InvoiceItems, Inv)
                  : Inv;
              return {
                "GSTIN of Supplier": i.supplier_gstin
                  ? i.supplier_gstin
                  : i.receiver_gstin,
                "Supplier Name": i.supplier_name,
                "Original Invoice Number": i.og_sup_inv_num,
                "Original Invoice date": i.og_sup_inv_dt,
                "Revised Invoice Type":
                  i.invoice_type === "R" ? "Regular" : i.invoice_type,
                "Revised Invoice Number": i.invoice_number,
                "Revised Invoice date": i.invoice_date,
                "Place Of Supply": i.pos_id
                  ? getPlaceOfSupply(i.pos_id.substring(0, 2))
                  : "",
                "Revised Reverse Charge": i.reverse_charge,
                "Revised Invoice Value": parseFloat(i.invoice_value),
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type == "PRB2B"
                    ? "Purchase Register"
                    : "GSTN",
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(miss_b2ba_2);
        MissMatchPRb2baDataList_2 = MisMatchTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    //Transactions from GST System
    var reconPRb2baDataList = [];
    try {
      if (recon_pr_b2ba !== undefined && recon_pr_b2ba !== null) {
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Invoice Number": Inv.og_sup_inv_num,
                "Original Invoice date": Inv.og_sup_inv_dt,
                "Revised Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Revised Invoice Number": Inv.supplier_inv_num,
                "Revised Invoice date": Inv.supplier_inv_dt,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Revised Reverse Charge": Inv.rev_charge,
                "Revised Invoice Value": parseFloat(Inv.supplier_inv_val),
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_b2ba_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_b2ba);

        reconPRb2baDataList = PRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    var reconPRb2baDataList_2 = [];
    try {
      if (recon_pr_b2ba_2 !== undefined && recon_pr_b2ba_2 !== null) {
        const PRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin
                  ? Inv.supplier_gstin
                  : Inv.receiver_gstin,
                "Supplier Name": Inv.supplier_name,
                "Original Invoice Number": Inv.og_sup_inv_num,
                "Original Invoice date": Inv.og_sup_inv_dt,
                "Revised Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Revised Invoice Number": Inv.supplier_inv_num,
                "Revised Invoice date": Inv.supplier_inv_dt,
                "Place Of Supply": Inv.pos_id
                  ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                  : "",
                "Revised Reverse Charge": Inv.rev_charge,
                "Revised Invoice Value": parseFloat(Inv.supplier_inv_val),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                // 'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
              };
            })(Inv.recon_pr_b2ba_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(recon_pr_b2ba_2);

        reconPRb2baDataList_2 = PRTransOnly;
      }
    } catch (e) {
      console.log(e);
    }

    try {
      // wb_new.Sheets["Summary Report"] = XLSX.utils.json_to_sheet(summary_report, {
      //   origin: 'A3'
      // });
    } catch (e) {
      console.log(e);
    }

    //B2BA
    try {
      if (match !== undefined && match !== null && match.length !== 0) {
        wb_new.Sheets["Matched - B2B - " + finYear] = XLSX.utils.json_to_sheet(
          Matchb2bDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (match_2 !== undefined && match_2 !== null && match_2.length !== 0) {
        wb_new.Sheets["Matched - B2B - " + nextFY] = XLSX.utils.json_to_sheet(
          Matchb2bDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (miss !== undefined && miss !== null && miss.length !== 0) {
        wb_new.Sheets[
          "Mismatched - B2B - " + finYear
        ] = XLSX.utils.json_to_sheet(MissMatchPRb2bDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (miss_2 !== undefined && miss_2 !== null && miss_2.length !== 0) {
        wb_new.Sheets[
          "Mismatched - B2B - " + nextFY
        ] = XLSX.utils.json_to_sheet(MissMatchPRb2bDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (only_pr !== undefined && only_pr !== null && only_pr.length !== 0) {
        wb_new.Sheets[
          "Standalone - B2B - " + finYear
        ] = XLSX.utils.json_to_sheet(onlyPRPRb2bDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        only_pr_2 !== undefined &&
        only_pr_2 !== null &&
        only_pr_2.length !== 0
      ) {
        wb_new.Sheets[
          "Standalone - B2B - " + nextFY
        ] = XLSX.utils.json_to_sheet(onlyPRPRb2bDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr !== undefined &&
        recon_pr !== null &&
        recon_pr.length !== 0
      ) {
        wb_new.Sheets["GST - B2B - " + finYear] = XLSX.utils.json_to_sheet(
          reconPRb2bDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_2 !== undefined &&
        recon_pr_2 !== null &&
        recon_pr_2.length !== 0
      ) {
        wb_new.Sheets["GST - B2B - " + nextFY] = XLSX.utils.json_to_sheet(
          reconPRb2bDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_b2b !== undefined &&
        likely_b2b !== null &&
        likely_b2b.length !== 0
      ) {
        wb_new.Sheets["Likely - B2B - " + finYear] = XLSX.utils.json_to_sheet(
          likelyPRb2bDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_b2b_2 !== undefined &&
        likely_b2b_2 !== null &&
        likely_b2b_2.length !== 0
      ) {
        wb_new.Sheets["Likely - B2B - " + nextFY] = XLSX.utils.json_to_sheet(
          likelyPRb2bDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_b2b !== undefined &&
        ineligible_b2b !== null &&
        ineligible_b2b.length !== 0
      ) {
        wb_new.Sheets[
          "Ineligible - B2B - " + finYear
        ] = XLSX.utils.json_to_sheet(ineligibleb2bDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_b2b_2 !== undefined &&
        ineligible_b2b_2 !== null &&
        ineligible_b2b_2.length !== 0
      ) {
        wb_new.Sheets[
          "Ineligible - B2B - " + nextFY
        ] = XLSX.utils.json_to_sheet(ineligibleb2bDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    //b2ba
    try {
      if (
        match_b2ba !== undefined &&
        match_b2ba !== null &&
        match_b2ba.length !== 0
      ) {
        wb_new.Sheets["Matched - B2BA - " + finYear] = XLSX.utils.json_to_sheet(
          Matchb2baDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        match_b2ba_2 !== undefined &&
        match_b2ba_2 !== null &&
        match_b2ba_2.length !== 0
      ) {
        wb_new.Sheets["Matched - B2BA - " + nextFY] = XLSX.utils.json_to_sheet(
          Matchb2baDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_b2ba !== undefined &&
        miss_b2ba !== null &&
        miss_b2ba.length !== 0
      ) {
        wb_new.Sheets[
          "Mismatched - B2BA - " + finYear
        ] = XLSX.utils.json_to_sheet(MissMatchPRb2baDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_b2ba_2 !== undefined &&
        miss_b2ba_2 !== null &&
        miss_b2ba_2.length !== 0
      ) {
        wb_new.Sheets[
          "Mismatched - B2BA - " + nextFY
        ] = XLSX.utils.json_to_sheet(MissMatchPRb2baDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_b2ba !== undefined &&
        recon_pr_b2ba !== null &&
        recon_pr_b2ba.length !== 0
      ) {
        wb_new.Sheets["GST - B2BA - " + finYear] = XLSX.utils.json_to_sheet(
          reconPRb2baDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_b2ba_2 !== undefined &&
        recon_pr_b2ba_2 !== null &&
        recon_pr_b2ba_2.length !== 0
      ) {
        wb_new.Sheets["GST - B2BA - " + nextFY] = XLSX.utils.json_to_sheet(
          reconPRb2baDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }
    //CDNR

    try {
      if (
        match_cdnr !== undefined &&
        match_cdnr !== null &&
        match_cdnr.length !== 0
      ) {
        wb_new.Sheets["Matched - CDNR - " + finYear] = XLSX.utils.json_to_sheet(
          MatchcdnrDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        match_cdnr_2 !== undefined &&
        match_cdnr_2 !== null &&
        match_cdnr_2.length !== 0
      ) {
        wb_new.Sheets["Matched - CDNR - " + nextFY] = XLSX.utils.json_to_sheet(
          MatchcdnrDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdnr !== undefined &&
        miss_cdnr !== null &&
        miss_cdnr.length !== 0
      ) {
        wb_new.Sheets[
          "Mismatched - CDNR - " + finYear
        ] = XLSX.utils.json_to_sheet(MissMatchPRcdnrDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdnr_2 !== undefined &&
        miss_cdnr_2 !== null &&
        miss_cdnr_2.length !== 0
      ) {
        wb_new.Sheets[
          "Mismatched - CDNR - " + nextFY
        ] = XLSX.utils.json_to_sheet(MissMatchPRcdnrDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        only_pr_cdnr !== undefined &&
        only_pr_cdnr !== null &&
        only_pr_cdnr.length !== 0
      ) {
        wb_new.Sheets[
          "Standalone - CDNR - " + finYear
        ] = XLSX.utils.json_to_sheet(onlyPRPRcdnrDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        only_pr_cdnr_2 !== undefined &&
        only_pr_cdnr_2 !== null &&
        only_pr_cdnr_2.length !== 0
      ) {
        wb_new.Sheets[
          "Standalone - CDNR - " + nextFY
        ] = XLSX.utils.json_to_sheet(onlyPRPRcdnrDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdnr !== undefined &&
        recon_pr_cdnr !== null &&
        recon_pr_cdnr.length !== 0
      ) {
        wb_new.Sheets["GST - CDNR - " + finYear] = XLSX.utils.json_to_sheet(
          reconPRcdnrDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdnr_2 !== undefined &&
        recon_pr_cdnr_2 !== null &&
        recon_pr_cdnr_2.length !== 0
      ) {
        wb_new.Sheets["GST - CDNR - " + nextFY] = XLSX.utils.json_to_sheet(
          reconPRcdnrDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_cdnr !== undefined &&
        likely_cdnr !== null &&
        likely_cdnr.length !== 0
      ) {
        wb_new.Sheets["Likely - CDNR - " + finYear] = XLSX.utils.json_to_sheet(
          LikelyMatchPRcdnrDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        likely_cdnr_2 !== undefined &&
        likely_cdnr_2 !== null &&
        likely_cdnr_2.length !== 0
      ) {
        wb_new.Sheets["Likely - CDNR - " + nextFY] = XLSX.utils.json_to_sheet(
          LikelyMatchPRcdnrDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_cdnr !== undefined &&
        ineligible_cdnr !== null &&
        ineligible_cdnr.length !== 0
      ) {
        wb_new.Sheets[
          "Ineligible - CDNR - " + finYear
        ] = XLSX.utils.json_to_sheet(ineligibleCdnrDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        ineligible_cdnr_2 !== undefined &&
        ineligible_cdnr_2 !== null &&
        ineligible_cdnr_2.length !== 0
      ) {
        wb_new.Sheets[
          "Ineligible - CDNR - " + nextFY
        ] = XLSX.utils.json_to_sheet(ineligibleCdnrDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    //CDNA
    try {
      if (
        match_cdna !== undefined &&
        match_cdna !== null &&
        match_cdna.length !== 0
      ) {
        wb_new.Sheets["Matched - CDNA - " + finYear] = XLSX.utils.json_to_sheet(
          MatchcdnaDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        match_cdna_2 !== undefined &&
        match_cdna_2 !== null &&
        match_cdna_2.length !== 0
      ) {
        wb_new.Sheets["Matched - CDNA - " + nextFY] = XLSX.utils.json_to_sheet(
          MatchcdnaDataList_2
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdna !== undefined &&
        miss_cdna !== null &&
        miss_cdna.length !== 0
      ) {
        wb_new.Sheets[
          "Mismatched - CDNA - " + finYear
        ] = XLSX.utils.json_to_sheet(MissMatchPRcdnaDataList);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        miss_cdna_2 !== undefined &&
        miss_cdna_2 !== null &&
        miss_cdna_2.length !== 0
      ) {
        wb_new.Sheets[
          "Mismatched - CDNA - " + nextFY
        ] = XLSX.utils.json_to_sheet(MissMatchPRcdnaDataList_2);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdna !== undefined &&
        recon_pr_cdna !== null &&
        recon_pr_cdna.length !== 0
      ) {
        wb_new.Sheets["GST - CDNA - " + finYear] = XLSX.utils.json_to_sheet(
          reconPRcdnaDataList
        );
      }
    } catch (e) {
      console.log(e);
    }

    try {
      if (
        recon_pr_cdna_2 !== undefined &&
        recon_pr_cdna_2 !== null &&
        recon_pr_cdna_2.length !== 0
      ) {
        wb_new.Sheets["GST - CDNA - " + nextFY] = XLSX.utils.json_to_sheet(
          reconPRcdnaDataList_2,
        );
      }
    } catch (e) {
      console.log(e);
    }

    var ws = XLSX.utils.aoa_to_sheet([[]]);
    var header = ["Particulars", "Taxable Value", "IGST", "CGST", "SGST", "Cess"];
    /* Section Heading */
    XLSX.utils.sheet_add_json(ws, [
      { A: "B2B" }
    ], { skipHeader: true });

    /* First row */
    XLSX.utils.sheet_add_json(ws, summary_report_b2b, { header: header, origin: "A2" });

    XLSX.utils.sheet_add_json(ws, [
      { A: "CDNR" }
    ], { skipHeader: true, origin: "A8" });

    XLSX.utils.sheet_add_json(ws, summary_report_cdnr, { header: header, origin: "A9" });

    // /* Write data starting at A2 */
    // XLSX.utils.sheet_add_json(ws, [
    //   { A: 1, B: 2 }, { A: 2, B: 3 }, { A: 3, B: 4 }
    // ], { header: header, skipHeader: true, origin: "A2" });

    // /* Write data starting at E2 */
    // XLSX.utils.sheet_add_json(ws, [
    //   { A: 5, B: 6, C: 7 }, { A: 6, B: 7, C: 8 }, { A: 7, B: 8, C: 9 }
    // ], { header: ["A", "B", "C"], skipHeader: true, origin: { r: 1, c: 4 } });

    // /* Append row */
    // XLSX.utils.sheet_add_json(ws, [
    //   { A: 4, B: 5, C: 6, D: 7, E: 8, F: 9, G: 0 }
    // ], { header: header, skipHeader: true, origin: -1 });

    wb_new.Sheets["Summary Report"] = ws;

    try {
      if (wb_new.SheetNames.length !== 0) {
        var wbout = XLSX.write(wb_new, {
          bookType: "xlsx",
          bookSST: true,
          type: "binary",
        });

        var cur_dt = new Date();

        return fileSaver.saveAs(
          blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
          "Reconciliation Summary - " + cur_dt + ".xlsx"
        );
      } else {
        return {
          status: "EMPTYSHEET",
        };
      }
    } catch (e) {
      console.log(e);
    }
  },

  ITC_Summary: (data) => {
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    var wb_new = XLSX.utils.book_new();
    wb_new.SheetNames.push("ITC Report");
    wb_new.Sheets["ITC Report"] = XLSX.utils.json_to_sheet(data);
    var wbout = XLSX.write(wb_new, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    var cur_dt = new Date();
    return fileSaver.saveAs(
      blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
      "ITC Summary - " + cur_dt + ".xlsx"
    );
  },

  ITC_2B_Summary: (data) => {
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    var wb_new = XLSX.utils.book_new();
    wb_new.SheetNames.push("ITC Report");
    wb_new.Sheets["ITC Report"] = XLSX.utils.json_to_sheet(data);
    var wbout = XLSX.write(wb_new, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    var cur_dt = new Date();
    return fileSaver.saveAs(
      blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
      "ITC 2B Summary - " + cur_dt + ".xlsx"
    );
  },

  RFT_Excel: (b2b) => {
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    var wb_new = XLSX.utils.book_new();

    if (b2b !== undefined && b2b !== null && b2b.length !== 0) {
      wb_new.SheetNames.push("Invoices");
    }

    var DataList = [];
    if (b2b !== undefined && b2b !== null) {
      const MatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems) => {
            return {
              // 'GSTIN of Supplier': Inv.supplier_gstin ? Inv.supplier_gstin : Inv.receiver_gstin,
              "Invoice Number": Inv.invoice_number,
              "Invoice date": Inv.invoice_date,
              "Invoice Value": parseFloat(Inv.invoice_value),
              "Invoice Type":
                Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
              "Place Of Supply": Inv.pos_id
                ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                : "",
              "Reverse Charge": Inv.reverse_charge,
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              "Integrated Tax Paid":
                InvoiceItems.igst === null ? "" : parseFloat(InvoiceItems.igst),
              "Central Tax Paid":
                InvoiceItems.cgst === null ? "" : parseFloat(InvoiceItems.cgst),
              "State/UT Tax Paid":
                InvoiceItems.sgst === null ? "" : parseFloat(InvoiceItems.sgst),
              "Cess Paid":
                InvoiceItems.cess === null ? "" : parseFloat(InvoiceItems.cess),
            };
          })(
            Inv.recon_pr_b2b_items ? Inv.recon_pr_b2b_items : Inv.sr_b2b_items
          );
          return InvoiceItems;
        }),
        flattenDeep
      )(b2b);

      DataList = MatchTransOnly;
    }

    if (b2b !== undefined && b2b !== null && b2b.length !== 0) {
      wb_new.Sheets["Invoices"] = XLSX.utils.json_to_sheet(DataList);
    }

    if (wb_new.SheetNames.length !== 0) {
      var wbout = XLSX.write(wb_new, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      return fileSaver.saveAs(
        blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Invoices.xlsx"
      );
    } else {
      return {
        status: "EMPTYSHEET",
      };
    }
  },
  CP_Excel: (b2b, cdnr, b2ba, cdna) => {
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    var wb_new = XLSX.utils.book_new();

    if (b2b !== undefined && b2b !== null && b2b.length !== 0) {
      wb_new.SheetNames.push("B2B Invoices");
    }
    if (b2ba !== undefined && b2ba !== null && b2ba.length !== 0) {
      wb_new.SheetNames.push("B2B Amended Invoices");
    }

    if (cdnr !== undefined && cdnr !== null && cdnr.length !== 0) {
      wb_new.SheetNames.push("Notes");
    }
    if (cdna !== undefined && cdna !== null && cdna.length !== 0) {
      wb_new.SheetNames.push("Notes Amended");
    }

    var DataList = [];
    var b2ba_DataList = [];

    var cdnr_DataList = [];
    var cdna_DataList = [];
    if (b2b !== undefined && b2b !== null) {
      const MatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems) => {
            return {
              "GSTIN of Supplier": Inv.supplier_gstin
                ? Inv.supplier_gstin
                : Inv.receiver_gstin,
              "Supplier Name": Inv.supplier_name,
              "Invoice Number": Inv.invoice_number,
              "Invoice Date": Inv.invoice_date,
              "Invoice Type":
                Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
              "Place Of Supply": Inv.pos_id
                ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                : "",
              "Reverse Charge": Inv.reverse_charge,
              "Invoice Value": parseFloat(Inv.invoice_value),
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              IGST:
                InvoiceItems.igst === null ? "" : parseFloat(InvoiceItems.igst),
              CGST:
                InvoiceItems.cgst === null ? "" : parseFloat(InvoiceItems.cgst),
              SGST:
                InvoiceItems.sgst === null ? "" : parseFloat(InvoiceItems.sgst),
              CESS:
                InvoiceItems.cess === null ? "" : parseFloat(InvoiceItems.cess),
            };
          })(
            Inv.recon_pr_b2b_items ? Inv.recon_pr_b2b_items : Inv.sr_b2b_items
          );
          return InvoiceItems;
        }),
        flattenDeep
      )(b2b);

      DataList = MatchTransOnly;
    }

    if (b2ba !== undefined && b2ba !== null) {
      const MatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems) => {
            return {
              "GSTIN of Supplier": Inv.receiver_gstin,
              "Supplier Name": Inv.supplier_name,
              "Original Invoice Number": Inv.supplier_inv_num,
              "Original Invoice Date": Inv.supplier_inv_dt,
              "Revised Invoice Type":
                Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
              "Revised Invoice Number": Inv.og_sup_inv_num,
              "Revised Invoice Date": Inv.og_sup_inv_dt,
              "Place Of Supply": Inv.pos_id
                ? getPlaceOfSupply(Inv.pos_id.substring(0, 2))
                : "",
              "Revised Reverse Charge": Inv.reverse_charge,
              "Invoice Value": parseFloat(Inv.supplier_inv_val),
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              IGST:
                InvoiceItems.igst === null ? "" : parseFloat(InvoiceItems.igst),
              CGST:
                InvoiceItems.cgst === null ? "" : parseFloat(InvoiceItems.cgst),
              SGST:
                InvoiceItems.sgst === null ? "" : parseFloat(InvoiceItems.sgst),
              CGST:
                InvoiceItems.cess === null ? "" : parseFloat(InvoiceItems.cess),
            };
          })(Inv.recon_pr_b2ba_items);
          return InvoiceItems;
        }),
        flattenDeep
      )(b2ba);

      b2ba_DataList = MatchTransOnly;
    }

    if (cdnr !== undefined && cdnr !== null) {
      const cdnr_MatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems) => {
            return {
              "GSTIN of Supplier": Inv.supplier_gstin
                ? Inv.supplier_gstin
                : Inv.receiver_gstin,
              "Supplier Name": Inv.supplier_name,
              "Note Type": Inv.cdnr_type === "D" ? "Debit" : "Credit",
              "Note Number": Inv.cdnr_number,
              "Note Date": Inv.cdnr_date,
              "Original Invoice Number": Inv.invoice_number,
              "Original Invoice Date": Inv.invoice_date,
              "CDNR Value": parseFloat(Inv.cdnr_value),
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              IGST:
                InvoiceItems.igst === null ? "" : parseFloat(InvoiceItems.igst),
              CGST:
                InvoiceItems.cgst === null ? "" : parseFloat(InvoiceItems.cgst),
              SGST:
                InvoiceItems.sgst === null ? "" : parseFloat(InvoiceItems.sgst),
              CESS:
                InvoiceItems.cess === null ? "" : parseFloat(InvoiceItems.cess),
            };
          })(
            Inv.recon_pr_cdnr_items ? Inv.recon_pr_cdnr_items : Inv.sr_b2b_items
          );
          return InvoiceItems;
        }),
        flattenDeep
      )(cdnr);
      cdnr_DataList = cdnr_MatchTransOnly;
    }

    if (cdna !== undefined && cdna !== null) {
      const cdna_MatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems) => {
            return {
              "GSTIN of Supplier": Inv.receiver_gstin,
              "Supplier Name": Inv.supplier_name,
              "Original Note Number": Inv.og_note_num,
              "Original Note Date": Inv.og_note_dt,
              "Note Type": Inv.note_type === "D" ? "Debit" : "Credit",
              "Note Number": Inv.note_num,
              "Note Date": Inv.note_dt,
              "Invoice Number": Inv.invoice_num,
              "Invoice Date": Inv.invoice_dt,
              "Note Value": parseFloat(Inv.note_val),
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              "Integrated Tax Paid":
                InvoiceItems.igst === null ? "" : parseFloat(InvoiceItems.igst),
              "Central Tax Paid":
                InvoiceItems.cgst === null ? "" : parseFloat(InvoiceItems.cgst),
              "State/UT Tax Paid":
                InvoiceItems.sgst === null ? "" : parseFloat(InvoiceItems.sgst),
              "Cess Paid":
                InvoiceItems.cess === null ? "" : parseFloat(InvoiceItems.cess),
            };
          })(Inv.recon_pr_cdna_items);
          return InvoiceItems;
        }),
        flattenDeep
      )(cdna);

      cdna_DataList = cdna_MatchTransOnly;
    }

    if (b2b !== undefined && b2b !== null && b2b.length !== 0) {
      wb_new.Sheets["B2B Invoices"] = XLSX.utils.json_to_sheet(DataList);
    }
    if (b2ba !== undefined && b2ba !== null && b2ba.length !== 0) {
      wb_new.Sheets["B2B Amended Invoices"] = XLSX.utils.json_to_sheet(
        b2ba_DataList
      );
    }

    if (cdnr !== undefined && cdnr !== null && cdnr.length !== 0) {
      wb_new.Sheets["Notes"] = XLSX.utils.json_to_sheet(cdnr_DataList);
    }

    if (cdna !== undefined && cdna !== null && cdna.length !== 0) {
      wb_new.Sheets["Notes Amended"] = XLSX.utils.json_to_sheet(cdna_DataList);
    }

    if (wb_new.SheetNames.length !== 0) {
      var wbout = XLSX.write(wb_new, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      return fileSaver.saveAs(
        blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Counter-Party Invoices.xlsx"
      );
    } else {
      return {
        status: "EMPTYSHEET",
      };
    }
  },
};
export default excelUtility;
