import React from "react";
import {
  Form,
  Input,
  message,
  Table,
  Modal,
  Tag,
  Button,
  AutoComplete,
  Icon,
  Row,
  Col,
  Select,
  Spin
} from "antd";
import { withRouter } from "react-router-dom";
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import get from "lodash/fp/get";
import reduce from "lodash/fp/reduce";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import find from "lodash/fp/find";
import debounce from "lodash/debounce";
import glamorous from "glamorous";
import { connect } from "react-redux";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { formatNumber, getPlaceOfSupply, stateArray, ROUTES, i18n } from "../../shared/constants";
import gspAuth from "../../services/GSPService";
import { BASE_URL } from "../../shared/constants";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import moment from "moment";
import { actions } from "../../redux";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import { FormItem } from "../../shared/utils";
import excelUtility from "../../services/excelUtilityService";
const row_style = {
  border: "solid 1px #e8e8e8",
  padding: "10px",
  margin: "1px"
};
const { ColumnGroup, Column } = Table;
const errorModal = Modal.error;
const SRow = glamorous(Row)({ margin: "15px 0" });
const { Option } = Select;

const SupplierSearch = props => (
  <Form>
    <SRow gutter={24}>
      <Col span="11">
        <FormItem label="GSTIN" style={{ marginBottom: "0 !important" }}>
          {props.getFieldDecorator("gstin", {})(
            <Input
              id="gstId"
              placeholder="GSTIN Number"
              style={{ marginBottom: "0 !important" }}
              type="text"
              maxLength="15"
            />
          )}
        </FormItem>
      </Col>
      <Col span="2">Or</Col>
      <Col span="11">
        <FormItem label="Supplier Name">
          {props.getFieldDecorator("supplier", {
            initialValue: ""
          })(
            // <AutoComplete
            //   placeholder="Enter your supplier name"
            //   onSearch={props.getSuppliers}
            //   filterOption={(inputValue, option) =>
            //     option.props.children
            //       .toUpperCase()
            //       .indexOf(inputValue.toUpperCase()) !== -1
            //   }
            // >
            //   {map(o => (
            //     <AutoComplete.Option key={o.gstin}>{o.gstin_tradeNam}</AutoComplete.Option>
            //   ))(props.suppliers)}
            // </AutoComplete>
            // <SupplierAutoSuggest fetchURL="/export/getsuppliers?q=" />
            <Select
              showSearch
              labelInValue
              placeholder="Enter your supplier name"
              notFoundContent={props.fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={props.fetchUser}
              onChange={props.handleSupplierChange}
              style={{ width: "100%" }}
            >
              {props.data.map(d => (
                <Option key={d.value}>{d.text}</Option>
              ))}
            </Select>
          )}
        </FormItem>
      </Col>
    </SRow>
    <Form.Item style={{ textAlign: "center" }}>
      <Button
        style={{ backgroundColor: "#2382c7", color: "white" }}
        htmlType="submit"
        onClick={props.fetchSupplierDetails}
      >
        Fetch
      </Button>
    </Form.Item>
  </Form>
);

class SupplierDetails extends React.Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 800);
  }
  state = {
    tableData: [],
    supplierInvoices: [],
    gstin: null,
    visibleModal: null,
    hideModal: null,
    searchText: "",
    visibleItemModal: null,
    visibleItemModal_Customer: null,
    hideItemModal: null,
    gstnName: "",
    gstn_status: "",
    emptyTableText:
      "Enter GSTIN and click fetch to view the filing trends for the entered GSTIN!",
    customerInvoices: [],
    visibleModal_Customer: null,
    queryModal: false,
    queryModalYearSelection: this.props.finYear,
    type: null,
    data: [],
    value: [],
    fetching: false,
  };

  fetchSupplierDetails = async e => {
    this.setState({
      emptyTableText: "Fetching.....",
      tableData: [],
      gstnName: "",
      gstn_status: ""
    });

    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      else {
        console.log(values);
        const suppliergstin =
          values.gstin === undefined || values.gstin === ""
            ? (values.supplier === "" ? "" : values.supplier.key)
            : values.gstin;
        try {
          if (suppliergstin.length !== 15) {
            message.error("Entered GSTIN should contain 15 characters.");
            return;
          }
          message.destroy();
          message.loading("Fetching from GSTN...", 0);
          this.setState({ gstin: suppliergstin });
          var gstResponse_Prev = await gspAuth.retfileStatus(
            suppliergstin,
            "2017-18"
          );

          if (
            gstResponse_Prev.data.resp.errorCode === "LG9002" ||
            gstResponse_Prev.data.resp.errorCode === "NOGSTIN" ||
            gstResponse_Prev.data.resp.errorCode === "RET11403"
          ) {
            message.destroy();
            message.error("Invalid GSTIN", 3);
            this.setState({
              tableData: [],
              emptyTableText:
                "Entered GSTIN seems to be invalid! Kindly enter valid GSTIN.",
              gstnName: ""
            });
          } else {
            const companyNameFetch = this.getCompanyName(suppliergstin);

            var gstResponse_Curr = await gspAuth.retfileStatus(
              suppliergstin,
              "2018-19"
            );
            var gstResponse_Next = await gspAuth.retfileStatus(
              suppliergstin,
              "2019-20"
            );
            var gstResponse_New = await gspAuth.retfileStatus(
              suppliergstin,
              "2020-21"
            );
            var gstResponse_2021_22 = await gspAuth.retfileStatus(
              suppliergstin,
              "2021-22"
            );
			var gstResponse_2022_23 = await gspAuth.retfileStatus(
              suppliergstin,
              "2022-23"
            );
            var arrayValues = [];

            console.log("gstResponse_Prev", gstResponse_Prev);
            console.log("gstResponse_Curr", gstResponse_Curr);
            console.log("gstResponse_Next", gstResponse_Next);
            console.log("gstResponse_2021_22", gstResponse_2021_22);
			console.log("gstResponse_2022_23", gstResponse_2022_23);

            if (
              get("data.resp.errorCode", gstResponse_Prev) === "RET13510" &&
              get("data.resp.errorCode", gstResponse_Curr) === "RET13510" &&
              get("data.resp.errorCode", gstResponse_Next) === "RET13510" &&
              get("data.resp.errorCode", gstResponse_New) === "RET13510" &&
              get("data.resp.errorCode", gstResponse_2021_22) === "RET13510" &&
              get("data.resp.errorCode", gstResponse_2022_23) === "RET13510"
            ) {
              this.setState({
                emptyTableText: "No Record found for the provided Inputs"
              });
            }

            if (get("data.resp.result.EFiledlist", gstResponse_Prev)) {
              console.log("Available gstResponse_Prev");
              arrayValues = arrayValues.concat(
                get("data.resp.result.EFiledlist", gstResponse_Prev)
              );
            }
            if (get("data.resp.result.EFiledlist", gstResponse_Curr)) {
              console.log("Available gstResponse_Curr");
              arrayValues = arrayValues.concat(
                get("data.resp.result.EFiledlist", gstResponse_Curr)
              );
            }
            if (get("data.resp.result.EFiledlist", gstResponse_Next)) {
              console.log("Available gstResponse_Next");
              arrayValues = arrayValues.concat(
                get("data.resp.result.EFiledlist", gstResponse_Next)
              );
            }
            if (get("data.resp.result.EFiledlist", gstResponse_New)) {
              console.log("Available gstResponse_New");
              arrayValues = arrayValues.concat(
                get("data.resp.result.EFiledlist", gstResponse_New)
              );
            }
            if (get("data.resp.result.EFiledlist", gstResponse_2021_22)) {
              console.log("Available gstResponse_2021_22");
              arrayValues = arrayValues.concat(
                get("data.resp.result.EFiledlist", gstResponse_2021_22)
              );
            }
			if (get("data.resp.result.EFiledlist", gstResponse_2022_23)) {
              console.log("Available gstResponse_2022_23");
              arrayValues = arrayValues.concat(
                get("data.resp.result.EFiledlist", gstResponse_2022_23)
              );
            }

            /*if(get('data.resp.errorCode', gstResponse_Prev) === 'RET13510' || get('data.resp.errorCode', gstResponse_Prev) === 'RTN_22'){
            if(get('data.resp.errorCode', gstResponse_Curr) !== 'RET13510' || get('data.resp.errorCode', gstResponse_Prev) === 'RTN_22'){
              arrayValues = arrayValues.concat(get('data.resp.result.EFiledlist', gstResponse_Curr));
            }
          }

          if(get('data.resp.errorCode', gstResponse_Curr) === 'RET13510' || get('data.resp.errorCode', gstResponse_Curr) === 'RTN_22'){
            if(get('data.resp.errorCode', gstResponse_Prev) !== 'RET13510' || get('data.resp.errorCode', gstResponse_Curr) === 'RTN_22'){
            arrayValues = arrayValues.concat(get('data.resp.result.EFiledlist', gstResponse_Prev));
            }
          }

          
          if((get('data.resp.errorCode', gstResponse_Prev) === '1000') && (get('data.resp.errorCode', gstResponse_Curr) === '1000'))
          {
            arrayValues = arrayValues.concat(get('data.resp.result.EFiledlist', gstResponse_Prev));
            arrayValues = arrayValues.concat(get('data.resp.result.EFiledlist', gstResponse_Curr));
            arrayValues = arrayValues.concat(get('data.resp.result.EFiledlist', gstResponse_Next));
          }*/

            this.setState({
              tableData: arrayValues
            });

            document.getElementsByClassName("showButton")[0].style.display =
              "block";

            message.destroy();

            
            message.success("Fetched");
          }
        } catch (e) {
          console.log("Error is " + e);
        }
      }
    });
  };

  sortDate = (a, b) => {
    const firstDateArray = a.split("-");
    const secondDateArray = b.split("-");

    return (
      new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0]) -
      new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0])
    );
  };

  handleSearch = (selectedKeys, confirm) => () => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => () => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  showSupplierInvoicesModal = () => {
    message.destroy();
    message.loading("Searching Supplier Invoices....", 0);

    var downloaddate =
      this.props.finMonth + "/" + this.state.queryModalYearSelection;

    const getInvoices = fetch(
      `${BASE_URL}/export/getSupplierInvoices?gstin=${
        this.state.gstin
      }&companyId=${this.props.companyId}&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(result => {
        // console.log("=======DATA=====");
        // console.log(result);

        this.setState({ supplierInvoices: result });

        message.destroy();

        this.setState({ visibleModal: true, queryModal: false });
      });
  };

  showSalesInvoicesModal = () => {
    message.destroy();
    message.loading("Searching Customer Invoices....", 0);

    var downloaddate =
      this.props.finMonth + "/" + this.state.queryModalYearSelection;

    const getInvoices = fetch(
      `${BASE_URL}/export/getCustomerInvoices?gstin=${
        this.state.gstin
      }&companyId=${this.props.companyId}&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(result => {
        // console.log("=======DATA=====");
        // console.log(result);

        this.setState({ customerInvoices: result });

        message.destroy();

        this.setState({ visibleModal_Customer: true, queryModal: false });
      });
  };

  hideModal = () => {
    this.setState({ visibleModal: false });
    document.getElementById("InvoiceSearch").click();
  };

  hideModal_Customer = () => {
    this.setState({ visibleModal_Customer: false });
    document.getElementById("InvoiceSearch_Sales").click();
  };

  showItemModal_Customer = async record => {
    this.setState({ visibleItemModal_Customer: record.id });
  };

  showItemModal = async record => {
    this.setState({ visibleItemModal: record.id });
  };

  hideItemModal = () => {
    this.setState({ visibleItemModal: false });
  };

  hideItemModal_Customer = () => {
    this.setState({ visibleItemModal_Customer: false });
  };

  getModalTitle = () => {
    return (
      <p>
        <b>
          B2B Data{" "}
          {this.state.gstnName === "" ? "" : " for " + this.state.gstnName}
        </b>
      </p>
    );
  };

  RFT_Excel = async b2b => {
    const excelOutput = await excelUtility.RFT_Excel(b2b);

    if (excelOutput.status === "EMPTYSHEET") {
      message.warning("No invoices available to download!");
    }
  };

  getCompanyName = async g => {
    if (g.length === 15) {
      const resp = await gspAuth.getCompanyName(g, 1);

      if (!resp || resp.data.success === false) {
        this.setState({ gstnName: "" });
      }
      if (resp.data.success === true) {
        this.setState({
          gstnName: resp.data.resp,
          gstn_status: resp.data.status
        });
      }

      return {
        success: true
      };
    }
  };

  queryYEAR = () => {
    if (this.state.type === "sales") {
      this.showSalesInvoicesModal();
    }
    if (this.state.type === "purchase") {
      this.showSupplierInvoicesModal();
    }
  };

  queryYEARSales = () => {
    this.setState({ queryModal: true, type: "sales" });
  };

  queryYEARPurchase = () => {
    this.setState({ queryModal: true, type: "purchase" });
  };

  queryYEAR_CANCEL = type => {
    this.setState({ queryModal: false });
  };

  handleYearChange = val => {
    this.setState({ queryModalYearSelection: val });
  };

  fetchUser = value => {
    console.log('fetching user', value);
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    fetch(`${BASE_URL}/export/getsuppliers?q=${value}`)
      .then(response => response.json())
      .then(body => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        // console.log(body)
        const data = body.map(o => ({
          text: o.gstin_tradeNam,
          value: o.gstin,
        }));
        console.log(this.props.form);
        this.setState({ data, fetching: false });
      });
  };

  handleSupplierChange = value => {
    console.log(value);
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  render() {
    return (
      <Content>
        <SupplierSearch
          getFieldDecorator={this.props.form.getFieldDecorator}
          fetchSupplierDetails={this.fetchSupplierDetails}
          value={this.state.value}
          fetching={this.state.fetching}
          fetchUser={this.fetchUser}
          handleSupplierChange={this.handleSupplierChange}
          data={this.state.data}
        />
        {this.state.gstnName !== "" && (
          <Tag
            style={{ cursor: "initial", fontSize: "14px", textAlign: "center" }}
          >
            {this.state.gstnName}
          </Tag>
        )}
        {this.state.gstn_status === "Active" && (
          <Tag style={{ cursor: "initial", textAlign: "center" }} color="green">
            {" "}
            Status: {this.state.gstn_status}
          </Tag>
        )}
        {this.state.gstn_status === "Provisional" && (
          <Tag
            style={{ cursor: "initial", textAlign: "center" }}
            color="orange"
          >
            {" "}
            Status: {this.state.gstn_status}
          </Tag>
        )}
        {this.state.gstn_status === "Cancelled" && (
          <Tag style={{ cursor: "initial", textAlign: "center" }} color="red">
            {" "}
            Status: {this.state.gstn_status}
          </Tag>
        )}
        {this.state.gstn_status === "Inactive" && (
          <Tag
            style={{ cursor: "initial", textAlign: "center" }}
            color="volcano"
          >
            {" "}
            Status: {this.state.gstn_status}
          </Tag>
        )}
        <div>
          <Table
            locale={{ emptyText: this.state.emptyTableText }}
            rowKey="arn"
            dataSource={this.state.tableData}
            bordered
            title={() => "Return Filing Trends"}
            size="middle"
          >
            <Column
              className="alignCenter"
              title="Return Type"
              dataIndex="rtntype"
              key="rtntype"
              render={text => <b>{text}</b>}
            />
            <Column
              className="alignCenter"
              title="Financial Year"
              dataIndex="ret_prd"
              key="ret_prd_yr"
              render={(text, record) => {
                if (record.rtntype === "ITC04") {
                  return <b>*****</b>;
                } else {
                  return text ? moment(text, "MMYYYY").format("YYYY") : "";
                }
              }}
            />
            <Column
              className="alignCenter"
              title="Tax Period"
              dataIndex="ret_prd"
              key="ret_prd_mon"
              render={(text, record) => {
                if (record.rtntype === "ITC04") {
                  return <b>*****</b>;
                } else {
                  return text ? moment(text, "MMYYYY").format("MMMM") : "";
                }
              }}
            />
            <Column
              className="alignCenter"
              title="Date of Filing"
              dataIndex="dof"
              key="dof"
              defaultSortOrder="descend"
              render={text =>
                text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
              }
              sorter={(a, b) => this.sortDate(a.dof, b.dof)}
            />
            <Column
              className="alignCenter"
              title="Mode of Filing"
              dataIndex="mof"
              key="mof"
            />
            <Column
              className="alignCenter"
              title={() => (
                <div>
                  <p>Filing Trends</p>
                  <p style={{ marginTop: "-5px" }}>
                    (Delay beyond subsequent month)
                  </p>
                </div>
              )}
              dataIndex="ft"
              key="ft"
              render={(text, record) => {
                if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(1, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="green"
                    >
                      On Time
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(2, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="orange"
                    >
                      One Month Delay
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(3, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="orange"
                    >
                      Two Months Delay
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(4, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="orange"
                    >
                      Three Months Delay
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(5, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="orange"
                    >
                      Four Months Delay
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(6, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="orange"
                    >
                      Five Months Delay
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY")
                    .subtract(7, "month")
                    .format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="orange"
                    >
                      Six Months Delay
                    </Tag>
                  );
                } else if (
                  record.ret_prd ===
                  moment(record.dof, "DD-MM-YYYY").format("MMYYYY")
                ) {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="green"
                    >
                      On Time
                    </Tag>
                  );
                } else if (record.rtntype === "ITC04") {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="green"
                    >
                      Filed
                    </Tag>
                  );
                } else {
                  return (
                    <Tag
                      style={{ cursor: "initial", width: "200px" }}
                      color="red"
                    >
                      More Than Six Months Delay
                    </Tag>
                  );
                }
              }}
            />
          </Table>
          <div
            className="showButton"
            style={{
              background: "#ECECEC",
              padding: "15px",
              display: "none",
              textAlign: "center"
            }}
          >
            <Row>
              <Col span={12}>
                <Button
                  style={{ backgroundColor: "#04880fe0", color: "white" }}
                  onClick={this.queryYEARSales}
                >
                  Customer - View invoices raised to this GSTN.
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{ backgroundColor: "#04880fe0", color: "white" }}
                  onClick={this.queryYEARPurchase}
                >
                  Supplier - View invoices filed by this GSTN.
                </Button>
              </Col>
            </Row>
          </div>
          <Modal
            title="Please select Financial Year to proceed"
            visible={this.state.queryModal}
            onOk={this.queryYEAR}
            onCancel={this.queryYEAR_CANCEL}
          >
            <div style={{ textAlign: "center" }}>
              <Select
                defaultValue={this.props.finYear}
                style={{ width: 120, marginTop: "10px" }}
                onChange={this.handleYearChange}
              >
                <Select.Option value="2017-18">2017-18</Select.Option>
                <Select.Option value="2018-19">2018-19</Select.Option>
                <Select.Option value="2019-20">2019-20</Select.Option>
                <Select.Option value="2020-21">2020-21</Select.Option>
                <Select.Option value="2021-22">2021-22</Select.Option>
              </Select>
            </div>
          </Modal>
          <Modal
            title={
              (this.state.supplierInvoices.length === 0
                ? "No"
                : this.state.supplierInvoices.length) +
              " Invoice(s) filed by " +
              this.state.gstnName +
              " for FY " +
              this.state.queryModalYearSelection
            }
            // title={this.getModalTitle}
            visible={this.state.visibleModal}
            onCancel={this.hideModal}
            footer={null}
            width="96%"
          >
            <SRow gutter={24} style={row_style}>
              <Col span="4">
                Total Invoice Value:{" "}
                {formatNumber(
                  reduce((curr, next) => {
                    curr =
                      parseFloat(curr) +
                      parseFloat(
                        next.invoice_value ? next.invoice_value : "0",
                        2
                      );
                    var temp = parseFloat(curr);
                    temp = parseFloat(temp).toFixed(2);
                    return temp;
                  }, 0)(this.state.supplierInvoices)
                )}
              </Col>
              <Col span="4">
                Total Taxable Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("recon_pr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(
                          next.taxable_value ? next.taxable_value : "0",
                          2
                        );
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.supplierInvoices)
                )}
              </Col>
              <Col span="4">
                Total IGST Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("recon_pr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.igst ? next.igst : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.supplierInvoices)
                )}
              </Col>
              <Col span="4">
                Total CGST Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("recon_pr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.cgst ? next.cgst : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.supplierInvoices)
                )}
              </Col>
              <Col span="4">
                Total SGST Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("recon_pr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.sgst ? next.sgst : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.supplierInvoices)
                )}
              </Col>
              <Col span="4">
                Total CESS Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("recon_pr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.cess ? next.cess : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.supplierInvoices)
                )}
              </Col>
            </SRow>
            <Table
              title={this.getModalTitle}
              dataSource={this.state.supplierInvoices}
              bordered
              footer={() => (
                <div style={{ textAlign: "center" }}>
                  <Button
                    icon="download"
                    style={{ backgroundColor: "#2382c7", color: "white" }}
                    onClick={() => this.RFT_Excel(this.state.supplierInvoices)}
                  >
                    Download as Excel
                  </Button>
                </div>
              )}
              locale={{ emptyText: "No invoices found!" }}
              rowKey="id"
            >
              <Column
                title="Invoice Number"
                dataIndex="invoice_number"
                className="alignCenter"
                key="invoice_number"
                filterDropdown={({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters
                }) => (
                  <div className="custom-filter-dropdown">
                    <Input
                      placeholder="Search Invoice Number"
                      value={selectedKeys[0]}
                      onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={this.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                      type="primary"
                      onClick={this.handleSearch(selectedKeys, confirm)}
                    >
                      Search
                    </Button>
                    <Button
                      id="InvoiceSearch"
                      onClick={this.handleReset(clearFilters)}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                filterIcon={filtered => (
                  <Icon
                    type="search"
                    style={{ color: filtered ? "#108ee9" : "#aaa" }}
                  />
                )}
                onFilter={(value, record) =>
                  record.invoice_number
                    .toLowerCase()
                    .includes(value.toLowerCase())
                }
                render={text => {
                  return this.state.searchText ? (
                    <span>
                      {text
                        .split(
                          new RegExp(
                            `(?<=${this.state.searchText})|(?=${
                              this.state.searchText
                            })`,
                            "i"
                          )
                        )
                        .map(
                          (fragment, i) =>
                            fragment.toLowerCase() ===
                            this.state.searchText.toLowerCase() ? (
                              <span key={i} className="highlight">
                                {fragment}
                              </span>
                            ) : (
                              fragment
                            ) // eslint-disable-line
                        )}
                    </span>
                  ) : (
                    text
                  );
                }}
              />
              {/*<Column
                        title="GSTIN"
                        dataIndex="supplier_gstin"
                        className="alignCenter"
                        key="supplier_gstin"                        
                      />*/}
              <Column
                title="Invoice Date"
                dataIndex="invoice_date"
                className="alignCenter"
                key="invoice_date"
                defaultSortOrder="descend"
                sorter={(a, b) => this.sortDate(a.invoice_date, b.invoice_date)}
                render={text =>
                  text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
                }
              />
              <Column
                title="Invoice Value"
                dataIndex="invoice_value"
                className="alignCenter"
                key="invoice_value"
                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
              />
              <Column
                title="Invoice Type"
                dataIndex="invoice_type"
                className="alignCenter"
                key="invoice_type"
              />
              <Column
                title="POS"
                dataIndex="pos_id"
                className="alignCenter"
                key="pos_id"
                render={pos => pos ?
                  getPlaceOfSupply(pos.substring(0,2)) : null
                }
              />
              <Column
                title="Reverse Charge"
                dataIndex="reverse_charge"
                className="alignCenter"
                key="reverse_charge"
              />

              <Column
                title="Items"
                key="action"
                className="alignCenter"
                rowSpan="2"
                render={(text, record, index) => {
                  var but = (
                    <SRow gutter={24} style={{ textAlign: "center" }}>
                      <Col span="8">
                        <Button
                          icon="bars"
                          type="primary"
                          onClick={() => this.showItemModal(record)}
                        >
                          Items
                        </Button>
                        <Modal
                          title="List Items Summary"
                          visible={
                            this.state.visibleItemModal === record.id
                              ? true
                              : false
                          }
                          onCancel={this.hideItemModal}
                          footer={null}
                          width="920"
                        >
                          <Table
                            dataSource={record.recon_pr_b2b_items}
                            bordered
                            pagination={{
                              className: "my-pagination",
                              defaultPageSize: 6
                            }}
                          >
                            <Column
                              title="Rate"
                              dataIndex="gst_rate"
                              className="alignCenter"
                              key="gst_rate"
                            />
                            <Column
                              title="Taxable Value"
                              className="alignCenter"
                              dataIndex="taxable_value"
                              key="taxable_value"
                              render={val => (
                                <p>{formatNumber(val === null ? 0 : val)}</p>
                              )}
                            />
                            <Column
                              title="IGST"
                              dataIndex="igst"
                              className="alignCenter"
                              key="igst"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                            <Column
                              title="CGST"
                              dataIndex="cgst"
                              className="alignCenter"
                              key="cgst"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                            <Column
                              title="SGST"
                              dataIndex="sgst"
                              className="alignCenter"
                              key="sgst"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                            <Column
                              title="Cess"
                              dataIndex="cess"
                              className="alignCenter"
                              key="cess"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                          </Table>
                        </Modal>
                      </Col>
                    </SRow>
                  );
                  return but;
                }}
              />
            </Table>
          </Modal>
          <Modal
            title={
              (this.state.customerInvoices.length === 0
                ? "No"
                : this.state.customerInvoices.length) +
              " Invoice(s) sent to " +
              this.state.gstnName +
              " for FY " +
              this.state.queryModalYearSelection
            }
            // title={this.getModalTitle}
            visible={this.state.visibleModal_Customer}
            onCancel={this.hideModal_Customer}
            footer={null}
            width="96%"
          >
            <SRow gutter={24} style={row_style}>
              <Col span="4">
                Total Invoice Value:{" "}
                {formatNumber(
                  reduce((curr, next) => {
                    curr =
                      parseFloat(curr) +
                      parseFloat(
                        next.invoice_value ? next.invoice_value : "0",
                        2
                      );
                    var temp = parseFloat(curr);
                    temp = parseFloat(temp).toFixed(2);
                    return temp;
                  }, 0)(this.state.customerInvoices)
                )}
              </Col>
              <Col span="4">
                Total Taxable Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("sr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(
                          next.taxable_value ? next.taxable_value : "0",
                          2
                        );
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.customerInvoices)
                )}
              </Col>
              <Col span="4">
                Total IGST Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("sr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.igst ? next.igst : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.customerInvoices)
                )}
              </Col>
              <Col span="4">
                Total CGST Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("sr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.cgst ? next.cgst : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.customerInvoices)
                )}
              </Col>
              <Col span="4">
                Total SGST Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("sr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.sgst ? next.sgst : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.customerInvoices)
                )}
              </Col>
              <Col span="4">
                Total CESS Value:{" "}
                {formatNumber(
                  flow(
                    flatMap(get("sr_b2b_items")),
                    reduce((curr, next) => {
                      curr =
                        parseFloat(curr) +
                        parseFloat(next.cess ? next.cess : "0", 2);
                      var temp = parseFloat(curr);
                      temp = parseFloat(temp).toFixed(2);
                      return temp;
                    }, 0)
                  )(this.state.customerInvoices)
                )}
              </Col>
            </SRow>
            <Table
              title={this.getModalTitle}
              dataSource={this.state.customerInvoices}
              bordered
              footer={() => (
                <div style={{ textAlign: "center" }}>
                  <Button
                    icon="download"
                    style={{ backgroundColor: "#2382c7", color: "white" }}
                    onClick={() => this.RFT_Excel(this.state.customerInvoices)}
                  >
                    Download as Excel
                  </Button>
                </div>
              )}
              locale={{ emptyText: "No invoices found!" }}
              rowKey="id"
            >
              <Column
                title="Invoice Number"
                dataIndex="invoice_number"
                className="alignCenter"
                key="invoice_number"
                filterDropdown={({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters
                }) => (
                  <div className="custom-filter-dropdown">
                    <Input
                      placeholder="Search Invoice Number"
                      value={selectedKeys[0]}
                      onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={this.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                      type="primary"
                      onClick={this.handleSearch(selectedKeys, confirm)}
                    >
                      Search
                    </Button>
                    <Button
                      id="InvoiceSearch_Sales"
                      onClick={this.handleReset(clearFilters)}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                filterIcon={filtered => (
                  <Icon
                    type="search"
                    style={{ color: filtered ? "#108ee9" : "#aaa" }}
                  />
                )}
                onFilter={(value, record) =>
                  record.invoice_number
                    .toLowerCase()
                    .includes(value.toLowerCase())
                }
                render={text => {
                  return this.state.searchText ? (
                    <span>
                      {text
                        .split(
                          new RegExp(
                            `(?<=${this.state.searchText})|(?=${
                              this.state.searchText
                            })`,
                            "i"
                          )
                        )
                        .map(
                          (fragment, i) =>
                            fragment.toLowerCase() ===
                            this.state.searchText.toLowerCase() ? (
                              <span key={i} className="highlight">
                                {fragment}
                              </span>
                            ) : (
                              fragment
                            ) // eslint-disable-line
                        )}
                    </span>
                  ) : (
                    text
                  );
                }}
              />
              {/*<Column
                        title="GSTIN"
                        dataIndex="supplier_gstin"
                        className="alignCenter"
                        key="receiver_gstin"                        
                      />*/}
              <Column
                title="Invoice Date"
                dataIndex="invoice_date"
                className="alignCenter"
                key="invoice_date"
                defaultSortOrder="descend"
                sorter={(a, b) => this.sortDate(a.invoice_date, b.invoice_date)}
                render={text =>
                  text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
                }
              />
              <Column
                title="Invoice Value"
                dataIndex="invoice_value"
                className="alignCenter"
                key="invoice_value"
                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
              />
              <Column
                title="Invoice Type"
                dataIndex="invoice_type"
                className="alignCenter"
                key="invoice_type"
              />
              <Column
                title="POS"
                dataIndex="pos_id"
                className="alignCenter"
                key="pos_id"
                render={pos => pos ?
                  getPlaceOfSupply(pos.substring(0,2)) : null
                }
              />
              <Column
                title="Reverse Charge"
                dataIndex="reverse_charge"
                className="alignCenter"
                key="reverse_charge"
              />

              <Column
                title="Items"
                key="action"
                className="alignCenter"
                rowSpan="2"
                render={(text, record, index) => {
                  var but = (
                    <SRow gutter={24} style={{ textAlign: "center" }}>
                      <Col span="8">
                        <Button
                          icon="bars"
                          type="primary"
                          onClick={() => this.showItemModal_Customer(record)}
                        >
                          Items
                        </Button>
                        <Modal
                          title="List Items Summary"
                          visible={
                            this.state.visibleItemModal_Customer === record.id
                              ? true
                              : false
                          }
                          onCancel={this.hideItemModal_Customer}
                          footer={null}
                          width="920"
                        >
                          <Table
                            dataSource={record.sr_b2b_items}
                            bordered
                            pagination={{
                              className: "my-pagination",
                              defaultPageSize: 6
                            }}
                          >
                            <Column
                              title="Rate"
                              dataIndex="gst_rate"
                              className="alignCenter"
                              key="gst_rate"
                            />
                            <Column
                              title="Taxable Value"
                              className="alignCenter"
                              dataIndex="taxable_value"
                              key="taxable_value"
                              render={val => (
                                <p>{formatNumber(val === null ? 0 : val)}</p>
                              )}
                            />
                            <Column
                              title="IGST"
                              dataIndex="igst"
                              className="alignCenter"
                              key="igst"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                            <Column
                              title="CGST"
                              dataIndex="cgst"
                              className="alignCenter"
                              key="cgst"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                            <Column
                              title="SGST"
                              dataIndex="sgst"
                              className="alignCenter"
                              key="sgst"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                            <Column
                              title="Cess"
                              dataIndex="cess"
                              className="alignCenter"
                              key="cess"
                              render={val => (
                                <p>
                                  {val === null ? " - - - " : formatNumber(val)}
                                </p>
                              )}
                            />
                          </Table>
                        </Modal>
                      </Col>
                    </SRow>
                  );
                  return but;
                }}
              />
            </Table>
          </Modal>
        </div>
      </Content>
    );
  }
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear
    }
  }),
  props: ({ company }) => {
    return {
      company: get("viewer.company", company),
      gspDetails: get("viewer.gspInfo", company)
    };
  }
};

const suppliersQueryConfig = {
  name: "suppliers",
  options: props => ({
    variables: {
      companyId: props.companyId
    }
  }),
  props: ({ suppliers }) => {
    console.log(suppliers);
    return {
      suppliers: get("viewer.companiesDetails.edges", suppliers)
    };
  }
};

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(
    mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }
  ),
  Form.create(),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
  //graphql(gql(CompaniesDetails), suppliersQueryConfig)
)(SupplierDetails);
