import React from "react";
import { Form, Button, InputNumber, Anchor, Layout, message, Spin } from "antd";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/fp/get";
import values from "lodash/fp/values";
import { GSTR3B_Mutation, osup_detQuery } from "qgst-gql-queries/web/data.json";
import { BASE_URL } from "../../shared/constants";
import { ContentWrapper } from "../../shared/styles";
import { FormItem } from "../../shared/utils";
import ThreePointTwo from "./ThreePointTwo";
import map from "lodash/fp/map";
import reduce from "lodash/fp/reduce";
import flow from "lodash/fp/flow";
import compact from "lodash/fp/compact"
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 14,
      offset: 6
    }
  }
};

const { Link } = Anchor;
const { Sider, Content } = Layout;
var inputRef = [];
var threepointtwo = null;

const EnterGSTR3BComponent = props => (
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR3B} >
      <h3 style={{ marginLeft: "35" }} id="link1">
        3.1 Details of outward supplies and inward supplies liable to reverse
        charge
      </h3>
      <table>
        <thead>
          <tr>
            <th style={{ width: "100%", height: "50px" }}>
              <h4>
                <b>Nature Of Supplies</b>
              </h4>
            </th>
            <th style={{ width: "70%" }}>
              <h4>
                <b>Total Taxable Value</b>
              </h4>
            </th>
            <th style={{ width: "70%" }}>
              <h4>
                <b>Integrated Tax</b>
              </h4>
            </th>
            <th style={{ width: "70%" }}>
              <h4>
                <b>Central Tax</b>
              </h4>
            </th>
            <th style={{ width: "70%" }}>
              <h4>
                <b>State/UT Tax</b>
              </h4>
            </th>
            <th style={{ width: "70%" }}>
              <h4>
                <b>Cess</b>
              </h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              (a) Outward taxable supplies * (other than zero rated, nil rated
              and exempted)
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Aa_a", {
                  initialValue: props.gstr_three_b.osup_det_query
                    ? props.gstr_three_b.osup_det_query.txval
                    : 'zero'
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Aa_a"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Aa_a"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_det_query
                      ? props.gstr_three_b.osup_det_query.txval
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Aa_b", {
                  initialValue: props.gstr_three_b.osup_det_query
                    ? props.gstr_three_b.osup_det_query.iamt
                    : 0.00
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Aa_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Aa_b"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_det_query
                      ? props.gstr_three_b.osup_det_query.iamt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Aa_c", {
                  initialValue: props.gstr_three_b.osup_det_query
                    ? props.gstr_three_b.osup_det_query.camt
                    : 0.00
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Aa_c"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Aa_c"
                  onChange={props.outwardTaxableCGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_det_query
                      ? props.gstr_three_b.osup_det_query.camt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Aa_c", {
                  initialValue: props.gstr_three_b.osup_det_query
                    ? props.gstr_three_b.osup_det_query.samt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Aa_d"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Aa_d"
                  onChange={props.outwardTaxableSGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_det_query
                      ? props.gstr_three_b.osup_det_query.samt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Aa_e", {
                  initialValue: props.gstr_three_b.osup_det_query
                    ? props.gstr_three_b.osup_det_query.csamt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Aa_e"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Aa_e"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_det_query
                      ? props.gstr_three_b.osup_det_query.csamt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
          </tr>

          <tr>
            <td>(b) Outward taxable supplies * (zero rated )</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ab_a", {
                  initialValue: props.gstr_three_b.osup_zero_query
                    ? props.gstr_three_b.osup_zero_query.txval
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ab_a"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ab_a"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_zero_query
                      ? props.gstr_three_b.osup_zero_query.txval
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ab_b", {
                  initialValue: props.gstr_three_b.osup_zero_query
                    ? props.gstr_three_b.osup_zero_query.iamt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ab_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ab_b"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_zero_query
                      ? props.gstr_three_b.osup_zero_query.iamt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ab_c", {
                  initialValue: props.gstr_three_b.osup_zero_query
                    ? props.gstr_three_b.osup_zero_query.camt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ab_c"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  id="Ab_c"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={0.0}
                  disabled={true}
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ab_c", {
                  initialValue: props.gstr_three_b.osup_zero_query
                    ? props.gstr_three_b.osup_zero_query.samt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ab_d"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  id="Ab_d"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={0.0}
                  disabled={true}
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ab_e", {
                  initialValue: props.gstr_three_b.osup_zero_query
                    ? props.gstr_three_b.osup_zero_query.csamt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ab_e"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ab_e"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_zero_query
                      ? props.gstr_three_b.osup_zero_query.csamt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
          </tr>

          <tr>
            <td>(c) Other outward supplies (Nil rated, exempted)</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ac_a", {
                  initialValue: props.gstr_three_b.osup_nil_exmp_query
                    ? props.gstr_three_b.osup_nil_exmp_query.txval
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ac_a"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ac_a"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_nil_exmp_query
                      ? props.gstr_three_b.osup_nil_exmp_query.txval
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ac_b", {})( */}
                <InputNumber
                  ref={c => (props.inputRef["Ac_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  id="Ac_b"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={0.0}
                  disabled={true}
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ac_c", {})( */}
                <InputNumber
                  ref={c => (props.inputRef["Ac_c"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  id="Ac_c"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={0.0}
                  disabled={true}
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ac_c", {})( */}
                <InputNumber
                  ref={c => (props.inputRef["Ac_d"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  id="Ac_d"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={0.0}
                  disabled={true}
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ac_e", {})( */}
                <InputNumber
                  ref={c => (props.inputRef["Ac_e"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  id="Ac_e"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={0.0}
                  disabled={true}
                />
                {/* )} */}
              </FormItem>
            </td>
          </tr>

          <tr>
            <td>(d) Inward supplies (liable to reverse charge)</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ad_a", {
                  initialValue: props.gstr_three_b.isup_rev_query
                    ? props.gstr_three_b.isup_rev_query.txval
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ad_a"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ad_a"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.isup_rev_query
                      ? props.gstr_three_b.isup_rev_query.txval
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ad_b", {
                  initialValue: props.gstr_three_b.isup_rev_query
                    ? props.gstr_three_b.isup_rev_query.iamt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ad_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ad_b"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.isup_rev_query
                      ? props.gstr_three_b.isup_rev_query.iamt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ad_c", {
                  initialValue: props.gstr_three_b.isup_rev_query
                    ? props.gstr_three_b.isup_rev_query.camt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ad_c"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ad_c"
                  onChange={props.inwardTaxableCGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.isup_rev_query
                      ? props.gstr_three_b.isup_rev_query.camt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ad_c", {
                  initialValue: props.gstr_three_b.isup_rev_query
                    ? props.gstr_three_b.isup_rev_query.samt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ad_d"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ad_d"
                  onChange={props.inwardTaxableSGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.isup_rev_query
                      ? props.gstr_three_b.isup_rev_query.samt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ad_e", {
                  initialValue: props.gstr_three_b.isup_rev_query
                    ? props.gstr_three_b.isup_rev_query.csamt
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ad_e"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ad_e"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.isup_rev_query
                      ? props.gstr_three_b.isup_rev_query.csamt
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
          </tr>

          <tr>
            <td>(e) Non-GST outward supplies</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ae_a", {
                  initialValue: props.gstr_three_b.osup_nongst_query
                    ? props.gstr_three_b.osup_nongst_query.txval
                    : 0.0
                })( */}
                <InputNumber
                  ref={c => (props.inputRef["Ae_a"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Ae_a"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.osup_nongst_query
                      ? props.gstr_three_b.osup_nongst_query.txval
                      : 0.0
                  }
                />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {
                  <InputNumber
                    step={0.01}
                    style={{ width: 135 }}
                    formatter={value => props.formatNumber(value)}
                    disabled={true}
                    defaultValue={0.0}
                  />
                }
              </FormItem>
            </td>
            <td>
              <FormItem>
                {
                  <InputNumber
                    step={0.01}
                    style={{ width: 135 }}
                    formatter={value => props.formatNumber(value)}
                    disabled={true}
                    defaultValue={0.0}
                  />
                }
              </FormItem>
            </td>
            <td>
              <FormItem>
                {
                  <InputNumber
                    step={0.01}
                    style={{ width: 135 }}
                    formatter={value => props.formatNumber(value)}
                    disabled={true}
                    defaultValue={0.0}
                  />
                }
              </FormItem>
            </td>
            <td>
              <FormItem>
                {
                  <InputNumber
                    step={0.01}
                    style={{ width: 135 }}
                    formatter={value => props.formatNumber(value)}
                    disabled={true}
                    defaultValue={0.0}
                  />
                }
              </FormItem>
            </td>
          </tr>
        </tbody>
      </table>

      <h3 style={{ marginLeft: "35" }} id="link2">
        3.2 Of the supplies shown in 3.1 (a) above, details of inter-State
        supplies made to unregistered persons, composition taxable persons and
        UIN holders
      </h3>
      {props.getFieldDecorator('names')(<ThreePointTwo
              getFieldDecorator={props.getFieldDecorator}
              getFieldValue={props.getFieldValue}
              setFieldsValue={props.setFieldsValue}
              queryFields={props.queryFields}
              inputRef={props.inputRef}
              onFocusEvent={props.onFocusEvent}
              onBlurEvent={props.onBlurEvent}
              formatNumber={props.formatNumber}
              // removeItemDetailsFromParent={props.removeItemDetailsFromParent}
              insertThreePointTwoData={props.insertThreePointTwoData}
            />)}
      
      <h3 style={{ marginLeft: "35" }} id="link3">
        4. Eligible ITC
      </h3>

      <table
        style={{
          width: "90%",
          marginLeft: "5%",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "100%", height: "50px" }}>
              <h4>
                <b>Eligible ITC</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Integrated Tax</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Central Tax</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>State/UT Tax</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Cess</b>
              </h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="5">
              <h5>
                <b>(A) ITC Available (whether in full or part)</b>
              </h5>
            </td>
          </tr>
          <tr>
            <td>(1) Import of goods</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ba_a", {
                  initialValue: props.gstr_three_b.itc_avl_query_a
                    ? props.gstr_three_b.itc_avl_query_a.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ba_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Ba_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_a
                        ? props.gstr_three_b.itc_avl_query_a.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ba_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_a
                    ? props.gstr_three_b.itc_avl_query_a.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ba_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    id="Ba_b"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={0.0}
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ba_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_a
                    ? props.gstr_three_b.itc_avl_query_a.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ba_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    id="Ba_c"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={0.0}
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ba_d", {
                  initialValue: props.gstr_three_b.itc_avl_query_a
                    ? props.gstr_three_b.itc_avl_query_a.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ba_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Ba_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_a
                        ? props.gstr_three_b.itc_avl_query_a.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>(2) Import of services</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bb_a", {
                  initialValue: props.gstr_three_b.itc_avl_query_b
                    ? props.gstr_three_b.itc_avl_query_b.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bb_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bb_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_b
                        ? props.gstr_three_b.itc_avl_query_b.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bb_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_b
                    ? props.gstr_three_b.itc_avl_query_b.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bb_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    id="Bb_b"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={0.0}
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bb_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_b
                    ? props.gstr_three_b.itc_avl_query_b.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bb_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    id="Bb_c"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={0.0}
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bb_d", {
                  initialValue: props.gstr_three_b.itc_avl_query_b
                    ? props.gstr_three_b.itc_avl_query_b.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bb_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bb_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_b
                        ? props.gstr_three_b.itc_avl_query_b.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>
              (3) Inward supplies liable to reverse charge (other than 1 & 2
              above)
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bc_a", {
                  initialValue: props.gstr_three_b.itc_avl_query_c
                    ? props.gstr_three_b.itc_avl_query_c.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bc_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bc_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_c
                        ? props.gstr_three_b.itc_avl_query_c.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bc_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_c
                    ? props.gstr_three_b.itc_avl_query_c.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bc_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bc_b"
                    formatter={value => props.formatNumber(value)}
                    onChange={props.inwardITCCGSTChange}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_c
                        ? props.gstr_three_b.itc_avl_query_c.camt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bc_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_c
                    ? props.gstr_three_b.itc_avl_query_c.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bc_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bc_c"
                    formatter={value => props.formatNumber(value)}
                    onChange={props.inwardITCSGSTChange}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_c
                        ? props.gstr_three_b.itc_avl_query_c.samt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bc_d", {
                  initialValue: props.gstr_three_b.itc_avl_query_c
                    ? props.gstr_three_b.itc_avl_query_c.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bc_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bc_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_c
                        ? props.gstr_three_b.itc_avl_query_c.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>(4) Inward supplies from ISD</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bd_a", {
                  initialValue: props.gstr_three_b.itc_avl_query_d
                    ? props.gstr_three_b.itc_avl_query_d.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bd_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bd_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_d
                        ? props.gstr_three_b.itc_avl_query_d.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bd_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_d
                    ? props.gstr_three_b.itc_avl_query_d.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bd_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bd_b"
                    onChange={props.inwardISDCGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_d
                        ? props.gstr_three_b.itc_avl_query_d.camt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bd_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_d
                    ? props.gstr_three_b.itc_avl_query_d.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bd_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bd_c"
                    onChange={props.inwardISDSGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_d
                        ? props.gstr_three_b.itc_avl_query_d.samt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bd_d", {
                  initialValue: props.gstr_three_b.itc_avl_query_d
                    ? props.gstr_three_b.itc_avl_query_d.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bd_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bd_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_d
                        ? props.gstr_three_b.itc_avl_query_d.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>(5) All other ITC</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Be_a", {
                  initialValue: props.gstr_three_b.itc_avl_query_e
                    ? props.gstr_three_b.itc_avl_query_e.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Be_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Be_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_e
                        ? props.gstr_three_b.itc_avl_query_e.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Be_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_e
                    ? props.gstr_three_b.itc_avl_query_e.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Be_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Be_b"
                    onChange={props.inwardAOTHCGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_e
                        ? props.gstr_three_b.itc_avl_query_e.camt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Be_b", {
                  initialValue: props.gstr_three_b.itc_avl_query_e
                    ? props.gstr_three_b.itc_avl_query_e.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Be_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Be_c"
                    onChange={props.inwardAOTHSGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_e
                        ? props.gstr_three_b.itc_avl_query_e.samt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Be_d", {
                  initialValue: props.gstr_three_b.itc_avl_query_e
                    ? props.gstr_three_b.itc_avl_query_e.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Be_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Be_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_avl_query_e
                        ? props.gstr_three_b.itc_avl_query_e.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <h5>
                <b>(B) ITC Reversed</b>
              </h5>
            </td>
          </tr>
          <tr>
            <td>(1) As per Rule 42 & 43 of CGST/SGST rules</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bf_a", {
                  initialValue: props.gstr_three_b.itc_rev_query_a
                    ? props.gstr_three_b.itc_rev_query_a.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bf_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bf_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_a
                        ? props.gstr_three_b.itc_rev_query_a.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bf_b", {
                  initialValue: props.gstr_three_b.itc_rev_query_a
                    ? props.gstr_three_b.itc_rev_query_a.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bf_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bf_b"
                    onChange={props.inwardITC1CGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_a
                        ? props.gstr_three_b.itc_rev_query_a.camt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bf_b", {
                  initialValue: props.gstr_three_b.itc_rev_query_a
                    ? props.gstr_three_b.itc_rev_query_a.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bf_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bf_c"
                    onChange={props.inwardITC1SGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_a
                        ? props.gstr_three_b.itc_rev_query_a.samt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bf_d", {
                  initialValue: props.gstr_three_b.itc_rev_query_a
                    ? props.gstr_three_b.itc_rev_query_a.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bf_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bf_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_a
                        ? props.gstr_three_b.itc_rev_query_a.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>(2) Others</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bg_a", {
                  initialValue: props.gstr_three_b.itc_rev_query_b
                    ? props.gstr_three_b.itc_rev_query_b.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bg_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bg_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_b
                        ? props.gstr_three_b.itc_rev_query_b.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bg_b", {
                  initialValue: props.gstr_three_b.itc_rev_query_b
                    ? props.gstr_three_b.itc_rev_query_b.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bg_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bg_b"
                    onChange={props.inwardITC2CGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_b
                        ? props.gstr_three_b.itc_rev_query_b.camt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bg_c", {
                  initialValue: props.gstr_three_b.itc_rev_query_b
                    ? props.gstr_three_b.itc_rev_query_b.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bg_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bg_c"
                    onChange={props.inwardITC2SGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_b
                        ? props.gstr_three_b.itc_rev_query_b.samt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bg_d", {
                  initialValue: props.gstr_three_b.itc_rev_query_b
                    ? props.gstr_three_b.itc_rev_query_b.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bg_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bg_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_rev_query_b
                        ? props.gstr_three_b.itc_rev_query_b.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <h5>
                <b>(C) Net ITC Available (A) – (B)</b>
              </h5>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bh_a", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.itc_net_query.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bh_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bh_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_net_query
                        ? props.gstr_three_b.itc_net_query.iamt
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bh_b", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.itc_net_query.camt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bh_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bh_b"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_net_query
                      ? props.gstr_three_b.itc_net_query.camt
                      : 0.0
                  }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bh_c", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.itc_net_query.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bh_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bh_c"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_net_query
                        ? props.gstr_three_b.itc_net_query.samt
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bh_d", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.itc_net_query.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bh_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bh_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_net_query
                        ? props.gstr_three_b.itc_net_query.csamt
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              <h4>
                <b>(D) Ineligible ITC</b>
              </h4>
            </td>
          </tr>
          <tr>
            <td>(1) As per section 17(5)</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bi_a", {
                  initialValue: props.gstr_three_b.itc_inelg_query_a
                    ? props.gstr_three_b.itc_inelg_query_a.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Bi_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Bi_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.itc_inelg_query_a
                        ? props.gstr_three_b.itc_inelg_query_a.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bi_b", {
                  initialValue: props.gstr_three_b.itc_inelg_query_a
                    ? props.gstr_three_b.itc_inelg_query_a.camt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bi_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bi_b"
                  onChange={props.inwardIITC1CGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_a
                      ? props.gstr_three_b.itc_inelg_query_a.camt
                      : 0.0
                  }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bi_b", {
                  initialValue: props.gstr_three_b.itc_inelg_query_a
                    ? props.gstr_three_b.itc_inelg_query_a.samt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bi_c"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bi_c"
                  onChange={props.inwardIITC1SGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_a
                      ? props.gstr_three_b.itc_inelg_query_a.samt
                      : 0.0
                  }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bi_d", {
                  initialValue: props.gstr_three_b.itc_inelg_query_a
                    ? props.gstr_three_b.itc_inelg_query_a.csamt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bi_d"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bi_d"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_a
                      ? props.gstr_three_b.itc_inelg_query_a.csamt
                      : 0.0
                  }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>(2) Others</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bj_a", {
                  initialValue: props.gstr_three_b.itc_inelg_query_b
                    ? props.gstr_three_b.itc_inelg_query_b.iamt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bj_a"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bj_a"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_b
                      ? props.gstr_three_b.itc_inelg_query_b.iamt
                      : 0.0
                  }
                  />
                 {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bj_b", {
                  initialValue: props.gstr_three_b.itc_inelg_query_b
                    ? props.gstr_three_b.itc_inelg_query_b.camt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bj_b"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bj_b"
                  onChange={props.inwardIITC2CGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_b
                      ? props.gstr_three_b.itc_inelg_query_b.camt
                      : 0.0
                  }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bj_b", {
                  initialValue: props.gstr_three_b.itc_inelg_query_b
                    ? props.gstr_three_b.itc_inelg_query_b.samt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bj_c"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bj_c"
                  onChange={props.inwardIITC2SGSTChange}
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_b
                      ? props.gstr_three_b.itc_inelg_query_b.samt
                      : 0.0
                  }
                  />
                 {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Bj_d", {
                  initialValue: props.gstr_three_b.itc_inelg_query_b
                    ? props.gstr_three_b.itc_inelg_query_b.csamt
                    : 0.0
                })( */}
                  <InputNumber
                  ref={c => (props.inputRef["Bj_d"] = c)}
                  step={0.01}
                  style={{ width: 135 }}
                  onFocus={props.onFocusEvent}
                  onBlur={props.onBlurEvent}
                  id="Bj_d"
                  formatter={value => props.formatNumber(value)}
                  defaultValue={
                    props.gstr_three_b.itc_inelg_query_b
                      ? props.gstr_three_b.itc_inelg_query_b.csamt
                      : 0.0
                  }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 style={{ marginLeft: "35" }} id="link4">
        5. Values of exempt, nil-rated and non-GST inward supplies
      </h3>
      <table
        style={{
          width: "90%",
          marginLeft: "5%"
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "100%", height: "50px" }}>
              <h4>
                <b>Nature Of Supplies</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Inter-State Supplies</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Intra-State Supplies</b>
              </h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              From a supplier under composition scheme, Exempt and Nil rated
              supply
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Da_a", {
                  initialValue: props.gstr_three_b.inward_sup_query_a
                    ? props.gstr_three_b.inward_sup_query_a.inter
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Da_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Da_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.inward_sup_query_a
                        ? props.gstr_three_b.inward_sup_query_a.inter
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Da_b", {
                  initialValue: props.gstr_three_b.inward_sup_query_a
                    ? props.gstr_three_b.inward_sup_query_a.intra
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Da_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Da_b"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.inward_sup_query_a
                        ? props.gstr_three_b.inward_sup_query_a.intra
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>Non GST Supply</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Db_a", {
                  initialValue: props.gstr_three_b.inward_sup_query_b
                    ? props.gstr_three_b.inward_sup_query_b.inter
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Db_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Db_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.inward_sup_query_b
                        ? props.gstr_three_b.inward_sup_query_b.inter
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Db_b", {
                  initialValue: props.gstr_three_b.inward_sup_query_b
                    ? props.gstr_three_b.inward_sup_query_b.intra
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Db_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Db_b"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.inward_sup_query_b
                        ? props.gstr_three_b.inward_sup_query_b.intra
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
        </tbody>
      </table>
      <h3 style={{ marginLeft: "35" }} id="link5">
        5.1 Interest and late fee payable
      </h3>
      <table
        style={{
          width: "90%",
          marginLeft: "5%"
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "100%", height: "50px" }}>
              <h4>
                <b>Description</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Integrated Tax</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Central Tax</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>State/UT Tax</b>
              </h4>
            </th>
            <th>
              <h4>
                <b>Cess</b>
              </h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Interest amount</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ea_a", {
                  initialValue: props.gstr_three_b.intr_details_query
                    ? props.gstr_three_b.intr_details_query.iamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ea_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Ea_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.intr_details_query
                        ? props.gstr_three_b.intr_details_query.iamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ea_b", {
                  initialValue: props.gstr_three_b.intr_details_query
                    ? props.gstr_three_b.intr_details_query.camt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ea_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Ea_b"
                    onChange={props.inwardINTCGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.intr_details_query
                        ? props.gstr_three_b.intr_details_query.camt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ea_b", {
                  initialValue: props.gstr_three_b.intr_details_query
                    ? props.gstr_three_b.intr_details_query.samt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ea_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Ea_c"
                    onChange={props.inwardINTSGSTChange}
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.intr_details_query
                        ? props.gstr_three_b.intr_details_query.samt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Ea_d", {
                  initialValue: props.gstr_three_b.intr_details_query
                    ? props.gstr_three_b.intr_details_query.csamt
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Ea_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Ea_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.intr_details_query
                        ? props.gstr_three_b.intr_details_query.csamt
                        : 0.0
                    }
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
          <tr>
            <td>Late Fees</td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Fb_a", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.Fb_a
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Fb_a"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Fb_a"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.Fb_a
                        ? props.gstr_three_b.Fb_a
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Fb_b", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.Fb_b
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Fb_b"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Fb_b"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.Fb_b
                        ? props.gstr_three_b.Fb_b
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Fb_c", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.Fb_c
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Fb_c"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Fb_c"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.Fb_c
                        ? props.gstr_three_b.Fb_c
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
            <td>
              <FormItem>
                {/* {props.getFieldDecorator("Fb_d", {
                  initialValue: props.gstr_three_b
                    ? props.gstr_three_b.Fb_d
                    : 0.0
                })( */}
                  <InputNumber
                    ref={c => (props.inputRef["Fb_d"] = c)}
                    step={0.01}
                    style={{ width: 135 }}
                    onFocus={props.onFocusEvent}
                    onBlur={props.onBlurEvent}
                    id="Fb_d"
                    formatter={value => props.formatNumber(value)}
                    defaultValue={
                      props.gstr_three_b.Fb_d
                        ? props.gstr_three_b.Fb_d
                        : 0.0
                    }
                    disabled={true}
                  />
                {/* )} */}
              </FormItem>
            </td>
          </tr>
        </tbody>
      </table>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Save GSTR3B Data
        </Button>
      </Form.Item>
    </Form>
  </ContentWrapper>
);

class EnterGSTR3B extends React.Component {
  formatNumber(value) {
    // console.log("formatting value :::: " + value);
    if (value === "") return "";
    // if (value > 0) {
    var x = value;
    x = x.toString();
    var afterPoint = "";
    if (x.indexOf(".") > 0)
      afterPoint = x.substring(x.indexOf("."), x.indexOf(".") + 3);
    // console.log(x.indexOf("."));
    x = x.indexOf(".") > 0 ? x.substring(0, x.indexOf(".")) : x;
    // x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;
    return `₹ ${res}`;
    // } else {
    //   return "";
    // }
  }

  saveGSTR3B = async e => {
    e.preventDefault();
    message.success("Saving GSTR3B Details!");
    // console.log(inputRef.names);
    let formError=null;
    let formValues=null;
    // console.log(inputRef);
    this.props.form.validateFields((err, values) => {
      // console.log(values);
      formError = err;
      if(err) return;
      formValues = values;
    });

    if(formError) {
      message.error("Please fill in all the required fields");
      return;
    }

    //"names: [["smcp","10",0,0],["smcp","35",0,0],["smup","04",0,0],["smuh","10",0,0]]"

    // const t = values(inputRef)
    let t = flow(
      values,
      reduce((c, i) => {
        if(i.props){
          c[i.props.id] = i.inputNumberRef.state.value;
        }
        return c;
      }, {})
    )(inputRef);

    // console.log(threepointtwo);

    const threePointTwo = flow(
      map(i => {
      // console.log(i);
      if(i[0].rcSelect === null || i[2].inputNumberRef === null) return null;
      return [i[0].props.value,i[1].props.value,i[2].inputNumberRef.state.value,i[3].inputNumberRef.state.value]
      // return i;
    }),
    compact
    )(inputRef.names)

    // console.log(JSON.stringify(threePointTwo));

    
    if(threePointTwo){
      t.names = threePointTwo
    }

    // console.log(t);


    try {
      await this.props.saveGSTR3B(t);
      // Reset fields only when adding new invoice
      // !this.props.isEdit && this.props.form.resetFields();
      message.success("GSTR3B saved successfully!");
      window.history.back();
    } catch (e) {
      message.error(e.graphQLErrors[0].message);
    }

    // this.props.form.validateFields(async (err, values) => {
    //   if (err) return;
    //   console.log(values);
    //   try {
    //     await this.props.saveGSTR3B(values);
    //     // Reset fields only when adding new invoice
    //     !this.props.isEdit && this.props.form.resetFields();
    //     message.success("GSTR3B saved successfully!");
    //     // window.history.back();
    //   } catch (e) {
    //     message.error(e.graphQLErrors[0].message);
    //   }
    // });
  };

  insertThreePointTwoData = (index, innerIndex, element) => {
    // console.log(element,index,innerIndex)
    if(element){
      if(inputRef.names === undefined) inputRef.names = [];
      if(inputRef.names[index] === undefined) inputRef.names[index] = [];
      inputRef.names[index][innerIndex] = element;
    }
    // console.log(inputRef);
  }

  resetGSTR3B = () => {
    // var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    // fetch(
    //   `${BASE_URL}/export/resetGSTR3B?from=sales&companyId=${
    //     this.props.companyId
    //   }&downloaddate=${downloaddate}`,
    //   {
    //     method: "GET"
    //   }
    // ).then(result => {
    //   message.success("GSTR-3B Data been reset for the selected tax period");
    // });
    // this.props.enter3bdata.refetch();
    window.history.back();
  };

  getThreePointTwoInput = v => {
    // console.log(v.substr(5,v.length - 5))
    const indexes = v.substr(5,v.length - 5);
    // console.log(inputRef.names);
    const firstIndex = indexes.substr(0, indexes.length - 1);
    const secondIndex = indexes.substr(indexes.length - 1);
    // console.log(firstIndex)
    // console.log(secondIndex)
    return inputRef.names[firstIndex][secondIndex];
  };

  onFocusEvent = e => {
    const focusedItem = e.target.id.indexOf("names") >= 0 ? this.getThreePointTwoInput(e.target.id) : inputRef[e.target.id]
    if (focusedItem) {
      if (focusedItem.inputNumberRef.state.value === 0) {
        focusedItem.inputNumberRef.setState({
          value: "",
          inputValue: ""
        });
      }
    }
  };

  onBlurEvent = e => {
    const focusedItem = e.target.id.indexOf("names") >= 0 ? this.getThreePointTwoInput(e.target.id) : inputRef[e.target.id]
    // console.log(focusedItem);
    if(focusedItem.inputNumberRef){
      if (focusedItem.inputNumberRef.state.inputValue == "" || focusedItem.inputNumberRef.state.inputValue == undefined) {
        focusedItem.inputNumberRef.setState({
          value: 0.0,
          inputValue: 0.0
        });
        this.updateCGSTorSGST(e.target.id);
      }
    }
  };

  updateCGSTorSGST = (id) => {
    switch(id){
      case "Aa_c" : {
        this.updateGSTTax(0.00,"Aa_d")
        break;
      }
      case "Aa_d" : {
        this.updateGSTTax(0.00,"Aa_c")
        break;
      }
      case "Ad_c" : {
        this.updateGSTTax(0.00,"Ad_d")
        break;
      }
      case "Ad_d" : {
        this.updateGSTTax(0.00,"Ad_c")
        break;
      }
      case "Bc_b" : {
        this.updateGSTTax(0.00,"Bc_c")
        break;
      }
      case "Bc_c" : {
        this.updateGSTTax(0.00,"Bc_b")
        break;
      }
      case "Bd_b" : {
        this.updateGSTTax(0.00,"Bd_c")
        break;
      }
      case "Bd_c" : {
        this.updateGSTTax(0.00,"Bd_b")
        break;
      }
      case "Be_b" : {
        this.updateGSTTax(0.00,"Be_c")
        break;
      }
      case "Be_c" : {
        this.updateGSTTax(0.00,"Be_b")
        break;
      }
      case "Bf_b" : {
        this.updateGSTTax(0.00,"Bf_c")
        break;
      }
      case "Bf_c" : {
        this.updateGSTTax(0.00,"Bf_b")
        break;
      }
      case "Bg_b" : {
        this.updateGSTTax(0.00,"Bg_c")
        break;
      }
      case "Bg_c" : {
        this.updateGSTTax(0.00,"Bg_b")
        break;
      }
      case "Bi_b" : {
        this.updateGSTTax(0.00,"Bi_c")
        break;
      }
      case "Bi_c" : {
        this.updateGSTTax(0.00,"Bi_b")
        break;
      }
      case "Bj_b" : {
        this.updateGSTTax(0.00,"Bj_c")
        break;
      }
      case "Bj_c" : {
        this.updateGSTTax(0.00,"Bj_b")
        break;
      }
      case "Ea_b" : {
        this.updateGSTTax(0.00,"Ea_c")
        break;
      }
      case "Ea_c" : {
        this.updateGSTTax(0.00,"Ea_b")
        break;
      }
    }
  };

  updateGSTTax = (value, pairId) => {
    if (value !== undefined) {
      const pairElement = inputRef[pairId];
      if (pairElement) {
        pairElement.inputNumberRef.setState({
          value: value,
          inputValue: value
        });
      }
    }
  };

  outwardTaxableCGSTChange = value => {
    this.updateGSTTax(value, "Aa_d");
  };
  outwardTaxableSGSTChange = value => {
    this.updateGSTTax(value, "Aa_c");
  };

  inwardTaxableCGSTChange = value => {
    this.updateGSTTax(value, "Ad_d");
  };
  inwardTaxableSGSTChange = value => {
    this.updateGSTTax(value, "Ad_c");
  };

  inwardITCCGSTChange = value => {
    this.updateGSTTax(value, "Bc_c");
  };
  inwardITCSGSTChange = value => {
    this.updateGSTTax(value, "Bc_b");
  };

  inwardISDCGSTChange = value => {
    this.updateGSTTax(value, "Bd_c");
  };
  inwardISDSGSTChange = value => {
    this.updateGSTTax(value, "Bd_b");
  };

  inwardAOTHCGSTChange = value => {
    this.updateGSTTax(value, "Be_c");
  };
  inwardAOTHSGSTChange = value => {
    this.updateGSTTax(value, "Be_b");
  };

  inwardITC1CGSTChange = value => {
    this.updateGSTTax(value, "Bf_c");
  };
  inwardITC1SGSTChange = value => {
    this.updateGSTTax(value, "Bf_b");
  };
  
  inwardITC2CGSTChange = value => {
    this.updateGSTTax(value, "Bg_c");
  };
  inwardITC2SGSTChange = value => {
    this.updateGSTTax(value, "Bg_b");
  };

  inwardIITC1CGSTChange = value => {
    this.updateGSTTax(value, "Bi_c");
  };
  inwardIITC1SGSTChange = value => {
    this.updateGSTTax(value, "Bi_b");
  };
  
  inwardIITC2CGSTChange = value => {
    this.updateGSTTax(value, "Bj_c");
  };
  inwardIITC2SGSTChange = value => {
    this.updateGSTTax(value, "Bj_b");
  };

  inwardINTCGSTChange = value => {
    this.updateGSTTax(value, "Ea_c");
  };
  inwardINTSGSTChange = value => {
    this.updateGSTTax(value, "Ea_b");
  };
  
  render() {
    console.log(this.props);
    if(this.props.gstr_three_b){
      return (
        <div>
          <Layout style={{ backgroundColor: "#FFFFFF" }}>
            <Content>
              <EnterGSTR3BComponent
                formatNumber={this.formatNumber}
                handleChange={this.handleChange}
                saveGSTR3B={this.saveGSTR3B}
                getFieldDecorator={this.props.form.getFieldDecorator}
                getFieldValue={this.props.form.getFieldValue}
                setFieldsValue={this.props.form.setFieldsValue}
                gstr_three_b={
                  this.props.gstr_three_b ? this.props.gstr_three_b : ""
                }
                isEdit={this.props.isEdit}
                onDataChange={this.onDataChange}
                queryFields={this.props.queryFields}
                onFocusEvent={this.onFocusEvent}
                onBlurEvent={this.onBlurEvent}
                outwardTaxableCGSTChange={this.outwardTaxableCGSTChange}
                outwardTaxableSGSTChange={this.outwardTaxableSGSTChange}
                inwardTaxableCGSTChange={this.inwardTaxableCGSTChange}
                inwardTaxableSGSTChange={this.inwardTaxableSGSTChange}
                inwardITCCGSTChange={this.inwardITCCGSTChange}
                inwardITCSGSTChange={this.inwardITCSGSTChange}
                inwardISDCGSTChange={this.inwardISDCGSTChange}
                inwardISDSGSTChange={this.inwardISDSGSTChange}
                inwardAOTHCGSTChange={this.inwardAOTHCGSTChange}
                inwardAOTHSGSTChange={this.inwardAOTHSGSTChange}
                inwardISDCGSTChange={this.inwardISDCGSTChange}
                inwardISDSGSTChange={this.inwardISDSGSTChange}
                inwardITC1CGSTChange={this.inwardITC1CGSTChange}
                inwardITC1SGSTChange={this.inwardITC1SGSTChange}
                inwardITC2CGSTChange={this.inwardITC2CGSTChange}
                inwardITC2SGSTChange={this.inwardITC2SGSTChange}
                inwardIITC1CGSTChange={this.inwardIITC1CGSTChange}
                inwardIITC1SGSTChange={this.inwardIITC1SGSTChange}
                inwardIITC2CGSTChange={this.inwardIITC2CGSTChange}
                inwardIITC2SGSTChange={this.inwardIITC2SGSTChange}
                inwardINTCGSTChange={this.inwardINTCGSTChange}
                inwardINTSGSTChange={this.inwardINTSGSTChange}
                inputRef={inputRef}
                insertThreePointTwoData={this.insertThreePointTwoData}
                // removeItemDetailsFromParent={this.removeItemDetailsFromParent}
              />
            </Content>
            <Sider
              style={{ backgroundColor: "##D3D3D3" }}
              theme="light"
              width="100"
            >
              <Anchor style={{ backgroundColor: "##D3D3D3" }} offsetTop={100}>
                <Link href="#link1" title="3.1" />
                <br />
                <Link href="#link2" title="3.2" />
                <br />
                <Link href="#link3" title="4" />
                <br />
                <Link href="#link4" title="5" />
                <br />
                <Link href="#link5" title="5.1" />
                <br />
                <Button icon="safety" type="success" onClick={this.saveGSTR3B}>
                  Save
                </Button>
                <br />
                <br />
                <Button
                  icon="info-circle"
                  type="danger"
                  onClick={this.resetGSTR3B}
                >
                  {" "}
                  Back
                </Button>
              </Anchor>
            </Sider>
          </Layout>
        </div>
      );
    }else {
      return (
        <div style={{ textAlign: 'center' }}>
        <Layout style={{backgroundColor:'#fff', padding: '5px'}}>
        <Spin size="large" style={{ marginTop: '160px' }} />
        </Layout>
        </div>
      )
    }
    
  }
}

const gstr3b_osup_det = {
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({ mutate, ownProps }) => {
    return {
      saveGSTR3B: values => {
        let itc_avail = [
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "IMPG",
            iamt: values.Ba_a.toString(),
            camt: values.Ba_b.toString(),
            samt: values.Ba_c.toString(),
            csamt: values.Ba_d.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "IMPS",
            iamt: values.Bb_a.toString(),
            camt: values.Bb_b.toString(),
            samt: values.Bb_c.toString(),
            csamt: values.Bb_d.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "ISRC",
            iamt: values.Bc_a.toString(),
            camt: values.Bc_b.toString(),
            samt: values.Bc_c.toString(),
            csamt: values.Bc_d.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "ISD",
            iamt: values.Bd_a.toString(),
            camt: values.Bd_b.toString(),
            samt: values.Bd_c.toString(),
            csamt: values.Bd_d.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "OTH",
            iamt: values.Be_a.toString(),
            camt: values.Be_b.toString(),
            samt: values.Be_c.toString(),
            csamt: values.Be_d.toString()
          }
        ];
        let itc_rev = [
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "RUL",
            iamt: values.Bf_a.toString(),
            camt: values.Bf_b.toString(),
            samt: values.Bf_c.toString(),
            csamt: values.Bf_d.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "OTH",
            iamt: values.Bg_a.toString(),
            camt: values.Bg_b.toString(),
            samt: values.Bg_c.toString(),
            csamt: values.Bg_d.toString()
          }
        ];
        let itc_inelg = [
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "SEC",
            iamt: values.Bi_a.toString(),
            camt: values.Bi_b.toString(),
            samt: values.Bi_c.toString(),
            csamt: values.Bi_d.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "OTH",
            iamt: values.Bj_a.toString(),
            camt: values.Bj_b.toString(),
            samt: values.Bj_c.toString(),
            csamt: values.Bj_d.toString()
          }
        ];
        let inward_sup = [
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "CEN",
            inter: values.Da_a.toString(),
            intra: values.Da_b.toString()
          },
          {
            companyId: get("companyId", ownProps),
            finyear: get("finYear", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            type: "NGST",
            inter: values.Db_a.toString(),
            intra: values.Db_b.toString()
          }
        ];
        let ThreepointTwo_details = map(item => {
          return {
            companyId: get("companyId", ownProps),
            finmonth: get("finMonth", ownProps) + '',
            finyear: get("finYear", ownProps),
            sup_made: item[0],
            pos: item[1],
            txval: item[2],
            iamt: item[3]
          };
        })(values.names);
        return mutate({
          variables: {
            osup_det: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              txval: values.Aa_a.toString(),
              iamt: values.Aa_b.toString(),
              camt: values.Aa_c.toString(),
              samt: values.Aa_d.toString(),
              csamt: values.Aa_e.toString()
            },
            osup_zero: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              txval: values.Ab_a.toString(),
              iamt: values.Ab_b.toString(),
              csamt: values.Ab_e.toString()
            },
            osup_nil_exmp: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              txval: values.Ac_a.toString()
            },
            isup_rev: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              txval: values.Ad_a.toString(),
              iamt: values.Ad_b.toString(),
              camt: values.Ad_c.toString(),
              samt: values.Ad_d.toString(),
              csamt: values.Ad_e.toString()
            },
            osup_nongst: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              txval: values.Ae_a.toString()
            },
            ThreepointTwo_details: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              items: ThreepointTwo_details
            },
            itc_avl: itc_avail,
            itc_rev: itc_rev,
            itc_inelg: itc_inelg,
            inward_sup: inward_sup,
            intr_details: {
              companyId: get("companyId", ownProps),
              finyear: get("finYear", ownProps),
              finmonth: get("finMonth", ownProps) + '',
              iamt: values.Ea_a.toString(),
              camt: values.Ea_b.toString(),
              samt: values.Ea_c.toString(),
              csamt: values.Ea_d.toString()
            }
          },
          refetchQueries: [
            {
              query: gql(osup_detQuery),
              variables: {
                companyId: get("companyId", ownProps),
                finyear: get("finYear", ownProps),
                finmonth: get("finMonth", ownProps) + ''
              }
            }
          ]
        });
      }
    };
  }
};

const osup_detQueryConfig = {
  name: "enter3bdata",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({ enter3bdata, ownProps }) => {
    return {
      enter3bdata: enter3bdata,
      gstr_three_b: get("viewer", enter3bdata),
      queryFields: get("viewer.three_point_two_query.edges", enter3bdata)
    };
  }
};

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  Form.create(),
  graphql(gql(osup_detQuery), osup_detQueryConfig),
  graphql(gql(GSTR3B_Mutation), gstr3b_osup_det)
)(EnterGSTR3B);
