import fetch from "isomorphic-fetch";
import { BASE_URL } from "../shared/constants";
import upload_progress from "../components/Common_UI";
import message from "antd/lib/message";
const XLSX = require("xlsx");
var fileSaver = require("file-saver");
var blob = require("blob-util");
const Purchase = {
  downloadError: (parsedData, tally, key) => {
    if (parsedData.filestatus === true) {
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      var wb_new = XLSX.utils.book_new();

      if (tally === "true") {
        if (
          parsedData.prb2b !== undefined &&
          parsedData.prb2b !== null &&
          parsedData.prb2b.length !== 0
        ) {
          wb_new.SheetNames.push("b2b");
        }
        if (
          parsedData.prb2bur !== undefined &&
          parsedData.prb2bur !== null &&
          parsedData.prb2bur.length !== 0
        ) {
          wb_new.SheetNames.push("b2bur");
        }
        if (
          parsedData.primps !== undefined &&
          parsedData.primps !== null &&
          parsedData.primps.length !== 0
        ) {
          wb_new.SheetNames.push("imps");
        }
        if (
          parsedData.primpg !== undefined &&
          parsedData.primpg !== null &&
          parsedData.primpg.length !== 0
        ) {
          wb_new.SheetNames.push("impg");
        }
        if (
          parsedData.prcdnr !== undefined &&
          parsedData.prcdnr !== null &&
          parsedData.prcdnr.length !== 0
        ) {
          wb_new.SheetNames.push("cdnr");
        }
        if (
          parsedData.prcdnur !== undefined &&
          parsedData.prcdnur !== null &&
          parsedData.prcdnur.length !== 0
        ) {
          wb_new.SheetNames.push("cdnur");
        }
        if (
          parsedData.prat !== undefined &&
          parsedData.prat !== null &&
          parsedData.prat.length !== 0
        ) {
          wb_new.SheetNames.push("at");
        }
        if (
          parsedData.pratadj !== undefined &&
          parsedData.pratadj !== null &&
          parsedData.pratadj.length !== 0
        ) {
          wb_new.SheetNames.push("atadj");
        }
        if (
          parsedData.prexemp !== undefined &&
          parsedData.prexemp !== null &&
          parsedData.prexemp.length !== 0
        ) {
          wb_new.SheetNames.push("exemp");
        }
        if (
          parsedData.pritc !== undefined &&
          parsedData.pritc !== null &&
          parsedData.pritc.length !== 0
        ) {
          wb_new.SheetNames.push("itcr");
        }
        if (
          parsedData.prhsn !== undefined &&
          parsedData.prhsn !== null &&
          parsedData.prhsn.length !== 0
        ) {
          wb_new.SheetNames.push("hsnsum");
        }
        // if ((parsedData.primpg !== undefined) && (parsedData.primpg !== null) && (parsedData.primpg.length !== 0)) {
        // 	wb_new.SheetNames.push("isd");
        // }

        //format B2B data
        var b2bDataList = [];
        var b2bData = {};
        if (parsedData.prb2b !== undefined && parsedData.prb2b !== null) {
          for (var i = 0; i < parsedData.prb2b.length; i++) {
            if (parsedData.prb2b[i].Status === "") {
              break;
            }
            b2bData = {
              // 'Supplier Name': parsedData.prb2b[i]["Supplier Name"],
              "GSTIN of Supplier": parsedData.prb2b[i]["GSTIN of Supplier"],
              "Invoice Number": parsedData.prb2b[i]["Invoice Number"],
              "Invoice date": parsedData.prb2b[i]["Invoice date"],
              "Invoice Value": parsedData.prb2b[i]["Invoice Value"],
              "Place Of Supply": parsedData.prb2b[i]["Place Of Supply"],
              "Reverse Charge": parsedData.prb2b[i]["Reverse Charge"],
              "Invoice Type":
                parsedData.prb2b[i]["Invoice Type"] === "R"
                  ? "Regular"
                  : parsedData.prb2b[i]["Invoice Type"],
              Rate: parsedData.prb2b[i]["Rate"],
              "Taxable Value": parsedData.prb2b[i]["Taxable Value"],
              "Integrated Tax Paid": parsedData.prb2b[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prb2b[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prb2b[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prb2b[i]["Cess Paid"],
              "Eligibility For ITC": parsedData.prb2b[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prb2b[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prb2b[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prb2b[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prb2b[i]["Availed ITC Cess"],
              Status: parsedData.prb2b[i]["Status"],
            };
            b2bDataList.push(b2bData);
          }
        }
        //format B2B data end

        //format B2BUR data
        var b2burDataList = [];
        var b2burData = {};
        if (parsedData.prb2bur !== undefined && parsedData.prb2bur !== null) {
          for (var i = 0; i < parsedData.prb2bur.length; i++) {
            if (parsedData.prb2bur[i].Status === "") {
              break;
            }
            b2burData = {
              "Supplier Name": parsedData.prb2bur[i]["Supplier Name"],
              "Invoice Number": parsedData.prb2bur[i]["Invoice Number"],
              "Invoice date": parsedData.prb2bur[i]["Invoice date"],
              "Invoice Value": parsedData.prb2bur[i]["Invoice Value"],
              "Place Of Supply": parsedData.prb2bur[i]["Place Of Supply"],
              "Supply Type": parsedData.prb2bur[i]["Supply Type"],
              Rate: parsedData.prb2bur[i]["Rate"],
              "Taxable Value": parsedData.prb2bur[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.prb2bur[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prb2bur[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prb2bur[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prb2bur[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.prb2bur[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prb2bur[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prb2bur[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prb2bur[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prb2bur[i]["Availed ITC Cess"],
              Status: parsedData.prb2bur[i]["Status"],
            };
            b2burDataList.push(b2burData);
          }
        }
        //format B2BUR data end

        //format CDNR data
        var cdnrDataList = [];
        var cdnrData = {};
        if (parsedData.prcdnr !== undefined && parsedData.prcdnr !== null) {
          for (var i = 0; i < parsedData.prcdnr.length; i++) {
            if (parsedData.prcdnr[i].Status === "") {
              break;
            }
            cdnrData = {
              // 'Supplier Name': parsedData.prcdnr[i]["Supplier Name"],
              "GSTIN of Supplier": parsedData.prcdnr[i]["GSTIN of Supplier"],
              "Note/Refund Voucher Number":
                parsedData.prcdnr[i]["Note/Refund Voucher Number"],
              "Note/Refund Voucher date":
                parsedData.prcdnr[i]["Note/Refund Voucher date"],
              "Invoice/Advance Payment Voucher Number":
                parsedData.prcdnr[i]["Invoice/Advance Payment Voucher Number"],
              "Invoice/Advance Payment Voucher date":
                parsedData.prcdnr[i]["Invoice/Advance Payment Voucher date"],
              "Pre GST": parsedData.prcdnr[i]["Pre GST"],
              "Document Type": parsedData.prcdnr[i]["Document Type"],
              "Reason For Issuing document":
                parsedData.prcdnr[i]["Reason For Issuing document"],
              "Supply Type": parsedData.prcdnr[i]["Supplier Type"],
              "Note/Refund Voucher Value":
                parsedData.prcdnr[i]["Note/Refund Voucher Value"],
              // 'Place Of Supply': parsedData.prcdnr[i]["Place Of Supply"],
              Rate: parsedData.prcdnr[i]["Rate"],
              "Taxable Value": parsedData.prcdnr[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.prcdnr[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prcdnr[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prcdnr[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prcdnr[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.prcdnr[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prcdnr[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prcdnr[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prcdnr[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prcdnr[i]["Availed ITC Cess"],
              Status: parsedData.prcdnr[i]["Status"],
            };
            cdnrDataList.push(cdnrData);
          }
        }
        //format CDNR data end

        //format CDNUR data
        var cdnurDataList = [];
        var cdnurData = {};
        if (parsedData.prcdnur !== undefined && parsedData.prcdnur !== null) {
          for (var i = 0; i < parsedData.prcdnur.length; i++) {
            if (parsedData.prcdnur[i].Status === "") {
              break;
            }
            cdnurData = {
              "Note/Voucher Number":
                parsedData.prcdnur[i]["Note/Refund Voucher Number"],
              "Note/Voucher date":
                parsedData.prcdnur[i]["Note/Refund Voucher date"],
              "Invoice/Advance Payment Voucher number":
                parsedData.prcdnur[i]["Invoice/Advance Payment Voucher number"],
              "Invoice/Advance Payment Voucher date":
                parsedData.prcdnur[i]["Invoice/Advance Payment Voucher date"],
              "Pre GST": parsedData.prcdnur[i]["Pre GST"],
              "Document Type": parsedData.prcdnur[i]["Document Type"],
              "Reason For Issuing document":
                parsedData.prcdnur[i]["Reason For Issuing document"],
              "Supply Type": parsedData.prcdnur[i]["Supply Type"],
              "Note/Voucher Value":
                parsedData.prcdnur[i]["Note/Refund Voucher Value"],
              // 'Place Of Supply': parsedData.prcdnur[i]["Place Of Supply"],
              Rate: parsedData.prcdnur[i]["Rate"],
              "Taxable Value": parsedData.prcdnur[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.prcdnur[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prcdnur[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prcdnur[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prcdnur[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.prcdnur[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prcdnur[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prcdnur[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prcdnur[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prcdnur[i]["Availed ITC Cess"],
              Status: parsedData.prcdnur[i]["Status"],
            };
            cdnurDataList.push(cdnurData);
          }
        }
        //format CDNUR data end

        //format EXEMP data
        var exempDataList = [];
        var exempData = {};
        if (parsedData.prexemp !== undefined && parsedData.prexemp !== null) {
          for (var i = 0; i < parsedData.prexemp.length; i++) {
            if (parsedData.prexemp[i].Status === "") {
              break;
            }
            exempData = {
              Description: parsedData.prexemp[i]["Description"],
              "Composition taxable person":
                parsedData.prexemp[i]["Composition taxable person"],
              "Nil Rated Supplies": parsedData.prexemp[i]["Nil Rated Supplies"],
              "Exempted (other than nil rated/non GST supply )":
                parsedData.prexemp[i][
                  "Exempted (other than nil rated/non GST supply )"
                ],
              "Non-GST supplies": parsedData.prexemp[i]["Non-GST supplies"],
              Status: parsedData.prexemp[i]["Status"],
            };
            exempDataList.push(exempData);
          }
        }
        //format EXEMP data end

        //format AT data
        var atDataList = [];
        var atData = {};
        if (parsedData.prat !== undefined && parsedData.prat !== null) {
          for (var i = 0; i < parsedData.prat.length; i++) {
            if (parsedData.prat[i].Status === "") {
              break;
            }
            atData = {
              "Place Of Supply": parsedData.prat[i]["Place Of Supply"],
              Rate: parsedData.prat[i]["Rate"],
              "Gross Advance Paid": parsedData.prat[i]["Gross Advance Paid"],
              // 'Integrated Tax': parsedData.prat[i]["Integrated Tax (₹)*"],
              // 'Central Tax': parsedData.prat[i]["Central Tax (₹)*"],
              // 'State/UT Tax': parsedData.prat[i]["State/UT Tax (₹)*"],
              "Cess Amount": parsedData.prat[i]["Cess Amount"],
              Status: parsedData.prat[i]["Status"],
            };
            atDataList.push(atData);
          }
        }
        //format AT data end

        //format ATADJ data
        var atadjDataList = [];
        var atadjData = {};
        if (parsedData.pratadj !== undefined && parsedData.pratadj !== null) {
          for (var i = 0; i < parsedData.pratadj.length; i++) {
            if (parsedData.pratadj[i].Status === "") {
              break;
            }
            atadjData = {
              "Place Of Supply": parsedData.pratadj[i]["Place Of Supply"],
              Rate: parsedData.pratadj[i]["Rate"],
              "Gross Advance Paid to be Adjusted":
                parsedData.pratadj[i]["Gross Advance Paid to be Adjusted"],
              // 'Integrated Tax': parsedData.pratadj[i]["Integrated Tax (₹)*"],
              // 'Central Tax': parsedData.pratadj[i]["Central Tax (₹)*"],
              // 'State/UT Tax': parsedData.pratadj[i]["State/UT Tax (₹)*"],
              "Cess Adjusted": parsedData.pratadj[i]["Cess Adjusted"],
              Status: parsedData.pratadj[i]["Status"],
            };
            atadjDataList.push(atadjData);
          }
        }
        //format ATADJ data end

        //format ITC data
        var itcDataList = [];
        var itcData = {};
        if (parsedData.pritc !== undefined && parsedData.pritc !== null) {
          for (var i = 0; i < parsedData.pritc.length; i++) {
            if (parsedData.pritc[i].Status === "") {
              break;
            }
            itcData = {
              "Description for reversal of ITC":
                parsedData.pritc[i]["Description for reversal of ITC"],
              "To be added or reduced from output liability":
                parsedData.pritc[i][
                  "To be added or reduced from output liability"
                ],
              "ITC Integrated Tax Amount":
                parsedData.pritc[i]["ITC Integrated Tax Amount"],
              "ITC Central Tax Amount":
                parsedData.pritc[i]["ITC Central Tax Amount"],
              "ITC State/UT Tax Amount":
                parsedData.pritc[i]["ITC State/UT Tax Amount"],
              "ITC Cess Amount": parsedData.pritc[i]["ITC Cess Amount"],
              Status: parsedData.pritc[i]["Status"],
            };
            itcDataList.push(itcData);
          }
        }
        //format ITC data end

        //format ISD data
        var isdDataList = [];
        var isdData = {};
        if (parsedData.prisd !== undefined && parsedData.prisd !== null) {
          for (var i = 0; i < parsedData.prisd.length; i++) {
            if (parsedData.prisd[i].Status === "") {
              break;
            }
            isdData = {
              "GSTIN of Supplier": parsedData.prisd[i]["GSTIN of Supplier"],
              "Invoice Number": parsedData.prisd[i]["Invoice Number"],
              "Invoice date": parsedData.prisd[i]["Invoice date"],
              "HSN / SAC Code": parsedData.prisd[i]["HSN / SAC Code"],
              "HSN / SAC Description":
                parsedData.prisd[i]["HSN / SAC Description"],
              "Integrated Tax Paid": parsedData.prisd[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prisd[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prisd[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prisd[i]["Cess Paid"],
              "Eligibility For ITC": parsedData.prisd[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prisd[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prisd[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prisd[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prisd[i]["Availed ITC Cess"],
              Status: parsedData.prisd[i]["Status"],
            };
            isdDataList.push(isdData);
          }
        }
        //format ISD data end

        //format IMPG data
        var impgDataList = [];
        var impgData = {};
        if (parsedData.primpg !== undefined && parsedData.primpg !== null) {
          for (var i = 0; i < parsedData.primpg.length; i++) {
            if (parsedData.primpg[i].Status === "") {
              break;
            }
            impgData = {
              "Port Code": parsedData.primpg[i]["Port Code"],
              "Bill Of Entry Number":
                parsedData.primpg[i]["Bill Of Entry Number"],
              "Bill Of Entry Date": parsedData.primpg[i]["Bill Of Entry Date"],
              "Bill Of Entry Value":
                parsedData.primpg[i]["Bill Of Entry Value"],
              "Document Type": parsedData.primpg[i]["Import Type"],
              "GSTIN Of SEZ Supplier":
                parsedData.primpg[i]["GSTIN Of SEZ Supplier"],
              Rate: parsedData.primpg[i]["Rate"],
              "Taxable Value": parsedData.primpg[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.primpg[i]["Integrated Tax Paid"],
              "Cess Paid": parsedData.primpg[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.primpg[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.primpg[i]["Availed ITC Integrated Tax"],
              "Availed ITC Cess": parsedData.primpg[i]["Availed ITC Cess"],
              Status: parsedData.primpg[i]["Status"],
            };
            impgDataList.push(impgData);
          }
        }
        //format IMPG data end

        //format IMPS data
        var impsDataList = [];
        var impsData = {};
        if (parsedData.primps !== undefined && parsedData.primps !== null) {
          for (var i = 0; i < parsedData.primps.length; i++) {
            if (parsedData.primps[i].Status === "") {
              break;
            }
            impsData = {
              "Invoice Number of Reg Recipient":
                parsedData.primps[i]["Invoice Number of Reg Recipient"],
              "Invoice Date": parsedData.primps[i]["Invoice Date"],
              "Invoice Value": parsedData.primps[i]["Invoice Value"],
              "Place Of Supply": parsedData.primps[i]["Place Of Supply"],
              Rate: parsedData.primps[i]["Rate"],
              "Taxable Value": parsedData.primps[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.primps[i]["Integrated Tax Paid"],
              "Cess Paid": parsedData.primps[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.primps[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.primps[i]["Availed ITC Integrated Tax"],
              "Availed ITC Cess": parsedData.primps[i]["Availed ITC Cess"],
              Status: parsedData.primps[i]["Status"],
            };
            impsDataList.push(impsData);
          }
        }
        //format IMPS data end

        //format HSN data
        var hsnDataList = [];
        var hsnData = {};
        if (parsedData.prhsn !== undefined && parsedData.prhsn !== null) {
          for (var i = 0; i < parsedData.prhsn.length; i++) {
            if (parsedData.prhsn[i].Status === "") {
              break;
            }
            hsnData = {
              HSN: parsedData.prhsn[i]["HSN"],
              Description: parsedData.prhsn[i]["Description"],
              UQC: parsedData.prhsn[i]["UQC"],
              "Total Quantity": parsedData.prhsn[i]["Total Quantity"],
              "Total Value": parsedData.prhsn[i]["Total Value"],
              "Taxable Value": parsedData.prhsn[i]["Taxable Value"],
              "Integrated Tax Amount":
                parsedData.prhsn[i]["Integrated Tax Amount"],
              "Central Tax Amount": parsedData.prhsn[i]["Central Tax Amount"],
              "State/UT Tax Amount": parsedData.prhsn[i]["State/UT Tax Amount"],
              "Cess Amount": parsedData.prhsn[i]["Cess Amount"],
              Status: parsedData.prhsn[i]["Status"],
            };
            hsnDataList.push(hsnData);
          }
        }
        //format HSN data end
        if (
          parsedData.prb2b !== undefined &&
          parsedData.prb2b !== null &&
          parsedData.prb2b.length !== 0
        ) {
          wb_new.Sheets["b2b"] = XLSX.utils.json_to_sheet(b2bDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.prb2bur !== undefined &&
          parsedData.prb2bur !== null &&
          parsedData.prb2bur.length !== 0
        ) {
          wb_new.Sheets["b2bur"] = XLSX.utils.json_to_sheet(b2burDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.primps !== undefined &&
          parsedData.primps !== null &&
          parsedData.primps.length !== 0
        ) {
          wb_new.Sheets["imps"] = XLSX.utils.json_to_sheet(impsDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.primpg !== undefined &&
          parsedData.primpg !== null &&
          parsedData.primpg.length !== 0
        ) {
          wb_new.Sheets["impg"] = XLSX.utils.json_to_sheet(impgDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.prcdnr !== undefined &&
          parsedData.prcdnr !== null &&
          parsedData.prcdnr.length !== 0
        ) {
          wb_new.Sheets["cdnr"] = XLSX.utils.json_to_sheet(cdnrDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.prcdnur !== undefined &&
          parsedData.prcdnur !== null &&
          parsedData.prcdnur.length !== 0
        ) {
          wb_new.Sheets["cdnur"] = XLSX.utils.json_to_sheet(cdnurDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.prat !== undefined &&
          parsedData.prat !== null &&
          parsedData.prat.length !== 0
        ) {
          wb_new.Sheets["at"] = XLSX.utils.json_to_sheet(atDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.pratadj !== undefined &&
          parsedData.pratadj !== null &&
          parsedData.pratadj.length !== 0
        ) {
          wb_new.Sheets["atadj"] = XLSX.utils.json_to_sheet(atadjDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.prexemp !== undefined &&
          parsedData.prexemp !== null &&
          parsedData.prexemp.length !== 0
        ) {
          wb_new.Sheets["exemp"] = XLSX.utils.json_to_sheet(exempDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.pritc !== undefined &&
          parsedData.pritc !== null &&
          parsedData.pritc.length !== 0
        ) {
          wb_new.Sheets["itcr"] = XLSX.utils.json_to_sheet(itcDataList, {
            origin: "A4",
          });
        }
        if (
          parsedData.prhsn !== undefined &&
          parsedData.prhsn !== null &&
          parsedData.prhsn.length !== 0
        ) {
          wb_new.Sheets["hsnsum"] = XLSX.utils.json_to_sheet(hsnDataList, {
            origin: "A4",
          });
        }
        // if ((parsedData.prisd !== undefined) && (parsedData.prisd !== null) && (parsedData.prisd.length !== 0)) {
        // 	wb_new.Sheets["isd"] = XLSX.utils.json_to_sheet(isdDataList);
        // }
      } else {
        if (
          parsedData.prb2b !== undefined &&
          parsedData.prb2b !== null &&
          parsedData.prb2b.length !== 0
        ) {
          wb_new.SheetNames.push("B2B");
        }
        if (
          parsedData.prb2bur !== undefined &&
          parsedData.prb2bur !== null &&
          parsedData.prb2bur.length !== 0
        ) {
          wb_new.SheetNames.push("B2BUR");
        }
        if (
          parsedData.prcdnr !== undefined &&
          parsedData.prcdnr !== null &&
          parsedData.prcdnr.length !== 0
        ) {
          wb_new.SheetNames.push("CDNR");
        }
        if (
          parsedData.prcdnur !== undefined &&
          parsedData.prcdnur !== null &&
          parsedData.prcdnur.length !== 0
        ) {
          wb_new.SheetNames.push("CDNUR");
        }
        if (
          parsedData.prexemp !== undefined &&
          parsedData.prexemp !== null &&
          parsedData.prexemp.length !== 0
        ) {
          wb_new.SheetNames.push("EXEMP");
        }
        if (
          parsedData.prat !== undefined &&
          parsedData.prat !== null &&
          parsedData.prat.length !== 0
        ) {
          wb_new.SheetNames.push("AT");
        }
        if (
          parsedData.pratadj !== undefined &&
          parsedData.pratadj !== null &&
          parsedData.pratadj.length !== 0
        ) {
          wb_new.SheetNames.push("ATADJ");
        }
        if (
          parsedData.pritc !== undefined &&
          parsedData.pritc !== null &&
          parsedData.pritc.length !== 0
        ) {
          wb_new.SheetNames.push("ITC");
        }
        if (
          parsedData.primpg !== undefined &&
          parsedData.primpg !== null &&
          parsedData.primpg.length !== 0
        ) {
          wb_new.SheetNames.push("ISD");
        }
        if (
          parsedData.primpg !== undefined &&
          parsedData.primpg !== null &&
          parsedData.primpg.length !== 0
        ) {
          wb_new.SheetNames.push("IMPG");
        }
        if (
          parsedData.primps !== undefined &&
          parsedData.primps !== null &&
          parsedData.primps.length !== 0
        ) {
          wb_new.SheetNames.push("IMPS");
        }
        if (
          parsedData.prhsn !== undefined &&
          parsedData.prhsn !== null &&
          parsedData.prhsn.length !== 0
        ) {
          wb_new.SheetNames.push("HSN");
        }

        //format B2B data
        var b2bDataList = [];
        var b2bData = {};
        if (parsedData.prb2b !== undefined && parsedData.prb2b !== null) {
          for (var i = 0; i < parsedData.prb2b.length; i++) {
            if (parsedData.prb2b[i].Status === "") {
              break;
            }
            b2bData = {
              "Supplier Name": parsedData.prb2b[i]["Supplier Name"],
              "GSTIN of Supplier": parsedData.prb2b[i]["GSTIN of Supplier"],
              "Invoice Number": parsedData.prb2b[i]["Invoice Number"],
              "Invoice date": parsedData.prb2b[i]["Invoice date"],
              "Invoice Value": parsedData.prb2b[i]["Invoice Value"],
              "Place Of Supply": parsedData.prb2b[i]["Place Of Supply"],
              "Reverse Charge": parsedData.prb2b[i]["Reverse Charge"],
              "Invoice Type":
                parsedData.prb2b[i]["Invoice Type"] === "R"
                  ? "Regular"
                  : parsedData.prb2b[i]["Invoice Type"],
              Rate: parsedData.prb2b[i]["Rate"],
              "Taxable Value": parsedData.prb2b[i]["Taxable Value"],
              "Integrated Tax Paid": parsedData.prb2b[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prb2b[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prb2b[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prb2b[i]["Cess Paid"],
              "Eligibility For ITC": parsedData.prb2b[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prb2b[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prb2b[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prb2b[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prb2b[i]["Availed ITC Cess"],
              Status: parsedData.prb2b[i]["Status"],
            };
            b2bDataList.push(b2bData);
          }
        }
        //format B2B data end

        //format B2BUR data
        var b2burDataList = [];
        var b2burData = {};
        if (parsedData.prb2bur !== undefined && parsedData.prb2bur !== null) {
          for (var i = 0; i < parsedData.prb2bur.length; i++) {
            if (parsedData.prb2bur[i].Status === "") {
              break;
            }
            b2burData = {
              "Supplier Name": parsedData.prb2bur[i]["Supplier Name"],
              "Invoice Number": parsedData.prb2bur[i]["Invoice Number"],
              "Invoice date": parsedData.prb2bur[i]["Invoice date"],
              "Invoice Value": parsedData.prb2bur[i]["Invoice Value"],
              "Place Of Supply": parsedData.prb2bur[i]["Place Of Supply"],
              "Supply Type*": parsedData.prb2bur[i]["Supply Type*"],
              Rate: parsedData.prb2bur[i]["Rate"],
              "Taxable Value": parsedData.prb2bur[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.prb2bur[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prb2bur[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prb2bur[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prb2bur[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.prb2bur[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prb2bur[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prb2bur[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prb2bur[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prb2bur[i]["Availed ITC Cess"],
              Status: parsedData.prb2bur[i]["Status"],
            };
            b2burDataList.push(b2burData);
          }
        }
        //format B2BUR data end

        //format CDNR data
        var cdnrDataList = [];
        var cdnrData = {};
        if (parsedData.prcdnr !== undefined && parsedData.prcdnr !== null) {
          for (var i = 0; i < parsedData.prcdnr.length; i++) {
            if (parsedData.prcdnr[i].Status === "") {
              break;
            }
            cdnrData = {
              "Supplier Name": parsedData.prcdnr[i]["Supplier Name"],
              "GSTIN of Supplier": parsedData.prcdnr[i]["GSTIN of Supplier"],
              "Note/Refund Voucher Number":
                parsedData.prcdnr[i]["Note/Refund Voucher Number"],
              "Note/Refund Voucher date":
                parsedData.prcdnr[i]["Note/Refund Voucher date"],
              "Invoice/Advance Payment Voucher Number":
                parsedData.prcdnr[i]["Invoice/Advance Payment Voucher Number"],
              "Invoice/Advance Payment Voucher date":
                parsedData.prcdnr[i]["Invoice/Advance Payment Voucher date"],
              "Pre GST": parsedData.prcdnr[i]["Pre GST"],
              "Document Type": parsedData.prcdnr[i]["Document Type"],
              "Place Of Supply": parsedData.prcdnr[i]["Place Of Supply"],
              "Note/Refund Voucher Value":
                parsedData.prcdnr[i]["Note/Refund Voucher Value"],
              // 'Reason For Issuing document': parsedData.prcdnr[i]["Reason For Issuing document"],
              Rate: parsedData.prcdnr[i]["Rate"],
              "Taxable Value": parsedData.prcdnr[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.prcdnr[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prcdnr[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prcdnr[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prcdnr[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.prcdnr[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prcdnr[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prcdnr[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prcdnr[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prcdnr[i]["Availed ITC Cess"],
              Status: parsedData.prcdnr[i]["Status"],
            };
            cdnrDataList.push(cdnrData);
          }
        }
        //format CDNR data end

        //format CDNUR data
        var cdnurDataList = [];
        var cdnurData = {};
        if (parsedData.prcdnur !== undefined && parsedData.prcdnur !== null) {
          for (var i = 0; i < parsedData.prcdnur.length; i++) {
            if (parsedData.prcdnur[i].Status === "") {
              break;
            }
            cdnurData = {
              "Supplier Name": parsedData.prcdnur[i]["Supplier Name"],
              "Note/Refund Voucher Number":
                parsedData.prcdnur[i]["Note/Refund Voucher Number"],
              "Note/Refund Voucher date":
                parsedData.prcdnur[i]["Note/Refund Voucher date"],
              "Invoice/Advance Payment Voucher number":
                parsedData.prcdnur[i]["Invoice/Advance Payment Voucher number"],
              "Invoice/Advance Payment Voucher date":
                parsedData.prcdnur[i]["Invoice/Advance Payment Voucher date"],
              "Pre GST": parsedData.prcdnur[i]["Pre GST"],
              "Document Type": parsedData.prcdnur[i]["Document Type"],
              "Place Of Supply": parsedData.prcdnur[i]["Place Of Supply"],
              "Supply Type*": parsedData.prcdnur[i]["Supply Type*"],
              "Invoice Type":
                parsedData.prcdnur[i]["Invoice Type"] === "R"
                  ? "Regular"
                  : parsedData.prcdnur[i]["Invoice Type"],
              "Note/Refund Voucher Value":
                parsedData.prcdnur[i]["Note/Refund Voucher Value"],
              // 'Reason For Issuing document': parsedData.prcdnur[i]["Reason For Issuing document"],
              Rate: parsedData.prcdnur[i]["Rate"],
              "Taxable Value": parsedData.prcdnur[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.prcdnur[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prcdnur[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prcdnur[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prcdnur[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.prcdnur[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prcdnur[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prcdnur[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prcdnur[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prcdnur[i]["Availed ITC Cess"],
              Status: parsedData.prcdnur[i]["Status"],
            };
            cdnurDataList.push(cdnurData);
          }
        }
        //format CDNUR data end

        //format EXEMP data
        var exempDataList = [];
        var exempData = {};
        if (parsedData.prexemp !== undefined && parsedData.prexemp !== null) {
          for (var i = 0; i < parsedData.prexemp.length; i++) {
            if (parsedData.prexemp[i].Status === "") {
              break;
            }
            exempData = {
              Description: parsedData.prexemp[i]["Description"],
              "Composition taxable person":
                parsedData.prexemp[i]["Composition taxable person"],
              "Nil Rated Supplies": parsedData.prexemp[i]["Nil Rated Supplies"],
              "Exempted (other than nil rated/non GST supply )":
                parsedData.prexemp[i][
                  "Exempted (other than nil rated/non GST supply )"
                ],
              "Non-GST supplies": parsedData.prexemp[i]["Non-GST supplies"],
              Status: parsedData.prexemp[i]["Status"],
            };
            exempDataList.push(exempData);
          }
        }
        //format EXEMP data end

        //format AT data
        var atDataList = [];
        var atData = {};
        if (parsedData.prat !== undefined && parsedData.prat !== null) {
          for (var i = 0; i < parsedData.prat.length; i++) {
            if (parsedData.prat[i].Status === "") {
              break;
            }
            atData = {
              "Place Of Supply": parsedData.prat[i]["Place Of Supply"],
              "Supply Type*": parsedData.prat[i]["Supply Type*"],
              Rate: parsedData.prat[i]["Rate"],
              "Gross Advance Received (₹)*":
                parsedData.prat[i]["Gross Advance Paid to be Adjusted (₹)*"],
              "Integrated Tax (₹)*": parsedData.prat[i]["Integrated Tax (₹)*"],
              "Central Tax (₹)*": parsedData.prat[i]["Central Tax (₹)*"],
              "State/UT Tax (₹)*": parsedData.prat[i]["State/UT Tax (₹)*"],
              "Cess (₹)": parsedData.prat[i]["Cess (₹)"],
              Status: parsedData.prat[i]["Status"],
            };
            atDataList.push(atData);
          }
        }
        //format AT data end

        //format ATADJ data
        var atadjDataList = [];
        var atadjData = {};
        if (parsedData.pratadj !== undefined && parsedData.pratadj !== null) {
          for (var i = 0; i < parsedData.pratadj.length; i++) {
            if (parsedData.pratadj[i].Status === "") {
              break;
            }
            atadjData = {
              "Place Of Supply": parsedData.pratadj[i]["Place Of Supply"],
              "Supply Type*": parsedData.pratadj[i]["Supply Type*"],
              Rate: parsedData.pratadj[i]["Rate"],
              "Gross Advance Paid to be Adjusted (₹)*":
                parsedData.pratadj[i][
                  "Gross Advance Paid to be Adjusted (₹)*"
                ] === "NaN"
                  ? ""
                  : parsedData.pratadj[i][
                      "Gross Advance Paid to be Adjusted (₹)*"
                    ],
              "Integrated Tax (₹)*":
                parsedData.pratadj[i]["Integrated Tax (₹)*"],
              "Central Tax (₹)*": parsedData.pratadj[i]["Central Tax (₹)*"],
              "State/UT Tax (₹)*": parsedData.pratadj[i]["State/UT Tax (₹)*"],
              "Cess (₹)": parsedData.pratadj[i]["Cess (₹)"],
              Status: parsedData.pratadj[i]["Status"],
            };
            atadjDataList.push(atadjData);
          }
        }
        //format ATADJ data end

        //format ITC data
        var itcDataList = [];
        var itcData = {};
        if (parsedData.pritc !== undefined && parsedData.pritc !== null) {
          for (var i = 0; i < parsedData.pritc.length; i++) {
            if (parsedData.pritc[i].Status === "") {
              break;
            }
            itcData = {
              "Description for reversal of ITC":
                parsedData.pritc[i]["Description for reversal of ITC"],
              "To be added or reduced from output liability":
                parsedData.pritc[i][
                  "To be added or reduced from output liability"
                ],
              "ITC Integrated Tax Amount":
                parsedData.pritc[i]["ITC Integrated Tax Amount"],
              "ITC Central Tax Amount":
                parsedData.pritc[i]["ITC Central Tax Amount"],
              "ITC State/UT Tax Amount":
                parsedData.pritc[i]["ITC State/UT Tax Amount"],
              "ITC Cess Amount": parsedData.pritc[i]["ITC Cess Amount"],
              Status: parsedData.pritc[i]["Status"],
            };
            itcDataList.push(itcData);
          }
        }
        //format ITC data end

        //format ISD data
        var isdDataList = [];
        var isdData = {};
        if (parsedData.prisd !== undefined && parsedData.prisd !== null) {
          for (var i = 0; i < parsedData.prisd.length; i++) {
            if (parsedData.prisd[i].Status === "") {
              break;
            }
            isdData = {
              "Supplier Name": parsedData.prisd[i]["Supplier Name"],
              "GSTIN of Supplier": parsedData.prisd[i]["GSTIN of Supplier"],
              "Invoice Number": parsedData.prisd[i]["Invoice Number"],
              "Invoice date": parsedData.prisd[i]["Invoice date"],
              "HSN / SAC Code": parsedData.prisd[i]["HSN / SAC Code"],
              "HSN / SAC Description":
                parsedData.prisd[i]["HSN / SAC Description"],
              "Integrated Tax Paid": parsedData.prisd[i]["Integrated Tax Paid"],
              "Central Tax Paid": parsedData.prisd[i]["Central Tax Paid"],
              "State/UT Tax Paid": parsedData.prisd[i]["State/UT Tax Paid"],
              "Cess Paid": parsedData.prisd[i]["Cess Paid"],
              "Eligibility For ITC": parsedData.prisd[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.prisd[i]["Availed ITC Integrated Tax"],
              "Availed ITC Central Tax":
                parsedData.prisd[i]["Availed ITC Central Tax"],
              "Availed ITC State/UT Tax":
                parsedData.prisd[i]["Availed ITC State/UT Tax"],
              "Availed ITC Cess": parsedData.prisd[i]["Availed ITC Cess"],
              Status: parsedData.prisd[i]["Status"],
            };
            isdDataList.push(isdData);
          }
        }
        //format ISD data end

        //format IMPG data
        var impgDataList = [];
        var impgData = {};
        if (parsedData.primpg !== undefined && parsedData.primpg !== null) {
          for (var i = 0; i < parsedData.primpg.length; i++) {
            if (parsedData.primpg[i].Status === "") {
              break;
            }
            impgData = {
              "Port Code": parsedData.primpg[i]["Port Code"],
              "Bill Of Entry Number":
                parsedData.primpg[i]["Bill Of Entry Number"],
              "Bill Of Entry Date": parsedData.primpg[i]["Bill Of Entry Date"],
              "Bill Of Entry Value":
                parsedData.primpg[i]["Bill Of Entry Value"],
              "Import Type": parsedData.primpg[i]["Import Type"],
              "GSTIN Of SEZ Supplier":
                parsedData.primpg[i]["GSTIN Of SEZ Supplier"],
              Rate: parsedData.primpg[i]["Rate"],
              "Taxable Value": parsedData.primpg[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.primpg[i]["Integrated Tax Paid"],
              "Cess Paid": parsedData.primpg[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.primpg[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.primpg[i]["Availed ITC Integrated Tax"],
              "Availed ITC Cess": parsedData.primpg[i]["Availed ITC Cess"],
              Status: parsedData.primpg[i]["Status"],
            };
            impgDataList.push(impgData);
          }
        }
        //format IMPG data end

        //format IMPS data
        var impsDataList = [];
        var impsData = {};
        if (parsedData.primps !== undefined && parsedData.primps !== null) {
          for (var i = 0; i < parsedData.primps.length; i++) {
            if (parsedData.primps[i].Status === "") {
              break;
            }
            impsData = {
              "Invoice Number of Reg Recipient":
                parsedData.primps[i]["Invoice Number of Reg Recipient"],
              "Invoice Date": parsedData.primps[i]["Invoice Date"],
              "Invoice Value": parsedData.primps[i]["Invoice Value"],
              "Place Of Supply": parsedData.primps[i]["Place Of Supply"],
              Rate: parsedData.primps[i]["Rate"],
              "Taxable Value": parsedData.primps[i]["Taxable Value"],
              "Integrated Tax Paid":
                parsedData.primps[i]["Integrated Tax Paid"],
              "Cess Paid": parsedData.primps[i]["Cess Paid"],
              "Eligibility For ITC":
                parsedData.primps[i]["Eligibility For ITC"],
              "Availed ITC Integrated Tax":
                parsedData.primps[i]["Availed ITC Integrated Tax"],
              "Availed ITC Cess": parsedData.primps[i]["Availed ITC Cess"],
              Status: parsedData.primps[i]["Status"],
            };
            impsDataList.push(impsData);
          }
        }
        //format IMPS data end

        //format HSN data
        var hsnDataList = [];
        var hsnData = {};
        if (parsedData.prhsn !== undefined && parsedData.prhsn !== null) {
          for (var i = 0; i < parsedData.prhsn.length; i++) {
            if (parsedData.prhsn[i].Status === "") {
              break;
            }
            hsnData = {
              HSN: parsedData.prhsn[i]["HSN"],
              Description: parsedData.prhsn[i]["Description"],
              UQC: parsedData.prhsn[i]["UQC"],
              "Total Quantity": parsedData.prhsn[i]["Total Quantity"],
              "Total Value": parsedData.prhsn[i]["Total Value"],
              "Taxable Value": parsedData.prhsn[i]["Taxable Value"],
              "Integrated Tax Amount": parsedData.prhsn[i]["Integrated Tax"],
              "Central Tax Amount": parsedData.prhsn[i]["Central Tax"],
              "State/UT Tax Amount": parsedData.prhsn[i]["State/UT Tax"],
              "Cess Amount": parsedData.prhsn[i]["Cess"],
              Status: parsedData.prhsn[i]["Status"],
            };
            hsnDataList.push(hsnData);
          }
        }
        //format HSN data end
        if (
          parsedData.prb2b !== undefined &&
          parsedData.prb2b !== null &&
          parsedData.prb2b.length !== 0
        ) {
          wb_new.Sheets["B2B"] = XLSX.utils.json_to_sheet(b2bDataList);
        }
        if (
          parsedData.prb2bur !== undefined &&
          parsedData.prb2bur !== null &&
          parsedData.prb2bur.length !== 0
        ) {
          wb_new.Sheets["B2BUR"] = XLSX.utils.json_to_sheet(b2burDataList);
        }
        if (
          parsedData.prcdnr !== undefined &&
          parsedData.prcdnr !== null &&
          parsedData.prcdnr.length !== 0
        ) {
          wb_new.Sheets["CDNR"] = XLSX.utils.json_to_sheet(cdnrDataList);
        }
        if (
          parsedData.prcdnur !== undefined &&
          parsedData.prcdnur !== null &&
          parsedData.prcdnur.length !== 0
        ) {
          wb_new.Sheets["CDNUR"] = XLSX.utils.json_to_sheet(cdnurDataList);
        }
        if (
          parsedData.prexemp !== undefined &&
          parsedData.prexemp !== null &&
          parsedData.prexemp.length !== 0
        ) {
          wb_new.Sheets["EXEMP"] = XLSX.utils.json_to_sheet(exempDataList);
        }
        if (
          parsedData.prat !== undefined &&
          parsedData.prat !== null &&
          parsedData.prat.length !== 0
        ) {
          wb_new.Sheets["AT"] = XLSX.utils.json_to_sheet(atDataList);
        }
        if (
          parsedData.pratadj !== undefined &&
          parsedData.pratadj !== null &&
          parsedData.pratadj.length !== 0
        ) {
          wb_new.Sheets["ATADJ"] = XLSX.utils.json_to_sheet(atadjDataList);
        }
        if (
          parsedData.pritc !== undefined &&
          parsedData.pritc !== null &&
          parsedData.pritc.length !== 0
        ) {
          wb_new.Sheets["ITC"] = XLSX.utils.json_to_sheet(itcDataList);
        }
        if (
          parsedData.prisd !== undefined &&
          parsedData.prisd !== null &&
          parsedData.prisd.length !== 0
        ) {
          wb_new.Sheets["ISD"] = XLSX.utils.json_to_sheet(isdDataList);
        }
        if (
          parsedData.primpg !== undefined &&
          parsedData.primpg !== null &&
          parsedData.primpg.length !== 0
        ) {
          wb_new.Sheets["IMPG"] = XLSX.utils.json_to_sheet(impgDataList);
        }
        if (
          parsedData.primps !== undefined &&
          parsedData.primps !== null &&
          parsedData.primps.length !== 0
        ) {
          wb_new.Sheets["IMPS"] = XLSX.utils.json_to_sheet(impsDataList);
        }
        if (
          parsedData.prhsn !== undefined &&
          parsedData.prhsn !== null &&
          parsedData.prhsn.length !== 0
        ) {
          wb_new.Sheets["HSN"] = XLSX.utils.json_to_sheet(hsnDataList);
        }
      }

      var wbout = XLSX.write(wb_new, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      const btnClick = () => {
        // uplo.close(key);
        message.loading("downloading..");
        var cur_dt = new Date();
        fileSaver.saveAs(
          blob.createBlob([s2ab(wbout)], {
            type: "application/octet-stream",
          }),
          "PURCHASE - " + cur_dt + ".xlsx"
        );
        message.destroy();
      };
      console.log(parsedData);
      if (parsedData.filestatus === true) {
        if (parsedData.total_errors > 0) {
          upload_progress.show(
            key,
            "Purchase Uploaded Successfully",
            100,
            btnClick,
            parsedData.total_errors,
            parsedData.total
          );
        } else {
          upload_progress.show(key, "Purchase Uploaded Successfully", 100);
        }
        // this.props.data.refetch();
      } else if (parsedData.file === false) {
        message.error("Uploaded file is not in ElixirGST Excel format!", 5);
      } else {
        message.error("Upload File Type is Not Allowed", 5);
      }

      return parsedData;
    } else {
      return parsedData;
    }
  },
  upload: async ({ file, type, tally, companyId, isSR, finMonth, finYear }) => {
    message.loading("Uploading...", 0);
    const data = new FormData();
    data.append(type, file);
    data.append("companyId", companyId);
    data.append("isSR", isSR);
    data.append("tally", tally);
    data.append("finMonth", finMonth);
    data.append("finYear", finYear);
    const resp = await fetch(`${BASE_URL}/upload/${type}async`, {
      method: "POST",
      body: data,
    }).then((res) => res.json()); 

    let eventSource = null;
    if (resp) {
      if (resp.key) {
        message.destroy();
        upload_progress.show(resp.key, "Uploading Purchase Invoices..", 0);
        try {
          eventSource = new EventSource(
            `${BASE_URL}/upload/internalpoll/${companyId}/${resp.key}`
          );
          eventSource.onmessage = (d) => {
            const data = JSON.parse(d.data);
            console.log(data);
            if (data.status !== "waiting") {
              eventSource.close();
              upload_progress.show(
                resp.key,
                "Uploading Purchase Invoices..",
                98
              );
              Purchase.downloadError(JSON.parse(data), tally, resp.key);
            } else {
              upload_progress.show(
                resp.key,
                "Uploading Purchase Invoices..",
                data.percentage
              );
            }
          };
        } catch (e) {
          console.log("error::::", e);
          if (eventSource) eventSource.close();
        }
      }
    } else {
      message.destroy();
      message.error("uploading failed..");
    }
    return resp;
  },
  upload_WS: ({ file, type, companyId, isSR, finMonth, finYear }) => {
    const data = new FormData();

    data.append(type, file);
    data.append("companyId", companyId);
    data.append("isSR", isSR);
    data.append("finMonth", finMonth);
    data.append("finYear", finYear);

    return fetch(`${BASE_URL}/upload/${type}`, {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then(function(data) {
        console.log(data);
        return data;
      });
  },
};

export default Purchase;
