import React from 'react'
import {
   AreaChart,
   Area,
   PieChart,
   Pie,
   Sector,
   BarChart,
   Bar,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend
  } from 'recharts'
import {
  Row,
  Col
} from 'antd';
import { Insights_Sales_Query, GSTR1_Query } from "qgst-gql-queries/web/data.json";
import get from "lodash/fp/get";
import reduce from "lodash/fp/reduce";
import flow from "lodash/fp/flow";
import flatMap from "lodash/fp/flatMap";
import toString from "lodash/fp/toString";
import pick from "lodash/fp/pick";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ContentWrapper } from '../../shared/styles'


const taxdata = [{name: 'Integrated Tax', value: 400}, {name: 'Central Tax', value: 300},
              {name: 'State Tax', value: 300}, {name: 'Cess', value: 200}];

const renderActiveShape = (props) => {
  console.log(props);
const RADIAN = Math.PI / 180;
const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
fill, payload, percent, value } = props;
const sin = Math.sin(-RADIAN * midAngle);
const cos = Math.cos(-RADIAN * midAngle);
const sx = cx + (outerRadius + 10) * cos;
const sy = cy + (outerRadius + 10) * sin;
const mx = cx + (outerRadius + 30) * cos;
const my = cy + (outerRadius + 30) * sin;
const ex = mx + (cos >= 0 ? 1 : -1) * 22;
const ey = my;
const textAnchor = cos >= 0 ? 'start' : 'end';

return (
<g>
  <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
  <Sector
    cx={cx}
    cy={cy}
    innerRadius={innerRadius}
    outerRadius={outerRadius}
    startAngle={startAngle}
    endAngle={endAngle}
    fill={fill}
  />
  <Sector
    cx={cx}
    cy={cy}
    startAngle={startAngle}
    endAngle={endAngle}
    innerRadius={outerRadius + 6}
    outerRadius={outerRadius + 10}
    fill={fill}
  />
  <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
  <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
  <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`₹ ${value}`}</text>
  <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
    {`(Rate ${(percent * 100).toFixed(2)}%)`}
  </text>
</g>
);
};

class InsightsDashboard extends React.Component {

state = { activeIndex: 0 };

onPieEnter(taxdata, index) {
this.setState({
  activeIndex: index,
});
}

// formatNumber(value) {
//   var x = parseFloat(value ? value : "0").toFixed(2);
//   x = x.toString();
//   var afterPoint = "";
//   if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
//   x = Math.floor(x);
//   x = x.toString();
//   var lastThree = x.substring(x.length - 3);
//   var otherNumbers = x.substring(0, x.length - 3);
//   if (otherNumbers !== "") lastThree = "," + lastThree;
//   var res =
//     otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
//     lastThree +
//     afterPoint;
//   return `₹ ${res}`;
// }
//
// iamt = {this.formatNumber(
//            flow(
//              flatMap(get("sr_b2b_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.igst ? next.igst : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
//            )
//          )} +
//          {this.formatNumber(
//            flow(
//              flatMap(get("sr_b2cl_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.igst ? next.igst : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_b2cl
//                ? this.props.gstr1_b2cl.edges
//                : []
//            )
//          )} +
//          {this.formatNumber(
//            flow(
//              flatMap(get("sr_export_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.igst ? next.igst : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_export
//                ? this.props.gstr1_export.edges
//                : []
//            )
//          )}
//
//
//   camt = {this.formatNumber(
//              flow(
//                flatMap(get("sr_b2b_items.edges")),
//                reduce(
//                  (curr, next) =>
//                    curr +
//                    parseFloat(next.cgst ? next.cgst : "0", 10),
//                  0
//                )
//              )(
//                this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
//              )
//            )} +
//            {this.formatNumber(
//              flow(
//                flatMap(get("sr_b2cl_items.edges")),
//                reduce(
//                  (curr, next) =>
//                    curr +
//                    parseFloat(next.cgst ? next.cgst : "0", 10),
//                  0
//                )
//              )(
//                this.props.gstr1_b2cl
//                  ? this.props.gstr1_b2cl.edges
//                  : []
//              )
//            )} +
//            {this.formatNumber(
//              flow(
//                flatMap(get("sr_export_items.edges")),
//                reduce(
//                  (curr, next) =>
//                    curr +
//                    parseFloat(next.cgst ? next.cgst : "0", 10),
//                  0
//                )
//              )(
//                this.props.gstr1_export
//                  ? this.props.gstr1_export.edges
//                  : []
//              )
//            )}
//
//
// samt = {this.formatNumber(
//            flow(
//              flatMap(get("sr_b2b_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.sgst ? next.sgst : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
//            )
//          )} +
//          {this.formatNumber(
//            flow(
//              flatMap(get("sr_b2cl_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.sgst ? next.sgst : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_b2cl
//                ? this.props.gstr1_b2cl.edges
//                : []
//            )
//          )} +
//          {this.formatNumber(
//            flow(
//              flatMap(get("sr_export_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.sgst ? next.sgst : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_export
//                ? this.props.gstr1_export.edges
//                : []
//            )
//          )}
//
//
// cessamt = {this.formatNumber(
//            flow(
//              flatMap(get("sr_b2b_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.cess ? next.cess : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
//            )
//          )} +
//          {this.formatNumber(
//            flow(
//              flatMap(get("sr_b2cl_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.cess ? next.cess : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_b2cl
//                ? this.props.gstr1_b2cl.edges
//                : []
//            )
//          )} +
//          {this.formatNumber(
//            flow(
//              flatMap(get("sr_export_items.edges")),
//              reduce(
//                (curr, next) =>
//                  curr +
//                  parseFloat(next.cess ? next.cess : "0", 10),
//                0
//              )
//            )(
//              this.props.gstr1_export
//                ? this.props.gstr1_export.edges
//                : []
//            )
//          )}
//


render () {

  console.log(this.props.insights_sales);

  this.sd = this.props.insights_sales ? this.props.insights_sales.totalsales : '0'

  var salesdata = [
    {name: 'B2B', pm: parseFloat(this.sd.sales_b2b_p), cm: parseFloat(this.sd.sales_b2b_c)},
    {name: 'B2CL', pm: parseFloat(this.sd.sales_b2cl_p), cm: parseFloat(this.sd.sales_b2cl_c)},
    {name: 'Export', pm: parseFloat(this.sd.sales_export_p), cm: parseFloat(this.sd.sales_export_c)},
  ];
return (
  <ContentWrapper>
  <br />
  <br />
  <Row>
  <Col span={12} style={{ paddingLeft: "40px" }}>

  <p style={{ fontSize: "20px"}}>Total Sales Figures</p>
  <br />
  <div>
        <p style={{ fontSize: "15px"}}><i>A comparison between last month and current month sales frequency</i></p>
        <br />
        <p>Previous Month</p>
        <br />
        <AreaChart width={600} height={200} data={salesdata} syncId="anyId" isAnimationActive={true}
              margin={{top: 10, right: 30, left: 0, bottom: 0}}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip/>
          <Area type='monotone' dataKey='pm' stroke='#8884d8' fill='#8884d8' />
        </AreaChart>
        <p>Current Month</p>
        <br />
        <AreaChart width={600} height={200} data={salesdata} syncId="anyId" isAnimationActive={true}
              margin={{top: 10, right: 30, left: 0, bottom: 0}}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip/>
          <Area type='monotone' dataKey='cm' stroke='#82ca9d' fill='#82ca9d' />
        </AreaChart>
  </div>
  </Col>
  <Col span={12}>

  <p style={{ fontSize: "20px"}}>Total Tax Levied</p>
  <br />
  <br />
  <PieChart width={800} height={400}>
    <Pie
      activeIndex={this.state.activeIndex}
      activeShape={renderActiveShape}
      isAnimationActive={true}
      data={taxdata}
      cx={300}
      cy={200}
      innerRadius={80}
      outerRadius={120}
      fill="#8884d8"
      onMouseEnter={this.onPieEnter.bind(this)}
    />
   </PieChart>
   </Col>
   </Row>

   </ContentWrapper>
);
}
}
const config = {
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({ data }) => {
    return {
      gstr1_b2b: get("viewer.sr_b2b_masters", data),
      gstr1_b2cl: get("viewer.sr_b2cl_masters", data),
      gstr1_export: get("viewer.sr_export_masters", data),
    };
  }
};

const configInsights = {
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({ data }) => {
    return {
      insights_sales: get("viewer", data),
    };
  }
};
const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(gql(Insights_Sales_Query), configInsights),
  graphql(gql(GSTR1_Query), config),
)(InsightsDashboard)
