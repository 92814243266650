import React from 'react';
import { Table, Tag } from 'antd';
import get from "lodash/fp/get";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { CompanyByIdQuery, RetFilingStatusQuery } from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import moment from 'moment'
const { Column } = Table;

class RetFilingStatus extends React.Component {

  constructor(props) {
    super(props);
    this.props.retStatus.refetch();
  }

  render() {
    console.log(this.props.retStatus);
    // console.log(this.props.ret_track_status_count);
    var noDataContent;
    var tableLoading = get('viewer.retfile_status.edges', this.props.retStatus) ? false : true;
    // var tableLoading = false;
    // if (this.props.retStatus.networkStatus === 4) { tableLoading = false; }
    // else if (this.props.retStatus.networkStatus === 7) { tableLoading = false; }
    // else if (this.props.retStatus.networkStatus === 1) { tableLoading = true; }
    if(this.props.ret_track_status_count && this.props.ret_track_status_count.total === 0){
      tableLoading = false;
      noDataContent = 'No Record found for the provided Inputs';
    } else if(this.props.ret_track_status_count && this.props.ret_track_status_count.total === 1){
      tableLoading = false;
      noDataContent = 'Govt API under Maintenance. Try after sometime!';
    }
       return (
         <div>
         <Table locale={{ emptyText: noDataContent }} dataSource={this.props.ret_track_status} rowKey="arn" loading={tableLoading} bordered title={() => 'Return filing status archives from GSTN'} size="middle">
           <Column className="alignCenter" title="ARN" dataIndex="arn" key="arn" />
           <Column className="alignCenter" title="Return Type" dataIndex="rtntype" key="rtntype" render={text => <b>{text}</b>} />
           <Column className="alignCenter" title="Financial Year" dataIndex="ret_prd" key="ret_prd_yr" render={text => (text ? moment(text, "MMYYYY").format('YYYY') : '')} />
           <Column className="alignCenter" title="Tax Period" dataIndex="ret_prd" key="ret_prd_mon" render={text => (text ? moment(text, "MMYYYY").format('MMMM') : '')} />
           <Column className="alignCenter" title="Date of Filing" dataIndex="dof" key="dof" render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}/>
           <Column className="alignCenter" title="Status" dataIndex="status" key="status" render={text => (text === 'Filed' ? <Tag style={{ cursor: 'initial' }} color="green">{text}</Tag> : <Tag style={{ cursor: 'initial' }} color="volcano">{text}</Tag>)} />
           <Column className="alignCenter" title="Mode of Filing" dataIndex="mof" key="mof" />
         </Table>
         </div>
       )
     }
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const RetFilingStatusConfig = {
  name: "retStatus",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finyear: props.finYear,
      gstin: props.company ? props.company.gstin : '',
    },
  }),
  props: ({ retStatus }) => {
    return {
      retStatus: retStatus,
      ret_track_status: get('viewer.retfile_status.edges', retStatus),
      ret_track_status_count: get('viewer.retfile_status.pageInfo', retStatus),
    }
  },
  skip: (props) => {
    return false;
  }
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  };
};


export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
  graphql(gql(RetFilingStatusQuery), RetFilingStatusConfig),
)(RetFilingStatus)
