import React from 'react'
import Icon from 'antd/lib/icon'
import { CenterAlignWrapper } from '../../../shared/styles'
import glamorous from 'glamorous'

const IconWrapperDiv = glamorous(CenterAlignWrapper)({
  height: 47,
  width: 47,
  fontSize: 18,
  cursor: 'pointer',
  transition: 'all .3s ease-out',

  '&:hover': {
    color: '#108ee9',
    backgroundColor: 'rgba(16,142,233,.15)',
  },
})

const IconWrapper = props =>
  <IconWrapperDiv>
    <Icon {...props} />
  </IconWrapperDiv>

export { IconWrapper, IconWrapperDiv }
