import React from "react";
import Glamorous from "glamorous";
import Form from "antd/lib/form";
import { BASE_URL } from '../../shared/constants'
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import { Redirect, Link } from "react-router-dom";

import { ROUTES, i18n } from "../../shared/constants";
import Auth from "../../services/AuthService";

const B = Glamorous(Button)({
  width: "100%"
});

const ForgotPassword = props => (
  <Form onSubmit={props.forgotPassword}>
    <Form.Item>
      {props.getFieldDecorator("email", {
        rules: [
          { required: true, message: i18n.en.register.email_required_message }
        ]
      })(<Input placeholder={i18n.en.register.email_placeholder_message} />)}
    </Form.Item>
    <B type="primary" size="large" htmlType="submit">
      Generate New Password
    </B>
    </Form>
);

const ValidateOTP = props =>
    <Form onSubmit={props.validateOTP}>
        <Form.Item>
            {
                props.getFieldDecorator('otp', {
                    rules: [{ required: true, message: 'Please input your otp!' }],
                })
                (<Input placeholder="Enter the received OTP" />)}
        </Form.Item>
        <B type="primary" size="large" htmlType="submit">
            Confirm OTP
        </B>
    </Form>

const NewPassword = props =>
    <Form onSubmit={props.validateNewPassword}>
        <Form.Item>
            {
                props.getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your new password!' }],
                })
                (<Input placeholder="Enter the New Password" type="password" />)}
        </Form.Item>
        <Form.Item>
            {
                props.getFieldDecorator('passwordverify', {
                    rules: [{ required: true, message: 'Please verify your new password!' }],
                })
                (<Input placeholder="Enter the New Password again." type="password" />)}
        </Form.Item>
        <B type="primary" size="large" htmlType="submit">
            Change Password
        </B>
    </Form>



class ForgotPasswordContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectToDashboard: Auth.isLoggedIn(),
      redirectToLogin: false,
      redirectToOTP:false,
      redirectToNewPassword:false,
      userid:null
    };
    this.forgotPasswordEmail = '';
  }

  forgotPassword = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
        this.forgotPasswordEmail = values.email

        // const checkDB = await fetch(`${BASE_URL}/export/findEMAIL?details=${values.email}`,
        //       {
        //         method: 'GET',
        //       })

        const forUser = await Auth.sendResetPasswordOTP({
          email: values.email
        });
        if(forUser !== undefined){
          message.info('An OTP has been sent to ' + values.email, 7);
        this.setState({
          redirectToOTP: true,
          userid: forUser
        })
      }else{
           message.error('Email Id not registered yet!', 7);
      }
        
    });
      
  };
  validateOTP = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
        if (err) return;
        values.userid = this.state.userid;
        const result = await Auth.validateOTP(values);
        console.log(result);
        if(result.success){
            message.destroy();
            message.success('OTP Verified, Enter you new password!!');
            console.log("success");
            this.setState({
                redirectToOTP: false,
                redirectToNewPassword: true
            });
        }else if(result.message === "Incorrect OTP!"){
          message.error('Incorrect OTP!!!');
        }
    });
}
validateNewPassword = async e => {
  e.preventDefault();
  this.props.form.validateFields(async (err, values) => {
    if (err) return;
    console.log(values);
    if(values.password !== values.passwordverify){
      message.error("Password not matching!!");
    }else{
    const result = await Auth.forgotPassword({password: values.passwordverify, email: this.forgotPasswordEmail});
    console.log(result);
    if(result.success){
        message.destroy();
        message.success('Password Changed Successfully! You can now login with your new password!');
        console.log("success");
        this.setState({
            redirectToOTP: false,
            redirectToNewPassword: false,
            redirectToLogin: true,
        });
    }
  }
  });
};

  render() {
    if (this.state.redirectToOTP) {
      return (
        <ValidateOTP
        getFieldDecorator={this.props.form.getFieldDecorator}
        validateOTP={this.validateOTP}
        />
      )
    }else if(this.state.redirectToNewPassword) {
      return (
      <NewPassword
      validateNewPassword={this.validateNewPassword}
      getFieldDecorator={this.props.form.getFieldDecorator}
      />
    )
    }else if (this.state.redirectToLogin) {
      return <Redirect to={ROUTES.LOGIN} />
    }else{
    return (
      <ForgotPassword
        forgotPassword={this.forgotPassword}
        getFieldDecorator={this.props.form.getFieldDecorator}
      />
    )
  }
  }
}

export default Form.create()(ForgotPasswordContainer);
