import React from "react";
import { Table, InputNumber, Input, Tag } from "antd";
const GSTR9Pt6Sec15 = props => {
  const columns = [
    {
      title: "Pt. VI",
      dataIndex: "sno",
      key: "sno",
      width: "5%"
    },
    {
      title: "Details",
      dataIndex: "det",
      key: "det",
      width: "11%"
    },
    {
      title: "Integrated Tax (₹)",
      dataIndex: "iamt",
      key: "iamt",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-iamt-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Central Tax (₹)",
      dataIndex: "camt",
      key: "camt",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-camt-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "State/UT Tax (₹)",
      dataIndex: "samt",
      key: "samt",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-samt-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "CESS (₹)",
      dataIndex: "cess",
      key: "cess",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-cess-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Interest (₹)",
      dataIndex: "int",
      key: "int",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-int-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Penalty (₹)",
      dataIndex: "penalty",
      key: "penalty",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-penalty-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Late fee/others (₹)",
      dataIndex: "late_fees",
      key: "late_fees",
      width: "12%",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec15-${record.key}-late_fees-tbl15`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "15. Particulars of Demands and Refunds"
      }
      size="small"
      dataSource={props.pt6sec15.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt6Sec15
