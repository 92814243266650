/**
 * @flow
 */
import React from 'react'
import Modal from 'antd/lib/modal'
import Table from 'antd/lib/table'
import { formatNumber } from "../../shared/constants";
const { ColumnGroup, Column } = Table

type props = {
  isVisible: boolean,
  onCancel: Function,
  sr_cdnura_items: Array,
}

export default (props: props) =>
  <Modal title="Sales Items" visible={props.isVisible} onCancel={props.onCancel} footer={null} width="90%">
    <Table dataSource={props.sr_cdnura_items} bordered rowKey="gstin">
      <Column title="Taxable Value" dataIndex="taxable_value" key="taxable_value" render={val => formatNumber(val === null ? 0 : val)}/>
      <Column title="Rate" dataIndex="gst_rate" key="gst_rate" />
      <ColumnGroup title="Amount of Tax">
        <Column title="Integrated GST" dataIndex="igst" key="igst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="Cess" dataIndex="cess" key="cess" render={val => (val === null ? '***' : formatNumber(val))} />
      </ColumnGroup>
    </Table>
  </Modal>
