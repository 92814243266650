import React from "react";
import { Anchor, Layout } from "antd";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import RET1_3A from './RET1_3A';
import RET1_3B from './RET1_3B';
import RET1_3C from './RET1_3C';
import RET1_3D_3E from './RET1_3D_3E';

const { Sider } = Layout;
const { Link } = Anchor;
class ReturnOne3 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            outward_supplies: [{
                sno: 1,
                type: "Taxable supplies made to consumers and unregistered persons (B2C)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 2,
                type: "Taxable supplies made to registered persons (other than those attracting reverse charge) (B2B)",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 3,
                type: "Exports with payment of tax",
                txval: 3000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 4,
                type: "Exports without payment of tax",
                txval: 3000,
            },
            {
                sno: 5,
                type: "Supplies to SEZ units/developers with payment of tax",
                txval: 3000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 6,
                type: "Supplies to SEZ units/developers without payment of tax",
                txval: 3000,
            },
            {
                sno: 7,
                type: "	Deemed exports",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 8,
                type: "Liabilities relating to the period prior to the introduction of current return filing system and any other liability to be paid",
                txval: 3000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 9,
                type: "Liabilities relating to export (out of 8)",
                txval: 3000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 10,
                type: "Sub-total (3A) [sum of 1 to 8]",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            }
            ],

            inwardsupplies: [{
                sno: 1,
                type: "Inward supplies attracting reverse charge (net of debit/credit notes and advances paid, if any)",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            {
                sno: 2,
                type: "Import of services (net of debit/credit notes and advances paid, if any)",
                txval: 50000,
                igst: 200,
                cess: 50,
            },
            {
                sno: 3,
                type: "Sub-total (3B) [sum of 1 & 2]",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,
            },
            ],
            otherreduction: [
                {
                    sno: 1,
                    type: "Debit notes issued (other than those attracting reverse charge)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 2,
                    type: "Credit notes issued (other than those attracting reverse charge)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 3,
                    type: "Advances received (net of refund vouchers and including adjustments on account of wrong reporting of advances earlier)",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 4,
                    type: "Advances adjusted",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 5,
                    type: "Reduction in output tax liability on account of transition from composition levy to normal levy, if any or any other reduction in liability",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
                {
                    sno: 6,
                    type: "Liabilities relating to export (out of 5)",
                    txval: 50000,
                    igst: 200,
                    cess: 50,
                },
                {
                    sno: 7,
                    type: "Sub-total (3C) [1-2+3-4-5]",
                    txval: 50000,
                    igst: 200,
                    cgst: 100,
                    sgst: 100,
                    cess: 50,
                },
            ],
            threeD: [
                {
                    sno: 1,
                    type: "Exempt and Nil rated supplies",
                    txval: 50000,
                },
                {
                    sno: 2,
                    type: "Non-GST supplies (including No Supply/Schedule III supplies)",
                    txval: 50000,
                },
                {
                    sno: 3,
                    type: "Outward supplies attracting reverse charge (net of debit/credit notes)",
                    txval: 50000,
                },
                {
                    sno: 4,
                    type: "Supply of goods by a SEZ unit/developer to DTA on a Bill of Entry",
                    txval: 50000,
                },
                {
                    sno: 5,
                    type: "Sub-total (3D) [sum of 1 to 4]",
                    txval: 50000,
                },

            ],
            threeE: [{
                type: "3E. Total value and tax liability (3A+3B+3C+3D)",
                txval: 50000,
                igst: 200,
                cgst: 100,
                sgst: 100,
                cess: 50,

            }
            ]
        }
        this.navigateTo = this.navigateTo.bind(this);
    }
    navigateNext = () => {
        this.setState({
            currentPage: this.state.currentPage + 1,
        });
    }
    navigatePrev = () => {
        this.setState({
            currentPage: this.state.currentPage - 1,
        });
    }
    navigateTo = (e, link) => {
        e.preventDefault();
        switch (link.title) {
            case "3A":
                this.setState({ currentPage: 1 })
                break;
            case "3B":
                this.setState({ currentPage: 2 })
                break;
            case "3C":
                this.setState({ currentPage: 3 })
                break;
            case "3D_3E":
                this.setState({ currentPage: 4 })
                break;
            default:

                break;
        }
    }
    render() {
        return (
            <Layout>
                <Root>
                    <Content>
                        <h2 id="3A">3. Summary of outward supplies, inward supplies attracting reverse charge, debit/credit notes, etc. and tax liability</h2>
                        {function (s, navigateNext, navigatePrev) {
                            switch (s.currentPage) {
                                case 1:
                                    return <RET1_3A navigateNext={navigateNext} outward_supplies={s.outward_supplies} />;
                                    break;
                                case 2:
                                    return <RET1_3B navigateNext={navigateNext} navigatePrev={navigatePrev} inwardsupplies={s.inwardsupplies} />;
                                    break;
                                case 3:
                                    return <RET1_3C navigateNext={navigateNext} navigatePrev={navigatePrev} otherreduction={s.otherreduction} />;
                                    break;
                                case 4:
                                    return <RET1_3D_3E navigatePrev={navigatePrev} threeD={s.threeD} threeE={s.threeE} />;
                                    break;
                                default:
                                    return null;
                            }
                        }(this.state, this.navigateNext, this.navigatePrev)}
                    </Content>
                </Root>
                <Sider
                    style={{ backgroundColor: "##D3D3D3" }}
                    theme="light"
                    width="100"
                >
                    <Anchor style={{ backgroundColor: "##D3D3D3" }} offsetTop={100} onClick={this.navigateTo}>
                        <Link title="3A" />
                        <br />
                        <Link href="javascript:void(0)" title="3B" />
                        <br />
                        <Link href="#3C" title="3C" />
                        <br />
                        <Link href="#3D_3E" title="3D_3E" />
                        <br />
                    </Anchor>
                </Sider>
            </Layout>
        )
    }
}
export default ReturnOne3;