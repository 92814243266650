import React from "react";
import Tooltip from "antd/lib/tooltip";
import Table from "antd/lib/table";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import { notification, Icon, Popconfirm, Steps } from "antd";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import map from "lodash/fp/map";
import toString from "lodash/fp/toString";
import findIndex from "lodash/fp/findIndex";
import filter from "lodash/fp/filter";
import compact from "lodash/fp/compact";
import omit from "lodash/fp/omit";
import message from "antd/lib/message";
import flow from "lodash/fp/flow";
import {
  formatNumber,
  stateArray,
  getPlaceOfSupply,
} from "../../shared/constants";
import {
  PR_RET_Period_Query,
  CompanyByIdQuery,
  CompanyUsersQuery,
} from "qgst-gql-queries/web/data.json";
import { gql, graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import DropZone from "react-dropzone";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import PURCHASE from "../../services/PurchaseService";
import { BASE_URL } from "../../shared/constants";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import Get2AForm from "../GSP/Get2AForm";
import Get2BForm from "../GSP/Get2BForm";
import gspAuth from "../../services/GSPService";
import moment from "moment";
import excelUtility from "../../services/excelUtilityService";

import ReconcileAdvanceModal from "./ReconcileAdvanceModal";
import ReconcileMatchedDetails from "./ReconcileMatchedDetails";
import ReconcileMisMatchedDetails from "./ReconcileMisMatchedDetails";
import ReconcilePurchaseOnlyDetails from "./ReconcilePurchaseOnlyDetails";
import ReconcileGSTOnlyDetails from "./ReconcileGSTOnlyDetails";
import ReconcileLikelyDetails from "./ReconcileLikelyDetails";
import ReconcileIneligibleDetails from "./ReconcileIneligibleDetails";
import Reconcile_B2BA_MatchedDetails from "./Reconcile_B2BA_MatchedDetails";
import Reconcile_B2BA_MisMatchedDetails from "./Reconcile_B2BA_MisMatchedDetails";
import Reconcile_B2BA_PurchaseOnlyDetails from "./Reconcile_B2BA_PurchaseOnlyDetails";
import Reconcile_B2BA_GSTOnlyDetails from "./Reconcile_B2BA_GSTOnlyDetails";
import Reconcile_B2BA_LikelyDetails from "./Reconcile_B2BA_LikelyDetails";
import Reconcile_B2BA_IneligibleDetails from "./Reconcile_B2BA_IneligibleDetails";

import ReconcileMismatchEmail from "./ReconcileMismatchEmail";
import ReconcilePurchaseEmail from "./ReconcilePurchaseEmail";

import ReconcileCDNRMatchedDetails from "./ReconcileCDNRMatchedDetails";
import ReconcileCDNRMisMatchedDetails from "./ReconcileCDNRMisMatchedDetails";
import ReconcileCDNRPurchaseOnlyDetails from "./ReconcileCDNRPurchaseOnlyDetails";
import ReconcileCDNRGSTOnlyDetails from "./ReconcileCDNRGSTOnlyDetails";
import ReconcileCDNRLikelyDetails from "./ReconcileCDNRLikelyDetails";
import ReconcileCDNRIneligibleDetails from "./ReconcileCDNRIneligibleDetails";
import Reconcile_CDNA_MatchedDetails from "./Reconcile_CDNA_MatchedDetails";
import Reconcile_CDNA_MisMatchedDetails from "./Reconcile_CDNA_MisMatchedDetails";
import Reconcile_CDNA_PurchaseOnlyDetails from "./Reconcile_CDNA_PurchaseOnlyDetails";
import Reconcile_CDNA_GSTOnlyDetails from "./Reconcile_CDNA_GSTOnlyDetails";
import Reconcile_CDNA_LikelyDetails from "./Reconcile_CDNA_LikelyDetails";
import Reconcile_CDNA_IneligibleDetails from "./Reconcile_CDNA_IneligibleDetails";

import ReconcileCDNRMismatchEmail from "./ReconcileCDNRMismatchEmail";
import ReconcileCDNRPurchaseEmail from "./ReconcileCDNRPurchaseEmail";

import ViewSupplierData from "./ViewSupplierData";

const XLSX = require("xlsx");
var fileSaver = require("file-saver");
var blob = require("blob-util");
const { flattenDeep, uniq, find, pick, get } = require("lodash/fp");

const row_style = {
  border: "solid 1px #e8e8e8",
  padding: "10px",
  margin: "1px",
};

const { Column, ColumnGroup } = Table;
const confirm = Modal.confirm;
var fileName;
var comp_id = "...";
const plainOptions_b2b = [
  "Invoice Date",
  "Invoice Value",
  "Taxable Value",
  "Taxes (IGST, CGST, SGST, CESS)",
];
const plainOptions_cdnr = [
  "CDNR Date",
  "CDNR Value",
  "Taxable Value",
  "Taxes (IGST, CGST, SGST, CESS)",
];
const defaultCheckedList = ["Taxes (IGST, CGST, SGST, CESS)"];

class ReconcileSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailTextBox: this.props.mailTextBox,
    };
  }

  showConfirm() {
    confirm({
      title: "Errors Found While Uploading Purchases",
      iconType: "printer",
      width: 866,
      content: <div id="dialogue_append" />,
      onOk() {
        document.getElementById("purchase_download").click();
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops errors!"));
      },
    });
  }

  info() { }

  componentDidUpdate(prevProps) {
    if (prevProps.mailTextBox !== this.props.mailTextBox) {
      if (document.getElementById("mailMessage")) {
        document.getElementById("mailMessage").value = this.props.mailTextBox;
      }
    }
  }

  render() {
    const props = this.props;
    return (
      <Root>
        <Content>
          <Row gutter={[16, 24]} style={row_style}>
            <div style={{ textAlign: "center" }}>
              <span>
                <Button
                  id="purchase_confirm_upload"
                  onClick={this.showConfirm}
                />{" "}
                <Button className="{ant-btn-primary}" id="purchase_download">
                  Download
                </Button>
              </span>
              <span>
                {" "}
                <Button
                  id="pull_external_click"
                  onClick={props.fetchFromExternalAPI}
                />
              </span>

              {!props.externalInterfaceInfo && (
                <Col md={8} sm={12} xs={24}>
                  <DropZone
                    onDrop={props.onDropTallyPR}
                    multiple={false}
                    disabled={props.role === "reviewer"}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Tooltip
                          title="Upload Purchase Invoices (Tally)"
                          overlay=""
                        >
                          <Button
                            size="large"
                            icon="cloud-upload"
                            style={{
                              width: "200px",
                              fontSize: "14px",
                              backgroundColor: "#2382c7",
                              color: "white",
                              padding: "0 10px",
                            }}
                            disabled={props.role === "reviewer"}
                          >
                            Upload Purchase (Tally)
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </DropZone>
                </Col>
              )}
              {!props.externalInterfaceInfo && (
                <Col md={8} sm={12} xs={24}>
                  <DropZone
                    onDrop={props.onDropPR}
                    multiple={false}
                    disabled={props.role === "reviewer"}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Tooltip
                          title="Upload Purchase Invoices (ElixirGST)"
                          overlay=""
                        >
                          <Button
                            size="large"
                            icon="cloud-upload"
                            style={{
                              width: "200px",
                              fontSize: "14px",
                              backgroundColor: "#2382c7",
                              color: "white",
                              padding: "0 10px",
                            }}
                            disabled={props.role === "reviewer"}
                          >
                            Upload Purchase (TG)
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </DropZone>
                </Col>
              )}

              <Col md={8} sm={12} xs={24}>
                <Tooltip title="Select 2B Period" overlay="">
                  <Button
                    style={{
                      width: "200px",
                      fontSize: "14px",
                      backgroundColor: "#2382c7",
                      color: "white",
                      padding: "0 10px",
                    }}
                    size="large"
                    icon="cloud-download"
                    onClick={props.onTwoB}
                    disabled={props.role === "reviewer"}
                  >
                    Get 2B Data
                  </Button>
                  <Get2BForm
                    wrappedComponentRef={props.save2BFormRef}
                    visible={props.pull2B}
                    onSubmit={props.handleGet2BSubmit}
                    onCancel={props.handleGet2BCancel}
                  />
                </Tooltip>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Tooltip title="Select 2A Period" overlay="">
                  <Button
                    style={{
                      width: "200px",
                      fontSize: "14px",
                      backgroundColor: "#2382c7",
                      color: "white",
                      padding: "0 10px",
                    }}
                    size="large"
                    icon="cloud-download"
                    onClick={props.onTwoA}
                    disabled={props.role === "reviewer"}
                  >
                    Get 2A Data
                  </Button>
                  <Get2AForm
                    wrappedComponentRef={props.save2AFormRef}
                    visible={props.pull2A}
                    onSubmit={props.handleGet2ASubmit}
                    onCancel={props.handleGet2ACancel}
                  />
                </Tooltip>
              </Col>
              <Col md={8} sm={12} xs={24}>
                <Button
                  size="large"
                  style={{
                    width: "200px",
                    fontSize: "14px",
                    backgroundColor: "#04880fe0",
                    color: "white",
                    padding: "0 10px",
                  }}
                  onClick={props.reconcilePrompt}
                  icon="fork"
                >
                  Reconcile
                </Button>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Tooltip
                  title="Clear all Purchases for selected Tax Period"
                  overlay=""
                >
                  <Popconfirm
                    placement="bottomRight"
                    onVisibleChange={props.popupConfirmResetVisibleChange}
                    visible={props.popupConfirmReset}
                    title="Clicking this button will reset all Purchases for selected Tax Period!"
                    onConfirm={props.resetPurchase}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        width: "200px",
                        fontSize: "14px",
                        backgroundColor: "#a01208de",
                        color: "white",
                        padding: "0 10px",
                      }}
                      size="large"
                      icon="safety"
                      disabled={props.role === "reviewer"}
                    >
                      Reset
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>

              <span>
                <Button id="purchase_error_status" onClick={this.info}>
                  Info
                </Button>{" "}
              </span>
            </div>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              {/*<Alert
                className='showAlert'
                type="info"
                style={{ display: 'none', textAlign: 'center' }}
                message={'Reconcilation done for the period '+moment(props.recon_from_month.toString()).format('MMMM')+ ' ('+props.recon_year+') - '+moment(props.recon_to_month.toString()).format('MMMM') + ' ('+props.recon_year+')'}
                closable
              />*/}
              <Table
                style={{ display: "none" }}
                rowKey="action"
                dataSource={props.output_b2b}
                bordered
                scroll={{ x: 300 + 8 * 125 }}
                title={() => "Reconciliation Summary"}
                pagination={false}
                footer={() => (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        icon="audit"
                        style={{ backgroundColor: "#0e5e98", color: "white" }}
                        onClick={() => props.viewCPdata()}
                      >
                        View Only Supplier Data
                      </Button>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "5px" }}>
                      <Button
                        icon="file-excel"
                        style={{ backgroundColor: "#2382c7", color: "white" }}
                        onClick={() =>
                          props.downExcel(
                            props.recon_matches_b2b,
                            props.recon_mismatches_b2b,
                            props.recon_one_b2b,
                            props.recon_two_b2b,
                            props.recon_likely_b2b,
                            props.recon_ineligible_b2b,

                            props.recon_matches_b2b_2,
                            props.recon_mismatches_b2b_2,
                            props.recon_one_b2b_2,
                            props.recon_two_b2b_2,
                            props.recon_likely_b2b_2,
                            props.recon_ineligible_b2b_2,

                            props.recon_matches_cdnr,
                            props.recon_mismatches_cdnr,
                            props.recon_one_cdnr,
                            props.recon_two_cdnr,
                            props.recon_likely_cdnr,
                            props.recon_ineligible_cdnr,

                            props.recon_matches_cdnr_2,
                            props.recon_mismatches_cdnr_2,
                            props.recon_one_cdnr_2,
                            props.recon_two_cdnr_2,
                            props.recon_likely_cdnr_2,
                            props.recon_ineligible_cdnr_2,

                            props.recon_matches_b2ba,
                            props.recon_mismatches_b2ba,
                            props.recon_two_b2ba,

                            props.recon_matches_b2ba_2,
                            props.recon_mismatches_b2ba_2,
                            props.recon_onlygst_b2ba_2,

                            props.recon_matches_cdna,
                            props.recon_mismatches_cdna,
                            props.recon_two_cdna,

                            props.recon_matches_cdna_2,
                            props.recon_mismatches_cdna_2,
                            props.recon_two_cdna_2,

                            props.finYear,
                            props.nextFY
                          )
                        }
                      >
                        Download Reconciliation Summary (Excel Format)
                      </Button>
                    </div>
                  </div>
                )}
              >
                <ColumnGroup title="">
                  <Column
                    dataIndex="type"
                    key="type"
                    className="alignCenter"
                    width={300}
                  />
                </ColumnGroup>
                {props.showB2B && (
                  <ColumnGroup title="B2B">
                    <Column
                      title={() => (
                        <div>
                          {props.finYear}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.finYear}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`action${props.finYear}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_b2b !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_b2b)}
                            style={{ width: "70px" }}
                          >
                            {record.count_b2b}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    />
                    <Column
                      title={() => (
                        <div>
                          {props.nextFY}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.nextFY}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`action${props.nextFY}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_b2b_2 !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_b2b_2)}
                            style={{ width: "70px" }}
                          >
                            {record.count_b2b_2}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    />
                  </ColumnGroup>
                )}
                {props.showCDNR && (
                  <ColumnGroup title="CDNR">
                    <Column
                      title={() => (
                        <div>
                          {props.finYear}&nbsp;
                          <Tooltip
                            title={`Invoices Booked in the year ${props.finYear}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`cdnr${props.finYear}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_cdnr !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_cdnr)}
                            style={{ width: "70px" }}
                          >
                            {record.count_cdnr}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    />
                    <Column
                      title={() => (
                        <div>
                          {props.nextFY}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.nextFY}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`cdnr${props.nextFY}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_cdnr_2 !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_cdnr_2)}
                            style={{ width: "70px" }}
                          >
                            {record.count_cdnr_2}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    />
                  </ColumnGroup>
                )}
                {props.showB2B && (
                  <ColumnGroup title="B2B - Amended">
                    <Column
                      title={() => (
                        <div>
                          {props.finYear}&nbsp;
                          <Tooltip
                            title={`Invoices Booked in the year ${props.finYear}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`b2ba${props.finYear}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_b2ba !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_b2ba)}
                            style={{ width: "70px" }}
                          >
                            {record.count_b2ba}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    ></Column>
                    <Column
                      title={() => (
                        <div>
                          {props.nextFY}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.nextFY}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`b2ba${props.nextFY}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_b2ba_2 !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_b2ba_2)}
                            style={{ width: "70px" }}
                          >
                            {record.count_b2ba_2}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    ></Column>
                  </ColumnGroup>
                )}
                {props.showCDNR && (
                  <ColumnGroup title="CDN - Amended">
                    <Column
                      title={() => (
                        <div>
                          {props.finYear}&nbsp;
                          <Tooltip
                            title={`Invoices Booked in the year ${props.finYear}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`cdnra${props.finYear}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_cdna !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_cdna)}
                            style={{ width: "70px" }}
                          >
                            {record.count_cdna}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    ></Column>
                    <Column
                      title={() => (
                        <div>
                          {props.nextFY}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.nextFY}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`cdnra${props.nextFY}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_cdna_2 !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_cdna_2)}
                            style={{ width: "70px" }}
                          >
                            {record.count_cdna_2}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    ></Column>
                  </ColumnGroup>
                )}
                                 {props.showIMPG && (
                  <ColumnGroup title="IMPG">
                    <Column
                      title={() => (
                        <div>
                          {props.finYear}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.finYear}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`impg${props.finYear}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_impg !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_impg)}
                            style={{ width: "70px" }}
                          >
                            {record.count_impg}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    />
                    <Column
                      title={() => (
                        <div>
                          {props.nextFY}
                          <Tooltip
                            title={`Invoices Booked in the year ${props.nextFY}`}
                          >
                            <Icon
                              type="bulb"
                              theme="filled"
                              style={{ color: "blue", fontSize: "12px" }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      key={`impg${props.nextFY}`}
                      width={125}
                      className="alignCenter"
                      render={(text, record) => {
                        return record.count_impg_2 !== 0 ? (
                          <Button
                            type="success"
                            onClick={() => props.showModal(record.sl_no_impg_2)}
                            style={{ width: "70px" }}
                          >
                            {record.count_impg_2}
                          </Button>
                        ) : (
                          "-"
                        );
                      }}
                    />
                  </ColumnGroup>
                )}               
                  
              </Table>
            </Col>
          </Row>
          <OTPForm
            wrappedComponentRef={props.saveFormRef}
            visible={props.requestOTP}
            onSubmit={props.handleOTPSubmit}
            onCancel={props.handleCancel}
          />
          {this.props.showModalPrompt && <ReconcileAdvanceModal {...props} />}
          {this.props.visibleMatchModal && (
            <ReconcileMatchedDetails {...props} />
          )}

          {this.props.visibleMatchModal_b2ba && (
            <Reconcile_B2BA_MatchedDetails {...props} />
          )}

          {this.props.visibleModal_b2ba && (
            <Reconcile_B2BA_MisMatchedDetails {...props} />
          )}

          {this.props.visibleOneModal_b2ba && (
            <Reconcile_B2BA_PurchaseOnlyDetails {...props} />
          )}

          {this.props.visibleTwoModal_b2ba && (
            <Reconcile_B2BA_GSTOnlyDetails {...props} />
          )} 

          {this.props.visibleLikelyModal_b2ba && (
            <Reconcile_B2BA_LikelyDetails {...props} />
          )}

          {this.props.visibleIneligibleModal_b2ba && (
            <Reconcile_B2BA_IneligibleDetails {...props} />
          )}

          {this.props.visibleModal && <ReconcileMisMatchedDetails {...props} />}
          {this.props.visibleOneModal && (
            <ReconcilePurchaseOnlyDetails {...props} />
          )}
          {this.props.visibleTwoModal && <ReconcileGSTOnlyDetails {...props} />}
          {this.props.visibleLikelyModal && (
            <ReconcileLikelyDetails {...props} />
          )}
          {this.props.visibleIneligibleModal && (
            <ReconcileIneligibleDetails {...props} />
          )}

          {this.props.showModalMail_PR && <ReconcilePurchaseEmail {...props} />}
          {this.props.showModalMail_Mismatch && (
            <ReconcileMismatchEmail {...props} />
          )}

          {this.props.visibleMatchModal_cdnr && (
            <ReconcileCDNRMatchedDetails {...props} />
          )}

          {this.props.visibleModal_cdnr && (
            <ReconcileCDNRMisMatchedDetails {...props} />
          )}
          {this.props.visibleOneModal_cdnr && (
            <ReconcileCDNRPurchaseOnlyDetails {...props} />
          )}
          {this.props.visibleTwoModal_cdnr && (
            <ReconcileCDNRGSTOnlyDetails {...props} />
          )}
          {this.props.visibleLikelyModal_cdnr && (
            <ReconcileCDNRLikelyDetails {...props} />
          )}
          {this.props.visibleIneligibleModal_cdnr && (
            <ReconcileCDNRIneligibleDetails {...props} />
          )}

          {this.props.visibleMatchModal_cdna && (
            <Reconcile_CDNA_MatchedDetails {...props} />
          )}

          {this.props.visibleModal_cdna && (
            <Reconcile_CDNA_MisMatchedDetails {...props} />
          )}
          {this.props.visibleOneModal_cdna && (
            <Reconcile_CDNA_PurchaseOnlyDetails {...props} />
          )}
          {this.props.visibleTwoModal_cdna && (
            <Reconcile_CDNA_GSTOnlyDetails {...props} />
          )}
          {this.props.visibleLikelyModal_cdna && (
            <Reconcile_CDNA_LikelyDetails {...props} />
          )}
          {this.props.visibleIneligibleModal_cdna && (
            <Reconcile_CDNA_IneligibleDetails {...props} />
          )}

          {this.props.showModalMail_PR_CDNR && (
            <ReconcileCDNRPurchaseEmail {...props} />
          )}
          {this.props.showModalMail_Mismatch_CDNR && (
            <ReconcileCDNRMismatchEmail {...props} />
          )}

          {this.props.visibleCP_Modal && <ViewSupplierData {...props} />}

          <script type="text/javascript" src="FileSaver.js" />
        </Content>
      </Root>
    );
  }
}

class ReconcileContainer extends React.Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year = curyear + "-" + nextyear;
    // var rod = 0;
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
        response_cdnr: null,
      };
    } else {
      this.gsp = {};
    }
    // this.downloadSales = this.downloadSales.bind(this);
    // this.takeGST = this.takeGST.bind(this);
    this.onDropPR = this.onDropPR.bind(this);
    this.onDropTallyPR = this.onDropTallyPR.bind(this);
    this.pullPurchaseDatafromExternal = this.pullPurchaseDatafromExternal.bind(
      this
    );
    // this.mailContentChange = this.mailContentChange.bind(this);
    // this.keepPending = this.keepPending.bind(this);
    // this.GkeepPending = this.GkeepPending.bind(this);
    // this.PkeepPending = this.PkeepPending.bind(this);
    this.mailReportDownload = this.mailReportDownload.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onMessageDrawer = this.onMessageDrawer.bind(this);
    this.sendWA = this.sendWA.bind(this);
    this.state = {
      currentYear: false,
      visible2a: false,

      visibleModal: null,
      visibleItemModal: false,
      visibleMatchModal: null,
      visibleMatchItemModal: null,
      visibleOneModal: null,
      visibleOneItemModal: null,
      visibleTwoModal: null,
      visibleTwoItemModal: null,
      visibleLikelyModal: null,
      visibleLikelyItemModal: null,
      visibleIneligibleModal: null,
      visibleIneligibleItemModal: null,
      visibleMatchModal_b2ba: null,
      visibleModal_b2ba: null,
      visibleOneModal_b2ba: null,
      visibleTwoModal_b2ba: null,
      visibleLikelyModal_b2ba: null,
      visibleIneligibleModal_b2ba: null,

      visibleModal_cdnr: null,
      visibleMatchModal_cdnr: null,
      visibleOneModal_cdnr: null,
      visibleTwoModal_cdnr: null,
      visibleLikelyModal_cdnr: null,
      visibleIneligibleModal_cdnr: null,
      visibleIneligibleItemModal_cdnr: null,
      visibleMatchModal_cdna: null,
      visibleModal_cdna: null,
      visibleOneModal_cdna: null,
      visibleTwoModal_cdna: null,
      visibleLikelyModal_cdna: null,
      visibleIneligibleModal_cdna: null,

      finMonth: props.finMonth,
      finYear: props.finYear,
      curFinMonth: n + 1,
      curFinYear: year,
      companyId: props.selectedCompany,
      output_b2b: [],
      m_id: 0,
      datasetForDetails: [],
      recon_mismatches_b2b: [],
      recon_mismatches_b2b_2: [],
      recon_matches_b2b: [],
      recon_matches_b2b_2: [],
      recon_matches_b2ba: [],
      recon_matches_b2ba_2: [],
      recon_mismatches_b2ba: [],
      recon_mismatches_b2ba_2: [],
      recon_one_b2ba: [],
      recon_onlypr_b2ba_2: [],
      recon_two_b2ba: [],
      recon_likely_b2ba: [],
      recon_likely_b2ba_2: [],
      recon_ineligible_b2ba: [],
      recon_ineligible_b2ba_2: [],
      recon_onlygst_b2ba_2: [],
      recon_one_b2b: [],
      recon_onlypr_b2b_2: [],
      recon_two_b2b: [],
      recon_onlygst_b2b_2: [],
      recon_likely_b2b: [],
      recon_likely_b2b_2: [],
      recon_ineligible_b2b: [],
      recon_ineligible_b2b_2: [],
      markedAsIneligible_cdnr: [],
      markedAsIneligible_cdnr_2: [],
      output_cdnr: [],
      recon_mismatches_cdnr: [],
      recon_mismatches_cdnr_2: [],
      recon_matches_cdnr: [],
      recon_matches_cdnr_2: [],
      recon_matches_cdna: [],
      recon_matches_cdna_2: [],
      recon_mismatches_cdna: [],
      recon_mismatches_cdna_2: [],
      recon_one_cdnr: [],
      recon_one_cdnr_2: [],
      recon_two_cdnr: [],
      recon_two_cdnr_2: [],
      recon_likely_cdnr: [],
      recon_likely_cdnr_2: [],
      recon_one_cdna: [],
      recon_one_cdna_2: [],
      recon_two_cdna: [],
      recon_two_cdna_2: [],
      recon_likely_cdna: [],
      recon_likely_cdna_2: [],
      downloadLoading: false,
      resetLoading: false,
      takePurchase: null,
      recon_loading: false,
      sales_loading: false,
      gst_loading: false,
      pending_load: false,
      requestOTP: false,
      pull2A: false,
      pull2B: false,
      otp: null,
      fromPeriod: null,
      toPeriod: null,
      fromPullData: null,
      searchText: "",
      run: false,
      fromReconcile: null,
      visible_notifyViaEmail: null,
      itcCounter_IGST: 0,
      itcCounter_CGST: 0,
      itcCounter_SGST: 0,
      itcCounter_CESS: 0,
      twoA_FinYear_Dependent_Value: 0,
      twoA_PrevFinYear_Dependent_Value: 0,
      threeB_FinYear_Dependent_Value: 0,
      threeB_Total_Value: 0,
      threeB_PrevFinYear_Dependent_Value: 0,
      prevYearTitle: "",
      currentYearTitle: "",
      prevFinYearThreeB: 0,
      currentFinYearThreeB: 0,
      balanceValues_PrevYear: 0,
      balanceValues_CurrentYear: 0,
      selectedRowKeys: [],
      noCreditTakenPrevYear: 0,
      RIC_Load: true,
      popupConfirmReset: false,
      popupConfirmReset_CP: false,
      checkedList_b2b: defaultCheckedList,
      indeterminate_b2b: true,
      checkAll_b2b: false,
      checkedList_cdnr: defaultCheckedList,
      indeterminate_cdnr: true,
      checkAll_cdnr: false,
      showModalPrompt: false,
      showModalMail_Mismatch: false,
      showModalMail_PR: false,
      showModalMail_Mismatch_CDNR: false,
      showModalMail_PR_CDNR: false,
      showModalMail_likely: false,
      roundoffState: 0,
      disableRoundOffInput: true,
      showB2B: true,
      showCDNR: true, 
      showIMPG: true,
      mailText: "",
      mailPayload: null,
      invoiceTags: [],
      emailTags: [],
      inputVisibleEmail: false,
      inputValueEmail: "",
      CP_DATA: null,
      visibleCP_Modal: false,
      recon_year: props.finYear,
      recon_from_month: props.finMonth.toString(),
      recon_to_month: props.finMonth.toString(),
      retInProgressData: [],
      retInProgress: false,
      isDrawerVisible: false,
    };
    comp_id = props.selectedCompany;
  }

  //email

  handleCloseEmail = (removedTag) => {
    const tags = this.state.emailTags.filter((tag) => tag !== removedTag);
    console.log(tags);
    this.setState({ emailTags: tags });
  };

  showInputEmail = () => {
    this.setState({ inputVisibleEmail: true }, () => this.inputEmail.focus());
  };

  handleInputChangeEmail = (e) => {
    if (
      e.target.value.substr(-1) === "," ||
      e.target.value.substr(-1) === ";"
    ) {
      this.handleInputConfirmEmail();
    }
    this.setState({ inputValueEmail: e.target.value });
  };

  sendWA = (m) => {
    console.log(m);
    var a = document.createElement("a");
    a.target = "_blank";
    const message = m.supplier_invoicenumber
      ? "Invoice Number " +
      m.supplier_invoicenumber +
      " is not matching with our records."
      : "Upon Reconciliation, we found that invoice number " +
      m.invoice_number +
      " is not filed. Kindly file!";
    a.href = "https://wa.me/91" + m.phone + "?text=" + message;
    a.click();
  };
  handleInputConfirmEmail = () => {
    const state = this.state;
    const inputValue = state.inputValueEmail;
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    let tags = state.emailTags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      if (reg.test(inputValue) === false) {
        message.warning("Invalid Email Address");
      } else {
        tags = [...tags, inputValue];
      }
    }
    this.setState({
      emailTags: tags,
      inputVisibleEmail: false,
      inputValueEmail: "",
    });
    // }
  };

  saveInputRefEmail = (input) => (this.inputEmail = input);

  reconcilePrompt = () => {
    this.setState({
      showModalPrompt: true,
    });
  };

  onCheckSelect2Aor2B = (e) => {
    this.setState({
      IsSelectReconcile2Aor2B: e.target.value
    });
  };

  onChangeCB_b2b = (checkedList_b2b) => {
    this.setState({
      checkedList_b2b,
      indeterminate_b2b:
        !!checkedList_b2b.length &&
        checkedList_b2b.length < plainOptions_b2b.length,
      checkAll_b2b: checkedList_b2b.length === plainOptions_b2b.length,
    });
  };

  onCheckAllChange_b2b = (e) => {
    this.setState({
      checkedList_b2b: e.target.checked ? plainOptions_b2b : [],
      indeterminate_b2b: false,
      checkAll_b2b: e.target.checked,
    });
  };

  onChangeCB_cdnr = (checkedList_cdnr) => {
    this.setState({
      checkedList_cdnr,
      indeterminate_cdnr:
        !!checkedList_cdnr.length &&
        checkedList_cdnr.length < plainOptions_cdnr.length,
      checkAll_cdnr: checkedList_cdnr.length === plainOptions_cdnr.length,
    });
  };

  onCheckAllChange_cdnr = (e) => {
    this.setState({
      checkedList_cdnr: e.target.checked ? plainOptions_cdnr : [],
      indeterminate_cdnr: false,
      checkAll_cdnr: e.target.checked,
    });
  };

  onRoundOffChange = (e) => {
    if (e.target.value === "n") {
      this.setState({
        roundoffState: e.target.value,
        disableRoundOffInput: false,
      });
    } else {
      this.setState({
        roundoffState: e.target.value,
        disableRoundOffInput: true,
      });
    }
  };

  handleOkPrompt = (e) => {
    // console.log(e);
    this.setState({
      showModalPrompt: false,
    });
  };

  handleCancelPrompt = (e) => {
    // console.log(e);
    this.setState({
      showModalPrompt: false,
    });
    //  this.setState(state => ({ places: removeElement(state.places, index) }));
  };

  handleCancelEmail = (e) => {
    // console.log(e);

    if (this.state.visibleModal || this.state.visibleModal_b2ba) {
      this.setState({
        showModalMail_Mismatch: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleOneModal || this.state.visibleOneModal_cdnr) {
      this.setState({
        showModalMail_PR: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleModal_cdnr || this.state.visibleModal_cdna) {
      this.setState({
        showModalMail_Mismatch_CDNR: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleOneModal_cdnr) {
      this.setState({
        showModalMail_PR_CDNR: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleLikelyModal) {
      this.setState({
        showModalMail_likely: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
  };

  handleSearch = (selectedKeys, confirm) => () => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => () => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidUpdate = () => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = (companyDetails) => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  periodArrayValues = (a, b) => {
    var dateStart = moment(a, "MMYYYY");
    var dateEnd = moment(b, "MMYYYY");
    // console.log("dateStart :" + dateStart + "---" + "dateEnd :" + dateEnd);
    var period = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("M") === dateEnd.format("M")
    ) {
      period.push(dateStart.format("MMYYYY"));
      dateStart.add(1, "month");
    }
    // console.log(period);
    return period;
  };

  callbackForRetInProgress = async (reqId, reqData, type) => {
    message.loading("Fetching B2B data from GSTN...", 0);
    reqData.reqId = reqId;
    const gspResponse = await gspAuth.getCounterPartyData(reqData, type);
    message.destroy();
    if (gspResponse.data.success) {
      notification.open({
        key: reqId,
        message: "Success",
        duration: 0,
        description:
          "Supplier B2B Invoices fetched successfully!! Now you can reconcile",
        icon: <Icon type="check-circle" style={{ color: "#008000" }} />,
      });
    } else {
      notification.error({
        key: reqId,
        duration: 0,
        message: "Failure",
        description: "Couldn't fetch Invoices!! Please try again later.",
      });
    }
  };

  getCounterPartyData = async (fromPeriod, toPeriod) => {
    // alert("fromPeriod");
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const periodArrayValuesfor2A = this.periodArrayValues(fromPeriod, toPeriod);

    // console.log(periodArrayValuesfor2A);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
    };

    // console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({
          requestOTP: true,
          fromPeriod: fromPeriod,
          toGetData: "2A",
          toPeriod: toPeriod,
          fromPullData: true,
          fromReconcile: null,
        });
      } else if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.indexOf("Under Maintenance") >= 0
      ) {
        message.destroy();
        message.error("Government API is under maintenance");
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      try {
        message.destroy();
        message.loading("Fetching B2B Invoices..", 0);
        const gstr2A_B2B_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.response.gspAuth.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_b2b = await gspAuth.getCounterPartyData(
              reqData,
              "B2B"
            );
            if (
              this.gsp.response_b2b.status &&
              get("data.data.errorCode", this.gsp.response_b2b) ===
              "RETINPROGRESS"
            ) {
              const reqId = get("data.data.reqId", this.gsp.response_b2b);
              message.destroy();
              notification.info({
                key: reqId,
                message: "Information",
                duration: 0,
                description:
                  "GSTN is preparing the requested supplier data, we will auto fetch the details again in " +
                  (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                    1) +
                  " minutes",
              });
              setTimeout(
                this.callbackForRetInProgress.bind(this, reqId, reqData, "B2B"),
                (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                  1) *
                60 *
                1000
              );
            }
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "B2B Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
      try {
        message.destroy();
        message.loading("Fetching Credit / Debit Notes..", 0);
        const gstr2A_CDNR_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_cdnr = await gspAuth.getCounterPartyData(
              reqData,
              "CDN"
            );
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "Credit / Debit Note Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
    }
    if (this.gsp.response.data.success || this.gsp.response.success) {
      try {
        message.destroy();
        // console.log(this.gsp.response)
        message.loading("Fetching Amended B2B Invoices..", 0);
        const gstr2A_B2BA_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.response.gspAuth.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_b2ba = await gspAuth.getCounterPartyData(
              reqData,
              "B2BA"
            );
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        console.log(e);
        alert(e);
        message.destroy();
        notification.destroy();
        notification.open({
          message: "B2B Amendments Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }

      try {
        message.destroy();
        message.loading("Fetching Amended Credit / Debit Notes..", 0);
        const gstr2A_CDNA_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_cdna = await gspAuth.getCounterPartyData(
              reqData,
              "CDNA"
            );
            if (this.gsp.response_cdna.status === true) {
              message.destroy();
              notification.open({
                message: "Success",
                description: "GSTR2A fetched successfully!!",
                duration: 30,
                icon: <Icon type="check-circle" style={{ color: "#008000" }} />,
              });
            }
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "Credit / Debit Note Amendments Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
    }
  };

  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      run: true,
    });
  };


  compareSales = async (id) => {
    if (this.state.IsSelectReconcile2Aor2B === null || this.state.IsSelectReconcile2Aor2B === undefined) {
      message.warning("Select atleast one field in 2A or 2B to reconcile!");
    } else if (
      this.state.checkedList_b2b.length === 0 &&
      this.state.checkedList_cdnr.length === 0
    ) {
      message.warning("Select atleast one field in B2B or CDNR to reconcile!");
    } else {
      console.log(this.state.IsSelectReconcile2Aor2B);
      if (this.state.checkedList_b2b.length === 0) {
        this.setState({
          showB2B: false,
          showCDNR: true,
        });
        // this.forceUpdate();
      }

      if (this.state.checkedList_cdnr.length === 0) {
        this.setState({
          showB2B: true,
          showCDNR: false,
        });
        // this.forceUpdate();
      }

      if (
        this.state.checkedList_b2b.length !== 0 &&
        this.state.checkedList_cdnr.length !== 0
      ) {
        this.setState({
          showB2B: true,
          showCDNR: true,
        });
      }
      var downloaddate = this.state.finMonth + "/" + this.state.finYear;
      if (this.state.roundoffState === "n") {
        this.rod = document.getElementById("roundOffInput").value;
        if (
          this.rod === "" ||
          this.rod === undefined ||
          parseInt(this.rod) > parseInt(99999999999999) ||
          parseInt(this.rod) < 0
        ) {
          return message.warning("Enter valid value to Roundoff!");
        }
        if (typeof this.rod === "number" && this.rod % 1 === 0) {
          return message.warning("Enter valid value to Roundoff!");
        }
      } else {
        this.rod = this.state.roundoffState;
      }
      this.setState({
        showModalPrompt: false,
      });
      notification.destroy();

      if (this.state.IsSelectReconcile2Aor2B === 1) {

        message.loading("Reconciling 2A...", 0);

console.log(`${BASE_URL}/export/compareSales?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}&rod=${this.rod}&feildsToRecon_b2b=${this.state.checkedList_b2b}&feildsToRecon_cdnr=${this.state.checkedList_cdnr}&fn=match`)

        //2A
        fetch(
          `${BASE_URL}/export/compareSales?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}&rod=${this.rod}&feildsToRecon_b2b=${this.state.checkedList_b2b}&feildsToRecon_cdnr=${this.state.checkedList_cdnr}&fn=match`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then(async (result) => {
            console.log(result.final.MATCHES_b2b);
            this.setState(
              {
                finYear: result.final.finYear,
                nextFY: result.final.nextFY,
                output_b2b: result.final.Summary_B2B, 

                recon_matches_b2b: result.final.MATCHES_b2b,
                recon_mismatches_b2b: result.final.MISMATCHES_b2b,
                recon_one_b2b: result.final.PR_b2b,
                recon_two_b2b: result.final.RECON_PR_b2b,
                recon_likely_b2b: result.final.LIKELY_b2b,
                recon_ineligible_b2b: result.final.markedAsIneligible_b2b,

                recon_matches_b2b_2: result.final.MATCHES_b2b_2,
                recon_mismatches_b2b_2: result.final.MISMATCHES_b2b_2,
                recon_onlypr_b2b_2: result.final.PR_b2b_2,
                recon_onlygst_b2b_2: result.final.RECON_PR_b2b_2,
                recon_likely_b2b_2: result.final.LIKELY_b2b_2,
                recon_ineligible_b2b_2: result.final.markedAsIneligible_b2b_2,

                recon_matches_cdnr: result.final.MATCHES_cdnr,
                recon_mismatches_cdnr: result.final.MISMATCHES_cdnr,
                recon_one_cdnr: result.final.PR_cdnr,
                recon_two_cdnr: result.final.RECON_PR_cdnr,
                recon_likely_cdnr: result.final.LIKELY_cdnr,
                recon_ineligible_cdnr: result.final.markedAsIneligible_cdnr,

                recon_matches_cdnr_2: result.final.MATCHES_cdnr_2,
                recon_mismatches_cdnr_2: result.final.MISMATCHES_cdnr_2,
                recon_one_cdnr_2: result.final.PR_cdnr_2,
                recon_two_cdnr_2: result.final.RECON_PR_cdnr_2,
                recon_likely_cdnr_2: result.final.LIKELY_cdnr_2,
                recon_ineligible_cdnr_2: result.final.markedAsIneligible_cdnr_2,

                recon_matches_b2ba: result.final.MATCHES_b2ba,
                recon_mismatches_b2ba: result.final.MISMATCHES_b2ba,
                recon_onlygst_b2ba: result.final.RECON_PR_b2ba,

                recon_matches_b2ba_2: result.final.MATCHES_b2ba_2,
                recon_mismatches_b2ba_2: result.final.MISMATCHES_b2ba_2,
                recon_onlygst_b2ba_2: result.final.RECON_PR_b2ba_2,

                recon_matches_cdna: result.final.MATCHES_cdna,
                recon_mismatches_cdna: result.final.MISMATCHES_cdna,
                recon_two_cdna: result.final.RECON_PR_cdna,

                recon_matches_cdna_2: result.final.MATCHES_cdna_2,
                recon_mismatches_cdna_2: result.final.MISMATCHES_cdna_2,
                recon_two_cdna_2: result.final.RECON_PR_cdna_2,

                recon_loading: false,
              },
              () => {
                console.log(":::::: AFTER RECONCILE :::::::::");
                this.showModal(id);
              }
            );
            if (document.getElementsByClassName("ant-table-wrapper")[0]) {
              document.getElementsByClassName(
                "ant-table-wrapper"
              )[0].style.display = "block";
            }
            message.destroy();
            message.success("Reconciled");
            this.props.data.refetch();
          });
        // await this.calcFY2Avs3B();
      } else {
        message.loading("Reconciling 2B...", 0);
        message.destroy();
 
        fetch(
          `${BASE_URL}/export/compareSales2B?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}&rod=${this.rod}&feildsToRecon_b2b=${this.state.checkedList_b2b}&feildsToRecon_cdnr=${this.state.checkedList_cdnr}&fn=match`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then(async (result) => {
            console.log(result);
            this.setState(
              {
                finYear: result.final.finYear,
                nextFY: result.final.nextFY,
                output_b2b: result.final.Summary_B2B,

                recon_matches_b2b: result.final.MATCHES_b2b,
                recon_mismatches_b2b: result.final.MISMATCHES_b2b,
                recon_one_b2b: result.final.PR_b2b,
                recon_two_b2b: result.final.RECON_PR_b2b,
                recon_likely_b2b: result.final.LIKELY_b2b,
                recon_ineligible_b2b: result.final.markedAsIneligible_b2b,

                recon_matches_b2b_2: result.final.MATCHES_b2b_2,
                recon_mismatches_b2b_2: result.final.MISMATCHES_b2b_2,
                recon_onlypr_b2b_2: result.final.PR_b2b_2,
                recon_onlygst_b2b_2: result.final.RECON_PR_b2b_2,
                recon_likely_b2b_2: result.final.LIKELY_b2b_2,
                recon_ineligible_b2b_2: result.final.markedAsIneligible_b2b_2,

                recon_matches_cdnr: result.final.MATCHES_cdnr,
                recon_mismatches_cdnr: result.final.MISMATCHES_cdnr,
                recon_one_cdnr: result.final.PR_cdnr,
                recon_two_cdnr: result.final.RECON_PR_cdnr,
                recon_likely_cdnr: result.final.LIKELY_cdnr,
                recon_ineligible_cdnr: result.final.markedAsIneligible_cdnr,

                recon_matches_cdnr_2: result.final.MATCHES_cdnr_2,
                recon_mismatches_cdnr_2: result.final.MISMATCHES_cdnr_2,
                recon_one_cdnr_2: result.final.PR_cdnr_2,
                recon_two_cdnr_2: result.final.RECON_PR_cdnr_2,
                recon_likely_cdnr_2: result.final.LIKELY_cdnr_2,
                recon_ineligible_cdnr_2: result.final.markedAsIneligible_cdnr_2,

                recon_matches_b2ba: result.final.MATCHES_b2ba,
                recon_mismatches_b2ba: result.final.MISMATCHES_b2ba,
                recon_onlygst_b2ba: result.final.RECON_PR_b2ba,

                recon_matches_b2ba_2: result.final.MATCHES_b2ba_2,
                recon_mismatches_b2ba_2: result.final.MISMATCHES_b2ba_2,
                recon_onlygst_b2ba_2: result.final.RECON_PR_b2ba_2,

                recon_matches_cdna: result.final.MATCHES_cdna,
                recon_mismatches_cdna: result.final.MISMATCHES_cdna,
                recon_two_cdna: result.final.RECON_PR_cdna,

                recon_matches_cdna_2: result.final.MATCHES_cdna_2,
                recon_mismatches_cdna_2: result.final.MISMATCHES_cdna_2,
                recon_two_cdna_2: result.final.RECON_PR_cdna_2,

                recon_loading: false,
              },
              () => {
                console.log(":::::: AFTER RECONCILE :::::::::");
                this.showModal(id);
              }
            );
            if (document.getElementsByClassName("ant-table-wrapper")[0]) {
              document.getElementsByClassName(
                "ant-table-wrapper"
              )[0].style.display = "block";
            }
            message.destroy();
            message.success("Reconciled");
            this.props.data.refetch();
          });
      }
    }
  };

  markAsIneligibleLikely = async () => {
    if (this.state.selectedRowKeys.length !== 0) {
      message.destroy();
      message.loading("Marking records as Ineligible....", 0);

      if (this.state.visibleLikelyModal) {
        if (this.state.currentYear) {
          var idARRAY_Ineligible = [];

          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_likely_b2b
            );
            if (findINDEX !== -1) {
              await idARRAY_Ineligible.push(
                this.state.recon_likely_b2b[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          const likelyData = map((d) => {
            if (d.type === "prb2b") {
              return {
                ...d,
                items: undefined,
                pr_b2b_items: filter({ item_type: "PRB2B" })(d.items),
              };
            }
            if (d.type === "reconprb2b") {
              return {
                ...d,
                items: undefined,
                recon_pr_b2b_items: filter({ item_type: "RECONPRB2B" })(
                  d.items
                ),
              };
            }
          })(idARRAY_Ineligible);

          if (idARRAY_Ineligible) {
            await this.updateIneligibleDB(likelyData, "R_MI");
          }
        } else {
          var idARRAY_Ineligible = [];

          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_likely_b2b_2
            );
            if (findINDEX !== -1) {
              await idARRAY_Ineligible.push(
                this.state.recon_likely_b2b_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          const likelyData = map((d) => {
            if (d.type === "prb2b") {
              return {
                ...d,
                items: undefined,
                pr_b2b_items: filter({ item_type: "PRB2B" })(d.items),
              };
            }
            if (d.type === "reconprb2b") {
              return {
                ...d,
                items: undefined,
                recon_pr_b2b_items: filter({ item_type: "RECONPRB2B" })(
                  d.items
                ),
              };
            }
          })(idARRAY_Ineligible);

          if (idARRAY_Ineligible) {
            await this.updateIneligibleDB(likelyData, "R_MI");
          }
        }

        message.destroy();
        message.success(
          "Selected invoice(s) have been made ineligible and reconciling now...!"
        );
        this.setState({ visibleLikelyModal: null, selectedRowKeys: [] });
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
      }

      if (this.state.visibleLikelyModal_cdnr) {
        if (this.state.currentYear) {
          var idARRAY_Ineligible = [];
          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_likely_cdnr
            );
            if (findINDEX !== -1) {
              await idARRAY_Ineligible.push(
                this.state.recon_likely_cdnr[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          const likelyData = map((d) => {
            if (d.type === "prcdnr") {
              return {
                ...d,
                items: undefined,
                pr_cdnr_items: filter({ item_type: "PRCDNR" })(d.items),
              };
            }
            if (d.type === "reconprcdnr") {
              return {
                ...d,
                items: undefined,
                recon_pr_cdnr_items: filter({ item_type: "RECONPRCDNR" })(
                  d.items
                ),
              };
            }
          })(idARRAY_Ineligible);

          if (idARRAY_Ineligible) {
            await this.updateIneligibleDB(likelyData, "R_MI_CDNR");
          }
        } else {
          var idARRAY_Ineligible = [];
          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_likely_cdnr_2
            );
            if (findINDEX !== -1) {
              await idARRAY_Ineligible.push(
                this.state.recon_likely_cdnr_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          const likelyData = map((d) => {
            if (d.type === "prcdnr") {
              return {
                ...d,
                items: undefined,
                pr_cdnr_items: filter({ item_type: "PRCDNR" })(d.items),
              };
            }
            if (d.type === "reconprcdnr") {
              return {
                ...d,
                items: undefined,
                recon_pr_cdnr_items: filter({ item_type: "RECONPRCDNR" })(
                  d.items
                ),
              };
            }
          })(idARRAY_Ineligible);

          if (idARRAY_Ineligible) {
            await this.updateIneligibleDB(likelyData, "R_MI_CDNR");
          }
        }

        message.destroy();
        message.success(
          "Selected note(s) have been made ineligible and reconciling now...!"
        );
        this.setState({ visibleLikelyModal_cdnr: null, selectedRowKeys: [] });
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
      }
    } else {
      message.error(
        "Please select atleast one PR/GSTN record to mark it as ineligible!!"
      );
    }
  };

  handleMismatchIneligible = async () => {
    if (this.state.selectedRowKeys.length !== 0) {
      message.destroy();
      message.loading("Marking records as Ineligible....", 0);

      if (this.state.visibleModal) {
        if (this.state.currentYear) {
          var idARRAY_Ineligible = [];

          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_mismatches_b2b
            );
            if (findINDEX !== -1) {
              map((d) => idARRAY_Ineligible.push(d))(
                filter({
                  invoice_number: this.state.recon_mismatches_b2b[findINDEX][
                    "invoice_number"
                  ],
                  supplier_gstin: this.state.recon_mismatches_b2b[findINDEX][
                    "supplier_gstin"
                  ],
                })(this.state.recon_mismatches_b2b)
              );
            }
          })(this.state.selectedRowKeys);

          const changeRecords = flow(
            filter((g) => {
              return (
                findIndex(
                  (pr) =>
                    pr.invoice_number === g.invoice_number &&
                    pr.supplier_gstin === g.supplier_gstin,
                  idARRAY_Ineligible
                ) === -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_mismatches_b2b);
          const mismatchData = map((d) => {
            return {
              ...d,
              items: undefined,
              pr_b2b_items: filter({ item_type: "PRB2B" })(d.items),
            };
          })(filter({ type: "prb2b" })(idARRAY_Ineligible));

          await this.updateIneligibleDB(mismatchData, "R_MI");

          this.setState({
            recon_mismatches_b2b: changeRecords,
            selectedRowKeys: [],
            visibleModal: null,
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              mismatchData
            ),
          });
        } else {
          var idARRAY_Ineligible = [];

          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_mismatches_b2b_2
            );
            if (findINDEX !== -1) {
              map((d) => idARRAY_Ineligible.push(d))(
                filter({
                  invoice_number: this.state.recon_mismatches_b2b_2[findINDEX][
                    "invoice_number"
                  ],
                  supplier_gstin: this.state.recon_mismatches_b2b_2[findINDEX][
                    "supplier_gstin"
                  ],
                })(this.state.recon_mismatches_b2b_2)
              );
            }
          })(this.state.selectedRowKeys);

          const changeRecords = flow(
            filter((g) => {
              return (
                findIndex(
                  (pr) =>
                    pr.invoice_number === g.invoice_number &&
                    pr.supplier_gstin === g.supplier_gstin,
                  idARRAY_Ineligible
                ) === -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_mismatches_b2b_2);
          const mismatchData = map((d) => {
            return {
              ...d,
              items: undefined,
              pr_b2b_items: filter({ item_type: "PRB2B" })(d.items),
            };
          })(filter({ type: "prb2b" })(idARRAY_Ineligible));

          await this.updateIneligibleDB(mismatchData, "R_MI");

          this.setState({
            recon_mismatches_b2b_2: changeRecords,
            selectedRowKeys: [],
            visibleModal: null,
            recon_ineligible_b2b_2: this.state.recon_ineligible_b2b_2.concat(
              mismatchData
            ),
          });
        }

        message.destroy();
        message.success("Selected invoice(s) have been made ineligible!");
        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }

      if (this.state.visibleModal_cdnr) {
        if (this.state.currentYear) {
          var idARRAY_Ineligible = [];

          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_mismatches_cdnr
            );
            if (findINDEX !== -1) {
              map((d) => idARRAY_Ineligible.push(d))(
                filter({
                  invoice_number: this.state.recon_mismatches_cdnr[findINDEX][
                    "invoice_number"
                  ],
                  supplier_gstin: this.state.recon_mismatches_cdnr[findINDEX][
                    "supplier_gstin"
                  ],
                })(this.state.recon_mismatches_cdnr)
              );
            }
          })(this.state.selectedRowKeys);

          const changeRecords = flow(
            filter((g) => {
              return (
                findIndex(
                  (pr) =>
                    pr.invoice_number === g.invoice_number &&
                    pr.supplier_gstin === g.supplier_gstin,
                  idARRAY_Ineligible
                ) === -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_mismatches_cdnr);

          const mismatchData = map((d) => {
            return {
              ...d,
              items: undefined,
              pr_cdnr_items: filter({ item_type: "PRCDNR" })(d.items),
            };
          })(filter({ type: "prcdnr" })(idARRAY_Ineligible));

          await this.updateIneligibleDB(mismatchData, "R_MI_CDNR");
          this.setState({
            recon_mismatches_cdnr: changeRecords,
            selectedRowKeys: [],
            visibleModal_cdnr: null,
            recon_ineligible_cdnr: this.state.recon_ineligible_cdnr.concat(
              mismatchData
            ),
          });
        } else {
          var idARRAY_Ineligible = [];

          const mapRecords = map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_mismatches_cdnr_2
            );
            if (findINDEX !== -1) {
              map((d) => idARRAY_Ineligible.push(d))(
                filter({
                  invoice_number: this.state.recon_mismatches_cdnr_2[findINDEX][
                    "invoice_number"
                  ],
                  supplier_gstin: this.state.recon_mismatches_cdnr_2[findINDEX][
                    "supplier_gstin"
                  ],
                })(this.state.recon_mismatches_cdnr_2)
              );
            }
          })(this.state.selectedRowKeys);

          const changeRecords = flow(
            filter((g) => {
              return (
                findIndex(
                  (pr) =>
                    pr.invoice_number === g.invoice_number &&
                    pr.supplier_gstin === g.supplier_gstin,
                  idARRAY_Ineligible
                ) === -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_mismatches_cdnr_2);

          const mismatchData = map((d) => {
            return {
              ...d,
              items: undefined,
              pr_cdnr_items: filter({ item_type: "PRCDNR" })(d.items),
            };
          })(filter({ type: "prcdnr" })(idARRAY_Ineligible));

          await this.updateIneligibleDB(mismatchData, "R_MI_CDNR");
          this.setState({
            recon_mismatches_cdnr_2: changeRecords,
            selectedRowKeys: [],
            visibleModal_cdnr: null,
            recon_ineligible_cdnr_2: this.state.recon_ineligible_cdnr_2.concat(
              mismatchData
            ),
          });
        }
        message.destroy();
        message.success("Selected note(s) have been made ineligible!");
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
      }
    } else {
      message.error(
        "Please select atleast one mismatched record to mark it as ineligible!!"
      );
    }
  };

  updateIneligibleDB = (record, action) => {
    var downloaddate = this.state.finMonth + "/" + this.state.finYear;

    return fetch(
      `${BASE_URL}/export/ineligibleUpdateDB?companyId=${this.state.companyId}&downloaddate=${downloaddate}&action=${action}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record), 
      }
    );
  };

  markAsIneligible = async () => {
    console.log(this.state.selectedRowKeys);
    if (this.state.selectedRowKeys.length !== 0) {
      message.destroy();
      message.loading("Marking records as Ineligible....", 0);

      var ineligibleArray = [];
      var eligibleArray = [];
      var mapRecords;
      var changeRecords;

      if (this.state.visibleTwoModal) {
        if (this.state.currentYear) {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_two_b2b
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(this.state.recon_two_b2b[findINDEX]);
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_two_b2b);

          const makeIneligible = await this.updateIneligibleDB(
            ineligibleArray,
            "R_MI"
          ); 

          this.setState({
            recon_two_b2b: changeRecords,
            selectedRowKeys: [],
            visibleTwoModal: null,
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              ineligibleArray
            ),
          });
        } else {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_onlygst_b2b_2
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_onlygst_b2b_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_onlygst_b2b_2);

          const makeIneligible = await this.updateIneligibleDB(
            ineligibleArray,
            "R_MI"
          );
          this.setState({
            recon_onlygst_b2b_2: changeRecords,
            selectedRowKeys: [],
            visibleTwoModal: null,
            recon_ineligible_b2b_2: this.state.recon_ineligible_b2b_2.concat(
              ineligibleArray
            ),
          });
        }
        message.destroy();
        message.success("Selected invoice(s) have been made ineligible!");
        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }

      if (this.state.visibleTwoModal_cdnr) {
        if (this.state.currentYear) {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_two_cdnr
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(this.state.recon_two_cdnr[findINDEX]);
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_two_cdnr);

          const makeIneligible = await this.updateIneligibleDB(
            ineligibleArray,
            "R_MI_CDNR"
          );
          this.setState({
            recon_two_cdnr: changeRecords,
            selectedRowKeys: [],
            visibleTwoModal_cdnr: null,
            recon_ineligible_cdnr: this.state.recon_ineligible_cdnr.concat(
              ineligibleArray
            ),
          });
        } else {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_two_cdnr_2
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_two_cdnr_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_two_cdnr_2);

          const makeIneligible = await this.updateIneligibleDB(
            ineligibleArray,
            "R_MI_CDNR"
          );
          this.setState({
            recon_two_cdnr_2: changeRecords,
            selectedRowKeys: [],
            visibleTwoModal_cdnr: null,
            recon_ineligible_cdnr_2: this.state.recon_ineligible_cdnr_2.concat(
              ineligibleArray
            ),
          });
        }

        message.destroy();

        message.success("Selected note(s) have been made ineligible!");

        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }

      if (this.state.visibleMatchModal) {
        if (this.state.currentYear) {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_matches_b2b
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_matches_b2b[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);
          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_matches_b2b);

          await this.updateIneligibleDB(ineligibleArray, "R_MI");
          this.setState({
            recon_matches_b2b: changeRecords,
            selectedRowKeys: [],
            visibleMatchModal: null,
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              ineligibleArray
            ),
          });
        } else {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_matches_b2b_2
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_matches_b2b_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);
          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_matches_b2b_2);

          await this.updateIneligibleDB(ineligibleArray, "R_MI");
          this.setState({
            recon_matches_b2b_2: changeRecords,
            selectedRowKeys: [],
            visibleMatchModal: null,
            recon_ineligible_b2b_2: this.state.recon_ineligible_b2b_2.concat(
              ineligibleArray
            ),
          });
        }
        message.destroy();
        message.success("Selected invoice(s) have been made ineligible!");
        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }

      if (this.state.visibleMatchModal_cdnr) {
        if (this.state.currentYear) {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_matches_cdnr
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_matches_cdnr[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_matches_cdnr);

          await this.updateIneligibleDB(ineligibleArray, "R_MI_CDNR");
          this.setState({
            recon_matches_cdnr: changeRecords,
            selectedRowKeys: [],
            visibleMatchModal_cdnr: null,
            recon_ineligible_cdnr: this.state.recon_ineligible_cdnr.concat(
              ineligibleArray
            ),
          });
        } else {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_matches_cdnr_2
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_matches_cdnr_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_matches_cdnr_2);

          await this.updateIneligibleDB(ineligibleArray, "R_MI_CDNR");
          this.setState({
            recon_matches_cdnr_2: changeRecords,
            selectedRowKeys: [],
            visibleMatchModal_cdnr: null,
            recon_ineligible_cdnr_2: this.state.recon_ineligible_cdnr_2.concat(
              ineligibleArray
            ),
          });
        }

        message.destroy();
        message.success("Selected note(s) have been made ineligible!");
        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }

      if (this.state.visibleOneModal) {
        if (this.state.currentYear) {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_one_b2b
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(this.state.recon_one_b2b[findINDEX]);
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_one_b2b);

          await this.updateIneligibleDB(ineligibleArray, "R_MI");
          this.setState({
            recon_one_b2b: changeRecords,
            selectedRowKeys: [],
            visibleOneModal: null,
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              ineligibleArray
            ),
          });
        } else {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_onlypr_b2b_2
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_onlypr_b2b_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_onlypr_b2b_2);

          await this.updateIneligibleDB(ineligibleArray, "R_MI");
          this.setState({
            recon_onlypr_b2b_2: changeRecords,
            selectedRowKeys: [],
            visibleOneModal: null,
            recon_ineligible_b2b_2: this.state.recon_ineligible_b2b_2.concat(
              ineligibleArray
            ),
          });
        }

        message.destroy();
        message.success("Selected invoice(s) have been made ineligible!");

        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }

      if (this.state.visibleOneModal_cdnr) {
        if (this.state.currentYear) {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_one_cdnr
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(this.state.recon_one_cdnr[findINDEX]);
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_one_cdnr);

          await this.updateIneligibleDB(ineligibleArray, "R_MI_CDNR");
          this.setState({
            recon_one_cdnr: changeRecords,
            selectedRowKeys: [],
            visibleOneModal_cdnr: null,
            recon_ineligible_cdnr: this.state.recon_ineligible_cdnr.concat(
              ineligibleArray
            ),
          });
        } else {
          mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_one_cdnr_2
            );
            if (findINDEX !== -1) {
              await ineligibleArray.push(
                this.state.recon_one_cdnr_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          changeRecords = flow(
            filter((g) => {
              return (
                findIndex((pr) => pr === g.id, this.state.selectedRowKeys) ===
                -1
              );
            }),
            map((g) => {
              return {
                ...g,
              };
            })
          )(this.state.recon_one_cdnr_2);

          await this.updateIneligibleDB(ineligibleArray, "R_MI_CDNR");
          this.setState({
            recon_one_cdnr_2: changeRecords,
            selectedRowKeys: [],
            visibleOneModal_cdnr: null,
            recon_ineligible_cdnr_2: this.state.recon_ineligible_cdnr_2.concat(
              ineligibleArray
            ),
          });
        }
        message.destroy();
        message.success("Selected note(s) have been made ineligible!");
        // return this.forceUpdate();
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";
        this.compareSales();
        return true;
      }
    } else {
      message.error(
        "Please select atleast one record to mark it as ineligible!!"
      );
    }
  };

  markAsEligible = async () => {
    if (this.state.selectedRowKeys.length !== 0) {
      message.destroy();
      message.loading("Marking records as Eligible....", 0);
      if (this.state.visibleIneligibleModal) {
        if (this.state.currentYear) {
          var eligibleArray = [];
          const mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_ineligible_b2b
            );
            if (findINDEX !== -1) {
              await eligibleArray.push(
                this.state.recon_ineligible_b2b[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          const makeEligible = await this.updateIneligibleDB(
            eligibleArray,
            "R_ME"
          );
        } else {
          var eligibleArray = [];
          const mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_ineligible_b2b_2
            );
            if (findINDEX !== -1) {
              await eligibleArray.push(
                this.state.recon_ineligible_b2b_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          const makeEligible = await this.updateIneligibleDB(
            eligibleArray,
            "R_ME"
          );
        }

        message.destroy();

        message.success(
          "Selected invoice(s) have been made eligible and reconciling now...!"
        );

        this.setState({ visibleIneligibleModal: null, selectedRowKeys: [] });

        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";

        this.compareSales();
      }

      if (this.state.visibleIneligibleModal_cdnr) {
        if (this.state.currentYear) {
          var eligibleArray = [];

          const mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_ineligible_cdnr
            );
            if (findINDEX !== -1) {
              await eligibleArray.push(
                this.state.recon_ineligible_cdnr[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          await this.updateIneligibleDB(eligibleArray, "R_ME_CDNR");
        } else {
          var eligibleArray = [];

          const mapRecords = await map(async (d) => {
            const findINDEX = findIndex(
              (g) => g.id === d,
              this.state.recon_ineligible_cdnr_2
            );
            if (findINDEX !== -1) {
              await eligibleArray.push(
                this.state.recon_ineligible_cdnr_2[findINDEX]
              );
            }
          })(this.state.selectedRowKeys);

          await this.updateIneligibleDB(eligibleArray, "R_ME_CDNR");
        }

        message.destroy();

        message.success(
          "Selected note(s) have been made eligible and reconciling now...!"
        );

        this.setState({
          visibleIneligibleModal_cdnr: null,
          selectedRowKeys: [],
        });

        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";

        this.compareSales();
      }
    } else {
      message.error(
        "Please select atleast one record to mark it as eligible!!"
      );
    }
  };

  compareSalesFetchNames = async () => {
    var downloaddate = this.state.finMonth + "/" + this.state.finYear;
    fetch(
      `${BASE_URL}/export/compareSales?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}&rod=${this.rod}&feildsToRecon_b2b=${this.state.checkedList_b2b}&feildsToRecon_cdnr=${this.state.checkedList_cdnr}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then(async (result) => {
        this.setState({
          finYear: result.final.finYear,
          nextFY: result.final.nextFY,
          output_b2b: result.final.Summary_B2B,

          recon_matches_b2b: result.final.MATCHES_b2b,
          recon_mismatches_b2b: result.final.MISMATCHES_b2b,
          recon_one_b2b: result.final.PR_b2b,
          recon_two_b2b: result.final.RECON_PR_b2b,
          recon_likely_b2b: result.final.LIKELY_b2b,
          recon_ineligible_b2b: result.final.markedAsIneligible_b2b,

          recon_matches_b2b_2: result.final.MATCHES_b2b_2,
          recon_mismatches_b2b_2: result.final.MISMATCHES_b2b_2,
          recon_onlypr_b2b_2: result.final.PR_b2b_2,
          recon_onlygst_b2b_2: result.final.RECON_PR_b2b_2,
          recon_likely_b2b_2: result.final.LIKELY_b2b_2,
          recon_ineligible_b2b_2: result.final.markedAsIneligible_b2b_2,

          recon_matches_cdnr: result.final.MATCHES_cdnr,
          recon_mismatches_cdnr: result.final.MISMATCHES_cdnr,
          recon_one_cdnr: result.final.PR_cdnr,
          recon_two_cdnr: result.final.RECON_PR_cdnr,
          recon_likely_cdnr: result.final.LIKELY_cdnr,
          recon_ineligible_cdnr: result.final.markedAsIneligible_cdnr,

          recon_matches_cdnr_2: result.final.MATCHES_cdnr_2,
          recon_mismatches_cdnr_2: result.final.MISMATCHES_cdnr_2,
          recon_one_cdnr_2: result.final.PR_cdnr_2,
          recon_two_cdnr_2: result.final.RECON_PR_cdnr_2,
          recon_likely_cdnr_2: result.final.LIKELY_cdnr_2,
          recon_ineligible_cdnr_2: result.final.markedAsIneligible_cdnr_2,

          recon_matches_b2ba: result.final.MATCHES_b2ba,
          recon_mismatches_b2ba: result.final.MISMATCHES_b2ba,
          recon_onlygst_b2ba: result.final.RECON_PR_b2ba,

          recon_matches_b2ba_2: result.final.MATCHES_b2ba_2,
          recon_mismatches_b2ba_2: result.final.MISMATCHES_b2ba_2,
          recon_onlygst_b2ba_2: result.final.RECON_PR_b2ba_2,

          recon_matches_cdna: result.final.MATCHES_cdna,
          recon_mismatches_cdna: result.final.MISMATCHES_cdna,
          recon_two_cdna: result.final.RECON_PR_cdna,

          recon_matches_cdna_2: result.final.MATCHES_cdna,
          recon_mismatches_cdna_2: result.final.MISMATCHES_cdna,
          recon_two_cdna_2: result.final.RECON_PR_cdna,

          recon_loading: false,
        });
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "block";
        message.destroy();
        message.success("Fetched");

        // this.props.data.refetch();
      });
  };

  resetPurchase = () => {
    this.setState({ resetLoading: true });
    var downloaddate = this.state.finMonth + "/" + this.state.finYear;
    fetch(
      `${BASE_URL}/export/resetPurchase?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: "GET",
      }
    ).then((result) => {
      // console.log(result);
      this.setState({ resetLoading: false });
      this.props.data.refetch();
      message.success("All Purchases been reset for selected tax period");
    });
  };

  confirmFetchfromExternalAPI = async () => {
    confirm({
      title: "Are you sure?",
      content: (
        <p>
          Clicking 'Yes' will pull all Purchase from ERP for selected Tax Period{" "}
          <b>(Existing data will be overwritten by data from ERP)</b>
        </p>
      ),
      okText: "Yes",
      cancelText: "No",
      onOk() {
        document.getElementById("pull_external_click").click();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  fetchFromExternalAPI = async () => {
    message.loading("Pulling from ERP. Please wait.....", 5);
    this.pullPurchaseDatafromExternal("ExcelFile", "prextapi", "false", null);
  };

  pullPurchaseDatafromExternal = async (
    datasource,
    sourceType,
    isTally,
    acceptedFiles
  ) => {
    // var el = document.body,
    //   elChild = document.createElement("div");
    // elChild.className = "root_div";
    // elChild.innerHTML = '<div class="custom_loading loader"></div>';
    // el.insertBefore(elChild, el.firstChild);
    // document.getElementById("root").classList.add("blur");
    var uploadedFile = null;
    if (acceptedFiles !== null) {
      uploadedFile = acceptedFiles[0];
    }
    const result = await PURCHASE.upload({
      file: "iSteer",
      type: sourceType,
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    });
    // document.body.getElementsByClassName("root_div")[0].remove();
    // document.getElementById("root").classList.remove("blur");

    // if (result.error_status !== "" && result.error_status !== undefined) {
    //   //document.getElementById("purchase_error_status").click();
    //   // console.log("-----------------ERROR REPORT----------------");
    //   // console.log(result.error_status);
    //   // console.log("---------------------------------------------");
    // }
    // if (result.filestatus === true) {
    //   if (result.flag === true) {
    //     var dialogue_msg =
    //       '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' +
    //       result.total_errors +
    //       " Invoices Failed out of " +
    //       result.total +
    //       " Invoices</b></span>) Purchase data upload Failed!</h4></p><br><p>Error Excel Will be Downloaded. Please look at the Error Excel to correct the Errors!</p></div>";
    //     var div = document.getElementById("dialogue_append");
    //     if (document.getElementById("dialogue_append") !== null) {
    //       div.innerHTML += dialogue_msg;
    //     }
    //   }
    //   /************************************************************************************/
    //   if (result.total_errors > 0) {
    //     //message.error('(' + total_negative + ' Invoices Failed out of ' + result.total + ' Invoices) Purchase data upload Failed!', 12)
    //   } else {
    //     message.success(
    //       "(" +
    //         result.total +
    //         "/" +
    //         result.total +
    //         ") Purchase Invoices uploaded successfully!",
    //       5
    //     );
    //   }
    //   this.props.data.refetch();
    // } else if (result.file === false) {
    //   message.error("Purchase Pull operation failed", 5);
    // } else {
    //   message.error("Failure in Purchase pull operation", 5);
    // }

    return result;
  };

  onDropPR = async (acceptedFiles) => {
    const result = PURCHASE.upload({
      acceptedFiles: "text/csv,application/vnd.ms-excel",
      file: acceptedFiles[0],
      type: "prall",
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    });
  };

  onDropTallyPR = async (acceptedFiles) => {
    // var el = document.body,
    //   elChild = document.createElement("div");
    // elChild.className = "root_div";
    // elChild.innerHTML = '<div class="custom_loading loader"></div>';
    // el.insertBefore(elChild, el.firstChild);
    // document.getElementById("root").classList.add("blur");
    const result = await PURCHASE.upload({
      acceptedFiles: "text/csv,application/vnd.ms-excel",
      file: acceptedFiles[0],
      type: "prall",
      tally: "true",
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    });
    // document.body.getElementsByClassName("root_div")[0].remove();
    // document.getElementById("root").classList.remove("blur");
    // // console.log(result);
    // if (result.error_status !== "" && result.error_status !== undefined) {
    //   document.getElementById("purchase_error_status").click();
    //   // console.log("-----------------ERROR REPORT----------------");
    //   // console.log(result.error_status);
    //   // console.log("---------------------------------------------");
    // }
    // if (result.filestatus === true) {
    //   if (result.flag === true) {
    //     var dialogue_msg =
    //       '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' +
    //       result.total_errors +
    //       " Invoices Failed out of " +
    //       result.total +
    //       " Invoices</b></span>) Purchase data upload Failed!</h4></p><br><p>Error Excel Will be Downloaded. Please look at the Error Excel to correct the Errors!</p></div>";
    //     var div = document.getElementById("dialogue_append");
    //     if (document.getElementById("dialogue_append") !== null) {
    //       div.innerHTML += dialogue_msg;
    //     }
    //   }
    //   if (result.total_errors > 0) {
    //     //message.error('(' + total_negative + ' Invoices Failed out of ' + result.total + ' Invoices) Purchase data upload Failed!', 12)
    //   } else {
    //     message.success(
    //       "(" +
    //         result.total +
    //         "/" +
    //         result.total +
    //         ") Purchase Invoices uploaded successfully!",
    //       5
    //     );
    //   }
    //   this.props.data.refetch();
    // } else if (result.file === false) {
    //   message.error("Uploaded file is not in Tally Excel format!", 5);
    // } else {
    //   message.error("Upload File Type is Not Allowed", 5);
    // }
  };

  showModal = async (id) => {
    // console.log(id);
    // console.log(this.state.recon_matches_b2b)
    if (id === "1") {
      this.setState({
        visibleMatchModal: true,
        currentYear: true,
        datasetForDetails: this.state.recon_matches_b2b,
        m_id: id,
      });
    } else if (id === "1_2") {
      this.setState({
        visibleMatchModal: true,
        currentYear: false,
        datasetForDetails: this.state.recon_matches_b2b_2,
        m_id: id,
      });
    } else if (id === "2") {
      this.setState({
        visibleModal: true,
        currentYear: true,
        datasetForDetails: this.state.recon_mismatches_b2b,
        m_id: id,
      });
    } else if (id === "2_2") {
      this.setState({
        visibleModal: true,
        currentYear: false,
        datasetForDetails: this.state.recon_mismatches_b2b_2,
        m_id: id,
      });
    } else if (id === "3") {
      this.setState({
        visibleOneModal: true,
        currentYear: true,
        datasetForDetails: this.state.recon_one_b2b,
        m_id: id,
      });
    } else if (id === "3_2") {
      this.setState({
        visibleOneModal: true,
        currentYear: false,
        datasetForDetails: this.state.recon_onlypr_b2b_2,
        m_id: id,
      });
    } else if (id === "4") {
      // console.log("id is ::::::::::::::::::::::::::", id);
      // console.log(this.state.recon_two_b2b);
      this.setState({
        visibleTwoModal: true,
        currentYear: true,
        datasetForDetails: this.state.recon_two_b2b,
        m_id: id,
      });
    } else if (id === "4_2") {
      this.setState({
        visibleTwoModal: true,
        currentYear: false,
        datasetForDetails: this.state.recon_onlygst_b2b_2,
        m_id: id,
      });
    } else if (id === "5") {
      this.setState({
        visibleLikelyModal: true,
        currentYear: true,
        datasetForDetails: this.state.recon_likely_b2b,
        m_id: id,
      });
    } else if (id === "5_2") {
      this.setState({
        visibleLikelyModal: true,
        currentYear: false,
        datasetForDetails: this.state.recon_likely_b2b_2,
        m_id: id,
      });
    } else if (id === "6") {
      this.setState({
        visibleIneligibleModal: true,
        currentYear: true,
        datasetForDetails: this.state.recon_ineligible_b2b,
        m_id: id,
      });
    } else if (id === "6_2") {
      this.setState({
        visibleIneligibleModal: true,
        currentYear: false,
        datasetForDetails: this.state.recon_ineligible_b2b_2,
        m_id: id,
      });
    } else if (id === "111") {
      this.setState({
        visibleMatchModal_b2ba: true,
        currentYear: true,
        datasetForDetails: this.state.recon_matches_b2ba,
        m_id: id,
      });
    } else if (id === "111_2") {
      this.setState({
        visibleMatchModal_b2ba: true,
        currentYear: false,
        datasetForDetails: this.state.recon_matches_b2ba_2,
        m_id: id,
      });
    } else if (id === "222") {
      this.setState({
        visibleModal_b2ba: true,
        currentYear: true,
        datasetForDetails: this.state.recon_mismatches_b2ba,
        m_id: id,
      });
    } else if (id === "222_2") {
      this.setState({
        visibleModal_b2ba: true,
        currentYear: false,
        datasetForDetails: this.state.recon_mismatches_b2ba_2,
        m_id: id,
      });
    } else if (id === "333") {
      this.setState({
        visibleOneModal_b2ba: true,
        currentYear: true,
        datasetForDetails: this.state.recon_one_b2ba,
        m_id: id,
      });
    } else if (id === "333_2") {
      this.setState({
        visibleOneModal_b2ba: true,
        currentYear: false,
        datasetForDetails: this.state.recon_onlypr_b2ba_2,
        m_id: id,
      });
    } else if (id === "444") {
      this.setState({
        visibleTwoModal_b2ba: true,
        currentYear: true,
        datasetForDetails: this.state.recon_onlygst_b2ba,
        m_id: id,
      });
    } else if (id === "444_2") {
      this.setState({
        visibleTwoModal_b2ba: true,
        currentYear: false,
        datasetForDetails: this.state.recon_onlygst_b2ba_2,
        m_id: id,
      });
    } else if (id === "555") {
      this.setState({
        visibleLikelyModal_b2ba: true,
        currentYear: true,
        datasetForDetails: this.state.recon_likely_b2ba,
        m_id: id,
      });
    } else if (id === "555_2") {
      this.setState({
        visibleLikelyModal_b2ba: true,
        currentYear: false,
        datasetForDetails: this.state.recon_likely_b2ba_2,
        m_id: id,
      });
    } else if (id === "666") {
      this.setState({
        visibleIneligibleModal_b2ba: true,
        currentYear: true,
        datasetForDetails: this.state.recon_ineligible_b2ba,
        m_id: id,
      });
    } else if (id === "666_2") {
      this.setState({
        visibleIneligibleModal_b2ba: true,
        currentYear: false,
        datasetForDetails: this.state.recon_ineligible_b2ba_2,
        m_id: id,
      });
    }

    if (id === "11") {
      this.setState({
        visibleMatchModal_cdnr: true,
        currentYear: true,
        datasetForDetails: this.state.recon_matches_cdnr,
        m_id: id,
      });
    } else if (id === "11_2") {
      this.setState({
        visibleMatchModal_cdnr: true,
        currentYear: false,
        datasetForDetails: this.state.recon_matches_cdnr_2,
        m_id: id,
      });
    } else if (id === "22") {
      this.setState({
        visibleModal_cdnr: true,
        currentYear: true,
        datasetForDetails: this.state.recon_mismatches_cdnr,
        m_id: id,
      });
    } else if (id === "22_2") {
      this.setState({
        visibleModal_cdnr: true,
        currentYear: false,
        datasetForDetails: this.state.recon_mismatches_cdnr_2,
        m_id: id,
      });
    } else if (id === "33") {
      this.setState({
        visibleOneModal_cdnr: true,
        currentYear: true,
        datasetForDetails: this.state.recon_one_cdnr,
        m_id: id,
      });
    } else if (id === "33_2") {
      this.setState({
        visibleOneModal_cdnr: true,
        currentYear: false,
        datasetForDetails: this.state.recon_one_cdnr_2,
        m_id: id,
      });
    } else if (id === "44") {
      this.setState({
        visibleTwoModal_cdnr: true,
        currentYear: true,
        datasetForDetails: this.state.recon_two_cdnr,
        m_id: id,
      });
    } else if (id === "44_2") {
      this.setState({
        visibleTwoModal_cdnr: true,
        currentYear: false,
        datasetForDetails: this.state.recon_two_cdnr_2,
        m_id: id,
      });
    } else if (id === "55") {
      this.setState({
        visibleLikelyModal_cdnr: true,
        currentYear: true,
        datasetForDetails: this.state.recon_likely_cdnr,
        m_id: id,
      });
    } else if (id === "55_2") {
      this.setState({
        visibleLikelyModal_cdnr: true,
        currentYear: false,
        datasetForDetails: this.state.recon_likely_cdnr_2,
        m_id: id,
      });
    } else if (id === "66") {
      this.setState({
        visibleIneligibleModal_cdnr: true,
        currentYear: true,
        datasetForDetails: this.state.recon_ineligible_cdnr,
        m_id: id,
      });
    } else if (id === "66_2") {
      this.setState({
        visibleIneligibleModal_cdnr: true,
        currentYear: false,
        datasetForDetails: this.state.recon_ineligible_cdnr_2,
        m_id: id,
      });
    }

    if (id === "1111") {
      this.setState({
        visibleMatchModal_cdna: true,
        currentYear: true,
        datasetForDetails: this.state.recon_matches_cdna,
        m_id: id,
      });
    } else if (id === "1111_2") {
      this.setState({
        visibleMatchModal_cdna: true,
        currentYear: false,
        datasetForDetails: this.state.recon_matches_cdna_2,
        m_id: id,
      });
    } else if (id === "2222") {
      this.setState({
        visibleModal_cdna: true,
        currentYear: true,
        datasetForDetails: this.state.recon_mismatches_cdna,
        m_id: id,
      });
    } else if (id === "2222_2") {
      this.setState({
        visibleModal_cdna: true,
        currentYear: false,
        datasetForDetails: this.state.recon_mismatches_cdna_2,
        m_id: id,
      });
    } else if (id === "3333") {
      this.setState({
        visibleOneModal_cdna: true,
        currentYear: true,
        datasetForDetails: this.state.recon_one_cdna,
        m_id: id,
      });
    } else if (id === "3333_2") {
      this.setState({
        visibleOneModal_cdna: true,
        currentYear: false,
        datasetForDetails: this.state.recon_one_cdna_2,
        m_id: id,
      });
    } else if (id === "4444") {
      this.setState({
        visibleTwoModal_cdna: true,
        currentYear: true,
        datasetForDetails: this.state.recon_two_cdna,
        m_id: id,
      });
    } else if (id === "4444_2") {
      this.setState({
        visibleTwoModal_cdna: true,
        currentYear: false,
        datasetForDetails: this.state.recon_two_cdna_2,
        m_id: id,
      });
    } else if (id === "5555") {
      this.setState({
        visibleLikelyModal_cdna: true,
        currentYear: true,
        datasetForDetails: this.state.recon_likely_cdna,
        m_id: id,
      });
    } else if (id === "5555_2") {
      this.setState({
        visibleLikelyModal_cdna: true,
        currentYear: false,
        datasetForDetails: this.state.recon_likely_cdna_2,
        m_id: id,
      });
    } else if (id === "6666") {
      this.setState({
        visibleIneligibleModal_cdna: true,
        currentYear: true,
        datasetForDetails: this.state.recon_ineligible_cdna,
        m_id: id,
      });
    } else if (id === "6666_2") {
      this.setState({
        visibleIneligibleModal_cdna: true,
        currentYear: false,
        datasetForDetails: this.state.recon_ineligible_cdna_2,
        m_id: id,
      });
    }
  };

  TransGSTOnly = async (
    match_b2b,
    miss_b2b,
    only_pr_b2b,
    recon_pr_b2b,
    likely_b2b,
    ineligible_b2b,

    match_b2b_2,
    miss_b2b_2,
    only_pr_b2b_2,
    recon_pr_b2b_2,
    likely_b2b_2,
    ineligible_b2b_2,

    match_cdnr,
    miss_cdnr,
    only_pr_cdnr,
    recon_pr_cdnr,
    likely_cdnr,
    ineligible_cdnr,

    match_cdnr_2,
    miss_cdnr_2,
    only_pr_cdnr_2,
    recon_pr_cdnr_2,
    likely_cdnr_2,
    ineligible_cdnr_2,

    match_b2ba,
    miss_b2ba,
    recon_pr_b2ba,

    match_b2ba_2,
    miss_b2ba_2,
    recon_pr_b2ba_2,

    match_cdna,
    miss_cdna,
    recon_pr_cdna,

    match_cdna_2,
    miss_cdna_2,
    recon_pr_cdna_2,

    finYear,
    nextFY
  ) => {

    //b2b

    const m_b2b = match_b2b.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const m_b2b_2 = match_b2b_2.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const opr_b2b = only_pr_b2b.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const opr_b2b_2 = only_pr_b2b_2.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_b2b = recon_pr_b2b.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_b2b_2 = recon_pr_b2b_2.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_ineligible_b2b = ineligible_b2b.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_ineligible_b2b_2 = ineligible_b2b_2.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    //b2ba
    const m_b2ba = match_b2ba.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const m_b2ba_2 = match_b2ba_2.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_b2ba = recon_pr_b2ba.sort(function (a, b) {
      const firstDateArray = a.supplier_inv_dt.split("-");
      const secondDateArray = b.supplier_inv_dt.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_b2ba_2 = recon_pr_b2ba_2.sort(function (a, b) {
      const firstDateArray = a.supplier_inv_dt.split("-");
      const secondDateArray = b.supplier_inv_dt.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });
    //cdnr

    const m_cdnr = match_cdnr.sort(function (a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const m_cdnr_2 = match_cdnr_2.sort(function (a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const opr_cdnr = only_pr_cdnr.sort(function (a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const opr_cdnr_2 = only_pr_cdnr_2.sort(function (a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_cdnr = recon_pr_cdnr.sort(function (a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_cdnr_2 = recon_pr_cdnr_2.sort(function (a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_ineligible_cdnr = ineligible_cdnr.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_ineligible_cdnr_2 = ineligible_cdnr_2.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    //cdna

    const m_cdna = match_cdna.sort(function (a, b) {
      const firstDateArray = a.cdna_date.split("-");
      const secondDateArray = b.cdna_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const m_cdna_2 = match_cdna_2.sort(function (a, b) {
      const firstDateArray = a.cdna_date.split("-");
      const secondDateArray = b.cdna_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_cdna = recon_pr_cdna.sort(function (a, b) {
      const firstDateArray = a.cdna_date.split("-");
      const secondDateArray = b.cdna_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_cdna_2 = recon_pr_cdna_2.sort(function (a, b) {
      const firstDateArray = a.cdna_date.split("-");
      const secondDateArray = b.cdna_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const excelOutput = await excelUtility.reconPR(
      m_b2b,
      miss_b2b,
      opr_b2b,
      recon_b2b,
      likely_b2b,
      recon_ineligible_b2b,

      m_b2b_2,
      miss_b2b_2,
      opr_b2b_2,
      recon_b2b_2,
      likely_b2b_2,
      recon_ineligible_b2b_2,

      m_cdnr,
      miss_cdnr,
      opr_cdnr,
      recon_cdnr,
      likely_cdnr,
      recon_ineligible_cdnr,

      m_cdnr_2,
      miss_cdnr_2,
      opr_cdnr_2,
      recon_cdnr_2,
      likely_cdnr_2,
      recon_ineligible_cdnr_2,

      m_b2ba,
      miss_b2ba,
      recon_b2ba,

      m_b2ba_2,
      miss_b2ba_2,
      recon_b2ba_2,

      m_cdna,
      miss_cdna,
      recon_cdna,

      m_cdna_2,
      miss_cdna_2,
      recon_cdna_2,

      finYear,
      nextFY
    );

    if (excelOutput.status === "EMPTYSHEET") {
      message.warning("No invoices available to download!");
    }
  };

  showItemModal = async (id) => {
    // console.log(id);
    // console.log(this.state);

    if (id) {
      this.setState({
        visibleItemModal: id.id,
      });
      console.log(id);
    }
  };

  handleOk_notifyViaEmail = (record, val) => {
    // console.log("Done");
    // console.log(record);
    // console.log(val);
  };

  handleCancel_notifyViaEmail = () => {
    this.setState({ visible_notifyViaEmail: null });
  };

  mailReportDownload = (data, flag) => {
    var record;
    record = this.state.mailPayload;

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    var flagData = flag;

    var wb_new = XLSX.utils.book_new();
    if (record !== undefined && record !== null && record.length !== 0) {
      wb_new.SheetNames.push("Reconciliation Report");
    }

    if (this.state.visibleModal) {
      var MissMatchPRb2bDataList = [];
      if (record !== undefined && record !== null) {
        // console.log(record);
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              // console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                // "Place Of Supply": getPlaceOfSupply(Inv.pos_id),
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                "Origin of Record":
                  InvoiceItems.item_type === "PRB2B"
                    ? "As per record of " + this.props.company.name
                    : "As per filing of " + record[0].supplier_name,
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prb2b" })(record));

        // console.log(MisMatchTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                "Invoice Number": Inv.invoice_number,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record":
                  "As per filing of " + record[0].supplier_name,
              })(MisMatchTransOnly);
              map((d) => {
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Place Of Supply"] = d["Reverse Charge"] = Inv.reverse_charge;
                // d["Counter-Party Filing Status"] =
                //   Inv.status === "Y" ? "Submitted" : "Not Submitted";
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(record));
        MissMatchPRb2bDataList = MisMatchTransOnly;
      }
      // console.log(MissMatchPRb2bDataList);
      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          MissMatchPRb2bDataList
        );
      }
    }

    if (this.state.visibleModal_b2ba) {
      var MissMatchPRb2baDataList = [];
      if (record !== undefined && record !== null) {
        // console.log(record);
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              // console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                "Origin of Record":
                  InvoiceItems.item_type === "PRB2B"
                    ? "As per record of " + this.props.company.name
                    : "As per filing of " + record[0].supplier_name,
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prb2b" })(record));

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                "Invoice Number": Inv.invoice_number,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record":
                  "As per filing of " + record[0].supplier_name,
              })(MisMatchTransOnly);
              map((d) => {
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Reverse Charge"] = Inv.reverse_charge;
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(record));
        MissMatchPRb2baDataList = MisMatchTransOnly;
      }
      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          MissMatchPRb2baDataList
        );
      }
    }

    if (this.state.visibleModal_cdnr) {
      var MissMatchPRcdnrDataList = [];
      if (record !== undefined && record !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                "Origin of Record":
                  InvoiceItems.item_type === "PRCDNR"
                    ? "As per record of " + this.props.company.name
                    : "As per filing of " + record[0].supplier_name,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prcdnr" })(record));

        console.log(MisMatchTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record":
                  "As per filing of " + record[0].supplier_name,
              })(MisMatchTransOnly);
              map((d) => {
                d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                // d['Invoice Value'] = parseFloat(Inv.invoice_value);
                // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

                d["Note/Refund Voucher date"] = Inv.cdnr_date;
                d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
                d["Document Type"] =
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type;

                // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
                d["Pre GST"] = Inv.pre_gst;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprcdnr" })(record));
        MissMatchPRcdnrDataList = MisMatchTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          MissMatchPRcdnrDataList
        );
      }
    }
    if (this.state.visibleModal_cdna) {
      var MissMatchPRcdnaDataList = [];
      if (record !== undefined && record !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                "Origin of Record":
                  InvoiceItems.item_type === "PRCDNR"
                    ? "As per record of " + this.props.company.name
                    : "As per filing of " + record[0].supplier_name,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prcdnr" })(record));

        console.log(MisMatchTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record":
                  "As per filing of " + record[0].supplier_name,
              })(MisMatchTransOnly);
              map((d) => {
                d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                // d['Invoice Value'] = parseFloat(Inv.invoice_value);
                // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

                d["Note/Refund Voucher date"] = Inv.cdnr_date;
                d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
                d["Document Type"] =
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type;

                // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
                d["Pre GST"] = Inv.pre_gst;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprcdnr" })(record));
        MissMatchPRcdnaDataList = MisMatchTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          MissMatchPRcdnaDataList
        );
      }
    }
    if (this.state.visibleOneModal) {
      var onlyPRPRb2bDataList = [];
      if (record !== undefined && record !== null) {
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": getPlaceOfSupply(Inv.pos_id),
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(record);

        onlyPRPRb2bDataList = StandAlonePRTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          onlyPRPRb2bDataList
        );
      }
    }

    if (this.state.visibleOneModal_cdnr) {
      var onlyPRPRcdnrDataList = [];
      if (record !== undefined && record !== null) {
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                      ? "Credit"
                      : Inv.cdnr_type,

                // 'Invoice Value': parseFloat(Inv.invoice_value),
                // 'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
                // 'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(record);

        onlyPRPRcdnrDataList = StandAlonePRTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          onlyPRPRcdnrDataList
        );
      }
    }

    if (this.state.visibleLikelyModal) {
      var likelyPRb2bDataList = [];
      if (record !== undefined && record !== null) {
        const LikelyTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": getPlaceOfSupply(Inv.pos_id),
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),

                // 'From': (InvoiceItems.item_type == "PRB2B" ? "Purchase Register" : "GSTN"),

                "Origin of Record":
                  InvoiceItems.item_type == "PRB2B"
                    ? "Record that I Possess!"
                    : "Record that you have filed!",

                tempID: Inv.tempID,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRB2B' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prb2b" })(record));

        // console.log(LikelyTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                tempID: Inv.tempID,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record": "Record that you have filed!",
              })(LikelyTransOnly);
              map((d) => {
                d["Invoice Number"] = Inv.invoice_number;
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Place Of Supply"] = getPlaceOfSupply(Inv.pos_id);
                d["Reverse Charge"] = Inv.reverse_charge;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(record));

        likelyPRb2bDataList = map((d) => {
          return omit("tempID")(d);
        })(LikelyTransOnly);
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          likelyPRb2bDataList
        );
      }
    }

    var wbout = XLSX.write(wb_new, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    // console.log(wbout);

    fileName =
      "Reconciliation Report - " +
      (data[0] ? data[0].supplier_gstin : "") +
      ".xlsx";

    return flagData === 1
      ? fileSaver.saveAs(
        blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Reconciliation Report - " +
        (data[0] ? data[0].supplier_gstin : "") +
        ".xlsx"
      )
      : this.state.visibleModal
        ? MissMatchPRb2bDataList
        : this.state.visibleOneModal
          ? onlyPRPRb2bDataList
          : this.state.visibleLikelyModal
            ? likelyPRb2bDataList
            : this.state.visibleModal_cdnr
              ? MissMatchPRcdnrDataList
              : this.state.visibleOneModal_cdnr
                ? onlyPRPRcdnrDataList
                : this.state.visibleModal_b2ba
                  ? MissMatchPRb2baDataList
                  : this.state.visibleModal_cdna
                    ? MissMatchPRcdnaDataList
                    : [];
  };

  mailReportDownload_CDNR = (data, flag) => {
    // console.log(data);
    var record = [];

    var flagData = flag;
    // console.log(flagData);
    record.push(data);
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    var wb_new = XLSX.utils.book_new();
    if (record !== undefined && record !== null && record.length !== 0) {
      wb_new.SheetNames.push("Reconcilation Report");
    }

    //Mismatched Purchase Transactions
    var MissMatchPRcdnrDataList = [];
    if (record !== undefined && record !== null) {
      const MisMatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems, item, index) => {
            // console.log(Inv);
            // console.log(Inv.status);
            return {
              "GSTIN of Supplier": Inv.supplier_gstin,
              "Supplier Name": Inv.supplier_name,
              "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
              "Invoice/Advance Payment Voucher date": Inv.invoice_date,

              "Note/Refund Voucher Number": Inv.cdnr_number,
              "Note/Refund Voucher date": Inv.cdnr_date,
              "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
              "Document Type":
                Inv.cdnr_type === "D"
                  ? "Debit"
                  : Inv.cdnr_type === "C"
                    ? "Credit"
                    : Inv.cdnr_type,

              // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
              "Pre GST": Inv.pre_gst,
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              "Integrated Tax Paid":
                InvoiceItems.igst === null ? "" : parseFloat(InvoiceItems.igst),
              "Central Tax Paid":
                InvoiceItems.cgst === null ? "" : parseFloat(InvoiceItems.cgst),
              "State/UT Tax Paid":
                InvoiceItems.sgst === null ? "" : parseFloat(InvoiceItems.sgst),
              "Cess Paid":
                InvoiceItems.cess === null ? "" : parseFloat(InvoiceItems.cess),
              From:
                InvoiceItems.item_type == "PRCDNR"
                  ? "Purchase Register"
                  : "GSTN",
              // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
            };
          })(Inv.items);
          return InvoiceItems;
        }),
        flattenDeep
      )(filter({ type: "prcdnr" })(record));

      // console.log(MisMatchTransOnly);

      const MisMatchTransOnly_Recon = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems, item, index) => {
            const prInvoice = filter({
              "Note/Refund Voucher Number": Inv.cdnr_number,
              "Invoice Number": Inv.invoice_number,
              "GSTIN of Supplier": Inv.supplier_gstin,
              From: "GSTN",
            })(MisMatchTransOnly);
            map((d) => {
              d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
              d["GSTIN of Supplier"] = Inv.supplier_gstin;
              // d['Invoice Value'] = parseFloat(Inv.invoice_value);
              // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

              d["Note/Refund Voucher date"] = Inv.cdnr_date;
              d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
              d["Document Type"] =
                Inv.cdnr_type === "D"
                  ? "Debit"
                  : Inv.cdnr_type === "C"
                    ? "Credit"
                    : Inv.cdnr_type;

              // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
              d["Pre GST"] = Inv.pre_gst;
              // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
            })(prInvoice);
            return {};
          })(Inv.items);
          return InvoiceItems;
        }),
        flattenDeep
      )(filter({ type: "reconprcdnr" })(record));
      MissMatchPRcdnrDataList = MisMatchTransOnly;
    }
    // console.log(MissMatchPRcdnrDataList);
    if (record !== undefined && record !== null && record.length !== 0) {
      wb_new.Sheets["Reconcilation Report"] = XLSX.utils.json_to_sheet(
        MissMatchPRcdnrDataList
      );
    }
    var wbout = XLSX.write(wb_new, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    // console.log(wbout);

    fileName =
      "Reconciliation Report - " +
      (record ? record.supplier_gstin : "") +
      ".xlsx";

    return flagData === 1
      ? fileSaver.saveAs(
        blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
        "Reconciliation Report - " +
        (record ? record[0].supplier_gstin : "") +
        ".xlsx"
      )
      : MissMatchPRcdnrDataList;
  };

  addSimilar = (record) => {
    console.log(record[0].supplier_gstin);

    var similarGSTN;
    var invoiceMailList;
    var sortInvoiceNumber = record[0].invoice_number;
    var likelyGSTN_InvNumber;

    if (this.state.visibleModal) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_mismatches_b2b
      );
      console.log(similarGSTN);

      // this.mailReportDownload(similarGSTN, 1)

      invoiceMailList = flow(
        map((d) => {
          return pick(["invoice_number"])(d).invoice_number;
        }),
        uniq
      )(similarGSTN);

      invoiceMailList.sort(function (x, y) {
        return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
      });

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found mismatches in these invoices: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function (x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
    if (this.state.visibleOneModal) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_one_b2b
      );
      console.log(similarGSTN);

      invoiceMailList = flow(
        map((d) => {
          return pick(["invoice_number"])(d).invoice_number;
        }),
        uniq
      )(similarGSTN);

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found that these invoices have not been filed: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function (x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
    if (this.state.visibleLikelyModal) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_likely_b2b
      );
      // likelyGSTN_InvNumber = filter({ tempID : record[0].tempID, type: 'reconprb2b'})(this.state.recon_likely_b2b);
      console.log(similarGSTN);

      invoiceMailList = flow(
        map((d) => {
          if (d.type === "reconprb2b") {
            return pick(["invoice_number"])(d).invoice_number;
          }
        }),
        compact,
        uniq
      )(similarGSTN);

      console.log(invoiceMailList);

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `I have reconciled the data with ElixirGST and found that there is some issue with the following invoice(s) ${invoiceMailList.toString()}. Kindly respond.`,
          mailPayload: similarGSTN,
          invoiceTags: invoiceMailList,
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }

    if (this.state.visibleModal_cdnr) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_mismatches_cdnr
      );
      console.log(similarGSTN);

      // this.mailReportDownload(similarGSTN, 1)

      invoiceMailList = flow(
        map((d) => {
          return pick(["cdnr_number"])(d).cdnr_number;
        }),
        uniq
      )(similarGSTN);

      invoiceMailList.sort(function (x, y) {
        return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
      });

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found mismatches in these credit/debit notes: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function (x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
    if (this.state.visibleOneModal_cdnr) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_one_cdnr
      );
      console.log(similarGSTN);

      invoiceMailList = flow(
        map((d) => {
          return pick(["cdnr_number"])(d).cdnr_number;
        }),
        uniq
      )(similarGSTN);

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found that these credit/debit notes have not been filed: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function (x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
  };

  notifyViaEmailSend = () => {
    var subjectMessage;

    const greetingsMessage = "Greetings from " + this.props.company.name + "!";
    const sendData = this.mailReportDownload(this.state.mailPayload);

    const EmailDetails = this.state.emailTags.toString();
    const EmailMessage = document.getElementById("mailMessage").value;

    const EmailDetails_CC =
      this.props.users_list_me && this.props.users_list_me.email;
    const EmailDetails_RT =
      this.props.users_list_me && this.props.users_list_me.email;

    if (
      this.state.visibleModal ||
      this.state.visibleModal_cdnr ||
      this.state.visibleModal_b2ba ||
      this.state.visibleModal_cdna
    ) {
      subjectMessage =
        "Mismatch in Invoices / Notes - " + this.props.company.name;
    }
    if (this.state.visibleOneModal || this.state.visibleOneModal_cdnr) {
      subjectMessage = "Missing Invoices / Notes - " + this.props.company.name;
    }
    if (this.state.visibleLikelyModal) {
      subjectMessage = "Invoice Number Mismatches - " + this.props.company.name;
    }

    if (this.state.emailTags && this.state.emailTags.length === 0) {
      message.warning("Kindly enter atleast one To Mail ID", 2);
    } else {
      const emailContent = {
        emailDetails: EmailDetails,
        emailMessage: EmailMessage,
        fileName: fileName,
        emailDetails_CC: EmailDetails_CC,
        emailDetails_RT: EmailDetails_RT,
        greetingsMessage: greetingsMessage,
        emailSubject: subjectMessage,
        record: sendData,
      };

      return fetch(`${BASE_URL}/export/sendNotifyMail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailContent),
      }).then((result) => {
        if (this.state.visibleModal || this.state.visibleModal_b2ba) {
          this.setState({
            showModalMail_Mismatch: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleOneModal) {
          this.setState({
            showModalMail_PR: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleModal_cdnr || this.state.visibleModal_cdna) {
          this.setState({
            showModalMail_Mismatch_CDNR: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleOneModal_cdnr) {
          this.setState({
            showModalMail_PR_CDNR: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleLikelyModal) {
          this.setState({
            showModalMail_likely: false,
            invoiceTags: [],
            emailTags: [],
          });
        }

        message.destroy();
        message.success("Mail Sent Successfully!");
      });
    }
  };

  handleCloseInvoiceTags = (removedTag) => {
    const tags = this.state.invoiceTags.filter((tag) => tag !== removedTag);
    console.log(tags);
    this.setState({
      invoiceTags: tags,
      mailText: `Upon reconciliation of our Purchases, we found mismatches in these invoices: ${tags.toString()}. Kindly respond.`,
      mailPayload: this.state.mailPayload.filter(
        (tag) => tag.invoice_number !== removedTag
      ),
    });
  };

  notifyViaEmail = async (record) => {
    // if (this.state.visibleLikelyModal) {
    //   var likelyGSTN_InvNumberMail = filter({
    //     tempID: record.tempID,
    //     type: "reconprb2b"
    //   })(this.state.recon_likely_b2b);

    //   console.log(likelyGSTN_InvNumberMail);

    //   this.setState({
    //     showModalMail_likely: true,
    //     mailPayload: filter({ tempID: record.tempID })(
    //       this.state.recon_likely_b2b
    //     )
    //   });

    //   this.setState({
    //     mailText:
    //       "I have reconciled the data with ThoughtGST and found that there is some issue with this Invoice Number : " +
    //       likelyGSTN_InvNumberMail[0].invoice_number +
    //       ". Kindly respond."
    //   });
    // }

    if (this.state.visibleOneModal) {
      if (this.state.currentYear) {
        this.setState({
          showModalMail_PR: true,
          mailPayload: filter({
            invoice_number: record.invoice_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_one_b2b),
        });
      } else {
        this.setState({
          showModalMail_PR: true,
          mailPayload: filter({
            invoice_number: record.invoice_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_onlypr_b2b_2),
        });
      }

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found that these invoices have not been filed: " +
          record.invoice_number +
          ". Kindly respond.",
      });
    }

    if (this.state.visibleModal) {
      if (this.state.currentYear) {
        this.setState({
          showModalMail_Mismatch: true,
          mailPayload: filter({
            invoice_number: record.invoice_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_b2b),
        });
      } else {
        this.setState({
          showModalMail_Mismatch: true,
          mailPayload: filter({
            invoice_number: record.invoice_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_b2b_2),
        });
      }

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found mismatches in these invoices: " +
          record.invoice_number +
          ". Kindly respond.",
      });
    }

    if (this.state.visibleModal_b2ba) {
      if (this.state.currentYear) {
        this.setState({
          showModalMail_Mismatch: true,
          mailPayload: filter({
            invoice_number: record.invoice_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_b2ba),
        });
      } else {
        this.setState({
          showModalMail_Mismatch: true,
          mailPayload: filter({
            invoice_number: record.invoice_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_b2ba_2),
        });
      }

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found mismatches in these invoices: " +
          record.invoice_number +
          ". Kindly respond.",
      });
    }
  };

  notifyViaEmail_CDNR = async (record) => {
    if (this.state.visibleOneModal_cdnr) {
      if (this.state.currentYear) {
        this.setState({
          showModalMail_PR_CDNR: true,
          mailPayload: filter({
            cdnr_number: record.cdnr_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_one_cdnr),
        });
      } else {
        this.setState({
          showModalMail_PR_CDNR: true,
          mailPayload: filter({
            cdnr_number: record.cdnr_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_one_cdnr_2),
        });
      }
      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found that these credit/debit notes have not been filed: " +
          record.cdnr_number +
          ". Kindly respond.",
      });
    }
    if (this.state.visibleModal_cdnr) {
      if (this.state.currentYear) {
        this.setState({
          showModalMail_Mismatch_CDNR: true,
          mailPayload: filter({
            cdnr_number: record.cdnr_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_cdnr),
        });
      } else {
        this.setState({
          showModalMail_Mismatch_CDNR: true,
          mailPayload: filter({
            cdnr_number: record.cdnr_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_cdnr_2),
        });
      }

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found mismatches in these credit/debit notes: " +
          record.cdnr_number +
          ". Kindly respond.",
      });
    }

    if (this.state.visibleModal_cdna) {
      if (this.state.currentYear) {
        this.setState({
          showModalMail_Mismatch_CDNR: true,
          mailPayload: filter({
            cdnr_number: record.cdnr_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_cdna),
        });
      } else {
        this.setState({
          showModalMail_Mismatch_CDNR: true,
          mailPayload: filter({
            cdnr_number: record.cdnr_number,
            supplier_gstin: record.supplier_gstin,
          })(this.state.recon_mismatches_cdna_2),
        });
      }

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found mismatches in these credit/debit notes: " +
          record.cdnr_number +
          ". Kindly respond.",
      });
    }
  };

  onTwoA = () => {
    this.setState({ pull2A: true });
  };
  onTwoB = () => {
    this.setState({ pull2B: true });
  };

  hideItemModal = () => {
    // console.log(this.state);
    this.setState({ visibleItemModal: false });
  };

  hideModal = () => {
    document.getElementById("misMatchInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_mismatch_b2b").click();
    this.setState({ selectedRowKeys: [], visibleModal: null });
  };

  hideModal_b2ba = () => {
    this.setState({ selectedRowKeys: [], visibleModal_b2ba: false });
  };

  hideMatchModal_b2ba = () => {
    this.setState({ selectedRowKeys: [], visibleMatchModal_b2ba: false });
  };

  hideOneModal_b2ba = () => {
    this.setState({ selectedRowKeys: [], visibleOneModal_b2ba: false });
  };

  hideTwoModal_b2ba = () => {
    this.setState({ selectedRowKeys: [], visibleTwoModal_b2ba: false });
  };

  hideLikelyModal_b2ba = () => {
    this.setState({ selectedRowKeys: [], visibleLikelyModal_b2ba: false });
  };

  hideIneligibleModal_b2ba = () => {
    this.setState({ visibleIneligibleModal_b2ba: false, selectedRowKeys: [] });
  };

  hideLikelyModal = () => {
    this.setState({ selectedRowKeys: [], visibleLikelyModal: null });
    document.getElementById("likelyInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_likely_b2b").click();
  };

  hideIneligibleModal = () => {
    this.setState({ selectedRowKeys: [], visibleIneligibleModal: null });
    document.getElementById("ineligibleInvoiceSearch_b2b").click();
    document.getElementById("ineligibleSearch_recon_b2b").click();
  };

  hideMatchModal = () => {
    this.setState({ selectedRowKeys: [], visibleMatchModal: false });
    document.getElementById("matchInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_match_b2b").click();
  };

  hideOneModal = () => {
    this.setState({ selectedRowKeys: [], visibleOneModal: false });
    document.getElementById("prInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_opr_b2b").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideTwoModal = () => {
    // console.log("Indide two model cacel");
    this.setState({ selectedRowKeys: [], visibleTwoModal: false });
    document.getElementById("gstnInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_recon_b2b").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideCPModal = () => {
    // console.log("Indide two model cacel");
    this.setState({ visibleCP_Modal: false, selectedRowKeys: [] });
    document.getElementById("gstnInvoiceSearch_b2b_CP").click();
    document.getElementById("gstnSearch_recon_b2b_CP").click();
    // this.setState({ selectedRowKeys: [] });
  };

  //cdnr

  hideModal_cdnr = () => {
    this.setState({ visibleModal_cdnr: null, selectedRowKeys: [] });
    document.getElementById("misMatchInvoiceSearch_cdnr").click();
    document.getElementById("misMatchCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_mismatch_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideLikelyModal_cdnr = () => {
    this.setState({ selectedRowKeys: [], visibleLikelyModal_cdnr: null });
    document.getElementById("gstnSearch_likely_cdnr").click();
    document.getElementById("likelyInvoiceSearch_cdnr").click();
    document.getElementById("likelyCDNRSearch_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideIneligibleModal_cdnr = () => {
    this.setState({ selectedRowKeys: [], visibleIneligibleModal_cdnr: null });
    document.getElementById("ineligibleInvoiceSearch_cdnr").click();
    document.getElementById("gstnSearch_ineligible_recon_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideMatchModal_cdnr = () => {
    // console.log("Indide Match model cacel");
    this.setState({ selectedRowKeys: [], visibleMatchModal_cdnr: false });
    document.getElementById("matchInvoiceSearch_cdnr").click();
    document.getElementById("matchCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_match_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideOneModal_cdnr = () => {
    // console.log("Indide one model cacel");
    this.setState({ selectedRowKeys: [], visibleOneModal_cdnr: false });
    document.getElementById("prInvoiceSearch_cdnr").click();
    document.getElementById("prCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_opr_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideTwoModal_cdnr = () => {
    // console.log("Indide two model cacel");
    this.setState({ selectedRowKeys: [], visibleTwoModal_cdnr: false });
    document.getElementById("gstnInvoiceSearch_cdnr").click();
    document.getElementById("gstnCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_recon_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideMatchModal_cdna = () => {
    // console.log("Indide Match model cacel");
    this.setState({ selectedRowKeys: [], visibleMatchModal_cdna: false });
    // document.getElementById("matchInvoiceSearch_cdnr").click();
    // document.getElementById("matchCDNRSearch_cdnr").click();
    // document.getElementById("gstnSearch_match_cdnr").click();
  };

  hideModal_cdna = () => {
    this.setState({ selectedRowKeys: [], visibleModal_cdna: false });
    // document.getElementById("misMatchInvoiceSearch_cdnr").click();
    // document.getElementById("misMatchCDNRSearch_cdnr").click();
    // document.getElementById("gstnSearch_mismatch_cdnr").click();
  };

  hideOneModal_cdna = () => {
    // console.log("Indide one model cacel");
    this.setState({ visibleOneModal_cdna: false, selectedRowKeys: [] });
    // document.getElementById("prInvoiceSearch_cdnr").click();
    // document.getElementById("prCDNRSearch_cdnr").click();
    // document.getElementById("gstnSearch_opr_cdnr").click();
  };

  hideTwoModal_cdna = () => {
    // console.log("Indide two model cacel");
    this.setState({ selectedRowKeys: [], visibleTwoModal_cdna: false });
    // document.getElementById("gstnInvoiceSearch_cdnr").click();
    // document.getElementById("gstnCDNRSearch_cdnr").click();
    // document.getElementById("gstnSearch_recon_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideLikelyModal_cdna = () => {
    this.setState({ selectedRowKeys: [], visibleLikelyModal_cdna: false });
    // document.getElementById("gstnSearch_likely_cdnr").click();
    // document.getElementById("likelyInvoiceSearch_cdnr").click();
    // document.getElementById("likelyCDNRSearch_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  hideIneligibleModal_cdna = () => {
    this.setState({ selectedRowKeys: [], visibleIneligibleModal_cdna: false });
    // document.getElementById("ineligibleInvoiceSearch_cdnr").click();
    // document.getElementById("gstnSearch_ineligible_recon_cdnr").click();
    // this.setState({ selectedRowKeys: [] });
  };

  likelyAction = ()=>{
message.success(`${this.state.selectedRowKeys.length} Invoice Moved to Matched Purchase Transactions`)
// console.log(data)
  }

  changeSelectedFinancialMonth = (name) => {
    this.props.changeSelectedFinancialMonth(name);
  };

  handleCancel = (e) => {
    this.setState({ requestOTP: false });
  };

  handleGet2ACancel = () => {
    this.setState({ pull2A: false });
  };

  handleGet2BCancel = () => {
    this.setState({ pull2B: false });
  };


  handleOTPSubmit = (val) => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      message.destroy();
      message.loading(
        "Fetching B2B Invoices and Credit / Debit Notes from GSTN...",
        0
      );
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      gspAuth.saveOTP(this.gsp.otp, this.state.companyId);

      if (this.state.toGetData == "2A") {
        await this.getCounterPartyData(
          this.state.fromPeriod,
          this.state.toPeriod
        );
      }
      else {
        await this.getCounterPartyData2B(
          this.state.fromPeriod,
          this.state.toPeriod
        );
      }
    });
  };



  handleGet2ASubmit = () => {
    message.destroy();
    message.loading(
      "Fetching B2B Invoices and Credit / Debit Notes from GSTN...",
      0
    );
    const form = this.form2ARef.props.form;
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      form.resetFields();
      // console.log(fieldsValue);
      const fromDate = fieldsValue["from_month"].format("MMYYYY");
      const toDate = fieldsValue["to_month"].format("MMYYYY");
      this.setState({ pull2A: false });
      const cpData = await this.getCounterPartyData(fromDate, toDate);
    });
  };

  fetchSupplierNamesForViewSupplierData = async () => {
    notification.destroy();
    message.loading("Fetching Names...", 0);
    var missingCompanyName = new Array();
    missingCompanyName = missingCompanyName.concat(this.state.CP_DATA);
    missingCompanyName = missingCompanyName.concat(this.state.b2ba_data);
    missingCompanyName = missingCompanyName.concat(this.state.cdnr_data);
    missingCompanyName = missingCompanyName.concat(this.state.cdna_data);

    const listMissingCompanyName = filter({ supplier_name: "..." })(
      missingCompanyName
    );

    console.log(listMissingCompanyName);

    const gstinList = flow(
      map((d) => {
        if (d.supplier_gstin) return pick(["supplier_gstin"])(d).supplier_gstin;
        else return pick(["receiver_gstin"])(d).receiver_gstin;
      }),
      uniq
    )(listMissingCompanyName);

    const isSuccess = await gspAuth.getListOfCompanyNames(
      gstinList,
      this.props.selectedCompany
    );

    if (isSuccess.data.success === true) {
      this.viewCPdata();
    } else if (isSuccess.data.success === false) {
      message.destroy();
      notification.destroy();
      notification.open({
        message: "Fetch Unsuccessful",
        description: "Unable to process your request, please try again later.",
        duration: 0,
        icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
      });
    }
  };

  fetchMissingNames = async (id) => {
    notification.destroy();
    message.loading("Fetching Names...", 0);

    var missingCompanyName = new Array();
    missingCompanyName = missingCompanyName.concat(this.state.recon_one_b2b);
    missingCompanyName = missingCompanyName.concat(this.state.recon_one_b2b_2);
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_b2b);
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_b2b_2);
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_b2b
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_b2b_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_b2b
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_b2b_2
    );
    missingCompanyName = missingCompanyName.concat(this.state.recon_one_cdnr);
    missingCompanyName = missingCompanyName.concat(this.state.recon_one_cdnr_2);
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_cdnr);
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_cdnr_2);
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_cdnr
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_cdnr_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_cdnr
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_cdnr_2
    );
    missingCompanyName = missingCompanyName.concat(this.state.CP_DATA);
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_ineligible_b2b
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_ineligible_b2b_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_ineligible_cdnr
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_ineligible_cdnr_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_b2ba
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_b2ba
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_onlygst_b2ba
    );

    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_b2ba_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_b2ba_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_onlygst_b2ba_2
    );

    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_cdna
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_cdna
    );
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_cdna);

    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_cdna_2
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_cdna_2
    );
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_cdna_2);

    const listMissingCompanyName = filter({ supplier_name: "..." })(
      missingCompanyName
    );

    const gstinList = flow(
      map((d) => {
        console.log(d.supplier_gstin);
        return d.supplier_gstin
          ? pick(["supplier_gstin"])(d).supplier_gstin
          : pick(["receiver_gstin"])(d).receiver_gstin;
      }),
      uniq
    )(listMissingCompanyName);

    const isSuccess = await gspAuth.getListOfCompanyNames(
      gstinList,
      this.props.selectedCompany
    );

    if (isSuccess.data.success === true) {
      this.compareSalesFetchNames();
      this.compareSales(id);
    } else if (isSuccess.data.success === false) {
      message.destroy();
      notification.destroy();
      notification.open({
        message: "Fetch Unsuccessful",
        description: "Unable to process your request, please try again later.",
        duration: 0,
        icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
      });
    }
  };

  //#region  2B

  save2BFormRef = (formRef) => {
    this.form2BRef = formRef;
  };


  handleGet2BSubmit = () => {
    message.destroy();
    message.loading(
      "Fetching B2B Invoices and Credit / Debit Notes from GSTN...",
      0
    );
    const form = this.form2BRef.props.form;
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      form.resetFields();
      // console.log(fieldsValue);
      const fromDate = fieldsValue["from_month"].format("MMYYYY");
      const toDate = fieldsValue["to_month"].format("MMYYYY");
      this.setState({ pull2B: false });
      console.log("fromDate", fromDate);
      console.log("toDate", toDate);
      const cpData = await this.getCounterPartyData2B(fromDate, toDate);  //API Calls
    });
  };



  getCounterPartyData2B = async (fromPeriod, toPeriod) => {
    // alert("fromPeriod");
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const periodArrayValuesfor2A = this.periodArrayValues(fromPeriod, toPeriod);

    // console.log(periodArrayValuesfor2A);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
    };

    // console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({
          requestOTP: true,
          fromPeriod: fromPeriod,
          toGetData: "2B",
          toPeriod: toPeriod,
          fromPullData: true,
          fromReconcile: null,
        });
      } else if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.indexOf("Under Maintenance") >= 0
      ) {
        message.destroy();
        message.error("Government API is under maintenance");
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      try {
        message.destroy();
        message.loading("Fetching B2B 2B Invoices..", 0);
        const gstr2A_B2B_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.response.gspAuth.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_b2b = await gspAuth.getCounterParty2BData(reqData, "GET2B");
            if (this.gsp.response_b2b.status && get("data.data.errorCode", this.gsp.response_b2b) === "RETINPROGRESS") {
              const reqId = get("data.data.reqId", this.gsp.response_b2b);
              message.destroy();
              notification.info({
                key: reqId,
                message: "Information",
                duration: 0,
                description:
                  "GSTN is preparing the requested supplier data, we will auto fetch the details again in " +
                  (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                    1) +
                  " minutes",
              });
              setTimeout(
                this.callbackForRetInProgress.bind(this, reqId, reqData, "B2B"),
                (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                  1) *
                60 *
                1000
              );
            }
            message.destroy();
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "B2B Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }

    }
  };
  //#endregion




  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  save2AFormRef = (formRef) => {
    this.form2ARef = formRef;
  };
  onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    console.log(selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  popupConfirmResetVisibleChange = (e) => {
    if (!e) {
      this.setState({ popupConfirmReset: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset: true });
      } else {
        this.setState({ popupConfirmReset: false });
      }
    }
  };

  popupConfirmResetVisibleChange_CP = (e) => {
    if (!e) {
      this.setState({ popupConfirmReset_CP: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset_CP: true });
      } else {
        this.setState({ popupConfirmReset_CP: false });
      }
    }
  };

  viewCPdata = () => {
    message.destroy();
    message.loading("Fetching Supplier data....", 0);

    var downloaddate =
      this.state.recon_from_month + "/" + this.state.recon_year;

    const getInvoices = fetch(
      `${BASE_URL}/export/getCPInvoices?&companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          CP_DATA: result.b2b,
          b2ba_data: result.b2ba,
          cdnr_data: result.cdnr,
          cdna_data: result.cdna,
        });
        this.setState({ visibleCP_Modal: true });
        message.destroy();
      });
  };

  resetCPData = () => {
    message.destroy();
    message.loading("Resetting Supplier data....", 0);

    var downloaddate =
      this.state.recon_from_month + "/" + this.state.recon_year;

    const getInvoices = fetch(
      `${BASE_URL}/export/deleteCPInvoices?&companyId=${this.props.selectedCompany}&downloaddate=${this.state.recon_year}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        message.destroy();
        message.success("Supplier Data Reset Successful");
        this.setState({ visibleCP_Modal: false });
      });
  };

  setDatasetInState = (k, v) => {
    // console.log("setting state",k,v)
    this.setState({ [k]: v });
  };

  cpExcelDownload = async () => {
    const sortCP = this.state.CP_DATA.sort(function (a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    console.table(this.state.cdnr_data);
    console.table(this.state.cdna_data);
    console.table(this.state.b2ba_data);

    const excelOutputCP = await excelUtility.CP_Excel(
      sortCP,
      this.state.cdnr_data,
      this.state.b2ba_data,
      this.state.cdna_data
    );

    if (excelOutputCP.status === "EMPTYSHEET") {
      message.warning("No invoices available to download!");
    }
  };

  validateMonths = (val) => {
    if (val === 1) {
      return 10;
    }
    if (val === 2) {
      return 11;
    }
    if (val === 3) {
      return 12;
    }
    if (val === 4) {
      return 1;
    }
    if (val === 5) {
      return 2;
    }
    if (val === 6) {
      return 3;
    }
    if (val === 7) {
      return 4;
    }
    if (val === 8) {
      return 5;
    }
    if (val === 9) {
      return 6;
    }
    if (val === 10) {
      return 7;
    }
    if (val === 11) {
      return 8;
    }
    if (val === 12) {
      return 9;
    }
  };

  handleYearChangeRecon = (val) => {
    this.setState({ recon_year: val });
  };

  handleFromMonthChangeRecon = (val) => {
    var properMonth1 = this.validateMonths(parseInt(val));
    var properMonth2 = this.validateMonths(parseInt(this.state.recon_to_month));

    if (properMonth1 > properMonth2) {
      this.setState({ recon_from_month: this.props.finMonth.toString() });
      return message.error("From period cannot be greater than To period!");
    } else {
      this.setState({ recon_from_month: val });
    }
  };

  handleToMonthChangeRecon = (val) => {
    var properMonth1 = this.validateMonths(parseInt(val));
    var properMonth2 = this.validateMonths(
      parseInt(this.state.recon_from_month)
    );

    if (properMonth1 < properMonth2) {
      this.setState({ recon_to_month: this.props.finMonth.toString() });
      return message.error("To period cannot be lesser than From period!");
    } else {
      this.setState({ recon_to_month: val });
    }
  };
  onMessageDrawer(r) {
    // console.log(this.state.isDrawerVisible)
    this.setState({ isDrawerVisible: !this.state.isDrawerVisible });
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All",
          onSelect: () => {
            if (this.state.visibleMatchModal === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_b2b.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_b2b_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleMatchModal_b2ba === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_b2ba.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_b2ba_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleModal_b2ba === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_mismatches_b2ba.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_mismatches_b2ba_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleOneModal_b2ba === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_one_b2ba.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_onlypr_b2ba_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleTwoModal_b2ba === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_two_b2ba.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_onlygst_b2ba_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleOneModal === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_one_b2b.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_onlypr_b2b_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleTwoModal === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_two_b2b.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_onlygst_b2b_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleIneligibleModal === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_ineligible_b2b.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_ineligible_b2b_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleMatchModal_cdnr === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_cdnr.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_cdnr_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleMatchModal_cdna === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_cdna.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_matches_cdna_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleOneModal_cdna === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_one_cdna.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_one_cdna_2.map((a) => a.id),
                });
              }
            }
            if (this.state.visibleTwoModal_cdna === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_two_cdna.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_two_cdna_2.map((a) => a.id),
                });
              }
            }
            if (this.state.visibleIneligibleModal_cdna === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_ineligible_cdna.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_ineligible_cdna_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
            if (this.state.visibleOneModal_cdnr === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_one_cdnr.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_one_cdnr_2.map((a) => a.id),
                });
              }
            }
            if (this.state.visibleTwoModal_cdnr === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_two_cdnr.map((a) => a.id),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_two_cdnr_2.map((a) => a.id),
                });
              }
            }
            if (this.state.visibleIneligibleModal_cdnr === true) {
              if (this.state.currentYear) {
                this.setState({
                  selectedRowKeys: this.state.recon_ineligible_cdnr.map(
                    (a) => a.id
                  ),
                });
              } else {
                this.setState({
                  selectedRowKeys: this.state.recon_ineligible_cdnr_2.map(
                    (a) => a.id
                  ),
                });
              }
            }
          },
        },
      ],
    };

    const rowSelection_mismatch = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      getCheckboxProps: (r) => {
        console.log(this.state.visibleModal);
        if (this.state.visibleModal) {
          return {
            disabled: r.type !== "prb2b",
          };
        }
        if (this.state.visibleModal_cdnr) {
          return {
            disabled: r.type !== "prcdnr",
          };
        }
        if (
          this.state.visibleModal === null ||
          this.state.visibleModal_cdnr === null
        ) {
          return {
            disabled: false,
          };
        }
      },
    };

    function sortDate(a, b) {
      const firstDateArray = a.split("-");
      const secondDateArray = b.split("-");
      // console.log(new Date(firstDateArray[2],firstDateArray[1],firstDateArray[0]))
      // console.log(new Date(secondDateArray[2],secondDateArray[1],secondDateArray[0]));
      // console.log();
      return (
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0]) -
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0])
      );
    }

    const rowSelection_likely = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      selections: [
        {
          key: "odd",
          text: "Select All PR",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            if (this.state.visibleLikelyModal) {
              if (this.state.currentYear) {
                newSelectedRowKeys = this.state.recon_likely_b2b.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return false;
                    }
                    return true;
                  }
                );
              } else {
                newSelectedRowKeys = this.state.recon_likely_b2b_2.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return false;
                    }
                    return true;
                  }
                );
              }
            }
            if (this.state.visibleLikelyModal_cdnr) {
              if (this.state.currentYear) {
                newSelectedRowKeys = this.state.recon_likely_cdnr.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return false;
                    }
                    return true;
                  }
                );
              } else {
                newSelectedRowKeys = this.state.recon_likely_cdnr_2.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return false;
                    }
                    return true;
                  }
                );
              }
            }

            this.setState({
              selectedRowKeys: newSelectedRowKeys.map((a) => a.id),
            });
          },
        },
        {
          key: "even",
          text: "Select All GSTN",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            if (this.state.visibleLikelyModal) {
              if (this.state.currentYear) {
                newSelectedRowKeys = this.state.recon_likely_b2b.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return true;
                    }
                    return false;
                  }
                );
              } else {
                newSelectedRowKeys = this.state.recon_likely_b2b_2.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return true;
                    }
                    return false;
                  }
                );
              }
            }
            if (this.state.visibleLikelyModal_cdnr) {
              if (this.state.currentYear) {
                newSelectedRowKeys = this.state.recon_likely_cdnr.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return true;
                    }
                    return false;
                  }
                );
              } else {
                newSelectedRowKeys = this.state.recon_likely_cdnr_2.filter(
                  (key, index) => {
                    if (index % 2 !== 0) {
                      return true;
                    }
                    return false;
                  }
                );
              }
            }
            this.setState({
              selectedRowKeys: newSelectedRowKeys.map((a) => a.id),
            });
          },
        },
      ],
    };


    const hasSelected = selectedRowKeys.length > 0;
    console.log(
      "--------------------------  Reconcile Container render ----------------------"
    );
    return (
      <Content>
        <ReconcileSummary
          pr_ret_period_masters={get("viewer.pr_ret_period_masters.edges")(
            this.props.data
          )}
          role={get("company.role", this.props)}
          finMonth={this.state.finMonth}
          finYear={this.state.finYear}
          nextFY={this.state.nextFY}
          showConfirm={this.showConfirm}
          onDropPR={this.onDropPR}
          fetchFromExternalAPI={this.fetchFromExternalAPI}
          confirmFetchfromExternalAPI={this.confirmFetchfromExternalAPI}
          externalInterfaceInfo={get("externalInterfaceInfo", this.props)}
          // onDropPR_WS={this.onDropPR_WS}
          // onDropReconPR={this.onDropReconPR}
          onTwoA={this.onTwoA}
          onTwoB={this.onTwoB}
          visibleTwoA={this.state.visible2a}
          handleCancelTwoA={this.handleCancelTwoA}
          loading={false}
          recon_loading={this.state.recon_loading}
          sales_loading={this.state.sales_loading}
          // gst_loading={this.state.gst_loading}
          // pending_load={this.state.pending_load}
          showModal={this.showModal}
          downExcel={this.TransGSTOnly}
          downExcel_cdnr={this.cdnrExcelDownload}
          fetchMissingNames={this.fetchMissingNames}
          hideModal={this.hideModal}
          hideLikelyModal={this.hideLikelyModal}
          hideMatchModal={this.hideMatchModal}
          hideMatchModal_b2ba={this.hideMatchModal_b2ba}
          hideOneModal_b2ba={this.hideOneModal_b2ba}
          hideTwoModal_b2ba={this.hideTwoModal_b2ba}
          hideLikelyModal_b2ba={this.hideLikelyModal_b2ba}
          hideIneligibleModal_b2ba={this.hideIneligibleModal_b2ba}
          hideModal_b2ba={this.hideModal_b2ba}
          hideOneModal={this.hideOneModal}
          hideTwoModal={this.hideTwoModal}
          hideIneligibleModal={this.hideIneligibleModal}
          hideModal_cdnr={this.hideModal_cdnr}
          hideLikelyModal_cdnr={this.hideLikelyModal_cdnr}
          hideMatchModal_cdnr={this.hideMatchModal_cdnr}
          hideOneModal_cdnr={this.hideOneModal_cdnr}
          hideTwoModal_cdnr={this.hideTwoModal_cdnr}
          hideIneligibleModal_cdnr={this.hideIneligibleModal_cdnr}
          hideModal_cdna={this.hideModal_cdna}
          showItemModal={this.showItemModal}
          notifyViaEmail={this.notifyViaEmail}
          notifyViaEmail_CDNR={this.notifyViaEmail_CDNR}
          hideItemModal={this.hideItemModal}
          compareSales={this.compareSales}
          hideModal_cdna={this.hideModal_cdna}
          hideMatchModal_cdna={this.hideMatchModal_cdna}
          hideLikelyModal_cdna={this.hideLikelyModal_cdna}
          hideOneModal_cdna={this.hideOneModal_cdna}
          hideTwoModal_cdna={this.hideTwoModal_cdna}
          hideIneligibleModal_cdna={this.hideIneligibleModal_cdna}
          isLikelyAction = {this.likelyAction}
          resetPurchase={this.resetPurchase}
          output_b2b={this.state.output_b2b}
          recon_matches_b2b={this.state.recon_matches_b2b}
          recon_mismatches_b2b={this.state.recon_mismatches_b2b}
          recon_one_b2b={this.state.recon_one_b2b}
          recon_two_b2b={this.state.recon_two_b2b}
          recon_likely_b2b={this.state.recon_likely_b2b}
          recon_ineligible_b2b={this.state.recon_ineligible_b2b}
          recon_matches_b2b_2={this.state.recon_matches_b2b_2}
          recon_mismatches_b2b_2={this.state.recon_mismatches_b2b_2}
          recon_one_b2b_2={this.state.recon_onlypr_b2b_2}
          recon_two_b2b_2={this.state.recon_onlygst_b2b_2}
          recon_likely_b2b_2={this.state.recon_likely_b2b_2}
          recon_ineligible_b2b_2={this.state.recon_ineligible_b2b_2}
          output_cdnr={this.state.output_cdnr}
          recon_matches_cdnr={this.state.recon_matches_cdnr}
          recon_mismatches_cdnr={this.state.recon_mismatches_cdnr}
          recon_one_cdnr={this.state.recon_one_cdnr}
          recon_two_cdnr={this.state.recon_two_cdnr}
          recon_likely_cdnr={this.state.recon_likely_cdnr}
          recon_ineligible_cdnr={this.state.recon_ineligible_cdnr}
          recon_matches_cdnr_2={this.state.recon_matches_cdnr_2}
          recon_mismatches_cdnr_2={this.state.recon_mismatches_cdnr_2}
          recon_one_cdnr_2={this.state.recon_one_cdnr_2}
          recon_two_cdnr_2={this.state.recon_two_cdnr_2}
          recon_likely_cdnr_2={this.state.recon_likely_cdnr_2}
          recon_ineligible_cdnr_2={this.state.recon_ineligible_cdnr_2}
          recon_matches_b2ba={this.state.recon_matches_b2ba}
          recon_mismatches_b2ba={this.state.recon_mismatches_b2ba}
          recon_two_b2ba={this.state.recon_onlygst_b2ba}
          recon_matches_b2ba_2={this.state.recon_matches_b2ba_2}
          recon_mismatches_b2ba_2={this.state.recon_mismatches_b2ba_2}
          recon_onlygst_b2ba_2={this.state.recon_onlygst_b2ba_2}
          recon_matches_cdna={this.state.recon_matches_cdna}
          recon_mismatches_cdna={this.state.recon_mismatches_cdna}
          recon_two_cdna={this.state.recon_two_cdna}
          recon_matches_cdna_2={this.state.recon_matches_cdna_2}
          recon_mismatches_cdna_2={this.state.recon_mismatches_cdna_2}
          recon_two_cdna_2={this.state.recon_two_cdna_2}
          visibleModal={this.state.visibleModal}
          visibleLikelyModal={this.state.visibleLikelyModal}
          visibleItemModal={this.state.visibleItemModal}
          visibleMatchModal={this.state.visibleMatchModal}
          visibleMatchModal_b2ba={this.state.visibleMatchModal_b2ba}
          visibleModal_b2ba={this.state.visibleModal_b2ba}
          visibleOneModal_b2ba={this.state.visibleOneModal_b2ba}
          visibleTwoModal_b2ba={this.state.visibleTwoModal_b2ba}
          visibleLikelyModal_b2ba={this.state.visibleLikelyModal_b2ba}
          visibleIneligibleModal_b2ba={this.state.visibleIneligibleModal_b2ba}
          visibleMatchItemModal={this.state.visibleMatchItemModal}
          visibleOneModal={this.state.visibleOneModal}
          visibleOneItemModal={this.state.visibleOneItemModal}
          visibleTwoModal={this.state.visibleTwoModal}
          visibleModal_cdnr={this.state.visibleModal_cdnr}
          visibleLikelyModal_cdnr={this.state.visibleLikelyModal_cdnr}
          visibleMatchModal_cdnr={this.state.visibleMatchModal_cdnr}
          visibleTwoModal_cdnr={this.state.visibleTwoModal_cdnr}
          visibleOneModal_cdnr={this.state.visibleOneModal_cdnr}
          visibleIneligibleModal_cdnr={this.state.visibleIneligibleModal_cdnr}
          visibleMatchModal_cdna={this.state.visibleMatchModal_cdna}
          visibleModal_cdna={this.state.visibleModal_cdna}
          visibleOneModal_cdna={this.state.visibleOneModal_cdna}
          visibleTwoModal_cdna={this.state.visibleTwoModal_cdna}
          visibleLikelyModal_cdna={this.state.visibleLikelyModal_cdna}
          visibleIneligibleModal_cdna={this.state.visibleIneligibleModal_cdna}
          visibleTwoItemModal={this.state.visibleTwoItemModal}
          isSR={this.props.isSR}
          downloadLoading={this.state.downloadLoading}
          resetLoading={this.state.resetLoading}
          requestOTP={this.state.requestOTP}
          handleOTPSubmit={this.handleOTPSubmit}
          handleCancel={this.handleCancel}
          saveFormRef={this.saveFormRef}
          save2AFormRef={this.save2AFormRef}
          save2BFormRef={this.save2BFormRef}
          pull2A={this.state.pull2A}
          pull2B={this.state.pull2B}
          handleGet2ASubmit={this.handleGet2ASubmit}
          handleGet2ACancel={this.handleGet2ACancel}
          handleGet2BSubmit={this.handleGet2BSubmit}
          handleGet2BCancel={this.handleGet2BCancel}
          formatNumber={formatNumber}
          handleSearch={this.handleSearch}
          handleReset={this.handleReset}
          searchText={this.state.searchText}
          isRetInProgressVisible={this.state.retInProgress}
          retInProgressData={this.state.retInProgressData}
          rowSelection={rowSelection}
          rowSelection_mismatch={rowSelection_mismatch}
          rowSelection_likely={rowSelection_likely}
          hasSelected={hasSelected}
          selectedRowKeys={this.state.selectedRowKeys}
          sortDate={sortDate}
          popupConfirmResetVisibleChange={this.popupConfirmResetVisibleChange}
          popupConfirmReset={this.state.popupConfirmReset}
          reconcilePrompt={this.reconcilePrompt}
          showModalMail_Mismatch={this.state.showModalMail_Mismatch}
          showModalMail_Mismatch_CDNR={this.state.showModalMail_Mismatch_CDNR}
          showModalMail_likely={this.state.showModalMail_likely}
          showModalMail_PR={this.state.showModalMail_PR}
          showModalMail_PR_CDNR={this.state.showModalMail_PR_CDNR}
          handleCancelEmail={this.handleCancelEmail}
          mailTextBox={this.state.mailText}
          mailReportDownload={this.mailReportDownload}
          addSimilar={this.addSimilar}
          mailPayload={this.state.mailPayload}
          handleCloseInvoiceTags={this.handleCloseInvoiceTags}
          invoiceTags={this.state.invoiceTags}
          notifyViaEmailSend={this.notifyViaEmailSend}
          userMailID={this.props.users_list_me}
          emailTags={this.state.emailTags}
          inputVisibleEmail={this.state.inputVisibleEmail}
          inputValueEmail={this.state.inputValueEmail}
          handleCloseEmail={this.handleCloseEmail}
          showInputEmail={this.showInputEmail}
          handleInputChangeEmail={this.handleInputChangeEmail}
          handleInputConfirmEmail={this.handleInputConfirmEmail}
          saveInputRefEmail={this.saveInputRefEmail}
          showModalPrompt={this.state.showModalPrompt}
          handleOkPrompt={this.handleOkPrompt}
          handleCancelPrompt={this.handleCancelPrompt}
          indeterminate_b2b={this.state.indeterminate_b2b}
          onCheckAllChange_b2b={this.onCheckAllChange_b2b}
          checkAll_b2b={this.state.checkAll_b2b}
          checkedList_b2b={this.state.checkedList_b2b}
          onChangeCB_b2b={this.onChangeCB_b2b}
          indeterminate_cdnr={this.state.indeterminate_cdnr}
          onCheckAllChange_cdnr={this.onCheckAllChange_cdnr}
          checkAll_cdnr={this.state.checkAll_cdnr}
          checkedList_cdnr={this.state.checkedList_cdnr}
          onChangeCB_cdnr={this.onChangeCB_cdnr}
          onCheckSelect2Aor2B={this.onCheckSelect2Aor2B}
          check_2Aor2B_b2b={this.check_2Aor2B_b2b}
          onRoundOffChange={this.onRoundOffChange}
          disableRoundOffInput={this.state.disableRoundOffInput}
          showCDNR={this.state.showCDNR}
          showIMPG={this.state.showIMPG}
          showB2B={this.state.showB2B}
          onDropTallyPR={this.onDropTallyPR}
          mailContentChange={this.mailContentChange}
          viewCPdata={this.viewCPdata}
          visibleCP_Modal={this.state.visibleCP_Modal}
          hideCPModal={this.hideCPModal}
          CP_DATA={this.state.CP_DATA}
          b2ba_data={this.state.b2ba_data}
          cdnr_data={this.state.cdnr_data}
          cdna_data={this.state.cdna_data}
          resetCPData={this.resetCPData}
          popupConfirmResetVisibleChange_CP={
            this.popupConfirmResetVisibleChange_CP
          }
          popupConfirmReset_CP={this.state.popupConfirmReset_CP}
          cpExcelDownload={this.cpExcelDownload}
          setDatasetInState={this.setDatasetInState}
          visibleIneligibleModal={this.state.visibleIneligibleModal}
          markAsIneligible={this.markAsIneligible}
          markAsEligible={this.markAsEligible}
          handleYearChangeRecon={this.handleYearChangeRecon}
          handleFromMonthChangeRecon={this.handleFromMonthChangeRecon}
          handleToMonthChangeRecon={this.handleToMonthChangeRecon}
          recon_year={this.state.recon_year}
          recon_from_month={this.state.recon_from_month}
          recon_to_month={this.state.recon_to_month}
          handleMismatchIneligible={this.handleMismatchIneligible}
          handleClickStart={this.handleClickStart}
          markAsIneligibleLikely={this.markAsIneligibleLikely}
          m_id={this.state.m_id}
          dataset_popup_details={this.state.datasetForDetails}
          fetchSupplierNamesForViewSupplierData={
            this.fetchSupplierNamesForViewSupplierData
          }
          isDrawerVisible={this.state.isDrawerVisible}
          onMessageDrawer={this.onMessageDrawer}
          sendWA={this.sendWA}
        />
      </Content>
    );
  }
}

const queryConfig = {
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
};

const usersQueryConfig = {
  name: "company_users",
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
  props: ({ company_users }) => {
    return {
      company_users: company_users,
      users_list_me: get("viewer.me", company_users),
      users_list: get("viewer.companyUsers.edges", company_users),
    };
  },
};

const companyQueryConfig = {
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ data }) => {
    return {
      company: get("viewer.company", data),
      isEdit: true,
      gspDetails: get("viewer.gspInfo", data),
      externalInterfaceInfo: get("viewer.externalInterfaceInfo", data),
    };
  },
};


const mapStateToProps = (state) => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    // output_b2b: state.output_b2b,
    // recon_matches_b2b: state.recon_matches_b2b,
    // recon_matches_b2b_2: state.recon_matches_b2b_2,
    // recon_mismatches_b2b: state.recon_mismatches_b2b,
    // recon_mismatches_b2b_2: state.recon_mismatches_b2b_2,
    // recon_one_b2b: state.recon_one_b2b,
    // recon_one_b2b: state.recon_one_b2b,
    // recon_two_b2b: state.recon_two_b2b,
    // recon_likely_b2b: state.recon_likely_b2b,

    // recon_matches_b2ba: state.recon_matches_b2ba,
    // recon_matches_b2ba_2: state.recon_matches_b2ba_2,
    // recon_mismatches_b2ba: state.recon_mismatches_b2ba,
    // recon_mismatches_b2ba_2: state.recon_mismatches_b2ba_2,
    // recon_one_b2ba: state.recon_one_b2ba,
    // recon_two_b2ba: state.recon_two_b2ba,
    // recon_likely_b2ba: state.recon_likely_b2ba,
    // recon_likely_b2ba_2: state.recon_likely_b2ba_2,
    // recon_ineligible_b2ba: state.recon_ineligible_b2ba,
    // recon_ineligible_b2ba_2: state.recon_ineligible_b2ba_2,
    // recon_onlypr_b2ba_2: state.recon_onlypr_b2ba_2,
    // recon_onlygst_b2ba_2: state.recon_onlygst_b2ba_2,

    // recon_mismatches_cdnr: state.recon_mismatches_cdnr,
    // recon_matches_cdnr: state.recon_matches_cdnr,
    // recon_one_cdnr: state.recon_one_cdnr,
    // recon_two_cdnr: state.recon_two_cdnr,
    // recon_likely_cdnr: state.recon_likely_cdnr,
    gspDetails: state.gsp,
  };


};

export default compose(
  connect(mapStateToProps, {
    setGspToken: gspActions.setGspToken,
    setGspOtp: gspActions.setGspOtp,
    setGspDetails: gspActions.setGspDetails,
  }),
  graphql(gql(CompanyUsersQuery), usersQueryConfig),
  graphql(gql(PR_RET_Period_Query), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(ReconcileContainer);

