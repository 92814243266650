import React from 'react'
import { Route } from 'react-router-dom'
import RET1_Parent from './RET1_Parent'
import RET1_DASHBOARD from "./RET1_DASHBOARD";
import { ROUTES } from '../../shared/constants'


export default () =>
    <div>
        <Route exact path={ROUTES.ANX1_RET} component={RET1_DASHBOARD} />
        <Route exact path={ROUTES.ANX1_RET_PARENT} component={RET1_Parent} />
    </div>
