/**
 * @flow
 */
import React from 'react'
import Modal from 'antd/lib/modal'
import Table from 'antd/lib/table'
import { formatNumber } from "../../shared/constants";
const { ColumnGroup, Column } = Table

type props = {
    isVisible: boolean,
    onCancel: Function,
    v2_recon_pr_sezwop_items: Array,
}

export default (props: props) =>
    <Modal title="Anx2 SEZWOP Items" visible={props.isVisible} onCancel={props.onCancel} footer={null} width="90%">
        <Table dataSource={props.v2_recon_pr_sezwop_items} bordered rowKey="gstin" pagination={{ className: 'items-pagination' }}>
            <Column title="HSN Code" dataIndex="hsn_code" key="hsn_code" />
            <Column title="Rate" dataIndex="gst_rate" key="gst_rate" />
            <Column title="Taxable Value" dataIndex="txval" key="txval" render={val => formatNumber(val === null ? 0 : val)} />
        </Table>
    </Modal>
