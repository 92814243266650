import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import LocaleProvider from 'antd/lib/locale-provider'
import enUS from 'antd/lib/locale-provider/en_US'
import {
  ApolloClient,
  ApolloProvider,
  createNetworkInterface,
} from 'react-apollo'

import { BASE_URL, LOCAL_STORAGE } from './shared/constants'
import { gspReducers } from './gspRedux'
import { reducers } from './redux'
import App from './components/App'
// import registerServiceWorker from './registerServiceWorker'
import './index.css'
import { unregister } from './registerServiceWorker';
unregister();

const networkInterface = createNetworkInterface({
  uri: BASE_URL + '/gql',
})
networkInterface.use([
  {
    applyMiddleware(req, next) {
      if (!req.options.headers) {
        req.options.headers = {} // Create the header object if needed.
      }
      req.options.headers['Authorization'] = `Bearer ${localStorage.getItem(
        LOCAL_STORAGE.TOKEN
      )}`
      next()
    },
  },
])
const client = new ApolloClient({ networkInterface })

const store = createStore(
  combineReducers({
    local: reducers,
    apollo: client.reducer(),
    gsp: gspReducers,
  }),
  {}, // initial state
  compose(
    applyMiddleware(client.middleware()),
    // If you are using the devToolsExtension, you can add it here also
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
)

ReactDOM.render(
  <ApolloProvider client={client} store={store}>
    <LocaleProvider locale={enUS}>
      <App />
    </LocaleProvider>
  </ApolloProvider>,
  document.getElementById('root')
)
// registerServiceWorker()
