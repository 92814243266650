import React from 'react'
import { Route } from 'react-router-dom'
import GSTR9Dashboard from './GSTR9Dashboard'
import EnterGSTR9_P_TWO from './EnterGSTR9_P_TWO'
import EnterGSTR9_P_THREE from './EnterGSTR9_P_THREE'
import EnterGSTR9_P_FOUR from './EnterGSTR9_P_FOUR'
import EnterGSTR9_P_FIVE from './EnterGSTR9_P_FIVE'
import EnterGSTR9_P_SIX from './EnterGSTR9_P_SIX'
import gstr9Form from './main'


import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'


export default () =>
    <Root>
      <Route exact path={ROUTES.GST_FILINGS_GSTR9} component={gstr9Form} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR9_P2} component={EnterGSTR9_P_TWO} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR9_P3} component={EnterGSTR9_P_THREE} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR9_P4} component={EnterGSTR9_P_FOUR} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR9_P5} component={EnterGSTR9_P_FIVE} />
      <Route exact path={ROUTES.GST_FILINGS_GSTR9_P6} component={EnterGSTR9_P_SIX} />
    </Root>
