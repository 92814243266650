/**
 * @flow
 */
import React from 'react'
import Col from 'antd/lib/col'
import Table from 'antd/lib/table'
import Input from 'antd/lib/input'
import Icon from 'antd/lib/icon'
import Button from 'antd/lib/button'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'
import { Link } from 'react-router-dom'
import get from 'lodash/get'

import {
  ContentWrapper as Content,
  MarginedRow as Row,
} from '../../shared/styles'
import {
  CompanyQuery,
  CompanyDeleteMutation,
} from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { ROUTES } from '../../shared/constants'
import { Button as B } from '../../shared/styles'
import ProfileSelModal from '../NF/Profile/ProfileModal'
const { Column } = Table  
const add_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.80', padding: '0 15px', fontSize: '12px', borderRadius: '5px', height: '33px', mozUserSelect: 'none', position: 'relative', color: '#000000a6', backgroundColor: 'rgb(35, 130, 199)', borderColor: '#d9d9d9', paddingTop: '5px'};
const edit_button = { outline: '0', display: 'inline-block', marginBottom: '0', marginRight: '5', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.80', padding: '0 15px', fontSize: '12px', borderRadius: '5px', height: '33px', mozUserSelect: 'none', position: 'relative', color: '#000000a6', backgroundColor: 'rgb(74, 101, 121)', borderColor: '#d9d9d9', paddingTop: '5px', width: '70px'};
const user_button = { outline: '0', display: 'inline-block', marginBottom: '0', marginRight: '5', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.80', padding: '0 15px', fontSize: '12px', borderRadius: '5px', height: '33px', mozUserSelect: 'none', position: 'relative', color: '#000000', backgroundColor: 'rgb(5, 112, 107)', borderColor: '#d9d9d9', paddingTop: '5px', width: '70px'};

const CompanyList = (props) =>
  <Content>
    <Row gutter={24}>
      <Col span="4">
        <a style={add_button}>
          <Link to={ROUTES.COMPANY_ADD}>Add<span> Company</span></Link>
        </a>

      </Col>
    </Row>
    <Table dataSource={props.companies} bordered rowKey="gstin">
      <Column title="GSTIN/UID" dataIndex="gstin" key="gstin" className="alignCenter"
      filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                          <div className="custom-filter-dropdown">
                            <Input
                              placeholder="Search GSTN"
                              value={selectedKeys[0]}
                              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                              onPressEnter={props.handleSearch(selectedKeys, confirm)}
                            />
                          <Button type="primary" onClick={props.handleSearch(selectedKeys, confirm)}>Search</Button>
                            <Button id="gstnSearch" onClick={props.handleReset(clearFilters)}>Reset</Button>
                          </div>
                        )}
                        filterIcon={filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />}
                        onFilter={(value, record) => record.gstin.toLowerCase().includes(value.toLowerCase())}
                        render={(text) => {
                          return props.searchText ? (
                            <span>
                              {text.split(new RegExp(`(?<=${props.searchText})|(?=${props.searchText})`, 'i')).map((fragment, i) => (
                                fragment.toLowerCase() === props.searchText.toLowerCase()
                                  ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
                              ))}
                            </span>
                          ) : text;
                        }}
      />
      <Column title="Company Name" dataIndex="name" key="name" className="alignCenter"
      filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                          <div className="custom-filter-dropdown">
                            <Input
                              placeholder="Search Company Name"
                              value={selectedKeys[0]}
                              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                              onPressEnter={props.handleSearch(selectedKeys, confirm)}
                            />
                          <Button type="primary" onClick={props.handleSearch(selectedKeys, confirm)}>Search</Button>
                            <Button id="gstnSearch" onClick={props.handleReset(clearFilters)}>Reset</Button>
                          </div>
                        )}
                        filterIcon={filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />}
                        onFilter={(value, record) => record.name.toLowerCase().includes(value.toLowerCase())}
                        render={(text) => {
                          return props.searchText ? (
                            <span>
                              {text.split(new RegExp(`(?<=${props.searchText})|(?=${props.searchText})`, 'i')).map((fragment, i) => (
                                fragment.toLowerCase() === props.searchText.toLowerCase()
                                  ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
                              ))}
                            </span>
                          ) : text;
                        }}
      />
      <Column
        title="Actions"
        dataIndex="actions"
        key="actions"
        className="alignCenter"
        render={(text, record) => {
          if (record.role !== 'admin') return <span />
          return (
            <span>
              <a style={user_button} type="primary">
                <Link to={ROUTES.COMPANY_USERS.replace(':id', record.id)}>
                  Users
                </Link>
              </a>
              <a style={edit_button} type="primary">
                <Link to={ROUTES.COMPANY_EDIT.replace(':id', record.id)}>
                  Edit
                </Link>
              </a>
              <B onClick={()=>props.showProfileModal(record.id)} style={{ backgroundColor: '#a01208de', color: 'white'}}>Profile</B>
              <ProfileSelModal isVisible={props.visibleProfileModal === record.id} onCancel={props.hideProfileModal} profile_values={null} />
              <Popconfirm
                title="Are you sure delete this company?"
                onConfirm={() => props.deleteCompany(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <B style={{ backgroundColor: '#a01208de', fontSize: '13px', color: 'white'}}>Delete</B>
              </Popconfirm>
            </span>
          )
        }}
      />
    </Table>
  </Content>

export class CompanyListContainer extends React.Component {
  state = { searchText: '' }
  showProfileModal = id => {
    this.setState({ visibleProfileModal: id })
    //message.success('showProfileModal : ' + id)
  }
  hideProfileModal = () => {
    this.setState({ visibleProfileModal: null })
    //message.success('hideProfileModal : ')
  }
  deleteCompany = async id => {
    try {
      await this.props.mutate(id)
      message.success('Company has been deleted!')
    } catch (e) {
      message.error(e.graphQLErrors[0].message)
    }
  }
  handleSearch = (selectedKeys, confirm) => () => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  }

  handleReset = clearFilters => () => {
    clearFilters();
    this.setState({ searchText: '' });
  }
  render() {
    console.log("this.props.form")
    console.log(this.props.form)
    console.log(this.form)
    return (
     

      <CompanyList
        showProfileModal={this.showProfileModal}
        hideProfileModal={this.hideProfileModal}        
        visibleProfileModal={this.state.visibleProfileModal}
        companies={get(this.props, 'data.viewer.companies.edges')}
        deleteCompany={this.deleteCompany}
        handleSearch={this.handleSearch}
        handleReset={this.handleReset}
        searchText={this.state.searchText}
      />
    )
  }
}

const companyQueryConfig = {
  options: { variables: {} },
}
const companyDeleteMutationConfig = {
  props: ({ mutate }) => {
    return {
      mutate: async id => {
        await mutate({
          variables: { id },
          refetchQueries: [
            {
              query: gql(CompanyQuery),
            },
          ],
        })
      },
    }
  },
}
export default compose(
  graphql(gql(CompanyQuery), companyQueryConfig),
  graphql(gql(CompanyDeleteMutation), companyDeleteMutationConfig)
)(CompanyListContainer)
