import React from 'react'
import { Form, Input, message, Table, Modal, Tag, Popconfirm, Button, Tooltip, notification, Icon, Timeline } from 'antd'
import flow from "lodash/fp/flow";
import find from "lodash/fp/find";
import map from "lodash/fp/map";
import omit from "lodash/fp/omit";
import groupBy from "lodash/fp/groupBy";
import entries from "lodash/fp/entries";
import pick from "lodash/fp/pick";
import findIndex from "lodash/fp/findIndex";
import flattenDeep from "lodash/fp/flattenDeep";
import toString from "lodash/fp/toString";
import { withRouter } from "react-router-dom";
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import get from "lodash/fp/get";
import filter from 'lodash/fp/filter';
import Glamorous from 'glamorous'
import { connect } from "react-redux";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { formatNumber, stateArray, ROUTES, i18n } from "../../shared/constants";
import gspAuth from "../../services/GSPService";
import Auth from '../../services/AuthService'
import { BASE_URL } from '../../shared/constants'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import moment from 'moment';
import { actions } from '../../redux'
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import { FormItem } from '../../shared/utils'
const { ColumnGroup, Column } = Table
const errorModal = Modal.error;


class FetchArchives extends React.Component {


  constructor(props) {
    super(props);
    console.log(props);
    console.log(this.props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
        response_delete: null,
      };
    } else {
      this.gsp = {};
    }

  }

  componentDidUpdate = () => {
    console.log(this.props);
    console.log(this.prevProps);
    console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    this.setState({ requestOTP: false });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.getAllGSTR1Data();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visibleModal: null,
    visible: false,
    downloadLoading: false,
    requestOTP: false,
    otp: null,
    period: null,
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  periodArrayValues = () => {
    var dateStart = moment("072017","MMYYYY");
    var dateEnd = moment();
    console.log("dateStart :" + dateStart + "---" + "dateEnd :" + dateEnd);
    var period = [];

    while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
      period.push(dateStart.format("MMYYYY"));
      dateStart.add(1, "month");
    }
    console.log(period);
    return period;
  };

  getAllGSTR1Data = async() => {
    message.destroy();
    message.loading('Fetching from GSTN...',0)
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const reqDataValues = {

    actionARRAY : [ "B2B", "B2CL", "B2CS", "CDNR", "CDNUR", "EXP", "AT", "TXP", "NIL", "HSNSUM", "DOCISS",
                          "B2BA", "B2CLA", "B2CSA", "CDNRA", "CDNURA", "EXPA", "ATA", "TXPA"
                        ],
    periodARRAY:  this.periodArrayValues()

    }

    console.log(reqDataValues);

    const gstr1ARCHIVES = map(

      i => {
        console.log(i);
        var tempPeriod = i;

        const gstr1ARCHIVES_Loop_Action = map(
          async d => {
            console.log(d);
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp
              },
              period: tempPeriod,
              action: d
            };
              console.log(reqData);
              this.gsp.response = await gspAuth.getB2BData(reqData);
              console.log(this.gsp.response);
          }
        )(reqDataValues.actionARRAY)

      }

    )(reqDataValues.periodARRAY)

}

render () {


return (
<Root>
  <Content>
  <Popconfirm placement="bottomRight" title="Clicking this button will fetch your GSTR1 B2B invoices from GSTN!" onConfirm={this.getAllGSTR1Data} okText="Yes" cancelText="No">
    <Button style={{ backgroundColor: '#04880fe0', fontSize: '13px', color: 'white'}} icon="cloud-upload">Fetch GSTR1 Archives</Button>
  </Popconfirm>
  
  <OTPForm
    wrappedComponentRef={this.saveFormRef}
    visible={this.state.requestOTP}
    onSubmit={this.handleOTPSubmit}
    onCancel={this.handleCancel}
  />
  </Content>
</Root>

)
}
}


const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(FetchArchives)


// const TimeLine = props =>
//   console.log(props.arrayValues);
//   <Timeline>
//     map(
//     d => {
//       return (<Timeline.Item>d</Timeline.Item>)
//       }
//     )(props.arrayValues)
//   </Timeline>
