import React from "react";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DatePicker from "antd/lib/date-picker";
import Modal from "antd/lib/modal";
import moment from "moment";

const FormItem = Form.Item;
const {MonthPicker} = DatePicker;
const Get2BForm = props => (
  <Modal
    visible={props.visible}
    title="Kindly select the Month Range to fetch Counterparty (2B) data."
    okText="Submit"
    onCancel={props.onCancel}
    onOk={props.onSubmit}
  >
    <Form layout="horizontal">
      <Row>
        <Col span={12}>
          <FormItem label="From Month">
            {props.getFieldDecorator("from_month", props.rangeConfig)(
              <MonthPicker
                format="MM-YYYY"
                placeholder="Select Start Month"
                disabledDate={props.disabledDate}
                onChange={props.onFromChange}
              />
            )}
          </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="To Month">
          {props.getFieldDecorator("to_month", props.rangeConfig)(
            <MonthPicker
              format="MM-YYYY"
              placeholder="Select End Month"
              disabledDate={props.disabledToDate}
              disabled={props.isToDisabled}
            />
          )}
        </FormItem>
      </Col>
    </Row>
    </Form>
  </Modal>
);

class Get2BFormContainer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isToDisabled: true,
      fromDate: '07-2017'
    }
    this.onFromChange = this.onFromChange.bind(this)
  }

  onFromChange = (d, ds) => {
    this.setState({isToDisabled:false, fromDate: ds})
  }

  disabledDate = (current) => {
    // Can not select days before today and today
    return (current && current < moment("30-06-2017","DD-MM-YYYY").endOf('day')) || (current && current > moment().endOf('day'));
  }

  disabledToDate = (current) => {
    return (current && current < moment(this.state.fromDate,"MM-YYYY").startOf('month')) || (current && current > moment().endOf('day')) || (current && current > moment(this.state.fromDate,"MM-YYYY").add(6,'months').startOf('month'));
  }
  rangeConfig = {
    rules: [
      { type: "object", required: true, message: "Please select desired Month!" }
    ]
  };
  render() {
    return (
      <Get2BForm
        visible={this.props.visible}
        onSubmit={this.props.onSubmit}
        onCancel={this.props.onCancel}
        getFieldDecorator={this.props.form.getFieldDecorator}
        rangeConfig={this.rangeConfig}
        disabledDate={this.disabledDate}
        isToDisabled={this.state.isToDisabled}
        onFromChange={this.onFromChange}
        disabledToDate={this.disabledToDate}
      />
    );
  }
}

export default Form.create()(Get2BFormContainer);
