import React from 'react'
import { Form, InputNumber, Anchor, Layout, Row, Col, Popconfirm, Button, message, Icon,
notification, Tooltip, Modal } from 'antd'
import { gql, graphql, compose, withApollo } from 'react-apollo'
import toString from "lodash/fp/toString";
import flow from "lodash/fp/flow";
import pick from "lodash/fp/pick";
import { BASE_URL } from '../../shared/constants'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import get from 'lodash/fp/get'
import { LedgerBalanceQuery, osup_detQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { ContentWrapper } from '../../shared/styles'
import { FormItem } from '../../shared/utils'
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import gspAuth from "../../services/GSPService";
const errorModal = Modal.error;

const { Link } = Anchor;
const { Sider, Content } = Layout;

const PayTaxComponent = props =>
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR3B}>
    <h3 style={{ marginLeft: "35"}} id="link1">(A) Your Tax Summary for Jun 2018</h3>
    <h4 style={{ marginLeft: "35"}}>The below tax summary is automatically populated based on your data from GSTR3B</h4>
    <Row style={{marginLeft: "8%"}}>
      <Col>
          <Row>
              <Col span={8}>
                <b>Type Of Invoice</b>
              </Col>
              <Col span={3}>
                <b>IGST</b>
              </Col>
              <Col span={3}>
                <b>CGST</b>
              </Col>
              <Col span={3}>
                <b>SGST</b>
              </Col>
              <Col span={3}>
                <b>CESS</b>
              </Col>
              <Col span={4}>
                <b>Total</b>
              </Col>
          </Row>
          <br />
          <br />
          <Row>
              <Col span={8}>
                Output Tax Liability other than Reverse Charge Supplies
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.osup_det_query ? props.challan.osup_det_query.iamt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.osup_det_query ? props.challan.osup_det_query.camt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.osup_det_query ? props.challan.osup_det_query.samt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.osup_det_query ? props.challan.osup_det_query.csamt : 0)}</p>
              </Col>
              <Col span={4}>
                <p>{props.formatNumber(props.challan.osup_det_query ? parseFloat(props.challan.osup_det_query.iamt) + parseFloat(props.challan.osup_det_query.camt) + parseFloat(props.challan.osup_det_query.samt) + parseFloat(props.challan.osup_det_query.csamt)  : 0)}</p>
              </Col>
          </Row>
          <br />
          <Row>
              <Col span={8}>
                Tax Liability under Reverse Charge
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.isup_rev_query ? props.challan.isup_rev_query.iamt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.isup_rev_query ? props.challan.isup_rev_query.camt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.isup_rev_query ? props.challan.isup_rev_query.samt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.isup_rev_query ? props.challan.isup_rev_query.csamt : 0)}</p>
              </Col>
              <Col span={4}>
                <p>{props.formatNumber(props.challan.isup_rev_query ? parseFloat(props.challan.isup_rev_query.iamt) + parseFloat(props.challan.isup_rev_query.camt) + parseFloat(props.challan.isup_rev_query.samt) + parseFloat(props.challan.isup_rev_query.csamt)  : 0)}</p>
              </Col>
          </Row>
          <br />
          <Row>
              <Col span={8}>
                Input Credit on Purchases including Reverse Charge
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.itc_net_query ? props.challan.itc_net_query.iamt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.itc_net_query ? props.challan.itc_net_query.camt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.itc_net_query ? props.challan.itc_net_query.samt : 0)}</p>
              </Col>
              <Col span={3}>
                <p>{props.formatNumber(props.challan.itc_net_query ? props.challan.itc_net_query.csamt : 0)}</p>
              </Col>
              <Col span={4}>
                <p>{props.formatNumber(props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.iamt) + parseFloat(props.challan.itc_net_query.camt) + parseFloat(props.challan.itc_net_query.samt) + parseFloat(props.challan.itc_net_query.csamt)  : 0)}</p>
              </Col>
          </Row>
        </Col>
      </Row>
    <br />
    <br />
    <br />
    <table style={{width:"100%"}}>
        <tr>
          <td rowSpan={2}>Description</td>
          <td colSpan={5}>Cash Ledger Balance</td>
          <td colSpan={5}>Credit Ledger Balance(including current month credit)</td>
        </tr>
        <tr>
            <td>IGST(₹)</td>
            <td>CGST(₹)</td>
            <td>SGST(₹)</td>
            <td>CESS(₹)</td>
            <td>TOTAL(₹)</td>
            <td>IGST(₹)</td>
            <td>CGST(₹)</td>
            <td>SGST(₹)</td>
            <td>CESS(₹)</td>
            <td>TOTAL(₹)</td>
        </tr>
          <tr>
            <td>Tax</td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.igst.tx : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_a', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.igst.tx : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cgst.tx : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_b', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cgst.tx : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.sgst.tx : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_c', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.sgst.tx : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cess.tx : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_d', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cess.tx : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_e', {

              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.igst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.iamt) : 0)))}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_f', {
                initialValue: ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.igst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.iamt) : 0)),
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.camt) : 0)))}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_g', {
                initialValue: ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.camt) : 0)),
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.sgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.samt) : 0)))}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_h', {
                initialValue: ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.sgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.samt) : 0)),
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
            <span>{props.formatNumber(((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cess_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.csamt) : 0)))}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_i', {
                initialValue: ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cess_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.csamt) : 0)),
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber((
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.igst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.iamt) : 0)) +
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.camt) : 0)) +
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.sgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.samt) : 0)) +
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cess_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.csamt) : 0))
                ))}</span>
            {/* <FormItem>
              {props.getFieldDecorator('tax_j', {
                initialValue: (
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.igst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.iamt) : 0)) +
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.camt) : 0)) +
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.sgst_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.samt) : 0)) +
                  ((props.ledger_tax.itc_bal ? props.ledger_tax.itc_bal.cess_bal : 0.00) + (props.challan.itc_net_query ? parseFloat(props.challan.itc_net_query.csamt) : 0))
                ),
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>

          </tr>
          <tr>
            <td>Interest</td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.igst.intr : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_a', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.igst.intr : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cgst.intr : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_b', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cgst.intr : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.sgst.intr : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_c', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.sgst.intr : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cess.intr : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_d', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cess.intr : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_e', {

              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_f', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_g', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_h', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_i', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('intr_j', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>

          </tr>
          <tr>
            <td>Late Fee</td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_a', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cgst.fee : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_b', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.cgst.fee : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.sgst.fee : 0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_c', {
                initialValue: props.ledger_tax.cash_bal ? props.ledger_tax.cash_bal.sgst.fee : 0.00,
              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)} disabled="true"  />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_d', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_e', {

              })(<InputNumber defaultValue={0.00} step={0.01} style={{ width: 130 }} formatter={value => props.formatNumber(value)}  disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_f', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_g', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_h', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_i', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>
            <td>
              <span>{props.formatNumber(0.00)}</span>
            {/* <FormItem>
              {props.getFieldDecorator('lf_j', {

              })(<InputNumber style={{ width: 130 }} disabled="true" />)}
            </FormItem> */}
            </td>

          </tr>
    </table>
    <br />
    <h3 style={{ marginLeft: "35"}} id="link3">Paid through ITC and Cash details (Payment of Tax details)</h3>
    <br />
    <h4 style={{ marginLeft: "35"}} id="link3">We have pre-calculated the Paid through ITC details based on Tax summary, Ledger data and Challan amount. You will need to enter these values on GSTN portal under Payment of Tax section.</h4>
    <br />
    <br />
    <div>
    <style>
          {`
            table tr td {
              border: 1px solid black;
              text-align: center;
              padding: 2px;
            }
      `}
    </style>
    </div>


    </Form>
  </ContentWrapper>

  class PayTax extends React.Component {

    state = {
      igstTax: null,
      cgstTax: null,
      sgstTax: null,
      csgstTax: null,
      igstAvail: null,
      cgstAvail: null,
      sgstAvail: null,
      csgstAvail: null,
    };

    componentDidUpdate = async () => {
      const waitData = await get('viewer.ledgerbalance_api.response.error', this.props.ledgerBalanceQuery)

      if(waitData === 'false' && this.state.igstAvail === null) {

        var igstBal_Ledg_3B = ((this.props.ledger_response.itc_bal ? this.props.ledger_response.itc_bal.igst_bal : 0.00) + (this.props.challan.itc_net_query ? parseFloat(this.props.challan.itc_net_query.iamt) : 0));
        var cgstBal_Ledg_3B = ((this.props.ledger_response.itc_bal ? this.props.ledger_response.itc_bal.cgst_bal : 0.00) + (this.props.challan.itc_net_query ? parseFloat(this.props.challan.itc_net_query.camt) : 0));
        var sgstBal_Ledg_3B = ((this.props.ledger_response.itc_bal ? this.props.ledger_response.itc_bal.sgst_bal : 0.00) + (this.props.challan.itc_net_query ? parseFloat(this.props.challan.itc_net_query.samt) : 0));
        var csgstBal_Ledg_3B = ((this.props.ledger_response.itc_bal ? this.props.ledger_response.itc_bal.cess_bal : 0.00) + (this.props.challan.itc_net_query ? parseFloat(this.props.challan.itc_net_query.csamt) : 0));

        var igstTaxPay =  ((this.props.challan.osup_det_query ? parseFloat(this.props.challan.osup_det_query.iamt) : 0.00) + (this.props.challan.osup_zero_query ? parseFloat(this.props.challan.osup_zero_query.iamt) : 0.00));
        var cgstTaxPay =  (this.props.challan.osup_det_query ? parseFloat(this.props.challan.osup_det_query.camt) : 0.00);
        var sgstTaxPay =  (this.props.challan.osup_det_query ? parseFloat(this.props.challan.osup_det_query.samt) : 0.00);
        var cessTaxPay =  ((this.props.challan.osup_det_query ? parseFloat(this.props.challan.osup_det_query.csamt) : 0.00) + (this.props.challan.osup_zero_query ? parseFloat(this.props.challan.osup_zero_query.csamt) : 0.00));

        this.setState({
          igstAvail: igstBal_Ledg_3B,
          cgstAvail: cgstBal_Ledg_3B,
          sgstAvail: sgstBal_Ledg_3B,
          csgstAvail: csgstBal_Ledg_3B,

          igstTax: igstTaxPay,
          cgstTax: cgstTaxPay,
          sgstTax: sgstTaxPay,
          csgstTax: cessTaxPay,
        });

      }
    };

    initialTaxValue = () => {


    }

    itcBalShow = (e, type) => {

      const valueSplit = (e.target.value).split('₹ ');

      const cessss = document.getElementById("cessInput").value;

      console.log(cessss);

      if(type === 'iamt'){
        console.log('iamt');
        console.log(valueSplit[1]);
        this.setState({
          igstAvail: (parseFloat(this.state.igstAvail) - parseFloat(valueSplit[1])),
        });
      }else if (type === 'camt') {
        console.log('camt');
        console.log(valueSplit[1]);
        this.setState({
          cgstAvail: (parseFloat(this.state.cgstAvail) - parseFloat(valueSplit[1])),
        });
      }else if (type === 'samt') {
        console.log('samt');
        console.log(valueSplit[1]);
        this.setState({
          sgstAvail: (parseFloat(this.state.sgstAvail) - parseFloat(valueSplit[1])),
        });
      }else if (type === 'cess') {
        console.log('csamt');
        console.log(valueSplit[1]);
        this.setState({
          csgstAvail: (parseFloat(this.state.csgstAvail) - parseFloat(valueSplit[1])),
        });
      }


    }

    formatNumber(value) {
          var x=value ? value : 0;
          x=x.toString();
          var afterPoint = '';
          if(x.indexOf('.') > 0)
             afterPoint = x.substring(x.indexOf('.'),x.length);
          x = Math.floor(x);
          x=x.toString();
          var lastThree = x.substring(x.length-3);
          var otherNumbers = x.substring(0,x.length-3);
          if(otherNumbers !== '')
              lastThree = ',' + lastThree;
          var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
          return `₹ ${res}`;
      }

    render() {
      console.log(this.props);
      console.log(this.state);
      return (
      <div>

        <Layout style={{ backgroundColor: "#FFFFFF" }}>
         <Content>

         <PayTaxComponent
           currentITCBalance={this.itcBalShow}
           formatNumber={this.formatNumber}
           getFieldDecorator={this.props.form.getFieldDecorator}
           challan={this.props.challan ? this.props.challan : ''}
           ledger_tax={this.props.ledger_response ? this.props.ledger_response : ''}
           dynamicCredits={this.state}
         />
         <OTPForm
           wrappedComponentRef={this.saveFormRef}
           visible={this.state.requestOTP}
           onSubmit={this.handleOTPSubmit}
           onCancel={this.handleCancel}
         />
          </Content>
        </Layout>

      </div>
      )
    }
  }


  const ledgerConfig = {
    name: "ledgerBalanceQuery",
    options: props => {
      var year_trim = props.finYear.split("-");
      var fy = "";
      if (props.finMonth < 4) {
        fy = "20" + year_trim[1];
      } else {
        fy = year_trim[0];
      }

      var period = toString(props.finMonth).padStart(2, "0") + "" + fy;
      return {
      variables: {
        companyId: props.companyId,
        finmonth: props.finMonth.toString(),
        finyear: props.finYear,
        username: props.company ? props.company.gstnUserName : '',
        state_cd: props.company ? props.company.gstin.substr(0, 2) : '',
        gstin: props.company ? props.company.gstin : '',
        otp: props.gspDetails ? props.gspDetails.otp ? props.gspDetails.otp.toString() : '' : '',
        gsptoken: props.gspDetails ? props.gspDetails.gspToken : '',
        period: period,
      },
    }
    },
    props: ({ ledgerBalanceQuery }) => {
      return {
        ledgerBalanceQuery: ledgerBalanceQuery,
        ledger_response: get('viewer.ledgerbalance_api', ledgerBalanceQuery),
      }
    },
    skip: (props) => {
      console.log(props);
      return false;
    }
  }

  const osup_detQueryConfig = {
    name: "threeBQuery",
    options: props => ({
      variables: {
        companyId: props.companyId,
        finmonth: props.finMonth.toString(),
        finyear: props.finYear
      },
    }),
    props: ({ threeBQuery }) => {
      return {
        challan: get('viewer', threeBQuery),
      }
    },

  }

  const companyQueryConfig = {
    name: "companyQuery",
    options: props => ({
      variables: {
        companyId: props.companyId,
        finmonth: props.finMonth,
        finyear: props.finYear,
      },
    }),
    props: ({ companyQuery }) => {
      console.log(get('viewer.company', companyQuery));
      return {
        company: get('viewer.company', companyQuery),
        gspDetails: get("viewer.gspInfo", companyQuery)
      }
    },
  }


  const mapStateToProps = state => {
    console.log(state);
    return {
      companyId: state.local.selectedCompany,
      finMonth: state.local.finMonth,
      finYear: state.local.finYear,
    }
  }

  export default compose(
    withRouter,
    withApollo,
    connect(mapStateToProps),
    Form.create(),
    graphql(gql(CompanyByIdQuery), companyQueryConfig),
    graphql(gql(osup_detQuery), osup_detQueryConfig),
    graphql(gql(LedgerBalanceQuery), ledgerConfig),
  )(PayTax)
