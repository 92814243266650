import React from 'react'
import { Route } from 'react-router-dom'

import Contact_Details from './Contact_Details'
import ContactAdd from './ContactAdd'
import ContactEdit from './ContactEdit'

import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'

export default () =>
  <Root>
    <Route exact path={ROUTES.CONTACT_DETAILS} component={Contact_Details} />
    <Route exact path={ROUTES.CONTACT_UPLOAD} component={ContactAdd} />
    <Route exact path={ROUTES.CONTACT_UPDATE} component={ContactEdit} />
  </Root>

export { Contact_Details, ContactAdd, ContactEdit }
