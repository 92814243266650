const reduce = require("lodash/fp/reduce");
const findIndex = require("lodash/fp/findIndex");
const get = require("lodash/fp/get");
const map = require("lodash/fp/map");

const getSection6SuppliesDetails = function(table, section, type, prop) {
  // return table[section] ? table[section].length > 0 ? findIndex({itc_typ: type})(table[section]) > -1 ?  : 0 : 0 : 0
  if (table[section]) {
    if (table[section].length > 0) {
      const index = findIndex({ itc_typ: type })(table[section]);
      if (index > -1) {
        return table[section][index][prop] ? table[section][index][prop] : 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

const getOutwardHSNDetails = function(tbl) {
  const outwardHSNItem = [
    {
      key: "1",
      hsn_sc: "",
      uqc: "",
      desc: "",
      isconcesstional: false,
      qty: 0,
      txval: 0,
      rt: 0,
      iamt: 0,
      camt: 0,
      samt: 0,
      cess: 0
    }
  ]
  if(!tbl) return outwardHSNItem;
  if(!tbl.items) return outwardHSNItem;
  if(!tbl.items.length === 0) return outwardHSNItem;
  return map(d => {
    return {
      key: Math.random().toString(),
      hsn_sc: d.hsn_sc ? d.hsn_sc : "",
      uqc: d.uqc ? d.uqc : "",
      desc: d.desc ? d.desc : "",
      isconcesstional: (d.isconcesstional === "N") ? false : true,
      qty: d.qty ? d.qty : 0,
      txval: d.txval ? d.txval : 0,
      rt: d.rt ? d.rt : 0,
      iamt: d.iamt ? d.iamt : 0,
      camt: d.camt ? d.camt : 0,
      samt: d.samt ? d.samt : 0,
      cess: d.csamt ? d.csamt : 0,
    };
  })(tbl.items);
}

const getOtherReversalItems = function(table) {
  // return table[section] ? table[section].length > 0 ? findIndex({itc_typ: type})(table[section]) > -1 ?  : 0 : 0 : 0
  const otherItem = [
    
  ];
  if (!table) return otherItem;
  if (!table["other"]) return otherItem;
  if (table["other"].length === 0) return otherItem;
  return map(d => {
    return {
      key: "h_" + Math.random().toString(),
      det: d.desc ? d.desc : "Other Reversal",
      iamt: d.iamt ? d.iamt : 0,
      camt: d.camt ? d.camt : 0,
      samt: d.samt ? d.samt : 0,
      cess: d.csamt ? d.csamt : 0
    };
  })(table["other"]);
};

const formatNumber = function(value) {
  if (value === "") return "";
  // if (value > 0) {
  var x = value;
  x = x.toString();
  var afterPoint = "";
  if (x.indexOf(".") > 0)
    afterPoint = x.substring(x.indexOf("."), x.indexOf(".") + 3);
  // console.log(x.indexOf("."));
  x = x.indexOf(".") > 0 ? x.substring(0, x.indexOf(".")) : x;
  // x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  var res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
  return `₹ ${res}`;
  // } else {
  //   return "";
  // }
};

const formatAndSetTable4Data = function(data) {
  const tbl4 = data.table4;
  const tbl5 = data.table5;
  const tbl6 = data.table6;
  const tbl7 = data.table7;
  const tbl8 = data.table8;
  const tbl9 = data.table9;
  const tbl10 = data.table10;
  const tbl14 = data.table14;
  const tbl15 = data.table15;
  const tbl16 = data.table16;
  const tbl17 = data.table17;
  const tbl18 = data.table18;
  const formattedData = {
    tbl4: [
      {
        key: "1",
        sno: "A",
        det: "Supplies made to unregistered persons (B2C)",
        val: tbl4.b2c.txval ? tbl4.b2c.txval : 0,
        iamt: tbl4.b2c.iamt ? tbl4.b2c.iamt : 0,
        camt: tbl4.b2c.camt ? tbl4.b2c.camt : 0,
        samt: tbl4.b2c.samt ? tbl4.b2c.samt : 0,
        cess: tbl4.b2c.csamt ? tbl4.b2c.csamt : 0
      },
      {
        key: "2",
        sno: "B",
        det: "Supplies made to registered person (B2B)",
        val: tbl4.b2b.txval ? tbl4.b2b.txval : 0,
        iamt: tbl4.b2b.iamt ? tbl4.b2b.iamt : 0,
        camt: tbl4.b2b.camt ? tbl4.b2b.camt : 0,
        samt: tbl4.b2b.samt ? tbl4.b2b.samt : 0,
        cess: tbl4.b2b.csamt ? tbl4.b2b.csamt : 0
      },
      {
        key: "3",
        sno: "C",
        det:
          "Zero rated supply (Export) on payment of tax (Except supplies to SEZ)",
        val: tbl4.exp.txval ? tbl4.exp.txval : 0,
        iamt: tbl4.exp.iamt ? tbl4.exp.iamt : 0,
        cess: tbl4.exp.csamt ? tbl4.exp.csamt : 0
      },
      {
        key: "4",
        sno: "D",
        det: "Supplies to SEZ on payment of tax",
        val: tbl4.sez.txval ? tbl4.sez.txval : 0,
        iamt: tbl4.sez.iamt ? tbl4.sez.iamt : 0,
        cess: tbl4.sez.csamt ? tbl4.sez.csamt : 0
      },
      {
        key: "5",
        sno: "E",
        det: "Deemed Exports",
        val: tbl4.deemed.txval ? tbl4.deemed.txval : 0,
        iamt: tbl4.deemed.iamt ? tbl4.deemed.iamt : 0,
        camt: tbl4.deemed.camt ? tbl4.deemed.camt : 0,
        samt: tbl4.deemed.samt ? tbl4.deemed.samt : 0,
        cess: tbl4.deemed.csamt ? tbl4.deemed.csamt : 0
      },
      {
        key: "6",
        sno: "F",
        det:
          "Advances on which tax has been paid but invoice has not been issued (not covered under (A) to (E) above)",
        val: tbl4.at.txval ? tbl4.at.txval : 0,
        iamt: tbl4.at.iamt ? tbl4.at.iamt : 0,
        camt: tbl4.at.camt ? tbl4.at.camt : 0,
        samt: tbl4.at.samt ? tbl4.at.samt : 0,
        cess: tbl4.at.csamt ? tbl4.at.csamt : 0
      },
      {
        key: "7",
        sno: "G",
        det:
          "Inward supplies on which tax is to be paid on the reverse charge basis",
        val: tbl4.rchrg.txval ? tbl4.rchrg.txval : 0,
        iamt: tbl4.rchrg.iamt ? tbl4.rchrg.iamt : 0,
        camt: tbl4.rchrg.camt ? tbl4.rchrg.camt : 0,
        samt: tbl4.rchrg.samt ? tbl4.rchrg.samt : 0,
        cess: tbl4.rchrg.csamt ? tbl4.rchrg.csamt : 0
      },
      {
        key: "8",
        sno: "H",
        det: "Sub total (A to G above)",
        val: tbl4.sub_totalAG.txval ? tbl4.sub_totalAG.txval : 0,
        iamt: tbl4.sub_totalAG.iamt ? tbl4.sub_totalAG.iamt : 0,
        camt: tbl4.sub_totalAG.camt ? tbl4.sub_totalAG.camt : 0,
        samt: tbl4.sub_totalAG.samt ? tbl4.sub_totalAG.samt : 0,
        cess: tbl4.sub_totalAG.csamt ? tbl4.sub_totalAG.csamt : 0
      },
      {
        key: "9",
        sno: "I",
        det:
          "Credit notes issued in respect of transactions specified in (B) to (E) above (-)",
        val: tbl4.cr_nt.txval ? tbl4.cr_nt.txval : 0,
        iamt: tbl4.cr_nt.iamt ? tbl4.cr_nt.iamt : 0,
        camt: tbl4.cr_nt.camt ? tbl4.cr_nt.camt : 0,
        samt: tbl4.cr_nt.samt ? tbl4.cr_nt.samt : 0,
        cess: tbl4.cr_nt.csamt ? tbl4.cr_nt.csamt : 0
      },
      {
        key: "10",
        sno: "J",
        det:
          "Debit notes issued in respect of transactions specified in (B) to (E) above (+)",
        val: tbl4.dr_nt.txval ? tbl4.dr_nt.txval : 0,
        iamt: tbl4.dr_nt.iamt ? tbl4.dr_nt.iamt : 0,
        camt: tbl4.dr_nt.camt ? tbl4.dr_nt.camt : 0,
        samt: tbl4.dr_nt.samt ? tbl4.dr_nt.samt : 0,
        cess: tbl4.dr_nt.csamt ? tbl4.dr_nt.csamt : 0
      },
      {
        key: "11",
        sno: "K",
        det: "Supplies/tax declared through Amendments (+)",
        val: tbl4.amd_pos.txval ? tbl4.amd_pos.txval : 0,
        iamt: tbl4.amd_pos.iamt ? tbl4.amd_pos.iamt : 0,
        camt: tbl4.amd_pos.camt ? tbl4.amd_pos.camt : 0,
        samt: tbl4.amd_pos.samt ? tbl4.amd_pos.samt : 0,
        cess: tbl4.amd_pos.csamt ? tbl4.amd_pos.csamt : 0
      },
      {
        key: "12",
        sno: "L",
        det: "Supplies/tax reduced through Amendments (-)",
        val: tbl4.amd_neg.txval ? tbl4.amd_neg.txval : 0,
        iamt: tbl4.amd_neg.iamt ? tbl4.amd_neg.iamt : 0,
        camt: tbl4.amd_neg.camt ? tbl4.amd_neg.camt : 0,
        samt: tbl4.amd_neg.samt ? tbl4.amd_neg.samt : 0,
        cess: tbl4.amd_neg.csamt ? tbl4.amd_neg.csamt : 0
      },
      {
        key: "13",
        sno: "M",
        det: "Sub total (I to L above)",
        val: tbl4.sub_totalIL.txval ? tbl4.sub_totalIL.txval : 0,
        iamt: tbl4.sub_totalIL.iamt ? tbl4.sub_totalIL.iamt : 0,
        camt: tbl4.sub_totalIL.camt ? tbl4.sub_totalIL.camt : 0,
        samt: tbl4.sub_totalIL.samt ? tbl4.sub_totalIL.samt : 0,
        cess: tbl4.sub_totalIL.csamt ? tbl4.sub_totalIL.csamt : 0
      },
      {
        key: "14",
        sno: "N",
        det: "Supplies and advances on which tax is to be paid (H + M) above",
        val: tbl4.sup_adv.txval ? tbl4.sup_adv.txval : 0,
        iamt: tbl4.sup_adv.iamt ? tbl4.sup_adv.iamt : 0,
        camt: tbl4.sup_adv.camt ? tbl4.sup_adv.camt : 0,
        samt: tbl4.sup_adv.samt ? tbl4.sup_adv.samt : 0,
        cess: tbl4.sup_adv.csamt ? tbl4.sup_adv.csamt : 0
      }
    ],
    tbl5: [
      {
        key: "1",
        sno: "A",
        det: "Zero rated supply (Export) without payment of tax",
        val: tbl5.zero_rtd.txval ? tbl5.zero_rtd.txval : 0
      },
      {
        key: "2",
        sno: "B",
        det: "Supply to SEZ without payment of tax",
        val: tbl5.sez.txval ? tbl5.sez.txval : 0
      },
      {
        key: "3",
        sno: "C",
        det:
          "Supplies on which tax is to be paid by the recipient on reverse charge basis",
        val: tbl5.rchrg.txval ? tbl5.rchrg.txval : 0
      },
      {
        key: "4",
        sno: "D",
        det: "Exempted",
        val: tbl5.exmt.txval ? tbl5.exmt.txval : 0
      },
      {
        key: "5",
        sno: "E",
        det: "Nil Rated",
        val: tbl5.nil.txval ? tbl5.nil.txval : 0
      },
      {
        key: "6",
        sno: "F",
        det: "Non-GST supply (includes ‘no supply’)",
        val: tbl5.non_gst.txval ? tbl5.non_gst.txval : 0
      },
      {
        key: "7",
        sno: "G",
        det: "Sub total (A to F above)",
        val: tbl5.sub_totalAF.txval ? tbl5.sub_totalAF.txval : 0
      },
      {
        key: "8",
        sno: "H",
        det:
          "Credit notes issued in respect of transactions specified in (A to F) above (-)",
        val: tbl5.cr_nt.txval ? tbl5.cr_nt.txval : 0
      },
      {
        key: "9",
        sno: "I",
        det:
          "Debit Notes issued in respect of transactions specified in (A to F) above (+)",
        val: tbl5.dr_nt.txval ? tbl5.dr_nt.txval : 0
      },
      {
        key: "10",
        sno: "J",
        det: "Supplies declared through Amendments (+)",
        val: tbl5.amd_pos.txval ? tbl5.amd_pos.txval : 0
      },
      {
        key: "11",
        sno: "K",
        det: "Supplies reduced through Amendments (-)",
        val: tbl5.amd_neg.txval ? tbl5.amd_neg.txval : 0
      },
      {
        key: "12",
        sno: "L",
        det: "Sub total (H to K)",
        val: tbl5.sub_totalHK.txval ? tbl5.sub_totalHK.txval : 0
      },
      {
        key: "13",
        sno: "M",
        det: "Turnover on which tax is not to be paid (G + L above)",
        val: tbl5.tover_tax_np.txval ? tbl5.tover_tax_np.txval : 0
      },
      {
        key: "14",
        sno: "N",
        det: "Total Turnover (including advances) (4N + 5M - 4G above)",
        val: tbl5.total_tover.txval ? tbl5.total_tover.txval : 0,
        iamt: tbl5.total_tover.iamt ? tbl5.total_tover.iamt : 0,
        camt: tbl5.total_tover.camt ? tbl5.total_tover.camt : 0,
        samt: tbl5.total_tover.samt ? tbl5.total_tover.samt : 0,
        cess: tbl5.total_tover.csamt ? tbl5.total_tover.csamt : 0
      }
    ],
    tbl6: [
      {
        key: "1",
        sno: "A",
        det:
          "Total amount of input tax credit availed through FORM GSTR-3B (sum total of Table 4A of FORM GSTR-3B)",
        iamt: tbl6.itc_3b.iamt ? tbl6.itc_3b.iamt : 0,
        camt: tbl6.itc_3b.camt ? tbl6.itc_3b.camt : 0,
        samt: tbl6.itc_3b.samt ? tbl6.itc_3b.samt : 0,
        cess: tbl6.itc_3b.csamt ? tbl6.itc_3b.csamt : 0
      },
      {
        key: "2",
        sno: "B",
        det:
          "Inward supplies (other than imports and inward supplies liable to reverse charge but includes services received from SEZs)",
        type: "Inputs",
        iamt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "ip", "iamt"),
        camt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "ip", "camt"),
        samt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "ip", "samt"),
        cess: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "ip", "csamt")
      },
      {
        key: "3",
        sno: "B2",
        type: "Capital goods",
        iamt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "cg", "iamt"),
        camt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "cg", "camt"),
        samt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "cg", "samt"),
        cess: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "cg", "csamt")
      },
      {
        key: "4",
        sno: "B3",
        type: "Input Services",
        iamt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "is", "iamt"),
        camt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "is", "camt"),
        samt: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "is", "samt"),
        cess: getSection6SuppliesDetails(tbl6, "supp_non_rchrg", "is", "csamt")
      },
      {
        key: "5",
        sno: "C",
        det:
          "Inward supplies received from unregistered persons liable to reverse charge (other than B above) on which tax is paid & ITC availed",
        type: "Inputs",
        iamt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "ip",
          "iamt"
        ),
        camt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "ip",
          "camt"
        ),
        samt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "ip",
          "samt"
        ),
        cess: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "ip",
          "csamt"
        )
      },
      {
        key: "6",
        sno: "C2",
        type: "Capital goods",
        iamt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "cg",
          "iamt"
        ),
        camt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "cg",
          "camt"
        ),
        samt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "cg",
          "samt"
        ),
        cess: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "cg",
          "csamt"
        )
      },
      {
        key: "7",
        sno: "C3",
        type: "Input Services",
        iamt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "is",
          "iamt"
        ),
        camt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "is",
          "camt"
        ),
        samt: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "is",
          "samt"
        ),
        cess: getSection6SuppliesDetails(
          tbl6,
          "supp_rchrg_unreg",
          "is",
          "csamt"
        )
      },
      {
        key: "8",
        sno: "D",
        det:
          "Inward supplies received from registered persons liable to reverse charge (other than B above) on which tax is paid and ITC availed",
        type: "Inputs",
        iamt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "ip", "iamt"),
        camt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "ip", "camt"),
        samt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "ip", "samt"),
        cess: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "ip", "csamt")
      },
      {
        key: "9",
        sno: "D2",
        type: "Capital goods",
        iamt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "cg", "iamt"),
        camt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "cg", "camt"),
        samt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "cg", "samt"),
        cess: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "cg", "csamt")
      },
      {
        key: "10",
        sno: "D3",
        type: "Input Services",
        iamt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "is", "iamt"),
        camt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "is", "camt"),
        samt: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "is", "samt"),
        cess: getSection6SuppliesDetails(tbl6, "supp_rchrg_reg", "is", "csamt")
      },
      {
        key: "11",
        sno: "E",
        det: "Import of goods (including supplies from SEZ)",
        type: "Inputs",
        iamt: getSection6SuppliesDetails(tbl6, "iog", "ip", "iamt"),
        cess: getSection6SuppliesDetails(tbl6, "iog", "ip", "csamt")
      },
      {
        key: "12",
        sno: "E2",
        type: "Capital goods",
        iamt: getSection6SuppliesDetails(tbl6, "iog", "cg", "iamt"),
        cess: getSection6SuppliesDetails(tbl6, "iog", "cg", "csamt")
      },
      {
        key: "13",
        sno: "F",
        det: "Import of services (excluding inward supplies from SEZ)",
        iamt: get("ios.iamt", tbl6) ? get("ios.iamt", tbl6) : 0,
        cess: get("ios.csamt", tbl6) ? get("ios.csamt", tbl6) : 0
      },
      {
        key: "14",
        sno: "G",
        det: "Input Tax credit received from ISD",
        iamt: tbl6.isd.iamt ? tbl6.isd.iamt : 0,
        camt: tbl6.isd.camt ? tbl6.isd.camt : 0,
        samt: tbl6.isd.samt ? tbl6.isd.samt : 0,
        cess: tbl6.isd.csamt ? tbl6.isd.csamt : 0
      },
      {
        key: "15",
        sno: "H",
        det:
          "Amount of ITC reclaimed (other than B above) under the provisions of the Act",
        iamt: get("itc_clmd.iamt", tbl6) ? get("itc_clmd.iamt", tbl6) : 0,
        camt: get("itc_clmd.camt", tbl6) ? get("itc_clmd.camt", tbl6) : 0,
        samt: get("itc_clmd.samt", tbl6) ? get("itc_clmd.samt", tbl6) : 0,
        cess: get("itc_clmd.csamt", tbl6) ? get("itc_clmd.csamt", tbl6) : 0
      },
      {
        key: "16",
        sno: "I",
        det: "Sub-total (B to H above)",
        iamt: get("sub_totalBH.iamt", tbl6) ? get("sub_totalBH.iamt", tbl6) : 0,
        camt: get("sub_totalBH.camt", tbl6) ? get("sub_totalBH.camt", tbl6) : 0,
        samt: get("sub_totalBH.samt", tbl6) ? get("sub_totalBH.samt", tbl6) : 0,
        cess: get("sub_totalBH.csamt", tbl6) ? get("sub_totalBH.csamt", tbl6) : 0
      },
      {
        key: "17",
        sno: "J",
        det: "Difference (I - A above)",
        iamt: get("difference.iamt", tbl6) ? get("difference.iamt", tbl6) : 0,
        camt: get("difference.camt", tbl6) ? get("difference.camt", tbl6) : 0,
        samt: get("difference.samt", tbl6) ? get("difference.samt", tbl6) : 0,
        cess: get("difference.csamt", tbl6) ? get("difference.csamt", tbl6) : 0
      },
      {
        key: "18",
        sno: "K",
        det: "Transition Credit through TRAN-I (including revisions if any)",
        camt: get("tran1.camt", tbl6) ? get("tran1.camt", tbl6) : 0,
        samt: get("tran1.samt", tbl6) ? get("tran1.samt", tbl6) : 0,
      },
      {
        key: "19",
        sno: "L",
        det: "Transition Credit through TRAN-II",
        camt: get("tran2.camt", tbl6) ? get("tran2.camt", tbl6) : 0,
        samt: get("tran2.samt", tbl6) ? get("tran2.samt", tbl6) : 0,
      },
      {
        key: "20",
        sno: "M",
        det: "Any other ITC availed but not specified above",
        iamt: get("other.iamt", tbl6) ? get("other.iamt", tbl6) : 0,
        camt: get("other.camt", tbl6) ? get("other.camt", tbl6) : 0,
        samt: get("other.samt", tbl6) ? get("other.samt", tbl6) : 0,
        cess: get("other.csamt", tbl6) ? get("other.csamt", tbl6) : 0
      },
      {
        key: "21",
        sno: "N",
        det: "Sub-total (K to M  above)",
        iamt: tbl6.sub_totalKM.iamt ? tbl6.sub_totalKM.iamt : 0,
        camt: tbl6.sub_totalKM.camt ? tbl6.sub_totalKM.camt : 0,
        samt: tbl6.sub_totalKM.samt ? tbl6.sub_totalKM.samt : 0,
        cess: tbl6.sub_totalKM.csamt ? tbl6.sub_totalKM.csamt : 0
      },
      {
        key: "22",
        sno: "O",
        det: "Total ITC availed (I + N above)",
        iamt: tbl6.total_itc_availed.iamt ? tbl6.total_itc_availed.iamt : 0,
        camt: tbl6.total_itc_availed.camt ? tbl6.total_itc_availed.camt : 0,
        samt: tbl6.total_itc_availed.samt ? tbl6.total_itc_availed.samt : 0,
        cess: tbl6.total_itc_availed.csamt ? tbl6.total_itc_availed.csamt : 0
      }
    ],
    tbl7: [
      {
        key: "1",
        sno: "A",
        det: "As per Rule 37",
        iamt: get("rule37.iamt", tbl7) ? get("rule37.iamt", tbl7) : 0,
        camt: get("rule37.camt", tbl7) ? get("rule37.camt", tbl7) : 0,
        samt: get("rule37.samt", tbl7) ? get("rule37.samt", tbl7) : 0,
        cess: get("rule37.csamt", tbl7) ? get("rule37.csamt", tbl7) : 0
      },
      {
        key: "2",
        sno: "B",
        det: "As per Rule 39",
        iamt: get("rule39.iamt", tbl7) ? get("rule39.iamt", tbl7) : 0,
        camt: get("rule39.camt", tbl7) ? get("rule39.camt", tbl7) : 0,
        samt: get("rule39.samt", tbl7) ? get("rule39.samt", tbl7) : 0,
        cess: get("rule39.csamt", tbl7) ? get("rule39.csamt", tbl7) : 0
      },
      {
        key: "3",
        sno: "C",
        det: "As per Rule 42",
        iamt: get("rule42.iamt", tbl7) ? get("rule42.iamt", tbl7) : 0,
        camt: get("rule42.camt", tbl7) ? get("rule42.camt", tbl7) : 0,
        samt: get("rule42.samt", tbl7) ? get("rule42.samt", tbl7) : 0,
        cess: get("rule42.csamt", tbl7) ? get("rule42.csamt", tbl7) : 0
      },
      {
        key: "4",
        sno: "D",
        det: "As per Rule 43",
        iamt: get("rule43.iamt", tbl7) ? get("rule43.iamt", tbl7) : 0,
        camt: get("rule43.camt", tbl7) ? get("rule43.camt", tbl7) : 0,
        samt: get("rule43.samt", tbl7) ? get("rule43.samt", tbl7) : 0,
        cess: get("rule43.csamt", tbl7) ? get("rule43.csamt", tbl7) : 0
      },
      {
        key: "5",
        sno: "E",
        det: "As per section 17(5)",
        iamt: get("sec17.iamt", tbl7) ? get("sec17.iamt", tbl7) : 0,
        camt: get("sec17.camt", tbl7) ? get("sec17.camt", tbl7) : 0,
        samt: get("sec17.samt", tbl7) ? get("sec17.samt", tbl7) : 0,
        cess: get("sec17.csamt", tbl7) ? get("sec17.csamt", tbl7) : 0
      },
      {
        key: "6",
        sno: "F",
        det: "Reversal of TRAN-I credit",
        camt: get("revsl_tran1.camt", tbl7) ? get("revsl_tran1.camt", tbl7) : 0,
        samt: get("revsl_tran1.samt", tbl7) ? get("revsl_tran1.samt", tbl7) : 0
      },
      {
        key: "7",
        sno: "G",
        det: "Reversal of TRAN-II credit",
        camt: get("revsl_tran2.camt", tbl7) ? get("revsl_tran2.camt", tbl7) : 0,
        samt: get("revsl_tran2.samt", tbl7) ? get("revsl_tran2.samt", tbl7) : 0
      },
      {
        key: "8",
        sno: "H",
        det: "test",
        items: getOtherReversalItems(tbl7)
      },
      {
        key: "9",
        sno: "I",
        det: "Total ITC Reversed (Sum of A to H above)",
        iamt: get("tot_itc_revd.iamt", tbl7)
          ? get("tot_itc_revd.iamt", tbl7)
          : 0,
        camt: get("tot_itc_revd.camt", tbl7)
          ? get("tot_itc_revd.camt", tbl7)
          : 0,
        samt: get("tot_itc_revd.samt", tbl7)
          ? get("tot_itc_revd.samt", tbl7)
          : 0,
        cess: get("tot_itc_revd.csamt", tbl7)
          ? get("tot_itc_revd.csamt", tbl7)
          : 0
      },
      {
        key: "10",
        sno: "J",
        det: "Net ITC Available for Utilization (6O - 7I)",
        iamt: get("net_itc_aval.iamt", tbl7)
          ? get("net_itc_aval.iamt", tbl7)
          : 0,
        camt: get("net_itc_aval.camt", tbl7)
          ? get("net_itc_aval.camt", tbl7)
          : 0,
        samt: get("net_itc_aval.samt", tbl7)
          ? get("net_itc_aval.samt", tbl7)
          : 0,
        cess: get("net_itc_aval.csamt", tbl7)
          ? get("net_itc_aval.csamt", tbl7)
          : 0
      }
    ],
    tbl8: [
      {
        key: "1",
        sno: "A",
        det: "ITC as per GSTR-2A (Table 3 & 5 thereof)",
        iamt: get("itc_2a.iamt", tbl8) ? get("itc_2a.iamt", tbl8) : 0,
        camt: get("itc_2a.camt", tbl8) ? get("itc_2a.camt", tbl8) : 0,
        samt: get("itc_2a.samt", tbl8) ? get("itc_2a.samt", tbl8) : 0,
        cess: get("itc_2a.csamt", tbl8) ? get("itc_2a.csamt", tbl8) : 0
      },
      {
        key: "2",
        sno: "B",
        det: "ITC as per sum total of 6(B) and 6(H) above",
        iamt: get("itc_tot.iamt", tbl8) ? get("itc_tot.iamt", tbl8) : 0,
        camt: get("itc_tot.camt", tbl8) ? get("itc_tot.camt", tbl8) : 0,
        samt: get("itc_tot.samt", tbl8) ? get("itc_tot.samt", tbl8) : 0,
        cess: get("itc_tot.csamt", tbl8) ? get("itc_tot.csamt", tbl8) : 0
      },
      {
        key: "3",
        sno: "C",
        det:
          "ITC on inward supplies (other than imports and inward supplies liable to reverse charge but includes services received from SEZs) received during 2017-18 but availed during April to September, 2018",
          iamt: get("itc_inwd_supp.iamt", tbl8) ? get("itc_inwd_supp.iamt", tbl8) : 0,
          camt: get("itc_inwd_supp.camt", tbl8) ? get("itc_inwd_supp.camt", tbl8) : 0,
          samt: get("itc_inwd_supp.samt", tbl8) ? get("itc_inwd_supp.samt", tbl8) : 0,
          cess: get("itc_inwd_supp.csamt", tbl8) ? get("itc_inwd_supp.csamt", tbl8) : 0
      },
      {
        key: "4",
        sno: "D",
        det: "Difference [A-(B+C)]",
        iamt: get("differenceABC.iamt", tbl8) ? get("differenceABC.iamt", tbl8) : 0,
        camt: get("differenceABC.camt", tbl8) ? get("differenceABC.camt", tbl8) : 0,
        samt: get("differenceABC.samt", tbl8) ? get("differenceABC.samt", tbl8) : 0,
        cess: get("differenceABC.csamt", tbl8) ? get("differenceABC.csamt", tbl8) : 0
      },
      {
        key: "5",
        sno: "E",
        det: "ITC available but not availed",
        iamt: get("itc_nt_availd.iamt", tbl8) ? get("itc_nt_availd.iamt", tbl8) : 0,
        camt: get("itc_nt_availd.camt", tbl8) ? get("itc_nt_availd.camt", tbl8) : 0,
        samt: get("itc_nt_availd.samt", tbl8) ? get("itc_nt_availd.samt", tbl8) : 0,
        cess: get("itc_nt_availd.csamt", tbl8) ? get("itc_nt_availd.csamt", tbl8) : 0
      },
      {
        key: "6",
        sno: "F",
        det: "ITC available but ineligible",
        iamt: get("itc_nt_eleg.iamt", tbl8) ? get("itc_nt_eleg.iamt", tbl8) : 0,
        camt: get("itc_nt_eleg.camt", tbl8) ? get("itc_nt_eleg.camt", tbl8) : 0,
        samt: get("itc_nt_eleg.samt", tbl8) ? get("itc_nt_eleg.samt", tbl8) : 0,
        cess: get("itc_nt_eleg.csamt", tbl8) ? get("itc_nt_eleg.csamt", tbl8) : 0
      },
      {
        key: "7",
        sno: "G",
        det: "IGST paid on import of goods (including supplies from SEZ)",
        iamt: get("iog_taxpaid.iamt", tbl8) ? get("iog_taxpaid.iamt", tbl8) : 0,
        camt: get("iog_taxpaid.camt", tbl8) ? get("iog_taxpaid.camt", tbl8) : 0,
        samt: get("iog_taxpaid.samt", tbl8) ? get("iog_taxpaid.samt", tbl8) : 0,
        cess: get("iog_taxpaid.csamt", tbl8) ? get("iog_taxpaid.csamt", tbl8) : 0
      },
      {
        key: "8",
        sno: "H",
        det: "IGST credit availed on import of goods (as per 6(E) above)",
        iamt: get("iog_itc_availd.iamt", tbl8) ? get("iog_itc_availd.iamt", tbl8) : 0,
        camt: get("iog_itc_availd.camt", tbl8) ? get("iog_itc_availd.camt", tbl8) : 0,
        samt: get("iog_itc_availd.samt", tbl8) ? get("iog_itc_availd.samt", tbl8) : 0,
        cess: get("iog_itc_availd.csamt", tbl8) ? get("iog_itc_availd.csamt", tbl8) : 0
      },
      {
        key: "9",
        sno: "I",
        det: "Difference (G-H)",
        iamt: get("differenceGH.iamt", tbl8) ? get("differenceGH.iamt", tbl8) : 0,
        camt: get("differenceGH.camt", tbl8) ? get("differenceGH.camt", tbl8) : 0,
        samt: get("differenceGH.samt", tbl8) ? get("differenceGH.samt", tbl8) : 0,
        cess: get("differenceGH.csamt", tbl8) ? get("differenceGH.csamt", tbl8) : 0
      },
      {
        key: "10",
        sno: "J",
        det: "ITC available but not availed on import of goods (Equal to I)",
        iamt: get("iog_itc_ntavaild.iamt", tbl8) ? get("iog_itc_ntavaild.iamt", tbl8) : 0,
        camt: get("iog_itc_ntavaild.camt", tbl8) ? get("iog_itc_ntavaild.camt", tbl8) : 0,
        samt: get("iog_itc_ntavaild.samt", tbl8) ? get("iog_itc_ntavaild.samt", tbl8) : 0,
        cess: get("iog_itc_ntavaild.csamt", tbl8) ? get("iog_itc_ntavaild.csamt", tbl8) : 0
      },
      {
        key: "11",
        sno: "K",
        det: "Total ITC to be lapsed in current financial year (E + F + J)",
        iamt: get("tot_itc_lapsed.iamt", tbl8) ? get("tot_itc_lapsed.iamt", tbl8) : 0,
        camt: get("tot_itc_lapsed.camt", tbl8) ? get("tot_itc_lapsed.camt", tbl8) : 0,
        samt: get("tot_itc_lapsed.samt", tbl8) ? get("tot_itc_lapsed.samt", tbl8) : 0,
        cess: get("tot_itc_lapsed.csamt", tbl8) ? get("tot_itc_lapsed.csamt", tbl8) : 0
      }
    ],
    tbl9: [
      {
        key: "1",
        sno: "A",
        det: "Integrated Tax",
        txpyble: get("iamt.txpyble", tbl9) ? get("iamt.txpyble", tbl9) : 0,
        txpaid_cash: get("iamt.txpaid_cash", tbl9) ? get("iamt.txpaid_cash", tbl9) : 0,
        iamt: get("iamt.tax_paid_itc_iamt", tbl9) ? get("iamt.tax_paid_itc_iamt", tbl9) : 0,
        camt: get("iamt.tax_paid_itc_camt", tbl9) ? get("iamt.tax_paid_itc_camt", tbl9) : 0,
        samt: get("iamt.tax_paid_itc_samt", tbl9) ? get("iamt.tax_paid_itc_samt", tbl9) : 0,
      },
      {
        key: "2",
        sno: "B",
        det: "Central Tax",
        txpyble: get("camt.txpyble", tbl9) ? get("camt.txpyble", tbl9) : 0,
        txpaid_cash: get("camt.txpaid_cash", tbl9) ? get("camt.txpaid_cash", tbl9) : 0,
        iamt: get("camt.tax_paid_itc_iamt", tbl9) ? get("camt.tax_paid_itc_iamt", tbl9) : 0,
        camt: get("camt.tax_paid_itc_camt", tbl9) ? get("camt.tax_paid_itc_camt", tbl9) : 0,
      },
      {
        key: "3",
        sno: "C",
        det: "State/UT Tax",
        txpyble: get("samt.txpyble", tbl9) ? get("samt.txpyble", tbl9) : 0,
        txpaid_cash: get("samt.txpaid_cash", tbl9) ? get("samt.txpaid_cash", tbl9) : 0,
        iamt: get("samt.tax_paid_itc_iamt", tbl9) ? get("samt.tax_paid_itc_iamt", tbl9) : 0,
        samt: get("samt.tax_paid_itc_samt", tbl9) ? get("samt.tax_paid_itc_samt", tbl9) : 0,
      },
      {
        key: "4",
        sno: "D",
        det: "Cess",
        txpyble: get("csamt.txpyble", tbl9) ? get("csamt.txpyble", tbl9) : 0,
        txpaid_cash: get("csamt.txpaid_cash", tbl9) ? get("csamt.txpaid_cash", tbl9) : 0,
        cess: get("csamt.tax_paid_itc_csamt", tbl9) ? get("csamt.tax_paid_itc_csamt", tbl9) : 0,
      },
      {
        key: "5",
        sno: "E",
        det: "Interest",
        txpyble: get("intr.txpyble", tbl9) ? get("intr.txpyble", tbl9) : 0,
        txpaid_cash: get("intr.txpaid_cash", tbl9) ? get("intr.txpaid_cash", tbl9) : 0,
      },
      {
        key: "6",
        sno: "F",
        det: "Late Fees",
        txpyble: get("fee.txpyble", tbl9) ? get("fee.txpyble", tbl9) : 0,
        txpaid_cash: get("fee.txpaid_cash", tbl9) ? get("fee.txpaid_cash", tbl9) : 0,
      },
      {
        key: "7",
        sno: "G",
        det: "Penalty",
        txpyble: get("pen.txpyble", tbl9) ? get("pen.txpyble", tbl9) : 0,
        txpaid_cash: get("pen.txpaid_cash", tbl9) ? get("pen.txpaid_cash", tbl9) : 0,
      },
      {
        key: "8",
        sno: "H",
        det: "Others",
        txpyble: get("other.txpyble", tbl9) ? get("other.txpyble", tbl9) : 0,
        txpaid_cash: get("other.txpaid_cash", tbl9) ? get("other.txpaid_cash", tbl9) : 0,
      }
    ],
    tbl10: [
      {
        key: "1",
        sno: "10",
        det:
          "Supplies / tax declared through Amendments (+) (net of debit notes)",
        val: get("dbn_amd.txval", tbl10) ? get("dbn_amd.txval", tbl10) : 0,
        iamt: get("dbn_amd.iamt", tbl10) ? get("dbn_amd.iamt", tbl10) : 0,
        camt: get("dbn_amd.camt", tbl10) ? get("dbn_amd.camt", tbl10) : 0,
        samt: get("dbn_amd.samt", tbl10) ? get("dbn_amd.samt", tbl10) : 0,
        cess: get("dbn_amd.csamt", tbl10) ? get("dbn_amd.csamt", tbl10) : 0
      },
      {
        key: "2",
        sno: "11",
        det:
          "Supplies / tax reduced through Amendments (-) (net of credit notes)",
          val: get("cdn_amd.txval", tbl10) ? get("cdn_amd.txval", tbl10) : 0,
          iamt: get("cdn_amd.iamt", tbl10) ? get("cdn_amd.iamt", tbl10) : 0,
          camt: get("cdn_amd.camt", tbl10) ? get("cdn_amd.camt", tbl10) : 0,
          samt: get("cdn_amd.samt", tbl10) ? get("cdn_amd.samt", tbl10) : 0,
          cess: get("cdn_amd.csamt", tbl10) ? get("cdn_amd.csamt", tbl10) : 0
      },
      {
        key: "3",
        sno: "12",
        det: "Reversal of ITC availed during previous financial year",
        iamt: get("itc_rvsl.iamt", tbl10) ? get("itc_rvsl.iamt", tbl10) : 0,
        camt: get("itc_rvsl.camt", tbl10) ? get("itc_rvsl.camt", tbl10) : 0,
        samt: get("itc_rvsl.samt", tbl10) ? get("itc_rvsl.samt", tbl10) : 0,
        cess: get("itc_rvsl.csamt", tbl10) ? get("itc_rvsl.csamt", tbl10) : 0
      },
      {
        key: "4",
        sno: "13",
        det: "ITC availed for the previous financial year",
        iamt: get("itc_availd.iamt", tbl10) ? get("itc_availd.iamt", tbl10) : 0,
        camt: get("itc_availd.camt", tbl10) ? get("itc_availd.camt", tbl10) : 0,
        samt: get("itc_availd.samt", tbl10) ? get("itc_availd.samt", tbl10) : 0,
        cess: get("itc_availd.csamt", tbl10) ? get("itc_availd.csamt", tbl10) : 0
      },
      {
        key: "5",
        sno: "",
        det: "Total turnover(5N + 10 - 11)",
        val: get("total_turnover.txval", tbl10) ? get("total_turnover.txval", tbl10) : 0,
        iamt: get("total_turnover.iamt", tbl10) ? get("total_turnover.iamt", tbl10) : 0,
        camt: get("total_turnover.camt", tbl10) ? get("total_turnover.camt", tbl10) : 0,
        samt: get("total_turnover.samt", tbl10) ? get("total_turnover.samt", tbl10) : 0,
        cess: get("total_turnover.csamt", tbl10) ? get("total_turnover.csamt", tbl10) : 0
      }
    ],
    tbl14: [
      {
        key: "1",
        sno: "A",
        det: "Integrated Tax",
        txpyble: get("iamt.txpyble", tbl14) ? get("iamt.txpyble", tbl14) : 0,
        txpaid: get("iamt.txpaid", tbl14) ? get("iamt.txpaid", tbl14) : 0,
      },
      {
        key: "2",
        sno: "B",
        det: "Central Tax",
        txpyble: get("camt.txpyble", tbl14) ? get("camt.txpyble", tbl14) : 0,
        txpaid: get("camt.txpaid", tbl14) ? get("camt.txpaid", tbl14) : 0,
      },
      {
        key: "3",
        sno: "C",
        det: "State/UT Tax",
        txpyble: get("samt.txpyble", tbl14) ? get("samt.txpyble", tbl14) : 0,
        txpaid: get("samt.txpaid", tbl14) ? get("samt.txpaid", tbl14) : 0,
      },
      {
        key: "4",
        sno: "D",
        det: "Cess",
        txpyble: get("csamt.txpyble", tbl14) ? get("csamt.txpyble", tbl14) : 0,
        txpaid: get("csamt.txpaid", tbl14) ? get("csamt.txpaid", tbl14) : 0,
      },
      {
        key: "5",
        sno: "E",
        det: "Interest",
        txpyble: get("intr.txpyble", tbl14) ? get("intr.txpyble", tbl14) : 0,
        txpaid: get("intr.txpaid", tbl14) ? get("intr.txpaid", tbl14) : 0,
      }
    ],
    tbl15: [
      {
        key: "1",
        sno: "A",
        det: "Total Refund claimed",
        iamt: get("rfd_clmd.iamt", tbl15) ? get("rfd_clmd.iamt", tbl15) : 0,
        camt: get("rfd_clmd.camt", tbl15) ? get("rfd_clmd.camt", tbl15) : 0,
        samt: get("rfd_clmd.samt", tbl15) ? get("rfd_clmd.samt", tbl15) : 0,
        cess: get("rfd_clmd.csamt", tbl15) ? get("rfd_clmd.csamt", tbl15) : 0
      },
      {
        key: "2",
        sno: "B",
        det: "Total Refund sanctioned",
        iamt: get("rfd_sanc.iamt", tbl15) ? get("rfd_sanc.iamt", tbl15) : 0,
        camt: get("rfd_sanc.camt", tbl15) ? get("rfd_sanc.camt", tbl15) : 0,
        samt: get("rfd_sanc.samt", tbl15) ? get("rfd_sanc.samt", tbl15) : 0,
        cess: get("rfd_sanc.csamt", tbl15) ? get("rfd_sanc.csamt", tbl15) : 0
      },
      {
        key: "3",
        sno: "C",
        det: "Total Refund Rejected",
        iamt: get("rfd_rejt.iamt", tbl15) ? get("rfd_rejt.iamt", tbl15) : 0,
        camt: get("rfd_rejt.camt", tbl15) ? get("rfd_rejt.camt", tbl15) : 0,
        samt: get("rfd_rejt.samt", tbl15) ? get("rfd_rejt.samt", tbl15) : 0,
        cess: get("rfd_rejt.csamt", tbl15) ? get("rfd_rejt.csamt", tbl15) : 0
      },
      {
        key: "4",
        sno: "D",
        det: "Total Refund Pending",
        iamt: get("rfd_pend.iamt", tbl15) ? get("rfd_pend.iamt", tbl15) : 0,
        camt: get("rfd_pend.camt", tbl15) ? get("rfd_pend.camt", tbl15) : 0,
        samt: get("rfd_pend.samt", tbl15) ? get("rfd_pend.samt", tbl15) : 0,
        cess: get("rfd_pend.csamt", tbl15) ? get("rfd_pend.csamt", tbl15) : 0
      },
      {
        key: "5",
        sno: "E",
        det: "Total demand of taxes",
        iamt: get("tax_dmnd.iamt", tbl15) ? get("tax_dmnd.iamt", tbl15) : 0,
        camt: get("tax_dmnd.camt", tbl15) ? get("tax_dmnd.camt", tbl15) : 0,
        samt: get("tax_dmnd.samt", tbl15) ? get("tax_dmnd.samt", tbl15) : 0,
        cess: get("tax_dmnd.csamt", tbl15) ? get("tax_dmnd.csamt", tbl15) : 0,
        int: get("tax_dmnd.intr", tbl15) ? get("tax_dmnd.intr", tbl15) : 0,
        penalty: get("tax_dmnd.pen", tbl15) ? get("tax_dmnd.pen", tbl15) : 0,
        late_fees: get("tax_dmnd.fee", tbl15) ? get("tax_dmnd.fee", tbl15) : 0
      },
      {
        key: "6",
        sno: "F",
        det: "Total taxes paid in respect of E above",
        iamt: get("tax_paid.iamt", tbl15) ? get("tax_paid.iamt", tbl15) : 0,
        camt: get("tax_paid.camt", tbl15) ? get("tax_paid.camt", tbl15) : 0,
        samt: get("tax_paid.samt", tbl15) ? get("tax_paid.samt", tbl15) : 0,
        cess: get("tax_paid.csamt", tbl15) ? get("tax_paid.csamt", tbl15) : 0,
        int: get("tax_paid.intr", tbl15) ? get("tax_paid.intr", tbl15) : 0,
        penalty: get("tax_paid.pen", tbl15) ? get("tax_paid.pen", tbl15) : 0,
        late_fees: get("tax_paid.fee", tbl15) ? get("tax_paid.fee", tbl15) : 0
      },
      {
        key: "7",
        sno: "G",
        det: "Total demands pending out of E above",
        iamt: get("dmnd_pend.iamt", tbl15) ? get("dmnd_pend.iamt", tbl15) : 0,
        camt: get("dmnd_pend.camt", tbl15) ? get("dmnd_pend.camt", tbl15) : 0,
        samt: get("dmnd_pend.samt", tbl15) ? get("dmnd_pend.samt", tbl15) : 0,
        cess: get("dmnd_pend.csamt", tbl15) ? get("dmnd_pend.csamt", tbl15) : 0,
        int: get("dmnd_pend.intr", tbl15) ? get("dmnd_pend.intr", tbl15) : 0,
        penalty: get("dmnd_pend.pen", tbl15) ? get("dmnd_pend.pen", tbl15) : 0,
        late_fees: get("dmnd_pend.fee", tbl15) ? get("dmnd_pend.fee", tbl15) : 0
      }
    ],
    tbl16: [
      {
        key: "1",
        sno: "A",
        det: "Supplies received from Composition taxpayers",
        val: get("comp_supp.txval", tbl16) ? get("comp_supp.txval", tbl16) : 0,
      },
      {
        key: "2",
        sno: "B",
        det: "Deemed supply under section 143",
        val: get("deemed_supp.txval", tbl16) ? get("deemed_supp.txval", tbl16) : 0,
        iamt: get("deemed_supp.iamt", tbl16) ? get("deemed_supp.iamt", tbl16) : 0,
        camt: get("deemed_supp.camt", tbl16) ? get("deemed_supp.camt", tbl16) : 0,
        samt: get("deemed_supp.samt", tbl16) ? get("deemed_supp.samt", tbl16) : 0,
        cess: get("deemed_supp.csamt", tbl16) ? get("deemed_supp.csamt", tbl16) : 0,
      },
      {
        key: "3",
        sno: "C",
        det: "Goods sent on approval basis but not returned",
        val: get("not_returned.txval", tbl16) ? get("not_returned.txval", tbl16) : 0,
        iamt: get("not_returned.iamt", tbl16) ? get("not_returned.iamt", tbl16) : 0,
        camt: get("not_returned.camt", tbl16) ? get("not_returned.camt", tbl16) : 0,
        samt: get("not_returned.samt", tbl16) ? get("not_returned.samt", tbl16) : 0,
        cess: get("not_returned.csamt", tbl16) ? get("not_returned.csamt", tbl16) : 0,
      }
    ],
    tbl17: getOutwardHSNDetails(tbl17),
    tbl18: getOutwardHSNDetails(tbl18)
  };
  return formattedData;
};

const countTotalTable4 = function(data) {
  const { tbl4, tbl5, tbl6, tbl7 } = data;
  const newTotals = reduce((d, r) => {
    const key = parseInt(r.key);
    if (key > 0 && key < 8) {
      d.h_val =
        d.h_val === undefined
          ? r.val === undefined
            ? 0.0
            : r.val
          : parseFloat(
              (d.h_val + (r.val === undefined ? 0.0 : r.val)).toFixed(2)
            );
      d.h_iamt =
        d.h_iamt === undefined
          ? r.iamt === undefined
            ? 0
            : r.iamt
          : parseFloat(
              (d.h_iamt + (r.iamt === undefined ? 0 : r.iamt)).toFixed(2)
            );
      d.h_camt =
        d.h_camt === undefined
          ? r.camt === undefined
            ? 0
            : r.camt
          : parseFloat(
              (d.h_camt + (r.camt === undefined ? 0 : r.camt)).toFixed(2)
            );
      d.h_samt =
        d.h_samt === undefined
          ? r.samt === undefined
            ? 0
            : r.samt
          : parseFloat(
              (d.h_samt + (r.samt === undefined ? 0 : r.samt)).toFixed(2)
            );
      d.h_cess =
        d.h_cess === undefined
          ? r.cess === undefined
            ? 0
            : r.cess
          : parseFloat(
              (d.h_cess + (r.cess === undefined ? 0 : r.cess)).toFixed(2)
            );
    } else if (key > 8 && key < 13) {
      if (key === 9 || key === 12) {
        d.m_val =
          d.m_val === undefined
            ? r.val === undefined
              ? 0
              : -r.val
            : parseFloat(
                (d.m_val + (r.val === undefined ? 0 : -r.val)).toFixed(2)
              );
        d.m_iamt =
          d.m_iamt === undefined
            ? r.iamt === undefined
              ? 0
              : -r.iamt
            : parseFloat(
                (d.m_iamt + (r.iamt === undefined ? 0 : -r.iamt)).toFixed(2)
              );
        d.m_camt =
          d.m_camt === undefined
            ? r.camt === undefined
              ? 0
              : -r.camt
            : parseFloat(
                (d.m_camt + (r.camt === undefined ? 0 : -r.camt)).toFixed(2)
              );
        d.m_samt =
          d.m_samt === undefined
            ? r.samt === undefined
              ? 0
              : -r.samt
            : parseFloat(
                (d.m_samt + (r.samt === undefined ? 0 : -r.samt)).toFixed(2)
              );
        d.m_cess =
          d.m_cess === undefined
            ? r.cess === undefined
              ? 0
              : -r.cess
            : parseFloat(
                (d.m_cess + (r.cess === undefined ? 0 : -r.cess)).toFixed(2)
              );
      } else {
        d.m_val =
          d.m_val === undefined
            ? r.val === undefined
              ? 0
              : r.val
            : parseFloat(
                (d.m_val + (r.val === undefined ? 0 : r.val)).toFixed(2)
              );
        d.m_iamt =
          d.m_iamt === undefined
            ? r.iamt === undefined
              ? 0
              : r.iamt
            : parseFloat(
                (d.m_iamt + (r.iamt === undefined ? 0 : r.iamt)).toFixed(2)
              );
        d.m_camt =
          d.m_camt === undefined
            ? r.camt === undefined
              ? 0
              : r.camt
            : parseFloat(
                (d.m_camt + (r.camt === undefined ? 0 : r.camt)).toFixed(2)
              );
        d.m_samt =
          d.m_samt === undefined
            ? r.samt === undefined
              ? 0
              : r.samt
            : parseFloat(
                (d.m_samt + (r.samt === undefined ? 0 : r.samt)).toFixed(2)
              );
        d.m_cess =
          d.m_cess === undefined
            ? r.cess === undefined
              ? 0
              : r.cess
            : parseFloat(
                (d.m_cess + (r.cess === undefined ? 0 : r.cess)).toFixed(2)
              );
      }
    }
    return d;
  }, {})(tbl4);

  const itemIndexH = findIndex({ key: "8" })(tbl4);
  if (itemIndexH > -1) {
    tbl4[itemIndexH].val = newTotals.h_val;
    tbl4[itemIndexH].iamt = newTotals.h_iamt;
    tbl4[itemIndexH].camt = newTotals.h_camt;
    tbl4[itemIndexH].samt = newTotals.h_samt;
    tbl4[itemIndexH].cess = newTotals.h_cess;
  }

  const itemIndexM = findIndex({ key: "13" })(tbl4);
  if (itemIndexM > -1) {
    tbl4[itemIndexM].val = newTotals.m_val;
    tbl4[itemIndexM].iamt = newTotals.m_iamt;
    tbl4[itemIndexM].camt = newTotals.m_camt;
    tbl4[itemIndexM].samt = newTotals.m_samt;
    tbl4[itemIndexM].cess = newTotals.m_cess;
  }

  const itemIndexN = findIndex({ key: "14" })(tbl4);
  if (itemIndexN > -1) {
    tbl4[itemIndexN].val = parseFloat(
      (newTotals.h_val + newTotals.m_val).toFixed(2)
    );
    tbl4[itemIndexN].iamt = parseFloat(
      (newTotals.h_iamt + newTotals.m_iamt).toFixed(2)
    );
    tbl4[itemIndexN].camt = parseFloat(
      (newTotals.h_camt + newTotals.m_camt).toFixed(2)
    );
    tbl4[itemIndexN].samt = parseFloat(
      (newTotals.h_samt + newTotals.m_samt).toFixed(2)
    );
    tbl4[itemIndexN].cess = parseFloat(
      (newTotals.h_cess + newTotals.m_cess).toFixed(2)
    );
  }

  const newTotalsSec5 = reduce((d, r) => {
    const key = parseInt(r.key);
    if (key > 0 && key < 7) {
      d.h_val =
        d.h_val === undefined
          ? r.val === undefined
            ? 0.0
            : r.val
          : parseFloat(
              (d.h_val + (r.val === undefined ? 0.0 : r.val)).toFixed(2)
            );
    } else if (key > 7 && key < 12) {
      if (key === 8 || key === 11) {
        d.m_val =
          d.m_val === undefined
            ? r.val === undefined
              ? 0
              : -r.val
            : parseFloat(
                (d.m_val + (r.val === undefined ? 0 : -r.val)).toFixed(2)
              );
      } else {
        d.m_val =
          d.m_val === undefined
            ? r.val === undefined
              ? 0
              : r.val
            : parseFloat(
                (d.m_val + (r.val === undefined ? 0 : r.val)).toFixed(2)
              );
      }
    }
    return d;
  }, {})(tbl5);

  const itemIndexG_5 = findIndex({ key: "7" })(tbl5);
  if (itemIndexG_5 > -1) {
    tbl5[itemIndexG_5].val = newTotalsSec5.h_val;
  }

  const itemIndexL_5 = findIndex({ key: "12" })(tbl5);
  if (itemIndexL_5 > -1) {
    tbl5[itemIndexL_5].val = newTotalsSec5.m_val;
  }

  const itemIndexM_5 = findIndex({ key: "13" })(tbl5);
  if (itemIndexM_5 > -1) {
    tbl5[itemIndexM_5].val = parseFloat(
      (newTotalsSec5.h_val + newTotalsSec5.m_val).toFixed(2)
    );
  }

  const newTotalsSec6 = reduce((d, r) => {
    const key = parseInt(r.key);
    if (key > 1 && key < 16) {
      d.h_val =
        d.h_val === undefined
          ? r.val === undefined
            ? 0.0
            : r.val
          : parseFloat(
              (d.h_val + (r.val === undefined ? 0.0 : r.val)).toFixed(2)
            );
      d.h_iamt =
        d.h_iamt === undefined
          ? r.iamt === undefined
            ? 0
            : r.iamt
          : parseFloat(
              (d.h_iamt + (r.iamt === undefined ? 0 : r.iamt)).toFixed(2)
            );
      d.h_camt =
        d.h_camt === undefined
          ? r.camt === undefined
            ? 0
            : r.camt
          : parseFloat(
              (d.h_camt + (r.camt === undefined ? 0 : r.camt)).toFixed(2)
            );
      d.h_samt =
        d.h_samt === undefined
          ? r.samt === undefined
            ? 0
            : r.samt
          : parseFloat(
              (d.h_samt + (r.samt === undefined ? 0 : r.samt)).toFixed(2)
            );
      d.h_cess =
        d.h_cess === undefined
          ? r.cess === undefined
            ? 0
            : r.cess
          : parseFloat(
              (d.h_cess + (r.cess === undefined ? 0 : r.cess)).toFixed(2)
            );
    } else if (key > 17 && key < 21) {
      d.n_iamt =
        d.n_iamt === undefined
          ? r.iamt === undefined
            ? 0
            : r.iamt
          : parseFloat(
              (d.n_iamt + (r.iamt === undefined ? 0 : r.iamt)).toFixed(2)
            );
      d.n_camt =
        d.n_camt === undefined
          ? r.camt === undefined
            ? 0
            : r.camt
          : parseFloat(
              (d.n_camt + (r.camt === undefined ? 0 : r.camt)).toFixed(2)
            );
      d.n_samt =
        d.n_samt === undefined
          ? r.samt === undefined
            ? 0
            : r.samt
          : parseFloat(
              (d.n_samt + (r.samt === undefined ? 0 : r.samt)).toFixed(2)
            );
      d.n_cess =
        d.n_cess === undefined
          ? r.cess === undefined
            ? 0
            : r.cess
          : parseFloat(
              (d.n_cess + (r.cess === undefined ? 0 : r.cess)).toFixed(2)
            );
    }
    return d;
  }, {})(tbl6);

  const itemIndexI = findIndex({ key: "16" })(tbl6);
  if (itemIndexI > -1) {
    tbl6[itemIndexI].iamt = newTotalsSec6.h_iamt;
    tbl6[itemIndexI].camt = newTotalsSec6.h_camt;
    tbl6[itemIndexI].samt = newTotalsSec6.h_samt;
    tbl6[itemIndexI].cess = newTotalsSec6.h_cess;
  }

  const itemIndexJ = findIndex({ key: "17" })(tbl6);
  const itemIndexA = findIndex({ key: "1" })(tbl6);
  if (itemIndexJ > -1) {
    tbl6[itemIndexJ].iamt = parseFloat(
      (tbl6[itemIndexI].iamt - tbl6[itemIndexA].iamt).toFixed(2)
    );
    tbl6[itemIndexJ].camt = parseFloat(
      (tbl6[itemIndexI].camt - tbl6[itemIndexA].camt).toFixed(2)
    );
    tbl6[itemIndexJ].samt = parseFloat(
      (tbl6[itemIndexI].samt - tbl6[itemIndexA].samt).toFixed(2)
    );
    tbl6[itemIndexJ].cess = parseFloat(
      (tbl6[itemIndexI].cess - tbl6[itemIndexA].cess).toFixed(2)
    );
  }

  const itemIndexN_sec6 = findIndex({ key: "21" })(tbl6);
  if (itemIndexN_sec6 > -1) {
    tbl6[itemIndexN_sec6].iamt = newTotalsSec6.n_iamt;
    tbl6[itemIndexN_sec6].camt = newTotalsSec6.n_camt;
    tbl6[itemIndexN_sec6].samt = newTotalsSec6.n_samt;
    tbl6[itemIndexN_sec6].cess = newTotalsSec6.n_cess;
  }

  const itemIndexO = findIndex({ key: "22" })(tbl6);
  if (itemIndexO > -1) {
    tbl6[itemIndexO].iamt = parseFloat(
      (tbl6[itemIndexI].iamt + tbl6[itemIndexN_sec6].iamt).toFixed(2)
    );
    tbl6[itemIndexO].camt = parseFloat(
      (tbl6[itemIndexI].camt + tbl6[itemIndexN_sec6].camt).toFixed(2)
    );
    tbl6[itemIndexO].samt = parseFloat(
      (tbl6[itemIndexI].samt + tbl6[itemIndexN_sec6].samt).toFixed(2)
    );
    tbl6[itemIndexO].cess = parseFloat(
      (tbl6[itemIndexI].cess + tbl6[itemIndexN_sec6].cess).toFixed(2)
    );
  }

  const newTotalsSec7 = reduce((d, r) => {
    const key = parseInt(r.key);
    if (key === 8) {
      map(dummy => {
        console.log(dummy);
        d.i_iamt =
          d.i_iamt === undefined
            ? dummy.iamt === undefined
              ? 0
              : dummy.iamt
            : parseFloat(
                (
                  d.i_iamt + (dummy.iamt === undefined ? 0 : dummy.iamt)
                ).toFixed(2)
              );
        d.i_camt =
          d.i_camt === undefined
            ? dummy.camt === undefined
              ? 0
              : dummy.camt
            : parseFloat(
                (
                  d.i_camt + (dummy.camt === undefined ? 0 : dummy.camt)
                ).toFixed(2)
              );
        d.i_samt =
          d.i_samt === undefined
            ? dummy.samt === undefined
              ? 0
              : dummy.samt
            : parseFloat(
                (
                  d.i_samt + (dummy.samt === undefined ? 0 : dummy.samt)
                ).toFixed(2)
              );
        d.i_cess =
          d.i_cess === undefined
            ? dummy.cess === undefined
              ? 0
              : dummy.cess
            : parseFloat(
                (
                  d.i_cess + (dummy.cess === undefined ? 0 : dummy.cess)
                ).toFixed(2)
              );
        return dummy;
      })(r.items);
    } else if (key > 0 && key < 8) {
      d.i_iamt =
        d.i_iamt === undefined
          ? r.iamt === undefined
            ? 0
            : r.iamt
          : parseFloat(
              (d.i_iamt + (r.iamt === undefined ? 0 : r.iamt)).toFixed(2)
            );
      d.i_camt =
        d.i_camt === undefined
          ? r.camt === undefined
            ? 0
            : r.camt
          : parseFloat(
              (d.i_camt + (r.camt === undefined ? 0 : r.camt)).toFixed(2)
            );
      d.i_samt =
        d.i_samt === undefined
          ? r.samt === undefined
            ? 0
            : r.samt
          : parseFloat(
              (d.i_samt + (r.samt === undefined ? 0 : r.samt)).toFixed(2)
            );
      d.i_cess =
        d.i_cess === undefined
          ? r.cess === undefined
            ? 0
            : r.cess
          : parseFloat(
              (d.i_cess + (r.cess === undefined ? 0 : r.cess)).toFixed(2)
            );
    }
    return d;
  }, {})(tbl7);

  const itemIndexI_tbl7 = findIndex({ key: "9" })(tbl7);
  if (itemIndexI_tbl7 > -1) {
    tbl7[itemIndexI_tbl7].iamt = newTotalsSec7.i_iamt;
    tbl7[itemIndexI_tbl7].camt = newTotalsSec7.i_camt;
    tbl7[itemIndexI_tbl7].samt = newTotalsSec7.i_samt;
    tbl7[itemIndexI_tbl7].cess = newTotalsSec7.i_cess;
  }

  return data;
};

const crossTableCalculation = function(data) {
  const itemIndexN_4 = findIndex({ key: "14" })(data.tbl4);
  const itemIndexM_5 = findIndex({ key: "13" })(data.tbl5);
  const itemIndexG_4 = findIndex({ key: "7" })(data.tbl4);
  const itemIndexN_5 = findIndex({ key: "14" })(data.tbl5);

  if (itemIndexN_4 > -1) {
    parseFloat(
      (data.tbl5[itemIndexN_5].val =
        data.tbl4[itemIndexN_4].val +
        data.tbl5[itemIndexM_5].val -
        data.tbl4[itemIndexG_4].val).toFixed(2)
    );
    parseFloat(
      (data.tbl5[itemIndexN_5].iamt =
        data.tbl4[itemIndexN_4].iamt - data.tbl4[itemIndexG_4].iamt).toFixed(2)
    );
    parseFloat(
      (data.tbl5[itemIndexN_5].camt =
        data.tbl4[itemIndexN_4].camt - data.tbl4[itemIndexG_4].camt).toFixed(2)
    );
    parseFloat(
      (data.tbl5[itemIndexN_5].samt =
        data.tbl4[itemIndexN_4].samt - data.tbl4[itemIndexG_4].samt).toFixed(2)
    );
    parseFloat(
      (data.tbl5[itemIndexN_5].cess =
        data.tbl4[itemIndexN_4].cess - data.tbl4[itemIndexG_4].cess).toFixed(2)
    );
  }

  const itemIndexO_6 = findIndex({ key: "22" })(data.tbl6);
  const itemIndexI_7 = findIndex({ key: "9" })(data.tbl7);
  const itemIndexJ_7 = findIndex({ key: "10" })(data.tbl7);

  if (itemIndexO_6 > -1) {
    data.tbl7[itemIndexJ_7].iamt = parseFloat(
      (data.tbl6[itemIndexO_6].iamt - data.tbl7[itemIndexI_7].iamt).toFixed(2)
    );
    data.tbl7[itemIndexJ_7].camt = parseFloat(
      (data.tbl6[itemIndexO_6].camt - data.tbl7[itemIndexI_7].camt).toFixed(2)
    );
    data.tbl7[itemIndexJ_7].samt = parseFloat(
      (data.tbl6[itemIndexO_6].samt - data.tbl7[itemIndexI_7].samt).toFixed(2)
    );
    data.tbl7[itemIndexJ_7].cess = parseFloat(
      (data.tbl6[itemIndexO_6].cess - data.tbl7[itemIndexI_7].cess).toFixed(2)
    );
  }

  const itemIndexB1_6 = findIndex({ key: "2" })(data.tbl6);
  const itemIndexB2_6 = findIndex({ key: "3" })(data.tbl6);
  const itemIndexB3_6 = findIndex({ key: "4" })(data.tbl6);
  const itemIndexH_6 = findIndex({ key: "15" })(data.tbl6);
  const itemIndexB_8 = findIndex({ key: "2" })(data.tbl8);

  if (itemIndexB1_6 > -1) {
    data.tbl8[itemIndexB_8].iamt = parseFloat(
      (
        data.tbl6[itemIndexB1_6].iamt +
        data.tbl6[itemIndexB2_6].iamt +
        data.tbl6[itemIndexB3_6].iamt +
        data.tbl6[itemIndexH_6].iamt
      ).toFixed(2)
    );
    data.tbl8[itemIndexB_8].camt = parseFloat(
      (
        data.tbl6[itemIndexB1_6].camt +
        data.tbl6[itemIndexB2_6].camt +
        data.tbl6[itemIndexB3_6].camt +
        data.tbl6[itemIndexH_6].camt
      ).toFixed(2)
    );
    data.tbl8[itemIndexB_8].samt = parseFloat(
      (
        data.tbl6[itemIndexB1_6].samt +
        data.tbl6[itemIndexB2_6].samt +
        data.tbl6[itemIndexB3_6].samt +
        data.tbl6[itemIndexH_6].samt
      ).toFixed(2)
    );
    data.tbl8[itemIndexB_8].cess = parseFloat(
      (
        data.tbl6[itemIndexB1_6].cess +
        data.tbl6[itemIndexB2_6].cess +
        data.tbl6[itemIndexB3_6].cess +
        data.tbl6[itemIndexH_6].cess
      ).toFixed(2)
    );
  }

  const itemIndexA_8 = findIndex({ key: "1" })(data.tbl8);
  const itemIndexC_8 = findIndex({ key: "3" })(data.tbl8);
  const itemIndexD_8 = findIndex({ key: "4" })(data.tbl8);

  if (itemIndexA_8 > -1) {
    data.tbl8[itemIndexD_8].iamt = parseFloat(
      (
        data.tbl8[itemIndexA_8].iamt -
        (data.tbl8[itemIndexB_8].iamt + data.tbl8[itemIndexC_8].iamt)
      ).toFixed(2)
    );
    data.tbl8[itemIndexD_8].camt = parseFloat(
      (
        data.tbl8[itemIndexA_8].camt -
        (data.tbl8[itemIndexB_8].camt + data.tbl8[itemIndexC_8].camt)
      ).toFixed(2)
    );
    data.tbl8[itemIndexD_8].samt = parseFloat(
      (
        data.tbl8[itemIndexA_8].samt -
        (data.tbl8[itemIndexB_8].samt + data.tbl8[itemIndexC_8].samt)
      ).toFixed(2)
    );
    data.tbl8[itemIndexD_8].cess = parseFloat(
      (
        data.tbl8[itemIndexA_8].cess -
        (data.tbl8[itemIndexB_8].cess + data.tbl8[itemIndexC_8].cess)
      ).toFixed(2)
    );
  }

  const itemIndexH_8 = findIndex({ key: "8" })(data.tbl8);
  const itemIndexE1_6 = findIndex({ key: "11" })(data.tbl6);
  const itemIndexE2_6 = findIndex({ key: "12" })(data.tbl6);

  if (itemIndexE1_6 > -1) {
    data.tbl8[itemIndexH_8].iamt = parseFloat(
      (data.tbl6[itemIndexE1_6].iamt + data.tbl6[itemIndexE2_6].iamt).toFixed(2)
    );
    data.tbl8[itemIndexH_8].camt = parseFloat(
      (data.tbl6[itemIndexE1_6].camt
        ? data.tbl6[itemIndexE1_6].camt
        : 0 + data.tbl6[itemIndexE2_6].camt
        ? data.tbl6[itemIndexE2_6].camt
        : 0
      ).toFixed(2)
    );
    data.tbl8[itemIndexH_8].samt = parseFloat(
      (data.tbl6[itemIndexE1_6].samt
        ? data.tbl6[itemIndexE1_6].samt
        : 0 + data.tbl6[itemIndexE2_6].samt
        ? data.tbl6[itemIndexE2_6].samt
        : 0
      ).toFixed(2)
    );
    data.tbl8[itemIndexH_8].cess = parseFloat(
      (data.tbl6[itemIndexE1_6].cess + data.tbl6[itemIndexE2_6].cess).toFixed(2)
    );
  }

  const itemIndexI_8 = findIndex({ key: "9" })(data.tbl8);
  const itemIndexJ_8 = findIndex({ key: "10" })(data.tbl8);
  const itemIndexG_8 = findIndex({ key: "7" })(data.tbl8);

  if (itemIndexG_8 > -1) {
    data.tbl8[itemIndexI_8].iamt = parseFloat(
      (data.tbl8[itemIndexG_8].iamt
        ? data.tbl8[itemIndexG_8].iamt
        : 0 - data.tbl8[itemIndexH_8].iamt
        ? data.tbl8[itemIndexH_8].iamt
        : 0
      ).toFixed(2)
    );
    data.tbl8[itemIndexI_8].camt = parseFloat(
      (data.tbl8[itemIndexG_8].camt
        ? data.tbl8[itemIndexG_8].camt
        : 0 - data.tbl8[itemIndexH_8].camt
        ? data.tbl8[itemIndexH_8].camt
        : 0
      ).toFixed(2)
    );
    data.tbl8[itemIndexI_8].samt = parseFloat(
      (data.tbl8[itemIndexG_8].samt
        ? data.tbl8[itemIndexG_8].samt
        : 0 - data.tbl8[itemIndexH_8].samt
        ? data.tbl8[itemIndexH_8].samt
        : 0
      ).toFixed(2)
    );
    data.tbl8[itemIndexI_8].cess = parseFloat(
      (data.tbl8[itemIndexG_8].cess
        ? data.tbl8[itemIndexG_8].cess
        : 0 - data.tbl8[itemIndexH_8].cess
        ? data.tbl8[itemIndexH_8].cess
        : 0
      ).toFixed(2)
    );

    data.tbl8[itemIndexJ_8].iamt = data.tbl8[itemIndexI_8].iamt;
    data.tbl8[itemIndexJ_8].camt = data.tbl8[itemIndexI_8].camt;
    data.tbl8[itemIndexJ_8].samt = data.tbl8[itemIndexI_8].samt;
    data.tbl8[itemIndexJ_8].cess = data.tbl8[itemIndexI_8].cess;
  }

  const itemIndexK_8 = findIndex({ key: "11" })(data.tbl8);
  const itemIndexE_8 = findIndex({ key: "5" })(data.tbl8);
  const itemIndexF_8 = findIndex({ key: "6" })(data.tbl8);

  if (itemIndexE_8 > -1) {
    data.tbl8[itemIndexK_8].iamt = parseFloat(
      (
        (data.tbl8[itemIndexE_8].iamt ? data.tbl8[itemIndexE_8].iamt : 0) +
        (data.tbl8[itemIndexF_8].iamt ? data.tbl8[itemIndexF_8].iamt : 0) +
        (data.tbl8[itemIndexJ_8].iamt ? data.tbl8[itemIndexJ_8].iamt : 0)
      ).toFixed(2)
    );
    data.tbl8[itemIndexK_8].camt = parseFloat(
      (
        (data.tbl8[itemIndexE_8].camt ? data.tbl8[itemIndexE_8].camt : 0) +
        (data.tbl8[itemIndexF_8].camt ? data.tbl8[itemIndexF_8].camt : 0) +
        (data.tbl8[itemIndexJ_8].camt ? data.tbl8[itemIndexJ_8].camt : 0)
      ).toFixed(2)
    );
    data.tbl8[itemIndexK_8].samt = parseFloat(
      (
        (data.tbl8[itemIndexE_8].samt ? data.tbl8[itemIndexE_8].samt : 0) +
        (data.tbl8[itemIndexF_8].samt ? data.tbl8[itemIndexF_8].samt : 0) +
        (data.tbl8[itemIndexJ_8].samt ? data.tbl8[itemIndexJ_8].samt : 0)
      ).toFixed(2)
    );
    data.tbl8[itemIndexK_8].cess = parseFloat(
      (
        (data.tbl8[itemIndexE_8].cess ? data.tbl8[itemIndexE_8].cess : 0) +
        (data.tbl8[itemIndexF_8].cess ? data.tbl8[itemIndexF_8].cess : 0) +
        (data.tbl8[itemIndexJ_8].cess ? data.tbl8[itemIndexJ_8].cess : 0)
      ).toFixed(2)
    );
  }

  const itemIndexE_10 = findIndex({ key: "5" })(data.tbl10);
  const itemIndexA_10 = findIndex({ key: "1" })(data.tbl10);
  const itemIndexB_10 = findIndex({ key: "2" })(data.tbl10);

  if (itemIndexA_10 > -1) {
    data.tbl10[itemIndexE_10].val = parseFloat(
      (
        (data.tbl5[itemIndexN_5].val ? data.tbl5[itemIndexN_5].val : 0) +
        (data.tbl10[itemIndexA_10].val ? data.tbl10[itemIndexA_10].val : 0) -
        (data.tbl10[itemIndexB_10].val ? data.tbl10[itemIndexB_10].val : 0)
      ).toFixed(2)
    );
    data.tbl10[itemIndexE_10].iamt = parseFloat(
      (
        (data.tbl5[itemIndexN_5].iamt ? data.tbl5[itemIndexN_5].iamt : 0) +
        (data.tbl10[itemIndexA_10].iamt ? data.tbl10[itemIndexA_10].iamt : 0) -
        (data.tbl10[itemIndexB_10].iamt ? data.tbl10[itemIndexB_10].iamt : 0)
      ).toFixed(2)
    );
    data.tbl10[itemIndexE_10].camt = parseFloat(
      (
        (data.tbl5[itemIndexN_5].camt ? data.tbl5[itemIndexN_5].camt : 0) +
        (data.tbl10[itemIndexA_10].camt ? data.tbl10[itemIndexA_10].camt : 0) -
        (data.tbl10[itemIndexB_10].camt ? data.tbl10[itemIndexB_10].camt : 0)
      ).toFixed(2)
    );
    data.tbl10[itemIndexE_10].samt = parseFloat(
      (
        (data.tbl5[itemIndexN_5].samt ? data.tbl5[itemIndexN_5].samt : 0) +
        (data.tbl10[itemIndexA_10].samt ? data.tbl10[itemIndexA_10].samt : 0) -
        (data.tbl10[itemIndexB_10].samt ? data.tbl10[itemIndexB_10].samt : 0)
      ).toFixed(2)
    );
    data.tbl10[itemIndexE_10].cess = parseFloat(
      (
        (data.tbl5[itemIndexN_5].cess ? data.tbl5[itemIndexN_5].cess : 0) +
        (data.tbl10[itemIndexA_10].cess ? data.tbl10[itemIndexA_10].cess : 0) -
        (data.tbl10[itemIndexB_10].cess ? data.tbl10[itemIndexB_10].cess : 0)
      ).toFixed(2)
    );
  }

  return data;
};

const getDummyData = function() {
  return {
    table5: {
      nil: { txval: 0 },
      dr_nt: { txval: 0 },
      amd_neg: { txval: 0 },
      non_gst: { txval: 0 },
      exmt: { txval: 0 },
      amd_pos: { txval: 0 },
      sez: { txval: 0 },
      rchrg: { txval: 0 },
      zero_rtd: { txval: 0 },
      cr_nt: { txval: 0 },
      chksum: "92314a0c85445e401fc386b73ec0578995be194df4f4bb1194d6cf91ac7a46ac"
    },
    table4: {
      dr_nt: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      b2c: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      b2b: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      amd_neg: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      at: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      amd_pos: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      sez: { csamt: 0, txval: 0, iamt: 0 },
      rchrg: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      deemed: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      exp: { csamt: 0, txval: 0, iamt: 0 },
      cr_nt: { csamt: 0, samt: 0, txval: 0, camt: 0, iamt: 0 },
      chksum: "875308b5015acab2df3da537f471347584690dbc8a444a735d3138a34b5802f1"
    },
    fp: "032018",
    gstin: "33AAGCT3794B1ZF",
    table9: {
      csamt: { txpyble: 0, txpaid_cash: 0, tax_paid_itc_csamt: 0 },
      samt: {
        tax_paid_itc_iamt: 0,
        tax_paid_itc_samt: 0,
        txpyble: 0,
        txpaid_cash: 0
      },
      intr: { txpyble: 0, txpaid_cash: 0 },
      fee: { txpyble: 0, txpaid_cash: 0 },
      camt: {
        tax_paid_itc_iamt: 0,
        txpyble: 0,
        tax_paid_itc_camt: 0,
        txpaid_cash: 0
      },
      iamt: {
        tax_paid_itc_iamt: 0,
        tax_paid_itc_samt: 0,
        txpyble: 0,
        tax_paid_itc_camt: 0,
        txpaid_cash: 0
      },
      chksum: "b60eb393cf22075ba58651d2ea9ef19f75653225dd539f887884a2fd9c78760e"
    },
    table8: {
      itc_2a: { csamt: 0, samt: 540, camt: 540, iamt: 39807 },
      chksum: "efb8c5256dd07bd994fd1b53cfedff4cc9566c21590692d0d1876d844c69de0f"
    },
    table6: {
      tran2: { samt: 0, camt: 0 },
      tran1: { samt: 0, camt: 0 },
      itc_3b: { csamt: 0, samt: 0, camt: 0, iamt: 9810 },
      isd: { csamt: 0, samt: 0, camt: 0, iamt: 0 },
      chksum: "ffdc03d6fa414dedd12e28e23018b7287306fefe9973c1ac94710d21ce0e7e62"
    }
  };
};

module.exports = {
  formatNumber,
  formatAndSetTable4Data,
  countTotalTable4,
  crossTableCalculation,
  getDummyData
};
