
import React from 'react'
import glamorous from 'glamorous'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Tooltip from 'antd/lib/tooltip'
import Row from 'antd/lib/row'
import get from 'lodash/fp/get'
import { V2_RECON_PR_SEZWOP_PurchaseQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { formatNumber, BASE_URL } from "../../shared/constants";
import { Root, ContentWrapper as Content } from '../../shared/styles'
import Modal from 'antd/lib/modal'
import message from 'antd/lib/message'
import OTPForm from "../GSP/OTPForm";
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import gspAuth from "../../services/GSPService";
import deleteData from "../../services/DeleteService";
import ANX2_SEZWOP_Items from './ANX2SEZWOP_Items'
const errorModal = Modal.error;
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const { Column } = Table
const SRow = glamorous(Row)({ margin: '15px 0', })

const ANX2SEZWOP = props => {
    console.log(props.v2_recon_pr_sezwop_masters)
    return (
        <Root>
            <Content>
                <SRow gutter={24} style={row_style}>
                </SRow>
                <Table dataSource={props.v2_recon_pr_sezwop_masters} bordered title={() => 'SEZWOP Purchases'} pagination={{ className: 'my-pagination' }}>
                    <Column title="GSTIN/UIN of Supplier" dataIndex="supplier_gstin" ></Column>
                    <Column title="Trade/Legal Name" dataIndex="supplier_name"></Column>
                    <Column title="Document Type" key="" dataIndex="invoice_type" ></Column>
                    <Column title="Document Number" key="" dataIndex="invoice_number" ></Column>
                    <Column title="Document Date" key="" dataIndex="invoice_date" ></Column>
                    <Column title="Document Value" key="" dataIndex="invoice_value" ></Column>
                    <Column title="Place Of Supply" dataIndex="pos_id"></Column>
                    <Column title="Filing Status" dataIndex="filing_status"></Column>
                    <Column title="Upload Date" dataIndex="upload_date"></Column>
                    <Column title="Items" key=""
                        render={(text, record) => {
                            return (
                                <Tooltip title="View Item(s)">
                                    <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                                        {record.v2_recon_pr_sezwop_items.pageInfo.total}  Item(s)
                            </Button>
                                    <ANX2_SEZWOP_Items isVisible={props.visibleModal === record.id} onCancel={props.hideModal} v2_recon_pr_sezwop_items={record.v2_recon_pr_sezwop_items.edges} />
                                </Tooltip>
                            )
                        }}
                    />
                </Table>
                <script type="text/javascript" src="FileSaver.js"></script>
            </Content>
        </Root>
    )
}
class ANX2SEZWOPContainer extends React.Component {
    constructor(props) {
        super(props);
        if (props.gspDetails !== undefined && props.gspDetails !== null) {
            this.gsp = {
                access_token: props.gspDetails.gspToken,
                otp: props.gspDetails.otp,
                response: null
            };
        } else {
            this.gsp = {};
        }

        var d = new Date();
        var n = d.getMonth();
        var y = d.getYear();
        var curyear = 1900 + y;
        var nextyear = curyear + 1 - 2000;
        var year = curyear + '-' + nextyear;
        this.state = {
            visibleModal: null,
            finMonth: n + 1,
            finYear: year,
            curFinMonth: n + 1,
            curFinYear: year,

            visible: false,
            requestOTP: false,
            otp: null,
            period: null,
            selectedRowKeys: []
        }
        this.props.data.refetch()
    }

    componentDidUpdate = () => {

        if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
            this.gsp.access_token = this.props.gspDetails.gspToken;
            this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
        }
    };


    handleCancel = e => {
        message.destroy();
        this.setState({ requestOTP: false });
    };

    hide = () => {
        this.setState({
            visible: false
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    handleOTPSubmit = val => {
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            form.resetFields();
            this.gsp.otp = values.otp.trim();
            this.setState({ requestOTP: false });
            gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
        });
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    showModal = async id => {
        this.setState({ visibleModal: id })
    }
    hideModal = () => {
        this.setState({ visibleModal: null })
    }
    changeSelectedFinancialMonth = name => {
        this.props.changeSelectedFinancialMonth(name)
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
    }
    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideDefaultSelections: true,
            selections: [{
                key: 'all-data',
                text: 'Select All Invoices',
                onSelect: () => {

                    this.setState({
                        selectedRowKeys: get('viewer.v2_recon_pr_sezwop_masters.edges')(this.props.data).map(a => a.id),

                    })
                },
            }]
        };
        return (
            <div style={{ flex: 1 }}>
                <ANX2SEZWOP
                    v2_recon_pr_sezwop_masters={get('viewer.v2_recon_pr_sezwop_masters.edges')(this.props.data)}
                    finMonth={this.state.finMonth}
                    finYear={this.state.finYear}
                    showModal={this.showModal}
                    hideModal={this.hideModal}
                    visibleModal={this.state.visibleModal}
                    isSR={this.props.isSR}
                    formatNumber={formatNumber}
                    rowSelection={rowSelection}
                />
                <OTPForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.requestOTP}
                    onSubmit={this.handleOTPSubmit}
                    onCancel={this.handleCancel}
                />
            </div>
        )
    }
}

const queryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            type: 'prsezwop',
            finmonth: props.finMonth.toString(),
            finyear: props.finYear,
        },
    }),
}
const companyQueryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finYear: props.finYear,
            finMonth: props.finMonth,
        },
    }),
    props: ({ data }) => {
        return {
            company: get('viewer.company', data),
            gspDetails: get("viewer.gspInfo", data),
        }
    },
}
const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany,
        finMonth: state.local.finMonth,
        finYear: state.local.finYear,
    }
}

export default compose(
    connect(mapStateToProps),
    graphql(gql(V2_RECON_PR_SEZWOP_PurchaseQuery), queryConfig),
    graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(ANX2SEZWOPContainer)