import React from 'react'
import { Form, Input, message, Table, Modal, Tag, Row, Col } from 'antd'
import {
  CompanyCreateMutation, ITC_comparison_Query
} from 'qgst-gql-queries/web/data.json'
import get from "lodash/fp/get";
import filter from 'lodash/fp/filter';
import Glamorous from 'glamorous'
import { connect } from "react-redux";
import { gql, graphql,compose } from 'react-apollo'
import gspAuth from "../../services/GSPService";
import Auth from '../../services/AuthService'
import { BASE_URL } from '../../shared/constants'
import moment from 'moment';
import { actions } from '../../redux'
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import { ROUTES, i18n } from '../../shared/constants'
import { Button, ContentWrapper as Content } from '../../shared/styles'
import { FormItem } from '../../shared/utils'
const popModal = Modal.info;

const DemoFields = props =>
  <Content>
    <p style={{  textAlign: 'center', display: 'block', margin: '20px 0', fontSize: '25px' }}> Welcome to ElixirGST ITC Report</p>
    <Row style={{ padding: '10px 25px 10px 25px' }}>
      <Col span={12}>
        <ol>
          <style>
                {`
                  ol li{
                    padding: 5px;
                  }
            `}
          </style>
          <li>Go to <a style={{  color: '#2424ca' }} href="https://services.gst.gov.in/services/login" target="_blank">GST Portal </a>
            and log on to your GST account. <Button style={{ height: '14px', width: '14px', fontSize: '10px' }} type="primary" onClick={props.showImageForStep1} shape="circle" icon="info" size='small' /></li>
          <li>Click Menu icon on top-right and navigate to 'My Profile'.(For desktops directly go to 'View Profile') <Button style={{ height: '14px', width: '14px', fontSize: '10px' }} type="primary" onClick={props.showImageForStep2} shape="circle" icon="info" size='small' /></li>
          <li>Click 'Manage API Access'. <Button style={{ height: '14px', width: '14px', fontSize: '10px' }} type="primary" onClick={props.showImageForStep3} shape="circle" icon="info" size='small' /></li>
          <li>Enable it by selecting 'Yes' in 'Enable API Request' and select desired duration for API access, clicking 'Confirm' will activate.
             your API session. <Button style={{ height: '14px', width: '14px', fontSize: '10px' }} type="primary" onClick={props.showImageForStep4} shape="circle" icon="info" size='small' /></li>
        </ol>
      </Col>
      <Col span={12}>
        <ol start={5}>
          <li>You can now see this notification once API is activated. <Button style={{ height: '14px', width: '14px', fontSize: '10px' }} type="primary" onClick={props.showImageForStep5} shape="circle" icon="info" size='small' /></li>
          <li>Coming back to demo page, Enter your details and click 'Get ITC Report' to generate the report.</li>
          <li>It will prompt for a OTP which you will receive via Email/SMS, enter the OTP and click 'Submit'. <Button style={{ height: '14px', width: '14px', fontSize: '10px' }} type="primary" onClick={props.showImageForStep6} shape="circle" icon="info" size='small' /></li>
          <li>Once it completes fetching the data you will be able to see the generated report.</li>
        </ol>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Form onSubmit={props.saveCompany}>
          <FormItem label="GSTIN">
            {props.getFieldDecorator('gstin', {
              rules: [{ required: true, message: 'Please input GSTIN!' }],
            })(<Input id="gstId" placeholder="GSTIN" disabled={props.isEdit} type="text" maxLength="15" required/>)}
          </FormItem>
          <FormItem label="GSTN UserName">
            {props.getFieldDecorator('gstnUserName', {
              rules: [{ required: true, message: 'Please input GSTN UserName!' }],
              initialValue: props.company ? props.company.gstnUserName : '',
            })(<Input id="gstnUserName" placeholder="GSTN UserName" type="text" maxLength="15" required/>)}
          </FormItem>
          <FormItem label="Company Name">
            {props.getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input Company Name!' }],
              initialValue: props.company ? props.company.name : '',
            })(<Input  placeholder="Company Name" />)}
          </FormItem>
          <Form.Item style={{ textAlign: 'center', display: 'block' }}>
            <Button type="primary" icon="cloud-download" htmlType="submit">
              Get ITC Report
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  </Content>


class Demo extends React.Component {


  constructor(props) {
    super(props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response_2a: null,
        response_3b: null
      };
    } else {
      this.gsp = {};
    }
    this.showImageForStep1 = this.showImageForStep1.bind(this);
  }

  state = {
    companyId_state: null,
    showTable: false,
    requestOTP: false,
    gstin:null,
    gstinUsername:null,
    otp: null,
    fromPeriod: null,
    toPeriod: null,
    fromPullData: null,
    fromReconcile:null,
    redirectToDashboard: Auth.isLoggedIn(),
    imgToRender: '',
    imgPopUpVisible: false
  };

  popupWidth = window.innerWidth - 250;

  componentDidMount = async () => {

    const validateDemoLoginIS = await  Auth.isLoggedIn();
    const validateDemoLogin = await Auth.login({email: 'j.v.rajesh@gmail.com', password: 'testdemo'});

  }

  componentDidUpdate = () => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = this.props.gspDetails.otp;
    }
  };

  reqHelp = () => {
    popModal({
      title: 'Provide your number to contact you.',
      iconType: 'exclamation-circle',
      content: <Input id='demoCallBack' defaultValue='+91 ' placeholder="Enter Number" />,
      okText: 'Submit',
      cancelText: 'Cancel',
      onOk() {
        const details = document.getElementById("demoCallBack").value;
        return fetch(`${BASE_URL}/export/sendDemoMail?details=${details}`,
        {
          method: 'POST',
        })
        .then((result) => {
          message.destroy();
          message.success('Callback request submitted successfully!');
        })

      },
      onCancel() {}
    });
  }

  showImageForStep1 = () => {
    this.setState({imgToRender: BASE_URL + '/portal/static/images/step1.jpg', imgPopUpVisible: true});
  }

  showImageForStep2 = () => {
    this.setState({imgToRender: BASE_URL + '/portal/static/images/step2.jpg', imgPopUpVisible: true});
  }

  showImageForStep3 = () => {
    this.setState({imgToRender: BASE_URL + '/portal/static/images/step3.jpg', imgPopUpVisible: true});
  }

  showImageForStep4 = () => {
    this.setState({imgToRender: BASE_URL + '/portal/static/images/step4.jpg', imgPopUpVisible: true});
  }

  showImageForStep5 = () => {
    this.setState({imgToRender: BASE_URL + '/portal/static/images/step5.jpg', imgPopUpVisible: true});
  }

  showImageForStep6 = () => {
    this.setState({imgToRender: BASE_URL + '/portal/static/images/step6.jpg', imgPopUpVisible: true});
  }

  showImage = (img) => {
    console.log(img);

  }

  getCounterPartyData = async (values)  => {

    const reqData = {
      companyDetails: {
        gstin: values.gstin,
        gstnusername: values.gstnUserName,
        state: values.gstin.substr(0, 2),
        id: this.state.companyId_state
      },
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      fromPeriod: '072017',
      toPeriod: moment().format("MMYYYY")
    };

    message.destroy();
    message.loading('Pulling Counter-party data from GSTN...',0);

    this.gsp.response_2a = await gspAuth.getCounterPartyData(reqData, 'B2B');

    if (!this.gsp.response_2a.data.success && this.gsp.response_2a.data.errorCode.toUpperCase() === "RETOTPREQUEST")
    {
      message.destroy();
      this.setState({ requestOTP: true, fromPeriod: '072017', toPeriod: moment().format('MMYYYY'), fromPullData: true, fromReconcile: null });
    }else if(!this.gsp.response_2a.data.success && this.gsp.response_2a.data.errorCode === 'GEN5008'){
      message.destroy();
      message.error('Govt API under Maintenance. Try after sometime!')
    }
    else {
    message.destroy();
    message.loading('Pulling Claimed ITC from GSTN...',0);
    this.gsp.response_3b = await gspAuth.getGSTR3BData(reqData);

      if (this.gsp.response_3b.data.success === true) {
        console.log(this.gsp.response_3b);
        message.destroy();
        message.success('ITC Report generated successfully!')
        this.props.itc_corner_calculations.refetch();
        this.setState({ showTable: true });
        return this.gsp.response_3b;
      }
    }
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      message.destroy();
      message.loading('Pulling from GSTN...',0);
      this.getCounterPartyData({ gstin: this.state.gstin, gstnUserName: this.state.gstnUserName });
      gspAuth.saveOTP(this.gsp.otp, this.state.companyId_state);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  saveCompany = async e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (err) return
      else{
        try {
          console.log('----DEMO----');
          //saveDB
          try {
            const save2DB = await this.props.saveDemoField(values)
            this.setState({ companyId_state: save2DB.data.createCompany.id, gstin: save2DB.data.createCompany.gstin, gstnUserName: values.gstnUserName });
          }
          catch(e) {
              const checkDB = await fetch(`${BASE_URL}/export/findGSTIN?details=${values.gstin}`,
              {
                method: 'GET',
              })
              .then(async (result) => {
                const body = await result.json();
                console.log(body);
                this.setState({ companyId_state: body.id, gstin: body.gstin, gstnUserName: body.gstnUserName });
              })
          }
          console.log("save2DB");

          this.props.changeSelectedCompany(this.state.companyId_state, values.gstin);

          //2aReq//3breq
          const finishProcess = await this.getCounterPartyData(values);
          console.log(finishProcess);
          if(finishProcess.data.success)
            {
              message.destroy();
              message.success('ITC Report generated successfully!')
              this.props.itc_corner_calculations.refetch();
              this.setState({ showTable: true });
            }

        }
        catch (e) {
          console.log(e);
        }
      }
    })
  }

  handleCancel = e => {
    this.setState({ requestOTP: false });
  }
  handleOK = e => {
    this.setState({ imgPopUpVisible: false });
  }
  cancelPopUp = e => {
    this.setState({ imgPopUpVisible: false });
  }
  getCompanyName = async (e) => {
    console.log(e.target.value);
    e.preventDefault();
    if(e.target.value.length === 15){
      const resp = await gspAuth.getCompanyName(e.target.value);
      console.log(resp);
      if(!resp || resp.data.success === false){
        return false;
      }
      this.props.form.setFieldsValue({
        name: resp.data.resp
      });
    }
  }

  demoText = (total) => {

    var n1, n2;
    const totalDerive = total.split('-');
    n1 = totalDerive[0];
    n2 = totalDerive[1] || null;

    if(n2 === null){

    return (<p  style={{  textAlign: 'center', display: 'block',  fontSize: '18px' }}>You have
    <Tag style={{ cursor: 'initial', marginLeft: '5px', fontSize: '18px' }} color='volcano'>claimed {n1} more</Tag>
    than the amount filed by suppliers. This could be liable for audit and penalties!
    </p>)

    }else if(n2 !== null){

       return (<p  style={{  textAlign: 'center', display: 'block',  fontSize: '18px' }}>You have
        <Tag  style={{ cursor: 'initial', marginLeft: '5px', fontSize: '18px' }} color='volcano'>missed ₹ {n2}</Tag>
        of eligible ITC filed by your suppliers and ended up paying it in cash!
      </p>)

    }

  }

render () {
  var itcLoad = false;
  var DemoData = new Array();

  if (this.props.itc_corner_calculations.networkStatus === 4) { itcLoad = false;}
  else if (this.props.itc_corner_calculations.networkStatus === 7) { itcLoad = false; }
  else if (this.props.itc_corner_calculations.networkStatus === 1) { itcLoad = true; }

  const maxWidth = window.innerWidth - 215 + "px";
  const dataSource = this.props.itc_corner === undefined ? [] : JSON.parse(get("itc_comparison.edges.data",this.props.itc_corner));
  const scrollX = this.props.itc_corner === undefined ? 0 : parseInt(get("itc_comparison.edges.scrollx",this.props.itc_corner));
  console.log(dataSource[5]);
  DemoData = DemoData.concat(dataSource[5]);
  DemoData = DemoData.concat(dataSource[7]);
  DemoData = DemoData.concat(dataSource[9]);
  console.log(DemoData);

if(this.state.showTable){
  console.log(DemoData);
  DemoData[0].title = 'Your Available ITC (GSTR-3B)';
  DemoData[1].title = 'Your Eligible ITC (GSTR-2A)';
  DemoData[2].title = 'ITC Mismatch (3B minus 2A)';
}

return (
  <div>
  <DemoFields
    handleChange={this.handleChange}
    saveCompany={this.saveCompany}
    getFieldDecorator={this.props.form.getFieldDecorator}
    company={this.props.company}
    getCompanyName={this.getCompanyName}
    isEdit={this.props.isEdit}
    showImageForStep1={this.showImageForStep1}
    showImageForStep2={this.showImageForStep2}
    showImageForStep3={this.showImageForStep3}
    showImageForStep4={this.showImageForStep4}
    showImageForStep5={this.showImageForStep5}
    showImageForStep6={this.showImageForStep6}
  />

  {
    this.state.showTable &&
  <div>
  <Table
    columns={get(
      "itc_comparison.edges.columns",
      this.props.itc_corner
    )}
    bordered
    loading={itcLoad}
    dataSource={DemoData}
    pagination={false}
    scroll={{ x: scrollX }}
    title={() => "ITC Report (GSTR-3B Monthly Report vs GSTR-2A Monthly Report)"}
  />
  <br />
  <br />
  <br />
  {this.demoText(dataSource[9].total)}
  </div>
 }

 <Glamorous.P textAlign="center" paddingTop='45px'>
   {i18n.en.powered_by}
 </Glamorous.P>
 <Glamorous.P textAlign="center" paddingTop='25px'>
   +91 98841 14067 | sid@thoughtbit.in
 </Glamorous.P>
 <div style={{ paddingTop: '14px', textAlign: 'center', display: 'block' }} >
 <Button style={{ backgroundColor: '#52636f', fontSize: '13px', color: 'white'}} icon="phone" onClick={this.reqHelp}>
   Request a CallBack!
 </Button>
 </div>
  <OTPForm
    wrappedComponentRef={this.saveFormRef}
    visible={this.state.requestOTP}
    onSubmit={this.handleOTPSubmit}
    onCancel={this.handleCancel}
  />
  <Modal
    title="Image Reference"
    visible={this.state.imgPopUpVisible}
    onOk={this.handleOK}
    onCancel={this.cancelPopUp}
    width='50%'
  >
    <div> <img width="100%" src={ this.state.imgToRender } /> </div>
  </Modal>
</div>
)
}
}

const demoConfig = {
  props: ({ mutate, ownProps }) => {
    return {
      saveDemoField: (values) => {
        return mutate({
          variables: values
        })
      }
    }
  }
}

const config = {
  name: "itc_corner_calculations",
  options: props => ({
    variables: {
      companyId: props.selectedCompany
    }
  }),
  props: ({ itc_corner_calculations }) => {
    return {
      itc_corner_calculations : itc_corner_calculations,
      itc_corner: get("viewer", itc_corner_calculations)
    };
  }
};

const mapStateToProps = state => {
  console.log(state);
  return {
    selectedCompany: state.local.selectedCompany,
    gspDetails: state.gsp
  };
};

export default compose(
  connect(mapStateToProps,
    {
      changeSelectedCompany: actions.changeSelectedCompany,
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  Form.create(),
  graphql(gql(ITC_comparison_Query), config),
  graphql(gql(CompanyCreateMutation), demoConfig),
)(Demo)
