import fetch from "isomorphic-fetch";
import { BASE_URL } from "../shared/constants";
import message from "antd/lib/message";
const { getPlaceOfSupply } = require("../shared/constants");
const XLSX = require("xlsx");
var fileSaver = require("file-saver");
var blob = require("blob-util");
var moment = require("moment");

const { flow, map, flattenDeep, find } = require("lodash/fp");

const gspAuth = {
  login: () => {
    return fetch(BASE_URL + "/gsp/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  saveOTP: (otp, companyId) => {
    return fetch(BASE_URL + "/gsp/saveotp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ otp: otp, company_id: companyId }),
    });
  },
  getCounterPartyData: (
    { companyDetails, gspDetails, toPeriod, period, reqId },
    action,
    version
  ) => {
    if (version === 2) {
      return fetch(BASE_URL + `/gsp/anx2?`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          action: action,
          period: period,
          toPeriod: toPeriod,
          reqId,
          ...companyDetails,
          ...gspDetails,
        },
      })
        .then((res) => res.json())
        .then(function(pData_Invoice) {
          return pData_Invoice;
        });
    }

    return fetch(BASE_URL + `/gsp/save2a?`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        action: action,
        period: period,
        toPeriod: toPeriod,
        reqId,
        ...companyDetails,
        ...gspDetails,
      },
    })
      .then((res) => res.json())
      .then(function(pData_Invoice) {
        return pData_Invoice;
      });
  },
  getCounterParty2BData: (
    { companyDetails, gspDetails, toPeriod, period, reqId },
    action, 
  ) => {
    debugger; 
    return fetch(BASE_URL + `/gsp/save2b?`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        action: action,
        period: period,
        toPeriod: toPeriod,
        reqId,
        ...companyDetails,
        ...gspDetails,
      },
    })
      .then((res) => res.json())
      .then(function(pData_Invoice) {
        return pData_Invoice;
      });
  },


  getGSTR3BData: ({ gspDetails, companyDetails, period }) => {
    console.log(gspDetails);
    console.log(companyDetails);
    console.log(period);
    return fetch(BASE_URL + `/gsp/gstr3bsum?`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  getB2BData: ({ gspDetails, companyDetails, period, action }) => {
    console.log(gspDetails);
    console.log(companyDetails);
    console.log(period);
    return fetch(BASE_URL + `/gsp/getb2bsum?`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        period: period,
        action: action,
        ...gspDetails,
        ...companyDetails,
      },
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },

  saveGSTR1: ({ companyDetails, gspDetails, period, body }, type) => {
    return fetch(BASE_URL + `/gsp/gstr1-retsave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        console.log(type);

        if (
          !pData.retstatus ||
          (pData.retstatus && !pData.retstatus.error_report) ||
          pData.retstatus.error_report.error_cd === "RET191106"
        ) {
          return pData;
        }
        const pData_Invoice = pData.retstatus.error_report;

        console.log(pData_Invoice);
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        }
        var wb_new = XLSX.utils.book_new();

        if (
          pData_Invoice.b2b &&
          pData_Invoice.b2b !== undefined &&
          pData_Invoice.b2b !== null
        ) {
          wb_new.SheetNames.push("B2B - 4A, 4B, 4C, 6B, 6C");
        }
        if (
          pData_Invoice.b2cl &&
          pData_Invoice.b2cl !== undefined &&
          pData_Invoice.b2cl !== null
        ) {
          wb_new.SheetNames.push("B2CL - 5A, 5B");
        }
        if (
          pData_Invoice.b2cs &&
          pData_Invoice.b2cs !== undefined &&
          pData_Invoice.b2cs !== null
        ) {
          wb_new.SheetNames.push("B2CS - 7");
        }
        if (
          pData_Invoice.cdnr &&
          pData_Invoice.cdnr !== undefined &&
          pData_Invoice.cdnr !== null
        ) {
          wb_new.SheetNames.push("CDNR - 9B");
        }
        if (
          pData_Invoice.cdnur &&
          pData_Invoice.cdnur !== undefined &&
          pData_Invoice.cdnur !== null
        ) {
          wb_new.SheetNames.push("CDNUR - 9B");
        }
        if (
          pData_Invoice.exp &&
          pData_Invoice.exp !== undefined &&
          pData_Invoice.exp !== null
        ) {
          wb_new.SheetNames.push("EXP - 6A");
        }
        if (
          pData_Invoice.at &&
          pData_Invoice.at !== undefined &&
          pData_Invoice.at !== null
        ) {
          wb_new.SheetNames.push("AT - 11A(1), 11A(2)");
        }
        if (
          pData_Invoice.txpd &&
          pData_Invoice.txpd !== undefined &&
          pData_Invoice.txpd !== null
        ) {
          wb_new.SheetNames.push("ATADJ - 11B(1), 11B(2)");
        }
        if (
          pData_Invoice.b2ba &&
          pData_Invoice.b2ba !== undefined &&
          pData_Invoice.b2ba !== null
        ) {
          wb_new.SheetNames.push("B2BA - 9A");
        }
        if (
          pData_Invoice.b2cla &&
          pData_Invoice.b2cla !== undefined &&
          pData_Invoice.b2cla !== null
        ) {
          wb_new.SheetNames.push("B2CLA - 9A");
        }
        if (
          pData_Invoice.b2csa &&
          pData_Invoice.b2csa !== undefined &&
          pData_Invoice.b2csa !== null
        ) {
          wb_new.SheetNames.push("B2CSA - 10");
        }
        if (
          pData_Invoice.cdnra &&
          pData_Invoice.cdnra !== undefined &&
          pData_Invoice.cdnra !== null
        ) {
          wb_new.SheetNames.push("CDNRA - 9C");
        }
        if (
          pData_Invoice.cdnura &&
          pData_Invoice.cdnura !== undefined &&
          pData_Invoice.cdnura !== null
        ) {
          wb_new.SheetNames.push("CDNURA - 9C");
        }
        if (
          pData_Invoice.expa &&
          pData_Invoice.expa !== undefined &&
          pData_Invoice.expa !== null
        ) {
          wb_new.SheetNames.push("EXPA - 9A");
        }
        if (
          pData_Invoice.ata &&
          pData_Invoice.ata !== undefined &&
          pData_Invoice.ata !== null
        ) {
          wb_new.SheetNames.push("ATA - 11(2)");
        }
        if (
          pData_Invoice.txpda &&
          pData_Invoice.txpda !== undefined &&
          pData_Invoice.txpda !== null
        ) {
          wb_new.SheetNames.push("ATADJA - 11(2)");
        }
        if (
          pData_Invoice.hsn &&
          pData_Invoice.hsn !== undefined &&
          pData_Invoice.hsn !== null
        ) {
          wb_new.SheetNames.push("HSN - 12");
        }
        if (
          pData_Invoice.nil &&
          pData_Invoice.nil !== undefined &&
          pData_Invoice.nil !== null
        ) {
          wb_new.SheetNames.push("EXEMP - 8A, 8B, 8C, 8D");
        }
        if (
          pData_Invoice.doc_issue &&
          pData_Invoice.doc_issue !== undefined &&
          pData_Invoice.doc_issue !== null
        ) {
          wb_new.SheetNames.push("DOC - 13");
        }

        //format B2B data
        var b2bDataList = [];

        if (pData_Invoice.b2b !== undefined && pData_Invoice.b2b !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;
              const Invoices = map((Invoice) => {
                const InvoiceItems = map((InvoiceItems) => {
                  return {
                    "Receiver GSTIN/UIN*": InvError.ctin,
                    "Invoice No.*": Invoice.inum,
                    "Invoice Date*": Invoice.idt,
                    "Invoice Value*": Invoice.val,
                    "Sale from Bonded WH":
                      Invoice.inv_typ === "CBW"
                        ? "SBWH"
                        : Invoice.inv_typ === "R"
                        ? "Regular"
                        : Invoice.inv_typ,
                    "Place of Supply*": Invoice.pos ? getPlaceOfSupply(Invoice.pos) : null,
                    "Applicable % of Tax Rate": Invoice.diff_percent
                      ? Invoice.diff_percent
                      : "",
                    "Reverse Charge*": Invoice.rchrg ? Invoice.rchrg : "",
                    "Rate*": InvoiceItems.itm_det.rt
                      ? InvoiceItems.itm_det.rt
                      : "",
                    "Taxable Value*": InvoiceItems.itm_det.txval
                      ? InvoiceItems.itm_det.txval
                      : "",
                    "Integrated Tax*": InvoiceItems.itm_det.iamt
                      ? InvoiceItems.itm_det.iamt
                      : "",
                    "Central Tax*": InvoiceItems.itm_det.camt
                      ? InvoiceItems.itm_det.camt
                      : "",
                    "State/UT Tax*": InvoiceItems.itm_det.samt
                      ? InvoiceItems.itm_det.samt
                      : "",
                    Cess: InvoiceItems.itm_det.csamt
                      ? InvoiceItems.itm_det.csamt
                      : "",
                    Status: ErrorMessage,
                  };
                })(Invoice.itms);
                return InvoiceItems;
              })(InvError.inv);
              return Invoices;
            }),
            flattenDeep
          )(pData_Invoice.b2b);

          console.log(ExcelErrorData);
          b2bDataList = ExcelErrorData;
        }
        //format B2CL data
        var b2clDataList = [];

        if (pData_Invoice.b2cl !== undefined && pData_Invoice.b2cl !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;
              const Invoices = map((Invoice) => {
                const InvoiceItems = map((InvoiceItems) => {
                  return {
                    "Invoice No.*": Invoice.inum,
                    "Invoice Date*": Invoice.idt,
                    "Invoice Value*": Invoice.val,
                    "Sale from Bonded WH":
                      Invoice.inv_typ === "CBW" ? "Y" : undefined,
                    "Place of Supply*": InvError.pos ? getPlaceOfSupply(InvError.pos) : null,
                    "Applicable % of Tax Rate": Invoice.diff_percent
                      ? Invoice.diff_percent
                      : "",
                    "Reverse Charge*": Invoice.rchrg ? Invoice.rchrg : "",
                    "Rate*": InvoiceItems.itm_det.rt
                      ? InvoiceItems.itm_det.rt
                      : "",
                    "Taxable Value*": InvoiceItems.itm_det.txval
                      ? InvoiceItems.itm_det.txval
                      : "",
                    "Integrated Tax*": InvoiceItems.itm_det.iamt
                      ? InvoiceItems.itm_det.iamt
                      : "",
                    "Central Tax*": InvoiceItems.itm_det.camt
                      ? InvoiceItems.itm_det.camt
                      : "",
                    "State/UT Tax*": InvoiceItems.itm_det.samt
                      ? InvoiceItems.itm_det.samt
                      : "",
                    Cess: InvoiceItems.itm_det.csamt
                      ? InvoiceItems.itm_det.csamt
                      : "",
                    Status: ErrorMessage,
                  };
                })(Invoice.itms);
                return InvoiceItems;
              })(InvError.inv);
              return Invoices;
            }),
            flattenDeep
          )(pData_Invoice.b2cl);
          b2clDataList = ExcelErrorData;
        }
        //format B2CS data
        var b2csDataList = [];
        var b2csData = {};
        if (pData_Invoice.b2cs !== undefined && pData_Invoice.b2cs !== null) {
          for (var i = 0; i < pData_Invoice.b2cs.length; i++) {
            b2csData = {
              // 'Invoice Type*': pData_Invoice.b2cs[i]["Invoice Type*"],
              // 'Place of Supply*': pData_Invoice.b2cs[i]["Place of Supply*"],
              // 'Supply Type*': pData_Invoice.b2cs[i]["Supply Type*"],
              // 'Applicable % of Tax Rate': pData_Invoice.b2cs[i]["Applicable % of Tax Rate"],
              // 'Rate*': pData_Invoice.b2cs[i]["Rate*"],
              // 'Taxable Value*': pData_Invoice.b2cs[i]["Taxable Value*"],
              // 'Integrated Tax*': pData_Invoice.b2cs[i]["Integrated Tax*"],
              // 'Central Tax*': pData_Invoice.b2cs[i]["Central Tax*"],
              // 'State/UT Tax*': pData_Invoice.b2cs[i]["State/UT Tax*"],
              // 'Cess': pData_Invoice.b2cs[i]["Cess"],
              // 'E-Commerce GSTIN': pData_Invoice.b2cs[i]["E-Commerce GSTIN"],
              "Error Report": pData_Invoice.b2cs[i]["error_msg"],
            };
            b2csDataList.push(b2csData);
          }
        }
        //format CDNR data
        var cdnrDataList = [];

        if (pData_Invoice.cdnr !== undefined && pData_Invoice.cdnr !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;
              const Invoices = map((Invoice) => {
                const InvoiceItems = map((InvoiceItems) => {
                  return {
                    "Receiver GSTIN/UIN*": InvError.ctin,
                    "Invoice/Advance Receipt Number*": Invoice.inum,
                    "Invoice/Advance Receipt Date*": Invoice.idt,
                    "Note/Refund Voucher Number*": Invoice.nt_num,
                    "Note/Refund Voucher Date*": Invoice.nt_dt,
                    "Note/Refund Voucher Value*": Invoice.val,
                    "Document Type*": Invoice.ntty,
                    "Applicable % of Tax Rate": Invoice.diff_percent
                      ? Invoice.diff_percent
                      : "",
                    "Pre GST*": Invoice.p_gst ? Invoice.p_gst : "",
                    "Rate*": InvoiceItems.itm_det.rt
                      ? InvoiceItems.itm_det.rt
                      : "",
                    "Taxable Value*": InvoiceItems.itm_det.txval
                      ? InvoiceItems.itm_det.txval
                      : "",
                    "Integrated Tax*": InvoiceItems.itm_det.iamt
                      ? InvoiceItems.itm_det.iamt
                      : "",
                    "Central Tax*": InvoiceItems.itm_det.camt
                      ? InvoiceItems.itm_det.camt
                      : "",
                    "State/UT Tax*": InvoiceItems.itm_det.samt
                      ? InvoiceItems.itm_det.samt
                      : "",
                    Cess: InvoiceItems.itm_det.csamt
                      ? InvoiceItems.itm_det.csamt
                      : "",
                    Status: ErrorMessage,
                  };
                })(Invoice.itms);
                return InvoiceItems;
              })(InvError.nt);
              return Invoices;
            }),
            flattenDeep
          )(pData_Invoice.cdnr);
          cdnrDataList = ExcelErrorData;
        }
        //format CDNUR data
        var cdnurDataList = [];

        if (pData_Invoice.cdnur !== undefined && pData_Invoice.cdnur !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;

              const InvoiceItems = map((InvoiceItems) => {
                return {
                  "Invoice Type*": InvError.typ,
                  "Invoice/Advance Receipt Number*": InvError.inum,
                  "Invoice/Advance Receipt Date*": InvError.idt,
                  "Note/Refund Voucher Number*": InvError.nt_num,
                  "Note/Refund Voucher Date*": InvError.nt_dt,
                  "Note/Refund Voucher Value*": InvError.val,
                  "Document Type*": InvError.ntty,
                  "Applicable % of Tax Rate": InvError.diff_percent
                    ? InvError.diff_percent
                    : "",
                  "Pre GST*": InvError.p_gst ? InvError.p_gst : "",
                  "Rate*": InvoiceItems.itm_det.rt
                    ? InvoiceItems.itm_det.rt
                    : "",
                  "Taxable Value*": InvoiceItems.itm_det.txval
                    ? InvoiceItems.itm_det.txval
                    : "",
                  "Integrated Tax*": InvoiceItems.itm_det.iamt
                    ? InvoiceItems.itm_det.iamt
                    : "",
                  "Central Tax*": InvoiceItems.itm_det.camt
                    ? InvoiceItems.itm_det.camt
                    : "",
                  "State/UT Tax*": InvoiceItems.itm_det.samt
                    ? InvoiceItems.itm_det.samt
                    : "",
                  Cess: InvoiceItems.itm_det.csamt
                    ? InvoiceItems.itm_det.csamt
                    : "",
                  Status: ErrorMessage,
                };
              })(InvError.itms);
              return InvoiceItems;
            }),
            flattenDeep
          )(pData_Invoice.cdnur);
          cdnurDataList = ExcelErrorData;
        }
        //format EXPORTS data
        var exportsDataList = [];

        if (pData_Invoice.exp !== undefined && pData_Invoice.exp !== null) {
          const expExcelErrorData = flow(
            map((expInvError) => {
              const expType = expInvError.exp_typ;
              const expErrorMessage = expInvError.error_msg;
              const expInvoices = map((expInvoice) => {
                const expInvoiceItems = map((expInvoiceItems) => {
                  return {
                    "Export Type*": expType,
                    "Invoice No.*": expInvoice.inum,
                    "Invoice Date*": expInvoice.idt,
                    "Invoice Value*": expInvoice.val,
                    "Applicable % of Tax Rate": expInvoice.diff_percent
                      ? expInvoice.diff_percent
                      : "",
                    "Port Code": expInvoice.sbpcode ? expInvoice.sbpcode : "",
                    "Shipping Bill No.": expInvoice.sbnum
                      ? expInvoice.sbnum
                      : "",
                    "Shipping Bill Date": expInvoice.sbdt
                      ? expInvoice.sbdt
                      : "",
                    "Rate*": expInvoiceItems.rt ? expInvoiceItems.rt : "",
                    "Taxable Value*": expInvoiceItems.txval
                      ? expInvoiceItems.txval
                      : "",
                    "Integrated Tax*": expInvoiceItems.iamt,
                    Cess: expInvoiceItems.csamt ? expInvoiceItems.csamt : "",
                    Status: expErrorMessage,
                  };
                })(expInvoice.itms);
                return expInvoiceItems;
              })(expInvError.inv);
              return expInvoices;
            }),
            flattenDeep
          )(pData_Invoice.exp);
          // console.log(expExcelErrorData);
          exportsDataList = expExcelErrorData;
        }

        //format AT data
        var atDataList = [];

        if (pData_Invoice.at !== undefined && pData_Invoice.at !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;

              const InvoiceItems = map((InvoiceItems) => {
                return {
                  "Place of Supply*": InvError.pos ? getPlaceOfSupply(InvError.pos) : null,
                  "Supply Type*": InvError.sply_ty,
                  "Applicable % of Tax Rate": InvError.diff_percent
                    ? InvError.diff_percent
                    : "",
                  "Rate*": InvoiceItems.rt ? InvoiceItems.rt : "",
                  "Gross Advance Received*": InvoiceItems.ad_amt
                    ? InvoiceItems.ad_amt
                    : "",
                  "Integrated Tax*": InvoiceItems.iamt ? InvoiceItems.iamt : "",
                  "Central Tax*": InvoiceItems.camt ? InvoiceItems.camt : "",
                  "State/UT Tax*": InvoiceItems.samt ? InvoiceItems.samt : "",
                  Cess: InvoiceItems.csamt ? InvoiceItems.csamt : "",
                  Status: ErrorMessage,
                };
              })(InvError.itms);
              return InvoiceItems;
            }),
            flattenDeep
          )(pData_Invoice.at);
          atDataList = ExcelErrorData;
        }
        //format ATADJ data
        var atadjDataList = [];

        if (pData_Invoice.txpd !== undefined && pData_Invoice.txpd !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;

              const InvoiceItems = map((InvoiceItems) => {
                return {
                  "Place of Supply*": InvError.pos ? getPlaceOfSupply(InvError.pos):null,
                  "Supply Type*": InvError.sply_ty,
                  "Applicable % of Tax Rate": InvError.diff_percent
                    ? InvError.diff_percent
                    : "",
                  "Rate*": InvoiceItems.rt ? InvoiceItems.rt : "",
                  "Gross Advance Adjusted*": InvoiceItems.ad_amt
                    ? InvoiceItems.ad_amt
                    : "",
                  "Integrated Tax*": InvoiceItems.iamt ? InvoiceItems.iamt : "",
                  "Central Tax*": InvoiceItems.camt ? InvoiceItems.camt : "",
                  "State/UT Tax*": InvoiceItems.samt ? InvoiceItems.samt : "",
                  Cess: InvoiceItems.csamt ? InvoiceItems.csamt : "",
                  Status: ErrorMessage,
                };
              })(InvError.itms);
              return InvoiceItems;
            }),
            flattenDeep
          )(pData_Invoice.txpd);
          atadjDataList = ExcelErrorData;
        }
        //format B2BA data
        var b2baDataList = [];

        if (pData_Invoice.b2ba !== undefined && pData_Invoice.b2ba !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;
              const Invoices = map((Invoice) => {
                const InvoiceItems = map((InvoiceItems) => {
                  return {
                    "Receiver GSTIN/UIN*": InvError.ctin,
                    "Original Invoice Number*": Invoice.oinum,
                    "Original Invoice Date*": Invoice.oidt,
                    "Revised Invoice Number*": Invoice.inum,
                    "Revised Invoice Date*": Invoice.idt,
                    "Revised Invoice Value*": Invoice.val,
                    "Sale from Bonded WH":
                      Invoice.inv_typ === "CBW"
                        ? "SBWH"
                        : Invoice.inv_typ === "R"
                        ? "Regular"
                        : Invoice.inv_typ,
                    "Place of Supply*": Invoice.pos ? getPlaceOfSupply(Invoice.pos) : null,
                    "Applicable % of Tax Rate": Invoice.diff_percent
                      ? Invoice.diff_percent
                      : "",
                    "Reverse Charge*": Invoice.rchrg ? Invoice.rchrg : "",
                    "Rate*": InvoiceItems.itm_det.rt
                      ? InvoiceItems.itm_det.rt
                      : "",
                    "Taxable Value*": InvoiceItems.itm_det.txval
                      ? InvoiceItems.itm_det.txval
                      : "",
                    "Integrated Tax*": InvoiceItems.itm_det.iamt
                      ? InvoiceItems.itm_det.iamt
                      : "",
                    "Central Tax*": InvoiceItems.itm_det.camt
                      ? InvoiceItems.itm_det.camt
                      : "",
                    "State/UT Tax*": InvoiceItems.itm_det.samt
                      ? InvoiceItems.itm_det.samt
                      : "",
                    Cess: InvoiceItems.itm_det.csamt
                      ? InvoiceItems.itm_det.csamt
                      : "",
                    Status: ErrorMessage,
                  };
                })(Invoice.itms);
                return InvoiceItems;
              })(InvError.inv);
              return Invoices;
            }),
            flattenDeep
          )(pData_Invoice.b2ba);

          console.log(ExcelErrorData);
          b2baDataList = ExcelErrorData;
        }
        //format B2CLA data
        var b2claDataList = [];

        if (pData_Invoice.b2cla !== undefined && pData_Invoice.b2cla !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;
              const Invoices = map((Invoice) => {
                const InvoiceItems = map((InvoiceItems) => {
                  return {
                    "Original Invoice Number*": Invoice.oinum,
                    "Original Invoice Date*": Invoice.oidt,
                    "Revised Invoice Number*": Invoice.inum,
                    "Revised Invoice Date*": Invoice.idt,
                    "Revised Invoice Value*": Invoice.val,
                    "Sale from Bonded WH":
                      Invoice.inv_typ === "CBW" ? "Y" : undefined,
                    "Place of Supply*": InvError.pos ? getPlaceOfSupply(InvError.pos) : null,
                    "Applicable % of Tax Rate": Invoice.diff_percent
                      ? Invoice.diff_percent
                      : "",
                    "Reverse Charge*": Invoice.rchrg ? Invoice.rchrg : "",
                    "Rate*": InvoiceItems.itm_det.rt
                      ? InvoiceItems.itm_det.rt
                      : "",
                    "Taxable Value*": InvoiceItems.itm_det.txval
                      ? InvoiceItems.itm_det.txval
                      : "",
                    "Integrated Tax*": InvoiceItems.itm_det.iamt
                      ? InvoiceItems.itm_det.iamt
                      : "",
                    "Central Tax*": InvoiceItems.itm_det.camt
                      ? InvoiceItems.itm_det.camt
                      : "",
                    "State/UT Tax*": InvoiceItems.itm_det.samt
                      ? InvoiceItems.itm_det.samt
                      : "",
                    Cess: InvoiceItems.itm_det.csamt
                      ? InvoiceItems.itm_det.csamt
                      : "",
                    Status: ErrorMessage,
                  };
                })(Invoice.itms);
                return InvoiceItems;
              })(InvError.inv);
              return Invoices;
            }),
            flattenDeep
          )(pData_Invoice.b2cla);
          b2claDataList = ExcelErrorData;
        }
        //format B2CSA data
        var b2csaDataList = [];
        var b2csaData = {};
        if (pData_Invoice.b2csa !== undefined && pData_Invoice.b2csa !== null) {
          for (var i = 0; i < pData_Invoice.b2csa.length; i++) {
            b2csaData = {
              // 'Invoice Type*': pData_Invoice.b2csa[i]["Invoice Type*"],
              // 'Original Year*': pData_Invoice.b2csa[i]["Original Year*"],
              // 'Original Month*': pData_Invoice.b2csa[i]["Original Month*"],
              // 'Original Place of Supply*': pData_Invoice.b2csa[i]["Original Place of Supply*"],
              // 'Revised Place of Supply*': pData_Invoice.b2csa[i]["Revised Place of Supply*"],
              // 'Supply Type*': pData_Invoice.b2csa[i]["Supply Type*"],
              // 'Applicable % of Tax Rate': pData_Invoice.b2csa[i]["Applicable % of Tax Rate"],
              // 'E-Commerce GSTIN': pData_Invoice.b2csa[i]["E-Commerce GSTIN"],
              // 'Rate*': pData_Invoice.b2csa[i]["Rate*"],
              // 'Taxable Value*': pData_Invoice.b2csa[i]["Taxable Value*"],
              // 'Integrated Tax*': pData_Invoice.b2csa[i]["Integrated Tax*"],
              // 'Central Tax*': pData_Invoice.b2csa[i]["Central Tax*"],
              // 'State/UT Tax*': pData_Invoice.b2csa[i]["State/UT Tax*"],
              // 'Cess': pData_Invoice.b2csa[i]["Cess"],
              "Error Report": pData_Invoice.b2csa[i]["error_msg"],
            };
            b2csaDataList.push(b2csaData);
          }
        }
        //format CDNRA data
        var cdnraDataList = [];

        if (pData_Invoice.cdnra !== undefined && pData_Invoice.cdnra !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;
              const Invoices = map((Invoice) => {
                const InvoiceItems = map((InvoiceItems) => {
                  return {
                    "Receiver GSTIN/UIN*": InvError.ctin,
                    "Invoice/Advance Receipt Number*": Invoice.inum,
                    "Invoice/Advance Receipt Date*": Invoice.idt,
                    "Original Note/Refund Voucher Number*": Invoice.ont_num,
                    "Original Note/Refund Voucher Date*": Invoice.ont_dt,
                    "Revised Note/Refund Voucher Number*": Invoice.nt_num,
                    "Revised Note/Refund Voucher Date*": Invoice.nt_dt,
                    "Revised Note/Refund Voucher Value*": Invoice.val,
                    "Document Type*": Invoice.ntty,
                    "Applicable % of Tax Rate": Invoice.diff_percent
                      ? Invoice.diff_percent
                      : "",
                    "Pre GST*": Invoice.p_gst ? Invoice.p_gst : "",
                    "Rate*": InvoiceItems.itm_det.rt
                      ? InvoiceItems.itm_det.rt
                      : "",
                    "Taxable Value*": InvoiceItems.itm_det.txval
                      ? InvoiceItems.itm_det.txval
                      : "",
                    "Integrated Tax*": InvoiceItems.itm_det.iamt
                      ? InvoiceItems.itm_det.iamt
                      : "",
                    "Central Tax*": InvoiceItems.itm_det.camt
                      ? InvoiceItems.itm_det.camt
                      : "",
                    "State/UT Tax*": InvoiceItems.itm_det.samt
                      ? InvoiceItems.itm_det.samt
                      : "",
                    Cess: InvoiceItems.itm_det.csamt
                      ? InvoiceItems.itm_det.csamt
                      : "",
                    Status: ErrorMessage,
                  };
                })(Invoice.itms);
                return InvoiceItems;
              })(InvError.nt);
              return Invoices;
            }),
            flattenDeep
          )(pData_Invoice.cdnra);
          cdnraDataList = ExcelErrorData;
        }
        //format CDNURA data
        var cdnuraDataList = [];

        if (
          pData_Invoice.cdnura !== undefined &&
          pData_Invoice.cdnura !== null
        ) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;

              const InvoiceItems = map((InvoiceItems) => {
                return {
                  "Invoice Type*": InvError.typ,
                  "Invoice/Advance Receipt Number*": InvError.inum,
                  "Invoice/Advance Receipt Date*": InvError.idt,
                  "Original Note/Refund Voucher Number*": InvError.ont_num,
                  "Original Note/Refund Voucher Date*": InvError.ont_dt,
                  "Revised Note/Refund Voucher Number*": InvError.nt_num,
                  "Revised Note/Refund Voucher Date*": InvError.nt_dt,
                  "Revised Note/Refund Voucher Value*": InvError.val,
                  "Document Type*": InvError.ntty,
                  "Applicable % of Tax Rate": InvError.diff_percent
                    ? InvError.diff_percent
                    : "",
                  "Pre GST*": InvError.p_gst ? InvError.p_gst : "",
                  "Rate*": InvoiceItems.itm_det.rt
                    ? InvoiceItems.itm_det.rt
                    : "",
                  "Taxable Value*": InvoiceItems.itm_det.txval
                    ? InvoiceItems.itm_det.txval
                    : "",
                  "Integrated Tax*": InvoiceItems.itm_det.iamt
                    ? InvoiceItems.itm_det.iamt
                    : "",
                  "Central Tax*": InvoiceItems.itm_det.camt
                    ? InvoiceItems.itm_det.camt
                    : "",
                  "State/UT Tax*": InvoiceItems.itm_det.samt
                    ? InvoiceItems.itm_det.samt
                    : "",
                  Cess: InvoiceItems.itm_det.csamt
                    ? InvoiceItems.itm_det.csamt
                    : "",
                  Status: ErrorMessage,
                };
              })(InvError.itms);
              return InvoiceItems;
            }),
            flattenDeep
          )(pData_Invoice.cdnura);
          cdnuraDataList = ExcelErrorData;
        }
        //format EXPORTSA data
        var exportsaDataList = [];

        if (pData_Invoice.expa !== undefined && pData_Invoice.expa !== null) {
          const expExcelErrorData = flow(
            map((expInvError) => {
              const expType = expInvError.exp_typ;
              const expErrorMessage = expInvError.error_msg;
              const expInvoices = map((expInvoice) => {
                const expInvoiceItems = map((expInvoiceItems) => {
                  return {
                    "Export Type*": expType,
                    "Original Invoice No.*": expInvoice.oinum,
                    "Original Invoice Date*": expInvoice.oidt,
                    "Revised Invoice No.*": expInvoice.inum,
                    "Revised Invoice Date*": expInvoice.idt,
                    "Revised Invoice Value*": expInvoice.val,
                    "Applicable % of Tax Rate": expInvoice.diff_percent
                      ? expInvoice.diff_percent
                      : "",
                    "Port Code": expInvoice.sbpcode ? expInvoice.sbpcode : "",
                    "Shipping Bill No.": expInvoice.sbnum
                      ? expInvoice.sbnum
                      : "",
                    "Shipping Bill Date": expInvoice.sbdt
                      ? expInvoice.sbdt
                      : "",
                    "Rate*": expInvoiceItems.rt ? expInvoiceItems.rt : "",
                    "Taxable Value*": expInvoiceItems.txval
                      ? expInvoiceItems.txval
                      : "",
                    "Integrated Tax*": expInvoiceItems.iamt,
                    Cess: expInvoiceItems.csamt ? expInvoiceItems.csamt : "",
                    Status: expErrorMessage,
                  };
                })(expInvoice.itms);
                return expInvoiceItems;
              })(expInvError.inv);
              return expInvoices;
            }),
            flattenDeep
          )(pData_Invoice.expa);
          // console.log(expExcelErrorData);
          exportsaDataList = expExcelErrorData;
        }

        //format ATA data
        var ataDataList = [];

        if (pData_Invoice.ata !== undefined && pData_Invoice.ata !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;

              const InvoiceItems = map((InvoiceItems) => {
                return {
                  "Original Month*": moment(
                    InvError.omon.substring(0, 2)
                  ).format("MMMM"),
                  "Original Place of Supply*": getPlaceOfSupply(InvError.pos),
                  "Supply Type*": InvError.sply_ty,
                  "Applicable % of Tax Rate": InvError.diff_percent
                    ? InvError.diff_percent
                    : "",
                  "Rate*": InvoiceItems.rt ? InvoiceItems.rt : "",
                  "Gross Advance Received*": InvoiceItems.ad_amt
                    ? InvoiceItems.ad_amt
                    : "",
                  "Integrated Tax*": InvoiceItems.iamt ? InvoiceItems.iamt : "",
                  "Central Tax*": InvoiceItems.camt ? InvoiceItems.camt : "",
                  "State/UT Tax*": InvoiceItems.samt ? InvoiceItems.samt : "",
                  Cess: InvoiceItems.csamt ? InvoiceItems.csamt : "",
                  Status: ErrorMessage,
                };
              })(InvError.itms);
              return InvoiceItems;
            }),
            flattenDeep
          )(pData_Invoice.ata);
          ataDataList = ExcelErrorData;
        }
        //format ATADJA data
        var atadjaDataList = [];

        if (pData_Invoice.txpda !== undefined && pData_Invoice.txpda !== null) {
          const ExcelErrorData = flow(
            map((InvError) => {
              const ErrorMessage = InvError.error_msg;

              const InvoiceItems = map((InvoiceItems) => {
                return {
                  "Original Month*": moment(
                    InvError.omon.substring(0, 2)
                  ).format("MMMM"),
                  "Original Place of Supply*":  getPlaceOfSupply(InvError.pos),
                  "Supply Type*": InvError.sply_ty,
                  "Applicable % of Tax Rate": InvError.diff_percent
                    ? InvError.diff_percent
                    : "",
                  "Rate*": InvoiceItems.rt ? InvoiceItems.rt : "",
                  "Gross Advance Adjusted*": InvoiceItems.ad_amt
                    ? InvoiceItems.ad_amt
                    : "",
                  "Integrated Tax*": InvoiceItems.iamt ? InvoiceItems.iamt : "",
                  "Central Tax*": InvoiceItems.camt ? InvoiceItems.camt : "",
                  "State/UT Tax*": InvoiceItems.samt ? InvoiceItems.samt : "",
                  Cess: InvoiceItems.csamt ? InvoiceItems.csamt : "",
                  Status: ErrorMessage,
                };
              })(InvError.itms);
              return InvoiceItems;
            }),
            flattenDeep
          )(pData_Invoice.txpda);
          atadjaDataList = ExcelErrorData;
        }
        //format HSN data
        var hsnDataList = [];
        var hsnData = {};
        if (pData_Invoice.hsn !== undefined && pData_Invoice.hsn !== null) {
          for (var i = 0; i < pData_Invoice.hsn.length; i++) {
            hsnData = {
              // 'HSN*': pData_Invoice.hsn[i]["HSN*"],
              // 'Description*': pData_Invoice.hsn[i]["Description*"],
              // 'UQC': pData_Invoice.hsn[i]["UQC"],
              // 'Total Quantity*': pData_Invoice.hsn[i]["Total Quantity*"],
              // 'Total Value*': pData_Invoice.hsn[i]["Total Value*"],
              // 'Taxable Value*': pData_Invoice.hsn[i]["Taxable Value*"],
              // 'Integrated Tax*': pData_Invoice.hsn[i]["Integrated Tax*"],
              // 'Central Tax*': pData_Invoice.hsn[i]["Central Tax*"],
              // 'State/UT Tax*': pData_Invoice.hsn[i]["State/UT Tax*"],
              // 'Cess': pData_Invoice.hsn[i]["Cess"],
              "Error Report": pData_Invoice.hsn[i]["error_msg"],
            };
            hsnDataList.push(hsnData);
          }
        }
        //format HSN data end
        //format EXEMP data
        var exempDataList = [];
        var exempData = {};
        if (pData_Invoice.nil !== undefined && pData_Invoice.nil !== null) {
          for (var i = 0; i < pData_Invoice.nil.length; i++) {
            console.log(pData_Invoice.nil[i]);

            console.log(pData_Invoice.nil[i]);
            exempData = {
              // 'Description': pData_Invoice.nil[i]["Description"],
              // 'Nil Rated Supplies': pData_Invoice.nil[i]["Nil Rated Supplies"],
              // 'Exempted (Other than Nil Rated / Non-GST Supplies)': pData_Invoice.nil[i]["Exempted (Other than Nil Rated / Non-GST Supplies)"],
              // 'Non-GST Supplies': pData_Invoice.nil[i]["Non-GST Supplies"],
              "Error Report": pData_Invoice.nil[i]["error_msg"],
            };
            console.log(exempData);
            exempDataList.push(exempData);
            console.log(exempDataList);
          }
        }
        //format DOC data
        var docDataList = [];
        var docData = {};
        if (
          pData_Invoice.doc_issue !== undefined &&
          pData_Invoice.doc_issue !== null
        ) {
          for (var i = 0; i < pData_Invoice.doc_issue.length; i++) {
            console.log(pData_Invoice.doc_issue[i]);
            docData = {
              // 'Nature of Document*': pData_Invoice.doc_issue[i]["Nature of Document*"],
              // '. No. From*': pData_Invoice.doc_issue[i][". No. From*"],
              // '. No. To*': pData_Invoice.doc_issue[i][". No. To*"],
              // 'Total Number*': pData_Invoice.doc_issue[i]["Total Number*"],
              // 'Cancelled*': pData_Invoice.doc_issue[i]["Cancelled*"],
              "Error Report": pData_Invoice.doc_issue[i]["error_msg"],
            };
            console.log(docData);
            docDataList.push(docData);
            console.log(docDataList);
          }
        }

        if (
          pData_Invoice.b2b &&
          pData_Invoice.b2b !== undefined &&
          pData_Invoice.b2b !== null
        ) {
          wb_new.Sheets["B2B - 4A, 4B, 4C, 6B, 6C"] = XLSX.utils.json_to_sheet(
            b2bDataList
          );
        }
        if (
          pData_Invoice.b2cl &&
          pData_Invoice.b2cl !== undefined &&
          pData_Invoice.b2cl !== null
        ) {
          wb_new.Sheets["B2CL - 5A, 5B"] = XLSX.utils.json_to_sheet(
            b2clDataList
          );
        }
        if (
          pData_Invoice.b2cs &&
          pData_Invoice.b2cs !== undefined &&
          pData_Invoice.b2cs !== null
        ) {
          wb_new.Sheets["B2CS - 7"] = XLSX.utils.json_to_sheet(b2csDataList);
        }
        if (
          pData_Invoice.cdnr &&
          pData_Invoice.cdnr !== undefined &&
          pData_Invoice.cdnr !== null
        ) {
          wb_new.Sheets["CDNR - 9B"] = XLSX.utils.json_to_sheet(cdnrDataList);
        }
        if (
          pData_Invoice.cdnur &&
          pData_Invoice.cdnur !== undefined &&
          pData_Invoice.cdnur !== null
        ) {
          wb_new.Sheets["CDNUR - 9B"] = XLSX.utils.json_to_sheet(cdnurDataList);
        }
        if (
          pData_Invoice.exp &&
          pData_Invoice.exp !== undefined &&
          pData_Invoice.exp !== null
        ) {
          wb_new.Sheets["EXP - 6A"] = XLSX.utils.json_to_sheet(exportsDataList);
        }
        if (
          pData_Invoice.at &&
          pData_Invoice.at !== undefined &&
          pData_Invoice.at !== null
        ) {
          wb_new.Sheets["AT - 11A(1), 11A(2)"] = XLSX.utils.json_to_sheet(
            atDataList
          );
        }
        if (
          pData_Invoice.txpd &&
          pData_Invoice.txpd !== undefined &&
          pData_Invoice.txpd !== null
        ) {
          wb_new.Sheets["ATADJ - 11B(1), 11B(2)"] = XLSX.utils.json_to_sheet(
            atadjDataList
          );
        }
        if (
          pData_Invoice.b2ba &&
          pData_Invoice.b2ba !== undefined &&
          pData_Invoice.b2ba !== null
        ) {
          wb_new.Sheets["B2BA - 9A"] = XLSX.utils.json_to_sheet(b2baDataList);
        }
        if (
          pData_Invoice.b2cla &&
          pData_Invoice.b2cla !== undefined &&
          pData_Invoice.b2cla !== null
        ) {
          wb_new.Sheets["B2CLA - 9A"] = XLSX.utils.json_to_sheet(b2claDataList);
        }
        if (
          pData_Invoice.b2csa &&
          pData_Invoice.b2csa !== undefined &&
          pData_Invoice.b2csa !== null
        ) {
          wb_new.Sheets["B2CSA - 10"] = XLSX.utils.json_to_sheet(b2csaDataList);
        }
        if (
          pData_Invoice.cdnra &&
          pData_Invoice.cdnra !== undefined &&
          pData_Invoice.cdnra !== null
        ) {
          wb_new.Sheets["CDNRA - 9C"] = XLSX.utils.json_to_sheet(cdnraDataList);
        }
        if (
          pData_Invoice.cdnura &&
          pData_Invoice.cdnura !== undefined &&
          pData_Invoice.cdnura !== null
        ) {
          wb_new.Sheets["CDNURA - 9C"] = XLSX.utils.json_to_sheet(
            cdnuraDataList
          );
        }
        if (
          pData_Invoice.expa &&
          pData_Invoice.expa !== undefined &&
          pData_Invoice.expa !== null
        ) {
          wb_new.Sheets["EXPA - 9A"] = XLSX.utils.json_to_sheet(
            exportsaDataList
          );
        }
        if (
          pData_Invoice.ata &&
          pData_Invoice.ata !== undefined &&
          pData_Invoice.ata !== null
        ) {
          wb_new.Sheets["ATA - 11(2)"] = XLSX.utils.json_to_sheet(ataDataList);
        }
        if (
          pData_Invoice.txpda &&
          pData_Invoice.txpda !== undefined &&
          pData_Invoice.txpda !== null
        ) {
          wb_new.Sheets["ATADJA - 11(2)"] = XLSX.utils.json_to_sheet(
            atadjaDataList
          );
        }
        if (
          pData_Invoice.hsn &&
          pData_Invoice.hsn !== undefined &&
          pData_Invoice.hsn !== null
        ) {
          wb_new.Sheets["HSN - 12"] = XLSX.utils.json_to_sheet(hsnDataList);
        }
        if (
          pData_Invoice.nil &&
          pData_Invoice.nil !== undefined &&
          pData_Invoice.nil !== null
        ) {
          wb_new.Sheets["EXEMP - 8A, 8B, 8C, 8D"] = XLSX.utils.json_to_sheet(
            exempDataList
          );
        }
        if (
          pData_Invoice.doc_issue &&
          pData_Invoice.doc_issue !== undefined &&
          pData_Invoice.doc_issue !== null
        ) {
          wb_new.Sheets["DOC - 13"] = XLSX.utils.json_to_sheet(docDataList);
        }

        var wbout = XLSX.write(wb_new, {
          bookType: "xlsx",
          bookSST: true,
          type: "binary",
        });
        var btn = document.getElementById("gstr1_error_confirm_download");
        if (type === "GSTR1_SAVE") {
          btn.onclick = function() {
            var cur_dt = new Date();
            const excelOutput = fileSaver.saveAs(
              blob.createBlob([s2ab(wbout)], {
                type: "application/octet-stream",
              }),
              "GSTR1 Save Error Report - " + cur_dt + ".xlsx"
            );
          };
        } else {
          var cur_dt = new Date();
          const excelOutput = fileSaver.saveAs(
            blob.createBlob([s2ab(wbout)], {
              type: "application/octet-stream",
            }),
            "GSTR1 Save Error Report - " + cur_dt + ".xlsx"
          );
        }

        return pData;
      });
  },

  saveGSTR2: ({ companyDetails, gspDetails, period, body }) => {
    return fetch(BASE_URL + `/gsp/gstr2-retsave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },

  submitGSTR1: ({ companyDetails, gspDetails, period, body }) => {
    console.log(companyDetails);
    console.log(gspDetails);
    console.log(period);
    console.log(body);
    return fetch(BASE_URL + `/gsp/gstr1-retsubmit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
    })
      .then((res) => res.json())
      .then(function(pData_Invoice) {
        console.log(pData_Invoice);
        return pData_Invoice;
      });
  },
  saveGSTR3B: ({ companyDetails, gspDetails, period, body }) => {
    console.log(companyDetails);
    console.log(gspDetails);
    console.log(period);
    console.log(body);
    return fetch(BASE_URL + `/gsp/gstr3b-retsave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  getCompanyName: (companyGSTN, flag) => {
    return fetch(BASE_URL + `/gsp/search`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        gstin: companyGSTN,
        flag: flag,
      },
    })
      .then((res) => res.json())
      .then(function(pData) {
        // console.log(pData);
        return pData;
      });
  },
  getListOfCompanyNames: (gstinList, companyId) => {
    console.log(companyId);
    return fetch(BASE_URL + `/gsp/get-company-name`, {
      method: "POST",
      body: JSON.stringify(gstinList),
      headers: {
        "Content-Type": "application/json",
        companyId: companyId,
      },
    })
      .then((res) => res.json())
      .then(function(pData) {
        // console.log(pData);
        return pData;
      });
  },
  generateLedgerBAL: ({ companyDetails, gspDetails, period, year, body }) => {
    console.log(companyDetails);
    console.log(gspDetails);
    console.log(period);
    console.log(body);
    return fetch(BASE_URL + `/gsp/ledger-bal`, {
      method: "POST",
      // headers: {
      //     'Content-Type': 'application/json',
      //     'period': period,
      //     ...gspDetails,
      //     ...companyDetails
      // },
      headers: {
        "Content-Type": "application/json",
        period: period,
        year: year,
        username: companyDetails.gstnUserName,
        state_cd: companyDetails.state,
        otp: gspDetails.otp,
        gsptoken: gspDetails.gspToken,
        gstin: companyDetails.gstin,
      },
      // body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  getGSTR9AutoCalculatedValues: ({
    companyDetails,
    gspDetails,
    period,
    year,
    body,
  }) => {
    return fetch(BASE_URL + `/gsp/gstr9-calrcds`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        period: period,
        year: year,
        username: companyDetails.gstnUserName,
        state_cd: companyDetails.state,
        otp: gspDetails.otp,
        gsptoken: gspDetails.gspToken,
        gstin: companyDetails.gstin,
      },
    }).then((res) => res.json());
  },
  getGSTR9SavedValues: ({ companyDetails, gspDetails, period, year, body }) => {
    return fetch(BASE_URL + `/gsp/gstr9-records`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        period: period,
        year: year,
        username: companyDetails.gstnUserName,
        state_cd: companyDetails.state,
        otp: gspDetails.otp,
        gsptoken: gspDetails.gspToken,
        gstin: companyDetails.gstin,
      },
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  saveGSTR9: ({ companyDetails, gspDetails, period, year, body }) => {
    return fetch(BASE_URL + `/gsp/gstr9-retsave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        year: year,
        ...gspDetails,
        ...companyDetails,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  generateLedgerTransactions: (
    { companyDetails, gspDetails, period, body },
    action,
    fromDate,
    toDate
  ) => {
    console.log(companyDetails);
    console.log(gspDetails);
    console.log(period);
    console.log(body);
    return fetch(BASE_URL + `/gsp/ledger-trans`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        username: companyDetails.gstnUserName,
        state_cd: companyDetails.state,
        otp: gspDetails.otp,
        gsptoken: gspDetails.gspToken,
        gstin: companyDetails.gstin,
        fromDate: fromDate,
        toDate: toDate,
        action: action,
      },
      // body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  retfileStatus: (gstin, period) => {
    console.log(gstin);
    console.log(period);

    return fetch(BASE_URL + `/gsp/return_track`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        fy: period,
        gstin: gstin,
      },
    })
      .then((res) => res.json())
      .then(function(pData) {
        console.log(pData);
        return pData;
      });
  },
  //error msg in catch block is not appearing
  submitNewFormsProfile: ({ companyDetails, gspDetails, period, body }) => {
    return fetch(BASE_URL + `/gsp/nf-profile-save-answer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(function(pData_Invoice) {
        console.log(pData_Invoice);
        return pData_Invoice;
      })
      .catch((excep) => message.failure("error while saving profile"));
  },
  getNewFormsProfile: async ({ companyDetails, gspDetails, period }) => {
    return fetch(BASE_URL + `/gsp/nf-profile-get-answer`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        period: period,
        ...gspDetails,
        ...companyDetails,
      },
    }).then((res) => res.json());
  },
};

export default gspAuth;
