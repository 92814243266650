import React from 'react'
import GSTR9Pt5Sec10 from './GSTR9Pt5Sec10'
import GSTR9Pt5Sec14 from './GSTR9Pt5Sec14'
const GSTR9Pt5 = props => {
    return (
      <div>
        {props.pt5sec10.dataSource && props.pt5sec10.dataSource.length > 0 ? (<GSTR9Pt5Sec10 {...props}></GSTR9Pt5Sec10>) : null}
        {props.pt5sec14.dataSource && props.pt5sec14.dataSource.length > 0 ? (<GSTR9Pt5Sec14 {...props}></GSTR9Pt5Sec14>) : null}
      </div>
    )
  }

  export default GSTR9Pt5