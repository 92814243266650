import React from 'react'
import glamorous from 'glamorous'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Tooltip from 'antd/lib/tooltip'
import get from 'lodash/fp/get'
import reduce from 'lodash/fp/reduce'
import flow from 'lodash/fp/flow'
import { formatNumber } from "../../shared/constants";
import flatMap from 'lodash/fp/flatMap'
import { PR_CDNUR_PurchaseQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PrCDNURItems from './PR_CDNUR_Items'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import moment from 'moment'
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const row_text_style = { border: 'solid 1px #e8e8e8', margin: '1px'};
const { ColumnGroup, Column } = Table
const SRow = glamorous(Row)({ margin: '15px 0',})

const PurchaseRegister = props => {
  function compareByAlph(a, b) {
    if (a < b) {
      return -1;
    } if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }
  return (
    <Root>
      <Content>
        <SRow gutter={24} style={row_style}>
          <Col span="6">
            Total CDNUR Value:{' '}
            {props.formatNumber(reduce((curr, next) => {
              curr = parseFloat(curr) + parseFloat(next.cdnr_value, 2);
              var temp = parseFloat(curr);
              temp = parseFloat(temp).toFixed(2);
              return(temp)}, 0)(props.pr_cdnur_masters))}
          </Col>
          <Col span="6">
            Total Taxable Value:{' '}
            {props.formatNumber(flow(
              flatMap(get('pr_cdnur_items.edges')),
              reduce((curr, next) => {
                curr = parseFloat(curr) + parseFloat(next.taxable_value, 2);
                var temp = parseFloat(curr);
                temp = parseFloat(temp).toFixed(2);
                return(temp)}, 0))(props.pr_cdnur_masters))}
          </Col>
          <Col span="12">
          </Col>
        </SRow>
        <Table dataSource={props.pr_cdnur_masters} bordered rowKey="id" loading={props.loading} title={() => 'CDNUR Purchases'}pagination={{ className: 'my-pagination' }} >
          <ColumnGroup title=" Invoice/Advance Payment Details ">
            <Column title="Type" dataIndex="invoice_type" key="invoice_type" />
            <Column title="No." dataIndex="invoice_number" key="invoice_number" sorter={(a, b) => compareByAlph(a.invoice_number, b.invoice_number)} />
            <Column title="Date" dataIndex="invoice_date" key="invoice_date"  render={text => moment(text, "DD-MM-YYYY").format('DD/MM/YYYY')}/>
          </ColumnGroup>
          <ColumnGroup title="CDNUR Details">
            <Column title="No." dataIndex="cdnr_number" key="cdnr_number" />
            <Column title="Date" dataIndex="cdnr_date" key="cdnr_date" render={text => moment(text, "DD-MM-YYYY").format('DD/MM/YYYY')} />
            <Column title="Type" dataIndex="cdnr_type" key="cdnr_type"  />
            <Column title="Value" dataIndex="cdnr_value" key="cdnr_value" render={val => <p style={{ width: '115px'}}>{props.formatNumber(val === null ? 0 : val)}</p>} />
          </ColumnGroup>
          <Column title="Place Of Supply" dataIndex="pos_id" key="pos_id" />
          <Column title="Supply Type" dataIndex="supply_type" key="supply_type" />
          <Column title="Pre-GST" dataIndex="pre_gst" key="pre_gst" />
          <Column title="Items" key="pr_cdnur_items"
            render={(text, record) => {
              return (
                <Tooltip title="View Item(s)">
                  <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                    {record.pr_cdnur_items.pageInfo.total} Item(s)

                  </Button>
                  <PrCDNURItems isVisible={props.visibleModal === record.id} onCancel={props.hideModal} pr_cdnur_items={record.pr_cdnur_items.edges}/>
                </Tooltip>
              )
            }}
          />
        </Table>
        <SRow gutter={24} style={row_text_style}>
          <Col span="24">
            <h3 style={{padding: '10px'}}>Document Types : C-Credit, D-Debit, R-Refund</h3>
          </Col>
        </SRow>
        <SRow gutter={24} style={row_text_style}>
          <Col span="24">
            <h3 style={{padding: '10px'}}>Invoice Types : B2BUR-Business to Business Unregistered, IMPS-import of services</h3>
          </Col>
        </SRow>
      </Content>
    </Root>
  )
}

class PurchaseRegisterContainer extends React.Component
{
  constructor(props)
  {
    super(props)
    console.log(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year =  curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n+1,
      finYear: year,
      curFinMonth: n+1,
      curFinYear: year,
    }
    this.props.data.refetch()
  }

  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }
  changeSelectedFinancialMonth = name => {
    this.props.changeSelectedFinancialMonth(name)
  }
  render() {
    var tableLoading = false;
    if (this.props.data.networkStatus === 4) { tableLoading = false; }
    else if (this.props.data.networkStatus === 7) { tableLoading = false; }
    else if (this.props.data.networkStatus === 1) { tableLoading = true; }
    return (
      <PurchaseRegister
        pr_cdnur_masters={get('viewer.pr_cdnur_masters.edges')(this.props.data)}
        role={get('company.role', this.props)}
        finMonth={this.state.finMonth}
		    finYear={this.state.finYear}
        loading={tableLoading}
        formatNumber={formatNumber}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
        isSR={this.props.isSR}
      />
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      // isSR: props.isSR,
      type: 'prcdnur',
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}
const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}
export default compose(
  connect(mapStateToProps),
  graphql(gql(PR_CDNUR_PurchaseQuery), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(PurchaseRegisterContainer)
