/**
 * @flow
 */
import React from 'react'
import Glamorous from 'glamorous'
import get from 'lodash/fp/get'
import Table from 'antd/lib/table'
import { withState } from 'recompose'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import moment from 'moment'
import type Moment from 'moment'

import {
  Root,
  MarginedRow as Row,
  ContentWrapper,
} from '../../shared/styles'
import CompanyDetails from './CompanyDetails'
import RetStatus from './RetFilingStatus'

import { RETURN_TYPES as RT } from '../../shared/constants'
const { Column } = Table
const SH2 = Glamorous.h2({
  textAlign: 'center',
  marginBottom: 10,
})

const Content = Glamorous(ContentWrapper)({
  margin: 8,
})

type props = {
  demoDate: Moment,
  setDemoDate: Function,
}
const Dashboard = (props: props) => {
  const { demoDate } = props
  const date = moment(demoDate).date()
  let type = RT.GSTR1
  if (date > 10 && date <= 15) type = RT.GSTR2
  else if (date > 15 && date <= 17) type = RT.GSTR1A
  else if (date > 17 && date <= 20) type = RT.GSTR3

  return (
    <div style={{backgroundColor:'#fff'}}>
      <CompanyDetails company={props.company || {}} />
      <RetStatus />
    </div>
  )
}

const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
  }
}

export default compose(
  connect(mapStateToProps),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
  withState('demoDate', 'setDemoDate', moment())
)(Dashboard)

// export { GSTRSteps }
