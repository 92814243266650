import React from 'react'
import glamorous from 'glamorous'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Tooltip from 'antd/lib/tooltip'
import get from 'lodash/fp/get'
import reduce from 'lodash/fp/reduce'
import flow from 'lodash/fp/flow'
import { formatNumber } from "../../shared/constants";
import flatMap from 'lodash/fp/flatMap'
import { PR_AT_PurchaseQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import PrATItems from './PR_AT_Items'
import { Root, ContentWrapper as Content } from '../../shared/styles'
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const { Column } = Table
const SRow = glamorous(Row)({ margin: '15px 0',})

const PurchaseRegister = props => {
  return (
    <Root>
      <Content>
        <SRow gutter={24} style={row_style}>
        <Col span="24">
          Total Advance Received:{' '}
            {props.formatNumber(flow(
              flatMap(get('pr_at_items.edges')),
              reduce((curr, next) => {
                curr = parseFloat(curr) + parseFloat(next.paid_amt, 2);
                var temp = parseFloat(curr);
                temp = parseFloat(temp).toFixed(2);
                return(temp)}, 0))(props.pr_at_masters))}
          </Col>
        </SRow>
        <Table dataSource={props.pr_at_masters} bordered rowKey="id" loading={props.loading} title={() => 'Advance Paid on Purchases'} pagination={{ className: 'my-pagination' }}>
          <Column title="Place Of Supply" dataIndex="pos_id" key="pos_id" />
          <Column title="Supply Type" dataIndex="supply_type" key="supply_type" />
          <Column title="Items" key="pr_at_items"
            render={(text, record) => {
              return (
                <Tooltip title="View Item(s)">
                  <Button icon="switcher" type="primary" onClick={() => props.showModal(record.id)}>
                    {record.pr_at_items.pageInfo.total} Item(s)

                  </Button>
                  <PrATItems isVisible={props.visibleModal === record.id} onCancel={props.hideModal} pr_at_items={record.pr_at_items.edges} />
                </Tooltip>
              )
            }}
          />
        </Table>
      </Content>
    </Root>
  )
}

class PurchaseRegisterContainer extends React.Component {
  constructor(props) {
    super(props)
    console.log(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year =  curyear + '-' + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n+1,
      finYear: year,
      curFinMonth: n+1,
      curFinYear: year,
    }
    this.props.data.refetch()
  }
  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }
  render() {
    var tableLoading = false;
    if (this.props.data.networkStatus === 4) { tableLoading = false; }
    else if (this.props.data.networkStatus === 7) { tableLoading = false; }
    else if (this.props.data.networkStatus === 1) { tableLoading = true; }
    return (
      <PurchaseRegister
        pr_at_masters={get('viewer.pr_at_masters.edges')(this.props.data)}
        role={get('company.role', this.props)}
        finMonth={this.state.finMonth}
		    finYear={this.state.finYear}
        loading={tableLoading}
        formatNumber={formatNumber}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
        isSR={this.props.isSR}
      />
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      // isSR: props.isSR,
      type: 'prat',
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}
const companyQueryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ data }) => {
    return {
      company: get('viewer.company', data),
      isEdit: true,
    }
  },
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  }
}
export default compose(
  connect(mapStateToProps),
  graphql(gql(PR_AT_PurchaseQuery), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(PurchaseRegisterContainer)
