import React from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'

const FormItem = Form.Item;

const OTPForm = props => 
    <Modal visible={props.visible} title="Enter the received OTP" okText="Submit" onCancel={props.onCancel} onOk={props.onSubmit}>
	<Form layout="vertical">
		<FormItem label="OTP">
              		{props.getFieldDecorator('otp', {
		                rules: [{ required: true, message: 'Please input the received OTP!' }],
              		})(
			<Input />
			)}
		</FormItem>
	</Form>
</Modal>


class OTPFormContainer extends React.Component {
    render() {
        return (
          <OTPForm
            visible={this.props.visible}
            onSubmit={this.props.onSubmit}
            onCancel={this.props.onCancel}
            getFieldDecorator={this.props.form.getFieldDecorator}
          />
        )
      }
}

export default Form.create()(OTPFormContainer)