import React, { Component } from "react";
import Notification from "antd/lib/notification";
import Progress from "antd/lib/progress";
import Button from "antd/lib/button";
import Tag from "antd/lib/tag";
const UploadProgress = {
  show(key, message, progress, btnClick, errors, total) {
    var but1 = null;
    if(btnClick) {
      but1 = (
        <Button
          type="primary"
          size="small"
          onClick={btnClick}
        >
          Download Error Report
        </Button>
      )
    }
    Notification.open({
      message,
      key,
      btn: but1 || undefined,
      description: total ? (<><Progress percent={progress} />
        <span style={{lineHeight:3}}><span style={{fontWeight:"bold"}}>{errors}</span> Invoices Failed out of <span style={{fontWeight:"bold"}}>{total}</span> Invoices</span></>) : <Progress percent={progress} />,
      duration: 0,
    });
  },
};

export default UploadProgress;
