import React from "react";
import { Table, InputNumber, Input, Tag } from "antd";
const GSTR9Pt3Sec6 = props => {
  const columns = [
    {
      title: "Pt. III",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (text, record, index) => {
        if(index === 1 || index === 4 || index === 7){
          return {
            children: text,
            props: {
              rowSpan: 3
            }
          }
        }else if(index == 2 || index === 3 || index == 5 || index === 6 || index == 8 || index === 9 || index === 11){
          return {
            children: text,
            props: {
              rowSpan:0
            }
          }
        }else if(index === 10){
          return {
            children: text,
            props: {
              rowSpan: 2
            }
          }
        }else {
          return text
        }
      }
    },
    {
      title:
        "6. Details of ITC availed as declared in returns filed during the financial year",
      children: [
        {
          title: "Description",
          dataIndex: "det",
          key: "det",
          width: "20%",
          render: (text, record, index) => {
            if(index === 1 || index === 4 || index === 7){
              return {
                children: text,
                props: {
                  rowSpan: 3
                }
              };
            }else if(index == 2 || index === 3 || index == 5 || index === 6 || index == 8 || index === 9 || index === 11){
              return {
                children: null,
                props: {
                  rowSpan:0,
                  colSpan:0
                }
              }
            }else if(index === 10){
              return {
                children: text,
                props: {
                  rowSpan: 2
                }
              }
            }else {
              return {
                children: text,
                props: {
                  colSpan:2
                }
              }
            }
          }
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: "15%",
          render: (text,record, index) => {
            if(index > 0 && index < 12) {
              return text
            }else {
              return {
                children: null,
                props: {
                  colSpan:0
                }
              }
            }
          }
        },
        {
          title: "Integrated Tax (₹)",
          dataIndex: "iamt",
          key: "iamt",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "16" ||
            record.key === "17" ||
            record.key === "21" ||
            record.key === "22" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec6-${record.key}-iamt-tbl6`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "Central Tax (₹)",
          dataIndex: "camt",
          key: "camt",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "16" ||
            record.key === "17" ||
            record.key === "21" ||
            record.key === "22" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec6-${record.key}-camt-tbl6`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "State/UT Tax (₹)",
          dataIndex: "samt",
          key: "samt",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "16" ||
            record.key === "17" ||
            record.key === "21" ||
            record.key === "22" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec6-${record.key}-samt-tbl6`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "CESS (₹)",
          dataIndex: "cess",
          key: "cess",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
              record.key === "16" ||
              record.key === "17" ||
              record.key === "21" ||
              record.key === "22" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec6-${record.key}-cess-tbl6`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        }
      ]
    }
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "Details of ITC as declared in returns filed during the financial year"
      }
      size="small"
      dataSource={props.pt3sec6.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt3Sec6;
