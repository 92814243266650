import React from 'react'
import glamorous from 'glamorous'
import Tooltip from 'antd/lib/tooltip'
import Button from 'antd/lib/button'
import { Table, Radio, Popconfirm, Input } from 'antd';
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import pick from 'lodash/fp/pick'
import { V2_SR_TABLEWISE_SUMMARY_QUERY, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import DropZone from 'react-dropzone';
import { Root, ContentWrapper as Content } from '../../shared/styles'
import SALES from '../../services/Anx1SalesService'
import { BASE_URL } from '../../shared/constants'
import { gspActions } from "../../gspRedux";
import toString from "lodash/fp/toString";
import Column from 'antd/lib/table/Column';
import ANX1B2BA from './ANX1B2BA';
import ANX1B2C from './ANX1B2C';
import ANX1B2B from './ANX1B2B';
import ANX1EXP from './ANX1EXP';
import ANX1SEZ from './ANX1SEZ';
import ANX1DE from './ANX1DE';
import ANX1REV from './ANX1REV';
import ANX1IMPS from './ANX1IMPS';
import ANX1IMPG from './ANX1IMPG';
import ANX1IMPGSEZ from './ANX1IMPGSEZ';
import ANX1ECOM from './ANX1ECOM';
import ANX1MissingDocs from './ANX1MissingDocs'
import ANX1DEA from './ANX1DEA'
import ANX1SEZWPA from './ANX1SEZWPA'
import ANX1SEZWOPA from './ANX1SEZWOPA'
var comp_id = '...'
const SRow = glamorous(Row)({ margin: '15px 0', })
const confirm = Modal.confirm;
const errorModal = Modal.error;

const Anx1Register = props => {
    return (
        <Root>
            <Content>
                <SRow>
                    <span><Button size="small" className="{ant-btn-primary}" id="sales_download" ></Button>
                    </span>
                    <span> <Button id="sales_confirm_upload" onClick={props.showUploadConfirm}></Button></span>
                    {!props.externalInterfaceInfo && (
                        <Col span="5">
                            <DropZone onDrop={props.onDropAnx1} multiple={false} disabled={props.role === 'reviewer'}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} style={{ marginRight: "65em" }}>
                                        <input {...getInputProps()} />
                                        <Tooltip title="Upload ANX1" overlay="">
                                            <Button size="large" icon="cloud-upload" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} disabled={props.role === 'reviewer'}>
                                                Upload ANX1
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}
                            </DropZone>
                        </Col>)}
                    <Col span="5">
                        <Tooltip title="Synchronize With GSTN" overlay="">
                            <Button size="large" icon="sync" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} onClick={props.syncAnx1}>
                                Sync With GSTN
                                </Button>
                        </Tooltip>
                    </Col>
                    <Col span="5">
                        <Tooltip title="Clear all Sales for selected Tax Period" overlay="">
                            <Popconfirm placement="bottomRight" title="Clicking this button will reset all Sales for selected Tax Period!" onVisibleChange={props.popupConfirmResetVisibleChange} visible={props.popupConfirmReset} onConfirm={props.resetSales} okText="Yes" cancelText="No">
                                <Button size="large" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#a01208de', color: 'white' }} icon="safety" disabled={props.role === 'reviewer'} >Reset</Button>
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </SRow>
                <Row>
                    <Table pagination={false} dataSource={props.v2_sr_tablewise_summary ? get('viewer.v2_sr_tablewise_summary.edges')(props.v2_sr_tablewise_summary) : []} bordered title={() => 'Table Wise Summary'}>
                        <Column title="Category" dataIndex='category' key="category"
                            render={(text, record) => {
                                if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (record.category)
                                }
                                else {
                                    return (<strong>{record.category}</strong>)
                                }
                            }}

                        ></Column>
                        <Column title="Number of Records" dataIndex='records'
                            key="records" render={(text, record) => {
                                return ((record.category != "Total Outward Supplies" && record.category != "Total Net Liability") ?
                                    <Button size="large" id={record.category} style={{ width: "3em", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}
                                        onClick={props.showModal}
                                    >
                                        {text}
                                    </Button> :
                                    <strong>{text}</strong>
                                )
                            }}
                        ></Column>
                        <Column title="Invoice Value" dataIndex='invoiceValue'
                            key="invoiceValue" render={(text, record) => {
                                if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (<span>{text}</span>)
                                }
                                else {
                                    return (<strong>{text}</strong>)
                                }

                            }}
                        ></Column>
                        <Column title="Taxable Value" dataIndex='taxableValue'
                            key="taxableVAlue" render={(text, record) => {
                                if (record.category != "ECOM" && record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (<div>{text}</div>)
                                }
                                else if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return {
                                        props: {
                                            style: { background: "#d9d9d9" }
                                        },
                                        children: <span></span>
                                    }
                                }
                                else {
                                    return (<strong>{text}</strong>)
                                }
                            }}
                        ></Column>
                        <Column title="Integrated Tax" dataIndex="igst"
                            key="igst" render={(text, record) => {
                                if (record.category != "SEZWOP-A" && record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (<div>{text}</div>)
                                }
                                else if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return {
                                        props: {
                                            style: { background: "#d9d9d9" }
                                        },
                                        children: (<span></span>)
                                    }
                                }
                                else {
                                    return (<strong>{text}</strong>)
                                }
                            }}
                        ></Column>
                        <Column title="Central Tax" dataIndex="cgst"
                            key="cgst" render={(text, record) => {
                                if (record.category != "EXP" && record.category != "SEZ" && record.category != "IMPS" && record.category != "IMPG" && record.category != "IMPGSEZ" && record.category != "SEZWP-A" && record.category != "SEZWOP-A" && record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (<div>{text}</div>)
                                }
                                else if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return {
                                        props: {
                                            style: { background: "#d9d9d9" }
                                        },
                                        children: (<span></span>)
                                    }
                                }
                                else {
                                    return (<strong>{text}</strong>)
                                }
                            }
                            }
                        >
                        </Column>
                        <Column title="State Tax" dataIndex="sgst"
                            key="sgst" render={(text, record) => {
                                if (record.category != "EXP" && record.category != "SEZ" && record.category != "IMPS" && record.category != "IMPG" && record.category != "IMPGSEZ" && record.category != "SEZWP-A" && record.category != "SEZWOP-A" && record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (<div>{text}</div>)
                                }
                                else if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return {
                                        props: {
                                            style: { background: "#d9d9d9" }
                                        },
                                        children: (<span></span>)
                                    }
                                }
                                else {
                                    return (<strong>{text}</strong>)
                                }
                            }
                            }
                        ></Column>
                        <Column title="Cess" dataIndex="cess"
                            key="cess" render={(text, record) => {
                                if (record.category != "SEZWOP-A" && record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return (<div>{text}</div>)
                                }
                                else if (record.category != "Total Outward Supplies" && record.category != "Total Net Liability") {
                                    return {
                                        props: {
                                            style: { background: "#d9d9d9" }
                                        },
                                        children: (<span></span>)
                                    }
                                }
                                else {
                                    return (<strong>{text}</strong>)
                                }
                            }}
                        ></Column>
                    </Table>
                </Row>
                <Modal
                    centered
                    width={"90%"}
                    visible={props.summary_modal_visible}
                    onCancel={props.handleCancel}
                    footer={<Button onClick={props.handleCancel}
                        style={{ width: "5em", fontSize: '14px', padding: '0 10px', backgroundColor: '#108ee9', color: 'white' }} >
                        OK
                        </Button>}
                >
                    {props.summary_modal_source === "b2c" && <ANX1B2C />}
                    {props.summary_modal_source === "b2b" && <ANX1B2B />}
                    {props.summary_modal_source === "exp" && <ANX1EXP />}
                    {props.summary_modal_source === "sez" && <ANX1SEZ />}
                    {props.summary_modal_source === "de" && <ANX1DE />}
                    {props.summary_modal_source === "rcm" && <ANX1REV />}
                    {props.summary_modal_source === "imps" && <ANX1IMPS />}
                    {props.summary_modal_source === "impg" && <ANX1IMPG />}
                    {props.summary_modal_source === "impgsez" && <ANX1IMPGSEZ />}
                    {props.summary_modal_source === "ecom" && <ANX1ECOM />}
                    {props.summary_modal_source === "missing docs" && <ANX1MissingDocs />}
                    {props.summary_modal_source === "b2b-a" && <ANX1B2BA />}
                    {props.summary_modal_source === "de-a" && <ANX1DEA />}
                    {props.summary_modal_source === "sezwp-a" && <ANX1SEZWPA />}
                    {props.summary_modal_source === "sezwop-a" && <ANX1SEZWOPA />}
                </Modal>
            </Content>
        </Root >
    )
}

class Anx1DashboardContainer extends React.Component {

    constructor(props) {
        super(props);
        var d = new Date();
        var n = d.getMonth();
        var y = d.getYear();
        var curyear = 1900 + y;
        var nextyear = curyear + 1 - 2000;
        var year = curyear + '-' + nextyear;
        if (props.gspDetails !== undefined && props.gspDetails !== null) {
            this.gsp = {
                access_token: props.gspDetails.gspToken,
                otp: props.gspDetails.otp,
                response: null,
                response_delete: null,
            };
        } else {
            this.gsp = {};
        }

        message.config({
            maxCount: 1,
        });
        this.state = {
            finMonth: props.finMonth,
            finYear: props.finYear,
            curFinMonth: n + 1,
            curFinYear: year,
            companyId: props.selectedCompany,
            resetLoading: false,
            popupConfirmReset: false,
            visible: false,
            period: null,
            selectedCategory: "tw",
            summary_modal_source: null,
            summary_modal_visible: false
        }
        comp_id = props.selectedCompany;
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.syncAnx1 = this.syncAnx1.bind(this);
    }

    otherGSTNInformation = companyDetails => {
        const state_cd = companyDetails["gstin"].substr(0, 2);
        companyDetails["state"] = state_cd;
        return companyDetails;
    };

    showModal = (e) => {
        this.setState({ "summary_modal_source": e.target.id.toLowerCase(), "summary_modal_visible": true })
    };

    handleCancel = e => {
        this.setState({
            summary_modal_visible: false,
        });
    }
    syncAnx1 = async () => {
        var el = document.body,
            elChild = document.createElement('div');
        elChild.className = "root_div";
        elChild.innerHTML = '<div class="custom_loading loader"></div>';
        el.insertBefore(elChild, el.firstChild);
        document.getElementById('root').classList.add("blur");
        var year_trim = this.props.finYear.split("-");
        var fy = "";
        if (this.props.finMonth < 4) {
            fy = "20" + year_trim[1];
        } else {
            fy = year_trim[0];
        }
        var downloaddate = this.props.finMonth + "/" + this.props.finYear;
        var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
        const companyDetails = flow(
            pick(["gstin", "id", "gstnUserName"]),
            this.otherGSTNInformation
        )(this.props.company);
        const postData = {
            company_id: this.props.selectedCompany,
            finYear: this.state.finYear,
            finMonth: this.state.finMonth.toString(),
            period,
            gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp
            },
            companyDetails
        }
        const anx1Data = await SALES.syncAnx1(postData);
        document.body.getElementsByClassName("root_div")[0].remove();
        document.getElementById('root').classList.remove('blur');

        if (anx1Data.retstatus && anx1Data.retstatus.status_cd === "P") {
            message.success('Successfully Synchronized With GSTN', 3)
            this.props.v2_sr_tablewise_summary.refetch();
        }
        else if (anx1Data.retstatus && anx1Data.retstatus.status_cd === "PE") {
            this.showConfirm(anx1Data);
            this.props.v2_sr_tablewise_summary.refetch();
        }
        else if (anx1Data.retstatus && anx1Data.retstatus.status_cd === "ER") {
            errorModal({
                title: 'Unable to process your request, Please try again later.',
            });
        }
    }
    showConfirm = (data) => {
        confirm({
            title: 'Errors Found While Syncing Anx1 with GSTN',
            iconType: 'printer',
            width: 866,
            content: (<div id="dialogue_append_sync">
                <div style={{ "width": "100%" }}>
                    <br />
                    <p>
                        <span><b style={{ "fontSize": "12px", color: "#0cde26" }}>Partially saved!</b></span>
                    </p>
                    <br />
                    <p>Click on "OK" to Download the Error Excel. Please check and correct the Errors!</p>
                </div>
            </div>),
            onOk() {
                data.downloadReport()
            }
        });
    }

    onCategoryChange = (e) => {
        this.setState({ selectedCategory: e.target.value })
    }

    showUploadConfirm() {
        confirm({
            title: 'Errors Found While Uploading Anx1',
            iconType: 'printer',
            width: 866,
            content: (<div id="dialogue_append1"></div>),
            onOk() {
                document.getElementById("sales_download").click();
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('Oops errors!'));
            }
        });
    }

    componentDidUpdate = () => {
        if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
            this.gsp.access_token = this.props.gspDetails.gspToken;
            this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
        }
    };

    onRadioChange(e) {
        this.setState({ selectedOption: e.target.value });
    }

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    resetSales = () => {
        message.loading('Resetting Anx1 Sales...', 0);
        this.setState({ resetLoading: true });
        var downloaddate = this.state.finMonth + '/' + this.state.finYear;
        fetch(`${BASE_URL}/export/resetAnx1Sales?from=sales&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
            {
                method: 'GET',
            })
            .then((result) => {
                this.setState({ resetLoading: false });
                this.props.v2_sr_tablewise_summary.refetch();
                message.success('All Sales have been reset for selected tax period', 3);
            })
    }

    onDropAnx1 = async acceptedFiles => {

        var el = document.body,
            elChild = document.createElement('div');
        elChild.className = "root_div";
        elChild.innerHTML = '<div class="custom_loading loader"></div>';
        el.insertBefore(elChild, el.firstChild);
        document.getElementById('root').classList.add("blur");
        const result = await SALES.upload({
            file: acceptedFiles[0],
            type: 'sranx1',
            tally: 'true',
            companyId: this.props.selectedCompany,
            isSR: this.props.isSR,
            finMonth: this.state.finMonth,
            finYear: this.state.finYear,
        })

        document.body.getElementsByClassName("root_div")[0].remove();
        document.getElementById('root').classList.remove('blur');
        if ((result.error_status !== '') && (result.error_status !== undefined)) {
        }
        if (result.filestatus === true) {
            if (result.flag === true) {
                var dialogue_msg = '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif" / style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + result.total_errors + ' Invoices Failed out of ' + result.total + ' Invoices</b></span>) Anx1 Sales Register partially saved!</h4></p><br><p>Error Excel will be downloaded. Please check and correct the Errors!</p></div>';
                var div = document.getElementById('dialogue_append1');
                if (document.getElementById('dialogue_append1') !== null) {
                    div.innerHTML += dialogue_msg;
                }
            }
            if (result.total_errors > 0) {
                this.props.v2_sr_tablewise_summary.refetch();
            }
            else {
                this.props.v2_sr_tablewise_summary.refetch();
                message.success('(' + result.total + '/' + result.total + ') Sales Invoices uploaded successfully!', 3)
            }
        } else if (result.file === false) {
            message.error('Uploaded file is not in Tally Excel format!', 3)
        } else {
            message.error('Upload File Type is Not Allowed', 3)
        }

    }

    changeSelectedFinancialMonth = name => {
        this.props.changeSelectedFinancialMonth(name)
    }

    popupConfirmResetVisibleChange = e => {
        if (!e) {
            this.setState({ popupConfirmReset: false });
            return;
        }
        if (this.props.company) {
            if (this.props.company.role === "admin") {
                this.setState({ popupConfirmReset: true })
            } else {
                this.setState({ popupConfirmReset: false })
            }
        }
    }

    render() {
        return (
            <div style={{ backgroundColor: '#fff', padding: '5px' }}>
                <Anx1Register
                    v2_sr_tablewise_summary={this.props.v2_sr_tablewise_summary}
                    role={get('company.role', this.props)}
                    finMonth={this.state.finMonth}
                    finYear={this.state.finYear}
                    showUploadConfirm={this.showUploadConfirm}
                    onSyncConfirm={this.onSyncConfirm}
                    isSR={this.props.isSR}
                    onDropAnx1={this.onDropAnx1}
                    resetSales={this.resetSales}
                    resetLoading={this.state.resetLoading}
                    popupConfirmResetVisibleChange={this.popupConfirmResetVisibleChange}
                    popupConfirmReset={this.state.popupConfirmReset}
                    externalInterfaceInfo={get('externalInterfaceInfo', this.props)}
                    selectedCategory={this.state.selectedCategory}
                    onCategoryChange={this.onCategoryChange}
                    syncAnx1={this.syncAnx1}
                    summary_modal_visible={this.state.summary_modal_visible}
                    summary_modal_source={this.state.summary_modal_source}
                    showModal={this.showModal}
                    handleCancel={this.handleCancel}
                />
            </div>
        )
    }
}

const tablewiseConfig = {
    name: "v2_sr_tablewise_summary",
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finmonth: props.finMonth.toString(),
            finyear: props.finYear,
        },
    }),
}
const companyQueryConfig = {
    name: "company",
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finYear: props.finYear,
            finMonth: props.finMonth,
        },
    }),
    props: ({ company }) => {
        return {
            company: get('viewer.company', company),
            gspDetails: get("viewer.gspInfo", company),
            externalInterfaceInfo: get("viewer.externalInterfaceInfo", company)
        }
    },
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany,
        finMonth: state.local.finMonth,
        finYear: state.local.finYear,
        gspDetails: state.gsp
    }
}

export default compose(
    connect(mapStateToProps,
        {
            setGspToken: gspActions.setGspToken,
            setGspOtp: gspActions.setGspOtp,
            setGspDetails: gspActions.setGspDetails
        }),
    graphql(gql(V2_SR_TABLEWISE_SUMMARY_QUERY), tablewiseConfig),
    graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(Anx1DashboardContainer)
