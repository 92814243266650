const find = require("lodash/fp/find");

const ROUTES = {
  LOGIN: "/auth/login",
  FORGOT_PASSWORD: "/auth/forgot_password",
  REGISTER: "/auth/register",
  DASHBOARD: "/secured/dashboard",
  ANALYTICS_SALES: "/secured/analytics/sales",
  ANALYTICS_PURCHASE: "/secured/analytics/purchase",
  COMPANY: "/secured/company",
  COMPANY_ADD: "/secured/company/add",
  COMPANY_EDIT: "/secured/company/:id/edit",
  COMPANY_USERS: "/secured/company/:id/users",
  SUPPLIER: "/secured/configuration/supplier",
  SUPPLIER_ADD: "/secured/configuration/supplier/add",
  CUSTOMER: "/secured/configuration/customer",
  CUSTOMER_ADD: "/secured/configuration/customer/add",
  SALES: "/secured/sales",
  INSIGHTS: "/secured/insights",
  INSIGHTS_ITC: "/secured/insights/itc",
  INSIGHTS_SALES: "/secured/insights/sales",
  ITC: "/secured/itc_corner",
  ITC_2B: "/secured/itc_corner_2b",
  TG_UTILITIES: "/secured/thoughtgst_utilities",
  TG_UTILITIES_LEDGERS: "/secured/thoughtgst_utilities/electronic_ledgers",
  TG_UTILITIES_2A: "/secured/thoughtgst_utilities/2a",
  SUPPLIER_FILING_TRENDS: "/secured/thoughtgst_utilities/return_filing_trends",
  CONTACTS: "/secured/contacts",
  CONTACT_DETAILS: "/secured/contacts/details",
  CONTACT_UPLOAD: "/secured/contacts/:id/add",
  CONTACT_UPDATE: "/secured/contacts/:id/update",
  GSTN_SYNC: "/secured/gstn_sync",
  GSTN_SYNC_FETCH_ALL: "/secured/gstn_sync/fetch_all_gstr1",
  GSTN_SYNC_B2B: "/secured/gstn_sync/b2b",
  GSTN_SYNC_B2CL: "/secured/gstn_sync/b2cl",
  GST_FILINGS: "/secured/thougtgst/gstfilings",
  GST_FILINGS_GSTR1: "/secured/thougtgst/gstfilings/gstr1",
  GST_FILINGS_GSTR2: "/secured/thougtgst/gstfilings/gstr2",
  GST_FILINGS_GSTR3B: "/secured/thougtgst/gstfilings/gstr3b",
  GST_FILINGS_GSTR3B_INPUT: "/secured/thougtgst/gstfilings/gstr3b/enterdata",
  GST_FILINGS_GSTR3B_PAYTAX: "/secured/thougtgst/gstfilings/gstr3b/paytax",
  GST_FILINGS_GSTR9: "/secured/thougtgst/gstfilings/gstr9",
  GST_FILINGS_GSTR9C: "/secured/thougtgst/gstfilings/gstr9c",
  GST_FILINGS_GSTR9_P2: "/secured/thougtgst/gstfilings/gstr9/point_two",
  GST_FILINGS_GSTR9_P3: "/secured/thougtgst/gstfilings/gstr9/point_three",
  GST_FILINGS_GSTR9_P4: "/secured/thougtgst/gstfilings/gstr9/point_four",
  GST_FILINGS_GSTR9_P5: "/secured/thougtgst/gstfilings/gstr9/point_five",
  GST_FILINGS_GSTR9_P6: "/secured/thougtgst/gstfilings/gstr9/point_six",
  SALES_DASHBOARD: "/secured/sales/dashboard",
  ANX2_DASHBOARD: "/secured/v2/purchase/anx2dashboard",
  ANX2_B2B: "/secured/v2/purchase/b2b",
  ANX2_DE: "/secured/v2/purchase/de",
  ANX2_SEZWP: "/secured/v2/purchase/sezwp",
  ANX2_SEZWOP: "/secured/v2/purchase/sezwop",
  ANX1_DASHBOARD: "/secured/v2/sales/anx1dashboard",
  ANX1_B2C: "/secured/v2/sales/b2c",
  ANX1_B2B: "/secured/v2/sales/b2b",
  ANX1_MISSINGDOCS: "/secured/v2/sales/anx1missingdocs",
  ANX1_EXP: "/secured/v2/sales/exp",
  ANX1_SEZ: "/secured/v2/sales/sez",
  ANX1_DE: "/secured/v2/sales/de",
  ANX1_REV: "/secured/v2/sales/rev",
  ANX1_IMPS: "/secured/v2/sales/imps",
  ANX1_IMPG: "/secured/v2/sales/impg",
  ANX1_IMPGSEZ: "/secured/v2/sales/impgsez",
  ANX1_ECOM: "/secured/v2/sales/ecom",
  ANX1_B2BA: "/secured/v2/sales/b2ba",
  ANX1_B2BAO: "/secured/v2/sales/b2bao",
  ANX1_DEA: "/secured/v2/sales/dea",
  ANX1_SEZWPA: "/secured/v2/sales/sezwpa",
  ANX1_SEZWOPA: "/secured/v2/sales/sezwopa",
  ANX1_A_B2CA: "/secured/v2/sales/anx1a-b2ca",
  ANX1_A_EXPWPA: "/secured/v2/sales/anx1a-expwpa",
  ANX1_A_EXPWOPA: "/secured/v2/sales/anx1a-expwopa",
  ANX1_A_REVA: "/secured/v2/sales/anx1a-reva",
  ANX1_A_IMPSA: "/secured/v2/sales/anx1a-impsa",
  ANX1_A_IMPGA: "/secured/v2/sales/anx1a-impga",
  ANX1_A_IMPGSEZA: "/secured/v2/sales/anx1a-impgseza",
  ANX1_A_ECOMA: "/secured/v2/sales/anx1a-ecoma",
  ANX1_A_DASHBOARD: "/secured/v2/sales/anx1a-dashboard",
  ANX1_RET: "/secured/gstfilings/return-01",
  ANX1_RET_PARENT: "/secured/gstfilings/return-01/Details",
  SALES_B2B: "/secured/sales/b2b",
  SALES_B2BA: "/secured/sales/b2ba",
  SALES_B2C: "/secured/sales/b2c",
  SALES_B2CA: "/secured/sales/b2ca",
  SALES_B2CS: "/secured/sales/b2cs",
  SALES_B2CSA: "/secured/sales/b2csa",
  SALES_CDNR: "/secured/sales/cdnr",
  SALES_CDNUR: "/secured/sales/cdnur",
  SALES_CDNRA: "/secured/sales/cdnra",
  SALES_CDNURA: "/secured/sales/cdnura",
  SALES_AT: "/secured/sales/at",
  SALES_ATADJ: "/secured/sales/atadj",
  SALES_ATA: "/secured/sales/ata",
  SALES_ATADJA: "/secured/sales/atadja",
  SALES_EXPORTS: "/secured/sales/exports",
  SALES_EXPORTSA: "/secured/sales/exportsa",
  SALES_HSN: "/secured/sales/hsn",
  SALES_EXEMP: "/secured/sales/exemp",
  SALES_DOC: "/secured/sales/doc",
  PURCHASE: "/secured/purchase",
  PURCHASE_DASHBOARD: "/secured/purchase/dashboard",
  PURCHASE_B2B: "/secured/purchase/b2b",
  PURCHASE_B2BUR: "/secured/purchase/b2bur",
  PURCHASE_CDNR: "/secured/purchase/cdnr",
  PURCHASE_CDNUR: "/secured/purchase/cdnur",
  PURCHASE_EXEMP: "/secured/purchase/exemp",
  PURCHASE_AT: "/secured/purchase/at",
  PURCHASE_ATADJ: "/secured/purchase/atadj",
  PURCHASE_ITC: "/secured/purchase/itc",
  PURCHASE_ISD: "/secured/purchase/isd",
  PURCHASE_IMPG: "/secured/purchase/impg",
  PURCHASE_IMPS: "/secured/purchase/imps",
  PURCHASE_HSN: "/secured/purchase/hsn",
  PURCHASE_B2C: "/secured/purchase/b2c",
  PURCHASE_EXPORTS: "/secured/purchase/exports",
  PURCHASE_GSTR2A: "/secured/purchase/gstr2a",
  PURCHASE_GSTR2B: "/secured/purchase/gstr2b",
  RECONCILE: "/secured/reconcile/dashboard",
  ITC04: "/secured/itc04",
  XL_PURCHASE: "/secured/xlt"
};
const RETURN_TYPES = {
  GSTR1: "GSTR1",
  GSTR1A: "GSTR1A",
  GSTR2: "GSTR2",
  GSTR3: "GSTR3",
};

const LOCAL_STORAGE = {
  IS_LOGGED_IN: "IS_LOGGED_IN",
  TOKEN: "TOKEN",
  CN: "CN",
};

// const BASE_URL = (process.env.REACT_APP_BASE_URL || "").trim();
// const BASE_URL = "http://localhost:3100";
const BASE_URL = "https://prod-ebs-thoughtgst-api.azurewebsites.net";

const formatNumber = function (num) {
  var n1, n2;
  num = parseFloat(num).toFixed(2) + "" || "";
  // works for integer and floating as well
  n1 = num.split("."); 
  n2 = n1[1] || null;
  n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
  num = n2 ? n1 + "." + n2 : n1;
  return `₹ ${num}`;
};

const stateArray = [
  {
    pos_id: "01",
    state_name: "01-Jammu & Kashmir",
    short_name: "Jammu & Kashmir",
  },
  {
    pos_id: "02",
    state_name: "02-Himachal Pradesh",
    short_name: "Himachal Pradesh",
  },
  {
    pos_id: "03",
    state_name: "03-Punjab",
    short_name: "Punjab",
  },
  {
    pos_id: "04",
    state_name: "04-Chandigarh",
    short_name: "Chandigarh",
  },
  {
    pos_id: "05",
    state_name: "05-Uttarakhand",
    short_name: "Uttarakhand",
  },
  {
    pos_id: "06",
    state_name: "06-Haryana",
    short_name: "Haryana",
  },
  {
    pos_id: "07",
    state_name: "07-Delhi",
    short_name: "Delhi",
  },
  {
    pos_id: "08",
    state_name: "08-Rajasthan",
    short_name: "Rajasthan",
  },
  {
    pos_id: "09",
    state_name: "09-Uttar Pradesh",
    short_name: "Uttar Pradesh",
  },
  {
    pos_id: "10",
    state_name: "10-Bihar",
    short_name: "Bihar",
  },
  {
    pos_id: "11",
    state_name: "11-Sikkim",
    short_name: "Sikkim",
  },
  {
    pos_id: "12",
    state_name: "12-Arunachal Pradesh",
    short_name: "Arunachal Pradesh",
  },
  {
    pos_id: "13",
    state_name: "13-Nagaland",
    short_name: "Nagaland",
  },
  {
    pos_id: "14",
    state_name: "14-Manipur",
    short_name: "Manipur",
  },
  {
    pos_id: "15",
    state_name: "15-Mizoram",
    short_name: "Mizoram",
  },
  {
    pos_id: "16",
    state_name: "16-Tripura",
    short_name: "Tripura",
  },
  {
    pos_id: "17",
    state_name: "17-Meghalaya",
    short_name: "Meghalaya",
  },
  {
    pos_id: "18",
    state_name: "18-Assam",
    short_name: "Assam",
  },
  {
    pos_id: "19",
    state_name: "19-West Bengal",
    short_name: "West Bengal",
  },
  {
    pos_id: "20",
    state_name: "20-Jharkhand",
    short_name: "Jharkhand",
  },
  {
    pos_id: "21",
    state_name: "21-Odisha",
    short_name: "Odisha",
  },
  {
    pos_id: "22",
    state_name: "22-Chhattisgarh",
    short_name: "Chhattisgarh",
  },
  {
    pos_id: "23",
    state_name: "23-Madhya Pradesh",
    short_name: "Madhya Pradesh",
  },
  {
    pos_id: "24",
    state_name: "24-Gujarat",
    short_name: "Gujarat",
  },
  {
    pos_id: "25",
    state_name: "25-Daman & Diu",
    short_name: "Daman & Diu",
  },
  {
    pos_id: "26",
    state_name: "26-Dadra & Nagar Haveli",
    short_name: "Dadra & Nagar Haveli",
  },
  {
    pos_id: "27",
    state_name: "27-Maharashtra",
    short_name: "Maharashtra",
  },
  {
    pos_id: "29",
    state_name: "29-Karnataka",
    short_name: "Karnataka",
  },
  {
    pos_id: "30",
    state_name: "30-Goa",
    short_name: "Goa",
  },
  {
    pos_id: "31",
    state_name: "31-Lakshdweep",
    short_name: "Lakshdweep",
  },
  {
    pos_id: "32",
    state_name: "32-Kerala",
    short_name: "Kerala",
  },
  {
    pos_id: "33",
    state_name: "33-Tamil Nadu",
    short_name: "Tamil Nadu",
  },
  {
    pos_id: "34",
    state_name: "34-Pondicherry",
    short_name: "Pondicherry",
  },
  {
    pos_id: "35",
    state_name: "35-Andaman & Nicobar Islands",
    short_name: "Andaman & Nicobar Islands",
  },
  {
    pos_id: "36",
    state_name: "36-Telangana",
    short_name: "Telangana",
  },
  {
    pos_id: "37",
    state_name: "37-Andhra Pradesh",
    short_name: "Andra Pradesh",
  },
  {
    pos_id: "97",
    state_name: "97-Other Territory",
    short_name: "Other Territory",
  },
];

const getPlaceOfSupply = function (pos) {
  if (!pos) return null;
  const s_pos = find({ pos_id: pos + "" })(stateArray);
  if (!s_pos) return pos;
  return s_pos.state_name ? s_pos.state_name : null;
};

const docNumberArray = [
  {
    id: 1,
    docName: "Invoices for outward supply",
  },
  {
    id: 2,
    docName: "Invoices for inward supply from unregistered person",
  },
  {
    id: 3,
    docName: "Revised Invoice",
  },
  {
    id: 4,
    docName: "Debit Note",
  },
  {
    id: 5,
    docName: "Credit Note",
  },
  {
    id: 6,
    docName: "Receipt voucher",
  },
  {
    id: 7,
    docName: "Payment voucher",
  },
  {
    id: 8,
    docName: "Refund voucher",
  },
  {
    id: 9,
    docName: "Delivery Challan for job work",
  },
  {
    id: 10,
    docName: "Delivery Challan for supply on approval",
  },
  {
    id: 11,
    docName: "Delivery Challan in case of liquid gas",
  },
  {
    id: 12,
    docName:
      "Delivery Challan in cases other than by way of supply (excluding at S no. 9 to 11)",
  },
];

const i18n = {
  en: {
    product_name: "ElixirGST",
    company_name: "Elixir Business Solution Private Limited",
    powered_by: "Powered by Elixir Business Solution Private Limited",
    privacy_policy_text: "Privacy Policy",
    common: {
      space: " ",
      or: "Or",
    },
    login: {
      email_required_message: "Please input your email!",
      email_placeholder_message: "Email",
      password_required_message: "Please input your password!",
      password_placeholder_message: "Password",
      login_button_text: "Log In",
      register_now_text: "Register Now",
    },
    register: {
      email_required_message: "Please input your email!",
      email_placeholder_message: "Email",
      password_required_message: "Please input your password!",
      password_placeholder_message: "Password",
      register_button_text: "Register",
      registration_success_message: "Registration is successful!",
      referral_code_message: "Please input referral code",
    },
    reconcile: {},
  },
};

export {
  RETURN_TYPES,
  ROUTES,
  LOCAL_STORAGE,
  BASE_URL,
  i18n,
  formatNumber,
  stateArray,
  docNumberArray,
  getPlaceOfSupply,
};
