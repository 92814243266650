import React from 'react'
import { Table, InputNumber, Input, Tag } from 'antd';
const GSTR9Pt2Sec4 = props => {
    const columns = [
      {
          title: 'Pt. II',
          dataIndex: 'sno',
          key: 'sno',
          width: '5%',
      },
      {
          title: '4. Details of advances, inward and outward supplies on which tax is payable as declared in returns filed during the financial year',
          children: [
            {
              title: 'Nature of Supplies',
              dataIndex: 'det',
              key: 'det',
              width: '20%',
          },
          {
              title: 'Taxable Value (₹)',
              dataIndex: 'val',
              key: 'val',
              width: '15%',
              render: (text, record) => {
                return (record.key === "8" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (text !== undefined) ? (
                  <Input
                    id={`pt2sec4-${record.key}-val-tbl4`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'Integrated Tax (₹)',
              dataIndex: 'iamt',
              key: 'iamt',
              width: '15%',
              render: (text, record) => {
                return (record.key === "8" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (text !== undefined) ? (
                  <Input
                    id={`pt2sec4-${record.key}-iamt-tbl4`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'Central Tax (₹)',
              dataIndex: 'camt',
              key: 'camt',
              width: '15%',
              render: (text, record) => {
                return (record.key === "8" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (text !== undefined) ? (
                  <Input
                    id={`pt2sec4-${record.key}-camt-tbl4`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'State/UT Tax (₹)',
              dataIndex: 'samt',
              key: 'samt',
              width: '15%',
              render: (text, record) => {
                return (record.key === "8" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (text !== undefined) ? (
                  <Input
                    id={`pt2sec4-${record.key}-samt-tbl4`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          },
          {
              title: 'CESS (₹)',
              dataIndex: 'cess',
              key: 'cess',
              width: '15%',
              render: (text, record) => {
                return (record.key === "8" || record.key === "13" || record.key === "14") ? (
                  <Tag width="100%">{`₹ ${text}`}</Tag>
                ) : (text !== undefined) ? (
                  <Input
                    id={`pt2sec4-${record.key}-cess-tbl4`}
                    defaultValue={text}
                    onKeyDown={props.txvalChange}
                    onBlur={props.txtOnBlur}
                    addonBefore='₹'
                  />
                ) : null
              }
          }
          ]
      },
    ];
    console.log(props)
    return (
        <Table pagination={false} className={props.className} bordered title={() => "Details of Outward and Inward supplies declared during the financial year"} size="small" dataSource={props.pt2sec4.dataSource} columns={columns} />
    )
  }

  export default GSTR9Pt2Sec4