/**
 * @flow
 */
import React from 'react'
import { Table, Tooltip, Button, Popconfirm,  } from 'antd'
import { formatNumber, stateArray } from "../../shared/constants";
import Items from './Items'
import moment from 'moment';
import find from "lodash/fp/find";
const { ColumnGroup, Column } = Table


export default (props: props) =>
<Table locale={{ emptyText: props.tableText }} dataSource={props.sr_b2b_data} bordered title={() => props.stateTYPE + ' Sales'} pagination={{ className: 'my-pagination' }}>
   {(props.stateTYPE === 'B2B' || props.stateTYPE === 'CDNR' || props.stateTYPE === 'B2BA' || props.stateTYPE === 'CDNRA') && <Column title="Receiver GSTIN" dataIndex="receiver_gstin" key="receiver_gstin" />}
   {(props.stateTYPE === 'B2BA' || props.stateTYPE === 'B2CLA' || props.stateTYPE === 'EXPA') &&
   <ColumnGroup title="Original Invoice Details">
    <Column title="No." dataIndex="o_invoice_number" key="o_invoice_number" />
    <Column title="Date" dataIndex="o_invoice_date" key="o_invoice_date" />
    </ColumnGroup>
    }
   <ColumnGroup title="Invoice Details">
     {(props.stateTYPE === 'B2CS' || props.stateTYPE === 'AT' || props.stateTYPE === 'TXP' || props.stateTYPE === 'B2CSA' || props.stateTYPE === 'ATA' || props.stateTYPE === 'TXPA' || props.stateTYPE === 'NIL' || props.stateTYPE === 'HSN' || props.stateTYPE === 'DOCISS') && <Column title="Info" key="info" render={() => 'No Invoice Level Information'} />}
     {(props.stateTYPE === 'B2B' || props.stateTYPE === 'B2CL' || props.stateTYPE === 'EXP' || props.stateTYPE === 'CDNR' || props.stateTYPE === 'CDNUR' || props.stateTYPE === 'B2BA' || props.stateTYPE === 'B2CLA' || props.stateTYPE === 'EXPA' || props.stateTYPE === 'CDNRA' || props.stateTYPE === 'CDNURA') && <Column title="No." dataIndex="invoice_number" key="invoice_number" />}
     {(props.stateTYPE === 'B2B' || props.stateTYPE === 'B2CL' || props.stateTYPE === 'EXP' || props.stateTYPE === 'CDNR' || props.stateTYPE === 'CDNUR' || props.stateTYPE === 'B2BA' || props.stateTYPE === 'B2CLA' || props.stateTYPE === 'EXPA' || props.stateTYPE === 'CDNRA' || props.stateTYPE === 'CDNURA') && <Column title="Date" dataIndex="invoice_date" key="invoice_date" />}
     {(props.stateTYPE === 'B2B' || props.stateTYPE === 'B2CL' || props.stateTYPE === 'EXP') && <Column title="Value" dataIndex="invoice_value" key="invoice_value" />}
     {(props.stateTYPE === 'B2B' || props.stateTYPE === 'CDNUR' || props.stateTYPE === 'B2BA' || props.stateTYPE === 'CDNURA') && <Column title="Invoice Type" dataIndex="invoice_type" key="invoice_type" />}
   </ColumnGroup>
   {(props.stateTYPE === 'AT' || props.stateTYPE === 'TXP') && <Column title="Supply Type" dataIndex="supply_type" key="supply_type" />}
   {(props.stateTYPE === 'B2CSA' ||props.stateTYPE === 'ATA' || props.stateTYPE === 'TXPA') && <Column title="Original Month" dataIndex="original_month" key="original_month" />}
   
   {props.stateTYPE === 'CDNRA' &&
     <ColumnGroup title="Original CDNR Details">
       <Column title="No." dataIndex="o_cdnr_number" key="o_cdnr_number" />
       <Column title="Date" dataIndex="o_cdnr_date" key="o_cdnr_date"  />
     </ColumnGroup>
    }
   {(props.stateTYPE === 'CDNR' || props.stateTYPE === 'CDNRA') &&
     <ColumnGroup title="CDNR Details">
       <Column title="No." dataIndex="cdnr_number" key="cdnr_number" />
       <Column title="Date" dataIndex="cdnr_date" key="cdnr_date"  />
       <Column title="Type" dataIndex="cdnr_type" key="cdnr_type" />
       <Column title="Value" dataIndex="cdnr_value" key="cdnr_value"   />
     </ColumnGroup>
    }
    {props.stateTYPE === 'CDNURA' &&
     <ColumnGroup title="Original CDNUR Details">
       <Column title="No." dataIndex="o_cdnur_number" key="o_cdnur_number" />
       <Column title="Date" dataIndex="o_cdnur_date" key="o_cdnur_date"  />
     </ColumnGroup>
    }
    
    {(props.stateTYPE === 'CDNUR' || props.stateTYPE === 'CDNURA') &&
      <ColumnGroup title="CDNUR Details">
        <Column title="No." dataIndex="cdnur_number" key="cdnur_number" />
        <Column title="Date" dataIndex="cdnur_date" key="cdnur_date"  />
        <Column title="Type" dataIndex="cdnur_type" key="cdnur_type" />
        <Column title="Value" dataIndex="cdnur_value" key="cdnur_value"  />
      </ColumnGroup>
     }
     {(props.stateTYPE === 'EXP' || props.stateTYPE === 'EXPA') &&
     <ColumnGroup title="Export Details">
       <Column title="Type" dataIndex="exp_type" key="exp_type" />
       <Column title="Shipping Bill No." dataIndex="sb_number" key="sb_number" />
       <Column title="Shipping Bill Date" dataIndex="sb_date" key="sb_date" render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}/>
       <Column title="Port Code" dataIndex="sb_port_code" key="sb_port_code" />
     </ColumnGroup>}
     {props.stateTYPE === 'HSNSUM' && <Column title="HSN" dataIndex="hsn_sac_code" key="hsn_sac_code" />}
     {
       props.stateTYPE === 'HSNSUM' &&

       <ColumnGroup title="HSN Summary">
         <Column title="Description" dataIndex="hsn_sac_desc" key="hsn_sac_desc" />
         <Column title="UQC" dataIndex="uqc_code" key="uqc_code" />
         <Column title="Total Quantity" dataIndex="total_qty" key="total_qty" />
         <Column title="Total Value" dataIndex="total_value" key="total_value" />
     </ColumnGroup>
     }
     { props.stateTYPE === 'DOCISS' && <Column title="Nature" dataIndex="nature_doc" key="nature_doc" />}
     { props.stateTYPE === 'DOCISS' && <Column title="Sr.No.From" dataIndex="from_serial" key="from_serial" />}
     { props.stateTYPE === 'DOCISS' && <Column title="Sr.No.To" dataIndex="to_serial" key="to_serial" />}
     { props.stateTYPE === 'DOCISS' && <Column title="Total" dataIndex="total_count" key="total_count" />}
     { props.stateTYPE === 'DOCISS' && <Column title="Cancelled" dataIndex="cancel_count" key="cancel_count" />}
     { props.stateTYPE === 'DOCISS' && <Column title="Net Issued" dataIndex="net_count" key="net_count" />}
     { props.stateTYPE === 'NIL' && <Column title="Description" dataIndex="supply_type" key="supply_type" />}
      { props.stateTYPE === 'NIL' &&  <Column title="Nil Rated Supplies" dataIndex="nil_amt" key="nil_amt"  />}
       { props.stateTYPE === 'NIL' && <Column title="Exempted (Other than NIL Rated)" dataIndex="expt_amt" key="expt_amt"  />}
      { props.stateTYPE === 'NIL' &&  <Column title="Non-GST Supplies" dataIndex="ngsup_amt" key="ngsup_amt"  />}
   {props.stateTYPE === 'B2CS' && <Column title="Invoice Type" dataIndex="invoice_type" key="invoice_type" />}
   <Column title="Place Of Supply" dataIndex="pos_id" key="pos_id" render={pos => pos ? find({pos_id: pos+""})(stateArray).state_name : '---'}/>
   {props.stateTYPE === 'B2B' && <Column title="Reverse Charge" dataIndex="reverse_charge" key="reverse_charge" />}
   {props.stateTYPE !== 'NIL' && <Column title="Applicable % of Tax Rate" dataIndex="atr" key="atr" render={atr => atr ? atr : '---'}/> }
     {props.stateTYPE !== 'NIL' && <Column title="Items" key="items"
       render={(text, record) => {
         return (
           <Tooltip title="View Item(s)">
             <Button icon="switcher" type="primary" onClick={() => props.showModal(record.invoice_number)}>
               {record.items.length ? record.items.length : ' 1 '} Item(s)
               <Items isVisible={props.visibleModal === record.invoice_number} onCancel={props.hideModal} sr_b2b_items={record.items}/>
             </Button>
           </Tooltip>
         )
       }}
     />}
   <Column title="Tax Payer Action" key="del"
     render={(text, record) => {
       return (
         <Tooltip title="Delete Invoice from GSTN">
           <Popconfirm placement="bottomRight" title="Clicking this button will delete this Invoice/Record from GSTN!"  onConfirm={() => props.delInvoiceFromGSTN(record)} okText="Yes" cancelText="No">
             <Button icon="delete" type="danger">
             Delete
             </Button>
           </Popconfirm>
         </Tooltip>
       )
     }}
   />
</Table>
