
import React from 'react'
import glamorous from 'glamorous'
import Table from 'antd/lib/table'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import get from 'lodash/fp/get'
import { V2_SR_ECOM_SalesQuery, CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import { formatNumber, BASE_URL } from "../../shared/constants";
import { Root, ContentWrapper as Content } from '../../shared/styles'
import Modal from 'antd/lib/modal'
import message from 'antd/lib/message'
import OTPForm from "../GSP/OTPForm";
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'
import gspAuth from "../../services/GSPService";
import deleteData from "../../services/DeleteService";
const errorModal = Modal.error;
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const { Column } = Table
const SRow = glamorous(Row)({ margin: '15px 0', })

const ANX1ECOM = props => {
    console.log(props);
    return (
        <Root>
            <Content>
                <SRow gutter={24} style={row_style}>
                    <Col span="6" />
                </SRow>
                <Table dataSource={props.v2_sr_ecom} bordered title={() => 'ECOM Sales'} pagination={{ className: 'my-pagination' }}>
                    <Column title="GSTIN of E-com" key="" dataIndex="ecom_gstin" ></Column>
                    <Column title="Trade/Legal  Name" key="" dataIndex="name" ></Column>
                    <Column title="Value of Supplies Made" key="" dataIndex="sup_made" ></Column>
                    <Column title="Value of Suppies Returned" key="" dataIndex="sup_ret" ></Column>
                    <Column title="Net Value of Supplies" key="" dataIndex="sup_net" ></Column>
                    <Column title="Integrated Tax" key="" dataIndex="igst" ></Column>
                    <Column title="Central Tax" key="" dataIndex="cgst" ></Column>
                    <Column title="State/UT Tax" key="" dataIndex="sgst" ></Column>
                    <Column title="Cess" key="" dataIndex="cess" ></Column>
                </Table>
                <script type="text/javascript" src="FileSaver.js"></script>
            </Content>
        </Root>
    )
}
class ANX1ECOMContainer extends React.Component {
    constructor(props) {
        super(props);
        console.log(props);

        if (props.gspDetails !== undefined && props.gspDetails !== null) {
            this.gsp = {
                access_token: props.gspDetails.gspToken,
                otp: props.gspDetails.otp,
                response: null
            };
        } else {
            this.gsp = {};
        }

        var d = new Date();
        var n = d.getMonth();
        var y = d.getYear();
        var curyear = 1900 + y;
        var nextyear = curyear + 1 - 2000;
        var year = curyear + '-' + nextyear;
        this.state = {
            visibleModal: null,
            finMonth: n + 1,
            finYear: year,
            curFinMonth: n + 1,
            curFinYear: year,

            visible: false,
            requestOTP: false,
            otp: null,
            period: null,
            selectedRowKeys: []
        }
        this.props.data.refetch()
    }

    componentDidUpdate = () => {
        console.log(this.props);
        console.log(this.prevProps);
        console.log(this.gsp);

        if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
            this.gsp.access_token = this.props.gspDetails.gspToken;
            this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
        }
    };

    otherGSTNInformation = companyDetails => {
        const state_cd = companyDetails["gstin"].substr(0, 2);
        companyDetails["state"] = state_cd;
        return companyDetails;
    };

    handleCancel = e => {
        message.destroy();
        this.setState({ requestOTP: false });
    };

    hide = () => {
        this.setState({
            visible: false
        });
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    handleOTPSubmit = val => {
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            form.resetFields();
            this.gsp.otp = values.otp.trim();
            this.setState({ requestOTP: false });
            this.delInvoiceFromGSTN();
            gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
        });
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    delInvoiceFromGSTN = async () => {

        message.destroy();
        message.loading('Deleting Invoice/Invoices from ElixirGST & GSTN...', 0)

        console.log(this.state.selectedRowKeys);

        var sendDeleteData = [];

        var downloaddate = this.props.finMonth + "/" + this.props.finYear;

        if (this.state.selectedRowKeys.length === 0) {
            message.destroy();
            message.warning('Kindly select the invoices for deletion..!', 2)
        } else {

            const filterItemArray = map(g => {
                const filterItemArray = filter({ id: g })(get('viewer.v2_sr_ecom.edges')(this.props.data));
                sendDeleteData = sendDeleteData.concat(filterItemArray)
            })(this.state.selectedRowKeys)

            const deleteDataFormat = deleteData.formatDeletePayload(sendDeleteData, 'ecoms');

            const GSPData = deleteData.formatGSPPayload(
                this.props.finYear,
                this.props.finMonth,
                this.props.company,
                this.otherGSTNInformation,
                this.gsp,
                deleteDataFormat,
                'B2CS'
            );

            this.gsp.response = await gspAuth.saveGSTR1(GSPData);

            console.log(this.gsp.response);
            if (
                !this.gsp.response.data.success &&
                this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
            ) {
                message.destroy();
                this.setState({ requestOTP: true });
            } else if (
                !this.gsp.response.data.success &&
                this.gsp.response.data.errorCode === "GSTR1 is already filed for current period"
            ) {
                message.destroy();
                errorModal({
                    title: 'GSTN Delete Unsuccessful',
                    content: <p>GSTR-1 is already filed, record(s) cannot be deleted!</p>,
                    iconType: "close-circle",
                });

            } else if (this.gsp.response.data.success) {
                message.destroy();
                if (this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "P") {
                    const deleteDB = fetch(
                        `${BASE_URL}/export/deleteGSTR1_DB?companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}&action=B2CS`,
                        {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(this.state.selectedRowKeys)
                        }
                    )
                    message.destroy();
                    message.success('Deleted Successfully', 1);
                }
                if (this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "PE") {
                    errorModal({
                        title: 'GSTN Delete Unsuccessful',
                        content: <p>Please check the downloaded error report!</p>,
                        iconType: "close-circle",
                    });
                }
                if (this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "ER") {
                    errorModal({
                        title: 'GSTN Delete Unsuccessful',
                        content: <p>Please validate your record</p>,
                        iconType: "close-circle",
                    });
                }
            }

            this.props.data.refetch()
        }
    }
    showModal = async id => {
        this.setState({ visibleModal: id })
    }
    hideModal = () => {
        this.setState({ visibleModal: null })
    }
    changeSelectedFinancialMonth = name => {
        this.props.changeSelectedFinancialMonth(name)
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        console.log(selectedRowKeys);
        this.setState({ selectedRowKeys });
    }
    render() {
        var tableLoading = false;
        if (this.props.data.networkStatus === 4) { tableLoading = false; }
        else if (this.props.data.networkStatus === 7) { tableLoading = false; }
        else if (this.props.data.networkStatus === 1) { tableLoading = true; }
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideDefaultSelections: true,
            selections: [{
                key: 'all-data',
                text: 'Select All Invoices',
                onSelect: () => {

                    this.setState({
                        selectedRowKeys: get('viewer.v2_sr_ecom.edges')(this.props.data).map(a => a.id),

                    })
                },
            }]
        };
        return (
            <div style={{ flex: 1 }}>
                <ANX1ECOM
                    v2_sr_ecom={get('viewer.v2_sr_ecom.edges')(this.props.data)}
                    role={get('company.role', this.props)}
                    finMonth={this.state.finMonth}
                    finYear={this.state.finYear}
                    loading={tableLoading}
                    showModal={this.showModal}
                    hideModal={this.hideModal}
                    visibleModal={this.state.visibleModal}
                    isSR={this.props.isSR}
                    role={get('company.role', this.props)}
                    formatNumber={formatNumber}
                    delInvoiceFromGSTN={this.delInvoiceFromGSTN}
                    rowSelection={rowSelection}
                />
                <OTPForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.requestOTP}
                    onSubmit={this.handleOTPSubmit}
                    onCancel={this.handleCancel}
                />
            </div>
        )
    }
}

const queryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finmonth: props.finMonth.toString(),
            finyear: props.finYear,
        },
    }),
}
const companyQueryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finYear: props.finYear,
            finMonth: props.finMonth,
        },
    }),
    props: ({ data }) => {
        return {
            company: get('viewer.company', data),
            gspDetails: get("viewer.gspInfo", data),
        }
    },
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany,
        finMonth: state.local.finMonth,
        finYear: state.local.finYear,
    }
}

export default compose(
    connect(mapStateToProps),
    graphql(gql(V2_SR_ECOM_SalesQuery), queryConfig),
    graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(ANX1ECOMContainer)
