import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { gql, graphql, compose, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { formatNumber } from "../../shared/constants";
import get from 'lodash/fp/get'
import TableSix from './tableSix'
import TableSeven from './tableSeven'
import TableEight from './tableEight'
import { BASE_URL } from '../../shared/constants'
import { ContentWrapper } from '../../shared/styles'
import { FormItem } from '../../shared/utils'
import map from 'lodash/fp/map'
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 6,
    },
  },
}

const { Link } = Anchor;
const { Sider, Content } = Layout;
var inputRef = [];
var focusedItem = null;



  class EnterGSTR9_P_THREE extends React.Component {

    render() {
      return(
        <div>
          <TableSix getFieldDecorator={this.props.form.getFieldDecorator} />
          <TableSeven getFieldDecorator={this.props.form.getFieldDecorator} />
          <TableEight getFieldDecorator={this.props.form.getFieldDecorator} />
        </div>
        )
    }
  }


const mapStateToProps = state => {
    return {
      companyId: state.local.selectedCompany,
      finMonth: state.local.finMonth,
      finYear: state.local.finYear,
    }
  }

export default compose(
    withRouter,
    withApollo,
    connect(mapStateToProps),
    Form.create(),
  )(EnterGSTR9_P_THREE)

  // <TableSix
  //           getFieldDecorator={this.props.form.getFieldDecorator}
  //         />
  //         <TableSeven 
  //           getFieldDecorator={this.props.form.getFieldDecorator}
  //         />
  //         <TableEight
  //           getFieldDecorator={this.props.form.getFieldDecorator}
  //         />