import React from "react";
import Modal from "antd/lib/modal";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";
import Popconfirm from "antd/lib/popconfirm";
import moment from "moment";

import find from "lodash/fp/find";
import { getPlaceOfSupply } from "../../shared/constants";

const { Column } = Table;
const scroll = { x: 1600 };

class ReconcileMisMatchedDetails extends React.Component {
  constructor(props){
    super(props);
    this.prepareAndSendWA = this.prepareAndSendWA.bind(this)
    this.state ={
      phone:""
    }
  }
  prepareAndSendWA(record) {
    console.log(record);
    console.log(this.state.phone)
    this.props.sendWA({...record, phone: this.state.phone});
  }
  onKeyPress(e){
    console.log(e.target.value);
    this.setState({phone:e.target.value})
  }
  render() {
    const props = this.props;
    const maxHeight = Math.floor((window.innerHeight - 250) / 83);
    return (
      <Modal
        title="Purchase - Mismatched Invoices (B2B)"
        visible={props.visibleModal}
        onCancel={props.hideModal}
        footer={null}
        width="96%"
      >
        <Table
          id="mismatchTABLE"
          dataSource={props.dataset_popup_details}
          bordered
          rowSelection={props.rowSelection_mismatch}
          rowClassName={(r, i) => {
            return i % 2 === 0
              ? "displayCheckBox"
              : "pr-register-reconcile-mismatch";
          }}
          rowKey="id"
          title={() => (
            <span style={{ marginLeft: 8 }}>
              {props.hasSelected
                ? `Selected ${props.selectedRowKeys.length} Invoices`
                : ""}
            </span>
          )}
          loading={props.loading}
          footer={() => (
            <div>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#2382c7",
                  color: "white",
                }}
                onClick={() => props.fetchMissingNames(this.props.m_id)}
              >
                Fetch missing Supplier Name(s)
              </Button>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#921c26",
                  color: "white",
                }}
                onClick={() => props.handleMismatchIneligible()}
              >
                Mark as Ineligible
              </Button>
            </div>
          )}
          pagination={{
            className: "my-pagination",
            defaultPageSize: 2 * Math.round(maxHeight / 2),
          }}
        >
          <Column
            title="Type"
            dataIndex="type"
            key="type"
            className="alignCenter"
            render={(text) =>
              text === "reconprb2b" ? "GSTN" : "Purchase Register"
            }
          />
          <Column
            title="GSTIN"
            dataIndex="supplier_gstin"
            className="alignCenter"
            key="supplier_gstin"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div className="custom-filter-dropdown">
                <Input
                  placeholder="Search GSTIN Number"
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={props.handleSearch(selectedKeys, confirm)}
                />
                <Button
                  type="primary"
                  onClick={props.handleSearch(selectedKeys, confirm)}
                >
                  Search
                </Button>
                <Button
                  id="gstnSearch_mismatch_b2b"
                  onClick={props.handleReset(clearFilters)}
                >
                  Reset
                </Button>
              </div>
            )}
            filterIcon={(filtered) => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa",
                }}
              />
            )}
            onFilter={(value, record) =>
              record.supplier_gstin.toLowerCase().includes(value.toLowerCase())
            }
            render={(text) => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />
          <Column
            title="Supplier Name"
            dataIndex="supplier_name"
            className="alignCenter"
            key="supplier_name"
          />
          <Column
            title="Invoice Number"
            dataIndex="invoice_number"
            className="alignCenter"
            key="invoice_number"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <span>
                <div className="custom-filter-dropdown">
                  <Input
                    placeholder="Search Invoice Number"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={props.handleSearch(selectedKeys, confirm)}
                  />
                  <Button
                    type="primary"
                    onClick={props.handleSearch(selectedKeys, confirm)}
                  >
                    Search
                  </Button>
                  <Button
                    id="misMatchInvoiceSearch_b2b"
                    onClick={props.handleReset(clearFilters)}
                  >
                    Reset
                  </Button>
                </div>
              </span>
            )}
            filterIcon={(filtered) => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa",
                }}
              />
            )}
            onFilter={(value, record) =>
              record.invoice_number.toLowerCase().includes(value.toLowerCase())
            }
            render={(text) => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />
          <Column
            title="Invoice Date"
            dataIndex="invoice_date"
            className="alignCenter"
            key="invoice_date"
            render={(text) =>
              text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
            }
          />
          <Column
            title="Invoice Value"
            dataIndex="invoice_value"
            className="alignCenter"
            key="invoice_value"
            render={(val) => (
              <p>{props.formatNumber(val === null ? 0 : val)}</p>
            )}
          />
          <Column
            title="Invoice Type"
            dataIndex="invoice_type"
            className="alignCenter"
            key="invoice_type"
          />
          <Column
            title="POS"
            dataIndex="pos_id"
            key="pos_id"
            className="alignCenter"
            render={(pos) =>
              pos ? getPlaceOfSupply(pos.substring(0, 2)) : null
            }
          />
          <Column
            title="Reverse Charge"
            dataIndex="reverse_charge"
            className="alignCenter"
            key="reverse_charge"
          />

          <Column
            title="Items"
            key="action"
            className="alignCenter"
            render={(text, record, index) => {
              var but = (
                <Row gutter={24}>
                  <Col span="8">
                    <Button
                      icon="bars"
                      type="primary"
                      onClick={() => props.showItemModal(record)}
                    >
                      Items
                    </Button>
                    <Modal
                      title="List Items Summary - Mismatched Invoices (B2B)"
                      visible={
                        props.visibleItemModal === record.id ? true : false
                      }
                      onCancel={props.hideItemModal}
                      footer={null}
                      width="920"
                    >
                      <Table
                        dataSource={record.items}
                        bordered
                        loading={props.loading}
                        scroll={scroll}
                        pagination={false}
                      >
                        <Column
                          title="TYPE"
                          dataIndex="item_type"
                          className="alignCenter"
                          key="item_type"
                          render={(text) =>
                            text.toUpperCase() === "RECONPRB2B"
                              ? "GSTN"
                              : "Purchase Register"
                          }
                        />
                        <Column
                          title="Rate"
                          dataIndex="gst_rate"
                          className="alignCenter"
                          key="gst_rate"
                          render={(val) => parseFloat(val).toFixed(2)}
                        />
                        <Column
                          title="Taxable Value"
                          className="alignCenter"
                          dataIndex="taxable_value"
                          key="taxable_value"
                          render={(val) => (
                            <p>{props.formatNumber(val === null ? 0 : val)}</p>
                          )}
                        />
                        <Column
                          title="IGST"
                          dataIndex="igst"
                          className="alignCenter"
                          key="igst"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="CGST"
                          dataIndex="cgst"
                          className="alignCenter"
                          key="cgst"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="SGST"
                          dataIndex="sgst"
                          className="alignCenter"
                          key="sgst"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="Cess"
                          dataIndex="cess"
                          className="alignCenter"
                          key="cess"
                          render={(val) => (
                            <p>
                              {val === null
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                      </Table>
                    </Modal>
                  </Col>
                </Row>
              );
              const obj = {
                children: but,
                props: { rowSpan: 2 },
              };
              var temp = index % 2;
              if (temp === 0) {
                return but, obj;
              } else {
                return {
                  props: {
                    rowSpan: 0,
                  },
                };
              }
            }}
          />
          <Column
            title="Contact"
            key="contact"
            className="alignCenter"
            render={(text, record, index) => {
              var but = (
                <>
                  <Button
                    icon="mail"
                    style={{
                      backgroundColor: "#148a5a",
                      color: "white",
                    }}
                    onClick={() => props.notifyViaEmail(record)}
                  ></Button>
                  <Popconfirm
                    title={
                      <div>
                        Send the message to..
                        <Input
                          placeholder="Enter Phone Number"
                          value={this.state.phone || record.contact_phone}
                          onChange={(e) => {console.log(e.target); this.setState({phone: e.target.value})}}
                          maxLength={10}
                          onPressEnter={()=> this.prepareAndSendWA(record)}
                        />
                      </div>
                    }
                    okText="Yes"
                    cancelText="No"
                    onConfirm={()=> this.prepareAndSendWA(record)}
                    onCancel={props.onMessageDrawer}
                  >
                    <Button
                      icon="message"
                      style={{
                        backgroundColor: "#148a5a",
                        color: "white",
                      }}
                    ></Button>
                  </Popconfirm>
                </>
              );
              const obj = {
                children: but,
                props: { rowSpan: 2, style: { padding: 0 } },
              };
              var temp = index % 2;
              if (temp === 0) {
                return but, obj;
              } else {
                return {
                  props: {
                    rowSpan: 0,
                  },
                };
              }
            }}
          />
        </Table>
      </Modal>
    );
  }
}
export default ReconcileMisMatchedDetails;
