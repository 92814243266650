import React from "react";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { Modal, notification, Icon, message } from "antd";
import { withRouter } from "react-router-dom";
import get from "lodash/fp/get";
import flow from "lodash/fp/flow";
import pick from "lodash/fp/pick";
import merge from "lodash/fp/merge";
import toString from "lodash/fp/toString";
import findIndex from "lodash/fp/findIndex";
import filter from "lodash/fp/filter";
import reduce from "lodash/fp/reduce";
import map from "lodash/fp/map";
import compact from "lodash/fp/compact";
import cloneDeep from "lodash/fp/cloneDeep";
import {
  CompanyByIdQuery,
  GSTR9AutoCalculateQuery,
} from "qgst-gql-queries/web/data.json";

import gspAuth from "../../services/GSPService";
import OTPForm from "../GSP/OTPForm";
import {
  formatNumber,
  formatAndSetTable4Data,
  countTotalTable4,
  crossTableCalculation,
  getDummyData,
} from "./GSTR9Utils";
import GSTR9Pt1 from "./GSTR9Pt1";
import GSTR9Pt2 from "./GSTR9Pt2";
import GSTR9Pt3 from "./GSTR9Pt3";
import GSTR9Pt4 from "./GSTR9Pt4";
import GSTR9Pt5 from "./GSTR9Pt5";
import GSTR9Pt6 from "./GSTR9Pt6";
import GSTR9Header from "./GSTR9Header";

const errorModal = Modal.error;

const GSTR9UI = (props) => {
  return (
    <div>
      <GSTR9Header {...props} />
      <GSTR9Pt1 {...props} />
      <GSTR9Pt2 className="gstr9Pt2" {...props} />
      <GSTR9Pt3 className="gstr9Pt3" {...props} />
      <GSTR9Pt4 className="gstr9Pt4" {...props} />
      <GSTR9Pt5 className="gstr9Pt5" {...props} />
      <GSTR9Pt6 className="gstr9Pt6" {...props} />
    </div>
  );
};
class GSTR9Container extends React.Component {
  constructor(props) {
    super(props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
      };
    } else {
      this.gsp = {};
    }
    this.state = {
      requestOTP: false,
      dataSource: null,
    };
    this.pullGSTRData = this.pullGSTRData.bind(this);
    this.txvalChange = this.txvalChange.bind(this);
    this.txtOnBlur = this.txtOnBlur.bind(this);
    this.addHSNInward = this.addHSNInward.bind(this);
    this.deleteHSNInward = this.deleteHSNInward.bind(this);
    this.changeHSNContent = this.changeHSNContent.bind(this);
    this.addOtherReversal = this.addOtherReversal.bind(this);
    this.deleteOtherReversal = this.deleteOtherReversal.bind(this);
    this.otherReversalDescription = this.otherReversalDescription.bind(this);
    this.showData = this.showData.bind(this);
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp =
        this.gsp.otp !== undefined && this.gsp.otp !== null
          ? this.gsp.otp
          : this.props.gspDetails.otp;
    }
    if (this.props.finYear !== prevProps.finYear) {
      this.setState({ dataSource: null });
    }
  };
  showData = async (e) => {
    message.destroy();

    if (!this.state.dataSource) {
      message.error("There is no data to save!!");
      return false;
    }
    message.loading("Saving your data to GSTN...", 0);
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    fy = "20" + year_trim[1];
    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    var year;
    year = fy;
    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    var reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
      year: year,
      body: this.formatGSTR9Data(this.state.dataSource, companyDetails),
    };

    const resp = await gspAuth.saveGSTR9(reqData);
    message.destroy();
    if (resp.data.success) {
      if (resp.retstatus.status_cd === "P") {
        message.success("Saved Successfully!!");
      } else {
        message.error("Save Failed");
      }
    } else {
      message.error("Save Failed");
    }
  };
  formatGSTR9Data = (reqData, companyDetails) => {
    var data = cloneDeep(reqData);
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    fy = "20" + year_trim[1];
    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    var year;
    year = fy;
    // const annual_ret_period = year === "2017" ? "032018" : "032019";
    const annual_ret_period = year ? "03" + year : "032019";
    data.gstin = companyDetails.gstin;
    data.fp = annual_ret_period;
    return flow(
      this.formatTable4Data,
      this.formatTable5Data,
      this.formatTable6Data,
      this.formatTable7Data,
      this.formatTable8Data,
      this.formatTable9Data,
      this.formatTable10Data,
      this.formatTable14Data,
      this.formatTable15Data,
      this.formatTable16Data,
      this.formatTable17Data,
      this.formatTable18Data,
      this.deleteUnNecessaryTableFields
    )(data);
  };
  deleteUnNecessaryTableFields = (data) => {
    delete data.tbl4;
    delete data.tbl5;
    delete data.tbl6;
    delete data.tbl7;
    delete data.tbl8;
    delete data.tbl9;
    delete data.tbl10;
    delete data.tbl14;
    delete data.tbl15;
    delete data.tbl16;
    delete data.tbl17;
    delete data.tbl18;
    return data;
  };
  formatTable4Data = (data) => {
    const table4 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.b2c = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "2":
          d.b2b = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "3":
          d.exp = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "4":
          d.sez = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "5":
          d.deemed = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "6":
          d.at = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "7":
          d.rchrg = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "9":
          d.cr_nt = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "10":
          d.dr_nt = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "11":
          d.amd_pos = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "12":
          d.amd_neg = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl4);
    data.table4 = table4;
    return data;
  };
  formatTable5Data = (data) => {
    const table5 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.zero_rtd = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "2":
          d.sez = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "3":
          d.rchrg = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "4":
          d.exmt = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "5":
          d.nil = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "6":
          d.non_gst = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "8":
          d.cr_nt = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "9":
          d.dr_nt = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "10":
          d.amd_pos = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "11":
          d.amd_neg = {
            txval: r.val ? r.val : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl5);
    data.table5 = table5;
    return data;
  };
  formatTable6Data = (data) => {
    const table6 = reduce((d, r) => {
      switch (r.key) {
        case "2":
          if (!d.supp_non_rchrg) d.supp_non_rchrg = [];
          d.supp_non_rchrg.push({
            itc_typ: "ip",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "3":
          if (!d.supp_non_rchrg) d.supp_non_rchrg = [];
          d.supp_non_rchrg.push({
            itc_typ: "cg",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "4":
          if (!d.supp_non_rchrg) d.supp_non_rchrg = [];
          d.supp_non_rchrg.push({
            itc_typ: "is",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "5":
          if (!d.supp_rchrg_unreg) d.supp_rchrg_unreg = [];
          d.supp_rchrg_unreg.push({
            itc_typ: "ip",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "6":
          if (!d.supp_rchrg_unreg) d.supp_rchrg_unreg = [];
          d.supp_rchrg_unreg.push({
            itc_typ: "cg",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "7":
          if (!d.supp_rchrg_unreg) d.supp_rchrg_unreg = [];
          d.supp_rchrg_unreg.push({
            itc_typ: "is",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "8":
          if (!d.supp_rchrg_reg) d.supp_rchrg_reg = [];
          d.supp_rchrg_reg.push({
            itc_typ: "ip",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "9":
          if (!d.supp_rchrg_reg) d.supp_rchrg_reg = [];
          d.supp_rchrg_reg.push({
            itc_typ: "cg",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "10":
          if (!d.supp_rchrg_reg) d.supp_rchrg_reg = [];
          d.supp_rchrg_reg.push({
            itc_typ: "is",
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "11":
          if (!d.iog) d.iog = [];
          d.iog.push({
            itc_typ: "ip",
            iamt: r.iamt ? r.iamt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "12":
          if (!d.iog) d.iog = [];
          d.iog.push({
            itc_typ: "cg",
            iamt: r.iamt ? r.iamt : 0,
            csamt: r.cess ? r.cess : 0,
          });
          break;
        case "13":
          d.ios = {
            iamt: r.iamt ? r.iamt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "14":
          d.isd = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "15":
          d.itc_clmd = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "18":
          d.tran1 = {
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
          };
          break;
        case "19":
          d.tran2 = {
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
          };
          break;
        case "20":
          d.other = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl6);
    data.table6 = table6;
    return data;
  };
  formatTable7Data = (data) => {
    const table7 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.rule37 = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "2":
          d.rule39 = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "3":
          d.rule42 = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "4":
          d.rule43 = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "5":
          d.sec17 = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "6":
          d.revsl_tran1 = {
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
          };
          break;
        case "7":
          d.revsl_tran2 = {
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
          };
          break;
        case "8":
          const other = flow(
            map((d) => {
              return {
                desc: d.det,
                iamt: d.iamt ? d.iamt : 0,
                camt: d.camt ? d.camt : 0,
                samt: d.samt ? d.samt : 0,
                csamt: d.cess ? d.cess : 0,
              };
            }),
            compact
          )(r.items);
          if (other.length > 0) d.other = other;
          break;
      }
      return d;
    }, {})(data.tbl7);
    data.table7 = table7;
    return data;
  };
  formatTable8Data = (data) => {
    const table8 = reduce((d, r) => {
      switch (r.key) {
        case "3":
          d.itc_inwd_supp = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "5":
          d.itc_nt_availd = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "6":
          d.itc_nt_eleg = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "7":
          d.iog_taxpaid = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl8);
    data.table8 = table8;
    return data;
  };
  formatTable9Data = (data) => {
    const table9 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.iamt = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "2":
          d.camt = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "3":
          d.samt = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "4":
          d.csamt = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "5":
          d.intr = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "6":
          d.fee = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "7":
          d.pen = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
        case "8":
          d.other = {
            txpyble: r.txpyble ? r.txpyble : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl9);
    data.table9 = table9;
    return data;
  };
  formatTable10Data = (data) => {
    const table10 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.dbn_amd = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "2":
          d.cdn_amd = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "3":
          d.itc_rvsl = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "4":
          d.itc_availd = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl10);
    data.table10 = table10;
    return data;
  };
  formatTable14Data = (data) => {
    const table14 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.iamt = {
            txpyble: r.txpyble ? r.txpyble : 0,
            txpaid: r.txpaid ? r.txpaid : 0,
          };
          break;
        case "2":
          d.camt = {
            txpyble: r.txpyble ? r.txpyble : 0,
            txpaid: r.txpaid ? r.txpaid : 0,
          };
          break;
        case "3":
          d.samt = {
            txpyble: r.txpyble ? r.txpyble : 0,
            txpaid: r.txpaid ? r.txpaid : 0,
          };
          break;
        case "4":
          d.csamt = {
            txpyble: r.txpyble ? r.txpyble : 0,
            txpaid: r.txpaid ? r.txpaid : 0,
          };
          break;
        case "5":
          d.intr = {
            txpyble: r.txpyble ? r.txpyble : 0,
            txpaid: r.txpaid ? r.txpaid : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl14);
    data.table14 = table14;
    return data;
  };
  formatTable15Data = (data) => {
    const table15 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.rfd_clmd = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "2":
          d.rfd_sanc = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "3":
          d.rfd_rejt = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "4":
          d.rfd_pend = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "5":
          d.tax_dmnd = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
            intr: r.int ? r.int : 0,
            pen: r.penalty ? r.penalty : 0,
            fee: r.late_fees ? r.late_fees : 0,
          };
          break;
        case "6":
          d.tax_paid = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
            intr: r.int ? r.int : 0,
            pen: r.penalty ? r.penalty : 0,
            fee: r.late_fees ? r.late_fees : 0,
          };
          break;
        case "7":
          d.dmnd_pend = {
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
            intr: r.int ? r.int : 0,
            pen: r.penalty ? r.penalty : 0,
            fee: r.late_fees ? r.late_fees : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl15);
    data.table15 = table15;
    return data;
  };
  formatTable16Data = (data) => {
    const table16 = reduce((d, r) => {
      switch (r.key) {
        case "1":
          d.comp_supp = {
            txval: r.val ? r.val : 0,
          };
          break;
        case "2":
          d.deemed_supp = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
        case "3":
          d.not_returned = {
            txval: r.val ? r.val : 0,
            iamt: r.iamt ? r.iamt : 0,
            camt: r.camt ? r.camt : 0,
            samt: r.samt ? r.samt : 0,
            csamt: r.cess ? r.cess : 0,
          };
          break;
      }
      return d;
    }, {})(data.tbl16);
    data.table16 = table16;
    return data;
  };
  formatTable17Data = (data) => {
    var table17 = {};
    const items = flow(
      map((d) => {
        if (d.hsn_sc === "") return false;
        return {
          hsn_sc: d.hsn_sc ? d.hsn_sc : "",
          uqc: d.uqc ? d.uqc : "",
          desc: d.desc ? d.desc : "",
          isconcesstional: d.isconcesstional === false ? "N" : "Y",
          qty: d.qty ? d.qty : 0,
          txval: d.txval ? d.txval : 0,
          rt: d.rt ? d.rt : 0,
          iamt: d.iamt ? d.iamt : 0,
          camt: d.camt ? d.camt : 0,
          samt: d.samt ? d.samt : 0,
          csamt: d.cess ? d.cess : 0,
        };
      }),
      compact
    )(data.tbl17);
    if (items.length > 0) {
      table17.items = items;
      data.table17 = table17;
    }
    return data;
  };
  formatTable18Data = (data) => {
    var table18 = {};
    const items = flow(
      map((d) => {
        if (d.hsn_sc === "") return false;
        return {
          hsn_sc: d.hsn_sc ? d.hsn_sc : "",
          uqc: d.uqc ? d.uqc : "",
          desc: d.desc ? d.desc : "",
          isconcesstional: d.isconcesstional === false ? "N" : "Y",
          qty: d.qty ? d.qty : 0,
          txval: d.txval ? d.txval : 0,
          rt: d.rt ? d.rt : 0,
          iamt: d.iamt ? d.iamt : 0,
          camt: d.camt ? d.camt : 0,
          samt: d.samt ? d.samt : 0,
          csamt: d.cess ? d.cess : 0,
        };
      }),
      compact
    )(data.tbl18);
    if (items.length > 0) {
      table18.items = items;
      data.table18 = table18;
    }
    return data;
  };
  otherGSTNInformation = (companyDetails) => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };
  pullGSTRData = async () => {
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    fy = "20" + year_trim[1];
    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    var year;
    year = fy;
    message.destroy();
    message.loading("Fetching GSTR9 Data from GSTN...", 0);
    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    var reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
      year: year,
    };
    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);
    message.destroy();
    reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.response.gspAuth.access_token,
        otp: this.gsp.response.gspAuth.otp,
      },
      period: period,
      year: year,
    };
    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        this.setState({ requestOTP: true });
      }
    } else if (!this.gsp.response.data.success) {
      errorModal({
        title: "Fetch Unsuccessful",
        content: (
          <p>"Unable to process your request, please try again later."</p>
        ),
        iconType: "close-circle",
      });
    }
    if (this.gsp.response.data.success || this.gsp.response.success) {
      message.loading("Fetching more information..");
      this.gsp.response = await gspAuth.getGSTR9AutoCalculatedValues(reqData);
      if (this.gsp.response.data) {
        if (this.gsp.response.data.success === false) {
          message.info(this.gsp.response.data.message);
          return false;
        }
      }
      this.gsp.gstr9Details = await gspAuth.getGSTR9SavedValues(reqData);

      const mergedData = merge(
        this.gsp.response.data.data,
        this.gsp.gstr9Details.data.data
      );
      const respTable = flow(
        formatAndSetTable4Data,
        countTotalTable4,
        crossTableCalculation
      )(mergedData);

      this.setState({ dataSource: respTable }, (value) => {
        message.success("Data Retrieved from GSTN");
      });
    }
  };
  addHSNInward = (e) => {
    const hsnInWardItem = {
      key: Math.random().toString(),
      hsn_sc: "",
      uqc: "",
      desc: "",
      isconcesstional: false,
      qty: 0,
      txval: 0,
      rt: 0,
      iamt: 0,
      camt: 0,
      samt: 0,
      cess: 0,
    };

    const id = e.target.id.split("-");
    var stateObject = this.state.dataSource;
    var hsnInwardRows = stateObject[id[3]];
    hsnInwardRows.push(hsnInWardItem);
    stateObject[id[3]] = hsnInwardRows;
    this.setState({ dataSource: stateObject });
  };
  deleteHSNInward = (e) => {
    const id = e.target.id.split("-");
    var stateObject = this.state.dataSource;
    var tbl = stateObject[id[3]];
    tbl = filter((item) => item.key !== id[1])(tbl);
    stateObject[id[3]] = tbl;
    this.setState({ dataSource: stateObject });
  };
  changeHSNContent = (e) => {
    const id = e.target.id.split("-");
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(e.target);
    var stateObject = this.state.dataSource;
    console.log(stateObject);
    var tbl = stateObject[id[3]];
    const index = findIndex({ key: id[1] })(tbl);
    if (
      id[2] === "hsn_sc" ||
      id[2] === "uqc" ||
      id[2] === "desc" ||
      e.target.type === "checkbox"
    ) {
      tbl[index][id[2]] = value;
    } else {
      tbl[index][id[2]] = parseFloat(parseFloat(value).toFixed(2));
    }
    const newDataSource = flow(
      countTotalTable4,
      crossTableCalculation
    )(stateObject);
    this.setState({ dataSource: newDataSource }, (value) => {});
  };
  addOtherReversal = (e) => {
    const otherReversalItem = {
      key: "h_" + Math.random().toString(),
      det: "Other Reversal",
      iamt: 0,
      camt: 0,
      samt: 0,
      cess: 0,
    };

    const id = e.target.id.split("-");
    var stateObject = this.state.dataSource;
    var tbl = stateObject[id[3]];
    const index = findIndex({ key: "8" })(tbl);
    tbl[index].items.push(otherReversalItem);
    stateObject[id[3]] = tbl;
    this.setState({ dataSource: stateObject });
  };
  deleteOtherReversal = (e) => {
    const id = e.target.id.split("-");
    var stateObject = this.state.dataSource;
    var tbl = stateObject[id[3]];
    const index = findIndex({ key: "8" })(tbl);
    tbl[index].items = filter((item) => item.key !== id[1])(tbl[index].items);
    stateObject[id[3]] = tbl;
    const newDataSource = flow(
      countTotalTable4,
      crossTableCalculation
    )(stateObject);
    this.setState({ dataSource: newDataSource }, (value) => {});
  };
  otherReversalDescription = (e) => {
    const id = e.target.id.split("-");
    const { value } = e.target;
    var stateObject = this.state.dataSource;
    var tbl = stateObject[id[3]];
    const index = findIndex({ key: "8" })(tbl);
    const itemIndex = findIndex({ key: id[1] })(tbl[index].items);
    if (itemIndex > -1) {
      if (id[1].toLowerCase() === "det") {
        tbl[index].items[itemIndex][id[2]] = value;
      } else {
        tbl[index].items[itemIndex][id[2]] = parseFloat(
          parseFloat(value).toFixed(2)
        );
      }
    }
    stateObject[id[3]] = tbl;
    const newDataSource = flow(
      countTotalTable4,
      crossTableCalculation
    )(stateObject);
    this.setState({ dataSource: newDataSource }, (value) => {});
  };
  txtOnBlur = (e) => {
    message.loading("calculating other fields..");
    const id = e.target.id.split("-");
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    console.log(e.target);
    var stateObject = this.state.dataSource;
    console.log(stateObject);
    var tbl = stateObject[id[3]];
    const index = findIndex({ key: id[1] })(tbl);
    if (e.target.type === "checkbox") {
      tbl[index][id[2]] = value;
    } else {
      tbl[index][id[2]] = parseFloat(parseFloat(value).toFixed(2));
    }
    const newDataSource = flow(
      countTotalTable4,
      crossTableCalculation
    )(stateObject);
    this.setState({ dataSource: newDataSource }, (value) => {
      message.destroy();
    });
  };
  txvalChange = (e) => {
    const { value } = e.target;
    if (
      (e.keyCode > 47 && e.keyCode < 58) ||
      (e.keyCode > 95 && e.keyCode < 106)
    ) {
      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if (
        (!Number.isNaN(value) && reg.test(value)) ||
        value === "" /* || value === '-'*/
      ) {
        const val = value.split(".");
        if (val[1] !== undefined) {
          if (val[1].length > 1) {
            e.preventDefault();
          }
        }
      } else {
        e.preventDefault();
      }
    } else if (
      e.keyCode === 8 ||
      e.keyCode === 9 ||
      e.keyCode === 18 ||
      e.keyCode === 46 ||
      e.keyCode === 190 ||
      e.keyCode === 35 ||
      e.keyCode === 36 ||
      e.keyCode === 37 ||
      e.keyCode === 39 ||
      e.keyCode === 16 ||
      e.keyCode === 110
    ) {
    } else {
      e.preventDefault();
    }
  };
  render() {
    const pt1 = {
      dataSource: [
        {
          key: "1",
          sno: "1",
          det: "Financial Year",
          value: this.props.finYear,
        },
        {
          key: "2",
          sno: "2",
          det: "GSTIN",
          value: this.props.company && this.props.company.gstin,
        },
        {
          key: "3",
          sno: "3A",
          det: "Legal Name",
          value: this.props.company && this.props.company.name,
        },
        {
          key: "4",
          sno: "3B",
          det: "Trade Name (if any)",
          value: "",
        },
      ],
    };

    const pt2sec4 = {
      dataSource: get("dataSource.tbl4", this.state),
    };
    const pt2sec5 = {
      dataSource: get("dataSource.tbl5", this.state),
    };
    const pt3sec6 = {
      dataSource: get("dataSource.tbl6", this.state),
    };
    const pt3sec7 = {
      dataSource: get("dataSource.tbl7", this.state),
      otherReversalCount: get("dataSource.tbl7", this.state),
    };
    const pt3sec8 = {
      dataSource: get("dataSource.tbl8", this.state),
    };
    const pt4sec9 = {
      dataSource: get("dataSource.tbl9", this.state),
    };
    const pt5sec10 = {
      dataSource: get("dataSource.tbl10", this.state),
    };
    const pt5sec14 = {
      dataSource: get("dataSource.tbl14", this.state),
    };
    const pt6sec15 = {
      dataSource: get("dataSource.tbl15", this.state),
    };
    const pt6sec16 = {
      dataSource: get("dataSource.tbl16", this.state),
    };
    const pt6sec17 = {
      dataSource: get("dataSource.tbl17", this.state),
    };
    const pt6sec18 = {
      dataSource: get("dataSource.tbl18", this.state),
    };

    return (
      <div>
        <GSTR9UI
          pullGSTRData={this.pullGSTRData}
          {...this.props}
          formatNumber={formatNumber}
          pt1={pt1}
          pt2sec4={pt2sec4}
          pt2sec5={pt2sec5}
          pt3sec6={pt3sec6}
          pt3sec7={pt3sec7}
          pt3sec8={pt3sec8}
          pt4sec9={pt4sec9}
          pt5sec10={pt5sec10}
          pt5sec14={pt5sec14}
          pt6sec15={pt6sec15}
          pt6sec16={pt6sec16}
          pt6sec17={pt6sec17}
          pt6sec18={pt6sec18}
          txvalChange={this.txvalChange}
          txtOnBlur={this.txtOnBlur}
          deleteHSNInward={this.deleteHSNInward}
          addHSNInward={this.addHSNInward}
          changeHSNContent={this.changeHSNContent}
          deleteOtherReversal={this.deleteOtherReversal}
          addOtherReversal={this.addOtherReversal}
          otherReversalDescription={this.otherReversalDescription}
          showData={this.showData}
        />
        <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
  handleOTPSubmit = (val) => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.pullGSTRData();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };
  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      requestOTP: false,
    });
  };
}
const mapStateToProps = (state) => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finToMonth: state.local.finToMonth,
    finYear: state.local.finYear,
  };
};
const companyQueryConfig = {
  name: "company",
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get("viewer.company", company),
      gspDetails: get("viewer.gspInfo", company),
    };
  },
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(GSTR9Container);
