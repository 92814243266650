/**
 * @flow
 */
import React from "react";
import Glamorous from "glamorous";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import { Redirect, Link } from "react-router-dom";

import { ROUTES, i18n } from "../../shared/constants";
import Auth from "../../services/AuthService";

const B = Glamorous(Button)({
  width: "100%"
});

const Register = props => (
  <Form onSubmit={props.register}>
    <Form.Item>
      {props.getFieldDecorator("email", {
        rules: [
          { required: true, message: i18n.en.register.email_required_message }
        ]
      })(<Input placeholder={i18n.en.register.email_placeholder_message} />)}
    </Form.Item>
    <Form.Item>
      {props.getFieldDecorator("password", {
        rules: [
          {
            required: true,
            message: i18n.en.register.password_required_message
          }
        ]
      })(
        <Input
          placeholder={i18n.en.register.password_placeholder_message}
          type="password"
        />
      )}
    </Form.Item>
    <Form.Item>
      {props.getFieldDecorator("coupon_code", {
        rules: [
          { max: 10, message: 'Please enter a valid referral code' }
        ]
      })(
        <Input
          placeholder={i18n.en.register.referral_code_message}
          type="text"
        />
      )}
    </Form.Item>
    <B type="primary" size="large" htmlType="submit">
      {i18n.en.register.register_button_text}
    </B>
    <p style={{ paddingTop: "20px", textAlign:"center" }}>
      {i18n.en.common.space}
      {i18n.en.common.or}
      {i18n.en.common.space}{" "}
    </p>
    <p style={{ paddingTop: "20px", textAlign: "center" }}>
      <Link
        to={ROUTES.LOGIN}
        className="ant-btn ant-btn-primary ant-btn-lg"
        style={{ width: "80%" }}
      >
        {i18n.en.login.login_button_text}
      </Link>
    </p>
  </Form>
);

const ValidateOTP = props => (
  <Form onSubmit={props.validateOTP}>
    <Form.Item>
      {props.getFieldDecorator("otp", {
        rules: [{ required: true, message: "Please input your otp!" }]
      })(<Input placeholder="Enter the received OTP" />)}
    </Form.Item>
    <B type="primary" size="large" htmlType="submit">
      Confirm OTP
    </B>
    <p style={{ paddingTop: "20px", textAlign: "center" }}>
      <Link style={{color: '#108ee9'}}
        to={ROUTES.LOGIN}
      >
        {i18n.en.login.login_button_text}
      </Link>
    </p>

  </Form>
);

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToDashboard: Auth.isLoggedIn(),
      redirectToLogin: false,
      redirectToOTP: false,
      userid: null
    };
  }
  register = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      const result = await Auth.register(values);
      if (result.success) {
        message.success(i18n.en.register.registration_success_message);
        const forUser = await Auth.sendOTP({
          email: values.email
        });
        message.info("An OTP has been sent to " + values.email, 30);
        this.setState({
          redirectToOTP: true,
          userid: forUser
        });
      } else {
        message.error(result.error.message);
      }
    });
  };
  validateOTP = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) return;
      values.userid = this.state.userid;
      const result = await Auth.validateOTP(values);
      console.log(result);
      if (result.success) {
        message.destroy();
        message.success("OTP Verified Successfully!!");
        console.log("success");
        this.setState({
          redirectToOTP: false,
          redirectToLogin: true
        });
      } else if (result.message === "Incorrect OTP!") {
        message.error("Incorrect OTP!!!");
      }
    });
  };
  render() {
    if (this.state.redirectToDashboard) {
      return <Redirect to={ROUTES.DASHBOARD} />;
    } else if (this.state.redirectToLogin) {
      return <Redirect to={ROUTES.LOGIN} />;
    } else if (this.state.redirectToOTP) {
      return (
        <ValidateOTP
          getFieldDecorator={this.props.form.getFieldDecorator}
          validateOTP={this.validateOTP}
        />
      );
    } else {
      return (
        <Register
          register={this.register}
          getFieldDecorator={this.props.form.getFieldDecorator}
        />
      );
    }
  }
}
export default Form.create()(LoginContainer);
