import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { Layout, Menu, Icon, Badge } from "antd";
import HeaderLayout from "./Header";
import SelectionBox from "./Header/selectionBox";
import SelectCompanyError from "./SelectCompanyError";
import Dashboard from "../Dashboard";
import Company from "../Company";
import Supplier from "../Supplier";
import Customer from "../Customer";
import SalesDashboard from "../Sales/index";
import Anx1Dashboard from "../ANX1/Anx1Dashboard";
import Anx2Dashboard from "../ANX2/Anx2Dashboard";
import Anx1A_Dashboard from '../ANX1_Amended/Anx1A_Dashboard';
import GSTR3B from "../GSTR3B";
import GSTR9 from "../GSTR9";
/*import GSTR9C from "../GSTR9C";*/
import GSTR1 from "../GSTR1";
import GSTR2 from "../GSTR2";
import InsightsITC from "../Insights/InsightsDashboardITC";
import InsightsSALES from "../Insights";
import B2BInvoice from "../Sales/B2BSales";
// import RET1_3A from '../RET1/RET1_3A';
import RET1 from '../RET1';
import CDNRInvoice from "../Sales/CDNRSales";
import B2CLInvoice from "../Sales/B2CLSales";
import B2CSInvoice from "../Sales/B2CSSales";
import CDNURInvoice from "../Sales/CDNURSales";
import ATInvoice from "../Sales/ATSales";
import ATADJInvoice from "../Sales/ATADJSales";
import B2BAInvoice from "../Sales/B2BASales";
import CDNRAInvoice from "../Sales/CDNRASales";
import B2CLAInvoice from "../Sales/B2CLASales";
import B2CSAInvoice from "../Sales/B2CSASales";
import CDNURAInvoice from "../Sales/CDNURASales";
import ATAInvoice from "../Sales/ATASales";
import ATADJAInvoice from "../Sales/ATADJASales";
import ExportsAInvoice from "../Sales/EXPORTASales";
import ExportsInvoice from "../Sales/EXPORTSales";
import HSNInvoice from "../Sales/HSNSales";
import EXEMPInvoice from "../Sales/NILSales";
import DOCInvoice from "../Sales/DOCSales";
import PurchaseDashboard from "../Purchases/Index";
import B2BPurchase from "../Purchases/B2BPurchase";
import B2BURPurchase from "../Purchases/B2BURPurchase";
import CDNRPurchase from "../Purchases/CDNRPurchase";
import CDNURPurchase from "../Purchases/CDNURPurchase";
import EXEMPPurchase from "../Purchases/EXEMPPurchase";
import ATPurchase from "../Purchases/ATPurchase";
import ATADJPurchase from "../Purchases/ATADJPurchase";
import ITCPurchase from "../Purchases/ITCPurchase";
import ISDPurchase from "../Purchases/ISDPurchase";
import IMPGPurchase from "../Purchases/IMPGPurchase";
import IMPSPurchase from "../Purchases/IMPSPurchase";
import HSNPurchase from "../Purchases/HSNPurchase";
import ReconcileDashboard from "../Reconcile/Index";
import ITC_Corner from "../ITC_Corner/ITC_UI";
import TG_Utility_UI from "../TG_Utilities/TG_Utility_UI";
import GetRegular from "../GSTN_Sync/Regular";
import SFT_UI from "../Supplier_Filing_Trends";
import CONTACTS from "../Contact"
import GetAmended from "../GSTN_Sync/Amended";
import FetchArchives from "../GSTN_Sync/FetchArchives";
import ITC04 from "../ITC04/Itc04";
import { ROUTES } from "../../shared/constants";
import { PurchaseAnalytics, SalesAnalytics } from "../Analytics";
import Purxl from "../Xltool/Purxl";
import ITC_UI_2B from "../ITC_Corner/ITC_UI_2B";

const { Header, Content, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const center = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginTop: "5px",
  backgroundColor: "#f0f8ff"
};

const CoreLayout = props => {
  // console.log("--------------------selected company-----------------");
  // console.log(props.sub_type);
  // console.log();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {!props.isMobile && (
        <Sider
          theme="light"
          collapsible
          collapsed={props.collapsed}
          onCollapse={props.onCollapse}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0
          }}
          width={250}
        >
          {props.collapsed ? (
            <h2 style={center}>
              <b>TG</b>
            </h2>
          ) : (
            <h2 style={center}>
              <b>ElixirGST</b>
              <h6>1.0.0</h6>
            </h2>
          )}
          <Menu
            mode="inline"
            defaultselectedkeys={["d"]}
            defaultOpenKeys={["gstfiling", "sales", "purchase"]}
          >
            <Menu.Item key="1">
              <Icon type="schedule" />
              <span>Dashboard</span>
              <Link to={ROUTES.DASHBOARD} />
            </Menu.Item>
            <Menu.Item key={ROUTES.COMPANY} className="tour_company">
              <Icon type="database" />
              <span>Company</span>
              <Link to={ROUTES.COMPANY} />
            </Menu.Item>
            {props.sub_type !== 2 && (
              <SubMenu
                key="gstfiling"
                title={
                  <span>
                    <Icon type="appstore" />
                    <span>GST Filings</span>
                  </span>
                }
              >
                {/* <Menu.Item
                  key={ROUTES.ANX1_RET}
                >
                  <Icon type="tags" />
                  <span>RETURN-1</span>
                  <Link to={ROUTES.ANX1_RET} />
                </Menu.Item> */}

                <Menu.Item key={ROUTES.GST_FILINGS_GSTR1} className="tour_gstr1">
                  <Icon type="tags-o" />
                  <span>GSTR-1</span>
                  <Link to={ROUTES.GST_FILINGS_GSTR1} />
                </Menu.Item>
                <Menu.Item
                  key={ROUTES.GST_FILINGS_GSTR3B}
                  className="tour_gstr3b"
                >
                  <Icon type="tags" />
                  <span>GSTR-3B</span>
                  <Link to={ROUTES.GST_FILINGS_GSTR3B} />
                </Menu.Item>
                <Menu.Item key={ROUTES.GST_FILINGS_GSTR9}>
                  <Icon type="tags" />
                  <span>GSTR-9</span>
                  <Link to={ROUTES.GST_FILINGS_GSTR9} />
                </Menu.Item>
                {/* <Menu.Item
                key={ROUTES.GST_FILINGS_GSTR9C}
              >
                <Icon type="tags" />
                <span>GSTR-9C</span>
                <Link to={ROUTES.GST_FILINGS_GSTR9C} />
              </Menu.Item> */}
              </SubMenu>)}
              <SubMenu
                key="itccorner"
                title={
                  <span>
                    <Icon type="appstore" />
                    <span>ITC Corner</span>
                  </span>
                }
              >
            <Menu.Item key={ROUTES.ITC} className="itccorner">
              <Icon type="calculator" />
              <span>ITC 2A Corner</span>
              <Link to={ROUTES.ITC} />
            </Menu.Item>
            <Menu.Item key={ROUTES.ITC_2B} className="itccorner">
              <Icon type="calculator" />
              <Badge count={"NEW"} offset={[30, 7]}>
              <span>ITC 2B Corner</span>
              </Badge>
              <Link to={ROUTES.ITC_2B} />
            </Menu.Item>
            </SubMenu>
           {/* {props.sub_type !== 2 && (
              <Menu.Item
                key={ROUTES.ANX1_DASHBOARD}
                className="tour_upload_sales"
              >
                <Icon type="book" />
                <span>Anx1</span>
                <Link to={ROUTES.ANX1_DASHBOARD} />
              </Menu.Item>
            )}

            {props.sub_type !== 2 && (
              <Menu.Item
                key={ROUTES.ANX1_A_DASHBOARD}
                className="tour_upload_sales"
              >
                <Icon type="book" />
                <span>Anx1-Amended</span>
                <Link to={ROUTES.ANX1_A_DASHBOARD} />
              </Menu.Item>
            )}

            {props.sub_type !== 2 && (
              <Menu.Item
                key={ROUTES.ANX2_DASHBOARD}
                className="tour_upload_sales"
              >
                <Icon type="book" />
                <span>Anx2</span>
                <Link to={ROUTES.ANX2_DASHBOARD} />
              </Menu.Item>
            )} */}
            {props.sub_type !== 2 && (
              <SubMenu
                key="sales"
                title={
                  <span>
                    <Icon type="book" />
                    <span>Sales Register</span>
                  </span>
                }
              >
                <Menu.Item
                  key={ROUTES.SALES_DASHBOARD}
                  className="tour_upload_sales"
                >
                  <Icon type="cloud-upload" />
                  <span>Upload Sales</span>
                  <Link to={ROUTES.SALES_DASHBOARD} />
                </Menu.Item>
                <SubMenu
                  key="sales_regular"
                  title={
                    <span className="tour_sales_regular">
                      <Icon type="check-circle-o" />
                      <span>Regular</span>
                    </span>
                  }
                >
                  <Menu.Item key={ROUTES.SALES_B2B}>
                    <Icon type="file-add" />
                    <span>B2B Invoices</span>
                    <Link to={ROUTES.SALES_B2B} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_B2C}>
                    <Icon type="file-add" />
                    <span>B2CL Invoices</span>
                    <Link to={ROUTES.SALES_B2C} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_B2CS}>
                    <Icon type="file-add" />
                    <span>B2CS Invoices</span>
                    <Link to={ROUTES.SALES_B2CS} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_CDNR}>
                    <Icon type="file-add" />
                    <span>CDNR</span>
                    <Link to={ROUTES.SALES_CDNR} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_CDNUR}>
                    <Icon type="file-add" />
                    <span>CDNUR</span>
                    <Link to={ROUTES.SALES_CDNUR} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_EXPORTS}>
                    <Icon type="file-add" />
                    <span>Exports</span>
                    <Link to={ROUTES.SALES_EXPORTS} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_AT}>
                    <Icon type="file-add" />
                    <span>Advanced Received</span>
                    <Link to={ROUTES.SALES_AT} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_ATADJ}>
                    <Icon type="file-add" />
                    <span>Advanced Adjusted</span>
                    <Link to={ROUTES.SALES_ATADJ} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_HSN}>
                    <Icon type="file-add" />
                    <span>HSN Summary</span>
                    <Link to={ROUTES.SALES_HSN} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_EXEMP}>
                    <Icon type="file-add" />
                    <span>Exempt/Nil/Non-GST</span>
                    <Link to={ROUTES.SALES_EXEMP} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_DOC}>
                    <Icon type="file-add" />
                    <span>DOCS</span>
                    <Link to={ROUTES.SALES_DOC} />
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sales_amended"
                  title={
                    <span className="tour_sales_amended">
                      <Icon type="check-circle-o" />
                      <span>Amended</span>
                    </span>
                  }
                >
                  <Menu.Item key={ROUTES.SALES_B2BA}>
                    <Icon type="file-add" />
                    <span>B2B-A Invoices</span>
                    <Link to={ROUTES.SALES_B2BA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_B2CA}>
                    <Icon type="file-add" />
                    <span>B2CL-A Invoices</span>
                    <Link to={ROUTES.SALES_B2CA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_B2CSA}>
                    <Icon type="file-add" />
                    <span>B2CS-A Invoices</span>
                    <Link to={ROUTES.SALES_B2CSA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_CDNRA}>
                    <Icon type="file-add" />
                    <span>CDNR-A</span>
                    <Link to={ROUTES.SALES_CDNRA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_CDNURA}>
                    <Icon type="file-add" />
                    <span>CDNUR-A</span>
                    <Link to={ROUTES.SALES_CDNURA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_EXPORTSA}>
                    <Icon type="file-add" />
                    <span>Exports-A</span>
                    <Link to={ROUTES.SALES_EXPORTSA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_ATA}>
                    <Icon type="file-add" />
                    <span>Advanced Received-A</span>
                    <Link to={ROUTES.SALES_ATA} />
                  </Menu.Item>
                  <Menu.Item key={ROUTES.SALES_ATADJA}>
                    <Icon type="file-add" />
                    <span>Advanced Adjusted-A</span>
                    <Link to={ROUTES.SALES_ATADJA} />
                  </Menu.Item>
                </SubMenu>
              </SubMenu>
            )}
            <SubMenu
              key="purchase"
              title={
                <span>
                  <Icon type="book" />
                  <span>Purchase Register</span>
                </span>
              }
            >
              <Menu.Item
                key={ROUTES.PURCHASE_DASHBOARD}
                className="tour_upload_purchase"
              >
                <Icon type="cloud-upload" />
                <span>Upload Purchase</span>
                <Link to={ROUTES.PURCHASE_DASHBOARD} />
              </Menu.Item>
              <Menu.Item key="excel">
              <Icon type="tool" />
              <Badge count={"NEW"} offset={[30, 7]}>
              <span>Tool</span>
              </Badge>
              <Link to={ROUTES.XL_PURCHASE} />
             
            </Menu.Item>
              <SubMenu
                key="purchase_regular"
                title={
                  <span className="tour_sales_regular">
                    <Icon type="check-circle-o" />
                    <span>Regular</span>
                  </span>
                }
              >
                <Menu.Item key={ROUTES.PURCHASE_B2B}>
                  <Icon type="file-add" />
                  <span>B2B Purchase</span>
                  <Link to={ROUTES.PURCHASE_B2B} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_B2BUR}>
                  <Icon type="file-add" />
                  <span>B2BUR Purchase</span>
                  <Link to={ROUTES.PURCHASE_B2BUR} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_CDNR}>
                  <Icon type="file-add" />
                  <span>CDNR Purchase</span>
                  <Link to={ROUTES.PURCHASE_CDNR} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_CDNUR}>
                  <Icon type="file-add" />
                  <span>CDNUR Purchase</span>
                  <Link to={ROUTES.PURCHASE_CDNUR} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_EXEMP}>
                  <Icon type="file-add" />
                  <span>EXEMP Purchase</span>
                  <Link to={ROUTES.PURCHASE_EXEMP} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_AT}>
                  <Icon type="file-add" />
                  <span>AT Purchase</span>
                  <Link to={ROUTES.PURCHASE_AT} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_ATADJ}>
                  <Icon type="file-add" />
                  <span>ATADJ Purchase</span>
                  <Link to={ROUTES.PURCHASE_ATADJ} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_ITC}>
                  <Icon type="file-add" />
                  <span>ITC Purchase</span>
                  <Link to={ROUTES.PURCHASE_ITC} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_ISD}>
                  <Icon type="file-add" />
                  <span>ISD Purchase</span>
                  <Link to={ROUTES.PURCHASE_ISD} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_IMPG}>
                  <Icon type="file-add" />
                  <span>IMPG Purchase</span>
                  <Link to={ROUTES.PURCHASE_IMPG} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_IMPS}>
                  <Icon type="file-add" />
                  <span>IMPS Purchase</span>
                  <Link to={ROUTES.PURCHASE_IMPS} />
                </Menu.Item>
                <Menu.Item key={ROUTES.PURCHASE_HSN}>
                  <Icon type="file-add" />
                  <span>HSN Purchase</span>
                  <Link to={ROUTES.PURCHASE_HSN} />
                </Menu.Item>
              </SubMenu>
            </SubMenu>
            {/* <Menu.Item key={ROUTES.ITC04} className="sft">
              <Icon type="team" />
              <span>ITC04 Job Worker</span>
              <Link to={ROUTES.ITC04} />
            </Menu.Item> */}
            <Menu.Item key={ROUTES.SUPPLIER_FILING_TRENDS} className="sft">
              <Icon type="team" />
              <span>Return Filing Trends</span>
              <Link to={ROUTES.SUPPLIER_FILING_TRENDS} />
            </Menu.Item>
            {/* <Menu.Item key={ROUTES.CONTACT_DETAILS} className="sft">
              <Icon type="phone" />
              <span>Contacts</span>
              <Link to={ROUTES.CONTACT_DETAILS} />
            </Menu.Item> */}
            <Menu.Item key={ROUTES.TG_UTILITIES_LEDGERS} className="ledgers">
              <Icon type="profile" />
              <span>Ledgers</span>
              <Link to={ROUTES.TG_UTILITIES_LEDGERS} />
            </Menu.Item>
            {props.sub_type !== 2 && props.selectedCompany === "f1a79e7c-96fb-4405-bb38-7389d7e46a1a" && (
              <SubMenu
                key="analytics"
                title={
                  <span>
                    <Icon type="line-chart" />
                    <span>Analytics</span>
                  </span>
                }
              >
                <Menu.Item key={ROUTES.ANALYTICS_SALES}>
                  <Icon type="schedule" />
                  <span>Sales</span>
                  <Link to={ROUTES.ANALYTICS_SALES} />
                </Menu.Item>
                <Menu.Item key={ROUTES.ANALYTICS_PURCHASE}>
                  <Icon type="schedule" />
                  <span>Purchase</span>
                  <Link to={ROUTES.ANALYTICS_PURCHASE} />
                </Menu.Item>
              </SubMenu>)}

            <Menu.Item key="downloads">
              <Icon type="profile" />
              <span>Download Templates</span>
              <a
                href="https://drive.google.com/file/d/11oyH4x2ynKmY5kWP4lc0x6FrRDLUgDcR/view?usp=sharing"
                target="_blank"
              />
            </Menu.Item>
          </Menu>
        </Sider>
      )}
      <Layout
        style={props.collapsed ? { marginLeft: 80 } : { marginLeft: 250 }}
      >
        <HeaderLayout isMobile={props.isMobile} />
        <div style={{ padding: "20px" }}>
          <SelectionBox />
          <Content>
            <Route path={ROUTES.COMPANY} component={Company} />
            <Route
              path={ROUTES.DASHBOARD}
              component={props.selectedCompany ? Dashboard : SelectCompanyError}
            />
            <Route
              path={ROUTES.ANALYTICS_SALES}
              component={props.selectedCompany ? SalesAnalytics : SelectCompanyError}
            />
            <Route
              path={ROUTES.ANALYTICS_PURCHASE}
              component={props.selectedCompany ? PurchaseAnalytics : SelectCompanyError}
            />
            <Route
              path={ROUTES.ITC04}
              component={props.selectedCompany ? ITC04 : SelectCompanyError}
            />
            <Route
              path={ROUTES.GST_FILINGS_GSTR3B}
              component={props.selectedCompany ? GSTR3B : SelectCompanyError}
            />
            <Route
              path={ROUTES.GST_FILINGS_GSTR9}
              component={props.selectedCompany ? GSTR9 : SelectCompanyError}
            />
                        <Route
              path={ROUTES.XL_PURCHASE}
              component={props.selectedCompany ? Purxl : SelectCompanyError}
            />
            {/* <Route
              path={ROUTES.GST_FILINGS_GSTR9C}
              component={props.selectedCompany ? GSTR9C : SelectCompanyError}
            /> */}
            <Route
              path={ROUTES.ITC}
              component={
                props.selectedCompany ? ITC_Corner : SelectCompanyError
              }
            />
                        <Route
              path={ROUTES.ITC_2B}
              component={
                props.selectedCompany ? ITC_UI_2B : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.TG_UTILITIES_LEDGERS}
              component={
                props.selectedCompany ? TG_Utility_UI : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.GSTN_SYNC_B2B}
              component={
                props.selectedCompany ? GetRegular : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SUPPLIER_FILING_TRENDS}
              component={props.selectedCompany ? SFT_UI : SelectCompanyError}
            />
            <Route
              path={ROUTES.CONTACTS}
              component={props.selectedCompany ? CONTACTS : SelectCompanyError}
            />
            <Route
              path={ROUTES.GSTN_SYNC_B2CL}
              component={
                props.selectedCompany ? GetAmended : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.GSTN_SYNC_FETCH_ALL}
              component={
                props.selectedCompany ? FetchArchives : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.GST_FILINGS_GSTR1}
              component={props.selectedCompany ? GSTR1 : SelectCompanyError}
            />
            <Route
              path={ROUTES.GST_FILINGS_GSTR2}
              component={props.selectedCompany ? GSTR2 : SelectCompanyError}
            />
            <Route
              path={ROUTES.INSIGHTS_SALES}
              component={
                props.selectedCompany ? InsightsSALES : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.INSIGHTS_ITC}
              component={
                props.selectedCompany ? InsightsITC : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SUPPLIER}
              component={props.selectedCompany ? Supplier : SelectCompanyError}
            />
            <Route
              path={ROUTES.CUSTOMER}
              component={props.selectedCompany ? Customer : SelectCompanyError}
            />
            <Route
              path={ROUTES.SALES_DASHBOARD}
              component={
                props.selectedCompany
                  ? () => <SalesDashboard isSR={true} />
                  : SelectCompanyError
              }
            />

            <Route
              path={ROUTES.SALES_B2BA}
              component={
                props.selectedCompany
                  ? () => <B2BAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_B2CA}
              component={
                props.selectedCompany
                  ? () => <B2CLAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_B2CSA}
              component={
                props.selectedCompany
                  ? () => <B2CSAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_CDNRA}
              component={
                props.selectedCompany
                  ? () => <CDNRAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_CDNURA}
              component={
                props.selectedCompany
                  ? () => <CDNURAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_ATA}
              component={
                props.selectedCompany
                  ? () => <ATAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_ATADJA}
              component={
                props.selectedCompany
                  ? () => <ATADJAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_EXPORTSA}
              component={
                props.selectedCompany
                  ? () => <ExportsAInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_B2B}
              component={
                props.selectedCompany
                  ? () => <B2BInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.ANX1_DASHBOARD}
              component={
                props.selectedCompany
                  ? () => <Anx1Dashboard isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.ANX2_DASHBOARD}
              component={
                props.selectedCompany
                  ? () => <Anx2Dashboard />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.ANX1_A_DASHBOARD}
              component={
                props.selectedCompany
                  ? () => <Anx1A_Dashboard />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_B2C}
              component={
                props.selectedCompany
                  ? () => <B2CLInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_B2CS}
              component={
                props.selectedCompany
                  ? () => <B2CSInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_CDNR}
              component={
                props.selectedCompany
                  ? () => <CDNRInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_CDNUR}
              component={
                props.selectedCompany
                  ? () => <CDNURInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_HSN}
              component={
                props.selectedCompany
                  ? () => <HSNInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_AT}
              component={
                props.selectedCompany
                  ? () => <ATInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_ATADJ}
              component={
                props.selectedCompany
                  ? () => <ATADJInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_EXPORTS}
              component={
                props.selectedCompany
                  ? () => <ExportsInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_EXEMP}
              component={
                props.selectedCompany
                  ? () => <EXEMPInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.SALES_DOC}
              component={
                props.selectedCompany
                  ? () => <DOCInvoice isSR={true} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_DASHBOARD}
              component={
                props.selectedCompany
                  ? () => <PurchaseDashboard isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_B2B}
              component={
                props.selectedCompany
                  ? () => <B2BPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_B2BUR}
              component={
                props.selectedCompany
                  ? () => <B2BURPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_CDNR}
              component={
                props.selectedCompany
                  ? () => <CDNRPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_CDNUR}
              component={
                props.selectedCompany
                  ? () => <CDNURPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_EXEMP}
              component={
                props.selectedCompany
                  ? () => <EXEMPPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_AT}
              component={
                props.selectedCompany
                  ? () => <ATPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_ATADJ}
              component={
                props.selectedCompany
                  ? () => <ATADJPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_ITC}
              component={
                props.selectedCompany
                  ? () => <ITCPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_ISD}
              component={
                props.selectedCompany
                  ? () => <ISDPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_IMPG}
              component={
                props.selectedCompany
                  ? () => <IMPGPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_IMPS}
              component={
                props.selectedCompany
                  ? () => <IMPSPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.PURCHASE_HSN}
              component={
                props.selectedCompany
                  ? () => <HSNPurchase isSR={false} />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.RECONCILE}
              component={
                props.selectedCompany
                  ? () => <ReconcileDashboard />
                  : SelectCompanyError
              }
            />
            <Route
              path={ROUTES.ANX1_RET}
              component={props.selectedCompany ? RET1 : SelectCompanyError}
            />
          </Content>
        </div>
      </Layout>
    </Layout>
  );
};

class CoreLayoutContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.document.body.clientWidth < 769,
      collapsed: false
    };
  }

  onCollapse = collapsed => {
    console.log(collapsed);

    this.setState({ collapsed });
  };

  // handleFinMonthChange = monthVal => {};

  render() {
    console.log("---render---");
    console.log(this.props);
    return (
      <CoreLayout
        isMobile={this.state.isMobile}
        selectedCompany={this.props.selectedCompany}
        finMonth={this.props.finMonth}
        finToMonth={this.props.finToMonth}
        finYear={this.props.finYear}
        sub_type={this.props.sub_type}
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        handleFinMonthChange={this.handleFinMonthChange}
        handleFinYearChange={this.handleFinYearChange}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finToMonth: state.local.finToMonth,
    finYear: state.local.finYear,
    sub_type: state.local.sub_type
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps)
)(CoreLayoutContainer);
