/* eslint-disable */
import { Col, Empty, Row } from 'antd';
import get from 'lodash/fp/get';
import { Analytics_PR_CDNR_PurchaseQuery } from 'qgst-gql-queries/web/data.json';
import React, { useEffect, useState } from 'react';
import { compose, gql, graphql } from 'react-apollo';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';

const Category_CDNR = props => {

    const { selectedDates, selectedRankRange, setSelectedRankRange, setPurchaseVolume, pr_cdnr_master_data } = props;
    const [filteredPurchaseVolume, setFilteredPurchaseVolume] = useState([]);

    useEffect(() => {
        if (pr_cdnr_master_data) {
            const PR_CDNR_Master_Data = {};
            const tempPurchaseVolumeArr = [];
            pr_cdnr_master_data?.forEach(tempPR_CDNR_Master_Data => {
                const finMonth = parseInt(tempPR_CDNR_Master_Data?.finmonth);
                const finYear = parseInt(finMonth) >= 12 ? parseInt(tempPR_CDNR_Master_Data?.finyear?.split("-")?.[1]) : parseInt(tempPR_CDNR_Master_Data?.finyear?.split("-")?.[0]);
                const startMonth = parseInt(selectedDates?.[0]?.format('M'));
                const startYear = parseInt(selectedDates?.[0]?.format('YYYY'));
                const endMonth = parseInt(selectedDates?.[1]?.format('M'));
                const endYear = parseInt(selectedDates?.[1]?.format('YYYY'));
                if (((finMonth >= startMonth && finYear == startYear) || (finYear > startYear)) && ((finMonth <= endMonth && finYear == endYear) || (finYear < endYear))) {
                    if (PR_CDNR_Master_Data[tempPR_CDNR_Master_Data?.supplier_gstin]) {
                        PR_CDNR_Master_Data[tempPR_CDNR_Master_Data?.supplier_gstin] = {
                            supplierName: tempPR_CDNR_Master_Data?.supplier_name,
                            noOfInvoices: PR_CDNR_Master_Data[tempPR_CDNR_Master_Data?.supplier_gstin]?.noOfInvoices + 1,
                            invoiceValue: PR_CDNR_Master_Data[tempPR_CDNR_Master_Data?.supplier_gstin]?.invoiceValue + parseFloat(tempPR_CDNR_Master_Data?.invoice_value),
                        };
                    } else {
                        PR_CDNR_Master_Data[tempPR_CDNR_Master_Data?.supplier_gstin] = {
                            supplierName: tempPR_CDNR_Master_Data?.supplier_name,
                            noOfInvoices: 1,
                            invoiceValue: parseFloat(tempPR_CDNR_Master_Data?.invoice_value)
                        };
                    }
                }
            });
            if (PR_CDNR_Master_Data) {
                // Preparing tempPurchaseVolumeArr
                Object.keys(PR_CDNR_Master_Data).some((key, index) => {
                    tempPurchaseVolumeArr.push({
                        gstin: key,
                        invoiceValue: PR_CDNR_Master_Data[key]?.invoiceValue,
                        customData: [PR_CDNR_Master_Data[key]?.supplierName !== "..." ? PR_CDNR_Master_Data[key]?.supplierName : "Not Available", PR_CDNR_Master_Data[key]?.noOfInvoices]
                    });
                });
                // Sorting tempPurchaseVolumeArr based on Invoice Value
                tempPurchaseVolumeArr.sort((a, b) => b.invoiceValue - a.invoiceValue);
                setPurchaseVolume(tempPurchaseVolumeArr);
                // Determining Start and End values from selected Rank Range
                const start = selectedRankRange ? selectedRankRange.split(" - ")[0] : "1";
                const end = selectedRankRange ? selectedRankRange.split(" - ")[1] : "10";
                // Filtering elements from array based on start and end values
                setFilteredPurchaseVolume(tempPurchaseVolumeArr.filter((data, index) => index >= start - 1 && index < end).sort((a, b) => a.invoiceValue - b.invoiceValue));
            }
        } else {
            setPurchaseVolume([]);
            setFilteredPurchaseVolume([]);
        }
    }, [selectedDates, selectedRankRange]);

    useEffect(() => {
        if (!selectedRankRange && filteredPurchaseVolume?.length > 0) {
            setSelectedRankRange("1 - 10")
        }
        if (filteredPurchaseVolume?.length == 0) {
            setSelectedRankRange(undefined);
        }
    }, [filteredPurchaseVolume]);

    console.log('Category_CDNR - filteredPurchaseVolume: ', filteredPurchaseVolume);

    var data = [
        {
            x: filteredPurchaseVolume?.map(data => data?.invoiceValue),
            y: filteredPurchaseVolume?.map(data => data?.gstin),
            width: 1,
            type: 'bar',
            orientation: 'h',
            customdata: filteredPurchaseVolume?.map(data => data?.customData),
            hovertemplate: "Supplier Name: %{customdata[0]} <br> Number of Invoices: %{customdata[1]} <br> Invoice Value: %{x}<extra></extra>",
            marker: {
                color: 'rgb(248, 131, 121)',
                line: {
                    color: 'rgb(0, 0, 0)',
                    width: 2
                }
            },
            textfont: {
                family: 'Lato',
                color: 'white',
                size: 16
            },
            hoverlabel: {
                bgcolor: 'black',
                bordercolor: 'black',
                font: {
                    family: 'Lato',
                    color: 'white',
                    size: 16
                }
            }
        }
    ];

    const layout = {
        xaxis: {
            title: 'Total Invoice Amount (In Rupees)',
            titlefont: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f'
            },
            automargin: true
        },
        yaxis: {
            title: 'Supplier GSTIN',
            titlefont: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f'
            },
            automargin: true
        },
        bargap: 1
    };

    if (filteredPurchaseVolume?.length > 0) {
        return (
            <Row justify="space-around" style={{ height: "calc(100vh - 350px)" }}>
                <Col span={24} style={{ marginLeft: "20%", marginRight: "20%" }}>
                    <Plot
                        data={data}
                        graphDiv="graph"
                        layout={layout}
                    />
                </Col>
            </Row>
        )
    } else {
        return (
            <Row justify="space-around" style={{ height: "calc(100vh - 350px)", marginTop: "calc(35vh - 175px)" }}>
                <Col span={24}>
                    <Empty
                        imageStyle={{
                            height: "20vh",
                        }}
                        description={
                            <span>
                                No Purchase Record found
                            </span>
                        }
                    />
                </Col>
            </Row>
        )
    }
}

const queryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            type: 'prcdnr'
        },
    }),
    props: ({ data }) => {
        return {
            pr_cdnr_master_data: get('viewer.analytics_pr_cdnr_masters.edges', data),
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany
    }
}

export default compose(
    connect(mapStateToProps),
    graphql(gql(Analytics_PR_CDNR_PurchaseQuery), queryConfig)
)(Category_CDNR)