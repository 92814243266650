import React from "react";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Icon from "antd/lib/icon";
import Table from "antd/lib/table";
import Row from "antd/lib/row";
import Input from "antd/lib/input";
import Popconfirm from "antd/lib/popconfirm";
import find from "lodash/fp/find";
import moment from "moment";
import { gql, graphql, compose } from "react-apollo";
import get from "lodash/fp/get";
import {
  RECON_PR_CDNA_PurchaseQuery,
  CompanyByIdQuery,
} from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import Column from "antd/lib/table/Column";
import ColumnGroup from "antd/lib/table/ColumnGroup";
const scroll = { x: 1600 };

const ViewCDNASupplierData = (props) => {
  const maxHeight = Math.floor((window.innerHeight - 250) / 83);
  return (
    <Table
      dataSource={props.cdna_data}
      bordered
      // rowSelection={props.props.rowSelection}
      pagination={{
        className: "my-pagination",
        defaultPageSize: maxHeight,
      }}
      title={() => "CDNA"}
      footer={() => (
        <div>
          <Button
            icon="download"
            style={{
              backgroundColor: "#2382c7",
              color: "white",
            }}
          onClick={() => props.fetchSupplierNamesForViewSupplierData()}
          >
            Fetch missing Supplier Name(s)
          </Button>
        </div>
      )}
    >
      <Column title="GSTIN" dataIndex="receiver_gstin"></Column>
      <Column
        title="Supplier Name"
        dataIndex="supplier_name"
        className="alignCenter"
        key="supplier_name"
      />
      <ColumnGroup title="Original Details">
        <Column title="Note Number" dataIndex="og_note_num"></Column>
        <Column title="Note Date" dataIndex="og_note_dt"></Column>
      </ColumnGroup>
      <ColumnGroup title="Revised Details">
        <Column title="Note Type" dataIndex="note_type"></Column>
        <Column title="Note Number" dataIndex="note_num"></Column>
        <Column title="Note Date" dataIndex="note_dt"></Column>
        <Column title="Invoice Number" dataIndex="invoice_num"></Column>
        <Column title="Invoice Date" dataIndex="invoice_dt"></Column>
        <Column title="Note Value" dataIndex="note_val"></Column>
      </ColumnGroup>

      <Column
        title="Items"
        dataIndex="invoice_num"
        className="alignCenter"
        width="130"
        rowSpan="2"
        render={(text, record) => {
          return (
            <div>
              <Button
                icon="bars"
                type="primary"
                onClick={() => props.showModal(record.id)}
              >
                {" "}
                Items
              </Button>
              <Modal
                title="List Items Summary - GST System Invoices (CDNA)"
                visible={props.visibleModal === record.id}
                onCancel={props.hideModal}
                width="920"
                footer={null}
                scroll={scroll}
              >
                <Table
                  dataSource={record.recon_pr_cdna_items}
                  pagination={false}
                >
                  <Column
                    title="Rate"
                    dataIndex="gst_rate"
                    className="alignCenter"
                  />
                  <Column
                    title="Taxable Value"
                    dataIndex="taxable_value"
                    className="alignCenter"
                  />
                  <Column
                    title="IGST"
                    dataIndex="igst"
                    className="alignCenter"
                    render={(text) => {
                      return text === null ? "- - -" : text;
                    }}
                  />
                  <Column
                    title="CGST"
                    dataIndex="cgst"
                    className="alignCenter"
                    render={(text) => {
                      return text === null ? "- - -" : text;
                    }}
                  />
                  <Column
                    title="SGST"
                    dataIndex="sgst"
                    className="alignCenter"
                    render={(text) => {
                      return text === null ? "- - -" : text;
                    }}
                  />
                  <Column
                    title="CESS"
                    dataIndex="cess"
                    className="alignCenter"
                    render={(text) => {
                      return text === null ? "- - -" : text;
                    }}
                  />
                </Table>
              </Modal>
            </div>
          );
        }}
      ></Column>
    </Table>
  );
};
class ViewCDNASupplierDataContainer extends React.Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year = curyear + "-" + nextyear;
    this.state = {
      visibleModal: null,
      finMonth: n + 1,
      finYear: year,
      curFinMonth: n + 1,
      curFinYear: year,
      visible: false,
      requestOTP: false,
      otp: null,
      period: null,
      selectedRowKeys: [],
    };
  }

  showModal = async (id) => {
    this.setState({ visibleModal: id });
  };
  hideModal = () => {
    this.setState({ visibleModal: null });
  };
  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All Invoices",
          onSelect: () => {
            this.setState({
              selectedRowKeys: get("viewer.recon_pr_cdna_masters.edges")(
                this.props.data
              ).map((a) => a.id),
            });
          },
        },
      ],
    };
    return (
      <ViewCDNASupplierData
      {...this.props}
        rowSelection={rowSelection}
        showModal={this.showModal}
        hideModal={this.hideModal}
        visibleModal={this.state.visibleModal}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
  };
};

export default compose(
  connect(mapStateToProps),
)(ViewCDNASupplierDataContainer);
