import React from 'react'

import Alert from 'antd/lib/alert'
import Popover from 'antd/lib/popover'
import Icon from 'antd/lib/icon'
import Badge from 'antd/lib/badge'

import G from 'glamorous'
import { gql, graphql } from 'react-apollo'
import { UserNotificationsQuery } from 'qgst-gql-queries/web/data.json'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import filter from 'lodash/fp/filter'

import { IconWrapperDiv } from './Icon'

const GAlert = G(Alert)({
  marginBottom: 16,
})

const Content = ({ notifications }) =>
  <div>
    {map(n =>
      <GAlert key={n.id} message={n.message} type="success" showIcon />
    )(notifications)}
  </div>

const Notifications = props =>
  <Popover
    content={<Content notifications={props.notifications} />}
    trigger="click"
    placement="bottomLeft"
  >
    <IconWrapperDiv>
      <Badge count={props.unReadCount}>
        <Icon type="mail" />
      </Badge>
    </IconWrapperDiv>
  </Popover>

const queryConfig = {
  options: { variables: {} },
  props: ({ data }) => {
    const notifications = get('viewer.notifications.edges', data)
    const unReadCount = filter(n => !n.isRead)(notifications).length
    return {
      notifications,
      unReadCount,
    }
  },
}
export default graphql(gql(UserNotificationsQuery), queryConfig)(Notifications)
