import React from 'react'
import Form from 'antd/lib/form'

function FormItem({ children, label }) {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  }
  return (
    <Form.Item label={label} {...formItemLayout}>
      {children}
    </Form.Item>
  )
}

export default FormItem
