import { gql, graphql, compose } from 'react-apollo'
import {
  ContactCreateMutation,
  ContactsDetails,
} from 'qgst-gql-queries/web/data.json'

import Upload_Contacts from './Upload_Contacts'

const config = {
  props: ownProps => {
    return {
      mutate: (values) => {
        return ownProps.mutate({
          variables: values,
          refetchQueries: [
            {
              query: gql(ContactsDetails),
            },
          ],
        })
      }
    }
  }
}

export default compose(
  graphql(gql(ContactCreateMutation), config)
)(Upload_Contacts)