import React from "react";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";
import moment from "moment";

import find from "lodash/fp/find";
import { getPlaceOfSupply } from "../../shared/constants";

const { Column } = Table;
const scroll = { x: 1600 };

class ReconcileIneligibleDetails extends React.Component {
  render() {
    const props = this.props;
    const maxHeight = Math.floor((window.innerHeight - 250) / 83);
    return (
      <Modal
        title="Ineligible Transactions (B2B)"
        visible={props.visibleIneligibleModal}
        onCancel={props.hideIneligibleModal}
        footer={null}
        width="96%"
      >
        <Table
          dataSource={props.dataset_popup_details}
          bordered
          rowSelection={props.rowSelection}
          pagination={{
            className: "my-pagination",
            defaultPageSize: maxHeight,
          }}
          rowKey="id"
          title={() => (
            <span style={{ marginLeft: 8 }}>
              {props.hasSelected
                ? `Selected ${props.selectedRowKeys.length} Invoices`
                : ""}
            </span>
          )}
          footer={() => (
            <div>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#2382c7",
                  color: "white",
                }}
                onClick={() => props.fetchMissingNames(this.props.m_id)}
              >
                Fetch missing Supplier Name(s)
              </Button>
              <Button
                icon="close-circle"
                style={{
                  backgroundColor: "#3f5a0a",
                  color: "white",
                }}
                onClick={() => props.markAsEligible()}
              >
                Mark as Eligible and Reconcile
              </Button>
            </div>
          )}
        >
          <Column
            title="GSTIN"
            dataIndex="supplier_gstin"
            className="alignCenter"
            key="supplier_gstin"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div className="custom-filter-dropdown">
                <Input
                  placeholder="Search GSTIN Number"
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={props.handleSearch(selectedKeys, confirm)}
                />
                <Button
                  type="primary"
                  onClick={props.handleSearch(selectedKeys, confirm)}
                >
                  Search
                </Button>
                <Button
                  id="ineligibleSearch_recon_b2b"
                  onClick={props.handleReset(clearFilters)}
                >
                  Reset
                </Button>
              </div>
            )}
            filterIcon={(filtered) => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa",
                }}
              />
            )}
            onFilter={(value, record) =>
              record.supplier_gstin.toLowerCase().includes(value.toLowerCase())
            }
            render={(text) => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />
          <Column
            title="Supplier Name"
            dataIndex="supplier_name"
            className="alignCenter"
            key="supplier_name"
          />
          <Column
            title="Invoice Number"
            dataIndex="invoice_number"
            className="alignCenter"
            key="invoice_number"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <div className="custom-filter-dropdown">
                <Input
                  placeholder="Search Invoice Number"
                  value={selectedKeys[0]}
                  onChange={(e) =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={props.handleSearch(selectedKeys, confirm)}
                />
                <Button
                  type="primary"
                  onClick={props.handleSearch(selectedKeys, confirm)}
                >
                  Search
                </Button>
                <Button
                  id="ineligibleInvoiceSearch_b2b"
                  onClick={props.handleReset(clearFilters)}
                >
                  Reset
                </Button>
              </div>
            )}
            filterIcon={(filtered) => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa",
                }}
              />
            )}
            onFilter={(value, record) =>
              record.invoice_number.toLowerCase().includes(value.toLowerCase())
            }
            render={(text) => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />

          <Column
            title="Invoice Date"
            dataIndex="invoice_date"
            className="alignCenter"
            key="invoice_date"
            width="120"
            defaultSortOrder="descend"
            sorter={(a, b) => props.sortDate(a.invoice_date, b.invoice_date)}
            render={(text) =>
              text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
            }
          />
          <Column
            title="Invoice Value"
            dataIndex="invoice_value"
            className="alignCenter"
            key="invoice_value"
            width="130"
            render={(val) => (
              <p>{props.formatNumber(val === null ? 0 : val)}</p>
            )}
          />
          <Column
            title="Invoice Type"
            dataIndex="invoice_type"
            className="alignCenter"
            key="invoice_type"
          />
          <Column
            title="POS"
            dataIndex="pos_id"
            key="pos_id"
            className="alignCenter"
            render={(pos) =>
              pos
                ? pos.length > 2
                  ? pos
                  : getPlaceOfSupply(pos)
                : null
            }
          />
          <Column
            title="Reverse Charge"
            dataIndex="reverse_charge"
            className="alignCenter"
            key="reverse_charge"
          />

          <Column
            title="Items"
            key="action"
            className="alignCenter"
            width="130"
            rowSpan="2"
            render={(text, record, index) => {
              var but = (
                <Row gutter={24}>
                  <Button
                    icon="bars"
                    type="primary"
                    onClick={() => props.showItemModal(record)}
                  >
                    Items
                  </Button>
                  <Modal
                    title="List Items Summary - Ineligible Invoices (B2B)"
                    visible={
                      props.visibleItemModal === record.id ? true : false
                    }
                    onCancel={props.hideItemModal}
                    footer={null}
                    width="920"
                  >
                    <Table
                      dataSource={
                        record.ineligible_invoice_items
                          ? record.ineligible_invoice_items
                          : record.pr_b2b_items
                          ? record.pr_b2b_items
                          : record.recon_pr_b2b_items
                      }
                      bordered
                      loading={props.loading}
                      scroll={scroll}
                      pagination={false}
                    >
                      <Column
                        title="Rate"
                        dataIndex="gst_rate"
                        className="alignCenter"
                        key="gst_rate"
                        render={(val) => parseFloat(val).toFixed(2)}
                      />
                      <Column
                        title="Taxable Value"
                        className="alignCenter"
                        dataIndex="taxable_value"
                        key="taxable_value"
                        render={(val) => (
                          <p>
                            {val === null
                              ? " - - - "
                              : props.formatNumber(val === null ? 0 : val)}
                          </p>
                        )}
                      />
                      <Column
                        title="IGST"
                        dataIndex="igst"
                        className="alignCenter"
                        key="igst"
                        render={(val) => (
                          <p>
                            {val === null
                              ? " - - - "
                              : props.formatNumber(val === null ? 0 : val)}
                          </p>
                        )}
                      />
                      <Column
                        title="CGST"
                        dataIndex="cgst"
                        className="alignCenter"
                        key="cgst"
                        render={(val) => (
                          <p>
                            {val === null
                              ? " - - - "
                              : props.formatNumber(val === null ? 0 : val)}
                          </p>
                        )}
                      />
                      <Column
                        title="SGST"
                        dataIndex="sgst"
                        className="alignCenter"
                        key="sgst"
                        render={(val) => (
                          <p>
                            {val === null
                              ? " - - - "
                              : props.formatNumber(val === null ? 0 : val)}
                          </p>
                        )}
                      />
                      <Column
                        title="Cess"
                        dataIndex="cess"
                        className="alignCenter"
                        key="cess"
                        render={(val) => (
                          <p>
                            {val === null
                              ? " - - - "
                              : props.formatNumber(val === null ? 0 : val)}
                          </p>
                        )}
                      />
                    </Table>
                  </Modal>
                </Row>
              );
              return but;
            }}
          />
        </Table>
      </Modal>
    );
  }
}
export default ReconcileIneligibleDetails;
