import React from "react";
import { Row, Col, DatePicker, Table, Button, Modal, notification, Icon, message, Card } from "antd";
import get from "lodash/fp/get";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { CompanyByIdQuery, ITC_comparison_Query, ITC_comparison_Query_Excel } from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import moment from "moment";
import toString from "lodash/fp/toString";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import range from "lodash/fp/range";
import pick from "lodash/fp/pick";
import { formatNumber, BASE_URL } from "../../shared/constants";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import excelService from "../../services/excelUtilityService";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import gspAuth from '../../services/GSPService'
const { Column } = Table;
const { MonthPicker } = DatePicker;
const card_styles = {
  width: 180
};
const errorModal = Modal.error;

class TG_Utility_UI extends React.Component {
  constructor(props){
    super(props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null
      };
    } else {
      this.gsp = {};
    }
    this.pullLedgerData = this.pullLedgerData.bind(this);
    
  }


  componentDidUpdate = () => {
    // console.log(this.props);
    // console.log(this.prevProps);
    // console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };



  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.pullLedgerData();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visible: false,
    downloadLoading: false,
    requestOTP: false,
    otp: null,
    period: null,
    cash_bal: null,
    itc_bal: null
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  showModal = () => {
    console.log("1");
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  pullLedgerData = async () => {
    message.destroy();
    message.loading('Pulling from GSTN...',0);
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    console.log(period);

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      period: period,
    };
    // console.log(reqData);
   this.gsp.response = await gspAuth.generateLedgerBAL(reqData);
   // console.log(this.gsp.response);

   message.destroy();
   if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
     this.setState({ requestOTP: true });
   }}else if(!this.gsp.response.data.success){
     errorModal({
       title: 'Fetch Unsuccessful',
       content: <p>"Unable to process your request, please try again later."</p>,
       iconType: "close-circle",
     });
   }
   if(this.gsp.response.data.success || this.gsp.response.success)
     {
      console.log(this.gsp.response);

      this.setState({ 
        cash_bal: this.gsp.response && this.gsp.response.data && this.gsp.response.data.cash_bal,
        itc_bal: this.gsp.response && this.gsp.response.data && this.gsp.response.data.itc_bal
      });

       notification.destroy();
       notification.open({
         message: 'Success',
         description: "Pulled Ledger Data Successfully!",
         duration: 0,
         icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
       });
     }
  }

  handleCancel = e => {
    // console.log(e);
    this.setState({
      requestOTP: false
    });
  };


  disabledDate = (current) => {
    // Can not select days before today and today
    return (current && current < moment("30-06-2017","DD-MM-YYYY").endOf('day')) || (current && current > moment().endOf('day'));
  }

  

  render() {
    
    const maxWidth = window.innerWidth - 215 + "px";

    return (
      <div style={{ maxWidth: maxWidth }}>
        <Root>
          <Content>
          <Button icon="sync" style={{ backgroundColor: '#2382c7', width: '250px', color: 'white' }} onClick={this.pullLedgerData} disabled={this.props.company && this.props.company.role === 'reviewer'}>
          Pull Ledger Details
          </Button>
          <div style={{ background: '#dbe1ec', padding: '30px', marginTop: '20px' }}>
            <p style={{ color: '#00000099', fontSize: '20px' }}>Electronic Cash Ledger</p>
            <br />
            
            <Row gutter={16}>
              <Col span={6}>
                <Card title="IGST - Total Balance" bordered={false} >
                  {(this.state.cash_bal ? formatNumber(this.state.cash_bal.igst_tot_bal) : 'Pull to see balance')}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="CGST - Total Balance" bordered={false}>
                  {(this.state.cash_bal ? formatNumber(this.state.cash_bal.cgst_tot_bal) : 'Pull to see balance')}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="SGST - Total Balance" bordered={false}>
                  {(this.state.cash_bal ? formatNumber(this.state.cash_bal.sgst_tot_bal) : 'Pull to see balance')}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="CESS - Total Balance" bordered={false}>
                  {(this.state.cash_bal ? formatNumber(this.state.cash_bal.cess_tot_bal) : 'Pull to see balance')}
                </Card>
              </Col>
            </Row>
          </div>
          <div style={{ background: '#dbe1ec', padding: '30px', marginTop: '20px' }}>
          <p style={{ color: '00000099', fontSize: '20px' }}>Electronic ITC Ledger</p>
            <br />
            
            <Row gutter={16}>
              <Col span={6}>
                <Card title="IGST - Total Balance" bordered={false} >
                  {(this.state.cash_bal ? formatNumber(this.state.itc_bal.igst_bal) : 'Pull to see balance')}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="CGST - Total Balance" bordered={false}>
                  {(this.state.cash_bal ? formatNumber(this.state.itc_bal.cgst_bal) : 'Pull to see balance')}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="SGST - Total Balance" bordered={false}>
                  {(this.state.cash_bal ? formatNumber(this.state.itc_bal.sgst_bal) : 'Pull to see balance')}
                </Card>
              </Col>
              <Col span={6}>
                <Card title="CESS - Total Balance" bordered={false}>
                  {(this.state.cash_bal ? formatNumber(this.state.itc_bal.cess_bal) : 'Pull to see balance')}
                </Card>
              </Col>
            </Row>
          </div>
            
            <OTPForm
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.requestOTP}
              onSubmit={this.handleOTPSubmit}
              onCancel={this.handleCancel}
            />
          </Content>
        </Root>
      </div>
    );
  }
}


const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(TG_Utility_UI)

