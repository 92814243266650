import { Steps, Button, message, Input, Card, Table, Select, Radio } from 'antd';
import React from 'react';
import { sc } from './util/gstin_util';
import * as XLSX from 'xlsx';
import { connect } from "react-redux";


const Step = Steps.Step;
const {Option} = Select;

const steps = [
  {
    title: 'GSTIN',
  }, {
  title: 'Upload',
}, {
  title: 'Review',
},
{
  title : "Download"
}];

class Purxl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      jsonData: [],
      datas: [],
      trans : [],
      istable : false,
      gstin : this.props.CompanyGSTIN,
      current : 0,
      rc: "N",
      ei : "Inputs",
      it : "Regular",
      isModalOpen: false,
      totalInvoice: 0,
      totalTv : 0,
      totalIv : 0,
      totalIgst : 0,
      totalCgst : 0,
      totalSgst : 0,
      type : 1,
    };
    this.handleFile = this.handleFile.bind(this)
    this.convert = this.convert.bind(this)
  }
  handleFile(e) {
    this.setState({file : null})
    const file = e.target.files[0];
    const reader = new FileReader();
    const fileName = file.name; // Get the file name
      const fileExtension = fileName.split('.').pop();
if(fileExtension.toLowerCase() == "xlsx" || "xlsm")
{
  message.success(file.name+" Uploaded")
  this.setState({
    isModalOpen : true
  })
}
else
{
  message.error("Upload Xecel File with xlsx")
}
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      let jsonData = XLSX.utils.sheet_to_json(sheet);
      jsonData.forEach((val,ind)=>{
        jsonData = this.fsum(jsonData,ind,val,"5%")
        jsonData = this.fsum(jsonData,ind,val,"12%")
        jsonData = this.fsum(jsonData,ind,val,"18%")
        jsonData = this.fsum(jsonData,ind,val,"28%")
      })
      this.setState({goldenCopy : jsonData})
      jsonData.forEach((val,ind)=>{
        jsonData = this.rmvGt(val,ind,jsonData)
      })
      //PLACE TO DESIDE CASE1 OR CASE2
      jsonData.forEach((val,ind)=>{
        jsonData = this.makeDub(val,ind,jsonData)
      })



      this.setState({jsonData});

    };
    reader.readAsBinaryString(file);

  }
rmvGt(val,ind,jsonData)
{
  const valuesArray = Object.values(val);
  const keysArray = Object.values(val)
  valuesArray.forEach((v,i)=>{
    if(v)
{
     if(((String(v)?.toLowerCase()?.trim()?.indexOf("grandtotal") !=-1)||(String(v)?.toLowerCase()?.trim()?.indexOf("total") !=-1))&&((String(keysArray[i])?.toLowerCase()?.trim()?.indexOf("grandtotal") !=-1)||(String(keysArray[i])?.toLowerCase()?.trim()?.indexOf("total") !=-1)))
 {
  jsonData = jsonData.slice(0, ind).concat(jsonData.slice(ind + 1));
 }
}
  })
  return jsonData;
}


makeDub(val,ind,jsonData)
{
let temp = []
  const keysArray = Object.keys(val);
  keysArray.forEach((v)=>{
    temp.push(v.match(/\d+/g))
  })
  const filteredArray = temp.filter((value) => value !== null);
  let LenArray = filteredArray.flat().filter(item => (item == 5 )||  (item == 12) ||  (item == 18) ||  (item == 28));
  LenArray = [...new Set(LenArray)]
  if((LenArray.length-1))
  {
let keyArr = Object.keys(val)
let valArr = Object.values(val)
let obj = {}
LenArray.forEach((v,i)=>{
  const isCharacterPresent = keyArr.find(str => str.includes(v));
  if(isCharacterPresent.trim()??false)
  {
    let keyind = keyArr.indexOf(isCharacterPresent);
    valArr = valArr.slice(0, keyind).concat(valArr.slice(keyind + 1));;
    keyArr = keyArr.filter(item => item !== isCharacterPresent);
     obj = keyArr.reduce((acc, key, index) => {
      acc[key] = valArr[index];
      return acc;
  }, {});
  }
  //DFEV STARTS
  for(let i in obj)
  {
      if((i?.toLowerCase()?.trim()?.indexOf("18%") !=-1)&&(i?.toLowerCase()?.trim()?.indexOf("gst") !=-1))
      {
       jsonData[ind][i] = this.state.goldenCopy[ind][i]
      }  
      if((i?.toLowerCase()?.trim()?.indexOf("18%") !=-1)&&(i?.toLowerCase()?.trim()?.indexOf("^") !=-1))
      {
       delete jsonData[ind][i]
      }  
  }

  //DEV ENDS
  if(this.getRate(obj,ind)??false)
  {
    jsonData = [...jsonData.slice(0, ind + 1), obj, ...jsonData.slice(ind + 1)]
  }
})
  }

return jsonData;
}

  fsum(jsonData,ind,val,code)
  {
    let temp = []
    for(let i in val)
{
 if((i?.toLowerCase()?.indexOf(code) !=-1) && ((i?.toLowerCase()?.indexOf("cgst") !=-1)||i?.toLowerCase()?.indexOf("sgst") !=-1)||i?.toLowerCase()?.indexOf("igst") !=-1)
 {
  temp.push(i)
 }
}
let sum18 = 0
temp.forEach((i)=>{
sum18 = val[i]+sum18
})
jsonData[ind][temp[0]]=sum18
return jsonData
  }


getIgst(gstnum,r,v)
{
  if(!  (this.state.gstin.slice(0,2) == gstnum?.slice(0,2)))
  {
    // if(!isNaN(tv))
    // {return ((r/100)*tv).toFixed(2)}
    return this.getByIgstRate(r,v,'igst')
  }
}

getCgst(gstnum,tv,r,v)
{
  if(!(this.state.gstin.slice(0,2) == gstnum?.slice(0,2)))
  {
    return ""
  }
  else
  {
    let s = r/2;
    if(!isNaN(tv))
    // {return ((s/100)*tv).toFixed(2)}
  {
    return this.getByCodeRate(r,v,'cgst',tv,s)
    // return parseFloat(((s/100)*tv).toFixed(2))
  }
  }
}

getByCodeRate(r,v,code,tv,s)
{
for(let i in v)
{

 if(this.state.type == 1)
{  if(i.includes(r))
  {
    return parseFloat(((s/100)*tv).toFixed(2));
  }
  }

  if(this.state.type == 2)
   {
    if( i.toLowerCase().includes(`${code}-${r/2}%`))
  {
    if(r/2 == 2.5)
    {console.log(`${code}-${r/2}%`,v[i])}
    return v[i]
  }
}
}
}

getByIgstRate(r,v,code)
{
for(let i in v)
{
    if( i.toLowerCase().includes(`${code}-${r}%`))
  {
    console.log("IGST",v[i])
    return v[i]
  }
}
}

getSgst(gstnum,tv,r,v)
{
  if(!(this.state.gstin.slice(0,2) == gstnum?.slice(0,2)))
  {
    return ""
  }
  else
  {
    let s = r/2
    if(!isNaN(tv))
    {return this.getByCodeRate(r,v,'sgst',tv,s) }
  }
}

gstSc()
{
for(let i in sc)
{
  if(i == this.state.gstin.slice(0,2))
  {
    return `${this.state.gstin.slice(0,2)} - ${sc[i]}`
  }
}
}

gd(date)
{
  const dates = new Date(null);
  dates.setMonth(XLSX.SSF.parse_date_code(date).m-1)
  return `${XLSX.SSF.parse_date_code(date).d}-${dates.toLocaleString('default', { month: 'long' }).slice(0,3)}-${String(XLSX.SSF.parse_date_code(date).y).slice(-2)}`
}
fix(val)
{
  return val?.toFixed(2)
}
getGstin(val)
{
for(let i in val)
{
  if((i?.toLowerCase()?.trim()?.indexOf("gstin") !=-1)||(i?.toLowerCase()?.trim()?.indexOf("uin") !=-1))
  {
    return val[i]
  }
}
}

getRate(val,ind)
{
  for(let i in val)
  {
    if((i?.toLowerCase()?.indexOf("@") !=-1)||(i?.toLowerCase()?.indexOf("%") !=-1) )
    {
      if((i?.toLowerCase()?.indexOf("5") !=-1)||(i?.toLowerCase()?.indexOf("12") !=-1)||(i?.toLowerCase()?.indexOf("18") !=-1)||(i?.toLowerCase()?.indexOf("28") !=-1))
      {return parseFloat(i.match(/\d+/)[0]);}
    }
  }
}
getTv(val,r)
{
  for(let i in val)
  {
    if(i?.toLowerCase()?.indexOf(r) !=-1 && !(i?.toLowerCase()?.indexOf("^") !=-1))
    {
      return parseFloat(val[i]);
    }
  }
}

getProf(val,r)
{
  for(let i in val)
  {
    // console.log(i)
    if((i?.toLowerCase()?.indexOf(`${r}^%`)!=-1))
    {
      console.log("PROF",val[i])
      return val[i];
    }
  }
}

getIndate(val)
{
  for(let i in val)
  {
    if((i?.toLowerCase()?.trim()?.indexOf("invoice") !=-1)&&(i?.toLowerCase()?.trim()?.indexOf("date") !=-1))
    {
      return parseFloat(val[i]);
    }
    else if((i?.toLowerCase()?.trim()?.indexOf("date") !=-1))
    {
      return parseFloat(val[i]);
    }
  }
}
getGross(val)
{
  for(let i in val)
  {
    if((i?.toLowerCase()?.trim()?.indexOf("gross") !=-1)&&(i?.toLowerCase()?.trim()?.indexOf("total") !=-1))
    {
      return parseFloat(val[i]);
    }
  }
}

getInvoice(val)
{
  for(let i in val)
  {
    if(((i?.toLowerCase()?.trim()?.indexOf("invoice") !=-1) || (i?.toLowerCase()?.trim()?.indexOf("voucher") !=-1))&&(i?.toLowerCase()?.trim()?.indexOf("no") !=-1) )
    {
      return val[i];
    }
  }
}

getSup(val)
{
  for(let i in val)
  {
    if(((i?.toLowerCase()?.trim()?.indexOf("supplier") !=-1) || (i?.toLowerCase()?.trim()?.indexOf("particular") !=-1)))
    {
      return val[i];
    }
  }
}

chkRate(rate)
{
if((rate==5)||(rate == 12)||(rate == 18)||(rate == 28))
{
return true
}
}

chkCtp(a,b,r)
{
if(r/2==2.5)
{
  return a-b
}
else
{
  return a;
}
}

convert()
{
  var tarray = []
console.log(this.state.jsonData)
const uniqueObjects = new Set(this.state.jsonData.map(obj => JSON.stringify(obj)));
let jsonData = Array.from(uniqueObjects, JSON.parse)
  jsonData.forEach((val,ind)=>{
      if((this.getGstin(val)??false))
{  
  if(this.chkRate(this.getRate(val))??false)
  {
    var tv = parseFloat(this.getTv(val,this.getRate(val,ind)));
    let temp = {
        "gstin": this.getGstin(val),
        "invoicenum": this.getInvoice(val),
        "suplier" : this.getSup(val),
         "invoicedate":this.gd(this.getIndate(val)),
        "invoiceval":parseFloat(this.getGross(val)?.toFixed(2)),
        "pos":this.gstSc(),
        "rc":"N",
        "inty":"Regular",  
        "rate": this.getRate(val,ind)+"%",
        // "tv": Number(this.getTv(val,this.getRate(val,ind)))+Number((this.getProf(val)??0)).toFixed(2),
        "tv": tv,
        "itp": this.getIgst(this.getGstin(val),this.getRate(val),val),
        "ctp": this.chkCtp(parseFloat(this.getCgst(this.getGstin(val),tv,this.getRate(val),val)),parseFloat(this.getSgst(this.getGstin(val),tv,this.getRate(val),val)),this.getRate(val,ind)),
        "stp": parseFloat(this.getSgst(this.getGstin(val),tv,this.getRate(val),val)),
        "cp" : "",
        "ei" : "Inputs",
        "ait" : this.getIgst(this.getGstin(val),tv,this.getRate(val)),
        "aict" : parseFloat(this.getCgst(this.getGstin(val),tv,this.getRate(val),val)),
        "ais" :  parseFloat(this.getSgst(this.getGstin(val),tv,this.getRate(val),val)),
        "aic" : ""
    }

    tarray.push(temp)}}
    this.setState({
      trans : this.removeDuplicateObjects(tarray)
      // trans : Array.from(uniqueObjects, JSON.parse)
    })
    this.setState({
      istable : true
    })
    })
}

removeDuplicateObjects(array) {
  const uniqueArray = [];

  // Helper function to check if an object is already in the uniqueArray
  function isUnique(obj) {
      return !uniqueArray.some(item => JSON.stringify(item) === JSON.stringify(obj));
  }

  // Iterate over the array and add unique objects to uniqueArray
  array.forEach(obj => {
      if (isUnique(obj)) {
          uniqueArray.push(obj);
      }
  });

  return uniqueArray;
}

  next() {
    if(this.state.current == 0)
    {
     if(this.state.gstin.length ==15 && (this.gstSc()??false))
     {
      //  this.convert()
       const current = this.state.current + 1;
       this.setState({ current });
     }
     else
     {
       message.error('Invalid GSTIN')
     }
    }
 if(this.state.current == 1)
 {
 
  if(this.state.jsonData.length)
  {
    this.convert()
    const current = this.state.current + 1;
    this.setState({ current });
  }
  else
  {
    message.error('Upload a Excel File')

  }
 }
 if(this.state.current == 2)
 {
  const current = this.state.current + 1;
  this.setState({ current });
 }
  }
  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }
  edit(value,i,code)
  {
let temp = this.state.trans;
temp[i][code] = value;
if(value.toLowerCase().includes('ineligible'))
{
  temp[i]['ait'] = '';
  temp[i]['aict'] = '';
  temp[i]['ais'] = '';
  temp[i]['aic'] = '';
}
else
{
  temp[i]['ait'] =  temp[i]['itp'];
  temp[i]['aict'] =  temp[i]['ctp'];
  temp[i]['ais'] =  temp[i]['stp'];
  temp[i]['aic'] =  temp[i]['cp'];
}
this.setState({
  trans : temp
})
  }
  columns = [
    {
        title: 'GSTIN of Supplier',
        dataIndex: 'gstin',
        key: 'gstin',
        align: 'center',
        fixed : 'left',
        render: (text, record,index) => (
                <span style={{color : (text.length!=15)?"red":""}}>{text?text:<Input/>}</span>
        )
      },
      {
        title: 'Invoice Number',
        dataIndex: 'invoicenum',
        key: 'invoicenum',
        align: 'center',
        fixed : 'left',
        render: (text, record,index) => (
          <span>{text?text:<Input/>}</span>
  )
      },
      {
        title: 'Suplier Name',
        dataIndex: 'suplier',
        key: 'suplier',
        align: 'center',
        width : "10%"
      },
      {
        title: 'Invoice date',
        dataIndex: 'invoicedate',
        key: 'invoicedate',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?text:<Input/>}</span>
  )
      },
      {
        title: 'Invoice Value',
        dataIndex: 'invoiceval',
        key: 'invoiceval',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?text:<Input/>}</span>
  )
      },
      {
        title: 'Place Of Supply',
        dataIndex: 'pos',
        key: 'pos',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?text:<Input/>}</span>
  )
      },
      {
        title: 'Reverse Charge',
        dataIndex: 'rc',
        align: 'center',
        render: (text, record,index) => (
          <Select style={{ width: 70 }} defaultValue={record.rc} onChange={(value)=>this.edit(value,index,"rc")}>
          <Option value="N">N</Option>
          <Option value="Y">Y</Option>
        </Select>
        )
      },
      {
        title: 'Invoice Type',
        dataIndex: 'inty',
        align: 'center',
        render: (text, record,index) => (
          <Select style={{ width: 150 }} defaultValue={record.inty} onChange={(value)=>this.edit(value,index,"inty")}>
          <Option value="Regular">Regular</Option>
          <Option value="SEZ Supplies with Payment">SEZ Supplies with Payment</Option>
          <Option value="SEZ Supplies without Payment">SEZ Supplies without Payment</Option>
          <Option value="Deemed Exp">Deemed Exp</Option>
        </Select>
        )
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?text:<Input/>}</span>
  )
      },
      {
        title: 'Taxable Value',
        dataIndex: 'tv',
        key: 'tv',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input/>}</span>
  )
      },
      {
        title: 'Integrated Tax Paid',
        dataIndex: 'itp',
        key: 'itp',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input/>}</span>
  )
      },
      {
        title: 'Central Tax Paid',
        dataIndex: 'ctp',
        key: 'ctp',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input/>}</span>
  )
      },
      {
        title: 'State/UT Tax Paid',
        dataIndex: 'stp',
        key: 'stp',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input/>}</span>
  )
      },
      {
        title: 'Cess Paid',
        dataIndex: 'cp',
        key: 'cp',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input/>}</span>
  )
      },
      {
        title: 'Eligibility For ITC',
        dataIndex: 'ei',
        align: 'center',
        render: (t,r,ind) => (
          <Select style={{ width: 170 }} defaultValue={r.ei}  onChange={(value)=>this.edit(value,ind,"ei")}>
          <Option value="Inputs">Inputs</Option>
          <Option value="Captital goods">Captital goods</Option>
          <Option value="Input services">Input services</Option>
          <Option value="Ineligible">Ineligible</Option>
        </Select>
        )
      },
      {
        title: 'Availed ITC Integrated Tax',
        dataIndex: 'ait',
        key: 'ait',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input defaultValue={record['itp']}/>}</span>
  )
      },
      {
        title: 'Availed ITC Central Tax',
        dataIndex: 'aic',
        key: 'aic',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input defaultValue={record['ctp']}/>}</span>
  )
      },
      {
        title: 'Availed ITC State/UT Tax',
        dataIndex: 'ais',
        key: 'ais',
        align: 'center',
        render: (text, record,index) => (
          <span>{text?<Input defaultValue={text}/>:<Input defaultValue={record['stp']}/>}</span>
  )
      },
      {
        title: 'Availed ITC Cess',
        dataIndex: 'aitc',
        key: 'aitc',
        align: 'center',
                render: (text, record,index) => (
                <span>{text?<Input defaultValue={text}/>:<Input defaultValue={record['cp']}/>}</span>
        )
      },
 ]

createExcelFile = (jsonData) => {
  let colData = ["GSTIN of Supplier","Invoice Number","Supplier Name","Invoice date","Invoice Value","Place Of Supply","Reverse Charge","Invoice Type","Rate","Taxable Value","Integrated Tax Paid","Central Tax Paid","State/UT Tax Paid","Cess Paid","Eligibility For ITC","Availed ITC Integrated Tax","Availed ITC Central Tax","Availed ITC State/UT Tax","Availed ITC Cess"]
  // let jsonData = frmtxl(xldata)
  const worksheetData = jsonData.map(item => {
    return Object.keys(item).map(key => item[key]);
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([colData,
 // Use the keys of the first object as titles
    ...worksheetData,
  ]);

  ws['!cols'] =[
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 },
     { wch : 20 }
  ]
 
  XLSX.utils.book_append_sheet(wb, ws, 'B2B');

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  this.saveExcelFile(excelBuffer, "ThoughtGST Purchase Template - TGST format.xlsx");
};

saveExcelFile = (buffer, fileName) => {
  const data = new Blob([buffer], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
};

setGst(value)
{
  this.setState({gstin : value})
}

setAddv(propertyToSum)
{
  let sum = 0;
  for (const obj of this.state.trans) {
    if(obj[propertyToSum])
      {
        sum = Number(sum)+Number(obj[propertyToSum]);
      }
  }
return sum;
}

  render() {
    const { current } = this.state;
    return (
<div style={{ backgroundColor: "#fff", padding: "5px" }}>
        <Steps current={current}>
          {steps.map(item => <Step key={item.title} title={item.title} />)}
        </Steps>
        <br/>
        <div className="steps-content">
{(this.state.current == 0) && <div align="center"><Input placeholder="ENTER YOUR GSTIN HERE"  defaultValue={this.props.CompanyGSTIN} style={{ width: 500 }}  onChange={(e)=>this.setGst(e.target.value)}/></div>}
{(this.state.current == 1) && <div style={{"textAlign" : "center"}}><input type="file" id="actual-btn" onChange={this.handleFile} style={{"display" : "none"}}/>
<label className="ant-btn"  style={
{
  "width": "180px",
  "font-size": "14px",
  "padding": "0px 10px",
  "background-color": "green",
  "color": "white",
  padding : "5px"
}

}for="actual-btn">Upload Excel</label> &nbsp;
<br/><br/>
<b>Select Type</b>
<br/>
<Radio.Group defaultValue={this.state.type} onChange={(e)=>{
  this.setState({
    type : e.target.value
  })
}}>
      <Radio value={1}>Tax Rate without Breakup</Radio>
      <Radio value={2}>Tax Rate with Breakup</Radio>
    </Radio.Group>
</div>
}
{(this.state.current == 2) && <div align="center">
    <Card title="Uploaded Data Summary"  style={{ width: 500 }}>
      <p>Total Taxable Value: ₹ {this.setAddv("tv")?.toFixed(2)}</p>
      <p>Total IGST: ₹ {this.setAddv("itp")?.toFixed(2)}</p>
      <p>Total SGST: ₹ {this.setAddv("stp")?.toFixed(2)}</p>
      <p>Total CGST: ₹ {this.setAddv("ctp")?.toFixed(2)}</p>
    </Card>
  </div>}
{(this.state.current == 3) &&<div style={{ backgroundColor: "#fff", padding: "5px" }}>
           <Table columns={this.columns} dataSource={this.state.trans} scroll={{ x: 3000 }}
                title={() =>
                    "Purchase Register"
                  }

           footer={() => (
            <div style={{ textAlign: "center" }}>
              <Button
                icon="file-excel"
                style={{ backgroundColor: "#2382c7", color: "white" }}
                onClick={()=> this.createExcelFile(this.state.trans)}
              >
                Download (Excel Format)
              </Button>
            </div>
          )}/>
         
           </div>}
</div>
        <br/>
        <div className="steps-action" align='center'>
                    {
            this.state.current > 0
            &&
            <Button type="danger" style={{ marginLeft: 8 }} onClick={() => this.prev()}>
              Back
            </Button>
          }
          {
            this.state.current < steps.length - 1
            &&
            <Button type="primary" onClick={() => this.next()}>Next</Button>
          }

        </div>

      </div>
    );
  }
}

const mapStateToProps = (state)=>{
  return {CompanyGSTIN : state.local.selectedCompanyGSTIN}
}

export default connect(mapStateToProps)(Purxl);