/**
 * @flow
 */
import React from 'react'
import Modal from 'antd/lib/modal'
import Table from 'antd/lib/table'
import { formatNumber } from "../../shared/constants";
const { ColumnGroup, Column } = Table

type props = {
  isVisible: boolean,
  onCancel: Function,
  pr_itc_items: Array,
}

export default (props: props) =>
  <Modal title="Purchase Items" visible={props.isVisible} onCancel={props.onCancel} footer={null} width="90%">
    <Table dataSource={props.pr_itc_items} bordered rowKey="gstin" pagination={{ className: 'itc-items-pagination' }}>
      <ColumnGroup title="Amount of Tax">
        <Column title="ITC IGST" dataIndex="igst" key="igst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC CGST" dataIndex="cgst" key="cgst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC SGST" dataIndex="sgst" key="sgst" render={val => (val === null ? '***' : formatNumber(val))} />
        <Column title="ITC CESS" dataIndex="cess" key="cess" render={val => (val === null ? '***' : formatNumber(val))} />
      </ColumnGroup>
      />
    </Table>
  </Modal>
