import { stateArray, docNumberArray } from "../shared/constants";
var moment = require('moment');
const {
  flow,
  map,
  flattenDeep,
  find,
  omit,
  groupBy,
  entries,
  pick,
  findIndex,
  toString,
  get,
  filter,
  reduce
} = require("lodash/fp");

const deleteData = {

  formatDeletePayload: (data, action) => {
    console.log(data);
    console.log(action);

    const getMonthYearForGSTN = (finMonth,finYear) => {
    //console.log(finYear);
    //console.log(finMonth);
    var year_trim = finYear.split("-");
    var fy = '';
    if (finMonth < 4) {
      fy = '20' + year_trim[1];
    }
    else {
      fy = year_trim[0];
    }
    finMonth= finMonth.toString();
    return finMonth.padStart(2, "0") + '' + fy;
  }

    if(action === 'b2b'){

      const b2barray = flow(
        map(inv => ({
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          val: parseFloat(inv.invoice_value),
          pos: inv.pos_id.substring(0, 2),
          etin: inv.receiver_gstin,
          rchrg: inv.reverse_charge,
          inv_typ: inv.invoice_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          flag: "D",
          itms: inv.sr_b2b_items.edges.map(mapSalesItems, { length: inv.sr_b2b_items.edges.length, type: 'B2B' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          inv: map(omit('etin'))(r[1]),
        }))
      )(data);
      console.log(b2barray);
      return b2barray;

    }else if(action === 'b2cl'){

      const b2clarray = flow(
        map(inv => ({
          pos: inv.pos_id.substring(0, 2),
          inv:
            [{
              flag: "D",
              inum: inv.invoice_number,
              idt: inv.invoice_date,
              inv_typ: inv.invoice_type,
              diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
              val: parseFloat(inv.invoice_value),
              itms: inv.sr_b2cl_items.edges.map(mapSalesItems, { length: inv.sr_b2cl_items.length, type: 'B2CL' }),
            }],
        })),
      )(data)
      console.log(b2clarray);
      return b2clarray;

    }else if(action === 'b2cs'){

      const b2csarray = flow(
        map(inv => {
      const InvoiceItems = map(InvoiceItems => {
       return {
          flag: "D",
          sply_ty: inv.supply_type,
          typ: inv.invoice_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          pos: inv.pos_id.substring(0, 2),
          txval: ((InvoiceItems.taxable_value === null || InvoiceItems.taxable_value === undefined || InvoiceItems.taxable_value === '') ? 0 : parseFloat(parseFloat(InvoiceItems.taxable_value).toFixed(2))),
          rt: ((InvoiceItems.gst_rate === null || InvoiceItems.gst_rate === undefined || InvoiceItems.gst_rate === '') ? 0 : parseFloat(parseFloat(InvoiceItems.gst_rate).toFixed(2))),
          iamt: ((InvoiceItems.igst === null || InvoiceItems.igst === undefined || InvoiceItems.igst === '') ? 0 : parseFloat(parseFloat(InvoiceItems.igst).toFixed(2))),
          camt: ((InvoiceItems.cgst === null || InvoiceItems.cgst === undefined || InvoiceItems.cgst === '') ? 0 : parseFloat(parseFloat(InvoiceItems.cgst).toFixed(2))),
          samt: ((InvoiceItems.sgst === null || InvoiceItems.sgst === undefined || InvoiceItems.sgst === '') ? 0 : parseFloat(parseFloat(InvoiceItems.sgst).toFixed(2))),
          csamt: ((InvoiceItems.cess === null || InvoiceItems.cess === undefined || InvoiceItems.cess === '') ? 0 : parseFloat(parseFloat(InvoiceItems.cess).toFixed(2))),
          etin: inv.ecom_gstin === null ? '' : inv.ecom_gstin
       }
    })(inv.sr_b2cs_items && inv.sr_b2cs_items.edges)
    return InvoiceItems;
        }),
        flattenDeep
      )(data)

      console.log(b2csarray);
      return b2csarray;

    }else if(action === 'cdnr'){
      console.log(data);
      const cdnrarray = flow(
        map(inv => ({
          flag: "D",
          ntty: inv.cdnr_type,
          nt_num: inv.cdnr_number,
          nt_dt: inv.cdnr_date,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.cdnr_value),
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          etin: inv.receiver_gstin,
          itms: inv.sr_cdnr_items.edges.map(mapSalesItems, { length: inv.sr_cdnr_items.length, type: 'CDNR' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          nt: map(omit('etin'))(r[1]),
        }))
      )(data)

      console.log(cdnrarray);

      return cdnrarray;

    }else if(action === 'cdnur'){

      const cdnurarray = flow(
        map(inv => ({
          flag: "D",
          typ: inv.invoice_type,
          ntty: inv.cdnur_type,
          nt_num: inv.cdnur_number,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          nt_dt: inv.cdnur_date,
          /*rsn: inv.cdnur_reason_id,*/
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          val: parseFloat(inv.cdnur_value),
          itms: inv.sr_cdnur_items.edges.map(mapSalesItems, { length: inv.sr_cdnur_items.length, type: 'CDNUR' }),
        })),
      )(data)

      console.log(cdnurarray);

      return cdnurarray;
    }else if(action === 'exp'){

      const exparray = reduce((c,n) => {
        const inv = n;
        const inv_details = {
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          flag: "D",
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.invoice_value),
          sbpcode: inv.sb_port_code === null ? undefined : inv.sb_port_code,
          sbnum: inv.sb_number === null ? undefined : inv.sb_number,
          sbdt: inv.sb_date === null ? undefined : inv.sb_date,
          itms: inv.sr_export_items.edges.map(mapSalesItems, { length: inv.sr_export_items.length, type: 'EXP'  }),
        }
        const expGroup = find({exp_typ: inv.exp_type})(c.exp);
        if(expGroup === undefined) {
          c.exp.push({exp_typ: inv.exp_type, inv: [inv_details]})
        }else {
          expGroup.inv.push(inv_details);
        }
        return c;
      },{"exp":[]})(data);

      console.log(exparray);

      return exparray;

    }else if(action === 'at'){
      const atarray = flow(
        map(inv => ({
          flag: "D",
          pos: inv.pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.sr_at_items.edges.map(mapSalesItems, { length: inv.sr_at_items.length, type: 'AT' }),
        })),
      )(data)

      console.log(atarray);
      return atarray;
    }else if(action === 'txp'){
      const atadjarray = flow(
        map(inv => ({
          flag: "D",
          pos: inv.pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.sr_atadj_items.edges.map(mapSalesItems, { length: inv.sr_atadj_items.length, type: 'ATADJ' }),
        })),
      )(data)

      console.log(atadjarray);
      return atadjarray;
    }else if(action === 'hsn'){

      var hsnSerialNo = 0;

      const hsnarray = flow(
        map(inv => {
          hsnSerialNo = hsnSerialNo + 1;
          return {
            // flag: "D",
            num: hsnSerialNo,
            hsn_sc: inv.hsn_sac_code,
            desc: inv.hsn_sac_desc ? inv.hsn_sac_desc : undefined,
            uqc: inv.uqc_code,
            qty: parseFloat(inv.total_qty),
            val: parseFloat(parseFloat(inv.total_value).toFixed(2)),
            txval: inv.sr_hsn_items.edges.map(mapSalesItem, { type: 'TXVAL' })[0],
            iamt: inv.sr_hsn_items.edges.map(mapSalesItem, { type: 'IGST' })[0],
            csamt: inv.sr_hsn_items.edges.map(mapSalesItem, { type: 'CSGST' })[0],
            camt: inv.sr_hsn_items.edges.map(mapSalesItem, { type: 'CGST' })[0],
            samt: inv.sr_hsn_items.edges.map(mapSalesItem, { type: 'SGST' })[0]
          }
        }),
      )(data)

      console.log(hsnarray);

      return hsnarray;

    }else if(action === 'nil'){
      const nilarray = flow(
        map(inv => ({
          sply_ty: inv.supply_type.split("-")[0].trim(),
          nil_amt: inv.nil_amt ? parseFloat(inv.nil_amt) : 0,
          expt_amt: inv.expt_amt ? parseFloat(inv.expt_amt) : 0,
          ngsup_amt: inv.ngsup_amt ? parseFloat(inv.ngsup_amt) : 0,

        })),
      )(data)
      console.log(nilarray);
      return nilarray;
    }else if(action === 'doc'){
      const docarray = flow(
        reduce((docs_det,inv) =>
      {
      const docTypeList = filter({doc_typ: inv.nature_doc})(docs_det)
      if(docTypeList.length === 0) {
        const docType = {
          doc_num: find({docName: inv.nature_doc})(docNumberArray).id,
          doc_typ: inv.nature_doc,
          docs: [ {
            cancel: parseInt(inv.cancel_count),
            from: inv.from_serial,
            net_issue: parseInt(inv.net_count),
            num: 1,
            to: inv.to_serial,
            totnum: parseInt(inv.total_count),
          } ]
        }
        docs_det.push(docType);
      }else {
        const docs = docTypeList[0].docs;
        docs.push({
            cancel: parseInt(inv.cancel_count),
            from: inv.from_serial,
            net_issue: parseInt(inv.net_count),
            num: docs.length + 1,
            to: inv.to_serial,
            totnum: parseInt(inv.total_count),
          })
      }
      console.log(docs_det);
      return docs_det;
    },[]),
      )(data)
      console.log(docarray);
      return docarray;
    }
    else if(action === 'b2ba'){

      const b2baarray = flow(
        map(inv => ({
          inum: inv.r_invoice_number,
          idt: inv.r_invoice_date,
          oinum: inv.o_invoice_number,
          oidt: inv.o_invoice_date,
          val: parseFloat(inv.invoice_value),
          pos: inv.pos_id.substring(0, 2),
          etin: inv.receiver_gstin,
          rchrg: inv.reverse_charge,
          inv_typ: inv.invoice_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          flag: "D",
          itms: inv.sr_b2ba_items.edges.map(mapSalesItems, { length: inv.sr_b2ba_items.length, type: 'B2BA' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          inv: map(omit('etin'))(r[1]),
        }))
      )(data);
      console.log(b2baarray);
      return b2baarray;

    }else if(action === 'b2cla'){

      const b2claarray = flow(
        map(inv => ({
          pos: inv.pos_id.substring(0, 2),
          inv:
            [{
              flag: "D",
              oinum: inv.o_invoice_number,
              oidt: inv.o_invoice_date,
              inum: inv.r_invoice_number,
              idt: inv.r_invoice_date,
              inv_typ: inv.invoice_type,
              diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
              val: parseFloat(inv.invoice_value),
              itms: inv.sr_b2cla_items.edges.map(mapSalesItems, { length: inv.sr_b2cla_items.length, type: 'B2CLA' }),
            }],
        })),
      )(data)
      console.log(b2claarray);
      return b2claarray;

    }else if(action === 'b2csa'){

      const b2csaarray = flow(
        map(inv => ({
          omon:  getMonthYearForGSTN(parseInt(moment().month(inv.original_month).format("MM")),inv.financial_year_a),
          sply_ty: inv.supply_type,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          typ: inv.invoice_type,
          pos: inv.r_pos_id.substring(0, 2),
          etin: inv.ecom_gstin === null ? '' : inv.ecom_gstin,
          itms: inv.sr_b2csa_items.edges.map(mapSalesItems, { length: inv.sr_b2csa_items.length, type: 'B2CSA' }),
        })),
      )(data)

      console.log(b2csaarray);
      return b2csaarray;

    }else if(action === 'cdnra'){

      const cdnraarray = flow(
        map(inv => ({
          flag: "D",
          ntty: inv.cdnr_type,
          nt_num: inv.r_cdnr_number,
          nt_dt: inv.r_cdnr_date,
          ont_num: inv.o_cdnr_number,
          ont_dt: inv.o_cdnr_date,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.cdnr_value),
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          etin: inv.receiver_gstin,
          itms: inv.sr_cdnra_items.edges.map(mapSalesItems, { length: inv.sr_cdnra_items.length, type: 'CDNRA' }),
        })),
        groupBy(r => r.etin),
        entries,
        map(r => ({
          ctin: r[0],
          nt: map(omit('etin'))(r[1]),
        }))
      )(data)

      console.log(cdnraarray);

      return cdnraarray;

    }else if(action === 'cdnura'){

      const cdnuraarray = flow(
        map(inv => ({
          flag: "D",
          typ: inv.invoice_type,
          ntty: inv.cdnur_type,
          nt_num: inv.r_cdnur_number,
          ont_num: inv.o_cdnur_number,
          ont_dt: inv.o_cdnur_date,
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          nt_dt: inv.r_cdnur_date,
          p_gst: inv.pre_gst,
          inum: inv.invoice_number,
          idt: inv.invoice_date,
          val: parseFloat(inv.cdnur_value),
          itms: inv.sr_cdnura_items.edges.map(mapSalesItems, { length: inv.sr_cdnura_items.length, type: 'CDNURA' }),
        })),
      )(data)

      console.log(cdnuraarray);

      return cdnuraarray;
    }else if(action === 'expa'){

      const expaarray = reduce((c,n) => {
        const inv = n;
        const inv_details = {
          inum: inv.r_invoice_number,
          idt: inv.r_invoice_date,
          oinum: inv.o_invoice_number,
          oidt: inv.o_invoice_date,
          flag: "D",
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          val: parseFloat(inv.invoice_value),
          sbpcode: inv.sb_port_code === null ? undefined : inv.sb_port_code,
          sbnum: inv.sb_number === null ? undefined : inv.sb_number,
          sbdt: inv.sb_date === null ? undefined : inv.sb_date,
          itms: inv.sr_exporta_items.edges.map(mapSalesItems, { length: inv.sr_exporta_items.length, type: 'EXPA'  }),
        }
        const expGroup = find({exp_typ: inv.exp_type})(c.exp);
        if(expGroup === undefined) {
          c.exp.push({exp_typ: inv.exp_type, inv: [inv_details]})
        }else {
          expGroup.inv.push(inv_details);
        }
        return c;
      },{"exp":[]})(data);

      console.log(expaarray);

      return expaarray;

    }else if(action === 'ata'){
      const ataarray = flow(
        map(inv => ({
          flag: "D",
          omon:  getMonthYearForGSTN(parseInt(moment().month(inv.original_month).format("MM")),inv.financial_year_a),
          pos: inv.o_pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.sr_ata_items.edges.map(mapSalesItems, { length: inv.sr_ata_items.length, type: 'ATA' }),
        })),
      )(data)

      console.log(ataarray);
      return ataarray;
    }else if(action === 'txpa'){
      const atadjaarray = flow(
        map(inv => ({
          flag: "D",
          omon:  getMonthYearForGSTN(parseInt(moment().month(inv.original_month).format("MM")),inv.financial_year_a),
          pos: inv.o_pos_id.substring(0, 2),
          diff_percent: inv.atr === null ? undefined : parseFloat(inv.atr)/100,
          sply_ty: inv.supply_type,
          itms: inv.sr_atadja_items.edges.map(mapSalesItems, { length: inv.sr_atadja_items.length, type: 'ATADJA' }),
        })),
      )(data)

      console.log(atadjaarray);
      return atadjaarray;
    }

    function mapSalesItems(item)
    {
      if ((this.type == 'B2B') || (this.type == 'B2CL') || (this.type == 'CDNR') || (this.type == 'CDNUR') || (this.type == 'B2BA') || (this.type == 'B2CLA') || (this.type == 'CDNRA') || (this.type == 'CDNURA'))
      {
        var rate_value = parseInt(parseInt(item.gst_rate) + '0' + this.length);
        if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN'))
        {
          return {
            num: rate_value,
            itm_det:
            {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
              iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
        else if ((parseFloat(item.cgst).toFixed(2) != NaN) && (parseFloat(item.cgst).toFixed(2) != null) && (parseFloat(item.cgst).toFixed(2) != 'NaN'))
        {
          return {
            num: rate_value,
            itm_det:
            {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
              camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
              samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
      } else if ((this.type == 'B2CSA'))
      {

        if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN'))
        {
          return {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
              iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
          }
        }
        else if ((parseFloat(item.cgst).toFixed(2) != NaN) && (parseFloat(item.cgst).toFixed(2) != null) && (parseFloat(item.cgst).toFixed(2) != 'NaN'))
        {
          return {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
              camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
              samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
          }
        }
      }
      else if ((this.type == "AT") || (this.type == "ATADJ") || (this.type == "ATA") || (this.type == "ATADJA"))
      {
        if ((this.type == "AT") || (this.type == "ATA"))
        {

          if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN')) {
            return {
                  ad_amt: (item.gross_received === null || item.gross_received === undefined || item.gross_received === '') ? 0 : parseFloat(parseFloat(item.gross_received).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//rate
                  iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
          else {
            return {
                  ad_amt: (item.gross_received === null || item.gross_received === undefined || item.gross_received === '') ? 0 : parseFloat(parseFloat(item.gross_received).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
                  camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
                  samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
        else
        {

          if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN')) {
            return {
                  ad_amt: (item.gross_adjusted === null || item.gross_adjusted === undefined || item.gross_adjusted === '') ? 0 : parseFloat(parseFloat(item.gross_adjusted).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//rate
                  iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
          else {
            return {
                  ad_amt: (item.gross_adjusted === null || item.gross_adjusted === undefined || item.gross_adjusted === '') ? 0 : parseFloat(parseFloat(item.gross_adjusted).toFixed(2)),
                  rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
                  camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
                  samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
                  csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
      }else if ((this.type == 'EXP') || (this.type == 'EXPA')){
        return {
          txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
          rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
          iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)),
          csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
        }
      }
    }
    function mapSalesItem(item)
    {
      //console.log("--------------------------------item-----------------------------")
      //console.log(item)
      //console.log("--------------------------------item-----------------------------")
      if (this.type == 'TXVAL')
      {
        return item.taxable_value === null ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2))
      }
      else if (this.type == "RT")
      {
        return item.gst_rate === null ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2))
      }
      else if (this.type == 'IGST')
      {
        return item.igst === null ? 0 : parseFloat(parseFloat(item.igst).toFixed(2))
      }
      else if (this.type == "CGST")
      {
        return item.cgst === null ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2))
      }
      else if (this.type == "SGST")
      {
        return item.sgst === null ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2))
      }
      else if (this.type == "CSGST")
      {
        return item.cess === null ? 0 : parseFloat(parseFloat(item.cess).toFixed(2))
      }
    }

  },

  formatGSPPayload: (finYear, finMonth, companyProps, otherGSTNInformation, gsp, deleteDataFormat, stateTYPE) => {
    console.log(finYear);
    console.log(finMonth);
    console.log(deleteDataFormat);
    console.log(companyProps);
    console.log(otherGSTNInformation);
    console.log(gsp);

    var year_trim = finYear.split("-");
    var fy = "";
    if (finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }
    var downloaddate = finMonth + "/" + finYear;
    var period = toString(finMonth).padStart(2, "0") + "" + fy;

    let gstr1json = {
    gstin: companyProps.gstin,
    fp: period,
    gt: 0,
    cur_gt: 0
    } 

    if(stateTYPE === 'B2B'){
    
    if(deleteDataFormat.length > 0) gstr1json["b2b"] = deleteDataFormat;
    }else if(stateTYPE === 'B2CL'){
      
      if(deleteDataFormat.length > 0) gstr1json["b2cl"] = deleteDataFormat;
    }else if(stateTYPE === 'B2CS'){
      
      if(deleteDataFormat.length > 0) gstr1json["b2cs"] = deleteDataFormat;
    }else if(stateTYPE === 'CDNR'){
      
      if(deleteDataFormat.length > 0) gstr1json["cdnr"] = deleteDataFormat;
    }else if(stateTYPE === 'CDNUR'){
      
      if(deleteDataFormat.length > 0) gstr1json["cdnur"] = deleteDataFormat;
    }else if(stateTYPE === 'EXP'){
      
      if(deleteDataFormat.exp.length > 0) gstr1json["exp"] = deleteDataFormat.exp;
    }else if(stateTYPE === 'AT'){
      
      if(deleteDataFormat.length > 0) gstr1json["at"] = deleteDataFormat;
    }else if(stateTYPE === 'TXP'){
      
      if(deleteDataFormat.length > 0) gstr1json["txpd"] = deleteDataFormat;
    }else if(stateTYPE === 'HSNSUM'){
      
      if(deleteDataFormat.length > 0){
        gstr1json["hsn"] = {};
        gstr1json["hsn"]["flag"] = 'D';
        gstr1json["hsn"]["data"] = deleteDataFormat;
      }
    }else if(stateTYPE === 'NIL'){
      
      console.log(deleteDataFormat);
      if(deleteDataFormat.length > 0){
        gstr1json["nil"] = {};
        gstr1json["nil"]["inv"] = deleteDataFormat;
        gstr1json["nil"]["inv"]["flag"] = 'D';
      }
    }else if(stateTYPE === 'DOC'){
      
      console.log(deleteDataFormat);
      if(deleteDataFormat.length > 0){
        gstr1json["doc_issue"] = {};
        gstr1json["doc_issue"]["flag"] = 'D';
        gstr1json["doc_issue"]["doc_det"] = deleteDataFormat;
    }
    }
    else if(stateTYPE === 'B2BA'){
    
    if(deleteDataFormat.length > 0) gstr1json["b2ba"] = deleteDataFormat;
    }else if(stateTYPE === 'B2CLA'){
      
      if(deleteDataFormat.length > 0) gstr1json["b2cla"] = deleteDataFormat;
    }else if(stateTYPE === 'B2CSA'){
      
      if(deleteDataFormat.length > 0) gstr1json["b2csa"] = deleteDataFormat;
    }else if(stateTYPE === 'CDNRA'){
      
      if(deleteDataFormat.length > 0) gstr1json["cdnra"] = deleteDataFormat;
    }else if(stateTYPE === 'CDNURA'){
      
      if(deleteDataFormat.length > 0) gstr1json["cdnura"] = deleteDataFormat;
    }else if(stateTYPE === 'EXPA'){
      
      if(deleteDataFormat.exp.length > 0) gstr1json["expa"] = deleteDataFormat.exp;
    }else if(stateTYPE === 'ATA'){
      
      if(deleteDataFormat.length > 0) gstr1json["ata"] = deleteDataFormat;
    }else if(stateTYPE === 'TXPA'){
      
      if(deleteDataFormat.length > 0) gstr1json["txpda"] = deleteDataFormat;
    }

    const companyDetails = flow(
        pick(["gstin", "id", "gstnUserName"]),
        otherGSTNInformation
      )(companyProps);

      console.log(companyDetails);

      const reqData = {
        companyDetails: companyDetails,
        gspDetails: {
          gspToken: gsp.access_token,
          otp: gsp.otp
        },
        period: period,
        body: gstr1json
      };

      console.log(reqData);

      return reqData

      // this.gsp.response = await gspAuth.saveGSTR1(reqData);



  }


}


export default deleteData;
