import fetch from 'isomorphic-fetch'
import { BASE_URL, stateArray } from '../shared/constants'
const XLSX = require('xlsx')
var fileSaver = require('file-saver')
var blob = require('blob-util')
const {
	flow,
	map,
	flattenDeep,
	find,
	findLast,
	filter
} = require("lodash/fp");
const Sales = {
	upload: ({ file, type, tally, companyId, isSR, finMonth, finYear }) => {
		const data = new FormData()
		data.append(type, file)
		data.append('companyId', companyId)
		data.append('isSR', isSR)
		data.append('tally', tally)
		data.append('finMonth', finMonth)
		data.append('finYear', finYear)
		return fetch(`${BASE_URL}/upload/${type}`, {
			method: 'POST',
			body: data,
		}).then(res => res.json()).then(function (parsedData) {
			console.log('$$$$Parsed_Data$$$$');
			console.log(parsedData);
			//console.log(parsedData.flag);

			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
				return buf;
			}

			var wb_new = XLSX.utils.book_new();

			if (tally === 'true') {

				if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null) && (parsedData.srb2b.length !== 0)) {
					wb_new.SheetNames.push("b2b");
				}
				if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null) && (parsedData.srb2cl.length !== 0)) {
					wb_new.SheetNames.push("b2cl");
				}
				if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null) && (parsedData.srb2cs.length !== 0)) {
					wb_new.SheetNames.push("b2cs");
				}
				if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null) && (parsedData.srcdnr.length !== 0)) {
					wb_new.SheetNames.push("cdnr");
				}
				if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null) && (parsedData.srcdnur.length !== 0)) {
					wb_new.SheetNames.push("cdnur");
				}
				if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null) && (parsedData.srexport.length !== 0)) {
					wb_new.SheetNames.push("exp");
				}
				if ((parsedData.srat !== undefined) && (parsedData.srat !== null) && (parsedData.srat.length !== 0)) {
					wb_new.SheetNames.push("at");
				}
				if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null) && (parsedData.sratadj.length !== 0)) {
					wb_new.SheetNames.push("atadj");
				}
				if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null) && (parsedData.srnil.length !== 0)) {
					wb_new.SheetNames.push("exemp");
				}
				if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null) && (parsedData.srhsn.length !== 0)) {
					wb_new.SheetNames.push("hsn");
				}
				if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null) && (parsedData.srdoc.length !== 0)) {
					wb_new.SheetNames.push("docs");
				}

				//format B2B data
				var b2bDataList = [];
				var b2bData = {};
				if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
					for (var i = 0; i < parsedData.srb2b.length; i++) {
						if (parsedData.srb2b[i].Status === "") {
							break;
						}
						b2bData =
						{
							'GSTIN/UIN of Recipient': parsedData.srb2b[i]["GSTIN/UIN of Recipient"],
							// 'Receiver Name*': parsedData.srb2b[i]["Receiver Name*"],
							'Invoice Number': parsedData.srb2b[i]["Invoice Number"],
							'Invoice date': parsedData.srb2b[i]["Invoice date"] ? parsedData.srb2b[i]["Invoice date"].padStart(9, "0") : parsedData.srb2b[i]["Invoice date"],
							'Invoice Value': parsedData.srb2b[i]["Invoice Value"],
							'Place Of Supply': parsedData.srb2b[i]["Place Of Supply"],
							'Reverse Charge': parsedData.srb2b[i]["Reverse Charge"],
							'Applicable % of Tax Rate': parsedData.srb2b[i]["Applicable % of Tax Rate"],
							'Invoice Type': parsedData.srb2b[i]["Invoice Type"] === "R" ? "Regular" : parsedData.srb2b[i]["Invoice Type"],
							'E-Commerce GSTIN': parsedData.srb2b[i]["E-Commerce GSTIN"],
							// 'Supply Type*': parsedData.srb2b[i]["Supply Type*"],
							'Rate': parsedData.srb2b[i]["Rate"],
							'Taxable Value': parsedData.srb2b[i]["Taxable Value"],
							// 'Integrated Tax*': parsedData.srb2b[i]["Integrated Tax*"],
							// 'Central Tax*': parsedData.srb2b[i]["Central Tax*"],
							// 'State/UT Tax*': parsedData.srb2b[i]["State/UT Tax*"],
							'Cess Amount': parsedData.srb2b[i]["Cess Amount"],
							'Status': parsedData.srb2b[i]["Status"]
						};
						b2bDataList.push(b2bData);
					}
				}
				//format B2CL data
				var b2clDataList = [];
				var b2clData = {};
				if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null)) {
					for (var i = 0; i < parsedData.srb2cl.length; i++) {
						if (parsedData.srb2cl[i].Status === "") {
							break;
						}
						b2clData =
						{
							'Invoice Number': parsedData.srb2cl[i]["Invoice Number"],
							'Invoice date': parsedData.srb2cl[i]["Invoice date"] ? parsedData.srb2cl[i]["Invoice date"].padStart(9, "0") : parsedData.srb2cl[i]["Invoice date"],
							'Invoice Value': parsedData.srb2cl[i]["Invoice Value"],
							'Place Of Supply': parsedData.srb2cl[i]["Place Of Supply"],
							// 'Supply Type*': parsedData.srb2cl[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srb2cl[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.srb2cl[i]["Rate"],
							'Taxable Value': parsedData.srb2cl[i]["Taxable Value"],
							// 'Integrated Tax*': parsedData.srb2cl[i]["Integrated Tax*"],
							'Cess Amount': parsedData.srb2cl[i]["Cess Amount"],
							'E-Commerce GSTIN': parsedData.srb2cl[i]["E-Commerce GSTIN"],
							'Sale from Bonded WH': parsedData.srb2cl[i]["Sale from Bonded WH"],
							'Status': parsedData.srb2cl[i]["Status"]
						};
						b2clDataList.push(b2clData);
					}
				}
				//format B2CS data
				var b2csDataList = [];
				var b2csData = {};
				if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
					for (var i = 0; i < parsedData.srb2cs.length; i++) {
						if (parsedData.srb2cs[i].Status === '') {
							break;
						}
						b2csData =
						{
							'Type': parsedData.srb2cs[i]["Type"],
							'Place Of Supply': parsedData.srb2cs[i]["Place Of Supply"],
							// 'Supply Type*': parsedData.srb2cs[i]["Supply Type*"],
							// 'Applicable % of Tax Rate': parsedData.srb2cs[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.srb2cs[i]["Rate"],
							'Taxable Value': parsedData.srb2cs[i]["Taxable Value"],
							// 'Integrated Tax*': parsedData.srb2cs[i]["Integrated Tax*"],
							// 'Central Tax*': parsedData.srb2cs[i]["Central Tax*"],
							// 'State/UT Tax*': parsedData.srb2cs[i]["State/UT Tax*"],
							'Cess Amount': parsedData.srb2cs[i]["Cess Amount"],
							'E-Commerce GSTIN': parsedData.srb2cs[i]["E-Commerce GSTIN"],
							'Status': parsedData.srb2cs[i]["status"]
						};
						b2csDataList.push(b2csData);
					}
				}
				//format CDNR data
				var cdnrDataList = [];
				var cdnrData = {};
				if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null)) {
					for (var i = 0; i < parsedData.srcdnr.length; i++) {
						if (parsedData.srcdnr[i].Status === "") {
							break;
						}
						cdnrData =
						{
							'GSTIN/UIN of Recipient': parsedData.srcdnr[i]["GSTIN/UIN of Recipient"],
							// 'Receiver Name*': parsedData.srcdnr[i]["Receiver Name*"],
							'Invoice/Advance Receipt Number': parsedData.srcdnr[i]["Invoice/Advance Receipt Number"],
							'Invoice/Advance Receipt date': parsedData.srcdnr[i]["Invoice/Advance Receipt date"] ? parsedData.srcdnr[i]["Invoice/Advance Receipt date"].padStart(9, "0") : parsedData.srcdnr[i]["Invoice/Advance Receipt date"],
							'Note/Refund Voucher Number': parsedData.srcdnr[i]["Note/Refund Voucher Number"],
							'Note/Refund Voucher date': parsedData.srcdnr[i]["Note/Refund Voucher date"] ? parsedData.srcdnr[i]["Note/Refund Voucher date"].padStart(9, "0") : parsedData.srcdnr[i]["Note/Refund Voucher date"],
							'Document Type': parsedData.srcdnr[i]["Document Type"],
							// 'Reason for Issuing Document*': parsedData.srcdnr[i]["Reason for Issuing Document*"],
							'Place Of Supply': parsedData.srcdnr[i]["Place Of Supply"],
							'Note/Refund Voucher Value': parsedData.srcdnr[i]["Note/Refund Voucher Value"],
							// 'Supply Type*': parsedData.srcdnr[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srcdnr[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.srcdnr[i]["Rate"],
							'Taxable Value': parsedData.srcdnr[i]["Taxable Value"],
							// 'Integrated Tax*': parsedData.srcdnr[i]["Integrated Tax*"],
							// 'Central Tax*': parsedData.srcdnr[i]["Central Tax*"],
							// 'State/UT Tax*': parsedData.srcdnr[i]["State/UT Tax*"],
							'Cess Amount': parsedData.srcdnr[i]["Cess Amount"],
							'Pre GST': parsedData.srcdnr[i]["Pre GST"],
							'Status': parsedData.srcdnr[i]["Status"]
						};
						cdnrDataList.push(cdnrData);
					}
				}
				//format CDNUR data
				var cdnurDataList = [];
				var cdnurData = {};
				if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null)) {
					for (var i = 0; i < parsedData.srcdnur.length; i++) {
						if (parsedData.srcdnur[i].Status === "") {
							break;
						}
						cdnurData =
						{
							'UR Type': parsedData.srcdnur[i]['UR Type'],
							'Note/Refund Voucher Number': parsedData.srcdnur[i]["Note/Refund Voucher Number"],
							'Note/Refund Voucher date': parsedData.srcdnur[i]["Note/Refund Voucher date"] ? parsedData.srcdnur[i]["Note/Refund Voucher date"].padStart(9, "0") : undefined,
							'Document Type': parsedData.srcdnur[i]["Document Type"],
							'Invoice/Advance Receipt Number': parsedData.srcdnur[i]["Invoice/Advance Receipt Number"],
							'Invoice/Advance Receipt date': parsedData.srcdnur[i]["Invoice/Advance Receipt date"] ? parsedData.srcdnur[i]["Invoice/Advance Receipt date"].padStart(9, "0") : undefined,
							// 'Reason for Issuing Document*': parsedData.srcdnur[i]["Reason for Issuing Document*"],
							'Place Of Supply': parsedData.srcdnur[i]["Place Of Supply"],
							'Note/Refund Voucher Value': parsedData.srcdnur[i]["Note/Refund Voucher Value"],
							// 'Supply Type*': parsedData.srcdnur[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srcdnur[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.srcdnur[i]["Rate"],
							'Taxable Value': parsedData.srcdnur[i]["Taxable Value"],
							// 'Integrated Tax*': parsedData.srcdnur[i]["Integrated Tax*"],
							'Cess Amount': parsedData.srcdnur[i]["Cess Amount"],
							'Pre GST': parsedData.srcdnur[i]["Pre GST"],
							'Status': parsedData.srcdnur[i]["Status"]
						};
						cdnurDataList.push(cdnurData);
					}
				}
				//format EXPORTS data
				var exportsDataList = [];
				var exportsData = {};
				if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
					for (var i = 0; i < parsedData.srexport.length; i++) {
						if (parsedData.srexport[i].Status === "") {
							break;
						}
						exportsData =
						{
							'Export Type': parsedData.srexport[i]["Export Type"],
							'Invoice Number': parsedData.srexport[i]["Invoice Number"],
							'Invoice date': parsedData.srexport[i]["Invoice date"] ? parsedData.srexport[i]["Invoice date"].padStart(9, "0") : parsedData.srexport[i]["Invoice date"],
							'Invoice Value': parsedData.srexport[i]["Invoice Value"],
							'Port Code': parsedData.srexport[i]["Port Code"],
							'Shipping Bill Number': parsedData.srexport[i]["Shipping Bill Number"],
							'Shipping Bill Date': parsedData.srexport[i]["Shipping Bill Date"] ? parsedData.srexport[i]["Shipping Bill Date"].padStart(9, "0") : parsedData.srexport[i]["Shipping Bill Date"],
							'Applicable % of Tax Rate': parsedData.srexport[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.srexport[i]["Rate"],
							'Taxable Value': parsedData.srexport[i]["Taxable Value"],
							'Cess Amount': parsedData.srexport[i]["Cess Amount"],
							// 'Integrated Tax*': parsedData.srexport[i]["Integrated Tax*"],
							'Status': parsedData.srexport[i]["Status"]
						};
						exportsDataList.push(exportsData);
					}
				}
				//format AT data
				var atDataList = [];
				var atData = {};
				if ((parsedData.srat !== undefined) && (parsedData.srat !== null)) {
					for (var i = 0; i < parsedData.srat.length; i++) {
						if (parsedData.srat[i].Status === "") {
							break;
						}
						atData =
						{
							'Place Of Supply': parsedData.srat[i]["Place Of Supply"],
							// 'Supply Type*': parsedData.srat[i]["Supply Type*"],
							// 'Applicable % of Tax Rate': parsedData.srat[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.srat[i]["Rate"],
							'Gross Advance Received': parsedData.srat[i]["Gross Advance Received"],
							// 'Integrated Tax*': parsedData.srat[i]["Integrated Tax*"],
							// 'Central Tax*': parsedData.srat[i]["Central Tax*"],
							// 'State/UT Tax*': parsedData.srat[i]["State/UT Tax*"],
							'Cess Amount': parsedData.srat[i]["Cess Amount"],
							'Status': parsedData.srat[i]["Status"]
						};
						atDataList.push(atData);
					}
				}
				//format ATADJ data
				var atadjDataList = [];
				var atadjData = {};
				if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null)) {
					for (var i = 0; i < parsedData.sratadj.length; i++) {
						if (parsedData.sratadj[i].Status === "") {
							break;
						}
						atadjData =
						{
							'Place of Supply': parsedData.sratadj[i]["Place Of Supply"],
							// 'Supply Type*': parsedData.sratadj[i]["Supply Type*"],
							// 'Applicable % of Tax Rate': parsedData.sratadj[i]["Applicable % of Tax Rate"],
							'Rate': parsedData.sratadj[i]["Rate"],
							'Gross Advance Adjusted': parsedData.sratadj[i]["Gross Advance Adjusted"],
							// 'Integrated Tax*': parsedData.sratadj[i]["Integrated Tax*"],
							// 'Central Tax*': parsedData.sratadj[i]["Central Tax*"],
							// 'State/UT Tax*': parsedData.sratadj[i]["State/UT Tax*"],
							'Cess Amount': parsedData.sratadj[i]["Cess Amount"],
							'Status': parsedData.sratadj[i]["Status"]
						};
						atadjDataList.push(atadjData);
					}
				}

				//format HSN data
				var hsnDataList = [];
				var hsnData = {};
				if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null)) {
					for (var i = 0; i < parsedData.srhsn.length; i++) {
						if (parsedData.srhsn[i].Status === "") {
							break;
						}
						hsnData =
						{
							'HSN': parsedData.srhsn[i]["HSN"],
							'Description': parsedData.srhsn[i]["Description"],
							'UQC': parsedData.srhsn[i]["UQC"],
							'Total Quantity': parsedData.srhsn[i]["Total Quantity"],
							'Total Value': parsedData.srhsn[i]["Total Value"],
							'Rate': parsedData.srhsn[i]["Rate"],
							'Taxable Value': parsedData.srhsn[i]["Taxable Value"],
							'Integrated Tax Amount': parsedData.srhsn[i]["Integrated Tax Amount"],
							'Central Tax Amount': parsedData.srhsn[i]["Central Tax Amount"],
							'State/UT Tax Amount': parsedData.srhsn[i]["State/UT Tax Amount"],
							'Cess Amount': parsedData.srhsn[i]["Cess Amount"],
							'Status': parsedData.srhsn[i]["Status"]
						};
						hsnDataList.push(hsnData);
					}
				}
				//format HSN data end
				//format EXEMP data
				var exempDataList = [];
				var exempData = {};
				if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null)) {
					for (var i = 0; i < parsedData.srnil.length; i++) {
						console.log(parsedData.srnil[i]);
						if (parsedData.srnil[i].Status === "") {
							break;
						}
						console.log(parsedData.srnil[i]);
						exempData = {
							'Description': parsedData.srnil[i]["Description"],
							'Nil Rated Supplies': parsedData.srnil[i]["Nil Rated Supplies"],
							'Exempted (other than nil rated/non GST supply )': parsedData.srnil[i]["Exempted (other than nil rated/non GST supply )"],
							'Non-GST supplies': parsedData.srnil[i]["Non-GST supplies"],
							'Status': parsedData.srnil[i]["Status"]
						};
						console.log(exempData);
						exempDataList.push(exempData);
						console.log(exempDataList);
					}
				}
				//format DOC data
				var docDataList = [];
				var docData = {};
				if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null)) {
					for (var i = 0; i < parsedData.srdoc.length; i++) {
						console.log(parsedData.srdoc[i]);
						if (parsedData.srdoc[i].Status === "") {
							break;
						}
						console.log(parsedData.srdoc[i]);
						docData = {
							'Nature of Document': parsedData.srdoc[i]["Nature of Document"],
							'Sr. No. From': parsedData.srdoc[i]["Sr. No. From"],
							'Sr. No. To': parsedData.srdoc[i]["Sr. No. To"],
							'Total Number': parsedData.srdoc[i]["Total Number"],
							'Cancelled': parsedData.srdoc[i]["Cancelled"],
							'Status': parsedData.srdoc[i]["Status"]
						};
						console.log(docData);
						docDataList.push(docData);
						console.log(docDataList);
					}
				}

				if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null) && (parsedData.srb2b.length !== 0)) {
					wb_new.Sheets["b2b"] = XLSX.utils.json_to_sheet(b2bDataList, { origin: "A4" });
				}
				if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null) && (parsedData.srb2cl.length !== 0)) {
					wb_new.Sheets["b2cl"] = XLSX.utils.json_to_sheet(b2clDataList, { origin: "A4" });
				}
				if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null) && (parsedData.srb2cs.length !== 0)) {
					wb_new.Sheets["b2cs"] = XLSX.utils.json_to_sheet(b2csDataList, { origin: "A4" });
				}
				if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null) && (parsedData.srcdnr.length !== 0)) {
					wb_new.Sheets["cdnr"] = XLSX.utils.json_to_sheet(cdnrDataList, { origin: "A4" });
				}
				if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null) && (parsedData.srcdnur.length !== 0)) {
					wb_new.Sheets["cdnur"] = XLSX.utils.json_to_sheet(cdnurDataList, { origin: "A4" });
				}
				if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null) && (parsedData.srexport.length !== 0)) {
					wb_new.Sheets["exp"] = XLSX.utils.json_to_sheet(exportsDataList, { origin: "A4" });
				}
				if ((parsedData.srat !== undefined) && (parsedData.srat !== null) && (parsedData.srat.length !== 0)) {
					wb_new.Sheets["at"] = XLSX.utils.json_to_sheet(atDataList, { origin: "A4" });
				}
				if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null) && (parsedData.sratadj.length !== 0)) {
					wb_new.Sheets["atadj"] = XLSX.utils.json_to_sheet(atadjDataList, { origin: "A4" });
				}
				if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null) && (parsedData.srnil.length !== 0)) {
					wb_new.Sheets["exemp"] = XLSX.utils.json_to_sheet(exempDataList, { origin: "A4" });
				}
				if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null) && (parsedData.srhsn.length !== 0)) {
					wb_new.Sheets["hsn"] = XLSX.utils.json_to_sheet(hsnDataList, { origin: "A4" });
				}
				if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null) && (parsedData.srdoc.length !== 0)) {
					wb_new.Sheets["docs"] = XLSX.utils.json_to_sheet(docDataList, { origin: "A4" });
				}

			}
			else {

				if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null) && (parsedData.srb2b.length !== 0)) {
					wb_new.SheetNames.push("B2B - 4A, 4B, 4C, 6B, 6C");
				}
				if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null) && (parsedData.srb2cl.length !== 0)) {
					wb_new.SheetNames.push("B2CL - 5A, 5B");
				}
				if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null) && (parsedData.srb2cs.length !== 0)) {
					wb_new.SheetNames.push("B2CS - 7");
				}
				if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null) && (parsedData.srcdnr.length !== 0)) {
					wb_new.SheetNames.push("CDNR - 9B");
				}
				if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null) && (parsedData.srcdnur.length !== 0)) {
					wb_new.SheetNames.push("CDNUR - 9B");
				}
				if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null) && (parsedData.srexport.length !== 0)) {
					wb_new.SheetNames.push("EXP - 6A");
				}
				if ((parsedData.srat !== undefined) && (parsedData.srat !== null) && (parsedData.srat.length !== 0)) {
					wb_new.SheetNames.push("AT - 11A(1), 11A(2)");
				}
				if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null) && (parsedData.sratadj.length !== 0)) {
					wb_new.SheetNames.push("ATADJ - 11B(1), 11B(2)");
				}
				if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null) && (parsedData.srhsn.length !== 0)) {
					wb_new.SheetNames.push("HSN - 12");
				}
				if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null) && (parsedData.srnil.length !== 0)) {
					wb_new.SheetNames.push("EXEMP - 8A, 8B, 8C, 8D");
				}
				if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null) && (parsedData.srdoc.length !== 0)) {
					wb_new.SheetNames.push("DOC - 13");
				}
				if ((parsedData.srb2ba !== undefined) && (parsedData.srb2ba !== null) && (parsedData.srb2ba.length !== 0)) {
					wb_new.SheetNames.push("B2BA - 9A");
				}
				if ((parsedData.srb2cla !== undefined) && (parsedData.srb2cla !== null) && (parsedData.srb2cla.length !== 0)) {
					wb_new.SheetNames.push("B2CLA - 9A");
				}
				if ((parsedData.srb2csa !== undefined) && (parsedData.srb2csa !== null) && (parsedData.srb2csa.length !== 0)) {
					wb_new.SheetNames.push("B2CSA - 10");
				}
				if ((parsedData.srcdnra !== undefined) && (parsedData.srcdnra !== null) && (parsedData.srcdnra.length !== 0)) {
					wb_new.SheetNames.push("CDNRA - 9C");
				}
				if ((parsedData.srcdnura !== undefined) && (parsedData.srcdnura !== null) && (parsedData.srcdnura.length !== 0)) {
					wb_new.SheetNames.push("CDNURA - 9C");
				}
				if ((parsedData.srexporta !== undefined) && (parsedData.srexporta !== null) && (parsedData.srexporta.length !== 0)) {
					wb_new.SheetNames.push("EXPA - 9A");
				}
				if ((parsedData.srata !== undefined) && (parsedData.srata !== null) && (parsedData.srata.length !== 0)) {
					wb_new.SheetNames.push("ATA - 11(2)");
				}
				if ((parsedData.sratadja !== undefined) && (parsedData.sratadja !== null) && (parsedData.sratadja.length !== 0)) {
					wb_new.SheetNames.push("ATADJA - 11(2)");
				}

				//format B2B data

				var b2bDataList = [];
				var b2bData = {};
				if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
					for (var i = 0; i < parsedData.srb2b.length; i++) {
						if (parsedData.srb2b[i].Status === "") {
							break;
						}

						b2bData =
						{
							'Receiver GSTIN/UIN*': parsedData.srb2b[i]["Receiver GSTIN/UIN*"],
							'Receiver Name*': parsedData.srb2b[i]["Receiver Name*"],
							'Invoice No.*': parsedData.srb2b[i]["Invoice No.*"],
							'Invoice Date*': parsedData.srb2b[i]["Invoice Date*"] ? parsedData.srb2b[i]["Invoice Date*"].padStart(9, "0") : parsedData.srb2b[i]["Invoice Date*"],
							'Invoice Value*': parsedData.srb2b[i]["Invoice Value*"],
							'Place of Supply*': parsedData.srb2b[i]["Place of Supply*"],
							'Reverse Charge*': parsedData.srb2b[i]["Reverse Charge*"],
							// 'Supply Type*': parsedData.srb2b[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srb2b[i]["Applicable % of Tax Rate"],
							'Invoice Type*': parsedData.srb2b[i]["Invoice Type*"] === "R" ? "Regular" : parsedData.srb2b[i]["Invoice Type*"],
							'E-Commerce GSTIN': parsedData.srb2b[i]["E-Commerce GSTIN"],
							'Rate*': parsedData.srb2b[i]["Rate*"],
							'Taxable Value*': parsedData.srb2b[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srb2b[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srb2b[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srb2b[i]["State/UT Tax*"],
							'Cess': parsedData.srb2b[i]["Cess"],
							'Status': parsedData.srb2b[i]["Status"]
						};
						b2bDataList.push(b2bData);
					}
				}
				//format B2CL data
				var b2clDataList = [];
				var b2clData = {};
				if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null)) {
					for (var i = 0; i < parsedData.srb2cl.length; i++) {
						if (parsedData.srb2cl[i].Status === "") {
							break;
						}
						b2clData =
						{
							'Invoice No.*': parsedData.srb2cl[i]["Invoice No.*"],
							'Invoice Date*': parsedData.srb2cl[i]["Invoice Date*"] ? parsedData.srb2cl[i]["Invoice Date*"].padStart(9, "0") : parsedData.srb2cl[i]["Invoice Date*"],
							'Invoice Value*': parsedData.srb2cl[i]["Invoice Value*"],
							'Place of Supply*': parsedData.srb2cl[i]["Place of Supply*"],
							'Applicable % of Tax Rate': parsedData.srb2cl[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srb2cl[i]["Rate*"],
							// 'Supply Type*': parsedData.srb2cl[i]["Supply Type*"],
							'Taxable Value*': parsedData.srb2cl[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srb2cl[i]["Integrated Tax*"],
							'Cess': parsedData.srb2cl[i]["Cess"],
							'E-Commerce GSTIN': parsedData.srb2cl[i]["E-Commerce GSTIN"],
							'Sale from Bonded WH': parsedData.srb2cl[i]["Sale from Bonded WH"],
							'Status': parsedData.srb2cl[i]["Status"]
						};
						b2clDataList.push(b2clData);
					}
				}
				//format B2CS data
				var b2csDataList = [];
				var b2csData = {};
				if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
					for (var i = 0; i < parsedData.srb2cs.length; i++) {
						if (parsedData.srb2cs[i].Status === '') {
							break;
						}
						b2csData =
						{
							'Invoice Type*': parsedData.srb2cs[i]["Invoice Type*"] === "R" ? "Regular" : parsedData.srb2cs[i]["Supply Type*"],
							'Place of Supply*': parsedData.srb2cs[i]["Place of Supply*"],
							// 'Supply Type*': parsedData.srb2cs[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srb2cs[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srb2cs[i]["Rate*"],
							'Taxable Value*': parsedData.srb2cs[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srb2cs[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srb2cs[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srb2cs[i]["State/UT Tax*"],
							'Cess': parsedData.srb2cs[i]["Cess"],
							'E-Commerce GSTIN': parsedData.srb2cs[i]["E-Commerce GSTIN"],
							'Status': parsedData.srb2cs[i]["status"]
						};
						b2csDataList.push(b2csData);
					}
				}
				//format CDNR data
				var cdnrDataList = [];
				var cdnrData = {};
				if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null)) {
					for (var i = 0; i < parsedData.srcdnr.length; i++) {
						if (parsedData.srcdnr[i].Status === "") {
							break;
						}
						cdnrData =
						{
							'Receiver GSTIN/UIN*': parsedData.srcdnr[i]["Receiver GSTIN/UIN*"],
							'Receiver Name*': parsedData.srcdnr[i]["Receiver Name*"],
							'Invoice/Advance Receipt Number*': parsedData.srcdnr[i]["Invoice/Advance Receipt Number*"],
							'Invoice/Advance Receipt Date*': parsedData.srcdnr[i]["Invoice/Advance Receipt Date*"] ? parsedData.srcdnr[i]["Invoice/Advance Receipt Date*"].padStart(9, "0") : undefined,
							'Note/Refund Voucher Number*': parsedData.srcdnr[i]["Note/Refund Voucher Number*"],
							'Note/Refund Voucher Date*': parsedData.srcdnr[i]["Note/Refund Voucher Date*"] ? parsedData.srcdnr[i]["Note/Refund Voucher Date*"].padStart(9, "0") : undefined,
							'Document Type*': parsedData.srcdnr[i]["Document Type*"],
							'Place of Supply*': parsedData.srcdnr[i]["Place of Supply*"],
							// 'Reason for Issuing Document*': parsedData.srcdnr[i]["Reason for Issuing Document*"],
							'Note/Refund Voucher Value*': parsedData.srcdnr[i]["Note/Refund Voucher Value*"],
							// 'Supply Type*': parsedData.srcdnr[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srcdnr[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srcdnr[i]["Rate*"],
							'Taxable Value*': parsedData.srcdnr[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srcdnr[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srcdnr[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srcdnr[i]["State/UT Tax*"],
							'Cess': parsedData.srcdnr[i]["Cess"],
							'Pre GST*': parsedData.srcdnr[i]["Pre GST*"],
							'Status': parsedData.srcdnr[i]["Status"]
						};
						cdnrDataList.push(cdnrData);
					}
				}
				//format CDNUR data
				var cdnurDataList = [];
				var cdnurData = {};
				if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null)) {
					for (var i = 0; i < parsedData.srcdnur.length; i++) {
						if (parsedData.srcdnur[i].Status === "") {
							break;
						}
						cdnurData =
						{
							'Invoice Type*': parsedData.srcdnur[i]['Invoice Type*'] === "R" ? "Regular" : parsedData.srcdnur[i]['Invoice Type*'],
							'Invoice/Advance Receipt Number*': parsedData.srcdnur[i]["Invoice/Advance Receipt Number*"],
							'Invoice/Advance Receipt Date*': parsedData.srcdnur[i]["Invoice/Advance Receipt Date*"] ? parsedData.srcdnur[i]["Invoice/Advance Receipt Date*"].padStart(9, "0") : parsedData.srcdnur[i]["Invoice/Advance Receipt Date*"],
							'Note/Refund Voucher Number*': parsedData.srcdnur[i]["Note/Refund Voucher Number*"],
							'Note/Refund Voucher Date*': parsedData.srcdnur[i]["Note/Refund Voucher Date*"] ? parsedData.srcdnur[i]["Note/Refund Voucher Date*"].padStart(9, "0") : parsedData.srcdnur[i]["Note/Refund Voucher Date*"],
							'Document Type*': parsedData.srcdnur[i]["Document Type*"],
							// 'Reason for Issuing Document*': parsedData.srcdnur[i]["Reason for Issuing Document*"],
							'Place of Supply*': parsedData.srcdnur[i]["Place of Supply*"],
							'Note/Refund Voucher Value*': parsedData.srcdnur[i]["Note/Refund Voucher Value*"],
							// 'Supply Type*': parsedData.srcdnur[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srcdnur[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srcdnur[i]["Rate*"],
							'Taxable Value*': parsedData.srcdnur[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srcdnur[i]["Integrated Tax*"],
							'Cess': parsedData.srcdnur[i]["Cess"],
							'Pre GST*': parsedData.srcdnur[i]["Pre GST*"],
							'Status': parsedData.srcdnur[i]["Status"]
						};
						cdnurDataList.push(cdnurData);
					}
				}
				//format EXPORTS data
				var exportsDataList = [];
				var exportsData = {};
				if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
					for (var i = 0; i < parsedData.srexport.length; i++) {
						if (parsedData.srexport[i].Status === "") {
							break;
						}
						exportsData =
						{
							'Export Type*': parsedData.srexport[i]["Export Type*"],
							'Invoice No.*': parsedData.srexport[i]["Invoice No.*"],
							'Invoice Date*': parsedData.srexport[i]["Invoice Date*"] ? parsedData.srexport[i]["Invoice Date*"].padStart(9, "0") : parsedData.srexport[i]["Invoice Date*"],
							'Invoice Value*': parsedData.srexport[i]["Invoice Value*"],
							'Port Code': parsedData.srexport[i]["Port Code"],
							'Shipping Bill No.': parsedData.srexport[i]["Shipping Bill No."],
							'Shipping Bill Date': parsedData.srexport[i]["Shipping Bill Date"] ? parsedData.srexport[i]["Shipping Bill Date"].padStart(9, "0") : parsedData.srexport[i]["Shipping Bill Date"],
							'Applicable % of Tax Rate': parsedData.srexport[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srexport[i]["Rate*"],
							'Taxable Value*': parsedData.srexport[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srexport[i]["Integrated Tax*"],
							'Cess': parsedData.srexport[i]["Cess"],
							'Status': parsedData.srexport[i]["Status"]
						};
						exportsDataList.push(exportsData);
					}
				}
				//format AT data
				var atDataList = [];
				var atData = {};
				if ((parsedData.srat !== undefined) && (parsedData.srat !== null)) {
					for (var i = 0; i < parsedData.srat.length; i++) {
						if (parsedData.srat[i].Status === "") {
							break;
						}
						atData =
						{
							'Place of Supply*': parsedData.srat[i]["Place of Supply*"],
							// 'Supply Type*': parsedData.srat[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srat[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srat[i]["Rate*"],
							'Gross Advance Received*': parsedData.srat[i]["Gross Advance Received*"],
							'Integrated Tax*': parsedData.srat[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srat[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srat[i]["State/UT Tax*"],
							'Cess': parsedData.srat[i]["Cess"],
							'Status': parsedData.srat[i]["Status"]
						};
						atDataList.push(atData);
					}
				}
				//format ATADJ data
				var atadjDataList = [];
				var atadjData = {};
				if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null)) {
					for (var i = 0; i < parsedData.sratadj.length; i++) {
						if (parsedData.sratadj[i].Status === "") {
							break;
						}
						atadjData =
						{
							'Place of Supply*': parsedData.sratadj[i]["Place of Supply*"],
							// 'Supply Type*': parsedData.sratadj[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.sratadj[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.sratadj[i]["Rate*"],
							'Gross Advance Adjusted*': parsedData.sratadj[i]["Gross Advance Adjusted*"],
							'Integrated Tax*': parsedData.sratadj[i]["Integrated Tax*"],
							'Central Tax*': parsedData.sratadj[i]["Central Tax*"],
							'State/UT Tax*': parsedData.sratadj[i]["State/UT Tax*"],
							'Cess': parsedData.sratadj[i]["Cess"],
							'Status': parsedData.sratadj[i]["Status"]
						};
						atadjDataList.push(atadjData);
					}
				}
				//format B2BA data
				var b2baDataList = [];
				var b2baData = {};
				if ((parsedData.srb2ba != undefined) && (parsedData.srb2ba != null)) {
					for (var i = 0; i < parsedData.srb2ba.length; i++) {
						if (parsedData.srb2ba[i].Status === "") {
							break;
						}
						b2baData =
						{
							'Receiver GSTIN/UIN*': parsedData.srb2ba[i]["Receiver GSTIN/UIN*"],
							'Receiver Name*': parsedData.srb2ba[i]["Receiver Name*"],
							'Original Invoice Number*': parsedData.srb2ba[i]["Original Invoice Number*"],
							'Revised Invoice Number*': parsedData.srb2ba[i]["Revised Invoice Number*"],
							'Revised Invoice Date*': parsedData.srb2ba[i]["Revised Invoice Date*"],
							'Revised Invoice Value*': parsedData.srb2ba[i]["Invoice Value*"],
							// 'Original Invoice Date*': parsedData.srb2ba[i]["Original Invoice Date*"],
							'Place of Supply*': parsedData.srb2ba[i]["Place of Supply*"],
							'Reverse Charge*': parsedData.srb2ba[i]["Reverse Charge*"],
							// 'Supply Type*': parsedData.srb2ba[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srb2ba[i]["Applicable % of Tax Rate"],
							'Invoice Type*': parsedData.srb2ba[i]["Invoice Type*"] === "R" ? "Regular" : parsedData.srb2ba[i]["Invoice Type*"],
							'E-Commerce GSTIN': parsedData.srb2ba[i]["E-Commerce GSTIN"],
							'Rate*': parsedData.srb2ba[i]["Rate*"],
							'Taxable Value*': parsedData.srb2ba[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srb2ba[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srb2ba[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srb2ba[i]["State/UT Tax*"],
							'Cess': parsedData.srb2ba[i]["Cess"],
							'Status': parsedData.srb2ba[i]["Status"]
						};
						console.log(b2baData);
						b2baDataList.push(b2baData);
						console.log(b2baDataList);
					}
				}
				//format B2CLA data
				var b2claDataList = [];
				var b2claData = {};
				if ((parsedData.srb2cla !== undefined) && (parsedData.srb2cla !== null)) {
					for (var i = 0; i < parsedData.srb2cla.length; i++) {
						if (parsedData.srb2cla[i].Status === "") {
							break;
						}
						b2claData =
						{
							'Original Invoice Number*': parsedData.srb2cla[i]["Original Invoice Number*"],
							'Revised Invoice Number*': parsedData.srb2cla[i]["Revised Invoice Number*"],
							'Revised Invoice Date*': parsedData.srb2cla[i]["Revised Invoice Date*"],
							'Revised Invoice Value*': parsedData.srb2cla[i]["Invoice Value*"],
							// 'Original Invoice Date*': parsedData.srb2cla[i]["Original Invoice Date*"],
							'Place of Supply*': parsedData.srb2cla[i]["Place of Supply*"],
							'Applicable % of Tax Rate': parsedData.srb2cla[i]["Applicable % of Tax Rate"],
							// 'Supply Type*': parsedData.srb2cla[i]["Supply Type*"],
							'Rate*': parsedData.srb2cla[i]["Rate*"],
							'Taxable Value*': parsedData.srb2cla[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srb2cla[i]["Integrated Tax*"],
							// 'Central Tax*': parsedData.srb2cla[i]["Central Tax*"],
							// 'State/UT Tax*': parsedData.srb2cla[i]["State/UT Tax*"],
							'Cess': parsedData.srb2cla[i]["Cess"],
							'E-Commerce GSTIN': parsedData.srb2cla[i]["E-Commerce GSTIN"],
							'Sale from Bonded WH': parsedData.srb2cla[i]["Sale from Bonded WH"],
							'Status': parsedData.srb2cla[i]["Status"]
						};
						b2claDataList.push(b2claData);
					}
				}
				//format B2CSA data
				var b2csaDataList = [];
				var b2csaData = {};
				if ((parsedData.srb2csa !== undefined) && (parsedData.srb2csa !== null)) {
					for (var i = 0; i < parsedData.srb2csa.length; i++) {
						if (parsedData.srb2csa[i].Status === '') {
							break;
						}
						b2csaData =
						{
							'Original Year*': parsedData.srb2csa[i]["Original Year*"],
							'Original Month*': parsedData.srb2csa[i]["Original Month*"],
							'Invoice Type*': parsedData.srb2csa[i]["Invoice Type*"] === "R" ? "Regular" : parsedData.srb2csa[i]["Invoice Type*"],
							'Original Place of Supply*': parsedData.srb2csa[i]["Original Place of Supply*"],
							'Revised Place of Supply*': parsedData.srb2csa[i]["Revised Place of Supply*"],
							// 'Supply Type*': parsedData.srb2csa[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srb2csa[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srb2csa[i]["Rate*"],
							'Taxable Value*': parsedData.srb2csa[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srb2csa[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srb2csa[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srb2csa[i]["State/UT Tax*"],
							'Cess': parsedData.srb2csa[i]["Cess"],
							'E-Commerce GSTIN': parsedData.srb2csa[i]["E-Commerce GSTIN"],
							'Status': parsedData.srb2csa[i]["status"]
						};
						b2csaDataList.push(b2csaData);
					}
				}
				//format CDNRA data
				var cdnraDataList = [];
				var cdnraData = {};
				if ((parsedData.srcdnra !== undefined) && (parsedData.srcdnra !== null)) {
					for (var i = 0; i < parsedData.srcdnra.length; i++) {
						if (parsedData.srcdnra[i].Status === "") {
							break;
						}
						cdnraData =
						{
							'Receiver GSTIN/UIN*': parsedData.srcdnra[i]["Receiver GSTIN/UIN*"],
							'Receiver Name*': parsedData.srcdnra[i]["Receiver Name*"],
							'Invoice/Advance Receipt Number*': parsedData.srcdnra[i]["Invoice/Advance Receipt Number*"],
							'Invoice/Advance Receipt Date*': parsedData.srcdnra[i]["Invoice/Advance Receipt Date*"],
							'Original Note/Refund Voucher Number*': parsedData.srcdnra[i]["Original Note/Refund Voucher Number*"],
							'Revised Note/Refund Voucher Number*': parsedData.srcdnra[i]["Revised Note/Refund Voucher Number*"],
							'Revised Note/Refund Voucher Date*': parsedData.srcdnra[i]["Revised Note/Refund Voucher Date*"] ? parsedData.srcdnra[i]["Revised Note/Refund Voucher Date*"].padStart(9, "0") : parsedData.srcdnra[i]["Revised Note/Refund Voucher Date*"],
							// 'Original Note/Refund Voucher Date*': parsedData.srcdnra[i]["Original Note/Refund Voucher Date*"],
							'Document Type*': parsedData.srcdnra[i]["Document Type*"],
							'Place of Supply*': parsedData.srcdnra[i]["Place of Supply*"],
							// 'Supply Type*': parsedData.srcdnra[i]["Supply Type*"],
							'Revised Note/Refund Voucher Value*': parsedData.srcdnra[i][" Revised Note/Refund Voucher Value*"],
							'Applicable % of Tax Rate': parsedData.srcdnra[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srcdnra[i]["Rate*"],
							'Taxable Value*': parsedData.srcdnra[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srcdnra[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srcdnra[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srcdnra[i]["State/UT Tax*"],
							'Cess': parsedData.srcdnra[i]["Cess"],
							'Pre GST*': parsedData.srcdnra[i]["Pre GST*"],
							'Status': parsedData.srcdnra[i]["Status"]
						};
						console.log(cdnraData);
						cdnraDataList.push(cdnraData);
						console.log(cdnraDataList);
					}
				}
				//format CDNURA data
				var cdnuraDataList = [];
				var cdnuraData = {};
				console.log(parsedData.srcdnura);
				if ((parsedData.srcdnura !== undefined) && (parsedData.srcdnura !== null)) {
					for (var i = 0; i < parsedData.srcdnura.length; i++) {
						console.log(parsedData.srcdnura[i]);
						if (parsedData.srcdnura[i].Status === "") {
							break;
						}
						console.log(parsedData.srcdnura[i]);
						cdnuraData =
						{
							'Invoice Type*': parsedData.srcdnura[i]["Invoice Type*"] === "R" ? "Regular" : parsedData.srcdnura[i]["Invoice Type*"],
							'Invoice/Advance Receipt Number*': parsedData.srcdnura[i]["Invoice/Advance Receipt Number*"],
							'Invoice/Advance Receipt Date*': parsedData.srcdnura[i]["Invoice/Advance Receipt Date*"] ? parsedData.srcdnura[i]["Invoice/Advance Receipt Date*"].padStart(9, "0") : parsedData.srcdnura[i]["Invoice/Advance Receipt Date*"],
							'Original Note/Refund Voucher Number*': parsedData.srcdnura[i]["Original Note/Refund Voucher Number*"],
							'Revised Note/Refund Voucher Number*': parsedData.srcdnura[i]["Revised Note/Refund Voucher Number*"],
							'Revised Note/Refund Voucher Date*': parsedData.srcdnura[i]["Revised Note/Refund Voucher Date*"] ? parsedData.srcdnura[i]["Revised Note/Refund Voucher Date*"].padStart(9, "0") : parsedData.srcdnura[i]["Revised Note/Refund Voucher Date*"],
							// 'Original Note/Refund Voucher Date*': parsedData.srcdnura[i]["Original Note/Refund Voucher Date*"],
							'Document Type*': parsedData.srcdnura[i]["Document Type*"],
							'Place of Supply*': parsedData.srcdnura[i]["Place of Supply*"],
							'Revised Note/Refund Voucher Value*': parsedData.srcdnura[i]["Revised Note/Refund Voucher Value*"],
							// 'Supply Type*': parsedData.srcdnura[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srcdnura[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srcdnura[i]["Rate*"],
							'Taxable Value*': parsedData.srcdnura[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srcdnura[i]["Integrated Tax*"],
							'Cess': parsedData.srcdnura[i]["Cess"],
							'Pre GST*': parsedData.srcdnura[i]["Pre GST*"],
							'Status': parsedData.srcdnura[i]["Status"]
						};
						console.log(cdnuraData);
						cdnuraDataList.push(cdnuraData);
						console.log(cdnuraDataList);
					}
				}
				//format EXPORTSA data
				var exportsaDataList = [];
				var exportsaData = {};
				if ((parsedData.srexporta !== undefined) && (parsedData.srexporta !== null)) {
					for (var i = 0; i < parsedData.srexporta.length; i++) {
						if (parsedData.srexporta[i].Status === "") {
							break;
						}
						exportsaData =
						{
							'Export Type*': parsedData.srexporta[i]["Export Type*"],
							'Original Invoice No.*': parsedData.srexporta[i]["Original Invoice No.*"],
							'Revised Invoice Number*': parsedData.srexporta[i]["Revised Invoice Number*"],
							// 'Original Invoice Date*': parsedData.srexporta[i]["Original Invoice Date*"],
							'Revised Invoice Date*': parsedData.srexporta[i]["Revised Invoice Date*"] ? parsedData.srexporta[i]["Revised Invoice Date*"].padStart(9, "0") : parsedData.srexporta[i]["Revised Invoice Date*"],
							'Revised Invoice Value*': parsedData.srexporta[i]["Revised Invoice Value*"],
							'Port Code': parsedData.srexporta[i]["Port Code"],
							'Shipping Bill No.': parsedData.srexporta[i]["Shipping Bill No."],
							'Shipping Bill Date': parsedData.srexporta[i]["Shipping Bill Date"] ? parsedData.srexporta[i]["Shipping Bill Date"].padStart(9, "0") : parsedData.srexporta[i]["Shipping Bill Date"],
							'Applicable % of Tax Rate': parsedData.srexporta[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srexporta[i]["Rate*"],
							'Taxable Value*': parsedData.srexporta[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srexporta[i]["Integrated Tax*"],
							'Cess': parsedData.srexporta[i]["Cess"],
							'Status': parsedData.srexporta[i]["Status"]
						};
						exportsaDataList.push(exportsaData);
					}
				}
				//format ATA data
				var ataDataList = [];
				var ataData = {};
				if ((parsedData.srata !== undefined) && (parsedData.srata !== null)) {
					for (var i = 0; i < parsedData.srata.length; i++) {
						if (parsedData.srata[i].Status === "") {
							break;
						}
						ataData =
						{
							'Original Year*': parsedData.srata[i]["Original Year*"],
							'Original Month*': parsedData.srata[i]["Original Month*"],
							'Original Place of Supply*': parsedData.srata[i]["Original Place of Supply*"],
							// 'Supply Type*': parsedData.srata[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.srata[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.srata[i]["Rate*"],
							'Gross Advance Received*': parsedData.srata[i]["Gross Advance Received*"],
							'Integrated Tax*': parsedData.srata[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srata[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srata[i]["State/UT Tax*"],
							'Cess': parsedData.srata[i]["Cess"],
							'Status': parsedData.srata[i]["Status"]
						};
						ataDataList.push(ataData);
					}
				}
				//format ATADJ data
				var atadjaDataList = [];
				var atadjaData = {};
				if ((parsedData.sratadja !== undefined) && (parsedData.sratadja !== null)) {
					for (var i = 0; i < parsedData.sratadja.length; i++) {
						if (parsedData.sratadja[i].Status === "") {
							break;
						}
						atadjaData =
						{
							'Original Year*': parsedData.sratadja[i]["Original Year*"],
							'Original Month*': parsedData.sratadja[i]["Original Month*"],
							'Original Place of Supply*': parsedData.sratadja[i]["Original Place of Supply*"],
							// 'Supply Type*': parsedData.sratadja[i]["Supply Type*"],
							'Applicable % of Tax Rate': parsedData.sratadja[i]["Applicable % of Tax Rate"],
							'Rate*': parsedData.sratadja[i]["Rate*"],
							'Gross Advance Adjusted*': parsedData.sratadja[i]["Gross Advance Adjusted*"],
							'Integrated Tax*': parsedData.sratadja[i]["Integrated Tax*"],
							'Central Tax*': parsedData.sratadja[i]["Central Tax*"],
							'State/UT Tax*': parsedData.sratadja[i]["State/UT Tax*"],
							'Cess': parsedData.sratadja[i]["Cess"],
							'Status': parsedData.sratadja[i]["Status"]
						};
						atadjaDataList.push(atadjaData);
					}
				}
				//format HSN data
				var hsnDataList = [];
				var hsnData = {};
				if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null)) {
					for (var i = 0; i < parsedData.srhsn.length; i++) {
						if (parsedData.srhsn[i].Status === "") {
							break;
						}
						hsnData =
						{
							'HSN*': parsedData.srhsn[i]["HSN*"],
							'Description*': parsedData.srhsn[i]["Description*"],
							'UQC*': parsedData.srhsn[i]["UQC*"],
							'Total Quantity*': parsedData.srhsn[i]["Total Quantity*"],
							'Total Value*': parsedData.srhsn[i]["Total Value*"],
							'Rate*': parsedData.srhsn[i]["Rate*"],
							'Taxable Value*': parsedData.srhsn[i]["Taxable Value*"],
							'Integrated Tax*': parsedData.srhsn[i]["Integrated Tax*"],
							'Central Tax*': parsedData.srhsn[i]["Central Tax*"],
							'State/UT Tax*': parsedData.srhsn[i]["State/UT Tax*"],
							'Cess': parsedData.srhsn[i]["Cess"],
							'Status': parsedData.srhsn[i]["Status"]
						};
						hsnDataList.push(hsnData);
					}
				}
				//format HSN data end
				//format EXEMP data
				var exempDataList = [];
				var exempData = {};
				if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null)) {
					for (var i = 0; i < parsedData.srnil.length; i++) {
						console.log(parsedData.srnil[i]);
						if (parsedData.srnil[i].Status === "") {
							break;
						}
						console.log(parsedData.srnil[i]);
						exempData = {
							'Description': parsedData.srnil[i]["Description"],
							'Nil Rated Supplies': parsedData.srnil[i]["Nil Rated Supplies"],
							'Exempted (Other than Nil Rated / Non-GST Supplies)': parsedData.srnil[i]["Exempted (Other than Nil Rated / Non-GST Supplies)"],
							'Non-GST Supplies': parsedData.srnil[i]["Non-GST Supplies"],
							'Status': parsedData.srnil[i]["Status"]
						};
						console.log(exempData);
						exempDataList.push(exempData);
						console.log(exempDataList);
					}
				}
				//format DOC data
				var docDataList = [];
				var docData = {};
				if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null)) {
					for (var i = 0; i < parsedData.srdoc.length; i++) {
						console.log(parsedData.srdoc[i]);
						if (parsedData.srdoc[i].Status === "") {
							break;
						}
						console.log(parsedData.srdoc[i]);
						docData = {
							'Nature of Document*': parsedData.srdoc[i]["Nature of Document*"],
							'Sr. No. From*': parsedData.srdoc[i]["Sr. No. From*"],
							'Sr. No. To*': parsedData.srdoc[i]["Sr. No. To*"],
							'Total Number*': parsedData.srdoc[i]["Total Number*"],
							'Cancelled*': parsedData.srdoc[i]["Cancelled*"],
							'Status': parsedData.srdoc[i]["Status"]
						};
						console.log(docData);
						docDataList.push(docData);
						console.log(docDataList);
					}
				}

				if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null) && (parsedData.srb2b.length !== 0)) {
					wb_new.Sheets["B2B - 4A, 4B, 4C, 6B, 6C"] = XLSX.utils.json_to_sheet(b2bDataList);
				}
				if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null) && (parsedData.srb2cl.length !== 0)) {
					wb_new.Sheets["B2CL - 5A, 5B"] = XLSX.utils.json_to_sheet(b2clDataList);
				}
				if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null) && (parsedData.srb2cs.length !== 0)) {
					wb_new.Sheets["B2CS - 7"] = XLSX.utils.json_to_sheet(b2csDataList);
				}
				if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null) && (parsedData.srcdnr.length !== 0)) {
					wb_new.Sheets["CDNR - 9B"] = XLSX.utils.json_to_sheet(cdnrDataList);
				}
				if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null) && (parsedData.srcdnur.length !== 0)) {
					wb_new.Sheets["CDNUR - 9B"] = XLSX.utils.json_to_sheet(cdnurDataList);
				}
				if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null) && (parsedData.srexport.length !== 0)) {
					wb_new.Sheets["EXP - 6A"] = XLSX.utils.json_to_sheet(exportsDataList);
				}
				if ((parsedData.srat !== undefined) && (parsedData.srat !== null) && (parsedData.srat.length !== 0)) {
					wb_new.Sheets["AT - 11A(1), 11A(2)"] = XLSX.utils.json_to_sheet(atDataList);
				}
				if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null) && (parsedData.sratadj.length !== 0)) {
					wb_new.Sheets["ATADJ - 11B(1), 11B(2)"] = XLSX.utils.json_to_sheet(atadjDataList);
				}
				if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null) && (parsedData.srhsn.length !== 0)) {
					wb_new.Sheets["HSN - 12"] = XLSX.utils.json_to_sheet(hsnDataList);
				}
				if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null) && (parsedData.srnil.length !== 0)) {
					wb_new.Sheets["EXEMP - 8A, 8B, 8C, 8D"] = XLSX.utils.json_to_sheet(exempDataList);
				}
				if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null) && (parsedData.srdoc.length !== 0)) {
					wb_new.Sheets["DOC - 13"] = XLSX.utils.json_to_sheet(docDataList);
				}
				if ((parsedData.srb2ba !== undefined) && (parsedData.srb2ba !== null) && (parsedData.srb2ba.length !== 0)) {
					wb_new.Sheets["B2BA - 9A"] = XLSX.utils.json_to_sheet(b2baDataList);
				}
				if ((parsedData.srb2cla !== undefined) && (parsedData.srb2cla !== null) && (parsedData.srb2cla.length !== 0)) {
					wb_new.Sheets["B2CLA - 9A"] = XLSX.utils.json_to_sheet(b2claDataList);
				}
				if ((parsedData.srb2csa !== undefined) && (parsedData.srb2csa !== null) && (parsedData.srb2csa.length !== 0)) {
					wb_new.Sheets["B2CSA - 10"] = XLSX.utils.json_to_sheet(b2csaDataList);
				}
				if ((parsedData.srcdnra !== undefined) && (parsedData.srcdnra !== null) && (parsedData.srcdnra.length !== 0)) {
					wb_new.Sheets["CDNRA - 9C"] = XLSX.utils.json_to_sheet(cdnraDataList);
				}
				if ((parsedData.srcdnura !== undefined) && (parsedData.srcdnura !== null) && (parsedData.srcdnura.length !== 0)) {
					wb_new.Sheets["CDNURA - 9C"] = XLSX.utils.json_to_sheet(cdnuraDataList);
				}
				if ((parsedData.srexporta !== undefined) && (parsedData.srexporta !== null) && (parsedData.srexporta.length !== 0)) {
					wb_new.Sheets["EXPA - 9A"] = XLSX.utils.json_to_sheet(exportsaDataList);
				}
				if ((parsedData.srata !== undefined) && (parsedData.srata !== null) && (parsedData.srata.length !== [])) {
					wb_new.Sheets["ATA - 11(2)"] = XLSX.utils.json_to_sheet(ataDataList);
				}
				if ((parsedData.sratadja !== undefined) && (parsedData.sratadja !== null) && (parsedData.sratadja.length !== 0)) {
					wb_new.Sheets["ATADJA - 11(2)"] = XLSX.utils.json_to_sheet(atadjaDataList);
				}

			}
			var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });
			var btn = document.getElementById("sales_download");

			btn.onclick = function () {
				var cur_dt = new Date();
				fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "SALES - " + cur_dt + ".xlsx");
			};
			if (parsedData.flag === true) {
				document.getElementById("sales_confirm_upload").click();
			}
			return parsedData;
		})
	},
	downloadExport: (srexport) => {

		function s2ab(s) {
			var buf = new ArrayBuffer(s.length);
			var view = new Uint8Array(buf);
			for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
			return buf;
		}
		var wb_new = XLSX.utils.book_new();
		if ((srexport !== undefined) && (srexport !== null) && (srexport.length !== 0)) {
			wb_new.SheetNames.push("EXP - 6A");
		}

		var exportsDataList = [];
		if ((srexport !== undefined) && (srexport !== null)) {
			const ExportData = flow(map(Inv => {
				const InvoiceItems = map(InvoiceItems => {
					return {
						'Export Type*': Inv.exp_type,
						'Invoice No.*': Inv.invoice_number,
						'Invoice Date*': Inv.invoice_date ? Inv.invoice_date.padStart(9, "0") : Inv.invoice_date,
						'Invoice Value*': Inv.invoice_value,
						'Applicable % of Tax Rate': Inv.atr,
						'Port Code': Inv.sb_port_code,
						'Shipping Bill No.': Inv.sb_number,
						'Shipping Bill Date': Inv.sb_date ? Inv.sb_date.padStart(9, "0") : Inv.sb_date,
						'Rate*': InvoiceItems.gst_rate,
						'Taxable Value*': InvoiceItems.taxable_value,
						'Integrated Tax*': InvoiceItems.igst,
						'Cess': InvoiceItems.cess,
					}
				})(Inv.sr_export_items.edges)
				return InvoiceItems;
			}),
				flattenDeep)(srexport)

			exportsDataList = ExportData;
		}

		if ((srexport !== undefined) && (srexport !== null) && (srexport.length !== 0)) {
			wb_new.Sheets["EXP - 6A"] = XLSX.utils.json_to_sheet(exportsDataList);
		}

		var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });

		var cur_dt = new Date();

		return fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "Export Summary - " + cur_dt + ".xlsx");

	},
	//  GSTR1_B2B: (b2bData) => {

	//     function s2ab(s) {
	//       var buf = new ArrayBuffer(s.length);
	//       var view = new Uint8Array(buf);
	//       for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	//       return buf;
	//     }
	//     var wb_new = XLSX.utils.book_new();
	//     if ((b2bData !== undefined) && (b2bData !== null) && (b2bData.length !== 0)) {
	//       wb_new.SheetNames.push("B2B");
	//     }

	// 		var b2bDataList = [];
	// 		if ((b2bData !== undefined) && (b2bData !== null)) {
	// 			const B2BData = flow(map(Inv => {
	// 				const InvoiceItems = map(InvoiceItems => {
	// 					return {
	//              'GSTIN of Supplier': Inv.supplier_gstin,
	//              'Supplier Name': Inv.receiver_name,
	//              'Invoice Number': Inv.invoice_number,
	//              'Invoice date': Inv.invoice_date,
	//              'Invoice Value': parseFloat(Inv.invoice_value),
	//              'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
	//              'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
	//              'Reverse Charge': Inv.reverse_charge,
	//              'Rate': parseFloat(InvoiceItems.gst_rate),
	//              'Taxable Value': parseFloat(InvoiceItems.taxable_value),
	//              'Integrated Tax Paid': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
	//              'Central Tax Paid': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
	//              'State/UT Tax Paid': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
	//              'Cess Paid': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
	//              'Counter-Party Filing Status': (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted'),
	// 				}
	// 			})(Inv.sr_export_items.edges)
	// 				return InvoiceItems;
	// 			}),
	// 		flattenDeep)(b2bData)

	// 		b2bDataList = B2BData;
	// 		}

	// 		if ((b2bData !== undefined) && (b2bData !== null) && (b2bData.length !== 0)){
	//       wb_new.Sheets["B2B"] = XLSX.utils.json_to_sheet(b2bDataList);
	//     }

	// 		var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });

	//     var cur_dt = new Date();

	//     return fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "Export Summary - " + cur_dt + ".xlsx");

	// },
	// upload_tally: ({ file, type, companyId, isSR, finMonth, finYear }) =>
	// 	{
	//     	const data = new FormData()

	//     	data.append(type, file)
	//    	 	data.append('companyId', companyId)
	//     	data.append('isSR', isSR)
	// 			data.append('finMonth', finMonth)
	//     	data.append('finYear', finYear)

	// 		return fetch(`${BASE_URL}/upload/${type}`,
	// 		{
	//       		method: 'POST',
	//       		body: data,
	// 		}).then(res => res.json()).then(function(data)
	// 		{
	// 				console.log(data);
	// 				return data;
	// 		})

	// 	},
}

export default Sales
