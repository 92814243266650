import React from 'react'
import glamorous from 'glamorous'
import Tooltip from 'antd/lib/tooltip'
import Table from 'antd/lib/table'
import Button from 'antd/lib/button'
import { Popover, Popconfirm, notification, Icon, Dropdown, Menu, Empty } from 'antd';
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import get from 'lodash/fp/get'
import { SR_RET_Period_Query, CompanyByIdQuery, GSTR1_Query } from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { ROUTES } from '../../shared/constants'
import { connect } from 'react-redux'
import DropZone from 'react-dropzone'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import SALES from '../../services/SalesService'
import RECON_SALES from '../../services/RECONSalesService'
import { BASE_URL } from '../../shared/constants'
import excelUtility from "../../services/GSTR1_Excel_Service";
import gspAuth from "../../services/GSPService";
import { actions } from '../../redux'
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import flow from "lodash/fp/flow";
import toString from "lodash/fp/toString";
import map from "lodash/fp/map";
import pick from "lodash/fp/pick";
import moment from 'moment';
import { Link } from 'react-router-dom'
const add_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.5', padding: '0 15px', fontSize: '13.5px', borderRadius: '5px', height: '34px', mozUserSelect: 'none', position: 'relative', color: '#000000a6', backgroundColor: 'rgb(49, 85, 111)', borderColor: '#d9d9d9', paddingTop: '5px' };
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px', textAlign: 'center' };
const button_style = { width: '200px' };
var comp_id = '...'
const { Column } = Table
const SRow = glamorous(Row)({ margin: '15px 0', })
const confirm = Modal.confirm;
const scroll = { x: 1600 };
const actionButton = { paddingLeft: '0px', paddingRight: '0px' };
var fileSaver = require('file-saver')
var blob = require('blob-util')


const SalesRegister = props => {
  function getCDNRNumber(record) {
    if (record.cdnr_number !== undefined) {
      return record.cdnr_number;
    }
    else {
      return 1;
    }
  }

  function capitalize(text) {
    return text.toUpperCase();
  }

  function showConfirm() {
    confirm({
      title: 'Errors Found While Uploading Sales',
      iconType: 'printer',
      width: 866,
      content: (<div id="dialogue_append1"></div>),
      onOk() {
        document.getElementById("sales_download").click();
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!'));
      }
    });
  }
  function info() {
    Modal.info({
      title: 'This is a notification message',
      iconType: 'delete',
      width: 450,
      content: (
        <div>
          <p id="dynamic_error_msg"></p>
        </div>
      ),
      onOk() { },
    });
  }


  return (
    <Root>

      <Content>
        <SRow gutter={24} style={row_style}>
          <span>
            <Button size="large" className="{ant-btn-primary}" id="sales_download" >Download</Button>
          </span>
          <span> <Button id="sales_confirm_upload" onClick={showConfirm}></Button></span>
          <span> <Button id="pull_button_click" onClick={props.getAllGSTR1Data}></Button></span>
          <span> <Button id="pull_external_click" onClick={props.fetchFromExternalAPI}></Button></span>
          {!props.externalInterfaceInfo && (
            <Col span="5">
              <DropZone onDrop={props.onDropSR} multiple={false} disabled={props.role === 'reviewer'}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Tooltip title="Upload Sales Invoices (ElixirGST)" overlay="">
                      <Button size="large" icon="cloud-upload" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} disabled={props.role === 'reviewer'}>
                        Upload Sales (TG)
                        </Button>
                    </Tooltip>
                  </div>
                )}
              </DropZone>
            </Col>)}

          {!props.externalInterfaceInfo && (
            <Col span="5">
              <DropZone onDrop={props.onDropTallySR} multiple={false} disabled={props.role === 'reviewer'}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Tooltip title="Upload Sales Invoices (Tally)" overlay="">
                      <Button size="large" icon="cloud-upload" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} disabled={props.role === 'reviewer'}>
                        Upload Sales (Tally)
                        </Button>
                    </Tooltip>
                  </div>
                )}
              </DropZone>
            </Col>)}
          {props.externalInterfaceInfo && (
            <Col span="5">
              <Tooltip title="Pull all invoices for selected Tax Period. (Existing data will be overwritten by data from ERP)" placement="top">
                <Button size="large" icon="cloud-download" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} disabled={props.role === 'reviewer'} onClick={props.confirmFetchfromExternalAPI}>Pull Sales (iSteer)</Button>
              </Tooltip>
            </Col>
          )}

          <Col span="5">
            <Tooltip title="Pull all invoices for selected Tax Period. (Existing data will be overwritten by data from GSTN)" placement="top">
              <Button size="large" icon="cloud-download" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} disabled={props.role === 'reviewer'} onClick={props.fetchAllGSTR_One}>Pull from GSTN</Button>
            </Tooltip>
          </Col>

          <Col span="5">
            <Tooltip title="Download all invoices in Excel Format for selected Tax Period from Sales Register" overlay="">

              <Button size="large" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#04880fe0', color: 'white' }} icon="file-excel" disabled={props.role === 'reviewer'} onClick={props.excelUtility}>Download SR</Button>

            </Tooltip>
          </Col>



          <Col span="4">
            <Tooltip title="Clear all Sales for selected Tax Period" overlay="">
              <Popconfirm placement="bottomRight" title="Clicking this button will reset all Sales for selected Tax Period!" onVisibleChange={props.popupConfirmResetVisibleChange} visible={props.popupConfirmReset} onConfirm={props.resetSales} okText="Yes" cancelText="No">
                <Button size="large" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#a01208de', color: 'white' }} icon="safety" disabled={props.role === 'reviewer'} >Reset</Button>
              </Popconfirm>
            </Tooltip>
          </Col>

          <span>
            <Button id="sales_error_status" onClick={info}>Info</Button>
          </span>
        </SRow>

        <Empty
          className='showEmpty'
          style={{ marginTop: '120px' }}
          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
          description={
            <div>
              <span>
                If you are done uploading sales invoices click the button below to preview and push them to GSTN!
            </span>
            </div>
          }
        >
          <a style={add_button} className='tour_uploadGstr1'>
            <Icon style={{ fontSize: '13.5px', color: '#FFFFFF' }} type="rollback" /> &nbsp; <Link to={ROUTES.GST_FILINGS_GSTR1}>GSTR - 1 (GST Filings)</Link>
          </a>
        </Empty>

        <SRow gutter={24}>
          <Table style={{ display: 'none' }} dataSource={props.output} bordered loading={props.loading} title={() => 'Reconciliation Summary'} pagination={false}>
            <Column title="SL No." dataIndex="sl_no" className="alignCenter" key="sl_no" />
            <Column title="Type" dataIndex="type" key="type" />
            <Column title="Count" dataIndex="count" className="alignCenter" key="count" />
            <Column title="Action" className="alignCenter" key="action"
              render={(text, record, index) => {
                return (
                  <Tooltip title="List Detailed Summary">
                    <Button icon="inbox" type="success" onClick={() => props.showModal(record.sl_no)}></Button>
                    <Modal title="Sales - Mismatched Invoices Summary" visible={props.visibleModal} onCancel={props.hideModal} footer={null} width="1120">
                      <Table dataSource={props.recon_mismatches} bordered scroll={scroll} pagination={{ className: 'my-pagination', defaultPageSize: 2 }} >
                        <Column title="Type" dataIndex="type" key="type" className="alignCenter" fixed="left" width="130" render={text => capitalize(text)} />
                        <Column title="Invoice Number" dataIndex="invoice_number" className="alignCenter" key="invoice_number" fixed="left" width="120" />
                        <Column title="GSTIN" dataIndex="receiver_gstin" className="alignCenter" key="receiver_gstin" width="160" />
                        <Column title="Invoice Date" dataIndex="invoice_date" className="alignCenter" key="invoice_date" width="120" />
                        <Column title="Invoice Value" dataIndex="invoice_value" className="alignCenter" key="invoice_value" width="120" />
                        <Column title="Invoice Type" dataIndex="invoice_type" className="alignCenter" key="invoice_type" width="120" />
                        <Column title="POS" dataIndex="pos_id" key="pos_id" className="alignCenter" width="110" />
                        <Column title="Revesre Charge" dataIndex="reverse_charge" className="alignCenter" key="reverse_charge" width="70" />
                        <Column title="CDNR Number" dataIndex="cdnr_number" className="alignCenter" key="cdnr_number" width="120" />
                        <Column title="CDNR Date" dataIndex="cdnr_date" className="alignCenter" key="cdnr_date" width="120" />
                        <Column title="CDNR Value" dataIndex="cdnr_value" className="alignCenter" key="cdnr_value" width="120" />
                        <Column title="CDNR Type" dataIndex="cdnr_type" className="alignCenter" key="cdnr_type" width="60" />
                        <Column title="Action" key="action" fixed="right" className="alignCenter" rowSpan="2"
                          render={(text, record, index) => {
                            var final_button_generation =
                              <SRow gutter={24} >
                                <Col span="8" style={{ paddingLeft: '0px' }}>
                                  <Tooltip title="Consider Sales Invoice" overlay="">
                                    <Button icon="rocket" loading={props.sales_loading} type="success" style={{ width: '100%', paddingRight: '35px' }} data-id={record.type} data-value={record.invoice_number} data-number={getCDNRNumber(record)} onClick={props.takePurchase}>
                                      Accept Sales
                                    </Button>
                                  </Tooltip>
                                </Col>
                                <Col span="8" style={actionButton}>
                                  <Tooltip title="Consider GST Invoice" overlay="">
                                    <Button icon="rocket" loading={props.gst_loading} type="primary" data-id={record.type} data-value={record.invoice_number} data-number={getCDNRNumber(record)} onClick={props.takeGST}>
                                      Accept GST
                                    </Button>
                                  </Tooltip>
                                </Col>
                                <Col span="8" style={{ paddingLeft: '0px' }}>
                                  <Tooltip title="Keep it Pending" overlay="">
                                    <Button icon="rocket" loading={props.pending_load} type="danger" style={{ width: '100%' }} data-id={record.type} data-call="MP" data-value={record.invoice_number} onClick={props.keepPending}>
                                      Pend it
                                    </Button>
                                  </Tooltip>
                                </Col>
                              </SRow>
                            var but =
                              <SRow gutter={24} >
                                <Col span="2"></Col>
                                <Col span="8">
                                  {/* <Button icon="bars" type="primary" onClick={() => props.showItemModal(record.id)}>Item(s)
                                    <SR_Mismatch_Items isVisible={props.visibleItemModal === record.id} onCancel={props.hideItemModal} sr_match_items={record.items} />
                                  </Button> */}
                                  <Button icon="bars" type="primary" onClick={() => props.showItemModal(record.id)}>
                                    Items
                                  </Button>
                                  <Modal title="List Items Summary" visible={props.visibleItemModal} onCancel={props.hideItemModal} footer={null} width="920">
                                    <Table dataSource={record.items} bordered loading={props.loading} scroll={scroll} style={{ textAlign: 'center' }} pagination={{ className: 'recon-item-my-pagination', defaultPageSize: 2 }} >
                                      <Column title="TYPE" dataIndex="item_type" className="alignCenter" key="item_type" width="100" />
                                      <Column title="Rate" dataIndex="gst_rate" className="alignCenter" key="gst_rate" width="100" />
                                      <Column title="Taxable Value" dataIndex="taxable_value" className="alignCenter" key="taxable_value" width="100" />
                                      <Column title="IGST" dataIndex="igst" className="alignCenter" key="igst" width="100" />
                                      <Column title="CGST" dataIndex="cgst" className="alignCenter" key="cgst" width="100" />
                                      <Column title="SGST" dataIndex="sgst" className="alignCenter" key="sgst" width="100" />
                                      <Column title="Cess" dataIndex="cess" className="alignCenter" key="cess" width="100" />
                                    </Table>
                                  </Modal>
                                </Col>
                                <Col span="4"></Col>
                                <Col span="8">
                                  <Popover content={final_button_generation} title="Details">
                                    <Button icon="export" type="primary" onClick={() => console.log('button clicked')}>
                                      Action
                                    </Button>
                                  </Popover>
                                </Col>
                                <Col span="2"></Col>
                              </SRow>
                            const obj = {
                              children: but,
                              props: { rowSpan: 2 },
                            };
                            var temp = (index) % 2;
                            if (temp === 0) {
                              return (but, obj)
                            }
                          }}
                        />
                      </Table>
                    </Modal>
                    <Modal title="Sales - Matched Invoices Summary" visible={props.visibleMatchModal} onCancel={props.hideMatchModal} footer={null} width="1120">
                      <Table dataSource={props.recon_matches} bordered scroll={scroll} pagination={{ className: 'my-pagination', defaultPageSize: 2 }} >
                        <Column title="Type" dataIndex="type" className="alignCenter" key="type" fixed="left" width="120" render={text => capitalize(text)} />
                        <Column title="Invoice Number" dataIndex="invoice_number" className="alignCenter" key="invoice_number" fixed="left" width="120" />
                        <Column title="GSTIN" dataIndex="receiver_gstin" className="alignCenter" key="receiver_gstin" width="160" />
                        <Column title="Invoice Date" dataIndex="invoice_date" className="alignCenter" key="invoice_date" width="120" />
                        <Column title="Invoice Value" dataIndex="invoice_value" className="alignCenter" key="invoice_value" width="120" />
                        <Column title="Invoice Type" dataIndex="invoice_type" className="alignCenter" key="invoice_type" width="120" />
                        <Column title="POS" dataIndex="pos_id" className="alignCenter" key="pos_id" width="160" />
                        <Column title="Revesre Charge" dataIndex="reverse_charge" className="alignCenter" key="reverse_charge" width="140" />
                        <Column title="CDNR Number" dataIndex="cdnr_number" className="alignCenter" key="cdnr_number" width="120" />
                        <Column title="CDNR Date" dataIndex="cdnr_date" className="alignCenter" key="cdnr_date" width="120" />
                        <Column title="CDNR Value" dataIndex="cdnr_value" className="alignCenter" key="cdnr_value" width="120" />
                        <Column title="CDNR Type" dataIndex="cdnr_type" className="alignCenter" key="cdnr_type" width="120" />
                      </Table>
                    </Modal>
                    <Modal title="Only in Sales and not in GST Summary" visible={props.visibleOneModal} onCancel={props.hideOneModal} footer={null} width="1120">
                      <Table dataSource={props.recon_one} bordered scroll={scroll} pagination={{ className: 'my-pagination', defaultPageSize: 2 }} >
                        <Column title="Invoice Number" dataIndex="invoice_number" className="alignCenter" key="invoice_number" fixed="left" width="120" />
                        <Column title="GSTIN" dataIndex="receiver_gstin" className="alignCenter" key="receiver_gstin" width="120" />
                        <Column title="Invoice Date" dataIndex="invoice_date" className="alignCenter" key="invoice_date" width="120" />
                        <Column title="Invoice Value" dataIndex="invoice_value" className="alignCenter" key="invoice_value" width="120" />
                        <Column title="Invoice Type" dataIndex="invoice_type" className="alignCenter" key="invoice_type" width="120" />
                        <Column title="POS" dataIndex="pos_id" className="alignCenter" key="pos_id" width="160" />
                        <Column title="Revesre Charge" dataIndex="reverse_charge" className="alignCenter" key="reverse_charge" width="120" />
                        <Column title="CDNR Number" dataIndex="cdnr_number" className="alignCenter" key="cdnr_number" width="120" />
                        <Column title="CDNR Date" dataIndex="cdnr_date" className="alignCenter" key="cdnr_date" width="120" />
                        <Column title="CDNR Value" dataIndex="cdnr_value" className="alignCenter" key="cdnr_value" width="120" />
                        <Column title="CDNR Type" dataIndex="cdnr_type" className="alignCenter" key="cdnr_type" width="120" />
                        <Column title="Action" className="alignCenter" key="action" fixed="right" width="250" rowSpan="2"
                          render={(text, record, index) => {
                            return (<SRow gutter={24} >
                              <Col span="12">
                                <Tooltip title="Consider Sales Invoice" overlay="">
                                  <Button icon="rocket" type="danger" data-id={record.type} data-value={record.invoice_number} loading={props.sales_loading} data-number={getCDNRNumber(record)} onClick={props.takePurchase}>
                                    Accept Sales
                                    </Button>
                                </Tooltip>
                              </Col>
                              <Col span="12">
                                <Tooltip title="Keep it Pending" overlay="">
                                  <Button icon="rocket" type="primary" data-id={record.type} data-value={record.invoice_number} loading={props.pending_load} data-call="SP" data-number={getCDNRNumber(record)} onClick={props.keepPending}>
                                    Pend it
                                    </Button>
                                </Tooltip>
                              </Col>
                            </SRow>)
                          }}
                        />
                      </Table>
                    </Modal>
                    <Modal title="Only in GST and not in Sales Summary" visible={props.visibleTwoModal} onCancel={props.hideTwoModal} footer={null} width="1120">
                      <Table dataSource={props.recon_two} bordered scroll={scroll} pagination={{ className: 'my-pagination', defaultPageSize: 2 }} >
                        <Column title="Invoice Number" dataIndex="invoice_number" className="alignCenter" key="invoice_number" fixed="left" width="120" />
                        <Column title="GSTIN" dataIndex="receiver_gstin" className="alignCenter" key="receiver_gstin" width="120" />
                        <Column title="Invoice Date" dataIndex="invoice_date" className="alignCenter" key="invoice_date" width="120" />
                        <Column title="Invoice Value" dataIndex="invoice_value" className="alignCenter" key="invoice_value" width="120" />
                        <Column title="Invoice Type" dataIndex="invoice_type" className="alignCenter" key="invoice_type" width="120" />
                        <Column title="POS" dataIndex="pos_id" className="alignCenter" key="pos_id" width="160" />
                        <Column title="Revesre Charge" dataIndex="reverse_charge" className="alignCenter" key="reverse_charge" width="120" />
                        <Column title="CDNR Number" dataIndex="cdnr_number" className="alignCenter" key="cdnr_number" width="120" />
                        <Column title="CDNR Date" dataIndex="cdnr_date" className="alignCenter" key="cdnr_date" width="120" />
                        <Column title="CDNR Value" dataIndex="cdnr_value" className="alignCenter" key="cdnr_value" width="120" />
                        <Column title="CDNR Type" dataIndex="cdnr_type" className="alignCenter" key="cdnr_type" width="120" />
                        <Column title="Action" className="alignCenter" key="action" fixed="right" width="250" rowSpan="2"
                          render={(text, record, index) => {
                            return (<SRow gutter={24} >
                              <Col span="24">
                                <Tooltip title="Consider GST Invoice" overlay="">
                                  <Button icon="rocket" type="danger" data-id={record.type} data-value={record.invoice_number} loading={props.gst_loading} data-number={getCDNRNumber(record)} onClick={props.takeGST}>
                                    Accept GST
                                    </Button>
                                </Tooltip>
                              </Col>
                            </SRow>)
                          }}
                        />
                      </Table>
                    </Modal>
                  </Tooltip>
                )
              }}
            />
          </Table>
        </SRow>
        <script type="text/javascript" src="FileSaver.js"></script>
      </Content>
    </Root>
  )
}

class SalesRegisterContainer extends React.Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year = curyear + '-' + nextyear;

    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
        response_delete: null,
      };
    } else {
      this.gsp = {};
    }

    this.downloadSales = this.downloadSales.bind(this);
    this.onDropSR = this.onDropSR.bind(this);
    this.takeGST = this.takeGST.bind(this);
    this.keepPending = this.keepPending.bind(this);
    message.config({
      maxCount: 1,
    });
    this.state = {
      visibleModal: null,
      visibleItemModal: null,
      visibleMatchModal: null,
      visibleMatchItemModal: null,
      visibleOneModal: null,
      visibleOneItemModal: null,
      visibleTwoModal: null,
      visibleTwoItemModal: null,
      finMonth: props.finMonth,
      finYear: props.finYear,
      curFinMonth: n + 1,
      curFinYear: year,
      companyId: props.selectedCompany,
      output: [],
      recon_mismatches: [],
      recon_matches: [],
      recon_one: [],
      recon_two: [],
      downloadLoading: false,
      resetLoading: false,
      takePurchase: null,
      recon_loading: false,
      sales_loading: false,
      gst_loading: false,
      pending_load: false,
      popupConfirmReset: false,
      popupConfirmPull: false,
      visible: false,
      downloadLoading: false,
      requestOTP: false,
      otp: null,
      period: null,
      tallyTGSwitchState: null

    }
    comp_id = props.selectedCompany;
    this.props.gstr1summary.refetch();
  }

  tallyTGSwitch = (e) => {

    // console.log(e.key);
    this.setState({
      tallyTGSwitchState: e.key
    });

  }

  //fetch GSTR1 Data
  componentDidUpdate = () => {
    // console.log(this.props);
    // console.log(this.prevProps);
    // console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    this.setState({ requestOTP: false });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.getAllGSTR1Data();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };


  hide = () => {
    this.setState({
      visible: false
    });
  };

  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  };



  getAllGSTR1Data = async () => {

    var downloaddate = this.state.finMonth + '/' + this.state.finYear;

    message.destroy();
    message.loading('Fetching from GSTN...', 0)

    const resetSales = fetch(`${BASE_URL}/export/resetSales?from=sales&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: 'GET',
      })
      .then((result) => {
        console.log(result);
      })


    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const reqDataValues = {

      actionARRAY: ["B2B", "B2CL", "B2CS", "CDNR", "CDNUR", "EXP", "AT", "TXP", "NIL", "HSNSUM", "DOCISS",
        "B2BA", "B2CLA", "B2CSA", "CDNRA", "CDNURA", "EXPA", "ATA", "TXPA"
      ]
    }

    // console.log(reqDataValues);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      period: period,
    };

    // console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    // console.log(this.gsp.response);

    // message.destroy();

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (this.gsp.response.data.errorCode && this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST") {
        message.destroy();
        this.setState({ requestOTP: true });
      }
    }


    if (this.gsp.response.data.success || this.gsp.response.success) {
      // console.log('Done Ledger');
      const gstr1ARCHIVES_Loop_Action = map(
        async d => {
          console.log(d);
          const reqData = {
            companyDetails: companyDetails,
            gspDetails: {
              gspToken: this.gsp.access_token,
              otp: this.gsp.otp
            },
            period: period,
            action: d
          };
          // console.log(reqData);
          this.gsp.response = await gspAuth.getB2BData(reqData);
          // console.log(this.gsp.response);

          if (this.gsp.response.status) {
            message.destroy();
            notification.destroy();
            notification.open({
              message: 'Success',
              description: <p>GSTR1 data pulled successfully for the period {moment(this.props.finMonth).format('MMMM')} {this.props.finYear}.</p>,
              duration: 0,
              icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
            });
            this.props.gstr1summary.refetch()
          }
        }
      )(reqDataValues.actionARRAY)

    }

  }

  compareSales = () => {
    this.setState({ recon_loading: true });
    this.props.data.refetch();
    var downloaddate = this.state.finMonth + '/' + this.state.finYear;
    fetch(`${BASE_URL}/export/comparePurchases?from=sales&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: 'GET',
      })
      .then(res => res.json())
      .then((result) => {
        console.log('DATA FROM COMPARISION');
        console.log(result.final);
        this.setState({
          output: result.final.Summary,
          recon_mismatches: result.final.MISMATCHES,
          recon_matches: result.final.MATCHES,
          recon_one: result.final.SR,
          recon_two: result.final.RECON_SR,
          recon_loading: false,
        });
        document.getElementsByClassName("ant-table-wrapper")[0].style.display = 'block';
        this.props.data.refetch()
      })
  }

  takePurchase = (key) => {
    this.setState({ sales_loading: true });
    this.props.data.refetch();
    console.log(key.nativeEvent.target.dataset.id);
    console.log(key.nativeEvent.target.dataset.value);
    var downloaddate = this.state.finMonth + '/' + this.state.finYear;
    var invoice_number = key.nativeEvent.target.dataset.value;
    var cdnr_number = key.nativeEvent.target.dataset.number;
    var type = key.nativeEvent.target.dataset.id;
    fetch(`${BASE_URL}/export/takeSales?invoice_number=${invoice_number}&type=${type}&cdnr_number=${cdnr_number}&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: 'GET',
      })
      .then(res => res.json())
      .then((result) => {
        console.log('DATA FROM BACKEND TAKE-PURCHASE');
        console.log(result);
        this.setState({
          output: result.final.Summary,
          recon_mismatches: result.final.MISMATCHES,
          recon_matches: result.final.MATCHES,
          recon_one: result.final.SR,
          recon_two: result.final.RECON_SR,
          sales_loading: false,
        });
        message.success('Sales updated successfully', 5)
        this.props.data.refetch()
      })
  }

  takeGST = (key) => {
    this.setState({ gst_loading: true });
    this.props.data.refetch();
    var type;
    if (key.nativeEvent.target.dataset.id === 'srb2b') {
      type = 'reconsrb2b';
    }
    else if (key.nativeEvent.target.dataset.id === 'srcdnr') {
      type = 'reconsrcdnr';
    }
    else {
      type = key.nativeEvent.target.dataset.id;
    }
    var cdnr_number = key.nativeEvent.target.dataset.number;
    console.log(key.nativeEvent.target.dataset.id);
    console.log(key.nativeEvent.target.dataset.value);
    var downloaddate = this.state.finMonth + '/' + this.state.finYear;
    var invoice_number = key.nativeEvent.target.dataset.value;
    fetch(`${BASE_URL}/export/takeSalesGST?invoice_number=${invoice_number}&cdnr_number=${cdnr_number}&type=${type}&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: 'GET',
      })
      .then(res => res.json())
      .then((result) => {
        console.log('DATA FROM BACKEND TAKE-GST');
        console.log(result);
        this.setState({
          output: result.final.Summary,
          recon_mismatches: result.final.MISMATCHES,
          recon_matches: result.final.MATCHES,
          recon_one: result.final.SR,
          recon_two: result.final.RECON_SR,
          gst_loading: false,
        });
        message.success('GST Sales updated successfully', 5)
        this.props.data.refetch()
      })
    this.props.data.refetch()
  }

  keepPending = (key) => {
    this.setState({ pending_load: true });
    this.props.data.refetch();
    var type;
    if (key.nativeEvent.target.dataset.call === 'SP') {
      if (key.nativeEvent.target.dataset.id === 'srb2b') {
        type = 'srb2b';
      }
      else if (key.nativeEvent.target.dataset.id === 'srcdnr') {
        type = 'srcdnr';
      }
    }
    else if (key.nativeEvent.target.dataset.call === 'MP') {
      if (key.nativeEvent.target.dataset.id === 'srb2b') {
        type = 'srb2b-reconsrb2b';
      }
      else if (key.nativeEvent.target.dataset.id === 'srcdnr') {
        type = 'srcdnr-reconsrcdnr';
      }
    }
    console.log(key.nativeEvent.target.dataset.id);
    console.log(key.nativeEvent.target.dataset.value);
    var downloaddate = this.state.finMonth + '/' + this.state.finYear;
    var invoice_number = key.nativeEvent.target.dataset.value;
    var cdnr_number = key.nativeEvent.target.dataset.number;
    fetch(`${BASE_URL}/export/SaleskeepPending?invoice_number=${invoice_number}&cdnr_number=${cdnr_number}&type=${type}&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: 'GET',
      })
      .then(res => res.json())
      .then((result) => {
        console.log('DATA FROM BACKEND TAKE-GST');
        console.log(result);
        this.setState({
          output: result.final.Summary,
          recon_mismatches: result.final.MISMATCHES,
          recon_matches: result.final.MATCHES,
          recon_one: result.final.SR,
          recon_two: result.final.RECON_SR,
          pending_load: false,
        });
        message.success('Invoice Pended successfully', 5)
        this.props.data.refetch()
      })
  }

  downloadSales = () => {
    this.setState({ downloadLoading: true });
    var downloaddate = this.state.finMonth + '/' + this.state.finYear;
    window.location.href = (`${BASE_URL}/export/json?from=sales&companyId=${comp_id}&downloaddate=${downloaddate}`);
    let that = this;
    setTimeout(function () { that.props.data.refetch(); that.setState({ downloadLoading: false }); }, 7000);
  }

  resetSales = () => {
    this.setState({ resetLoading: true });
    var downloaddate = this.state.finMonth + '/' + this.state.finYear;
    fetch(`${BASE_URL}/export/resetSales?from=sales&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: 'GET',
      })
      .then((result) => {
        console.log(result);
        this.setState({ resetLoading: false });
        this.props.data.refetch();
        this.props.gstr1summary.refetch()
        message.success('All Sales have been reset for selected tax period');
      })
  }

  confirmFetchfromExternalAPI = async () => {
    confirm({
      title: 'Are you sure?',
      content: <p>Clicking 'Yes' will pull all Sales from ERP for selected Tax Period <b>(Existing data will be overwritten by data from ERP)</b></p>,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        document.getElementById("pull_external_click").click();
        //const res = fetchFromExternalAPI();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  fetchFromExternalAPI = async () => {
    message.loading('Pulling from ERP. Please wait.....', 5)   
    this.pullSalesData('ExcelFile', 'srextapi', 'false', null);
  }
  pullSalesData = async (datasource, sourceType, isTally, acceptedFiles) => {
    var el = document.body,
      elChild = document.createElement('div');
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById('root').classList.add("blur");
    var uploadedFile = null;
    if (acceptedFiles !== null) {
      uploadedFile = acceptedFiles[0];
    }

    try{
        const result = await SALES.upload({
          file: "isteer",
          type: sourceType,//'srextapi',
          tally: isTally,//'true',
          companyId: this.props.selectedCompany,
          isSR: this.props.isSR,
          finMonth: this.state.finMonth,
          finYear: this.state.finYear,
        })
        document.body.getElementsByClassName("root_div")[0].remove();  // Get the <ul> element with id="myList"
        document.getElementById('root').classList.remove('blur');

        
        if ((result.error_status !== '') && (result.error_status !== undefined)) {
          //document.getElementById('sales_error_status').click();
          // console.log('-----------------ERROR REPORT----------------');
          // console.log(result.error_status);
          // console.log('---------------------------------------------');
        }
        if (result.filestatus === true && (result.error_status === '' || result.error_status === undefined || result.error_status === null)) {
          if (result.flag === true) {
            var dialogue_msg ='<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + 
            result.total_errors + ' Invoices Failed out of ' + result.total + 
            ' Invoices</b></span>) Sales Register partially saved!</h4></p><br><p>Error Excel will be downloaded. Please check and correct the Errors!</p></div>';
            var div = document.getElementById('dialogue_append1');
            if (document.getElementById('dialogue_append1') !== null) {
              div.innerHTML += dialogue_msg;
            }
          }
          /************************************************************************************/
          if (result.total_errors > 0) {
            //message.error('Failed to pull data', 5)
          }
          else {
            message.success('(' + result.total + '/' + result.total + ') Sales Invoices uploaded successfully!', 8)
          }
          this.props.data.refetch()
          this.props.gstr1summary.refetch()
        }
        else if (result.file === false) {
          message.error('Sales Pull operation failed', 5)
        } 
        else if (result.operation_timedout===true){
          message.error('Possible Failure during pull operation', 5)
        }else {
          message.error('Failure in Sales pull operation', 5)
        }
        return result;
    }catch(e){
        message.error('Could be a Failure during pull operation', 5)
    }

    return null;
  }


  onDropSR = async acceptedFiles => {
    //tg
    var el = document.body,
      elChild = document.createElement('div');
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById('root').classList.add("blur");
    const result = await SALES.upload({
      file: acceptedFiles[0],
      type: 'srall',
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    })
    document.body.getElementsByClassName("root_div")[0].remove();  // Get the <ul> element with id="myList"
    document.getElementById('root').classList.remove('blur');
    console.log('DATA FROM UPLOAD');
    console.log(result);

    if ((result.error_status !== '') && (result.error_status !== undefined)) {
      //document.getElementById('sales_error_status').click();
      console.log('-----------------ERROR REPORT----------------');
      console.log(result.error_status);
      console.log('---------------------------------------------');
    }
    if (result.filestatus === true) {
      if (result.flag === true) {
        var dialogue_msg = '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + result.total_errors + ' Invoices Failed out of ' + result.total + ' Invoices</b></span>) Sales Register partially saved!</h4></p><br><p>Error Excel will be downloaded. Please check and correct the Errors!</p></div>';
        var div = document.getElementById('dialogue_append1');
        if (document.getElementById('dialogue_append1') !== null) {
          div.innerHTML += dialogue_msg;
        }
      }
      /************************************************************************************/
      if (result.total_errors > 0) {
        //message.error('(' + total_negative + '/' + result.total + ') Purchase data upload Failed!')
      }
      else {
        message.success('(' + result.total + '/' + result.total + ') Sales Invoices uploaded successfully!', 8)
      }
      this.props.data.refetch()
      this.props.gstr1summary.refetch()
    } else if (result.file === false) {
      message.error('Uploaded file is not in ElixirGST Excel format!', 5)
    } else {
      message.error('Upload File Type is Not Allowed', 10)
    }

  }

  onDropTallySR = async acceptedFiles => {

    //tally

    var el = document.body,
      elChild = document.createElement('div');
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById('root').classList.add("blur");
    const result = await SALES.upload({
      file: acceptedFiles[0],
      type: 'srall',
      tally: 'true',
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    })
    document.body.getElementsByClassName("root_div")[0].remove();  // Get the <ul> element with id="myList"
    document.getElementById('root').classList.remove('blur');
    console.log('DATA FROM UPLOAD');
    console.log(result);

    if ((result.error_status !== '') && (result.error_status !== undefined)) {
      //document.getElementById('sales_error_status').click();
      console.log('-----------------ERROR REPORT----------------');
      console.log(result.error_status);
      console.log('---------------------------------------------');
    }
    if (result.filestatus === true) {
      if (result.flag === true) {
        var dialogue_msg = '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + result.total_errors + ' Invoices Failed out of ' + result.total + ' Invoices</b></span>) Sales Register partially saved!</h4></p><br><p>Error Excel will be downloaded. Please check and correct the Errors!</p></div>';
        var div = document.getElementById('dialogue_append1');
        if (document.getElementById('dialogue_append1') !== null) {
          div.innerHTML += dialogue_msg;
        }
      }
      /************************************************************************************/
      if (result.total_errors > 0) {
        //message.error('(' + total_negative + '/' + result.total + ') Purchase data upload Failed!')
      }
      else {
        message.success('(' + result.total + '/' + result.total + ') Sales Invoices uploaded successfully!', 8)
      }
      this.props.data.refetch()
      this.props.gstr1summary.refetch()
    } else if (result.file === false) {
      message.error('Uploaded file is not in Tally Excel format!', 5)
    } else {
      message.error('Upload File Type is Not Allowed', 10)
    }

  }


  onDropReconSR = async acceptedFiles => {
    var el = document.body,
      elChild = document.createElement('div');
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById('root').classList.add("blur");
    const result = await RECON_SALES.upload({
      file: acceptedFiles[0],
      type: 'reconsrall',
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    })
    document.body.getElementsByClassName("root_div")[0].remove();  // Get the <ul> element with id="myList"
    document.getElementById('root').classList.remove('blur');
    console.log('DATA FROM JSON UPLOAD');
    console.log(result);
    if ((result.error_status !== '') && (result.error_status !== undefined)) {
      document.getElementById('sales_error_status').click();
      if (result.error_status.trim() === "Mandatory Fields Missing") {
        document.getElementById('dynamic_error_msg').innerHTML = "Please check the file and Upload Correct JSON File";
      }
      else {
        document.getElementById('dynamic_error_msg').innerHTML = "Data being uploaded for a different month than the selected month";
      }
      console.log('-----------------ERROR REPORT----------------');
      console.log(result.error_status);
      console.log('---------------------------------------------');
    }
    if (result.filestatus === true) {
      if (result.flag === false) {
        message.success(' JSON uploaded successfully!', 5)
        this.props.data.refetch()
      }
      else {
        message.error(' Invalid JSON Upload', 5)
        this.props.data.refetch()
      }
    }
    else {
      message.error('Not a valid GST System File', 5)
      this.props.data.refetch()
    }
  }

  excelUtility = async () => {

    const excelOutput = await excelUtility.GSTR_ONE(this.props);

    if (excelOutput.status === 'EMPTYSHEET') {
      message.warning('No invoices available to download!')
    }

  }

  showModal = async id => {
    if (id === 1) {
      this.setState({ visibleMatchModal: true })
    }
    else if (id === 2) {
      this.setState({ visibleModal: true })
    }
    else if (id === 3) {
      this.setState({ visibleOneModal: true })
    }
    else if (id === 4) {
      this.setState({ visibleTwoModal: true })
    }
  }

  showItemModal = async id => {
    if (id !== '') {
      this.setState({ visibleItemModal: true })
      console.log(id);
    }
  }

  hideItemModal = () => {
    this.setState({ visibleItemModal: false })
  }

  hideModal = () => {
    this.setState({ visibleModal: false })
  }

  hideMatchModal = () => {
    console.log('Indide Match model cacel');
    this.setState({ visibleMatchModal: false })
  }

  hideOneModal = () => {
    console.log('Indide one model cacel');
    this.setState({ visibleOneModal: false })
  }

  hideTwoModal = () => {
    console.log('Indide two model cacel');
    this.setState({ visibleTwoModal: false })
  }

  changeSelectedFinancialMonth = name => {
    this.props.changeSelectedFinancialMonth(name)
  }

  popupConfirmResetVisibleChange = e => {
    if (!e) {
      this.setState({ popupConfirmReset: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset: true })
      } else {
        this.setState({ popupConfirmReset: false })
      }
    }
  }

  popupConfirmPullVisibleChange = e => {
    if (!e) {
      this.setState({ popupConfirmPull: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmPull: true })
      } else {
        this.setState({ popupConfirmPull: false })
      }
    }
  }

  fetchAllGSTR_One = () => {
    confirm({
      title: 'Are you sure?',
      content: <p>Clicking 'Yes' will pull all Sales(GSTR-1) from GSTN for selected Tax Period <b>(Existing data will be overwritten by data from GSTN)</b></p>,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        document.getElementById("pull_button_click").click();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  render() {
    return (
      <div style={{ backgroundColor: '#fff', padding: '5px' }}>
        <SalesRegister
          sr_ret_period_masters={get('viewer.sr_ret_period_masters.edges')(this.props.data)}
          role={get('company.role', this.props)}
          finMonth={this.state.finMonth}
          finYear={this.state.finYear}
          showConfirm={this.showConfirm}
          onDropSR={this.onDropSR}
          onDropReconSR={this.onDropReconSR}
          loading={this.props.data.networkStatus < 7}
          recon_loading={this.state.recon_loading}
          sales_loading={this.state.sales_loading}
          gst_loading={this.state.gst_loading}
          pending_load={this.state.pending_load}
          showModal={this.showModal}
          hideModal={this.hideModal}
          hideMatchModal={this.hideMatchModal}
          hideOneModal={this.hideOneModal}
          hideTwoModal={this.hideTwoModal}
          showItemModal={this.showItemModal}
          hideItemModal={this.hideItemModal}
          compareSales={this.compareSales}
          takePurchase={this.takePurchase}
          takeGST={this.takeGST}
          keepPending={this.keepPending}
          downloadSales={this.downloadSales}
          output={this.state.output}
          recon_mismatches={this.state.recon_mismatches}
          recon_matches={this.state.recon_matches}
          recon_one={this.state.recon_one}
          recon_two={this.state.recon_two}
          visibleModal={this.state.visibleModal}
          visibleItemModal={this.state.visibleItemModal}
          visibleMatchModal={this.state.visibleMatchModal}
          visibleMatchItemModal={this.state.visibleMatchItemModal}
          visibleOneModal={this.state.visibleOneModal}
          visibleOneItemModal={this.state.visibleOneItemModal}
          visibleTwoModal={this.state.visibleTwoModal}
          visibleTwoItemModal={this.state.visibleTwoItemModal}
          isSR={this.props.isSR}
          onDropTallySR={this.onDropTallySR}
          downloadLoading={this.state.downloadLoading}
          resetSales={this.resetSales}
          resetLoading={this.state.resetLoading}
          fetchAllGSTR_One={this.fetchAllGSTR_One}
          confirmFetchfromExternalAPI={this.confirmFetchfromExternalAPI}
          fetchFromExternalAPI={this.fetchFromExternalAPI}
          getAllGSTR1Data={this.getAllGSTR1Data}
          excelUtility={this.excelUtility}
          popupConfirmResetVisibleChange={this.popupConfirmResetVisibleChange}
          popupConfirmReset={this.state.popupConfirmReset}
          popupConfirmPullVisibleChange={this.popupConfirmPullVisibleChange}
          popupConfirmPull={this.state.popupConfirmPull}
          tallyTGSwitch={this.tallyTGSwitch}
          externalInterfaceInfo={get('externalInterfaceInfo', this.props)}
        />
        <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
        />
      </div>
    )
  }
}

const queryConfig = {
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finYear: props.finYear,
      finMonth: props.finMonth,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company),
      externalInterfaceInfo: get("viewer.externalInterfaceInfo", company)
    }
  },
}

const config = {
  name: "gstr1summary",
  options: props => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({ gstr1summary, ownProps }) => {
    // console.log(a);
    // var data=a;
    return {
      gstr1summary: gstr1summary,
      srb2b: get("viewer.sr_b2b_masters.edges", gstr1summary),
      srb2cl: get("viewer.sr_b2cl_masters.edges", gstr1summary),
      srcdnr: get("viewer.sr_cdnr_masters.edges", gstr1summary),
      srcdnur: get("viewer.sr_cdnur_masters.edges", gstr1summary),
      srexport: get("viewer.sr_export_masters.edges", gstr1summary),

      srb2cs: get("viewer.sr_b2cs_masters.edges", gstr1summary),
      srnil: get("viewer.sr_nil_masters.edges", gstr1summary),
      srat: get("viewer.sr_at_masters.edges", gstr1summary),
      sratadj: get("viewer.sr_atadj_masters.edges", gstr1summary),
      srhsn: get("viewer.sr_hsn_masters.edges", gstr1summary),
      srdoc: get("viewer.sr_doc_masters.edges", gstr1summary),

      srb2ba: get("viewer.sr_b2ba_masters.edges", gstr1summary),
      srb2cla: get("viewer.sr_b2cla_masters.edges", gstr1summary),
      srb2csa: get("viewer.sr_b2csa_masters.edges", gstr1summary),
      srcdnra: get("viewer.sr_cdnra_masters.edges", gstr1summary),
      srcdnura: get("viewer.sr_cdnura_masters.edges", gstr1summary),
      srexporta: get("viewer.sr_exporta_masters.edges", gstr1summary),
      srata: get("viewer.sr_ata_masters.edges", gstr1summary),
      sratadja: get("viewer.sr_atadja_masters.edges", gstr1summary)
    };
  }
};

const mapStateToProps = state => {
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    output: state.output,
    recon_mismatches: state.recon_mismatches,
    recon_matches: state.recon_matches,
    recon_one: state.recon_one,
    recon_two: state.recon_two,
    gspDetails: state.gsp
  }
}

export default compose(
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(GSTR1_Query), config),
  graphql(gql(SR_RET_Period_Query), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(SalesRegisterContainer)
