/* eslint-disable */
import { Col, Empty, Row } from 'antd';
import get from 'lodash/fp/get';
import { Analytics_PR_CDNR_PurchaseQuery } from 'qgst-gql-queries/web/data.json';
import React, { useEffect, useState } from 'react';
import { compose, gql, graphql } from 'react-apollo';
import Plot from 'react-plotly.js';
import { connect } from 'react-redux';

const Category_CDNR = props => {

    const { selectedDates, pr_cdnr_master_data } = props;
    const [purchaseVolume, setPurchaseVolume] = useState({});

    useEffect(() => {
        if (pr_cdnr_master_data) {
            const PR_CDNR_Master_Data = {};
            pr_cdnr_master_data?.forEach(tempPR_CDNR_Master_Data => {
                const finMonth = parseInt(tempPR_CDNR_Master_Data?.finmonth);
                const finYear = parseInt(finMonth) >= 12 ? parseInt(tempPR_CDNR_Master_Data?.finyear?.split("-")?.[1]) : parseInt(tempPR_CDNR_Master_Data?.finyear?.split("-")?.[0]);
                const startMonth = parseInt(selectedDates?.[0]?.format('M'));
                const startYear = parseInt(selectedDates?.[0]?.format('YYYY'));
                const endMonth = parseInt(selectedDates?.[1]?.format('M'));
                const endYear = parseInt(selectedDates?.[1]?.format('YYYY'));
                const posValue = tempPR_CDNR_Master_Data?.pos_id?.split("-")?.[1];
                if (((finMonth >= startMonth && finYear == startYear) || (finYear > startYear)) && ((finMonth <= endMonth && finYear == endYear) || (finYear < endYear))) {
                    if (PR_CDNR_Master_Data[posValue]) {
                        PR_CDNR_Master_Data[posValue] = {
                            noOfInvoices: PR_CDNR_Master_Data[posValue]?.noOfInvoices + 1,
                            invoiceValue: PR_CDNR_Master_Data[posValue]?.invoiceValue + parseFloat(tempPR_CDNR_Master_Data?.invoice_value)
                        };
                    } else {
                        PR_CDNR_Master_Data[posValue] = {
                            noOfInvoices: 1,
                            invoiceValue: parseFloat(tempPR_CDNR_Master_Data?.invoice_value)
                        };
                    }
                }
            });
            setPurchaseVolume(PR_CDNR_Master_Data);
        } else {
            setPurchaseVolume({});
        }
    }, [selectedDates]);

    function getValues() {
        const valueArr = [];
        if (purchaseVolume) {
            Object.keys(purchaseVolume)?.forEach(data => {
                valueArr.push(purchaseVolume?.[data]?.invoiceValue);
            })
        }
        return valueArr;
    }

    function getCustomData() {
        const customDataArr = [];
        if (purchaseVolume) {
            Object.keys(purchaseVolume)?.forEach(data => {
                customDataArr.push(purchaseVolume?.[data]?.noOfInvoices);
            })
        }
        return customDataArr;
    }

    var data = [
        {
            type: "pie",
            values: getValues(),
            labels: Object.keys(purchaseVolume),
            hole: 0.40,
            pull: [0.1, 0, 0],
            direction: 'clockwise',
            customdata: getCustomData(),
            hovertemplate: "<b>Number of Invoices: </b>%{customdata[0]} <br> <b>Total Invoice Amount (In Rupees): </b> %{value}<extra></extra>",
            marker: {
                colors: ['#CDDC39', '#673AB7', '#F44336'],
                line: {
                    color: 'black',
                    width: 2
                }
            },
            textinfo: "none",
            textfont: {
                family: 'Lato',
                color: 'white',
                size: 16
            },
            hoverlabel: {
                bgcolor: 'black',
                bordercolor: 'black',
                font: {
                    family: 'Lato',
                    color: 'white',
                    size: 16
                }
            }
        }
    ];

    if (purchaseVolume && Object.keys(purchaseVolume)?.length > 0) {
        return (
            <Row justify="space-around" style={{ height: "calc(100vh - 350px)" }}>
                <Col span={24} style={{ marginLeft: "20%", marginRight: "20%" }}>
                    <Plot
                        data={data}
                    />
                </Col>
            </Row>
        )
    } else {
        return (
            <Row justify="space-around" style={{ height: "calc(100vh - 350px)", marginTop: "calc(35vh - 175px)" }}>
                <Col span={24}>
                    <Empty
                        imageStyle={{
                            height: "20vh",
                        }}
                        description={
                            <span>
                                No Purchase Record found
                            </span>
                        }
                    />
                </Col>
            </Row>
        )
    }
}

const queryConfig = {
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            type: 'prcdnr'
        },
    }),
    props: ({ data }) => {
        return {
            pr_cdnr_master_data: get('viewer.analytics_pr_cdnr_masters.edges', data),
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany
    }
}

export default compose(
    connect(mapStateToProps),
    graphql(gql(Analytics_PR_CDNR_PurchaseQuery), queryConfig)
)(Category_CDNR)