import React from "react";
import { Table, InputNumber, Input, Tag, Checkbox, Button } from "antd";
const GSTR9Pt6Sec18 = props => {
  const columns = [
    {
      title: "HSN Code",
      dataIndex: "hsn_sc",
      key: "hsn_sc",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-hsn_sc-tbl18`}
            defaultValue={text}
            onBlur={props.changeHSNContent}
          />
        ) : null;
      }
    },
    {
      title: "UQC",
      dataIndex: "uqc",
      key: "uqc",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-uqc-tbl18`}
            defaultValue={text}
            onBlur={props.changeHSNContent}
          />
        ) : null;
      }
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-desc-tbl18`}
            defaultValue={text}
            onBlur={props.changeHSNContent}
          />
        ) : null;
      }
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-qty-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.changeHSNContent}
          />
        ) : null;
      }
    },
    {
      title: "Is supply applicable for concesstional rate of tax",
      dataIndex: "isconcesstional",
      key: "isconcesstional",
      render: (text, record) => {
        return text !== undefined ? (
          <Checkbox 
            id={`pt6sec18-${record.key}-isconcesstional-tbl18`}
            defaultValue={text}
            onChange={props.changeHSNContent}
          ></Checkbox>
        ) : null;
      }
    },
    {
      title: "Rate of Tax (%)",
      dataIndex: "rt",
      key: "rt",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-rt-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.changeHSNContent}
          />
        ) : null;
      }
    },
    {
      title: "Total Taxable Value (₹)",
      dataIndex: "txval",
      key: "txval",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-txval-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.changeHSNContent}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Integrated Tax (₹)",
      dataIndex: "iamt",
      key: "iamt",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-iamt-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "Central Tax (₹)",
      dataIndex: "camt",
      key: "camt",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-camt-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "State/UT Tax (₹)",
      dataIndex: "samt",
      key: "samt",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-samt-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      title: "CESS (₹)",
      dataIndex: "cess",
      key: "cess",
      render: (text, record) => {
        return text !== undefined ? (
          <Input
            id={`pt6sec18-${record.key}-cess-tbl18`}
            defaultValue={text}
            onKeyDown={props.txvalChange}
            onBlur={props.txtOnBlur}
            addonBefore="₹"
          />
        ) : null;
      }
    },
    {
      render: (text, record) => {
        return text !== undefined ? (
          <Button type="primary" shape="circle" icon="delete" 
          id={`pt6sec18-${record.key}-delete-tbl18`}
          onClick={props.deleteHSNInward}
          />
        ) : null;
      }
    }
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "18. HSN wise summary of Inward Supplies"
      }
      size="small"
      dataSource={props.pt6sec18.dataSource}
      columns={columns}
      footer={(d) => {
        return (
          <Button type="primary" onClick={props.addHSNInward}
          id={`pt6sec18-hsnInward-add-tbl18`}
          >
            Add a row
          </Button>
        )
      }}
    />
  );
};

export default GSTR9Pt6Sec18