import React from 'react'
import Col from 'antd/lib/col'
import Button from 'antd/lib/button'
import Table from 'antd/lib/table'
import { Link } from 'react-router-dom'
import {
  ContentWrapper as Content,
  MarginedRow as Row,
} from '../../shared/styles'
import { ROUTES } from '../../shared/constants'

const { Column } = Table

const data = [
  {
    name: 'Truecopy Credentials Private Limited',
    gstin: '27AABCI5857K1Z5',
  },
  {
    name: 'Doublejob Private Limited',
    gstin: '29AABCO1774K1Z3',
  },
  {
    name: 'Saravana Cards Private Limited',
    gstin: '33AABSO2344A1ZM',
  },
]
export default () =>
  <Content>
    <Row gutter={24}>
      <Col span="4">
        <Button type="primary">
          <Link to={ROUTES.SUPPLIER_ADD}>Add Supplier</Link>
        </Button>
      </Col>
    </Row>
    <Table dataSource={data} bordered rowKey="gstin">
      <Column title="Supplier Name" dataIndex="name" key="name" />
      <Column title="GSTIN/UID" dataIndex="gstin" key="gstin" />
    </Table>
  </Content>
