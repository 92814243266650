import React from "react";
import { Row, Col, DatePicker, Table, Button, Modal, notification, Icon, message, Card, Form, Radio } from "antd";
import get from "lodash/fp/get";
import glamorous from 'glamorous'
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { CompanyByIdQuery, ITC_comparison_Query, ITC_comparison_Query_Excel } from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import moment from "moment";
import toString from "lodash/fp/toString";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import range from "lodash/fp/range";
import pick from "lodash/fp/pick";
import { formatNumber, BASE_URL } from "../../shared/constants";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import excelService from "../../services/excelUtilityService";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import gspAuth from '../../services/GSPService'
const row_style = { border: 'solid 1px #e8e8e8', padding: '10px', margin: '1px' };
const SRow = glamorous(Row)({margin: '15px 0',})
const FormItem = Form.Item;
const { ColumnGroup, Column } = Table
const { MonthPicker } = DatePicker;
const card_styles = {
  width: 180
};
const errorModal = Modal.error;

const GetDateRangeForm = props => (
    <Form layout="horizontal">
      <Row>
        <Col span={8}>
          <FormItem label="Ledger Type">
            {props.getFieldDecorator("type",{initialValue: 'ITC'})(
              <Radio.Group
                    
                    buttonStyle="solid"
                    onChange={e => props.ledgerTypeChange(e)}
                  >
                    <Radio.Button value="CASH">CASH</Radio.Button>
                    <Radio.Button value="ITC">ITC</Radio.Button>
                  </Radio.Group>
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="From Month">
            {props.getFieldDecorator("from_month", props.rangeConfig)(
              <DatePicker
                id='fromDate'
                format="DD-MM-YYYY"
                placeholder="Select Start Month"
                disabledDate={props.disabledDate}
              />
            )}
          </FormItem>
      </Col>
      <Col span={8}>
        <FormItem label="To Month">
          {props.getFieldDecorator("to_month", props.rangeConfig)(
            <DatePicker
              id='toDate'
              format="DD-MM-YYYY"
              placeholder="Select End Month"
              disabledDate={props.disabledDate}
            />
          )}
        </FormItem>
      </Col>
    </Row>
    </Form>
);

class TransactionDetails extends React.Component {
  constructor(props){
    super(props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null
      };
    } else {
      this.gsp = {};
    }
    this.pullLedgerTransData = this.pullLedgerTransData.bind(this);
    
  }


  componentDidUpdate = () => {
    // console.log(this.props);
    // console.log(this.prevProps);
    // console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };



  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.pullLedgerTransData();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visible: false,
    downloadLoading: false,
    requestOTP: false,
    otp: null,
    period: null,
    transactionPayload: null,
    transactionPayloadCash: null,
    ledgerTYPE: 'ITC'
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  showModal = () => {
    console.log("1");
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  pullLedgerTransData = async () => {
    
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    console.log(period);

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      period: period,
    };
    // console.log(reqData);

     this.props.form.validateFields(async (err, values) => {

    if(values.type && values.from_month && values.to_month){

      message.destroy();
      message.loading('Pulling from GSTN...',0);


      this.gsp.response = await gspAuth.generateLedgerTransactions(
                                      reqData, 
                                      values.type, 
                                      values.from_month.format("DD-MM-YYYY"), 
                                      values.to_month.format("DD-MM-YYYY")
                                      );

      if(moment(values.from_month) > moment(values.to_month)){
        message.destroy();
        return message.error('To Month cannot be lesser than From Month')
      }

     console.log(this.gsp.response);
     message.destroy();
     if (!this.gsp.response.data.success || !this.gsp.response.success) {
        if (
          this.gsp.response.data.errorCode &&
          this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
        ) {
       this.setState({ requestOTP: true });
     }}else if(!this.gsp.response.data.success){
       errorModal({
         title: 'Fetch Unsuccessful',
         content: <p>"Unable to process your request, please try again later."</p>,
         iconType: "close-circle",
       });
     }
     if((!this.gsp.response.data.success && this.gsp.response.data.errorCode)){
      this.setState({ 
          transactionPayload: null,
          transactionPayloadCash: null
        });
      message.destroy();
      message.warning(this.gsp.response.data.errorCode)
     }
     if(this.gsp.response.data.success || this.gsp.response.success)
       {
        console.log(this.gsp.response);

        if(values.type === 'ITC'){
        this.setState({ 
          transactionPayload: this.gsp.response.data.itcLdgDtls,
          transactionPayloadCash: null
        });
        }else{
          this.setState({ 
          transactionPayloadCash: this.gsp.response.data,
          transactionPayload: null
        });
        }
         notification.destroy();
         notification.open({
           message: 'Success',
           description: "Pulled Ledger Transactions Successfully!",
           duration: 0,
           icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
         });
       }

    }else{
      message.error('Please provide the requested inputs!')
    }
     
    })
   

   // message.destroy();
   // if ((this.gsp.response && this.gsp.response.data && !this.gsp.response.data.success) || (this.gsp.response && !this.gsp.response.success)) {
   //    if (
   //      this.gsp.response.data.errorCode &&
   //      this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
   //    ) {
   //   this.setState({ requestOTP: true });
   // }}else if(this.gsp.response && this.gsp.response.data && !this.gsp.response.data.success){
   //   errorModal({
   //     title: 'Fetch Unsuccessful',
   //     content: <p>"Unable to process your request, please try again later."</p>,
   //     iconType: "close-circle",
   //   });
   // }
   // if((this.gsp.response && this.gsp.response.data && this.gsp.response.data.success) || (this.gsp.response && this.gsp.response.success))
   //   {
   //    console.log(this.gsp.response);

   //    this.setState({ 
   //      transactionPayload: this.gsp.response.data.itcLdgDtls
   //    });

   //     notification.destroy();
   //     notification.open({
   //       message: 'Success',
   //       description: "Pulled Ledger Transactions Successfully!",
   //       duration: 0,
   //       icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
   //     });
   //   }
  }

  handleCancel = e => {
    // console.log(e);
    this.setState({
      requestOTP: false
    });
  };


 disabledDate = (current) => {
    // Can not select days before today and today
    return (current && current < moment("30-06-2017","DD-MM-YYYY").endOf('day')) || (current && current > moment().endOf('day'));
  }
  rangeConfig = {
    rules: [
      { type: "object", required: true, message: "Please select desired Month!" }
    ]
  };

  ledgerTypeChange = (e) => {
    console.log(e);
    this.setState({
      ledgerTYPE: e.target.value
    })
  }
  
  dateRangeFormRef = formRef => {
    this.dateRangeRef = formRef;
  };

  handleDateRangeForm = () => {

    // console.log(document.getElementById("fromDate"));
    // console.log(document.getElementById("toDate"));
    
    // const form = this.dateRangeRef.props.form;
    // form.validateFields(async (err, fieldsValue) => {
    //   if (err) {
    //     return;
    //   }
    //   form.resetFields();
    //   console.log(fieldsValue);
    //   })

    this.props.form.validateFields(async (err, values) => {
      console.log(values);
      console.log(values.from_month.format("DD-MM-YYYY"));
      console.log(values.to_month.format("DD-MM-YYYY"));
      console.log(values.type);
    })


    }

  render() {
    console.log(this.state);
    
    
    const maxWidth = window.innerWidth - 215 + "px";

    return (
      <div>
        <Root>
          <Content>
         <GetDateRangeForm
          wrappedComponentRef={this.dateRangeFormRef}
          getFieldDecorator={this.props.form.getFieldDecorator}
          ledgerTypeChange={this.ledgerTypeChange}
          rangeConfig={this.rangeConfig}
          disabledDate={this.disabledDate}
        />
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Button style={{ width: '300px' }} type='primary' onClick={this.pullLedgerTransData}>Fetch</Button>
        </div>

        {this.state.transactionPayload && this.state.ledgerTYPE === 'ITC' && 
                              <div>
                              <SRow gutter={24} style={row_style}>
                              <Col span={6} style={{ textAlign: 'left' }}>
                              <p><b>Opening balance</b> : {formatNumber(this.gsp.response && this.gsp.response.data && this.gsp.response.data.itcLdgDtls && this.gsp.response.data.itcLdgDtls.op_bal && this.gsp.response.data.itcLdgDtls.op_bal.tot_rng_bal)}</p>
                              </Col>
                              <Col span={6} style={{ textAlign: 'left' }}>
                              <p><b>Closing balance</b> : {formatNumber(this.gsp.response && this.gsp.response.data && this.gsp.response.data.itcLdgDtls && this.gsp.response.data.itcLdgDtls.cl_bal && this.gsp.response.data.itcLdgDtls.cl_bal.tot_rng_bal)}</p>
                              </Col>
                              </SRow>
                              <Table
                                title={() => <b>ITC Transactions</b>}
                                dataSource={this.state.transactionPayload && this.state.transactionPayload.tr}
                                bordered
                                rowKey="ref_no"
                              >
                              <Column
                                title="Reference Number"
                                dataIndex="ref_no"
                                key="ref_no"
                              />
                              <Column
                                title="Date"
                                dataIndex="dt"
                                key="dt"
                              />
                              <Column
                                title="Return Period"
                                dataIndex="ret_period"
                                key="ret_period"
                                render={val => <p>{moment(val, 'MMYYYY').format('MMM-YYYY')}</p>}
                              />

                              <Column
                                title="Description"
                                dataIndex="desc"
                                key="desc"
                              />
                              <Column
                                title="Transaction Type"
                                dataIndex="tr_typ"
                                key="tr_typ"
                              />
                              <ColumnGroup title="Credit / Debit">

                                <Column
                                title="IGST"
                                dataIndex="igstTaxAmt"
                                key="igstTaxAmt"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="CGST"
                                dataIndex="cgstTaxAmt"
                                key="cgstTaxAmt"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="SGST"
                                dataIndex="sgstTaxAmt"
                                key="sgstTaxAmt"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="CESS"
                                dataIndex="cessTaxAmt"
                                key="cessTaxAmt"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="Total"
                                dataIndex="tot_tr_amt"
                                key="tot_tr_amt"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />
            
                              </ColumnGroup>

                              <ColumnGroup title="Balance">

                                <Column
                                title="IGST"
                                dataIndex="igstTaxBal"
                                key="igstTaxBal"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="CGST"
                                dataIndex="cgstTaxBal"
                                key="cgstTaxBal"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="SGST"
                                dataIndex="sgstTaxBal"
                                key="sgstTaxBal"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="CESS"
                                dataIndex="cessTaxBal"
                                key="cessTaxBal"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />

                                <Column
                                title="Total"
                                dataIndex="tot_rng_bal"
                                key="tot_rng_bal"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />
            
                              </ColumnGroup>
                              </Table>
                              </div>
        }


        {this.state.transactionPayloadCash && this.state.ledgerTYPE === 'CASH' && 
                              <div>
                              <SRow gutter={24} style={row_style}>
                              <Col span={6} style={{ textAlign: 'left' }}>
                              <p><b>Opening balance</b> : {formatNumber(this.gsp.response && this.gsp.response.data && this.gsp.response.data.op_bal && this.gsp.response.data.op_bal.tot_rng_bal)}</p>
                              </Col>
                              <Col span={6} style={{ textAlign: 'left' }}>
                              <p><b>Closing balance</b> : {formatNumber(this.gsp.response && this.gsp.response.data && this.gsp.response.data.cl_bal && this.gsp.response.data.cl_bal.tot_rng_bal)}</p>
                              </Col>
                              </SRow>
                              <Table
                                title={() => <b>Ledger Transactions</b>}
                                dataSource={this.state.transactionPayloadCash && this.state.transactionPayloadCash.tr}
                                bordered
                                rowKey="refNo"
                              >
                              <Column
                                title="Reference Number"
                                dataIndex="refNo"
                                key="refNo"
                              />
                              <Column
                                title="Deposit Date"
                                dataIndex="dpt_dt"
                                key="dpt_dt"
                              />
                              <Column
                                title="Return Period"
                                dataIndex="ret_period"
                                key="ret_period"
                                render={val => val === '-' ? '-' : <p>{moment(val, 'MMYYYY').format('MMM-YYYY')}</p>}
                              />

                              <Column
                                title="Description"
                                dataIndex="desc"
                                key="desc"
                              />
                              <Column
                                title="Transaction Type"
                                dataIndex="tr_typ"
                                key="tr_typ"
                              />
                              <ColumnGroup title="Credit / Debit">

                                <Column
                                title="IGST"
                                dataIndex="igst"
                                key="igst"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="CGST"
                                dataIndex="cgst"
                                key="cgst"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="SGST"
                                dataIndex="sgst"
                                key="sgst"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="CESS"
                                dataIndex="cess"
                                key="cess"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="Total"
                                dataIndex="tot_tr_amt"
                                key="tot_tr_amt"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />
            
                              </ColumnGroup>

                              <ColumnGroup title="Balance">

                                <Column
                                title="IGST"
                                dataIndex="igstbal"
                                key="igstbal"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="CGST"
                                dataIndex="cgstbal"
                                key="cgstbal"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="SGST"
                                dataIndex="sgstbal"
                                key="sgstbal"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="CESS"
                                dataIndex="cessbal"
                                key="cessbal"
                                render={val => <p>{formatNumber(val === null ? 0 : val.intr + val.oth + val.tx + val.fee + val.pen + val.tot)}</p>}
                                />

                                <Column
                                title="Total"
                                dataIndex="tot_rng_bal"
                                key="tot_rng_bal"
                                render={val => <p>{formatNumber(val === null ? 0 : val)}</p>}
                                />
            
                              </ColumnGroup>
                              </Table>
                              </div>
        }
            
            <OTPForm
              wrappedComponentRef={this.saveFormRef}
              visible={this.state.requestOTP}
              onSubmit={this.handleOTPSubmit}
              onCancel={this.handleCancel}
            />
          </Content>
        </Root>
      </div>
    );
  }
}


const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  Form.create(),
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(TransactionDetails)

