import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import CoreLayout from './CoreLayout'
import Demo from './Demo'
import { AuthLayout } from './Auth'

class App extends Component {

  componentDidMount () {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "9d83f10e-062b-407d-8fdd-eb76058f83ce";
    // var a = this.props.users_list_me

    (function() {
      var d = document;
      var s = d.createElement("script");
      // console.log(a);
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
   };

  render() {
    return (
      <Router basename="/portal">
        {/*<Glamorous.Div height="100%">*/ }
        <div>
          <Route path="/secured" component={CoreLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route exact path="/" component={AuthLayout} />
          <Route exact path="/ITC" component={Demo} />
        </div>
        { /*</Glamorous.Div> */ }
      </Router>
    )
  }
}

export default App

{/*const usersQueryConfig = {
  name: "company_users",
  options: props => ({
    variables: {
      companyId: props.selectedCompany ? props.selectedCompany : '',
    },
  }),
  props: ({ company_users }) => {
    return {
      company_users: company_users,
      users_list_me: get("viewer.me", company_users),
      users_list: get("viewer.companyUsers.edges", company_users),
    };
  }
}

const mapStateToProps = state => {
  console.log(state);
  return {
    selectedCompany: state.local.selectedCompany,
  };
};

export default compose(
  connect(
    mapStateToProps),
graphql(gql(CompanyUsersQuery), usersQueryConfig),
)(App)*/}
