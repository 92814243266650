import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { FormItem } from '../../shared/utils'
import { ContentWrapper } from '../../shared/styles'

export default (props) =>
  <ContentWrapper>
    <Form onSubmit={props.saveGSTR9}>
    <br />
    <br />
    <h3 style={{ marginLeft: "35"}} id="link1">7. Details of ITC Reversed and Ineligible ITC as declared in returns filed during the financial year</h3>
    <br />
    <br />
    <br />
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "100%", height: "100px" }}><h4><b>Nature Of Supplies</b></h4></th>
        
        <th style={{ width: "70%" }}><h4><b>Integrated Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Central Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>State/UT Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Cess</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>A)</b> As per Rule 37</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td><b>B)</b> As per Rule 39</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>C)</b> As per Rule 42</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_b', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_e', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>D)</b> As per Rule 43</td>
        
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_b', {
               
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>E)</b> As per section 17(5)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>F)</b> Reversal of TRAN-I credit</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>G)</b> Reversal of TRAN-II credit</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>H)</b> Other reversals (pl. specify)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>I)</b> Total ITC Reversed (A to H above)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      <tr>
        <td><b>J)</b> Net ITC Available for Utilization (6O - 7I)</td>
        
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>

      
    </tbody>
    </table>
  </Form>
  </ContentWrapper>

