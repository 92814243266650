import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { FormItem } from '../../shared/utils'
import { ContentWrapper } from '../../shared/styles'

export default (props) =>
  <ContentWrapper>
  <br />
    <br />
    <br />
    <br />
    <Form onSubmit={props.saveGSTR9}>
    <h2 style={{ textAlign: "center"}}>Particulars of the transactions for the previous FY declared in returns of April to September of current FY or upto date of filing of annual return of previous FY whichever is earlier</h2>
    <br />
    <br />
    
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "100%", height: "100px" }}><h4><b>Description</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Total Taxable Value</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Integrated Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Central Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>State/UT Tax</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Cess</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>10)</b> Supplies / tax declared through Amendments (+) (net of debit notes)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_a', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>



      <tr>
        <td><b>11)</b> Supplies / tax reduced through Amendments (-) (net of credit notes)</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_a', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_b', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ab_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>12)</b> Reversal of ITC availed during previous financial year</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_a', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_b', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_c', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ac_e', {

              })(<InputNumber   step={0.01} style={{ width: 135 }}   disabled={true} />)}
            </FormItem>
        </td>
      </tr>


      <tr>
        <td><b>13)</b> ITC availed for the previous financial year</td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_a', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_b', {
               
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_c', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Ad_e', {
                
              })(<InputNumber   step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
      </tr>


      


    </tbody>
    </table>
    <br />
    <br />
    <br />
    <br />

    <h3 style={{ marginLeft: "35"}} id="link1">14. Differential tax paid on account of declaration in 10 & 11 above</h3>
    <br />
    <br />
    <br />
    <table style={{ width:"90%", marginLeft:"5%", cellSpacing: "15", cellPadding: "10"}}>
    <thead>
      <tr>
        <th style={{ width: "50%", height: "100px" }}><h4><b>Description</b></h4></th>
        <th style={{ width: "20%" }}><h4><b>Payable</b></h4></th>
        <th style={{ width: "70%" }}><h4><b>Paid</b></h4></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>Integrated Tax</b></td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_a', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        
      </tr>

      <tr>
        <td><b>Central Tax</b></td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_a', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        
      </tr>

      <tr>
        <td><b>State/UT Tax</b></td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_a', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        
      </tr>

      <tr>
        <td><b>Cess</b></td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_a', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        
      </tr>

      <tr>
        <td><b>Interest</b></td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_a', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }} />)}
            </FormItem>
        </td>
        <td>
            <FormItem>
              {props.getFieldDecorator('Aa_b', {
                
              })(<InputNumber  step={0.01} style={{ width: 135 }}  />)}
            </FormItem>
        </td>
        
      </tr>



      


    </tbody>
    </table>
  </Form>
  </ContentWrapper>

