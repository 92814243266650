import React from 'react'
import { Route } from 'react-router-dom'

import CompanyAdd from './CompanyAdd'
import CompanyList from './CompanyList'
import CompanyUsers from './CompanyUsers'
import CompanyEdit from './CompanyEdit'

import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'

export default () =>
  <Root>
    <Route exact path={ROUTES.COMPANY} component={CompanyList} />
    <Route exact path={ROUTES.COMPANY_ADD} component={CompanyAdd} />
    <Route exact path={ROUTES.COMPANY_EDIT} component={CompanyEdit} />
    <Route exact path={ROUTES.COMPANY_USERS} component={CompanyUsers} />
  </Root>

export { CompanyAdd, CompanyList }
