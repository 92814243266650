import React from 'react'
import { Route } from 'react-router-dom'

import Fetch_Supplier_Details from './Fetch_Supplier_Details'

import { Root } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'

export default () =>
  <Root>
    <Route exact path={ROUTES.SUPPLIER_FILING_TRENDS} component={Fetch_Supplier_Details} />
  </Root>

export { Fetch_Supplier_Details }
