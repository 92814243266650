const XLSX = require('xlsx')
const { stateArray } = require('../shared/constants')
var fileSaver = require('file-saver')
var blob = require('blob-util')
const {
  flow,
  map,
  flattenDeep,
  find,
  findLast,
  filter
} = require("lodash/fp");

const excelUtility = {

  GSTR_ONE: (parsedData) => {

    console.log("PDATA",parsedData);
    
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
    var wb_new = XLSX.utils.book_new();
    

    if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null) && (parsedData.srb2b.length !== 0)) {
        wb_new.SheetNames.push("B2B - 4A, 4B, 4C, 6B, 6C");
      }
      if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null) && (parsedData.srb2cl.length !== 0)) {
        wb_new.SheetNames.push("B2CL - 5A, 5B");
      }
      if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null) && (parsedData.srb2cs.length !== 0)) {
        wb_new.SheetNames.push("B2CS - 7");
      }
      if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null) && (parsedData.srcdnr.length !== 0)) {
        wb_new.SheetNames.push("CDNR - 9B");
      }
      if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null) && (parsedData.srcdnur.length !== 0)) {
        wb_new.SheetNames.push("CDNUR - 9B");
      }
      if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null) && (parsedData.srexport.length !== 0)) {
        wb_new.SheetNames.push("EXP - 6A");
      }
      if ((parsedData.srat !== undefined) && (parsedData.srat !== null) && (parsedData.srat.length !== 0)) {
        wb_new.SheetNames.push("AT - 11A(1), 11A(2)");
      }
      if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null) && (parsedData.sratadj.length !== 0)) {
        wb_new.SheetNames.push("ATADJ - 11B(1), 11B(2)");
      }
      if ((parsedData.srb2ba !== undefined) && (parsedData.srb2ba !== null) && (parsedData.srb2ba.length !== 0)) {
        wb_new.SheetNames.push("B2BA - 9A");
      }
      if ((parsedData.srb2cla !== undefined) && (parsedData.srb2cla !== null) && (parsedData.srb2cla.length !== 0)) {
        wb_new.SheetNames.push("B2CLA - 9A");
      }
      if ((parsedData.srb2csa !== undefined) && (parsedData.srb2csa !== null) && (parsedData.srb2csa.length !== 0)) {
        wb_new.SheetNames.push("B2CSA - 10");
      }
      if ((parsedData.srcdnra !== undefined) && (parsedData.srcdnra !== null) && (parsedData.srcdnra.length !== 0)) {
        wb_new.SheetNames.push("CDNRA - 9C");
      }
      if ((parsedData.srcdnura !== undefined) && (parsedData.srcdnura !== null) && (parsedData.srcdnura.length !== 0)) {
        wb_new.SheetNames.push("CDNURA - 9C");
      }
      if ((parsedData.srexporta !== undefined) && (parsedData.srexporta !== null) && (parsedData.srexporta.length !== 0)) {
        wb_new.SheetNames.push("EXPA - 9A");
      }
      if ((parsedData.srata !== undefined) && (parsedData.srata !== null) && (parsedData.srata.length !== 0)) {
        wb_new.SheetNames.push("ATA - 11(2)");
      }
      if ((parsedData.sratadja !== undefined) && (parsedData.sratadja !== null) && (parsedData.sratadja.length !== 0)) {
        wb_new.SheetNames.push("ATADJA - 11(2)");
      }
      if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null) && (parsedData.srhsn.length !== 0)) {
        wb_new.SheetNames.push("HSN - 12");
      }
      if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null) && (parsedData.srnil.length !== 0)) {
        wb_new.SheetNames.push("EXEMP - 8A, 8B, 8C, 8D");
      }
      if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null) && (parsedData.srdoc.length !== 0)) {
        wb_new.SheetNames.push("DOC - 13");
      }


      //regular
      var b2bDataList = [];
      var b2clDataList = [];
      var b2csDataList = [];
      var cdnrDataList = [];
      var cdnurDataList = [];
      var exportsDataList = [];
      var atDataList = [];
      var atadjDataList = [];

      //amended
      var b2baDataList = [];
      var b2claDataList = [];
      var b2csaDataList = [];
      var cdnraDataList = [];
      var cdnuraDataList = [];
      var exportsaDataList = [];
      var ataDataList = [];
      var atadjaDataList = [];

      var hsnDataList = [];
      var exempDataList = [];
      var docDataList = [];

      if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Receiver GSTIN/UIN*': Inv.receiver_gstin,
              'Receiver Name*': Inv.receiver_name,
              'Invoice No.*': Inv.invoice_number,
              'Invoice Date*': Inv.invoice_date,
              'Invoice Value*': parseFloat(Inv.invoice_value),
              'Invoice Type*': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Reverse Charge*': Inv.reverse_charge,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'E-Commerce GSTIN': Inv.ecom_gstin,
          }
        })(Inv.sr_b2b_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srb2b)

      b2bDataList = Data;

      }

      if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Invoice No.*': Inv.invoice_number,
              'Invoice Date*': Inv.invoice_date,
              'Invoice Value*': parseFloat(Inv.invoice_value),
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Sale from Bonded WH': Inv.sbwh,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'E-Commerce GSTIN': Inv.ecom_gstin,
          }
        })(Inv.sr_b2cl_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srb2cl)

      b2clDataList = Data;

      }

      if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              
              'Invoice Type*': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'E-Commerce GSTIN': Inv.ecom_gstin,

          }
        })(Inv.sr_b2cs_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srb2cs)

      b2csDataList = Data;

      }

      if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Receiver GSTIN/UIN*': Inv.receiver_gstin,
              'Receiver Name*': Inv.receiver_name,
              'Invoice/Advance Receipt Number*': Inv.invoice_number,
              'Invoice/Advance Receipt Date*': Inv.invoice_date,

              'Note/Refund Voucher Number*': Inv.cdnr_number,
              'Note/Refund Voucher Date*': Inv.cdnr_date,
              'Document Type*': Inv.cdnr_type,
              'Note/Refund Voucher Value*': parseFloat(Inv.cdnr_value),

              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'Pre GST*': Inv.pre_gst,
          }
        })(Inv.sr_cdnr_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srcdnr)

      cdnrDataList = Data;

      }

      if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              
              'Invoice Type*': Inv.invoice_type,
              'Invoice/Advance Receipt Number*': Inv.invoice_number,
              'Invoice/Advance Receipt Date*': Inv.invoice_date,

              'Note/Refund Voucher Number*': Inv.cdnur_number,
              'Note/Refund Voucher Date*': Inv.cdnur_date,
              'Document Type*': Inv.cdnur_type,
              'Note/Refund Voucher Value*': parseFloat(Inv.cdnur_value),

              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              // 'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              // 'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'Pre GST*': Inv.pre_gst,

          }
        })(Inv.sr_cdnur_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srcdnur)

      cdnurDataList = Data;

      }

      if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Export Type*': Inv.exp_type,
              'Invoice No.*': Inv.invoice_number,
              'Invoice Date*': Inv.invoice_date,
              'Invoice Value*': parseFloat(Inv.invoice_value),
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              
          }
        })(Inv.sr_export_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srexport)

      exportsDataList = Data;

      }

      if ((parsedData.srat !== undefined) && (parsedData.srat !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Gross Advance Received*': parseFloat(InvoiceItems.gross_received),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
          }
        })(Inv.sr_at_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srat)

      atDataList = Data;

      }

      if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Gross Advance Adjusted*': parseFloat(InvoiceItems.gross_adjusted),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
          }
        })(Inv.sr_atadj_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.sratadj)

      atadjDataList = Data;

      }

      //Amended

      if ((parsedData.srb2ba !== undefined) && (parsedData.srb2ba !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Receiver GSTIN/UIN*': Inv.receiver_gstin,
              'Receiver Name*': Inv.receiver_name,

              'Original Invoice Number*': Inv.o_invoice_number,
              'Original Invoice Date*': Inv.o_invoice_date,
              'Revised Invoice Number*': Inv.r_invoice_number,
              'Revised Invoice Date*': Inv.r_invoice_date,
              'Revised Invoice Value*': parseFloat(Inv.invoice_value),

              'Invoice Type*': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Reverse Charge*': Inv.reverse_charge,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'E-Commerce GSTIN': Inv.ecom_gstin,
          }
        })(Inv.sr_b2ba_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srb2ba)

      b2baDataList = Data;

      }

      if ((parsedData.srb2cla !== undefined) && (parsedData.srb2cla !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Original Invoice Number*': Inv.o_invoice_number,
              'Original Invoice Date*': Inv.o_invoice_date,
              'Revised Invoice Number*': Inv.r_invoice_number,
              'Revised Invoice Date*': Inv.r_invoice_date,
              'Revised Invoice Value*': parseFloat(Inv.invoice_value),

              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Sale from Bonded WH': Inv.sbwh,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'E-Commerce GSTIN': Inv.ecom_gstin,
          }
        })(Inv.sr_b2cla_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srb2cla)

      b2claDataList = Data;

      }

      if ((parsedData.srb2csa !== undefined) && (parsedData.srb2csa !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {

              'Original Year*': Inv.financial_year_a,
              'Original Month*': Inv.original_month,
              'Original Place of Supply*': Inv.o_pos_id,
              'Revised Place of Supply*': Inv.r_pos_id,
              
              'Invoice Type*': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'E-Commerce GSTIN': Inv.ecom_gstin,

          }
        })(Inv.sr_b2csa_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srb2csa)

      b2csaDataList = Data;

      }

      if ((parsedData.srcdnra !== undefined) && (parsedData.srcdnra !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Receiver GSTIN/UIN*': Inv.receiver_gstin,
              'Receiver Name*': Inv.receiver_name,
              'Invoice/Advance Receipt Number*': Inv.invoice_number,
              'Invoice/Advance Receipt Date*': Inv.invoice_date,

              'Original Note/Refund Voucher Number*': Inv.o_cdnr_number,
              'Original Note/Refund Voucher Date*': Inv.o_cdnr_date,
              'Revised Note/Refund Voucher Number*': Inv.r_cdnr_number,
              'Revised Note/Refund Voucher Date*': Inv.r_cdnr_date,

              'Document Type*': Inv.cdnr_type,
              'Revised Note/Refund Voucher Value*': parseFloat(Inv.cdnr_value),

              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'Pre GST*': Inv.pre_gst,
          }
        })(Inv.sr_cdnra_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srcdnra)

      cdnraDataList = Data;

      }

      if ((parsedData.srcdnura !== undefined) && (parsedData.srcdnura !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              
              'Invoice Type*': Inv.invoice_type,
              'Invoice/Advance Receipt Number*': Inv.invoice_number,
              'Invoice/Advance Receipt Date*': Inv.invoice_date,

              'Original Note/Refund Voucher Number*': Inv.o_cdnur_number,
              'Original Note/Refund Voucher Date*': Inv.o_cdnur_date,
              'Revised Note/Refund Voucher Number*': Inv.r_cdnur_number,
              'Revised Note/Refund Voucher Date*': Inv.r_cdnur_date,

              'Document Type*': Inv.cdnur_type,
              'Note/Refund Voucher Value*': parseFloat(Inv.cdnur_value),

              'Place Of Supply*': Inv.pos_id,
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              'Pre GST*': Inv.pre_gst,

          }
        })(Inv.sr_cdnura_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srcdnura)

      cdnuraDataList = Data;

      }

      if ((parsedData.srexporta !== undefined) && (parsedData.srexporta !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Export Type*': Inv.exp_type,
              
              'Original Invoice Number*': Inv.o_invoice_number,
              'Original Invoice Date*': Inv.o_invoice_date,
              'Revised Invoice Number*': Inv.r_invoice_number,
              'Revised Invoice Date*': Inv.r_invoice_date,
              'Revised Invoice Value*': parseFloat(Inv.invoice_value),

              'Invoice Value*': parseFloat(Inv.invoice_value),
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
              
          }
        })(Inv.sr_exporta_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srexporta)

      exportsaDataList = Data;

      }

      if ((parsedData.srata !== undefined) && (parsedData.srata !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              'Original Year*': Inv.financial_year_a,
              'Original Month*': Inv.original_month,
              'Original Place of Supply*': Inv.o_pos_id,

              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Gross Advance Received*': parseFloat(InvoiceItems.gross_received),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
          }
        })(Inv.sr_ata_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srata)

      ataDataList = Data;

      }

      if ((parsedData.sratadja !== undefined) && (parsedData.sratadja !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              
              'Original Year*': Inv.financial_year_a,
              'Original Month*': Inv.original_month,
              'Original Place of Supply*': Inv.o_pos_id,
              
              'Supply Type*': Inv.supply_type,
              'Applicable % of Tax Rate': Inv.atr,
              'Rate*': parseFloat(InvoiceItems.gst_rate),
              'Gross Advance Adjusted*': parseFloat(InvoiceItems.gross_adjusted),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
          }
        })(Inv.sr_atadja_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.sratadja)

      atadjaDataList = Data;

      }

      if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null)) {

        const Data = flow(map(Inv => {
          const InvoiceItems = map(InvoiceItems => {
            return {
              
              'HSN*': Inv.hsn_sac_code,
              'Description*': Inv.hsn_sac_desc,
              'UQC*': Inv.uqc_code,
              'Total Quantity*': Inv.total_qty,
              'Total Value*': Inv.total_value,
              'Rate*': parseFloat(InvoiceItems.gst_rate), 
              'Taxable Value*': parseFloat(InvoiceItems.taxable_value),
              'Integrated Tax*': (InvoiceItems.igst === null ? '' : parseFloat(InvoiceItems.igst)),
              'Central Tax*': (InvoiceItems.cgst === null ? '' : parseFloat(InvoiceItems.cgst)),
              'State/UT Tax*': (InvoiceItems.sgst === null ? '' : parseFloat(InvoiceItems.sgst)),
              'Cess': (InvoiceItems.cess === null ? '' : parseFloat(InvoiceItems.cess)),
          }
        })(Inv.sr_hsn_items.edges)
          return InvoiceItems;
        }),
      flattenDeep)(parsedData.srhsn)

      hsnDataList = Data;

      }

      if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null)) {

        const Data = flow(map(Inv => {
            return {
              'Description': Inv.supply_type,
              'Nil Rated Supplies': parseFloat(Inv.nil_amt === null ? 0 : Inv.nil_amt),
              'Exempted (Other than Nil Rated / Non-GST Supplies)': parseFloat(Inv.expt_amt === null ? 0 : Inv.expt_amt),
              'Non-GST Supplies': parseFloat(Inv.ngsup_amt === null ? 0 : Inv.ngsup_amt),
          }
        }),
      flattenDeep)(parsedData.srnil)

      exempDataList = Data;

      }

      if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null)) {

        const Data = flow(map(Inv => {
            return {
              'Nature of Document*': Inv.nature_doc,
              'Sr. No. From*': Inv.from_serial,
              'Sr. No. To*': Inv.to_serial,
              'Total Number*': parseInt(Inv.total_count),
              'Cancelled*': parseInt(Inv.cancel_count),
          }
        }),
      flattenDeep)(parsedData.srdoc)

      docDataList = Data;

      }
     
     

      if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null) && (parsedData.srb2b.length !== 0)) {
        wb_new.Sheets["B2B - 4A, 4B, 4C, 6B, 6C"] = XLSX.utils.json_to_sheet(b2bDataList);
      }
      if ((parsedData.srb2cl !== undefined) && (parsedData.srb2cl !== null) && (parsedData.srb2cl.length !== 0)) {
        wb_new.Sheets["B2CL - 5A, 5B"] = XLSX.utils.json_to_sheet(b2clDataList);
      }
      if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null) && (parsedData.srb2cs.length !== 0)) {
        wb_new.Sheets["B2CS - 7"] = XLSX.utils.json_to_sheet(b2csDataList);
      }
      if ((parsedData.srcdnr !== undefined) && (parsedData.srcdnr !== null) && (parsedData.srcdnr.length !== 0)) {
        wb_new.Sheets["CDNR - 9B"] = XLSX.utils.json_to_sheet(cdnrDataList);
      }
      if ((parsedData.srcdnur !== undefined) && (parsedData.srcdnur !== null) && (parsedData.srcdnur.length !== 0)) {
        wb_new.Sheets["CDNUR - 9B"] = XLSX.utils.json_to_sheet(cdnurDataList);
      }
      if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null) && (parsedData.srexport.length !== 0)) {
        wb_new.Sheets["EXP - 6A"] = XLSX.utils.json_to_sheet(exportsDataList);
      }
      if ((parsedData.srat !== undefined) && (parsedData.srat !== null) && (parsedData.srat.length !== 0)) {
        wb_new.Sheets["AT - 11A(1), 11A(2)"] = XLSX.utils.json_to_sheet(atDataList);
      }
      if ((parsedData.sratadj !== undefined) && (parsedData.sratadj !== null) && (parsedData.sratadj.length !== 0)) {
        wb_new.Sheets["ATADJ - 11B(1), 11B(2)"] = XLSX.utils.json_to_sheet(atadjDataList);
      }
      if ((parsedData.srb2ba !== undefined) && (parsedData.srb2ba !== null) && (parsedData.srb2ba.length !== 0)) {
        wb_new.Sheets["B2BA - 9A"] = XLSX.utils.json_to_sheet(b2baDataList);
      }
      if ((parsedData.srb2cla !== undefined) && (parsedData.srb2cla !== null) && (parsedData.srb2cla.length !== 0)) {
        wb_new.Sheets["B2CLA - 9A"] = XLSX.utils.json_to_sheet(b2claDataList);
      }
      if ((parsedData.srb2csa !== undefined) && (parsedData.srb2csa !== null) && (parsedData.srb2csa.length !== 0)) {
        wb_new.Sheets["B2CSA - 10"] = XLSX.utils.json_to_sheet(b2csaDataList);
      }
      if ((parsedData.srcdnra !== undefined) && (parsedData.srcdnra !== null) && (parsedData.srcdnra.length !== 0)) {
        wb_new.Sheets["CDNRA - 9C"] = XLSX.utils.json_to_sheet(cdnraDataList);
      }
      if ((parsedData.srcdnura !== undefined) && (parsedData.srcdnura !== null) && (parsedData.srcdnura.length !== 0)) {
        wb_new.Sheets["CDNURA - 9C"] = XLSX.utils.json_to_sheet(cdnuraDataList);
      }
      if ((parsedData.srexporta !== undefined) && (parsedData.srexporta !== null) && (parsedData.srexporta.length !== 0)) {
        wb_new.Sheets["EXPA - 9A"] = XLSX.utils.json_to_sheet(exportsaDataList);
      }
      if ((parsedData.srata !== undefined) && (parsedData.srata !== null) && (parsedData.srata.length !== [])) {
        wb_new.Sheets["ATA - 11(2)"] = XLSX.utils.json_to_sheet(ataDataList);
      }
      if ((parsedData.sratadja !== undefined) && (parsedData.sratadja !== null) && (parsedData.sratadja.length !== 0)) {
        wb_new.Sheets["ATADJA - 11(2)"] = XLSX.utils.json_to_sheet(atadjaDataList);
      }
      if ((parsedData.srhsn !== undefined) && (parsedData.srhsn !== null) && (parsedData.srhsn.length !== 0)) {
        wb_new.Sheets["HSN - 12"] = XLSX.utils.json_to_sheet(hsnDataList);
      }
      if ((parsedData.srnil !== undefined) && (parsedData.srnil !== null) && (parsedData.srnil.length !== 0)) {
        wb_new.Sheets["EXEMP - 8A, 8B, 8C, 8D"] = XLSX.utils.json_to_sheet(exempDataList);
      }
      if ((parsedData.srdoc !== undefined) && (parsedData.srdoc !== null) && (parsedData.srdoc.length !== 0)) {
        wb_new.Sheets["DOC - 13"] = XLSX.utils.json_to_sheet(docDataList);
      }

    console.log(wb_new);

    if(wb_new.SheetNames.length !== 0){

      var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      var cur_dt = new Date();

      return fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "Sales Register - " + cur_dt + ".xlsx");
  
    }
    else
    {
      return {
        status: 'EMPTYSHEET'
      }
    }

    
  },

}
export default excelUtility
