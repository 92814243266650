import React from "react";
import { Table, InputNumber, Input, Tag } from "antd";
const GSTR9Pt3Sec8 = props => {
  const columns = [
    {
      title: "Pt. III",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },
    {
      title:
        "8. Other ITC related information",
      children: [
        {
          title: "Description",
          dataIndex: "det",
          key: "det",
          width: "35%",
        },
        {
          title: "Integrated Tax (₹)",
          dataIndex: "iamt",
          key: "iamt",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "2" ||
            record.key === "4" ||
            record.key === "8" ||
            record.key === "9" ||
            record.key === "10" ||
            record.key === "11" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec8-${record.key}-iamt-tbl8`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "Central Tax (₹)",
          dataIndex: "camt",
          key: "camt",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "2" ||
            record.key === "4" ||
            record.key === "8" ||
            record.key === "9" ||
            record.key === "10" ||
            record.key === "11" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec8-${record.key}-camt-tbl8`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "State/UT Tax (₹)",
          dataIndex: "samt",
          key: "samt",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "2" ||
            record.key === "4" ||
            record.key === "8" ||
            record.key === "9" ||
            record.key === "10" ||
            record.key === "11" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec8-${record.key}-samt-tbl8`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        },
        {
          title: "CESS (₹)",
          dataIndex: "cess",
          key: "cess",
          width: "15%",
          render: (text, record) => {
            return record.key === "1" ||
            record.key === "2" ||
            record.key === "4" ||
            record.key === "8" ||
            record.key === "9" ||
            record.key === "10" ||
            record.key === "11" ? (
              <Tag width="100%">{`₹ ${text}`}</Tag>
            ) : text !== undefined ? (
              <Input
                id={`pt3sec8-${record.key}-cess-tbl8`}
                defaultValue={text}
                onKeyDown={props.txvalChange}
                onBlur={props.txtOnBlur}
                addonBefore="₹"
              />
            ) : null;
          }
        }
      ]
    }
  ];
  return (
    <Table
      pagination={false}
      className={props.className}
      bordered
      title={() =>
        "Details of ITC as declared in returns filed during the financial year"
      }
      size="small"
      dataSource={props.pt3sec8.dataSource}
      columns={columns}
    />
  );
};

export default GSTR9Pt3Sec8;
