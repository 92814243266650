import React from 'react'
import glamorous from 'glamorous'
import Tooltip from 'antd/lib/tooltip'
import Button from 'antd/lib/button'
import { Table, Radio, Popconfirm, Card } from 'antd';
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import message from 'antd/lib/message'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import pick from 'lodash/fp/pick'
import { gql, graphql, compose } from 'react-apollo'
import { connect } from 'react-redux'
import DropZone from 'react-dropzone';
import toString from "lodash/fp/toString";
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import Select from 'antd/lib/select'

import { Root, ContentWrapper as Content } from '../../shared/styles'
import { CompanyByIdQuery } from 'qgst-gql-queries/web/data.json'
import { BASE_URL } from '../../shared/constants'
import { gspActions } from "../../gspRedux";
import Itc04Service from '../../services/Itc04Service'
// var table4JSON = require('./table4JSON.json')

const row_style = { border: 'solid 1px #e8e8e8', padding: '1px', margin: '1px', textAlign: 'center' };
var comp_id = '...'
const SRow = glamorous(Row)({ margin: '4px 0', })
const confirm = Modal.confirm;
const errorModal = Modal.error;

// value={} onChange={} onLoad={} 
const Itc04Register = props => {
    return (
        <Root>
            <Content>
                <SRow>
                
                    <Col span={3} >Quarter</Col>
                    <Col span={3} >
                        <Select value={props.quarterNumber} onChange={props.onQuarterDropDownChange.bind(this)} id="year" name="year" style={{ width: '120px' }}>
                            <Select.Option value={13}>Apr-Jun</Select.Option>
                            <Select.Option value={14}>Jul-Sep</Select.Option>
                            <Select.Option value={15}>Oct-Dec</Select.Option>
                            <Select.Option value={16}>Jan-Mar</Select.Option>
                        </Select>
                    </Col>
                    <Col span={5}>
                        <span><Button size="small" className="{ant-btn-primary}" id="sales_download"></Button></span>
                        <span><Button id="sales_confirm_upload" onClick={props.showUploadConfirm}></Button></span>
                                <DropZone onDrop={props.onDropItc04} multiple={false} disabled={props.role === 'reviewer'}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} style={{ marginRight: "65em" }}>
                                            <input {...getInputProps()} />
                                            <Tooltip title="Upload Itc04" overlay="">
                                                <Button size="large" icon="cloud-upload" style={{ width: "180px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }} disabled={props.role === 'reviewer'}>
                                                    Upload Itc04
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    )}
                                </DropZone>
                    </Col>
                </SRow>
                <SRow>
                    <Col>
                        <Card bordered={true}>
                            <Row >
                                <Col align="left" span={2}>
                                    <b></b>
                                </Col>
                                <Col align="middle" span={5}>
                                    <b>Table 4</b>
                                </Col>
                                <Col align="middle" span={5}>
                                    <b>Table 5A</b>
                                </Col>
                                <Col align="middle" span={5}>
                                    <b>Table 5B</b>
                                </Col>
                                <Col align="middle" span={5}>
                                    <b>Table 5C</b>
                                </Col>
                            </Row> 
                            <br/>
                            <Row>
                                <Col align="left" span={2}>
                                    <b>Count</b>
                                </Col>
                                <Col align="middle"  span={5}>
                                    {props.table4InvoiceCount}
                                </Col>
                                <Col align="middle" span={5}>
                                    {props.table5AInvoiceCount}
                                </Col>
                                <Col align="middle" span={5}>
                                    {props.table5BInvoiceCount}
                                </Col>
                                <Col align="middle" span={5}>
                                    {props.table5CInvoiceCount}
                                </Col>
                            </Row>
                            <br/> 
                            <Row>
                                <Col align="left"  span={2}>
                                    <b>Total Value</b>
                                </Col>
                                <Col align="middle"  span={5}>
                                    {props.table4SumValue}
                                </Col>
                                <Col align="middle" span={5}>
                                    
                                </Col>
                                <Col align="middle"  span={5}>
                                    
                                </Col>
                                <Col align="middle" span={5}>
                                    
                                </Col>
                            </Row>    
                            <Row>
                                <Col span={2}></Col>
                                <Col align="middle"  span={5}>
                                    <Button onClick={props.onClickViewTable4btn} size="large" style={{width: "130px", fontSize: '14px', padding: '0 10px', backgroundColor: '#04880fe0', color: 'white' }} id="table4_view">View Table 4</Button>
                                </Col>
                                <Col align="middle" span={5}>
                                    <Button onClick={props.onClickViewTable5Abtn} size="large" style={{width: "130px", fontSize: '14px', padding: '0 10px', backgroundColor: '#04880fe0', color: 'white' }} id="table5A_view">View Table 5A</Button>
                                </Col>
                                <Col align="middle" span={5}>
                                    <Button onClick={props.onClickViewTable5Bbtn} size="large" style={{ width: "130px", fontSize: '14px', padding: '0 10px', backgroundColor: '#04880fe0', color: 'white' }} id="table5B_view">View Table 5B</Button>
                                </Col>
                                <Col align="middle" span={5}>
                                    <Button onClick={props.onClickViewTable5Cbtn} size="large" style={{ width: "130px", fontSize: '14px', padding: '0 10px', backgroundColor: '#04880fe0', color: 'white' }} id="table5C_view">View Table 5C</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </SRow>
                <SRow>
                    <Col>
                        {props.viewTable4 &&
                        <Table align="middle" dataSource={props.tableData.itc04Table4InvoicesDetails} bordered title={() => 'Table 4'} pagination={{ className: 'my-pagination' }}>
                            {/* <Column title="GSTIN of Tax Payer" dataIndex="" ></Column> */}
                            <Column title="State Code" dataIndex="jw_stcd"></Column>
                            <Column title="Challan Number" key="" dataIndex="chnum" ></Column>
                            <Column title="Challan Date" key="" dataIndex="chdt" ></Column>
                            <Column title="Goods Type" dataIndex="goods_ty"></Column>
                            <Column title="Description" dataIndex="desc"></Column>
                            <Column title="UQC" dataIndex="uqc"></Column>
                            <Column title="Quantity" dataIndex="qty"></Column>
                            <Column title="Taxable Value" dataIndex="txval"></Column>
                            <Column title="CGST" dataIndex="tx_c"></Column>
                            <Column title="SGST" dataIndex="tx_s"></Column>
                            <Column title="IGST" dataIndex="tx_i"></Column>
                            <Column title="CESS" dataIndex="tx_cs"></Column>
                        </Table> 
                        }
                    </Col>
                </SRow>
                <SRow>
                    <Col>
                        {props.viewTable5A &&
                            <Table align="middle" dataSource={props.tableData.itc04Table5AInvoicesDetails} bordered title={() => 'Table 5A'} pagination={{ className: 'my-pagination' }}>
                                <Column  title="GSTIN of Tax Payer" dataIndex="ctin" ></Column>
                                <Column title="State Code" dataIndex="jw_stcd"></Column>
                                <Column title="Original Challan Number" key="" dataIndex="o_chnum" ></Column>
                                <Column title="Original Challan Date" key="" dataIndex="o_chdt" ></Column>
                                <Column title="Challan Number" key="" dataIndex="jw2_chnum" ></Column>
                                <Column title="Challan Date" key="" dataIndex="jw2_chdt" ></Column>
                                
                                <Column title="Nature of Job" dataIndex="nat_jw"></Column>
                                <Column title="Description" dataIndex="desc"></Column>
                                <Column title="UQC" dataIndex="uqc"></Column>
                                <Column title="Quantity" dataIndex="qty"></Column>
                                <Column title="Loss UQC" dataIndex="lwuqc"></Column>
                                <Column title="Loss UQC Quantity" dataIndex="lwqty"></Column>
                            </Table>
                        } 
                    </Col>                    
                </SRow>
                <SRow>
                    <Col>
                        {props.viewTable5B &&
                            <Table align="middle" dataSource={props.tableData.itc04Table5BInvoicesDetails} bordered title={() => 'Table 5B'} pagination={{ className: 'my-pagination' }}>
                                <Column  title="GSTIN of Tax Payer" dataIndex="ctin" ></Column>
                                <Column title="State Code" dataIndex="jw_stcd"></Column>
                                <Column title="Original Challan Number" key="" dataIndex="o_chnum" ></Column>
                                <Column title="Original Challan Date" key="" dataIndex="o_chdt" ></Column>
                                <Column title="Challan Number" key="" dataIndex="jw2_chnum" ></Column>
                                <Column title="Challan Date" key="" dataIndex="jw2_chdt" ></Column>
                                
                                <Column title="Nature of Job" dataIndex="nat_jw"></Column>
                                <Column title="Description" dataIndex="desc"></Column>
                                <Column title="UQC" dataIndex="uqc"></Column>
                                <Column title="Quantity" dataIndex="qty"></Column>
                                <Column title="Loss UQC" dataIndex="lwuqc"></Column>
                                <Column title="Loss UQC Quantity" dataIndex="lwqty"></Column>
                            </Table>
                        } 
                    </Col>                    
                </SRow>
                <SRow>
                    <Col>
                        {props.viewTable5C &&
                            <Table align="middle" dataSource={props.tableData.itc04Table5CInvoicesDetails} bordered title={() => 'Table 5C'} pagination={{ className: 'my-pagination' }}>
                                <Column  title="GSTIN of Tax Payer" dataIndex="ctin" ></Column>
                                <Column title="State Code" dataIndex="jw_stcd"></Column>
                                <Column title="Original Challan Number" key="" dataIndex="o_chnum" ></Column>
                                <Column title="Original Challan Date" key="" dataIndex="o_chdt" ></Column>
                                <Column title="Challan Number" key="" dataIndex="inum" ></Column>
                                <Column title="Challan Date" key="" dataIndex="idt" ></Column>
                                
                                <Column title="Nature of Job" dataIndex="nat_jw"></Column>
                                <Column title="Description" dataIndex="desc"></Column>
                                <Column title="UQC" dataIndex="uqc"></Column>
                                <Column title="Quantity" dataIndex="qty"></Column>
                                <Column title="Loss UQC" dataIndex="lwuqc"></Column>
                                <Column title="Loss UQC Quantity" dataIndex="lwqty"></Column>
                            </Table>
                        } 
                    </Col>                    
                </SRow>
            </Content>
        </Root>
    )
}

class Itc04Container extends React.Component{
    constructor(props){
        super(props);
        if (props.gspDetails !== undefined && props.gspDetails !== null) {
            this.gsp = {
                access_token: props.gspDetails.gspToken,
                otp: props.gspDetails.otp,
                response: null,
                response_delete: null,
            };
        } else {
            this.gsp = {};
        }
        const quartCurrNumber = Itc04Service.quarterCurrent();
        this.state = {
            finMonth: props.finMonth,
            finYear: props.finYear,
            companyId: props.selectedCompany,
            quarterCurrentNumber: quartCurrNumber,
            viewTable4:true, viewTable5A:false, viewTable5B:false, viewTable5C:false

        }
        comp_id = props.selectedCompany;
        this.onQuarterDropDownChange = this.onQuarterDropDownChange.bind(this);
        this.onClickViewTable4btn = this.onClickViewTable4btn.bind(this);
        this.onClickViewTable5Abtn = this.onClickViewTable5Abtn.bind(this);
        this.onClickViewTable5Bbtn = this.onClickViewTable5Bbtn.bind(this);
        this.onClickViewTable5Cbtn = this.onClickViewTable5Cbtn.bind(this);

    }

    onClickViewTable4btn = () => {
        this.setState({viewTable4:true, viewTable5A:false, viewTable5B:false, viewTable5C:false})
    }

    onClickViewTable5Abtn = () => {
        this.setState({viewTable4:false, viewTable5A:true, viewTable5B:false, viewTable5C:false})
    }

    onClickViewTable5Bbtn = () => {
        this.setState({viewTable4:false, viewTable5A:false, viewTable5B:true, viewTable5C:false})
    }

    onClickViewTable5Cbtn = () => {
        this.setState({viewTable4:false, viewTable5A:false, viewTable5B:false, viewTable5C:true})
    }

    _callAPIUpdateState = async () => {
        const itc04DataFromAPI = await Itc04Service.getInvoices({quarterNumber: this.state.quarterCurrentNumber, finYear: this.state.finYear})
        const itc04SummaryFromAPI = itc04DataFromAPI.invoiceSummary
        const itc04DetailsFromAPI = itc04DataFromAPI.invoiceDetails
        
        this.setState({
            table4SumValueFromAPI: itc04SummaryFromAPI.table4_sumTaxableValue,
            table4CountInvoicesFromAPI: itc04SummaryFromAPI.table4_countInvoices,
            table5ACountInvoicesFromAPI: itc04SummaryFromAPI.table5A_countInvoices,
            table5BCountInvoicesFromAPI: itc04SummaryFromAPI.table5B_countInvoices,
            table5CCountInvoicesFromAPI: itc04SummaryFromAPI.table5C_countInvoices,

            tableDataFromAPI : itc04DetailsFromAPI
        })
    }

    componentWillMount = async () => {
        // console.log("componentWillMount")
        await this._callAPIUpdateState()
    }

    componentDidUpdate = () => {
        if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
            this.gsp.access_token = this.props.gspDetails.gspToken;
            this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
        }
    };

    onDropItc04 = async acceptedFiles => {

        var el = document.body,
        elChild = document.createElement('div');
        elChild.className = "root_div";
        elChild.innerHTML = '<div class="custom_loading loader"></div>';
        el.insertBefore(elChild, el.firstChild);
        document.getElementById('root').classList.add("blur");
        const result = await Itc04Service.upload({
            file: acceptedFiles[0],
            type: 'itc04',
            companyId: this.props.selectedCompany,
            quarterNumber: this.state.quarterCurrentNumber, 
            finYear: this.state.finYear
        })

        document.body.getElementsByClassName("root_div")[0].remove();
        document.getElementById('root').classList.remove('blur');
        if ((result.error_status !== '') && (result.error_status !== undefined)) {
        }
        if (result.filestatus === true) {
            if (result.flag === true) {
                var dialogue_msg = '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif" / style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' + result.total_errors + ' Invoices Failed out of ' + result.total + ' Invoices</b></span>) ITC 04 partially saved!</h4></p><br><p>Error Excel will be downloaded. Please check and correct the Errors!</p></div>';
                var div = document.getElementById('dialogue_append1');
                if (document.getElementById('dialogue_append1') !== null) {
                    div.innerHTML += dialogue_msg;
                }
            }
            if (result.total_errors > 0) {
                this.props.v2_sr_tablewise_summary.refetch();
            }
            else {
                this.props.v2_sr_tablewise_summary.refetch();
                message.success('(' + result.total + '/' + result.total + ') ITC 04 uploaded successfully!', 3)
            }
        } else if (result.file === false) {
            message.error('Uploaded file is not in Tally Excel format!', 3)
        } else {
            message.error('Upload File Type is Not Allowed', 3)
        }
       this._callAPIUpdateState()
    }

    showUploadConfirm() {
        confirm({
            title: 'Errors Found While Uploading ITC 04',
            iconType: 'printer',
            width: 866,
            content: (<div id="dialogue_append1"></div>),
            onOk() {
                document.getElementById("sales_download").click();
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('Oops errors!'));
            }
        });
    }

    onQuarterDropDownChange(quarterValue){
        this.setState({quarterCurrentNumber:quarterValue})
        this._callAPIUpdateState()
    }

    render(){
        // console.log("render")
        // console.log(this.state.table4DataFromAPI)
        return(
            
           <div>
            {this.state && this.state.tableDataFromAPI &&
                <div style={{ backgroundColor: '#fff', padding: '5px' }}>
                    <Itc04Register
                        role={get('company.role', this.props)}
                        finMonth={this.state.finMonth}
                        finYear={this.state.finYear}
                        showUploadConfirm={this.showUploadConfirm}
                        onDropItc04={this.onDropItc04}
                        tableData = {this.state.tableDataFromAPI}
                        table4SumValue = {this.state.table4SumValueFromAPI}
                        table4InvoiceCount = {this.state.table4CountInvoicesFromAPI}
                        table5AInvoiceCount = {this.state.table5ACountInvoicesFromAPI}
                        table5BInvoiceCount = {this.state.table5BCountInvoicesFromAPI}
                        table5CInvoiceCount = {this.state.table5CCountInvoicesFromAPI}
                        quarterNumber = {this.state.quarterCurrentNumber}
                        onQuarterDropDownChange = {this.onQuarterDropDownChange}
                        viewTable4 = {this.state.viewTable4}
                        viewTable5A = {this.state.viewTable5A}
                        viewTable5B = {this.state.viewTable5B}
                        viewTable5C = {this.state.viewTable5C}
                        onClickViewTable4btn = {this.onClickViewTable4btn}
                        onClickViewTable5Abtn = {this.onClickViewTable5Abtn}
                        onClickViewTable5Bbtn = {this.onClickViewTable5Bbtn}
                        onClickViewTable5Cbtn = {this.onClickViewTable5Cbtn}
                    />
                </div>
            }
            </div>            
            )
    }
}

const companyQueryConfig = {
    name: "company",
    options: props => ({
        variables: {
            companyId: props.selectedCompany,
            finYear: props.finYear,
            finMonth: props.finMonth,
        },
    }),
    props: ({ company }) => {
        return {
            company: get('viewer.company', company),
            gspDetails: get("viewer.gspInfo", company),
            externalInterfaceInfo: get("viewer.externalInterfaceInfo", company)
        }
    },
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.local.selectedCompany,
        finMonth: state.local.finMonth,
        finYear: state.local.finYear,
        gspDetails: state.gsp
    }
}

export default compose(
    connect(mapStateToProps,
        {
            setGspToken: gspActions.setGspToken,
            setGspOtp: gspActions.setGspOtp,
            setGspDetails: gspActions.setGspDetails
        }),
    graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(Itc04Container)