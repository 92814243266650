import React from 'react'
import {
   AreaChart,
   Area,
   PieChart,
   Pie,
   Sector,
   BarChart,
   Bar,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend
  } from 'recharts'
import { formatNumber, BASE_URL } from "../../shared/constants";
import {
  Row,
  Col,
  Skeleton,
  Table,
  Radio
} from 'antd';
import orderBy from "lodash/fp/orderBy";
import get from "lodash/fp/get";
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { ContentWrapper } from '../../shared/styles'
const { ColumnGroup, Column } = Table
var prevFinYear


class InsightsStatistics extends React.Component {

  state = { activeIndex: 0, view: 'table' };

onPieEnter(taxdata, index) {
this.setState({
  activeIndex: index,
});
}

componentDidMount () {
 
      this.fetchStats()
      var temp_year = this.props.finYear.split('-');

    temp_year[0] = parseInt(temp_year[0]) - 1

    temp_year[1] =  parseInt(temp_year[1]) - 1 

    prevFinYear = temp_year.join('-')
    
}

onPieEnter(data, index) {
this.setState({
  activeIndex: index,
});
}

fetchStats = () => {
  var downloaddate = this.props.finMonth + "/" + this.props.finYear;
  fetch(
        `${BASE_URL}/export/placeOfSupply?companyId=${this.props.companyId}&downloaddate=${downloaddate}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(async result => {
          console.log(result);
          this.setState({ loading: false, insightsData: orderBy(['currValue'], ['desc'], result) });
        });
}
handleViewChange = () => {
  if(this.state.view === 'table'){
    this.setState({ view: 'graph' });  
  }
  if(this.state.view === 'graph'){
    this.setState({ view: 'table' });  
  }
  
}


render () {

  console.log(this.props);
  
  
return (
  <ContentWrapper>
  
  <Row span={24}>
  <Col span={24}>
  <p style={{ fontSize: "20px"}}>FY {prevFinYear} vs FY {this.props.finYear}</p>
  <br />
  <Radio.Group onChange={this.handleViewChange} value={this.state.view} style={{ marginBottom: 8 }}>
          <Radio.Button value="table">Tabular View</Radio.Button>
          <Radio.Button value="graph">Graphical View</Radio.Button>
        </Radio.Group>

        {this.state.view === 'graph' &&
  <BarChart
        width={1300}
        height={500}
        data={this.state.insightsData.slice(0,10)}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="pos" />
        <YAxis />
        <Tooltip formatter={(value, name, props) =>( formatNumber(value) )} itemSorter={() => 0} payload={this.state.insightsData} />
        <Legend />
        <Bar dataKey="prevValue" stackId="a" fill="#8884d8"  />
        <Bar dataKey="currValue" stackId="b" fill="#82ca9d" />
      </BarChart>
    }
    {this.state.view === 'table' &&
  
<Table dataSource={this.state.insightsData} bordered rowKey="pos" title={() => 'Place Of Supply Ranking'} pagination={{ className: 'my-pagination' }}>
          <Column title="Place of Supply" dataIndex="pos" key="pos" />
          <Column title={"Total Value " + prevFinYear} dataIndex="prevValue" key="prevValue" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>}/>
          <Column title={"Total Value " + this.props.finYear} dataIndex="currValue" key="currValue" render={val => <p style={{ width: '115px'}}>{formatNumber(val === null ? 0 : val)}</p>}/>
          </Table> 
        }
  </Col>
  </Row>
   </ContentWrapper>
);
}
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};



export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(InsightsStatistics)