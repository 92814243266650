import React from 'react'
import { Form, Button, InputNumber, Anchor, Layout, message } from 'antd'
import { gql, graphql, compose, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { formatNumber } from "../../shared/constants";
import get from 'lodash/fp/get'
import TableFour from './tableFour'
import TableFive from './tableFive'
import { BASE_URL } from '../../shared/constants'
import { ContentWrapper } from '../../shared/styles'
import { FormItem } from '../../shared/utils'
import map from 'lodash/fp/map'
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 6,
    },
  },
}

const { Link } = Anchor;
const { Sider, Content } = Layout;
var inputRef = [];
var focusedItem = null;



  class EnterGSTR9_P_TWO extends React.Component {

    saveGSTR9 = async e => {
      e.preventDefault()
      this.props.form.validateFields(async (err, values) => {
        if (err) return
        console.log(values);
        try {
          // await this.props.saveGSTR9(values)
          // // Reset fields only when adding new invoice
          // !this.props.isEdit && this.props.form.resetFields()
          // message.success('GSTR3B saved successfully!');
          // // window.history.back();
        }
        catch (e) {
          // message.error(e.graphQLErrors[0].message);
        }
      })
    }

    render() {
      return(
        <Layout style={{ backgroundColor: "#FFFFFF" }}>
        <Content>
          <TableFour 
            getFieldDecorator={this.props.form.getFieldDecorator}
            saveGSTR9={this.saveGSTR9}
          />
          
        </Content>
        <Sider style={{ backgroundColor: "##D3D3D3" }} theme="light" width="100">
           <Anchor style={{ backgroundColor: "##D3D3D3" }} offsetTop={100}>
                 <Button icon="safety" type="success" onClick={this.saveGSTR9} >
                 Save
                 </Button>
                 <br />
                 <br />
                 <Button icon="info-circle" type="danger" > Reset</Button>
           </Anchor>

        </Sider>
      </Layout>
        )
    }
  }


const mapStateToProps = state => {
    return {
      companyId: state.local.selectedCompany,
      finMonth: state.local.finMonth,
      finYear: state.local.finYear,
    }
  }

export default compose(
    withRouter,
    withApollo,
    connect(mapStateToProps),
    Form.create(),
  )(EnterGSTR9_P_TWO)