import React from 'react'
import {
   AreaChart,
   Area,
   PieChart,
   Pie,
   Sector,
   BarChart,
   Bar,
   XAxis,
   YAxis,
   CartesianGrid,
   Tooltip,
   Legend
  } from 'recharts'
import { formatNumber, BASE_URL } from "../../shared/constants";
import {
  Row,
  Col,
  Skeleton,
  Table
} from 'antd';
import orderBy from "lodash/fp/orderBy";
import get from "lodash/fp/get";
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { ContentWrapper } from '../../shared/styles'
const { ColumnGroup, Column } = Table


const renderActiveShape = (props) => {
  console.log(props);
const RADIAN = Math.PI / 180;
const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
fill, payload, percent, value } = props;
const sin = Math.sin(-RADIAN * midAngle);
const cos = Math.cos(-RADIAN * midAngle);
const sx = cx + (outerRadius + 10) * cos;
const sy = cy + (outerRadius + 10) * sin;
const mx = cx + (outerRadius + 30) * cos;
const my = cy + (outerRadius + 30) * sin;
const ex = mx + (cos >= 0 ? 1 : -1) * 22;
const ey = my;
const textAnchor = cos >= 0 ? 'start' : 'end';

return (
<g>
  <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
  <Sector
    cx={cx}
    cy={cy}
    innerRadius={innerRadius}
    outerRadius={outerRadius}
    startAngle={startAngle}
    endAngle={endAngle}
    fill={fill}
  />
  <Sector
    cx={cx}
    cy={cy}
    startAngle={startAngle}
    endAngle={endAngle}
    innerRadius={outerRadius + 6}
    outerRadius={outerRadius + 10}
    fill={fill}
  />
  <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
  <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
  <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`₹ ${value}`}</text>
  <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
    {`(Rate ${(percent * 100).toFixed(2)}%)`}
  </text>
</g>
);
};



class InsightsStatistics extends React.Component {

  state = { loading: false };

onPieEnter(taxdata, index) {
this.setState({
  activeIndex: index,
});
}

// componentDidMount () {
 
//       this.fetchStats()
    
// }

onPieEnter(data, index) {
this.setState({
  activeIndex: index,
});
}

fetchStats = () => {
  fetch(
        `${BASE_URL}/export/fetchTaxStats?companyId=${this.props.companyId}`,
        {
          method: "GET"
        }
      )
        .then(res => res.json())
        .then(async result => {
          console.log(result);
          this.setState({ loading: true })

        });
}


render () {

  console.log(this.props);
  
  
return (
  <ContentWrapper>
  
  <Row span={24}>
  <Col span={24}>

  

          <p>Total Tax Paid</p>
          <p>Total ITC Claimed</p>
          <p>ITC Utililzed</p>
          <p>Tax Paid through Challan (Cash)</p>

  </Col>
  </Row>
   </ContentWrapper>
);
}
}

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};



export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(InsightsStatistics)