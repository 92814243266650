import fetch from 'isomorphic-fetch'
import { BASE_URL } from '../shared/constants'
const XLSX = require('xlsx')
var fileSaver = require('file-saver')
var blob = require('blob-util')
const {
	flow,
	map,
	flattenDeep,
	findIndex,
	findLast,
	filter
} = require("lodash/fp");
const Anx1Sales = {
	upload: ({ file, type, tally, companyId, isSR, finMonth, finYear }) => {
		const data = new FormData()
		data.append(type, file)
		data.append('companyId', companyId)
		data.append('isSR', isSR)
		data.append('tally', tally)
		data.append('finMonth', finMonth)
		data.append('finYear', finYear)
		return fetch(`${BASE_URL}/upload/${type}`, {
			method: 'POST',
			body: data,
		}).then(res => res.json()).then(function (parsedData) {
			console.log('----Parsed_Data----');
			console.log(parsedData);

			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
				return buf;
			}

			var wb_new = XLSX.utils.book_new();
			if ((parsedData.srb2cs) && (parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
				wb_new.SheetNames.push("B2C");
			}
			if ((parsedData.srb2b) && (parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
				wb_new.SheetNames.push("B2B");
			}
			if ((parsedData.srexport) && (parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
				wb_new.SheetNames.push("EXP");
			}
			if ((parsedData.srsez) && (parsedData.srsez !== undefined) && (parsedData.srsez !== null)) {
				wb_new.SheetNames.push("SEZ");
			}
			if ((parsedData.srde) && (parsedData.srde !== undefined) && (parsedData.srde !== null)) {
				wb_new.SheetNames.push("DE");
			}
			if ((parsedData.srrev) && (parsedData.srrev !== undefined) && (parsedData.srrev !== null)) {
				wb_new.SheetNames.push("REV");
			}
			if ((parsedData.srimps) && (parsedData.srimps !== undefined) && (parsedData.srimps !== null)) {
				wb_new.SheetNames.push("IMPS");
			}
			if ((parsedData.srimpg) && (parsedData.srimpg !== undefined) && (parsedData.srimpg !== null)) {
				wb_new.SheetNames.push("IMPG");
			}
			if ((parsedData.srimpgsez) && (parsedData.srimpgsez !== undefined) && (parsedData.srimpgsez !== null)) {
				wb_new.SheetNames.push("IMPGSEZ");
			}
			if ((parsedData.srecom) && (parsedData.srecom !== undefined) && (parsedData.srecom !== null)) {
				wb_new.SheetNames.push("ECOM");
			}
			var b2csDataList = [];
			var b2csData = {};
			if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
				for (var i = 0; i < parsedData.srb2cs.length; i++) {
					if (parsedData.srb2cs[i].Status === "" || parsedData.srb2cs[i].Status === undefined) {
						break;
					}
					b2csData =
					{
						'Place of Supply*': parsedData.srb2cs[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srb2cs[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srb2cs[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'Rate*': parsedData.srb2cs[i]["Rate*"],
						'Taxable Value*': parsedData.srb2cs[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srb2cs[i]["Integrated Tax"],
						'Central Tax': parsedData.srb2cs[i]["Central Tax"],
						'State/UT Tax': parsedData.srb2cs[i]["State/UT Tax"],
						'Cess Amount': parsedData.srb2cs[i]["Cess"],
						'Status': parsedData.srb2cs[i]["Status"]
					};
					b2csDataList.push(b2csData);
				}
				if (b2csDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "B2C")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			var b2bDataList = [];
			var b2bData = {};
			if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
				for (var i = 0; i < parsedData.srb2b.length; i++) {
					if (parsedData.srb2b[i].Status === "" || parsedData.srb2b[i].Status === undefined) {
						break;
					}
					b2bData =
					{
						'GSTIN/UIN of Recipient*': parsedData.srb2b[i]["GSTIN/UIN of Recipient*"],
						'Trade/Legal Name': parsedData.srb2b[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srb2b[i]["Document Type*"],
						'Document Number*': parsedData.srb2b[i]["Document Number*"],
						'Document Date*': parsedData.srb2b[i]["Document Date*"],
						'Document Value*': parsedData.srb2b[i]["Document Value*"],
						'Place of Supply*': parsedData.srb2b[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srb2b[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srb2b[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'HSN Code': parsedData.srb2b[i]["HSN Code"],
						'Rate*': parsedData.srb2b[i]["Rate*"],
						'Taxable Value*': parsedData.srb2b[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srb2b[i]["Integrated Tax"],
						'Central Tax': parsedData.srb2b[i]["Central Tax"],
						'State/UT Tax': parsedData.srb2b[i]["State/UT Tax"],
						'Cess': parsedData.srb2b[i]["Cess"],
						'Status': parsedData.srb2b[i]["Status"]
					};
					b2bDataList.push(b2bData);
				}
				if (b2bDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "B2B")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}
			var expDataList = [];
			var expData = {};
			if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
				for (var i = 0; i < parsedData.srexport.length; i++) {
					if (parsedData.srexport[i].Status === "" || parsedData.srexport[i].Status === undefined) {
						break;
					}
					expData =
					{
						'Document Type*': parsedData.srexport[i]["Document Type*"],
						'Document Number*': parsedData.srexport[i]["Document Number*"],
						'Document Date*': parsedData.srexport[i]["Document Date*"],
						'Document Value*': parsedData.srexport[i]["Document Value*"],
						'Export Type*': parsedData.srexport[i]["Export Type*"],
						'Port Code': parsedData.srexport[i]["Port Code"],
						'Shipping Bill Number': parsedData.srexport[i]["Shipping Bill Number"],
						'Shipping Bill Date': parsedData.srexport[i]["Shipping Bill Date"],
						'HSN Code*': parsedData.srexport[i]["HSN Code*"],
						'Rate*': parsedData.srexport[i]["Rate*"],
						'Taxable Value*': parsedData.srexport[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srexport[i]["Integrated Tax"],
						'Cess': parsedData.srexport[i]["Cess"],
						'Status': parsedData.srexport[i]["Status"]
					};
					expDataList.push(expData);
				}
				if (expDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "EXP")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}
			var sezDataList = [];
			var sezData = {};
			if ((parsedData.srsez !== undefined) && (parsedData.srsez !== null)) {
				for (var i = 0; i < parsedData.srsez.length; i++) {
					if (parsedData.srsez[i].Status === "" || parsedData.srsez[i].Status === undefined) {
						break;
					}
					sezData =
					{
						'GSTIN of Recipient*': parsedData.srsez[i]["GSTIN of Recipient*"],
						'Trade/Legal Name': parsedData.srsez[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srsez[i]["Document Type*"],
						'Document Number*': parsedData.srsez[i]["Document Number*"],
						'Document Date*': parsedData.srsez[i]["Document Date*"],
						'Document Value*': parsedData.srsez[i]["Document Value*"],
						'Place of Supply*': parsedData.srsez[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srsez[i]["Differential % of Tax Rate*"],
						'GST Payment Type*': parsedData.srsez[i]["GST Payment Type*"],
						'Differential % of Tax Rate*': parsedData.srsez[i]["Differential % of Tax Rate*"],
						'Would You Claim Refund*': parsedData.srsez[i]["Would You Claim Refund*"],
						'HSN Code*': parsedData.srsez[i]["HSN Code*"],
						'Rate*': parsedData.srsez[i]["Rate*"],
						'Taxable Value*': parsedData.srsez[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srsez[i]["Integrated Tax"],
						'Cess': parsedData.srsez[i]["Cess"],
						'Status': parsedData.srsez[i]["Status"]
					};
					sezDataList.push(sezData);
				}
				if (sezDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "SEZ")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}

			}
			var deDataList = [];
			var deData = {};
			if ((parsedData.srde !== undefined) && (parsedData.srde !== null)) {
				for (var i = 0; i < parsedData.srde.length; i++) {
					if (parsedData.srde[i].Status === "" || parsedData.srde[i].Status === undefined) {
						break;
					}
					deData =
					{
						'GSTIN of Recipient*': parsedData.srde[i]["GSTIN of Recipient*"],
						'Trade/Legal Name': parsedData.srde[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srde[i]["Document Type*"],
						'Document Number*': parsedData.srde[i]["Document Number*"],
						'Document Date*': parsedData.srde[i]["Document Date*"],
						'Document Value*': parsedData.srde[i]["Document Value*"],
						'Place of Supply*': parsedData.srde[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srde[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srde[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'Differential % of Tax Rate*': parsedData.srde[i]["Differential % of Tax Rate*"],
						'Would You Claim Refund*': parsedData.srde[i]["Would You Claim Refund*"],
						'HSN Code*': parsedData.srde[i]["HSN Code*"],
						'Rate*': parsedData.srde[i]["Rate*"],
						'Taxable Value*': parsedData.srde[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srde[i]["Integrated Tax"],
						'Central Tax': parsedData.srde[i]["Central Tax"],
						'State/UT Tax': parsedData.srde[i]["State/UT Tax"],
						'Cess': parsedData.srde[i]["Cess"],
						'Status': parsedData.srde[i]["Status"]
					};
					deDataList.push(deData);
				}
				if (deDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "DE")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}



			var revDataList = [];
			var revData = {};
			if ((parsedData.srrev !== undefined) && (parsedData.srrev !== null)) {
				for (var i = 0; i < parsedData.srrev.length; i++) {
					if (parsedData.srrev[i].Status === "" || parsedData.srrev[i].Status === undefined) {
						break;
					}
					revData =
					{
						'GSTIN/PAN of Supplier*': parsedData.srrev[i]["GSTIN/PAN of Supplier*"],
						'Trade/Legal Name': parsedData.srrev[i]["Trade/Legal Name"],
						'Place Of Supply*': parsedData.srrev[i]["Place Of Supply*"],
						'Differential % of Tax Rate*': parsedData.srrev[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srrev[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'Supply Type': parsedData.srrev[i]["Supply Type"],
						'HSN Code': parsedData.srrev[i]["HSN Code"],
						'Rate*': parsedData.srrev[i]["Rate*"],
						'Taxable Value*': parsedData.srrev[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srrev[i]["Integrated Tax"],
						'Central Tax*': parsedData.srrev[i]["Central Tax*"],
						'State/UT Tax*': parsedData.srrev[i]["State/UT Tax*"],
						'Cess': parsedData.srrev[i]["Cess"],
						'Status': parsedData.srrev[i]["Status"]
					};
					revDataList.push(revData);
				}
				if (revDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "REV")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}


			var impsDataList = [];
			var impsData = {};
			if ((parsedData.srimps !== undefined) && (parsedData.srimps !== null)) {
				for (var i = 0; i < parsedData.srimps.length; i++) {
					if (parsedData.srimps[i].Status === "" || parsedData.srimps[i].Status === undefined) {
						break;
					}
					impsData =
					{
						'Place of Supply*': parsedData.srimps[i]["Place of Supply*"],
						'HSN Code*': parsedData.srimps[i]["HSN Code*"],
						'Rate*': parsedData.srimps[i]["Rate*"],
						'Taxable Value*': parsedData.srimps[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srimps[i]["Integrated Tax"],
						'Cess': parsedData.srimps[i]["Cess"],
						'Status': parsedData.srimps[i]["Status"]
					};
					impsDataList.push(impsData);
				}
				if (impsDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "IMPS")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			var impgDataList = [];
			var impgData = {};
			if ((parsedData.srimpg !== undefined) && (parsedData.srimpg !== null)) {
				for (var i = 0; i < parsedData.srimpg.length; i++) {
					if (parsedData.srimpg[i].Status === "" || parsedData.srimpg[i].Status === undefined) {
						break;
					}
					impgData =
					{
						'Document Type*': parsedData.srimpg[i]["Document Type*"],
						'Port Code*': parsedData.srimpg[i]["Port Code*"],
						'Bill of Entry Number*': parsedData.srimpg[i]["Bill of Entry Number*"],
						'Bill of Entry Date*': parsedData.srimpg[i]["Bill of Entry Date*"],
						'Bill of Entry Value*': parsedData.srimpg[i]["Bill of Entry Value*"],
						'Place of Supply*': parsedData.srimpg[i]["Place of Supply*"],
						'HSN Code*': parsedData.srimpg[i]["HSN Code*"],
						'Rate*': parsedData.srimpg[i]["Rate*"],
						'Taxable Value*': parsedData.srimpg[i]["Taxable Value*"],
						'Integrated Tax Paid': parsedData.srimpg[i]["Integrated Tax Paid"],
						'Cess Paid': parsedData.srimpg[i]["Cess Paid"],
						'Status': parsedData.srimpg[i]["Status"]
					};
					impgDataList.push(impgData);
				}
				if (impgDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "IMPG")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}
			var impgsezDataList = [];
			var impgsezData = {};
			if ((parsedData.srimpgsez !== undefined) && (parsedData.srimpgsez !== null)) {
				for (var i = 0; i < parsedData.srimpgsez.length; i++) {
					if (parsedData.srimpgsez[i].Status === "" || parsedData.srimpgsez[i].Status === undefined) {
						break;
					}
					impgsezData =
					{
						'GSTIN of Supplier*': parsedData.srimpgsez[i]["GSTIN of Supplier*"],
						'Trade/Legal Name': parsedData.srimpgsez[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srimpgsez[i]["Document Type*"],
						'Port Code*': parsedData.srimpgsez[i]["Port Code*"],
						'Bill of Entry Number*': parsedData.srimpgsez[i]["Bill of Entry Number*"],
						'Bill of Entry Date*': parsedData.srimpgsez[i]["Bill of Entry Date*"],
						'Bill of Entry Value*': parsedData.srimpgsez[i]["Bill of Entry Value*"],
						'Place of Supply*': parsedData.srimpgsez[i]["Place of Supply*"],
						'HSN Code*': parsedData.srimpgsez[i]["HSN Code*"],
						'Rate*': parsedData.srimpgsez[i]["Rate*"],
						'Taxable Value*': parsedData.srimpgsez[i]["Taxable Value*"],
						'Integrated Tax Paid': parsedData.srimpgsez[i]["Integrated Tax Paid"],
						'Cess Paid': parsedData.srimpgsez[i]["Cess Paid"],
						'Status': parsedData.srimpgsez[i]["Status"]
					};
					impgsezDataList.push(impgsezData);
				}
				if (impgsezDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "IMPGSEZ")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			var ecomDataList = [];
			var ecomData = {};
			if ((parsedData.srecom !== undefined) && (parsedData.srecom !== null)) {
				for (var i = 0; i < parsedData.srecom.length; i++) {
					if (parsedData.srecom[i].Status === "" || parsedData.srecom[i].Status === undefined) {
						break;
					}
					ecomData =
					{
						'GSTIN of E-com*': parsedData.srecom[i]["GSTIN of E-com*"],
						'Trade/Legal Name': parsedData.srecom[i]["Trade/Legal Name"],
						'Value of supplies made*': parsedData.srecom[i]["Value of supplies made*"],
						'Value of Suppies Returned*': parsedData.srecom[i]["Value of Suppies Returned*"],
						'Net Value of Supplies': parsedData.srecom[i]["Net Value of Supplies"],
						'Integrated Tax': parsedData.srecom[i]["Integrated Tax"],
						'Central Tax': parsedData.srecom[i]["Central Tax"],
						'State/UT Tax': parsedData.srecom[i]["State/UT Tax"],
						'Cess': parsedData.srecom[i]["Cess"],
						'Status': parsedData.srecom[i]["Status"]
					};
					ecomDataList.push(ecomData);
				}
				if (ecomDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "ECOM")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			if ((parsedData.srb2cs) && (parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
				wb_new.Sheets["B2C"] = XLSX.utils.json_to_sheet(b2csDataList);
			}
			if ((parsedData.srb2b) && (parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
				wb_new.Sheets["B2B"] = XLSX.utils.json_to_sheet(b2bDataList);
			}
			if ((parsedData.srexport) && (parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
				wb_new.Sheets["EXP"] = XLSX.utils.json_to_sheet(expDataList);
			}
			if ((parsedData.srsez) && (parsedData.srsez !== undefined) && (parsedData.srsez !== null)) {
				wb_new.Sheets["SEZ"] = XLSX.utils.json_to_sheet(sezDataList);
			}
			if ((parsedData.srde) && (parsedData.srde !== undefined) && (parsedData.srde !== null)) {
				wb_new.Sheets["DE"] = XLSX.utils.json_to_sheet(deDataList);
			}
			if ((parsedData.srrev) && (parsedData.srrev !== undefined) && (parsedData.srrev !== null)) {
				wb_new.Sheets["REV"] = XLSX.utils.json_to_sheet(revDataList);
			}
			if ((parsedData.srimps) && (parsedData.srimps !== undefined) && (parsedData.srimps !== null)) {
				wb_new.Sheets["IMPS"] = XLSX.utils.json_to_sheet(impsDataList);
			}
			if ((parsedData.srimpg) && (parsedData.srimpg !== undefined) && (parsedData.srimpg !== null)) {
				wb_new.Sheets["IMPG"] = XLSX.utils.json_to_sheet(impgDataList);
			}
			if ((parsedData.srimpgsez) && (parsedData.srimpgsez !== undefined) && (parsedData.srimpgsez !== null)) {
				wb_new.Sheets["IMPGSEZ"] = XLSX.utils.json_to_sheet(impgsezDataList);
			}
			if ((parsedData.srecom) && (parsedData.srecom !== undefined) && (parsedData.srecom !== null)) {
				wb_new.Sheets["ECOM"] = XLSX.utils.json_to_sheet(ecomDataList);
			}


			var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });
			var btn = document.getElementById("sales_download");

			btn.onclick = function () {
				var cur_dt = new Date();
				fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "SALES - " + cur_dt + ".xlsx");
			};
			if (parsedData.flag === true) {
				document.getElementById("sales_confirm_upload").click();
			}
			return parsedData;
		})
	},
	downloadExport: (srexport) => {

		function s2ab(s) {
			var buf = new ArrayBuffer(s.length);
			var view = new Uint8Array(buf);
			for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
			return buf;
		}
		var wb_new = XLSX.utils.book_new();
		if ((srexport !== undefined) && (srexport !== null) && (srexport.length !== 0)) {
			wb_new.SheetNames.push("EXP - 6A");
		}

		var exportsDataList = [];
		if ((srexport !== undefined) && (srexport !== null)) {
			const ExportData = flow(map(Inv => {
				const InvoiceItems = map(InvoiceItems => {
					return {
						'Export Type*': Inv.exp_type,
						'Invoice No.*': Inv.invoice_number,
						'Invoice Date*': Inv.invoice_date,
						'Invoice Value*': Inv.invoice_value,
						'Applicable % of Tax Rate': Inv.atr,
						'Port Code': Inv.sb_port_code,
						'Shipping Bill No.': Inv.sb_number,
						'Shipping Bill Date': Inv.sb_date,
						'Rate*': InvoiceItems.gst_rate,
						'Taxable Value*': InvoiceItems.taxable_value,
						'Integrated Tax*': InvoiceItems.igst,
						'Cess': InvoiceItems.cess,
					}
				})(Inv.sr_export_items.edges)
				return InvoiceItems;
			}),
				flattenDeep)(srexport)

			exportsDataList = ExportData;
		}

		if ((srexport !== undefined) && (srexport !== null) && (srexport.length !== 0)) {
			wb_new.Sheets["EXP - 6A"] = XLSX.utils.json_to_sheet(exportsDataList);
		}

		var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });

		var cur_dt = new Date();

		return fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "Export Summary - " + cur_dt + ".xlsx");

	},
	syncAnx1: (data, type) => {
		return fetch(`${BASE_URL}/gsp/sync-anx1`, {
			headers: {
				'Content-Type': 'application/json',
				'period': data.period,
				'gsptoken': data.gspDetails.gspToken,
				'otp': data.gspDetails.otp,
				'gstnusername': data.companyDetails.gstnUserName,
				'state': data.companyDetails.state,
				'gstin': data.companyDetails.gstin,
				'id': data.companyDetails.id,
			},
			method: 'POST',
			body: JSON.stringify(data),
		}).then(res => res.json()).then(function (anx1Data) {

			if (!anx1Data.retstatus || (anx1Data.retstatus && !anx1Data.retstatus.error_report) || (anx1Data.retstatus.error_report.error_cd === "ANX1SX20030")) {
				return anx1Data;
			}
			const Data_Invoice = anx1Data.retstatus.error_report;
			console.log("Data_Invoice")
			console.log(Data_Invoice.data)
			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
				return buf;
			}

			var wb_new = XLSX.utils.book_new();
			if ((Data_Invoice.data.b2c) && (Data_Invoice.data.b2c !== undefined) && (Data_Invoice.data.b2c !== null)) {
				wb_new.SheetNames.push("B2C");
			}
			if ((Data_Invoice.data.b2b) && (Data_Invoice.data.b2b !== undefined) && (Data_Invoice.data.b2b !== null)) {
				wb_new.SheetNames.push("B2B");
			}
			if ((Data_Invoice.data.expwp) && (Data_Invoice.data.expwp !== undefined) && (Data_Invoice.data.expwp !== null)) {
				wb_new.SheetNames.push("EXPWP");
			}
			if ((Data_Invoice.data.expwop) && (Data_Invoice.data.expwop !== undefined) && (Data_Invoice.data.expwop !== null)) {
				wb_new.SheetNames.push("EXPWOP");
			}
			if ((Data_Invoice.data.sezwp) && (Data_Invoice.data.sezwp !== undefined) && (Data_Invoice.data.sezwp !== null)) {
				wb_new.SheetNames.push("SEZWP");
			}
			if ((Data_Invoice.data.sezwop) && (Data_Invoice.data.sezwop !== undefined) && (Data_Invoice.data.sezwop !== null)) {
				wb_new.SheetNames.push("SEZWOP");
			}
			if ((Data_Invoice.data.de) && (Data_Invoice.data.de !== undefined) && (Data_Invoice.data.de !== null)) {
				wb_new.SheetNames.push("DE");
			}
			if ((Data_Invoice.data.rev) && (Data_Invoice.data.rev !== undefined) && (Data_Invoice.data.rev !== null)) {
				wb_new.SheetNames.push("REV");
			}
			if ((Data_Invoice.data.imps) && (Data_Invoice.data.imps !== undefined) && (Data_Invoice.data.imps !== null)) {
				wb_new.SheetNames.push("IMPS");
			}
			if ((Data_Invoice.data.impg) && (Data_Invoice.data.impg !== undefined) && (Data_Invoice.data.impg !== null)) {
				wb_new.SheetNames.push("IMPG");
			}
			if ((Data_Invoice.data.impgsez) && (Data_Invoice.data.impgsez !== undefined) && (Data_Invoice.data.impgsez !== null)) {
				wb_new.SheetNames.push("IMPGSEZ");
			}
			if ((Data_Invoice.data.ecom) && (Data_Invoice.data.ecom !== undefined) && (Data_Invoice.data.ecom !== null)) {
				wb_new.SheetNames.push("ECOM");
			}


			var b2cDataList = [];
			if ((Data_Invoice.data.b2c !== undefined) && (Data_Invoice.data.b2c !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const ErrorMessage = InvError.errorDetails.errMsg
					const InvoiceItems = map(InvoiceItems => {
						return {
							"Place of Supply*": InvError.pos,
							"Differential % of Tax Rate*": InvError.diffprcnt ? (parseFloat(InvError.diffprcnt) * 100).toFixed(2) : "100.00",
							"Supply Covered Under Sec 7 of IGST Act*": InvError.sec7act === "Y" ? "Yes" : "No",
							"Rate*": InvoiceItems.rate,
							"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
							"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
							"Central Tax": InvoiceItems.cgst ? InvoiceItems.cgst : "",
							"State/UT Tax": InvoiceItems.sgst ? InvoiceItems.sgst : "",
							"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
							"Status": ErrorMessage,
						}
					})(InvError.items)
					return InvoiceItems;
				}),
					flattenDeep)(Data_Invoice.data.b2c)
				b2cDataList = ExcelErrorData;
			}

			var b2bDataList = [];
			if ((Data_Invoice.data.b2b !== undefined) && (Data_Invoice.data.b2b !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const Invoices = map(Invoice => {
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => {
							return {
								"GSTIN of Recipient*": InvError.ctin,
								"Trade/Legal Name": "",
								"Document Type*": (Invoice.doctyp === "I" ? "Invoice" : Invoice.doctyp === "C" ? "Credit Note" : Invoice.doctyp === "D" ? "Debit Note" : ""),
								"Document Number*": Invoice.doc.num,
								"Document Date*": Invoice.doc.dt,
								"Document Value*": Invoice.doc.val,
								"Place of Supply*": Invoice.pos,
								"Differential % of Tax Rate*": Invoice.diffprcnt ? (parseFloat(Invoice.diffprcnt) * 100).toFixed(2) : "100.00",
								"Supply Covered Under Sec 7 of IGST Act*": Invoice.sec7act === "Y" ? "Yes" : "No",
								"HSN Code": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Central Tax": InvoiceItems.cgst ? InvoiceItems.cgst : "",
								"State/UT Tax": InvoiceItems.sgst ? InvoiceItems.sgst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.b2b)
				b2bDataList = ExcelErrorData;
			}

			var expwpDataList = [];
			if ((Data_Invoice.data.expwp !== undefined) && (Data_Invoice.data.expwp !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const ErrorMessage = InvError.errorDetails.errMsg
					const InvoiceItems = map(InvoiceItems => {
						return {
							"Document Type*": (InvError.doctyp === "I" ? "Invoice" : InvError.doctyp === "C" ? "Credit Note" : InvError.doctyp === "D" ? "Debit Note" : ""),
							"Document Number*": InvError.doc.num,
							"Document Date*": InvError.doc.dt,
							"Document Value*": InvError.doc.val,
							"Export Type*": "WPAY",
							"Port Code": InvError.sb.pcode,
							"Shipping Bill Number": InvError.sb.num,
							"Shipping Bill Date": InvError.sb.dt,
							"HSN Code*": InvoiceItems.hsn,
							"Rate*": InvoiceItems.rate,
							"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
							"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
							"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
							"Status": ErrorMessage,
						}
					})(InvError.items)
					return InvoiceItems;
				}),
					flattenDeep)(Data_Invoice.data.expwp)
				expwpDataList = ExcelErrorData;
			}

			var expwopDataList = [];
			if ((Data_Invoice.data.expwop !== undefined) && (Data_Invoice.data.expwop !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const ErrorMessage = InvError.errorDetails.errMsg
					const InvoiceItems = map(InvoiceItems => {
						return {
							"Document Type*": (InvError.doctyp === "I" ? "Invoice" : InvError.doctyp === "C" ? "Credit Note" : InvError.doctyp === "D" ? "Debit Note" : ""),
							"Document Number*": InvError.doc.num,
							"Document Date*": InvError.doc.dt,
							"Document Value*": InvError.doc.val,
							"Export Type*": "WOPAY",
							"Port Code": InvError.sb.pcode,
							"Shipping Bill Number": InvError.sb.num,
							"Shipping Bill Date": InvError.sb.dt,
							"HSN Code*": InvoiceItems.hsn,
							"Rate*": InvoiceItems.rate,
							"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
							"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
							"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
							"Status": ErrorMessage,
						}
					})(InvError.items)
					return InvoiceItems;
				}),
					flattenDeep)(Data_Invoice.data.expwop)
				expwopDataList = ExcelErrorData;
			}


			var sezwpDataList = [];
			if ((Data_Invoice.data.sezwp !== undefined) && (Data_Invoice.data.sezwp !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const Invoices = map(Invoice => {
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => {
							return {
								"GSTIN of Recipient*": InvError.ctin,
								"Trade/Legal Name": "",
								"Document Type*": (Invoice.doctyp === "I" ? "Invoice" : Invoice.doctyp === "C" ? "Credit Note" : Invoice.doctyp === "D" ? "Debit Note" : ""),
								"Document Number*": Invoice.doc.num,
								"Document Date*": Invoice.doc.dt,
								"Document Value*": Invoice.doc.val,
								"Place of Supply*": Invoice.pos,
								"GST Payment Type": "",
								"Differential % of Tax Rate*": Invoice.diffprcnt ? (parseFloat(Invoice.diffprcnt) * 100).toFixed(2) : "100.00",
								"Would You Claim Refund*": Invoice.clmrfnd === "Y" ? "Yes" : "No",
								"HSN Code*": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.sezwp)
				sezwpDataList = ExcelErrorData;
			}

			var sezwopDataList = [];
			if ((Data_Invoice.data.sezwop !== undefined) && (Data_Invoice.data.sezwop !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const Invoices = map(Invoice => {
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => {
							return {
								"GSTIN of Recipient*": InvError.ctin,
								"Trade/Legal Name": "",
								"Document Type*": (Invoice.doctyp === "I" ? "Invoice" : Invoice.doctyp === "C" ? "Credit Note" : Invoice.doctyp === "D" ? "Debit Note" : ""),
								"Document Number*": Invoice.doc.num,
								"Document Date*": Invoice.doc.dt,
								"Document Value*": Invoice.doc.val,
								"Place of Supply*": Invoice.pos,
								"GST Payment Type": "",
								"Differential % of Tax Rate*": Invoice.diffprcnt ? (parseFloat(Invoice.diffprcnt) * 100).toFixed(2) : "100.00",
								"Would You Claim Refund*": Invoice.clmrfnd === "Y" ? "Yes" : "No",
								"HSN Code*": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.sezwop)
				sezwopDataList = ExcelErrorData;
			}




			var deDataList = [];
			// console.log(Data_Invoice.de)
			if ((Data_Invoice.data.de !== undefined) && (Data_Invoice.data.de !== null)) {
				const ExcelErrorData = flow(map(InvError => { // de
					const Invoices = map(Invoice => { // docs - invoices
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => { // items
							return {
								"GSTIN of Recipient*": InvError.ctin,
								"Trade/Legal Name": "",
								"Document Type*": (Invoice.doctyp === "I" ? "Invoice" : Invoice.doctyp === "C" ? "Credit Note" : Invoice.doctyp === "D" ? "Debit Note" : ""),
								"Document Number*": Invoice.doc.num,
								"Document Date*": Invoice.doc.dt,
								"Place of Supply*": Invoice.pos,
								"Differential % of Tax Rate*": Invoice.diffprcnt ? (parseFloat(Invoice.diffprcnt) * 100).toFixed(2) : "100.00",
								"Supply Covered Under Sec 7 of IGST Act*": Invoice.sec7act === "Y" ? "Yes" : "No",
								"Would You Claim Refund*": Invoice.clmrfnd === "Y" ? "Yes" : "No",
								"HSN Code*": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Central Tax": InvoiceItems.cgst ? InvoiceItems.cgst : "",
								"State/UT Tax": InvoiceItems.sgst ? InvoiceItems.sgst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.de)
				deDataList = ExcelErrorData;
			}


			var revDataList = [];
			if ((Data_Invoice.data.rev !== undefined) && (Data_Invoice.data.rev !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const Invoices = map(Invoice => {
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => {
							return {
								"GSTIN of Supplier*": InvError.ctin,
								"Trade/Legal Name": "",
								"Place of Supply*": Invoice.pos,
								"Differential % of Tax Rate*": Invoice.diffprcnt ? (parseFloat(Invoice.diffprcnt) * 100).toFixed(2) : "100.00",
								"Supply Covered Under Sec 7 of IGST Act*": Invoice.sec7act === "Y" ? "Yes" : "No",
								"Supply Type*": "",
								"HSN Code*": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Central Tax": InvoiceItems.cgst ? InvoiceItems.cgst : "",
								"State/UT Tax": InvoiceItems.sgst ? InvoiceItems.sgst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.rev)
				revDataList = ExcelErrorData
			}

			var impsDataList = [];
			if ((Data_Invoice.data.imps !== undefined) && (Data_Invoice.data.imps !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const ErrorMessage = InvError.errorDetails.errMsg
					const InvoiceItems = map(InvoiceItems => {
						return {
							"Place of Supply*": InvError.pos,
							"HSN Code*": InvoiceItems.hsn,
							"Rate*": InvoiceItems.rate,
							"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
							"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
							"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
							"Status": ErrorMessage,
						}
					})(InvError.items)
					return InvoiceItems;
				}),
					flattenDeep)(Data_Invoice.data.imps)
				impsDataList = ExcelErrorData
			}

			var impgDataList = [];
			if ((Data_Invoice.data.impg !== undefined) && (Data_Invoice.data.impg !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const Invoices = map(Invoice => {
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => {
							return {
								"Document Type*": (Invoice.doctyp === "B" ? "Bill of Entry" : Invoice.doctyp),
								"Bill of Entry Number*": Invoice.boe.num,
								"Bill of Entry Date*": Invoice.boe.dt,
								"Bill of Entry Value*": Invoice.boe.val,
								"Place of Supply*": Invoice.pos,
								"HSN Code*": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.impg)
				impgDataList = ExcelErrorData
			}



			var impgsezDataList = [];
			if ((Data_Invoice.data.impgsez !== undefined) && (Data_Invoice.data.impgsez !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const Invoices = map(Invoice => {
						const ErrorMessage = Invoice.errorDetails.errMsg
						const InvoiceItems = map(InvoiceItems => {
							return {
								"GSTIN of Supplier*": InvError.ctin,
								"Trade/Legal Name": "",
								"Document Type*": (Invoice.doctyp === "I" ? "Invoice" : Invoice.doctyp === "C" ? "Credit Note" : Invoice.doctyp === "D" ? "Debit Note" : ""),
								"Port Code*": Invoice.boe.pcode,
								"Bill of Entry Number*": Invoice.boe.dt,
								"Bill of Entry Date*": Invoice.pos,
								"Bill of Entry Value*": "",
								"Place of Supply*": Invoice.pos,
								"HSN Code*": InvoiceItems.hsn,
								"Rate*": InvoiceItems.rate,
								"Taxable Value*": InvoiceItems.txval ? InvoiceItems.txval : "",
								"Integrated Tax": InvoiceItems.igst ? InvoiceItems.igst : "",
								"Cess": InvoiceItems.cess ? InvoiceItems.cess : "",
								"Status": ErrorMessage,
							}
						})(Invoice.items)
						return InvoiceItems;
					})(InvError.docs)
					return Invoices;
				}),
					flattenDeep)(Data_Invoice.data.impgsez)
				impgsezDataList = ExcelErrorData
			}

			var ecomDataList = [];
			if ((Data_Invoice.data.ecom !== undefined) && (Data_Invoice.data.ecom !== null)) {
				const ExcelErrorData = flow(map(InvError => {
					const ErrorMessage = InvError.errorDetails.errMsg
					return {
						"GSTIN of E-com*": InvError.etin,
						"Trade/Legal Name": "",
						"Value of supplies made*": InvError.sup,
						"Value of Suppies Returned*": InvError.supr,
						"Net Value of Supplies": InvError.nsup,
						"Integrated Tax": InvError.igst ? InvError.igst : "",
						"Central Tax": InvError.cgst ? InvError.cgst : "",
						"Taxable Value*": InvError.txval ? InvError.txval : "",
						"Cess": InvError.cess ? InvError.cess : "",
						"Status": ErrorMessage,
					}
				}),
					flattenDeep)(Data_Invoice.data.ecom)
				ecomDataList = ExcelErrorData
			}


			if ((Data_Invoice.data.b2c) && (Data_Invoice.data.b2c !== undefined) && (Data_Invoice.data.b2c !== null)) {
				wb_new.Sheets["B2C"] = XLSX.utils.json_to_sheet(b2cDataList);
			}
			if ((Data_Invoice.data.b2b) && (Data_Invoice.data.b2b !== undefined) && (Data_Invoice.data.b2b !== null)) {
				wb_new.Sheets["B2B"] = XLSX.utils.json_to_sheet(b2bDataList);
			}
			if ((Data_Invoice.data.expwp) && (Data_Invoice.data.expwp !== undefined) && (Data_Invoice.data.expwp !== null)) {
				wb_new.Sheets["EXPWP"] = XLSX.utils.json_to_sheet(expwpDataList);
			}
			if ((Data_Invoice.data.expwop) && (Data_Invoice.data.expwop !== undefined) && (Data_Invoice.data.expwop !== null)) {
				wb_new.Sheets["EXPWOP"] = XLSX.utils.json_to_sheet(expwopDataList);
			}
			if ((Data_Invoice.data.sezwp) && (Data_Invoice.data.sezwp !== undefined) && (Data_Invoice.data.sezwp !== null)) {
				wb_new.Sheets["SEZWP"] = XLSX.utils.json_to_sheet(sezwpDataList);
			}
			if ((Data_Invoice.data.sezwop) && (Data_Invoice.data.sezwop !== undefined) && (Data_Invoice.data.sezwop !== null)) {
				wb_new.Sheets["SEZWOP"] = XLSX.utils.json_to_sheet(sezwopDataList);
			}
			if ((Data_Invoice.data.de) && (Data_Invoice.data.de !== undefined) && (Data_Invoice.data.de !== null)) {
				wb_new.Sheets["DE"] = XLSX.utils.json_to_sheet(deDataList);
			}
			if ((Data_Invoice.data.rev) && (Data_Invoice.data.rev !== undefined) && (Data_Invoice.data.rev !== null)) {
				wb_new.Sheets["REV"] = XLSX.utils.json_to_sheet(revDataList);
			}
			if ((Data_Invoice.data.imps) && (Data_Invoice.data.imps !== undefined) && (Data_Invoice.data.imps !== null)) {
				wb_new.Sheets["IMPS"] = XLSX.utils.json_to_sheet(impsDataList);
			}
			if ((Data_Invoice.data.impg) && (Data_Invoice.data.impg !== undefined) && (Data_Invoice.data.impg !== null)) {
				wb_new.Sheets["IMPG"] = XLSX.utils.json_to_sheet(impgDataList);
			}
			if ((Data_Invoice.data.impgsez) && (Data_Invoice.data.impgsez !== undefined) && (Data_Invoice.data.impgsez !== null)) {
				wb_new.Sheets["IMPGSEZ"] = XLSX.utils.json_to_sheet(impgsezDataList);
			}
			if ((Data_Invoice.data.ecom) && (Data_Invoice.data.ecom !== undefined) && (Data_Invoice.data.ecom !== null)) {
				wb_new.Sheets["ECOM"] = XLSX.utils.json_to_sheet(ecomDataList);
			}

			var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });
			anx1Data.downloadReport = () => {
				var cur_dt = new Date();
				const excelOutput = fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "ANX1Sync Error Report - " + cur_dt + ".xlsx");
			}

			return anx1Data;
		})
	}
}

export default Anx1Sales
