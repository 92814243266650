/**
 * @flow
 */
import React from 'react'

import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import Select from 'antd/lib/select'
import Table from 'antd/lib/table'
import Col from 'antd/lib/col'

import get from 'lodash/fp/get'
import {
  ContentWrapper as Content,
  MarginedRow as Row,
} from '../../shared/styles'
import {
  CompanyUsersQuery,
  AddUserToCompanyMutation,
  ChangeUserRoleMutation,
} from 'qgst-gql-queries/web/data.json'
import { gql, graphql, compose } from 'react-apollo'
import capitalize from 'lodash/capitalize'

import { Button as B } from '../../shared/styles'
const { Column } = Table

const UserList = (props) =>
  <Content>
    <Form onSubmit={props.addUser}>
      <Row gutter={24}>
        <Col xs={24} sm={6}>
          <Form.Item>
            {props.getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input user email!' }],
            })(<Input placeholder="Email" size="large" />)}
          </Form.Item>
        </Col>
        <Col xs={24} sm={6}>
          <Form.Item>
            <Select
              defaultValue={props.selectedRole}
              size="large"
              style={{ width: '100%' }}
              onSelect={props.onSelectRole}
            >
              <Select.Option value="admin">Admin</Select.Option>
              <Select.Option value="reviewer">Reviewer</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <B type="primary" size="large" htmlType="submit">
            Add User
          </B>
        </Col>
      </Row>
    </Form>
    <Table
      loading={props.tableLoading}
      dataSource={props.users}
      bordered
      rowKey={record => record.user.email}
    >
      <Column title="Email" dataIndex="user.email" key="user.email" />
      <Column title="Role" dataIndex="role" key="role" render={capitalize} />
      <Column
        title="Actions"
        dataIndex="actions"
        key="actions"
        render={(text, record) => {
          const toChangeRole = record.role === 'admin' ? 'reviewer' : 'admin'
          return (
            record.user.id !== props.me.id &&
            <span>
              <B
                type="primary"
                onClick={() => {
                  props.changeUserRole(record.user.email, toChangeRole)
                }}
              >
                <span>
                Make 
                </span>
                <span style={{ marginLeft: '3px' }}>
                {capitalize(toChangeRole)}
                </span>
              </B>
            </span>
          )
        }}
      />
    </Table>
  </Content>

export class UserListContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedRole: 'admin',
    }

    this.companyId = this.props.match.params.id

    this.props.company_users.refetch();
  }
  refetchQueries = [
    {
      query: gql(CompanyUsersQuery),
      variables: {
        companyId: this.props.match.params.id,
      },
    },
  ]
  onSelectRole = selectedRole => {
    this.setState({ selectedRole })
  }
  changeUserRole = (userEmail, role) => {
    const companyId = this.companyId
    this.props.changeRole({
      variables: { userEmail, companyId, role },
      refetchQueries: this.refetchQueries,
    })
  }
  addUser = e => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (err) return
      try {
        await this.props.mutate({
          variables: {
            userEmail: values.email,
            companyId: this.companyId,
            role: this.state.selectedRole,
          },
          refetchQueries: this.refetchQueries,
        })
        this.props.form.resetFields()
        message.success('User saved successfully!')
      } catch (e) {
        message.error(e.graphQLErrors[0].message)
      }
    })
  }
  render() {
    console.log(this.props);
    var tableLoading = false;
    if (this.props.company_users.networkStatus === 4) { tableLoading = false; }
    else if (this.props.company_users.networkStatus === 7) { tableLoading = false; }
    else if (this.props.company_users.networkStatus === 1) { tableLoading = true; }
    return (
      <UserList
        tableLoading={tableLoading}
        me={this.props.users_list_me}
        users={this.props.users_list}
        {...this.state}
        onSelectRole={this.onSelectRole}
        getFieldDecorator={this.props.form.getFieldDecorator}
        addUser={this.addUser}
        changeUserRole={this.changeUserRole}
      />
    )
  }
}

const queryConfig = {
  name: "company_users",
  options: props => ({
    variables: {
      companyId: props.match.params.id,
    },
  }),
  props: ({ company_users }) => {
    return {
      company_users: company_users,
      users_list_me: get("viewer.me", company_users),
      users_list: get("viewer.companyUsers.edges", company_users),
    };
  }
}

const addUserMutationOptions = {}
const changeUserRoleMutation = {
  props: ({ mutate }) => ({
    changeRole: mutate,
  }),
}
export default compose(
  graphql(gql(CompanyUsersQuery), queryConfig),
  graphql(gql(AddUserToCompanyMutation), addUserMutationOptions),
  graphql(gql(ChangeUserRoleMutation), changeUserRoleMutation),
  Form.create()
)(UserListContainer)
