import React from "react";
import {
  Row,
  Col,
  DatePicker,
  Table,
  Button,
  Modal,
  notification,
  Icon,
  message,
  Steps,
} from "antd";
import get from "lodash/fp/get";
import find from "lodash/fp/find";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import {
  CompanyByIdQuery,
  ITC_comparison_Query_2B,
  ITC_comparison_Query_Excel,
} from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import moment from "moment";
import toString from "lodash/fp/toString";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import range from "lodash/fp/range";
import pick from "lodash/fp/pick";
import { BASE_URL } from "../../shared/constants";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import excelService from "../../services/excelUtilityService";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import gspAuth from "../../services/GSPService";
import Get2BForm from "../GSP/Get2BForm";
const { Column } = Table;
const { MonthPicker } = DatePicker;
const Step = Steps.Step;
const errorModal = Modal.error;

class ITC_UI_2B extends React.Component {
  constructor(props) {
    super(props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
      };
    } else {
      this.gsp = {};
    }
    this.pullGSTR3BData = this.pullGSTR3BData.bind(this);
    this.props.itc_corner_calculations.refetch();
    this.props.itc_corner_calculations_excel.refetch();
  }

  componentDidUpdate = () => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp =
        this.gsp.otp !== undefined && this.gsp.otp !== null
          ? this.gsp.otp
          : this.props.gspDetails.otp;
    }
    let columns = [];

    if (this.props.itc_corner_2b ?? false) {
      if (this.props.itc_corner_2b.itc_comparison_2b ?? false) {
        // columns = this.props.itc_corner_2b.itc_comparison_2b.edges.columns
        // console.log("ITC CORNER 2B COLUMNS",this.props.itc_corner_2b.itc_comparison_2b.edges.columns)
        columns = this.props?.itc_corner_2b?.itc_comparison_2b?.edges?.columns; 
      }
    }
    if (this.state.columns.length !== columns.length) {
      const nc = map((d) => {
        return {
          ...d,
          render: this.getRenderFunctions(d),
        };
      })(columns);
      this.setState({ columns: nc });
      console.log("NC",nc)
    }
  };

  getRenderFunctions = (column) => {
    if (
      column.key.toUpperCase() === "TOTAL" ||
      column.key.toUpperCase() === "TITLE"
    ) {
      return (t, r) => <span>{t}</span>;
    } else {
      return (t, r) => {
        if (r.title.toUpperCase() === "ITC MISMATCH (5 MINUS 11)") {
          const dataSource =
            this.props.itc_corner_2b === undefined
              ? []
              : JSON.parse(
                  get("itc_comparison_2b.edges.data", this.props.itc_corner_2b)
                );
          let allOtherITC = find({ title: "5) All other ITC" })(dataSource);
          let totalITCAvailable = find({
            title: "11) Total - ITC available in GSTR-2B",
          })(dataSource);
          allOtherITC = parseFloat(
            parseFloat(
              allOtherITC[column.dataIndex].replace(/₹ /g, "").replace(/,/g, "")
            ).toFixed(2)
          );
          totalITCAvailable = parseFloat(
            parseFloat(
              totalITCAvailable[column.dataIndex]
                .replace(/₹ /g, "")
                .replace(/,/g, "")
            ).toFixed(2)
          );
          const oneTwentyPercent = parseFloat(
            parseFloat(totalITCAvailable + totalITCAvailable * 0.1).toFixed(2)
          );
          if (allOtherITC > oneTwentyPercent) {
            return <span style={{ color: "red" }}>{t}</span>;
          }
        }
        return t;
      };
    }
  };

  otherGSTNInformation = (companyDetails) => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleOTPSubmit = (val) => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      if (this.state.B) {
        message.destroy();
        message.loading("Fetching...");
        this.getCounterPartyData(this.state.fromPeriod, this.state.toPeriod);
      } else {
        this.pullGSTR3BData();
      }
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  save2BFormRef = (formRef) => {
    this.form2BRef = formRef;
  };

  state = {
    visible: false,
    downloadLoading: false,
    requestOTP: false,
    otp: null,
    period: null,
    pull2B: false,
    call2B: false,
    fromPeriod: null,
    toPeriod: null,
    run: false,
    columns: [],
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  showModal = () => {
    console.log("1");
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  pullGSTR3BData = async () => {
    message.destroy();
    message.loading("Pulling from GSTN...", 0);
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
    };
    this.gsp.response = await gspAuth.getGSTR3BData(reqData);
    message.destroy();

    if (
      !this.gsp.response.data.success &&
      this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
    ) {
      this.setState({ requestOTP: true, call2B: false });
    } else if (!this.gsp.response.data.success) {
      errorModal({
        title: "Fetch Unsuccessful",
        content: (
          <p>"Unable to process your request, please try again later."</p>
        ),
        iconType: "close-circle",
      });
    }
    if (this.gsp.response.data.success) {
      this.props.itc_corner_calculations.refetch();
      this.props.itc_corner_calculations_excel.refetch();
      notification.destroy();
      notification.open({
        message: "Success",
        description: "Pulled GSTR3B Data Successfully!",
        duration: 0,
        icon: <Icon type="check-circle" style={{ color: "#008000" }} />,
      });
    }
  };

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      requestOTP: false,
    });
  };

  formatNumber(value) {
    var x = parseFloat(value ? value : "0").toFixed(2);
    x = x.toString();
    var afterPoint = "";
    if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
    x = Math.floor(x);
    x = x.toString();
    var lastThree = x.substring(x.length - 3);
    var otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    var res =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      lastThree +
      afterPoint;

    return `₹ ${res}`;
  }


  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      run: true,
    });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return (
      (current && current < moment("30-06-2017", "DD-MM-YYYY").endOf("day")) ||
      (current && current > moment().endOf("day"))
    );
  };

  onTwoB = () => {
    this.setState({ pull2B: true });
  };

  handleGet2BCancel = () => {
    this.setState({ pull2B: false });
  };

  // handleGet2ASubmit = () => {
  //   message.destroy();
  //   message.loading("Fetching 2A data from GSTN...", 0);
  //   const form = this.form2ARef.props.form;
  //   form.validateFields(async (err, fieldsValue) => {
  //     if (err) {
  //       return;
  //     }
  //     form.resetFields();
  //     const fromDate = fieldsValue["from_month"].format("MMYYYY");
  //     const toDate = fieldsValue["to_month"].format("MMYYYY");
  //     this.setState({ pull2B: false, call2B: true });
  //     const cpData = await this.getCounterPartyData(fromDate, toDate);
  //   });
  // };

  periodArrayValues = (a, b) => {
    var dateStart = moment(a, "MMYYYY");
    var dateEnd = moment(b, "MMYYYY");
    // console.log("dateStart :" + dateStart + "---" + "dateEnd :" + dateEnd);
    var period = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("M") === dateEnd.format("M")
    ) {
      period.push(dateStart.format("MMYYYY"));
      dateStart.add(1, "month");
    }
    return period;
  };

  getCounterPartyData = async (fromPeriod, toPeriod) => {
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const periodArrayValuesfor2A = this.periodArrayValues(fromPeriod, toPeriod);

    // console.log(periodArrayValuesfor2A);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
    };

    // console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    // console.log(this.gsp.response);

    // message.destroy();

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({
          requestOTP: true,
          fromPeriod: fromPeriod,
          toPeriod: toPeriod,
          fromPullData: true,
          fromReconcile: null,
        });
      } else if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.indexOf("Under Maintenance") >= 0
      ) {
        message.destroy();
        message.error("Government API is under maintenance");
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      try {
        message.destroy();
        message.loading("Fetching B2B Invoices..", 0);
        const gstr2A_B2B_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.response.gspAuth.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_b2b = await gspAuth.getCounterPartyData(
              reqData,
              "B2B"
            );
            if (
              this.gsp.response_b2b.status &&
              get("data.data.errorCode", this.gsp.response_b2b) ===
                "RETINPROGRESS"
            ) {
              const reqId = get("data.data.reqId", this.gsp.response_b2b);
              message.destroy();
              notification.info({
                key: reqId,
                message: "Information",
                duration: 0,
                description:
                  "GSTN is preparing the requested supplier data, we will auto fetch the details again in " +
                  (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                    1) +
                  " minutes",
              });
              setTimeout(
                this.callbackForRetInProgress.bind(this, reqId, reqData, "B2B"),
                (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                  1) *
                  60 *
                  1000
              );
            }
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        console.log(e)
        message.destroy();
        notification.destroy();
        notification.open({
          message: "B2B Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
      try {
        message.destroy();
        message.loading("Fetching Credit / Debit Notes..", 0);
        const gstr2A_CDNR_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };

            this.gsp.response_cdnr = await gspAuth.getCounterPartyData(
              reqData,
              "CDN"
            );
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "Credit / Debit Note Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      try {
        message.destroy();
        // console.log(this.gsp.response)
        message.loading("Fetching Amended B2B Invoices..", 0);
        const gstr2A_B2BA_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.response.gspAuth.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_b2ba = await gspAuth.getCounterPartyData(
              reqData,
              "B2BA"
            );
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "B2B Amendments Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
      try {
        message.destroy();
        message.loading("Fetching Amended Credit / Debit Notes..", 0);
        const gstr2A_CDNA_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };

            this.gsp.response_cdna = await gspAuth.getCounterPartyData(
              reqData,
              "CDNA"
            );

            if (this.gsp.response_cdna.status === true) {
              message.destroy();

              this.props.itc_corner_calculations.refetch();
              this.props.itc_corner_calculations_excel.refetch();

              notification.open({
                message: "Success",
                description: "GSTR2A fetched successfully!!",
                duration: 0,
                icon: <Icon type="check-circle" style={{ color: "#008000" }} />,
              });
            }
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "Credit / Debit Note Amendments Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }
    }
  };

//EBS-118 DEV 
  handleGet2BSubmit = () => {
    message.destroy();
    message.loading(
      "Fetching B2B Invoices and Credit / Debit Notes from GSTN...",
      0
    );
    const form = this.form2BRef.props.form;
    // console.log(this.form2BRef.props.form)
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      form.resetFields();
      console.log(fieldsValue);
      const fromDate = fieldsValue["from_month"].format("MMYYYY");
      const toDate = fieldsValue["to_month"].format("MMYYYY");
      this.setState({ pull2B: false });
      console.log("fromDate", fromDate);
      console.log("toDate", toDate);
      const cpData = await this.getCounterPartyData2B(fromDate, toDate);  //API Calls
    });
  };

  handleGet2BCancel = () => {
    this.setState({ pull2B: false });
  };

  getCounterPartyData2B = async (fromPeriod, toPeriod) => {
    // alert("fromPeriod");
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const periodArrayValuesfor2A = this.periodArrayValues(fromPeriod, toPeriod);

    // console.log(periodArrayValuesfor2A);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
    };

    // console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({
          requestOTP: true,
          fromPeriod: fromPeriod,
          toGetData: "2B",
          toPeriod: toPeriod,
          fromPullData: true,
          fromReconcile: null,
        });
      } else if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.indexOf("Under Maintenance") >= 0
      ) {
        message.destroy();
        message.error("Government API is under maintenance");
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      try {
        message.destroy();
        message.loading("Fetching B2B 2B Invoices..", 0);
        const gstr2A_B2B_Loop_Action = await Promise.all(
          map(async (d) => {
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.response.gspAuth.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };
            this.gsp.response_b2b = await gspAuth.getCounterParty2BData(reqData, "GET2B");
            if (this.gsp.response_b2b.status && get("data.data.errorCode", this.gsp.response_b2b) === "RETINPROGRESS") {
              const reqId = get("data.data.reqId", this.gsp.response_b2b);
              message.destroy();
              notification.info({
                key: reqId,
                message: "Information",
                duration: 0,
                description:
                  "GSTN is preparing the requested supplier data, we will auto fetch the details again in " +
                  (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                    1) +
                  " minutes",
              });
              setTimeout(
                this.callbackForRetInProgress.bind(this, reqId, reqData, "B2B"),
                (get("data.data.retryTimeInMinutes", this.gsp.response_b2b) +
                  1) *
                60 *
                1000
              );
            }
            message.destroy();
          })(periodArrayValuesfor2A)
        );
      } catch (e) {
        message.destroy();
        notification.destroy();
        notification.open({
          message: "B2B Fetch Unsuccessful",
          description:
            "Unable to process your request, please try again later.",
          duration: 0,
          icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
        });
      }

    }
  };
  //EBS118 DEV

  render() {
    var itcLoad = false;

    if (this.props.itc_corner_calculations.networkStatus === 4) {
      itcLoad = false;
    } else if (this.props.itc_corner_calculations.networkStatus === 7) {
      itcLoad = false;
    } else if (this.props.itc_corner_calculations.networkStatus === 1) {
      itcLoad = true;
    }

    const dataSource =
      this.props.itc_corner_2b === undefined
        ? []
        : JSON.parse(get("itc_comparison_2b.edges.data", this.props.itc_corner_2b));
        console.log("2B Datasource",this.props.itc_corner_2b)
    const dataSource_excel =
      this.props.itc_corner_excel === undefined
        ? []
        : JSON.parse(
            get("itc_comparison_excel.edges.data", this.props.itc_corner_excel)
          );
          
    // console.log("dataSource",this.props);
    // console.log(dataSource_excel);
    const scrollX =
      this.props.itc_corner_2b === undefined
        ? 0
        : parseInt(get("itc_comparison_2b.edges.scrollx", this.props.itc_corner_2b));

    return (
      <div style={{ backgroundColor: "#fff", padding: "5px" }}>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon="question"
            onClick={this.handleClickStart}
          />
        </div>
        <Steps current={[1, 2, 3]} style={{ marginBottom: "10px" }}>
          <Step
            title={
              <Button
                className="get3b"
                style={{
                  backgroundColor: "#376ba7",
                  fontSize: "13px",
                  color: "white",
                }}
                icon="cloud-download"
                disabled={
                  this.props.company && this.props.company.role === "reviewer"
                }
                onClick={this.pullGSTR3BData}
              >
                Pull GSTR-3B
              </Button>
            }
            description="Clicking this button will pull all your GSTR-3B data from GSTN!"
          />
          <Step
            title={
              <div>
                <Button
                  className="get2a"
                  style={{
                    backgroundColor: "#385c8ce0",
                    fontSize: "13px",
                    color: "white",
                  }}
                  onClick={this.onTwoB}
                  icon="cloud-download"
                  disabled={
                    this.props.company && this.props.company.role === "reviewer"
                  }
                >
                  Pull GSTR-2B 
                </Button>
                <Get2BForm
                  wrappedComponentRef={this.save2BFormRef}
                  visible={this.state.pull2B}
                  onSubmit={this.handleGet2BSubmit}
                  onCancel={this.handleGet2BCancel}
                /> 
              </div>
            }
            description="Clicking this button will pull all your GSTR-2B data from GSTN."
          />
          <Step
            title="View ITC Report"
            description="Now you can see the data comparison below."
          />
        </Steps>
        {/*<Button icon="sync" style={{ backgroundColor: '#2382c7', width: '250px', color: 'white' }} onClick={this.pullGSTR3BData} disabled={this.props.company && this.props.company.role === 'reviewer'}>
          Pull GSTR3B Data
        </Button>*/}

        <Table
          columns={this.state.columns}
          bordered
          className="view_itc"
          loading={itcLoad}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: scrollX }}
          footer={() => (
            <div style={{ textAlign: "center" }}>
              <Button
                icon="file-excel"
                style={{ backgroundColor: "#2382c7", color: "white" }}
                onClick={() => excelService.ITC_2B_Summary  (dataSource)}
              >
                Download ITC 2B Summary (Excel Format)
              </Button>
            </div>
          )}
          title={() =>
            "ITC Report (GSTR-3B Monthly Report vs GSTR-2B Monthly Report)"
          }
        />
        <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
        />
      </div>
    );
  }
}

const config = {
  name: "itc_corner_calculations_2b",
  options: (props) => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear, 
    },
  }),
  props: ({ itc_corner_calculations_2b }) => {
    console.log("GQL: DATA", itc_corner_calculations_2b)
    return {
      itc_corner_calculations: itc_corner_calculations_2b,
      itc_corner_2b : get("viewer", itc_corner_calculations_2b),
    };
  },
};

const configexcel = {
  name: "itc_corner_calculations_excel",
  options: (props) => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ itc_corner_calculations_excel }) => {
    return {
      itc_corner_calculations_excel: itc_corner_calculations_excel,
      itc_corner_excel: get("viewer", itc_corner_calculations_excel),
    };
  },
};

const companyQueryConfig = {
  name: "company",
  options: (props) => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get("viewer.company", company),
      gspDetails: get("viewer.gspInfo", company),
    };
  },
};

const mapStateToProps = (state) => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp,
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps, {
    setGspToken: gspActions.setGspToken,
    setGspOtp: gspActions.setGspOtp,
    setGspDetails: gspActions.setGspDetails,
  }),
  graphql(gql(ITC_comparison_Query_2B), config),
  graphql(gql(ITC_comparison_Query_Excel), configexcel),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(ITC_UI_2B);
