import React from "react";
import { Row, Col, Card, Steps, Collapse, Button, Icon, message, Popconfirm, notification, Modal,Layout } from "antd";
import get from "lodash/fp/get";
import reduce from "lodash/fp/reduce";
import flow from "lodash/fp/flow";
import flatMap from "lodash/fp/flatMap";
import toString from "lodash/fp/toString";
import pick from "lodash/fp/pick";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GSTR1_Query, CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import { connect } from "react-redux";
import { ContentWrapper } from "../../shared/styles";
import { BASE_URL, formatNumber } from "../../shared/constants";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import gspAuth from "../../services/GSPService";
import { ROUTES } from '../../shared/constants'
import { Link } from 'react-router-dom'
const add_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', whiteSpace: 'nowrap', lineHeight: '1.5', padding: '0 15px', fontSize: '13.5px', borderRadius: '5px', height: '34px', mozUserSelect: 'none', position: 'relative', color: '#000000a6', backgroundColor: 'rgb(35, 130, 199)', borderColor: '#d9d9d9', paddingTop: '5px'};
const Step = Steps.Step;
const Panel = Collapse.Panel;
const errorModal = Modal.error;

class GSTR1Dashboard extends React.Component {
  constructor(props) {
    super(props);
    // console.log(props);
    // console.log(this.props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null
      };
    } else {
      this.gsp = {};
    }
    this.props.gstr1summary.refetch();
  }

  componentDidUpdate = () => {
    // console.log(this.props);
    // console.log(this.prevProps);
    // console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    this.setState({ requestOTP: false });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.uploadGSTR1Data();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visible: false,
    downloadLoading: false,
    popupConfirmSubmit: false,
    popupConfirmSave: false,
    requestOTP: false,
    otp: null,
    run: false,
    period: null,
    submitVisible: true,
  };

  popupConfirmSubmitVisibleChange = e => {
    if(this.state.submitVisible){
      this.setState({popupConfirmSubmit:false});
      return;
    }else{
    if(!e){
      this.setState({popupConfirmSubmit:false});
      return;
    }
    if(this.props.company){
      if(this.props.company.role === "admin"){
        this.setState({popupConfirmSubmit:true})
      }else{
        this.setState({popupConfirmSubmit:false})
      }
    }
  }
  }

  popupConfirmSaveVisibleChange = e => {
    if(!e){
      this.setState({popupConfirmSave:false});
      return;
    }
    if(this.props.company){
      if(this.props.company.role === "admin"){
        this.setState({popupConfirmSave:true})
      }else{
        this.setState({popupConfirmSave:false})
      }
    }
  }

  hide = () => {
    this.setState({
      visible: false
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  uploadGSTR1Data = async () => {
    message.destroy();
    message.loading('Uploading to GSTN...',0)

    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }
    var downloaddate = this.props.finMonth + "/" + this.props.finYear;
    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    return fetch(
      `${BASE_URL}/export/downloadjson?from=sales&companyId=${
        this.props.companyId
      }&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    ).then(async result => {
      const body = await result.json();
      const companyDetails = flow(
        pick(["gstin", "id", "gstnUserName"]),
        this.otherGSTNInformation
      )(this.props.company);
      const reqData = {
        companyDetails: companyDetails,
        gspDetails: {
          gspToken: this.gsp.access_token,
          otp: this.gsp.otp
        },
        period: period,
        body: body
      };
      // console.log(JSON.stringify(reqData));
      this.gsp.response = await gspAuth.saveGSTR1(reqData, 'GSTR1_SAVE');
      message.destroy();
      if (
        !this.gsp.response.data.success &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        this.setState({ requestOTP: true });
      }else if(!this.gsp.response.data.success){
        errorModal({
          title: 'Save Unsuccessful',
          content: <p>{this.gsp.response.data.errorCode ? this.gsp.response.data.errorCode : "Unable to process your request, please try again later."}</p>,
          iconType: "close-circle",
        });
      }
      if(this.gsp.response.data.success) {
        if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "P")
        {
          this.setState({ submitVisible: false });
          notification.destroy();
          notification.open({
            message: 'Success',
            description: "GSTR1 Saved Successfully!!",
            duration: 0,
            icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
          });
        }else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "REC"){
          notification.destroy();
          notification.open({
            message: 'GSTN',
            description: "GSTR1 Save-Status REC.",
            duration: 0,
            icon: <Icon type="close-circle" style={{ color: '#e6122e' }} />,
          });
        }
        else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "PE"){
          errorModal({
            title: 'GSTR1 data partially saved!',
            iconType: 'printer',
            content: <p>Please check the error report!</p>,
            okText: 'Download Error Report',
            onOk() {
              document.getElementById("gstr1_error_confirm_download").click();
              return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
              }).catch(() => console.log('GSTR1 errors!'));
            }
          });
        }else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "ER") {
          errorModal({
            title: 'GSTR1 Saved Failed!!',
            content: <p>Enter Correct GSTR1 details and try again.</p>,
            iconType: "close-circle",
          });
        }else if(this.gsp.response.retstatus && this.gsp.response.retstatus.status_cd === "IP") {
          notification.destroy();
          notification.open({
            message: 'In Progress...',
            description: "GSTR1 save in progress...",
            duration: 500,
            icon: <Icon type="exclamation-circle" style={{ color: '#008000' }} />,
          });
        }
      }
    });
  };

  submitGSTR1Data = async() => {
    message.destroy();
    message.loading('Submitting to GSTN...',0)
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;
    // console.log(period);

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      period: period,
    };
    // console.log(reqData);
    this.gsp.response = await gspAuth.submitGSTR1(reqData);
    message.destroy();
    if (
      !this.gsp.response.data.success &&
      this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
    ) {
      this.setState({ requestOTP: true });
    }else if(!this.gsp.response.data.success){
      errorModal({
        title: 'Submit Unsuccessful',
        content: <p>{this.gsp.response.data.errorCode ? this.gsp.response.data.errorCode : "Unable to process your request, please try again later."}</p>,
        iconType: "close-circle",
      });
    }
    if(this.gsp.response.data.success) {
        notification.destroy();
        notification.open({
          message: 'Success',
          description: "GSTR1 Sumbitted Successfully!!",
          duration: 0,
          icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
        });
      }

  }

  handleClickStart = e => {
    
    e.preventDefault();

    this.setState({
      run: true
    });
  };

  fileGSTR1 = () => {

    var downloaddate = this.props.finMonth + "/" + this.props.finYear;

    fetch(
      `${BASE_URL}/export/proceedToFile?&companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(result => {
        // console.log("=======DATA=====");
        console.log(result);
      });

  }

  render() {
    // console.log(this.props);
    return (
      <Layout style={{backgroundColor:'#fff', padding: '5px'}}>
      <div style={{ textAlign: 'center' }}>
        <Button  size='small' onClick={this.handleClickStart} type="primary" shape="circle" icon="question" />
      </div>
        <br />
        <Steps current={[1, 2, 3, 4]}>
          <Step
            title="Preview GSTR-1"
            description="Summarized data of GSTR-1."
          />
          <Step title={
            <Popconfirm className='tour_saveGstr1' placement="bottomRight" title="Clicking this button will SAVE your GSTR1 info in GSTN!" onVisibleChange={this.popupConfirmSaveVisibleChange} visible={this.state.popupConfirmSave} onConfirm={this.uploadGSTR1Data} okText="Yes" cancelText="No">
              <Button style={{ backgroundColor: '#04880fe0', fontSize: '13px', color: 'white'}} icon="cloud-upload" disabled={this.props.company && this.props.company.role === 'reviewer'}>Save GSTR1</Button>
            </Popconfirm>
          } description="Clicking this button will save your GSTR1 info to GST network." />
          <Step title={
            <Popconfirm className='tour_submitGstr1' placement="bottomRight" title="Clicking this button will submit freeze your GSTR1 info in GSTN!" onVisibleChange={this.popupConfirmSubmitVisibleChange} visible={this.state.popupConfirmSubmit} onConfirm={this.submitGSTR1Data} okText="Yes" cancelText="No">
              <Button style={{ backgroundColor: '#a01208de', fontSize: '13px', color: 'white'}} disabled={true} icon="check-circle">Submit GSTR1</Button>
            </Popconfirm>
          } description="Clicking this button will submit your GSTR1 info to GST network." />
        </Steps>
        <br />
        <br />
        <Collapse bordered={false} defaultActiveKey={["1"]} className='tour_previewGstr1'>
          <Panel header={<b>Invoices and Credit/Debit Notes</b>} key="1">
            <Row gutter={16}>
              <Col>
                <Card bordered={true}>
                  <Row>
                    <Col span={8}>
                      <b>Type</b>
                    </Col>
                    <Col span={4}>
                      <b>Document Count</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Value</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Taxable Value</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Tax Liability</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <b>A) Regular</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>B2B (4A, 4B, 4C, 6B, 6C)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_b2b
                          ? this.props.gstr1_b2b.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.invoice_value, 10),
                          0
                        )(
                          this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2b_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2b_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2b ? this.props.gstr1_b2b.edges : []
                        ) +
                          flow(
                            flatMap(get("sr_b2b_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2b
                              ? this.props.gstr1_b2b.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2b_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2b
                              ? this.props.gstr1_b2b.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2b_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2b
                              ? this.props.gstr1_b2b.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>B2C Large (5A, 5B)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_b2cl
                          ? this.props.gstr1_b2cl.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.invoice_value, 10),
                          0
                        )(
                          this.props.gstr1_b2cl
                            ? this.props.gstr1_b2cl.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2cl_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2cl
                            ? this.props.gstr1_b2cl.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2cl_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2cl
                            ? this.props.gstr1_b2cl.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_b2cl_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cl
                              ? this.props.gstr1_b2cl.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2cl_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cl
                              ? this.props.gstr1_b2cl.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2cl_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cl
                              ? this.props.gstr1_b2cl.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>Export Invoices</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_export
                          ? this.props.gstr1_export.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.invoice_value, 10),
                          0
                        )(
                          this.props.gstr1_export
                            ? this.props.gstr1_export.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_export_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_export
                            ? this.props.gstr1_export.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_export_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_export
                            ? this.props.gstr1_export.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_export_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_export
                              ? this.props.gstr1_export.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_export_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_export
                              ? this.props.gstr1_export.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_export_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_export
                              ? this.props.gstr1_export.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col span={8}>Credit/Debit Notes (Registered) (9B)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_cdnr
                          ? this.props.gstr1_cdnr.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.cdnr_value, 10),
                          0
                        )(
                          this.props.gstr1_cdnr_d
                            ? this.props.gstr1_cdnr_d.edges
                            : []
                        ) -
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.cdnr_value, 10),
                            0
                          )(
                            this.props.gstr1_cdnr_c
                              ? this.props.gstr1_cdnr_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnr_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnr_d
                            ? this.props.gstr1_cdnr_d.edges
                            : []
                        ) -
                          flow(
                            flatMap(get("sr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr + parseFloat(next.taxable_value, 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnr_c
                              ? this.props.gstr1_cdnr_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnr_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnr_d
                            ? this.props.gstr1_cdnr_d.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnr_d
                              ? this.props.gstr1_cdnr_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnr_d
                              ? this.props.gstr1_cdnr_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnr_d
                              ? this.props.gstr1_cdnr_d.edges
                              : []
                          ) -
                          (flow(
                            flatMap(get("sr_cdnr_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.igst ? next.igst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnr_c
                              ? this.props.gstr1_cdnr_c.edges
                              : []
                          ) +
                            flow(
                              flatMap(get("sr_cdnr_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cgst ? next.cgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnr_c
                                ? this.props.gstr1_cdnr_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnr_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.sgst ? next.sgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnr_c
                                ? this.props.gstr1_cdnr_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnr_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cess ? next.cess : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnr_c
                                ? this.props.gstr1_cdnr_c.edges
                                : []
                            ))
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>Credit Debit Note (unregistered) (9B)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_cdnur
                          ? this.props.gstr1_cdnur.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.cdnur_value, 10),
                          0
                        )(
                          this.props.gstr1_cdnur_d
                            ? this.props.gstr1_cdnur_d.edges
                            : []
                        ) -
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.cdnur_value, 10),
                            0
                          )(
                            this.props.gstr1_cdnur_c
                              ? this.props.gstr1_cdnur_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnur_d
                            ? this.props.gstr1_cdnur_d.edges
                            : []
                        ) -
                          flow(
                            flatMap(get("sr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr + parseFloat(next.taxable_value, 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnur_c
                              ? this.props.gstr1_cdnur_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnur_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnur_d
                            ? this.props.gstr1_cdnur_d.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnur_d
                              ? this.props.gstr1_cdnur_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnur_d
                              ? this.props.gstr1_cdnur_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnur_d
                              ? this.props.gstr1_cdnur_d.edges
                              : []
                          ) -
                          (flow(
                            flatMap(get("sr_cdnur_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.igst ? next.igst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnur_c
                              ? this.props.gstr1_cdnur_c.edges
                              : []
                          ) +
                            flow(
                              flatMap(get("sr_cdnur_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cgst ? next.cgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnur_c
                                ? this.props.gstr1_cdnur_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnur_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.sgst ? next.sgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnur_c
                                ? this.props.gstr1_cdnur_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnur_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cess ? next.cess : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnur_c
                                ? this.props.gstr1_cdnur_c.edges
                                : []
                            ))
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <b>B) Amended</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>B2B Amendments (9A)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_b2ba
                          ? this.props.gstr1_b2ba.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.invoice_value, 10),
                          0
                        )(
                          this.props.gstr1_b2ba
                            ? this.props.gstr1_b2ba.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2ba_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2ba
                            ? this.props.gstr1_b2ba.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2ba_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2ba
                            ? this.props.gstr1_b2ba.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_b2ba_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2ba
                              ? this.props.gstr1_b2ba.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2ba_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2ba
                              ? this.props.gstr1_b2ba.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2ba_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2ba
                              ? this.props.gstr1_b2ba.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>B2C Large Amendments(9A)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_b2cla
                          ? this.props.gstr1_b2cla.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.invoice_value, 10),
                          0
                        )(
                          this.props.gstr1_b2cla
                            ? this.props.gstr1_b2cla.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2cla_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2cla
                            ? this.props.gstr1_b2cla.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2cla_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2cla
                            ? this.props.gstr1_b2cla.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_b2cla_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cla
                              ? this.props.gstr1_b2cla.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2cla_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cla
                              ? this.props.gstr1_b2cla.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2cla_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cla
                              ? this.props.gstr1_b2cla.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>Export Invoices Amendments (9A)</Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_exporta
                          ? this.props.gstr1_exporta.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.invoice_value, 10),
                          0
                        )(
                          this.props.gstr1_exporta
                            ? this.props.gstr1_exporta.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_exporta_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_exporta
                            ? this.props.gstr1_exporta.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_exporta_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_exporta
                            ? this.props.gstr1_exporta.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_exporta_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_exporta
                              ? this.props.gstr1_exporta.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_exporta_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_exporta
                              ? this.props.gstr1_exporta.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_exporta_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_exporta
                              ? this.props.gstr1_exporta.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>
                      Credit/Debit Notes (Registered) Amendments (9C)
                    </Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_cdnra
                          ? this.props.gstr1_cdnra.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.cdnr_value, 10),
                          0
                        )(
                          this.props.gstr1_cdnra_d
                            ? this.props.gstr1_cdnra_d.edges
                            : []
                        ) -
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.cdnr_value, 10),
                            0
                          )(
                            this.props.gstr1_cdnra_c
                              ? this.props.gstr1_cdnra_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnra_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnra_d
                            ? this.props.gstr1_cdnra_d.edges
                            : []
                        ) -
                          flow(
                            flatMap(get("sr_cdnra_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr + parseFloat(next.taxable_value, 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnra_c
                              ? this.props.gstr1_cdnra_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnra_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnra_d
                            ? this.props.gstr1_cdnra_d.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_cdnra_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnra_d
                              ? this.props.gstr1_cdnra_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnra_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnra_d
                              ? this.props.gstr1_cdnra_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnra_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnra_d
                              ? this.props.gstr1_cdnra_d.edges
                              : []
                          ) -
                          (flow(
                            flatMap(get("sr_cdnra_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.igst ? next.igst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnra_c
                              ? this.props.gstr1_cdnra_c.edges
                              : []
                          ) +
                            flow(
                              flatMap(get("sr_cdnra_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cgst ? next.cgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnra_c
                                ? this.props.gstr1_cdnra_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnra_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.sgst ? next.sgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnra_c
                                ? this.props.gstr1_cdnra_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnra_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cess ? next.cess : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnra_c
                                ? this.props.gstr1_cdnra_c.edges
                                : []
                            ))
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={8}>
                      Credit Debit Note (Unregistered) Amendments (9C)
                    </Col>
                    <Col span={4}>
                      <p>
                        {this.props.gstr1_cdnura
                          ? this.props.gstr1_cdnura.pageInfo.total
                          : "-"}
                      </p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.cdnur_value, 10),
                          0
                        )(
                          this.props.gstr1_cdnura_d
                            ? this.props.gstr1_cdnura_d.edges
                            : []
                        ) -
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.cdnur_value, 10),
                            0
                          )(
                            this.props.gstr1_cdnura_c
                              ? this.props.gstr1_cdnura_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnura_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnura_d
                            ? this.props.gstr1_cdnura_d.edges
                            : []
                        ) -
                          flow(
                            flatMap(get("sr_cdnura_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr + parseFloat(next.taxable_value, 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnura_c
                              ? this.props.gstr1_cdnura_c.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_cdnura_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_cdnura_d
                            ? this.props.gstr1_cdnura_d.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_cdnura_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnura_d
                              ? this.props.gstr1_cdnura_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnura_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnura_d
                              ? this.props.gstr1_cdnura_d.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_cdnura_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnura_d
                              ? this.props.gstr1_cdnura_d.edges
                              : []
                          ) -
                          (flow(
                            flatMap(get("sr_cdnura_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.igst ? next.igst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_cdnura_c
                              ? this.props.gstr1_cdnura_c.edges
                              : []
                          ) +
                            flow(
                              flatMap(get("sr_cdnura_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cgst ? next.cgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnura_c
                                ? this.props.gstr1_cdnura_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnura_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.sgst ? next.sgst : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnura_c
                                ? this.props.gstr1_cdnura_c.edges
                                : []
                            ) +
                            flow(
                              flatMap(get("sr_cdnura_items.edges")),
                              reduce(
                                (curr, next) =>
                                  curr +
                                  parseFloat(next.cess ? next.cess : "0", 10),
                                0
                              )
                            )(
                              this.props.gstr1_cdnura_c
                                ? this.props.gstr1_cdnura_c.edges
                                : []
                            ))
                      )}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Panel>

          <br />

          <Panel header={<b>Summary Level Details</b>} key="2">
            <Row gutter={16}>
              <Col>
                <Card bordered={true}>
                  <Row>
                    <Col span={10}>
                      <b>Type</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Value</b>
                    </Col>
                    <Col span={4}>
                      <b>Total Taxable Value</b>
                    </Col>
                    <Col span={5}>
                      <b>Total Tax Liability</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <b>A) Regular</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>B2C Others (7)</Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2cs_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2cs
                            ? this.props.gstr1_b2cs.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2cs_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2cs
                            ? this.props.gstr1_b2cs.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_b2cs_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cs
                              ? this.props.gstr1_b2cs.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2cs_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cs
                              ? this.props.gstr1_b2cs.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2cs_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2cs
                              ? this.props.gstr1_b2cs.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>
                      Advances Received (Tax Liability) (11A(1), 11A(2))
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_at_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(
                                next.gross_received ? next.gross_received : "0",
                                10
                              ),
                            0
                          )
                        )(this.props.gstr1_ar ? this.props.gstr1_ar.edges : [])
                      )}
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_at_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_ar ? this.props.gstr1_ar.edges : []
                        ) +
                          flow(
                            flatMap(get("sr_at_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_ar ? this.props.gstr1_ar.edges : []
                          ) +
                          flow(
                            flatMap(get("sr_at_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_ar ? this.props.gstr1_ar.edges : []
                          ) +
                          flow(
                            flatMap(get("sr_at_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_ar ? this.props.gstr1_ar.edges : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>Adjustment of Advances (11B(1), 11B(2))</Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_atadj_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(
                                next.gross_adjusted ? next.gross_adjusted : "0",
                                10
                              ),
                            0
                          )
                        )(this.props.gstr1_aa ? this.props.gstr1_aa.edges : [])
                      )}
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_atadj_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_aa ? this.props.gstr1_aa.edges : []
                        ) +
                          flow(
                            flatMap(get("sr_atadj_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_aa ? this.props.gstr1_aa.edges : []
                          ) +
                          flow(
                            flatMap(get("sr_atadj_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_aa ? this.props.gstr1_aa.edges : []
                          ) +
                          flow(
                            flatMap(get("sr_atadj_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_aa ? this.props.gstr1_aa.edges : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col span={10}>HSN summary of outward supplies (13)</Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) =>
                            curr + parseFloat(next.total_value, 10),
                          0
                        )(
                          this.props.gstr1_hsn ? this.props.gstr1_hsn.edges : []
                        )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_hsn_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_hsn ? this.props.gstr1_hsn.edges : []
                        )
                      )}
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_hsn_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_hsn ? this.props.gstr1_hsn.edges : []
                        ) +
                          flow(
                            flatMap(get("sr_hsn_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_hsn
                              ? this.props.gstr1_hsn.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_hsn_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_hsn
                              ? this.props.gstr1_hsn.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_hsn_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_hsn
                              ? this.props.gstr1_hsn.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row style={{ marginTop: '12px' }}>
                    <Col span={10}>Nil Rated Supplies (8A, 8B, 8C, 8D)</Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) => curr + parseFloat((next.nil_amt ? next.nil_amt : 0), 10),
                          0
                        )(
                          this.props.gstr1_nil ? this.props.gstr1_nil.edges : []
                        ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.expt_amt ? next.expt_amt : 0), 10),
                            0
                          )(
                            this.props.gstr1_nil
                              ? this.props.gstr1_nil.edges
                              : []
                          ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.ngsup_amt ? next.ngsup_amt : 0), 10),
                            0
                          )(
                            this.props.gstr1_nil
                              ? this.props.gstr1_nil.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        reduce(
                          (curr, next) => curr + parseFloat((next.nil_amt ? next.nil_amt : 0), 10),
                          0
                        )(
                          this.props.gstr1_nil ? this.props.gstr1_nil.edges : []
                        ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.expt_amt ? next.expt_amt : 0), 10),
                            0
                          )(
                            this.props.gstr1_nil
                              ? this.props.gstr1_nil.edges
                              : []
                          ) +
                          reduce(
                            (curr, next) =>
                              curr + parseFloat((next.ngsup_amt ? next.ngsup_amt : 0), 10),
                            0
                          )(
                            this.props.gstr1_nil
                              ? this.props.gstr1_nil.edges
                              : []
                          )
                      )}
                    </Col>
                    <Col span={5}>
                      <p>N/A</p>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <b>B) Amended</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>B2C Others Amendments (10)</Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={4}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2csa_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr + parseFloat(next.taxable_value, 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2csa
                            ? this.props.gstr1_b2csa.edges
                            : []
                        )
                      )}
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_b2csa_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_b2csa
                            ? this.props.gstr1_b2csa.edges
                            : []
                        ) +
                          flow(
                            flatMap(get("sr_b2csa_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2csa
                              ? this.props.gstr1_b2csa.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2csa_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2csa
                              ? this.props.gstr1_b2csa.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_b2csa_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_b2csa
                              ? this.props.gstr1_b2csa.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>
                      Advances Received (Tax Liability) Amendments (11(2))
                    </Col>
                    <Col span={4}>
                      {formatNumber(flow(
                        flatMap(get('sr_ata_items.edges')),
                        reduce((curr, next) => {
                          curr = parseFloat(curr) + parseFloat(next.gross_received ? next.gross_received : "0", 2);
                          var temp = parseFloat(curr);
                          temp = parseFloat(temp).toFixed(2);
                          return (temp)
                        }, 0))(this.props.gstr1_ara ? this.props.gstr1_ara.edges : []))}
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_ata_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_ara ? this.props.gstr1_ara.edges : []
                        ) +
                          flow(
                            flatMap(get("sr_ata_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_ara
                              ? this.props.gstr1_ara.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_ata_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_ara
                              ? this.props.gstr1_ara.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_ata_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_ara
                              ? this.props.gstr1_ara.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>
                      Adjustment of Advances Amendments (11(2))
                    </Col>
                    <Col span={4}>
                      {formatNumber(flow(
                        flatMap(get('sr_atadja_items.edges')),
                        reduce((curr, next) => {
                          curr = parseFloat(curr) + parseFloat(next.gross_adjusted ? next.gross_adjusted : "0", 2);
                          var temp = parseFloat(curr);
                          temp = parseFloat(temp).toFixed(2);
                          return (temp)
                        }, 0))(this.props.gstr1_aaa ? this.props.gstr1_aaa.edges : []))}
                    </Col>
                    <Col span={4}>
                      <p>N/A</p>
                    </Col>
                    <Col span={5}>
                      {formatNumber(
                        flow(
                          flatMap(get("sr_atadja_items.edges")),
                          reduce(
                            (curr, next) =>
                              curr +
                              parseFloat(next.igst ? next.igst : "0", 10),
                            0
                          )
                        )(
                          this.props.gstr1_aaa ? this.props.gstr1_aaa.edges : []
                        ) +
                          flow(
                            flatMap(get("sr_atadja_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cgst ? next.cgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_aaa
                              ? this.props.gstr1_aaa.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_atadja_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.sgst ? next.sgst : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_aaa
                              ? this.props.gstr1_aaa.edges
                              : []
                          ) +
                          flow(
                            flatMap(get("sr_atadja_items.edges")),
                            reduce(
                              (curr, next) =>
                                curr +
                                parseFloat(next.cess ? next.cess : "0", 10),
                              0
                            )
                          )(
                            this.props.gstr1_aaa
                              ? this.props.gstr1_aaa.edges
                              : []
                          )
                      )}
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col>
                      <b>C) Documents Issued</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>
                      <b>Type</b>
                    </Col>
                    <Col span={5}>
                      <b>Total Docs</b>
                    </Col>
                    <Col span={4}>
                      <b>Cancelled Docs</b>
                    </Col>
                    <Col span={4}>
                      <b>Net Issued Docs</b>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={10}>Documents Series Summary</Col>
                    <Col span={5}>
                      {reduce(
                        (curr, next) => curr + parseFloat(next.total_count, 10),
                        0
                      )(this.props.gstr1_doc ? this.props.gstr1_doc.edges : [])}
                    </Col>
                    <Col span={4}>
                      {reduce(
                        (curr, next) =>
                          curr + parseFloat(next.cancel_count, 10),
                        0
                      )(this.props.gstr1_doc ? this.props.gstr1_doc.edges : [])}
                    </Col>
                    <Col span={4}>
                      {reduce(
                        (curr, next) => curr + parseFloat(next.net_count, 10),
                        0
                      )(this.props.gstr1_doc ? this.props.gstr1_doc.edges : [])}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Row>

        <Col span="1">
          <Button size="large" style={{ display: "none" }} id="gstr1_error_confirm_download" >Download</Button>
        </Col>


        </Row>

      
        <OTPForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.requestOTP}
          onSubmit={this.handleOTPSubmit}
          onCancel={this.handleCancel}
        />

      </Layout>
    );
  }
}

const config = {
  name: "gstr1summary",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear
    }
  }),
  props: ({gstr1summary, ownProps}) => {
    // console.log(a);
    // var data=a;
    return {
      gstr1summary: gstr1summary,
      gstr1_b2b: get("viewer.sr_b2b_masters", gstr1summary),
      gstr1_b2cl: get("viewer.sr_b2cl_masters", gstr1summary),
      gstr1_cdnr: get("viewer.sr_cdnr_masters", gstr1summary),
      gstr1_cdnur: get("viewer.sr_cdnur_masters", gstr1summary),
      gstr1_cdnr_c: get("viewer.sr_cdnr_masters_c", gstr1summary),
      gstr1_cdnur_c: get("viewer.sr_cdnur_masters_c", gstr1summary),
      gstr1_cdnr_d: get("viewer.sr_cdnr_masters_d", gstr1summary),
      gstr1_cdnur_d: get("viewer.sr_cdnur_masters_d", gstr1summary),
      gstr1_export: get("viewer.sr_export_masters", gstr1summary),

      gstr1_b2cs: get("viewer.sr_b2cs_masters", gstr1summary),
      gstr1_nil: get("viewer.sr_nil_masters", gstr1summary),
      gstr1_ar: get("viewer.sr_at_masters", gstr1summary),
      gstr1_aa: get("viewer.sr_atadj_masters", gstr1summary),
      gstr1_hsn: get("viewer.sr_hsn_masters", gstr1summary),
      gstr1_doc: get("viewer.sr_doc_masters", gstr1summary),

      gstr1_b2ba: get("viewer.sr_b2ba_masters", gstr1summary),
      gstr1_b2cla: get("viewer.sr_b2cla_masters", gstr1summary),
      gstr1_b2csa: get("viewer.sr_b2csa_masters", gstr1summary),
      gstr1_cdnra: get("viewer.sr_cdnra_masters", gstr1summary),
      gstr1_cdnura: get("viewer.sr_cdnura_masters", gstr1summary),
      gstr1_cdnra_c: get("viewer.sr_cdnra_masters_c", gstr1summary),
      gstr1_cdnura_c: get("viewer.sr_cdnura_masters_c", gstr1summary),
      gstr1_cdnra_d: get("viewer.sr_cdnra_masters_d", gstr1summary),
      gstr1_cdnura_d: get("viewer.sr_cdnura_masters_d", gstr1summary),
      gstr1_exporta: get("viewer.sr_exporta_masters", gstr1summary),
      gstr1_ara: get("viewer.sr_ata_masters", gstr1summary),
      gstr1_aaa: get("viewer.sr_atadja_masters", gstr1summary)
    };
  }
};

const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(GSTR1_Query), config),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(GSTR1Dashboard);



// <Step
//             title={<a style={add_button} className='tour_uploadGstr1'>
//               <Icon style={{ fontSize: '13.5px', color: '#FFFFFF' }} type="rollback" /> &nbsp; <Link to={ROUTES.SALES_DASHBOARD}>Go to Upload Sales</Link>
//             </a>}
//             description="Sales Invoices are uploaded in Upload Sales under Sales Register."
//           />