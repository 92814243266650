import React from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import { Link } from 'react-router-dom'

import { Button, ContentWrapper as Content } from '../../shared/styles'
import { ROUTES } from '../../shared/constants'
import { FormItem } from '../../shared/utils'

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 6,
    },
  },
}
const SupplierAdd = ({ form }) =>
  <Content>
    <Form>
      <FormItem form={form} label="Supplier Name" name="name">
        <Input placeholder="Supplier Name" />
      </FormItem>
      <FormItem form={form} label="GSTIN" name="gstin">
        <Input placeholder="Supplier GSTIN" />
      </FormItem>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Add
        </Button>
        <Button>
          <Link to={ROUTES.SUPPLIER}>Cancel</Link>
        </Button>
      </Form.Item>
    </Form>
  </Content>
export default Form.create()(SupplierAdd)
