import React from "react";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Icon from "antd/lib/icon";

const { Column } = Table;
const scroll = { x: 1600 };

class ReconcileCDNRGSTOnlyDetails extends React.Component {
  render() {
    const props = this.props;
    const maxHeight = Math.floor((window.innerHeight - 250) / 83);
    return (
      <Modal
        title="Likely Matched Invoices (CDNR)"
        visible={props.visibleLikelyModal_cdnr}
        onCancel={props.hideLikelyModal_cdnr}
        footer={null}
        width="96%"
      >
        <Table
          dataSource={props.dataset_popup_details}
          bordered
          rowSelection={props.rowSelection_likely}
          rowKey="id"
          title={() => (
            <span style={{ marginLeft: 8 }}>
              {props.hasSelected
                ? `Selected ${props.selectedRowKeys.length} Invoices`
                : ""}
            </span>
          )}
          loading={props.loading}
          footer={() => (
            <div>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#2382c7",
                  color: "white"
                }}
                onClick={() => props.fetchMissingNames(this.props.m_id)}
              >
                Fetch missing Supplier Name(s)
              </Button>
              <Button
                icon="download"
                style={{
                  backgroundColor: "#921c26",
                  color: "white"
                }}
                onClick={() => props.markAsIneligibleLikely()}
              >
                Mark as Ineligible and Reconcile
              </Button>
            </div>
          )}
          pagination={{
            className: "my-pagination",
            defaultPageSize: 2 * Math.round(maxHeight / 2)
          }}
        >
          <Column
            title="Type"
            dataIndex="type"
            key="type"
            className="alignCenter"
            render={text =>
              text === "reconprcdnr" ? "GSTN" : "Purchase Register"
            }
          />
          <Column
            title="GSTIN"
            dataIndex="supplier_gstin"
            className="alignCenter"
            key="supplier_gstin"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters
            }) => (
              <div className="custom-filter-dropdown">
                <Input
                  placeholder="Search GSTIN Number"
                  value={selectedKeys[0]}
                  onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                  }
                  onPressEnter={props.handleSearch(selectedKeys, confirm)}
                />
                <Button
                  type="primary"
                  onClick={props.handleSearch(selectedKeys, confirm)}
                >
                  Search
                </Button>
                <Button
                  id="gstnSearch_likely_cdnr"
                  onClick={props.handleReset(clearFilters)}
                >
                  Reset
                </Button>
              </div>
            )}
            filterIcon={filtered => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa"
                }}
              />
            )}
            onFilter={(value, record) =>
              record.supplier_gstin.toLowerCase().includes(value.toLowerCase())
            }
            render={text => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />
          <Column
            title="Supplier Name"
            dataIndex="supplier_name"
            className="alignCenter"
            key="supplier_name"
          />
          <Column
            title="Invoice Number"
            dataIndex="invoice_number"
            className="alignCenter"
            key="invoice_number"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters
            }) => (
              <span>
                <div className="custom-filter-dropdown">
                  <Input
                    placeholder="Search Invoice Number"
                    value={selectedKeys[0]}
                    onChange={e =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={props.handleSearch(selectedKeys, confirm)}
                  />
                  <Button
                    type="primary"
                    onClick={props.handleSearch(selectedKeys, confirm)}
                  >
                    Search
                  </Button>
                  <Button
                    id="likelyInvoiceSearch_cdnr"
                    onClick={props.handleReset(clearFilters)}
                  >
                    Reset
                  </Button>
                </div>
              </span>
            )}
            filterIcon={filtered => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa"
                }}
              />
            )}
            onFilter={(value, record) =>
              record.invoice_number.toLowerCase().includes(value.toLowerCase())
            }
            render={text => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />
          <Column
            title="Invoice Date"
            dataIndex="invoice_date"
            className="alignCenter"
            key="invoice_date"
            width="120"
            // defaultSortOrder='descend'
            // sorter={(a, b) => props.sortDate(a.invoice_date, b.invoice_date)}
            // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
          />
          <Column
            title="CDNR Number"
            dataIndex="cdnr_number"
            className="alignCenter"
            key="cdnr_number"
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters
            }) => (
              <span>
                <div className="custom-filter-dropdown">
                  <Input
                    placeholder="Search CDNR Number"
                    value={selectedKeys[0]}
                    onChange={e =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={props.handleSearch(selectedKeys, confirm)}
                  />
                  <Button
                    type="primary"
                    onClick={props.handleSearch(selectedKeys, confirm)}
                  >
                    Search
                  </Button>
                  <Button
                    id="likelyCDNRSearch_cdnr"
                    onClick={props.handleReset(clearFilters)}
                  >
                    Reset
                  </Button>
                </div>
              </span>
            )}
            filterIcon={filtered => (
              <Icon
                type="search"
                style={{
                  color: filtered ? "#108ee9" : "#aaa"
                }}
              />
            )}
            onFilter={(value, record) =>
              record.cdnr_number.toLowerCase().includes(value.toLowerCase())
            }
            render={text => {
              return props.searchText ? (
                <span>
                  {text
                    .split(
                      new RegExp(
                        `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                        "i"
                      )
                    )
                    .map(
                      (fragment, i) =>
                        fragment.toLowerCase() ===
                        props.searchText.toLowerCase() ? (
                          <span key={i} className="highlight">
                            {fragment}
                          </span>
                        ) : (
                          fragment
                        ) // eslint-disable-line
                    )}
                </span>
              ) : (
                text
              );
            }}
          />
          <Column
            title="CDNR Date"
            dataIndex="cdnr_date"
            className="alignCenter"
            key="cdnr_date"
            // defaultSortOrder='descend'
            // sorter={(a, b) => props.sortDate(a.cdnr_date, b.cdnr_date)}
            // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
          />
          <Column
            title="CDNR Value"
            dataIndex="cdnr_value"
            className="alignCenter"
            key="cdnr_value"
            render={val => <p>{props.formatNumber(val === null ? 0 : val)}</p>}
          />
          <Column
            title="CDNR Type"
            dataIndex="cdnr_type"
            className="alignCenter"
            key="cdnr_type"
            render={val => (
              <p>{val === "C" ? "Credit" : val === "D" ? "Debit" : ""}</p>
            )}
          />

          <Column
            title="Pre-GST"
            dataIndex="pre_gst"
            className="alignCenter"
            key="pre_gst"
          />

          <Column
            title="Items"
            key="action"
            className="alignCenter"
            rowSpan="2"
            render={(text, record, index) => {
              var but = (
                <Row gutter={24}>
                  <Col span="8">
                    <Button
                      icon="bars"
                      type="primary"
                      onClick={() => props.showItemModal(record)}
                    >
                      Items
                    </Button>
                    <Modal
                      title="List Items Summary - Likely Matched Invoices (CDNR)"
                      visible={
                        props.visibleItemModal === record.id ? true : false
                      }
                      onCancel={props.hideItemModal}
                      footer={null}
                      width="920"
                    >
                      <Table
                        dataSource={record.items}
                        bordered
                        loading={props.loading}
                        scroll={scroll}
                        pagination={false}
                      >
                        <Column
                          title="TYPE"
                          dataIndex="item_type"
                          className="alignCenter"
                          key="item_type"
                          render={text =>
                            text.toUpperCase() === "RECONPRCDNR"
                              ? "GSTN"
                              : "Purchase Register"
                          }
                        />
                        <Column
                          title="Rate"
                          dataIndex="gst_rate"
                          className="alignCenter"
                          key="gst_rate"
                        />
                        <Column
                          title="Taxable Value"
                          className="alignCenter"
                          dataIndex="taxable_value"
                          key="taxable_value"
                          render={val => (
                            <p>{props.formatNumber(val === null ? 0 : val)}</p>
                          )}
                        />
                        <Column
                          title="IGST"
                          dataIndex="igst"
                          className="alignCenter"
                          key="igst"
                          render={val => (
                            <p>
                              {val === " - - - "
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="CGST"
                          dataIndex="cgst"
                          className="alignCenter"
                          key="cgst"
                          render={val => (
                            <p>
                              {val === " - - - "
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="SGST"
                          dataIndex="sgst"
                          className="alignCenter"
                          key="sgst"
                          render={val => (
                            <p>
                              {val === " - - - "
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                        <Column
                          title="Cess"
                          dataIndex="cess"
                          className="alignCenter"
                          key="cess"
                          render={val => (
                            <p>
                              {val === " - - - "
                                ? " - - - "
                                : props.formatNumber(val)}
                            </p>
                          )}
                        />
                      </Table>
                    </Modal>
                  </Col>
                </Row>
              );
              const obj = {
                children: but,
                props: { rowSpan: 2 }
              };
              var temp = index % 2;
              if (temp === 0) {
                return but, obj;
              }
            }}
          />
          <Column
            title="Match Percentile"
            key="mp"
            className="alignCenter"
            rowSpan="2"
            render={(text, record, index) => {
              var but_1 = (
                <Row gutter={24}>
                  <Col span="8">
                    <Tag color="green">{record.threshold}</Tag>
                  </Col>{" "}
                </Row>
              );
              var but_2 = (
                <Row gutter={24}>
                  <Col span="8">
                    <Tag color="orange">{record.threshold}</Tag>
                  </Col>{" "}
                </Row>
              );
              var but_3 = (
                <Row gutter={24}>
                  <Col span="8">
                    <Tag color="purple">{record.threshold}</Tag>
                  </Col>{" "}
                </Row>
              );

              const obj_1 = {
                children: but_1,
                props: { rowSpan: 2 }
              };
              const obj_2 = {
                children: but_2,
                props: { rowSpan: 2 }
              };
              const obj_3 = {
                children: but_3,
                props: { rowSpan: 2 }
              };

              var temp = index % 2;
              if (temp === 0) {
                //  if(!record.threshold){
                //  return but_1, obj_1
                // }
                if (record.threshold === "95%" || record.threshold === "90%") {
                  return but_1, obj_1;
                }
                if (record.threshold === "85%" || record.threshold === "80%") {
                  return but_2, obj_2;
                }
                if (record.threshold === "75%") {
                  return but_3, obj_3;
                }
              }
            }}
          />
        </Table>
      </Modal>
    );
  }
}
export default ReconcileCDNRGSTOnlyDetails;
