import React from "react";
import RET1_3 from './RET1_3';
import RET1_4 from './RET1_4';
import RET1_6 from './RET1_6';
import Tabs from "antd/lib/tabs";
import { StickyContainer } from 'react-sticky';
import { Root, ContentWrapper as Content } from "../../shared/styles";
const { TabPane } = Tabs;

class Return1Parent extends React.Component {
    render() {
        return (<Root>
            <Content><StickyContainer>
                <Tabs defaultActiveKey="1" size="large" >
                    <TabPane tab="Table 3" key="1">
                        <RET1_3 />
                    </TabPane>
                    <TabPane tab="Table 4" key="2">
                        <RET1_4 />
                    </TabPane>
                    <TabPane tab="Table 6" key="3">
                        <RET1_6 />
                    </TabPane>
                </Tabs>
            </StickyContainer></Content></Root>
        )
    }
}
export default Return1Parent;