import fetch from 'isomorphic-fetch'
import { BASE_URL } from '../shared/constants'

const XLSX = require('xlsx')
var fileSaver = require('file-saver')
var blob = require('blob-util')
const {
	flow,
	map,
	flattenDeep,
	findIndex,
	findLast,
	filter
} = require("lodash/fp");
const Itc04Service = {
	upload: ({ file, type, companyId, quarterNumber, finYear }) => {
		const yearNumber = finYear.split("-");
		const it04Period = quarterNumber + yearNumber[0];
		const data = new FormData()
		data.append(type, file)
		data.append('companyId', companyId)
		data.append('period', it04Period)
		
		return fetch(`${BASE_URL}/gsp/saveitc04`, {
			method: 'POST',
			body: data,
		}).then(res => res.json()).then(function (parsedData) {
			console.log('----Parsed_Data----');
			console.log(parsedData);

			function s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
				return buf;
			}

			var wb_new = XLSX.utils.book_new();
			if ((parsedData.srb2cs) && (parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
				wb_new.SheetNames.push("B2C");
			}
			if ((parsedData.srb2b) && (parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
				wb_new.SheetNames.push("B2B");
			}
			if ((parsedData.srexport) && (parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
				wb_new.SheetNames.push("EXP");
			}
			if ((parsedData.srsez) && (parsedData.srsez !== undefined) && (parsedData.srsez !== null)) {
				wb_new.SheetNames.push("SEZ");
			}
			if ((parsedData.srde) && (parsedData.srde !== undefined) && (parsedData.srde !== null)) {
				wb_new.SheetNames.push("DE");
			}
			if ((parsedData.srrev) && (parsedData.srrev !== undefined) && (parsedData.srrev !== null)) {
				wb_new.SheetNames.push("REV");
			}
			if ((parsedData.srimps) && (parsedData.srimps !== undefined) && (parsedData.srimps !== null)) {
				wb_new.SheetNames.push("IMPS");
			}
			if ((parsedData.srimpg) && (parsedData.srimpg !== undefined) && (parsedData.srimpg !== null)) {
				wb_new.SheetNames.push("IMPG");
			}
			if ((parsedData.srimpgsez) && (parsedData.srimpgsez !== undefined) && (parsedData.srimpgsez !== null)) {
				wb_new.SheetNames.push("IMPGSEZ");
			}
			if ((parsedData.srecom) && (parsedData.srecom !== undefined) && (parsedData.srecom !== null)) {
				wb_new.SheetNames.push("ECOM");
			}
			var b2csDataList = [];
			var b2csData = {};
			if ((parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
				for (var i = 0; i < parsedData.srb2cs.length; i++) {
					if (parsedData.srb2cs[i].Status === "" || parsedData.srb2cs[i].Status === undefined) {
						break;
					}
					b2csData =
					{
						'Place of Supply*': parsedData.srb2cs[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srb2cs[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srb2cs[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'Rate*': parsedData.srb2cs[i]["Rate*"],
						'Taxable Value*': parsedData.srb2cs[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srb2cs[i]["Integrated Tax"],
						'Central Tax': parsedData.srb2cs[i]["Central Tax"],
						'State/UT Tax': parsedData.srb2cs[i]["State/UT Tax"],
						'Cess Amount': parsedData.srb2cs[i]["Cess"],
						'Status': parsedData.srb2cs[i]["Status"]
					};
					b2csDataList.push(b2csData);
				}
				if (b2csDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "B2C")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			var b2bDataList = [];
			var b2bData = {};
			if ((parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
				for (var i = 0; i < parsedData.srb2b.length; i++) {
					if (parsedData.srb2b[i].Status === "" || parsedData.srb2b[i].Status === undefined) {
						break;
					}
					b2bData =
					{
						'GSTIN/UIN of Recipient*': parsedData.srb2b[i]["GSTIN/UIN of Recipient*"],
						'Trade/Legal Name': parsedData.srb2b[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srb2b[i]["Document Type*"],
						'Document Number*': parsedData.srb2b[i]["Document Number*"],
						'Document Date*': parsedData.srb2b[i]["Document Date*"],
						'Document Value*': parsedData.srb2b[i]["Document Value*"],
						'Place of Supply*': parsedData.srb2b[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srb2b[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srb2b[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'HSN Code': parsedData.srb2b[i]["HSN Code"],
						'Rate*': parsedData.srb2b[i]["Rate*"],
						'Taxable Value*': parsedData.srb2b[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srb2b[i]["Integrated Tax"],
						'Central Tax': parsedData.srb2b[i]["Central Tax"],
						'State/UT Tax': parsedData.srb2b[i]["State/UT Tax"],
						'Cess': parsedData.srb2b[i]["Cess"],
						'Status': parsedData.srb2b[i]["Status"]
					};
					b2bDataList.push(b2bData);
				}
				if (b2bDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "B2B")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}
			var expDataList = [];
			var expData = {};
			if ((parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
				for (var i = 0; i < parsedData.srexport.length; i++) {
					if (parsedData.srexport[i].Status === "" || parsedData.srexport[i].Status === undefined) {
						break;
					}
					expData =
					{
						'Document Type*': parsedData.srexport[i]["Document Type*"],
						'Document Number*': parsedData.srexport[i]["Document Number*"],
						'Document Date*': parsedData.srexport[i]["Document Date*"],
						'Document Value*': parsedData.srexport[i]["Document Value*"],
						'Export Type*': parsedData.srexport[i]["Export Type*"],
						'Port Code': parsedData.srexport[i]["Port Code"],
						'Shipping Bill Number': parsedData.srexport[i]["Shipping Bill Number"],
						'Shipping Bill Date': parsedData.srexport[i]["Shipping Bill Date"],
						'HSN Code*': parsedData.srexport[i]["HSN Code*"],
						'Rate*': parsedData.srexport[i]["Rate*"],
						'Taxable Value*': parsedData.srexport[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srexport[i]["Integrated Tax"],
						'Cess': parsedData.srexport[i]["Cess"],
						'Status': parsedData.srexport[i]["Status"]
					};
					expDataList.push(expData);
				}
				if (expDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "EXP")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}
			var sezDataList = [];
			var sezData = {};
			if ((parsedData.srsez !== undefined) && (parsedData.srsez !== null)) {
				for (var i = 0; i < parsedData.srsez.length; i++) {
					if (parsedData.srsez[i].Status === "" || parsedData.srsez[i].Status === undefined) {
						break;
					}
					sezData =
					{
						'GSTIN of Recipient*': parsedData.srsez[i]["GSTIN of Recipient*"],
						'Trade/Legal Name': parsedData.srsez[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srsez[i]["Document Type*"],
						'Document Number*': parsedData.srsez[i]["Document Number*"],
						'Document Date*': parsedData.srsez[i]["Document Date*"],
						'Document Value*': parsedData.srsez[i]["Document Value*"],
						'Place of Supply*': parsedData.srsez[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srsez[i]["Differential % of Tax Rate*"],
						'GST Payment Type*': parsedData.srsez[i]["GST Payment Type*"],
						'Differential % of Tax Rate*': parsedData.srsez[i]["Differential % of Tax Rate*"],
						'Would You Claim Refund*': parsedData.srsez[i]["Would You Claim Refund*"],
						'HSN Code*': parsedData.srsez[i]["HSN Code*"],
						'Rate*': parsedData.srsez[i]["Rate*"],
						'Taxable Value*': parsedData.srsez[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srsez[i]["Integrated Tax"],
						'Cess': parsedData.srsez[i]["Cess"],
						'Status': parsedData.srsez[i]["Status"]
					};
					sezDataList.push(sezData);
				}
				if (sezDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "SEZ")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}

			}
			var deDataList = [];
			var deData = {};
			if ((parsedData.srde !== undefined) && (parsedData.srde !== null)) {
				for (var i = 0; i < parsedData.srde.length; i++) {
					if (parsedData.srde[i].Status === "" || parsedData.srde[i].Status === undefined) {
						break;
					}
					deData =
					{
						'GSTIN of Recipient*': parsedData.srde[i]["GSTIN of Recipient*"],
						'Trade/Legal Name': parsedData.srde[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srde[i]["Document Type*"],
						'Document Number*': parsedData.srde[i]["Document Number*"],
						'Document Date*': parsedData.srde[i]["Document Date*"],
						'Document Value*': parsedData.srde[i]["Document Value*"],
						'Place of Supply*': parsedData.srde[i]["Place of Supply*"],
						'Differential % of Tax Rate*': parsedData.srde[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srde[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'Differential % of Tax Rate*': parsedData.srde[i]["Differential % of Tax Rate*"],
						'Would You Claim Refund*': parsedData.srde[i]["Would You Claim Refund*"],
						'HSN Code*': parsedData.srde[i]["HSN Code*"],
						'Rate*': parsedData.srde[i]["Rate*"],
						'Taxable Value*': parsedData.srde[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srde[i]["Integrated Tax"],
						'Central Tax': parsedData.srde[i]["Central Tax"],
						'State/UT Tax': parsedData.srde[i]["State/UT Tax"],
						'Cess': parsedData.srde[i]["Cess"],
						'Status': parsedData.srde[i]["Status"]
					};
					deDataList.push(deData);
				}
				if (deDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "DE")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}



			var revDataList = [];
			var revData = {};
			if ((parsedData.srrev !== undefined) && (parsedData.srrev !== null)) {
				for (var i = 0; i < parsedData.srrev.length; i++) {
					if (parsedData.srrev[i].Status === "" || parsedData.srrev[i].Status === undefined) {
						break;
					}
					revData =
					{
						'GSTIN/PAN of Supplier*': parsedData.srrev[i]["GSTIN/PAN of Supplier*"],
						'Trade/Legal Name': parsedData.srrev[i]["Trade/Legal Name"],
						'Place Of Supply*': parsedData.srrev[i]["Place Of Supply*"],
						'Differential % of Tax Rate*': parsedData.srrev[i]["Differential % of Tax Rate*"],
						'Supply Covered Under Sec 7 of IGST Act*': parsedData.srrev[i]["Supply Covered Under Sec 7 of IGST Act*"],
						'Supply Type': parsedData.srrev[i]["Supply Type"],
						'HSN Code': parsedData.srrev[i]["HSN Code"],
						'Rate*': parsedData.srrev[i]["Rate*"],
						'Taxable Value*': parsedData.srrev[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srrev[i]["Integrated Tax"],
						'Central Tax*': parsedData.srrev[i]["Central Tax*"],
						'State/UT Tax*': parsedData.srrev[i]["State/UT Tax*"],
						'Cess': parsedData.srrev[i]["Cess"],
						'Status': parsedData.srrev[i]["Status"]
					};
					revDataList.push(revData);
				}
				if (revDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "REV")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}


			var impsDataList = [];
			var impsData = {};
			if ((parsedData.srimps !== undefined) && (parsedData.srimps !== null)) {
				for (var i = 0; i < parsedData.srimps.length; i++) {
					if (parsedData.srimps[i].Status === "" || parsedData.srimps[i].Status === undefined) {
						break;
					}
					impsData =
					{
						'Place of Supply*': parsedData.srimps[i]["Place of Supply*"],
						'HSN Code*': parsedData.srimps[i]["HSN Code*"],
						'Rate*': parsedData.srimps[i]["Rate*"],
						'Taxable Value*': parsedData.srimps[i]["Taxable Value*"],
						'Integrated Tax': parsedData.srimps[i]["Integrated Tax"],
						'Cess': parsedData.srimps[i]["Cess"],
						'Status': parsedData.srimps[i]["Status"]
					};
					impsDataList.push(impsData);
				}
				if (impsDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "IMPS")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			var impgDataList = [];
			var impgData = {};
			if ((parsedData.srimpg !== undefined) && (parsedData.srimpg !== null)) {
				for (var i = 0; i < parsedData.srimpg.length; i++) {
					if (parsedData.srimpg[i].Status === "" || parsedData.srimpg[i].Status === undefined) {
						break;
					}
					impgData =
					{
						'Document Type*': parsedData.srimpg[i]["Document Type*"],
						'Port Code*': parsedData.srimpg[i]["Port Code*"],
						'Bill of Entry Number*': parsedData.srimpg[i]["Bill of Entry Number*"],
						'Bill of Entry Date*': parsedData.srimpg[i]["Bill of Entry Date*"],
						'Bill of Entry Value*': parsedData.srimpg[i]["Bill of Entry Value*"],
						'Place of Supply*': parsedData.srimpg[i]["Place of Supply*"],
						'HSN Code*': parsedData.srimpg[i]["HSN Code*"],
						'Rate*': parsedData.srimpg[i]["Rate*"],
						'Taxable Value*': parsedData.srimpg[i]["Taxable Value*"],
						'Integrated Tax Paid': parsedData.srimpg[i]["Integrated Tax Paid"],
						'Cess Paid': parsedData.srimpg[i]["Cess Paid"],
						'Status': parsedData.srimpg[i]["Status"]
					};
					impgDataList.push(impgData);
				}
				if (impgDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "IMPG")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}
			var impgsezDataList = [];
			var impgsezData = {};
			if ((parsedData.srimpgsez !== undefined) && (parsedData.srimpgsez !== null)) {
				for (var i = 0; i < parsedData.srimpgsez.length; i++) {
					if (parsedData.srimpgsez[i].Status === "" || parsedData.srimpgsez[i].Status === undefined) {
						break;
					}
					impgsezData =
					{
						'GSTIN of Supplier*': parsedData.srimpgsez[i]["GSTIN of Supplier*"],
						'Trade/Legal Name': parsedData.srimpgsez[i]["Trade/Legal Name"],
						'Document Type*': parsedData.srimpgsez[i]["Document Type*"],
						'Port Code*': parsedData.srimpgsez[i]["Port Code*"],
						'Bill of Entry Number*': parsedData.srimpgsez[i]["Bill of Entry Number*"],
						'Bill of Entry Date*': parsedData.srimpgsez[i]["Bill of Entry Date*"],
						'Bill of Entry Value*': parsedData.srimpgsez[i]["Bill of Entry Value*"],
						'Place of Supply*': parsedData.srimpgsez[i]["Place of Supply*"],
						'HSN Code*': parsedData.srimpgsez[i]["HSN Code*"],
						'Rate*': parsedData.srimpgsez[i]["Rate*"],
						'Taxable Value*': parsedData.srimpgsez[i]["Taxable Value*"],
						'Integrated Tax Paid': parsedData.srimpgsez[i]["Integrated Tax Paid"],
						'Cess Paid': parsedData.srimpgsez[i]["Cess Paid"],
						'Status': parsedData.srimpgsez[i]["Status"]
					};
					impgsezDataList.push(impgsezData);
				}
				if (impgsezDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "IMPGSEZ")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			var ecomDataList = [];
			var ecomData = {};
			if ((parsedData.srecom !== undefined) && (parsedData.srecom !== null)) {
				for (var i = 0; i < parsedData.srecom.length; i++) {
					if (parsedData.srecom[i].Status === "" || parsedData.srecom[i].Status === undefined) {
						break;
					}
					ecomData =
					{
						'GSTIN of E-com*': parsedData.srecom[i]["GSTIN of E-com*"],
						'Trade/Legal Name': parsedData.srecom[i]["Trade/Legal Name"],
						'Value of supplies made*': parsedData.srecom[i]["Value of supplies made*"],
						'Value of Suppies Returned*': parsedData.srecom[i]["Value of Suppies Returned*"],
						'Net Value of Supplies': parsedData.srecom[i]["Net Value of Supplies"],
						'Integrated Tax': parsedData.srecom[i]["Integrated Tax"],
						'Central Tax': parsedData.srecom[i]["Central Tax"],
						'State/UT Tax': parsedData.srecom[i]["State/UT Tax"],
						'Cess': parsedData.srecom[i]["Cess"],
						'Status': parsedData.srecom[i]["Status"]
					};
					ecomDataList.push(ecomData);
				}
				if (ecomDataList.length === 0) {
					const sheetIndex = findIndex((sheetName) => sheetName === "ECOM")(wb_new.SheetNames);
					if (sheetIndex >= 0) wb_new.SheetNames.splice(sheetIndex, 1);
				}
			}

			if ((parsedData.srb2cs) && (parsedData.srb2cs !== undefined) && (parsedData.srb2cs !== null)) {
				wb_new.Sheets["B2C"] = XLSX.utils.json_to_sheet(b2csDataList);
			}
			if ((parsedData.srb2b) && (parsedData.srb2b !== undefined) && (parsedData.srb2b !== null)) {
				wb_new.Sheets["B2B"] = XLSX.utils.json_to_sheet(b2bDataList);
			}
			if ((parsedData.srexport) && (parsedData.srexport !== undefined) && (parsedData.srexport !== null)) {
				wb_new.Sheets["EXP"] = XLSX.utils.json_to_sheet(expDataList);
			}
			if ((parsedData.srsez) && (parsedData.srsez !== undefined) && (parsedData.srsez !== null)) {
				wb_new.Sheets["SEZ"] = XLSX.utils.json_to_sheet(sezDataList);
			}
			if ((parsedData.srde) && (parsedData.srde !== undefined) && (parsedData.srde !== null)) {
				wb_new.Sheets["DE"] = XLSX.utils.json_to_sheet(deDataList);
			}
			if ((parsedData.srrev) && (parsedData.srrev !== undefined) && (parsedData.srrev !== null)) {
				wb_new.Sheets["REV"] = XLSX.utils.json_to_sheet(revDataList);
			}
			if ((parsedData.srimps) && (parsedData.srimps !== undefined) && (parsedData.srimps !== null)) {
				wb_new.Sheets["IMPS"] = XLSX.utils.json_to_sheet(impsDataList);
			}
			if ((parsedData.srimpg) && (parsedData.srimpg !== undefined) && (parsedData.srimpg !== null)) {
				wb_new.Sheets["IMPG"] = XLSX.utils.json_to_sheet(impgDataList);
			}
			if ((parsedData.srimpgsez) && (parsedData.srimpgsez !== undefined) && (parsedData.srimpgsez !== null)) {
				wb_new.Sheets["IMPGSEZ"] = XLSX.utils.json_to_sheet(impgsezDataList);
			}
			if ((parsedData.srecom) && (parsedData.srecom !== undefined) && (parsedData.srecom !== null)) {
				wb_new.Sheets["ECOM"] = XLSX.utils.json_to_sheet(ecomDataList);
			}


			var wbout = XLSX.write(wb_new, { bookType: 'xlsx', bookSST: true, type: 'binary' });
			var btn = document.getElementById("sales_download");

			btn.onclick = function () {
				var cur_dt = new Date();
				fileSaver.saveAs(blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }), "SALES - " + cur_dt + ".xlsx");
			};
			if (parsedData.flag === true) {
				document.getElementById("sales_confirm_upload").click();
			}
			return parsedData;
		})
	},
	getInvoices: ({quarterNumber, finYear}) => {
		const yearNumber = finYear.split("-");
		const it04Period = quarterNumber + yearNumber[0];
        return fetch(`${BASE_URL}/gsp/getitc04invoices`, {
            headers: {
				'Content-Type': 'application/json',
                'period': it04Period,
                /*'gsptoken': data.gspDetails.gspToken,
                'otp': data.gspDetails.otp,
                'gstnusername': data.companyDetails.gstnUserName,
                'state': data.companyDetails.state,
                'gstin': data.companyDetails.gstin,
                'id': data.companyDetails.id,*/
            },
            method: 'GET'
            //body: JSON.stringify(data),
        }).then(res => res.json()).then(function(parsedData)
		{
			return parsedData
		})
		},
			/*
			3,4,5 - 13
			6,7,8 - 14
			9,10,11 - 15
			0,1,2 - 16
			*/
	quarterCurrent(){
		const currentMonth = new Date().getMonth();        
		const currQuart = (() => { 
				if(currentMonth==3 || currentMonth==4 || currentMonth==5){
					return 13
				} 
				else if(currentMonth==6 || currentMonth==7 || currentMonth==8){
					return 14
				}  
				else if(currentMonth==9 || currentMonth==10 || currentMonth==11){
					return 15
				}  
				else if(currentMonth==0 || currentMonth==1 || currentMonth==2){
					return 16
				}    
			})()
		return currQuart;
	}
}

export default Itc04Service
