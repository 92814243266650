import React from 'react'
import { Row, Col, Form, Input, message, Table, Modal, Tag, Popconfirm, Button, Tooltip, notification, Icon, Radio } from 'antd'
import flow from "lodash/fp/flow";
import find from "lodash/fp/find";
import map from "lodash/fp/map";
import omit from "lodash/fp/omit";
import groupBy from "lodash/fp/groupBy";
import entries from "lodash/fp/entries";
import pick from "lodash/fp/pick";
import findIndex from "lodash/fp/findIndex";
import flattenDeep from "lodash/fp/flattenDeep";
import toString from "lodash/fp/toString";
import { withRouter } from "react-router-dom";
import { CompanyByIdQuery } from "qgst-gql-queries/web/data.json";
import get from "lodash/fp/get";
import filter from 'lodash/fp/filter';
import Glamorous from 'glamorous'
import { connect } from "react-redux";
import { gql, graphql, compose, withApollo } from "react-apollo";
import { formatNumber, stateArray, ROUTES, i18n } from "../../shared/constants";
import gspAuth from "../../services/GSPService";
import Auth from '../../services/AuthService'
import DynamicTable from './DynamicTable'
import { Root, ContentWrapper as Content } from '../../shared/styles'
import moment from 'moment';
import { actions } from '../../redux'
import { gspActions } from "../../gspRedux";
import formatData from "./formatData";
import deleteData from "./deleteData";
import OTPForm from "../GSP/OTPForm";

import { FormItem } from '../../shared/utils'
const { ColumnGroup, Column } = Table
const errorModal = Modal.error;

class B2CL_Sync extends React.Component {


  constructor(props) {
    super(props);
    console.log(props);
    console.log(this.props);
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
        response_delete: null,
      };
    } else {
      this.gsp = {};
    }

  }

  componentDidUpdate = () => {
    console.log(this.props);
    console.log(this.prevProps);
    console.log(this.gsp);

    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = (this.gsp.otp !== undefined && this.gsp.otp !== null) ? this.gsp.otp : this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = companyDetails => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  handleCancel = e => {
    this.setState({ requestOTP: false });
  };

  handleOTPSubmit = val => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      this.getB2BGSTR1Data();
      gspAuth.saveOTP(this.gsp.otp, this.props.companyId);
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  state = {
    visibleModal: null,
    visible: false,
    downloadLoading: false,
    requestOTP: false,
    otp: null,
    period: null,
    submitVisible: true,
    sr_b2b_data: null,
    gstr1json: null,
    tableText: 'Fetch Data',
    stateTYPE: 'B2BA'
  };

  hide = () => {
    this.setState({
      visible: false
    });
  };

  showModal = async id => {
    this.setState({ visibleModal: id })
  }
  hideModal = () => {
    this.setState({ visibleModal: null })
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  getB2BGSTR1Data = async() => {
    message.destroy();
    message.loading('Fetching from GSTN...',0)
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);
    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp
      },
      period: period,
      action: this.state.stateTYPE
    };
    console.log(reqData);
    this.gsp.response = await gspAuth.getB2BData(reqData);
    message.destroy();
    if (
      !this.gsp.response.data.success &&
      this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
    ) {
      this.setState({ requestOTP: true });
    }

    console.log(this.gsp.response);

    var tableDATA;

    if(!this.gsp.response.data.errorCode){
      if(this.state.stateTYPE === 'B2BA'){
        tableDATA = formatData.formatB2BA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'B2CLA'){
        tableDATA = formatData.formatB2CLA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'B2CSA'){
        tableDATA = formatData.formatB2CSA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'CDNRA'){
        tableDATA = formatData.formatCDNRA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'CDNURA'){
        tableDATA = formatData.formatCDNURA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'EXPA'){
        tableDATA = formatData.formatEXPA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'ATA'){
        tableDATA = formatData.formatATA(this.gsp.response.data);
      }else if(this.state.stateTYPE === 'TXPA'){
        tableDATA = formatData.formatTXPA(this.gsp.response.data);
      }
            message.destroy();
            message.success('Fetched Successfully')

          this.setState({sr_b2b_data: tableDATA});
        }else if(this.gsp.response.data.errorCode === "No Invoices found for the provided Inputs"){
          message.destroy();
          message.warning('No Invoices found for the provided Inputs')
          this.setState({sr_b2b_data: null});
          this.setState({tableText: 'It seems there are no invoices to pull from GSTN!'});
        }
}

delALLInvoiceFromGSTN = async details => {


    message.destroy();
    message.loading('Deleting all the invoices from GSTN...',0)

  console.log(details);

  var sendDeleteALLData = this.state.sr_b2b_data;


console.log(sendDeleteALLData);
  var year_trim = this.props.finYear.split("-");
  var fy = "";
  if (this.props.finMonth < 4) {
    fy = "20" + year_trim[1];
  } else {
    fy = year_trim[0];
  }

  var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

  let gstr1json = {
    gstin: this.props.company.gstin,
    fp: period,
    gt: 0,
    cur_gt: 0
  }

  const b2barray = flow(
    map(inv => ({
      inum: inv.invoice_number,
      idt: inv.invoice_date,
      val: parseFloat(inv.invoice_value),
      pos: inv.pos_id,
      etin: inv.receiver_gstin,
      rchrg: inv.reverse_charge,
      inv_typ: inv.invoice_type==="Regular"?"R":(inv.invoice_type==="SBWH"?"CBW":inv.invoice_type),
      flag: "D",
      itms: map(item => {
        var rate_value = parseInt(parseInt(item.gst_rate) + '0' + inv.sr_b2b_items.length);
        if ((parseFloat(item.igst).toFixed(2) != NaN) && (parseFloat(item.igst).toFixed(2) != null) && (parseFloat(item.igst).toFixed(2) != 'NaN'))
        {
          return {
            num: rate_value,
            itm_det:
            {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),
              iamt: (item.igst === null || item.igst === undefined || item.igst === '') ? 0 : parseFloat(parseFloat(item.igst).toFixed(2)), //igst
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
        else if ((parseFloat(item.cgst).toFixed(2) != NaN) && (parseFloat(item.cgst).toFixed(2) != null) && (parseFloat(item.cgst).toFixed(2) != 'NaN'))
        {
          return {
            num: rate_value,
            itm_det:
            {
              txval: (item.taxable_value === null || item.taxable_value === undefined || item.taxable_value === '') ? 0 : parseFloat(parseFloat(item.taxable_value).toFixed(2)),
              rt: (item.gst_rate === null || item.gst_rate === undefined || item.gst_rate === '') ? 0 : parseFloat(parseFloat(item.gst_rate).toFixed(2)),//parseFloat(item.gst_rate).toFixed(2),
              camt: (item.cgst === null || item.cgst === undefined || item.cgst === '') ? 0 : parseFloat(parseFloat(item.cgst).toFixed(2)),//parseFloat(item.cgst).toFixed(2),
              samt: (item.sgst === null || item.sgst === undefined || item.sgst === '') ? 0 : parseFloat(parseFloat(item.sgst).toFixed(2)),//parseFloat(item.sgst).toFixed(2),
              csamt: (item.cess === null || item.cess === undefined || item.cess === '') ? 0 : parseFloat(parseFloat(item.cess).toFixed(2)),
            }
          }
        }
      })(inv.sr_b2b_items)
    })),
    groupBy(r => r.etin),
    entries,
    map(r => ({
      ctin: r[0],
      inv: map(omit('etin'))(r[1]),
    }))
  )(sendDeleteALLData);
  if(b2barray.length > 0) gstr1json["b2b"] = b2barray;

  console.log(gstr1json);

  const companyDetails = flow(
    pick(["gstin", "id", "gstnUserName"]),
    this.otherGSTNInformation
  )(this.props.company);
  const reqData = {
    companyDetails: companyDetails,
    gspDetails: {
      gspToken: this.gsp.access_token,
      otp: this.gsp.otp
    },
    period: period,
    body: gstr1json
  };
  this.gsp.response_delete = await gspAuth.saveGSTR1(reqData);

  message.destroy();
  if (
    !this.gsp.response_delete.data.success &&
    this.gsp.response_delete.data.errorCode.toUpperCase() === "RETOTPREQUEST"
  ) {
    this.setState({ requestOTP: true });
  }else if(!this.gsp.response_delete.data.success){
    errorModal({
      title: 'Delete Unsuccessful',
      content: <p>{this.gsp.response_delete.data.errorCode ? this.gsp.response_delete.data.errorCode : "Unable to process your request, please try again later."}</p>,
      iconType: "close-circle",
    });
  }
  if(this.gsp.response_delete.data.success) {
    this.getB2BGSTR1Data();
    if(this.gsp.response_delete.retstatus && this.gsp.response_delete.retstatus.status_cd === "P")
    {

      notification.destroy();
      notification.open({
        message: 'Success',
        description: "Invoice Deleted Successfully!!",
        duration: 0,
        icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
      });
    }
  }
}



delInvoiceFromGSTN = async details => {


  message.destroy();
  message.loading('Deleting the invoice from GSTN...',0)

  console.log(details);

  var sendDeleteData = [];
  var deleteArray = [];

  sendDeleteData = sendDeleteData.concat(details);
  console.log(sendDeleteData);
  var year_trim = this.props.finYear.split("-");
  var fy = "";
  if (this.props.finMonth < 4) {
    fy = "20" + year_trim[1];
  } else {
    fy = year_trim[0];
  }

  var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

  let gstr1json = {
    gstin: this.props.company.gstin,
    fp: period,
    gt: 0,
    cur_gt: 0
  }

  if(this.state.stateTYPE === 'B2BA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'b2ba');
    if(deleteArray.length > 0) gstr1json["b2ba"] = deleteArray;
  }else if(this.state.stateTYPE === 'B2CLA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'b2cla');
    if(deleteArray.length > 0) gstr1json["b2cla"] = deleteArray;
  }else if(this.state.stateTYPE === 'B2CSA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'b2csa');
    if(deleteArray.length > 0) gstr1json["b2csa"] = deleteArray;
  }else if(this.state.stateTYPE === 'CDNRA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'cdnra');
    if(deleteArray.length > 0) gstr1json["cdnra"] = deleteArray;
  }else if(this.state.stateTYPE === 'CDNURA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'cdnura');
    if(deleteArray.length > 0) gstr1json["cdnura"] = deleteArray;
  }else if(this.state.stateTYPE === 'EXPA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'expa');
    if(deleteArray.exp.length > 0) gstr1json["expa"] = deleteArray.exp;
  }else if(this.state.stateTYPE === 'AT'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'ata');
    if(deleteArray.length > 0) gstr1json["ata"] = deleteArray;
  }else if(this.state.stateTYPE === 'TXPA'){
    deleteArray = deleteData.formatDeletePayload(sendDeleteData, 'txpa');
    if(deleteArray.length > 0) gstr1json["txpda"] = deleteArray;
  }

  console.log(gstr1json);

  const companyDetails = flow(
    pick(["gstin", "id", "gstnUserName"]),
    this.otherGSTNInformation
  )(this.props.company);
  const reqData = {
    companyDetails: companyDetails,
    gspDetails: {
      gspToken: this.gsp.access_token,
      otp: this.gsp.otp
    },
    period: period,
    body: gstr1json
  };
  this.gsp.response_delete = await gspAuth.saveGSTR1(reqData);

  message.destroy();
  if (
    !this.gsp.response_delete.data.success &&
    this.gsp.response_delete.data.errorCode.toUpperCase() === "RETOTPREQUEST"
  ) {
    this.setState({ requestOTP: true });
  }else if(!this.gsp.response_delete.data.success){
    errorModal({
      title: 'Delete Unsuccessful',
      content: <p>{this.gsp.response_delete.data.errorCode ? this.gsp.response_delete.data.errorCode : "Unable to process your request, please try again later."}</p>,
      iconType: "close-circle",
    });
  }
  if(this.gsp.response_delete.data.success) {
    this.getB2BGSTR1Data();
    if(this.gsp.response_delete.retstatus && this.gsp.response_delete.retstatus.status_cd === "P")
    {

      notification.destroy();
      notification.open({
        message: 'Success',
        description: "Invoice Deleted Successfully!!",
        duration: 0,
        icon: <Icon type="check-circle" style={{ color: '#008000' }} />,
      });
    }
  }
}

onTypeSelectChange = (e) =>{
  console.log(e.target.value);
  this.setState({ stateTYPE: e.target.value });
  this.setState({sr_b2b_data: null});
  this.setState({tableText: 'Fetch Data'});
}



render () {

console.log(this.state);
return (
<Root>
  <Content>

  <Row style={{ textAlign: 'center' }}>
    <Col>
      <Radio.Group defaultValue="B2BA" buttonStyle="solid" onChange={e => this.onTypeSelectChange(e)} size="large">
        <Radio.Button style={{ margin: '2px' }} value="B2BA">B2B-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="B2CLA">B2CL-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="B2CSA">B2CS-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="CDNRA">CDNR-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="CDNURA">CDNUR-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="EXPA">EXP-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="ATA">AT-A</Radio.Button>
        <Radio.Button style={{ margin: '2px' }} value="TXPA">ATADJ-A</Radio.Button>
      </Radio.Group>
    </Col>
  </Row>


  <Row style={{ textAlign: 'center', marginTop: '15px' }}>
    <Col>
      <Popconfirm placement="bottomRight" title="Clicking this button will fetch your GSTR1 Selected type invoices from GSTN!" onConfirm={this.getB2BGSTR1Data} okText="Yes" cancelText="No">
        <Button style={{ backgroundColor: '#5f565fe0', fontSize: '13px', color: 'white', marginBottom: '5px'}} icon="cloud-download">Fetch<span style={{ marginLeft: '5px' }}>{this.state.stateTYPE}</span></Button>
      </Popconfirm>
    </Col>

  </Row>

  <DynamicTable
    stateTYPE={this.state.stateTYPE}
    showModal={this.showModal}
    hideModal={this.hideModal}
    visibleModal={this.state.visibleModal}
    delInvoiceFromGSTN={this.delInvoiceFromGSTN}
    sr_b2b_data={this.state.sr_b2b_data}
    tableText={this.state.tableText}
  />

  <OTPForm
    wrappedComponentRef={this.saveFormRef}
    visible={this.state.requestOTP}
    onSubmit={this.handleOTPSubmit}
    onCancel={this.handleCancel}
  />
  </Content>
</Root>

)
}
}


const companyQueryConfig = {
  name: "company",
  options: props => ({
    variables: {
      companyId: props.companyId,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ company }) => {
    return {
      company: get('viewer.company', company),
      gspDetails: get("viewer.gspInfo", company)
    }
  },
}

const mapStateToProps = state => {
  return {
    companyId: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    gspDetails: state.gsp
  };
};

export default compose(
  withRouter,
  withApollo,
  connect(mapStateToProps,
    {
      setGspToken: gspActions.setGspToken,
      setGspOtp: gspActions.setGspOtp,
      setGspDetails: gspActions.setGspDetails
    }),
  graphql(gql(CompanyByIdQuery), companyQueryConfig),
)(B2CL_Sync)

// exportExcel = () => {
//
//     return fetch(`${BASE_URL}/export/exportAllGSTR1?details=${this.props.companyId}`,
//     {
//       method: 'GET',
//     })
//     .then((result) => {
//       console.log(result);
//     })
// }
// <Popconfirm placement="bottomRight" title="Export all as excel" onConfirm={this.exportExcel} okText="Yes" cancelText="No">
//   <Button type='danger' icon="cloud-upload">Export all archives</Button>
// </Popconfirm>


// <Row style={{ padding: '20px'}}>
//   <Col span={4} style={{ textAlign: 'center', marginTop: '10px' }}>
//     Amendments
//   </Col>
//   <Col span={20}>
//     <Radio.Group buttonStyle="solid" onChange={e => this.onTypeSelectChange(e)} size="large">
//       <Radio.Button value="B2BA">B2B-A</Radio.Button>
//       <Radio.Button value="B2CLA">B2CL-A</Radio.Button>
//       <Radio.Button value="B2CSA">B2CS-A</Radio.Button>
//       <Radio.Button value="CDNRA">CDNR-A</Radio.Button>
//       <Radio.Button value="CDNURA">CDNUR-A</Radio.Button>
//       <Radio.Button value="EXPORTA">EXP-A</Radio.Button>
//       <Radio.Button value="ATA">AT-A</Radio.Button>
//       <Radio.Button value="TXPA">ATADJ-A</Radio.Button>
//     </Radio.Group>
//   </Col>
// </Row>
// <Col span={12} style={{ textAlign: 'center', marginTop: '15px' }}>
//   <Popconfirm placement="bottomRight" title="Clicking this button will delete all your GSTR1 Selected type invoices from GSTN!" onConfirm={this.delALLInvoiceFromGSTN} okText="Yes" cancelText="No">
//     <Button block style={{ backgroundColor: '#94301fe0', fontSize: '13px', color: 'white'}} icon="cloud-upload">Delete All {this.state.stateTYPE}</Button>
//   </Popconfirm>
// </Col>
