import React from "react";
import glamorous from "glamorous";
import Tooltip from "antd/lib/tooltip";
import Table from "antd/lib/table";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import {
  // Popover,
  notification,
  Icon,
  Tag,
  Popconfirm,
  Input,
  // Menu,
  // Dropdown,
  // Card,
  Checkbox,
  Radio,
  InputNumber,
  Empty,
  Steps,
  Alert,
  Select,
  Divider,
} from "antd";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import map from "lodash/fp/map";
import toString from "lodash/fp/toString";
import findIndex from "lodash/fp/findIndex";
import filter from "lodash/fp/filter";
import compact from "lodash/fp/compact";
import omit from "lodash/fp/omit";
// import flatMap from "lodash/fp/flatMap";
// import reduce from "lodash/fp/reduce";
import message from "antd/lib/message";
import flow from "lodash/fp/flow";
// import mergeWith from "lodash/fp/mergeWith";
import {
  formatNumber,
  stateArray,
  getPlaceOfSupply,
} from "../../shared/constants";
import {
  PR_RET_Period_Query,
  CompanyByIdQuery,
  CompanyUsersQuery,
} from "qgst-gql-queries/web/data.json";
import { gql, graphql, compose } from "react-apollo";
import { connect } from "react-redux";
// import DropZone from "react-dropzone";
import { Root, ContentWrapper as Content } from "../../shared/styles";
import PURCHASE from "../../services/PurchaseService";
// import RECON_PURCHASE from "../../services/RECONPurchaseService";
import { BASE_URL } from "../../shared/constants";
import { gspActions } from "../../gspRedux";
import OTPForm from "../GSP/OTPForm";
import Get2AForm from "../GSP/Get2AForm";
// import RIC_Container from "../ReconcileITC_Connect/RIC_Container";
// import TaxContainer from "../ReconcileITC_Connect/TaxContainer";
import gspAuth from "../../services/GSPService";
import moment from "moment";
import excelUtility from "../../services/excelUtilityService";
// import { Redirect } from "react-router-dom";
import { ROUTES } from "../../shared/constants";
import { Link } from "react-router-dom";
const Step = Steps.Step;
const XLSX = require("xlsx");
var fileSaver = require("file-saver");
var blob = require("blob-util");
const { flattenDeep, uniq, find, pick, get } = require("lodash/fp");
// const popModal = Modal.confirm;
// const errorModal = Modal.error;
const add_button = {
  outline: "0",
  display: "inline-block",
  marginBottom: "0",
  fontWeight: "500",
  textAlign: "center",
  touchAction: "manipulation",
  cursor: "pointer",
  backgroundImage: "none",
  border: "1px solid transparent",
  whiteSpace: "nowrap",
  lineHeight: "1.5",
  padding: "0 15px",
  fontSize: "13.5px",
  borderRadius: "5px",
  height: "34px",
  mozUserSelect: "none",
  position: "relative",
  color: "#000000a6",
  backgroundColor: "rgb(35, 130, 199)",
  borderColor: "#d9d9d9",
  paddingTop: "5px",
};
// const row_style = {
//   border: "solid 1px #e8e8e8",
//   padding: "10px",
//   margin: "1px"
// };
// const button_style = { width: "200px" };
const { Column } = Table;
const { TextArea } = Input;
const SRow = glamorous(Row)({ margin: "15px 0" });
const confirm = Modal.confirm;
const scroll = { x: 1600 };
var comp_id = "...";
var fileName;
// const actionButton = { paddingLeft: "0px", paddingRight: "0px" };
const CheckboxGroup = Checkbox.Group;
const plainOptions_b2b = [
  "Invoice Date",
  "Invoice Value",
  "Taxable Value",
  "Taxes (IGST, CGST, SGST, CESS)",
];
const plainOptions_cdnr = [
  "CDNR Date",
  "CDNR Value",
  "Taxable Value",
  "Taxes (IGST, CGST, SGST, CESS)",
];
const defaultCheckedList = ["Taxes (IGST, CGST, SGST, CESS)"];

// const PurchaseRegister = props => {
class ReconcileSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailTextBox: this.props.mailTextBox,
    };
  }

  showConfirm() {
    confirm({
      title: "Errors Found While Uploading Purchases",
      iconType: "printer",
      width: 866,
      content: <div id="dialogue_append" />,
      onOk() {
        document.getElementById("purchase_download").click();
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops errors!"));
      },
    });
  }

  info() {
    // Modal.info({
    //   title: "This is a notification message",
    //   iconType: "delete",
    //   width: 450,
    //   content: (
    //     <div>
    //       <p id="dynamic_error_msg" />
    //     </div>
    //   ),
    //   onOk() {}
    // });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (prevProps.mailTextBox !== this.props.mailTextBox) {
      if (document.getElementById("mailMessage")) {
        document.getElementById("mailMessage").value = this.props.mailTextBox;
      }
    }
  }

  render() {
    const props = this.props;
    console.log(props);
    return (
      <Root>
        <Content>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <Button
              size="small"
              type="primary"
              shape="circle"
              icon="question"
              onClick={props.handleClickStart}
            />
          </div>
          <Steps current={[1, 2, 3]}>
            <Step
              title={
                <a style={add_button} className="goto_upload_purchase">
                  <Icon
                    style={{ fontSize: "13.5px", color: "#FFFFFF" }}
                    type="rollback"
                  />{" "}
                  &nbsp;{" "}
                  <Link to={ROUTES.PURCHASE_DASHBOARD}>
                    Go to Upload Purchase
                  </Link>
                </a>
              }
              description="Purchase Invoices are uploaded in Upload Purchase under Purchase Register."
            />
            <Step
              title={
                <div>
                  <Button
                    style={{
                      width: "200px",
                      fontSize: "14px",
                      backgroundColor: "#964557",
                      color: "white",
                      padding: "0 10px",
                    }}
                    size="large"
                    icon="cloud-download"
                    onClick={props.onTwoA}
                    disabled={props.role === "reviewer"}
                    className="get_2a_tour"
                  >
                    Get Supplier Invoices
                  </Button>
                  <Get2AForm
                    wrappedComponentRef={props.save2AFormRef}
                    visible={props.pull2A}
                    onSubmit={props.handleGet2ASubmit}
                    onCancel={props.handleGet2ACancel}
                  />
                </div>
              }
              description="Clicking this button will fetch your supplier invoices from GST network."
            />
            <Step
              title={
                <Button
                  size="large"
                  className="reconcile_tour"
                  style={{
                    width: "200px",
                    fontSize: "14px",
                    backgroundColor: "#04880fe0",
                    color: "white",
                    padding: "0 10px",
                  }}
                  onClick={props.reconcilePrompt}
                  icon="fork"
                >
                  Reconcile
                </Button>
              }
              description="Clicking this button will reconcile your Purchases with supplier data."
            />
          </Steps>

          <Empty
            className="showEmpty"
            style={{ marginTop: "120px" }}
            image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
            description={
              <div>
                <span>Complete the steps and click reconcile! If done,</span>
              </div>
            }
          >
            <Button onClick={props.reconcilePrompt} type="primary">
              Reconcile Now
            </Button>
          </Empty>
          <SRow gutter={24}>
            <Col span={24}>
              <Alert
                className="showAlert"
                type="info"
                style={{ display: "none", textAlign: "center" }}
                message={
                  "Reconcilation done for the period " +
                  moment(props.recon_from_month.toString()).format("MMMM") +
                  " (" +
                  props.recon_year +
                  ") - " +
                  moment(props.recon_to_month.toString()).format("MMMM") +
                  " (" +
                  props.recon_year +
                  ")"
                }
                closable
              />
              <Table
                style={{ display: "none" }}
                rowKey="action"
                dataSource={props.output_b2b}
                bordered
                title={() => "Reconciliation Summary"}
                pagination={false}
                footer={() => (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        icon="audit"
                        style={{ backgroundColor: "#0e5e98", color: "white" }}
                        onClick={() => props.viewCPdata()}
                      >
                        View Only Supplier Data
                      </Button>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "5px" }}>
                      <Button
                        icon="file-excel"
                        style={{ backgroundColor: "#2382c7", color: "white" }}
                        onClick={() =>
                          props.downExcel(
                            props.recon_matches_b2b,
                            props.recon_mismatches_b2b,
                            props.recon_one_b2b,
                            props.recon_two_b2b,
                            props.recon_likely_b2b,
                            props.recon_matches_cdnr,
                            props.recon_mismatches_cdnr,
                            props.recon_one_cdnr,
                            props.recon_two_cdnr,
                            props.recon_likely_cdnr
                          )
                        }
                      >
                        Download Reconciliation Summary (Excel Format)
                      </Button>
                    </div>
                  </div>
                )}
              >
                <Column
                  title="Type"
                  dataIndex="type"
                  key="type"
                  className="alignCenter"
                />
                {props.showB2B && (
                  <Column
                    title="B2B"
                    key="action"
                    className="alignCenter"
                    render={(text, record) => {
                      if (record.action === "11") {
                        return (
                          <div>
                            <Button
                              type="success"
                              onClick={() => props.showModal(record.sl_no_b2b)}
                              style={{ width: "70px" }}
                            >
                              {props.recon_matches_b2b.length}
                            </Button>
                            <Modal
                              title="Purchase - Matched Invoices (B2B)"
                              visible={props.visibleMatchModal}
                              onCancel={props.hideMatchModal}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_matches_b2b}
                                bordered
                                rowSelection={props.rowSelection}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                                footer={() => (
                                  <div>
                                    <Button
                                      icon="download"
                                      style={{
                                        backgroundColor: "#2382c7",
                                        color: "white",
                                      }}
                                      onClick={() => props.fetchMissingNames()}
                                    >
                                      Fetch missing Supplier Name(s)
                                    </Button>
                                    <Button
                                      icon="close-circle"
                                      style={{
                                        backgroundColor: "#921c26",
                                        color: "white",
                                      }}
                                      onClick={() => props.markAsIneligible()}
                                    >
                                      Mark as Ineligible
                                    </Button>
                                  </div>
                                )}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="matchInvoiceSearch_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_match_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  defaultSortOrder="descend"
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="Invoice Value"
                                  dataIndex="invoice_value"
                                  className="alignCenter"
                                  key="invoice_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="Invoice Type"
                                  dataIndex="invoice_type"
                                  className="alignCenter"
                                  key="invoice_type"
                                />
                                <Column
                                  title="POS"
                                  dataIndex="pos_id"
                                  className="alignCenter"
                                  key="pos_id"
                                  render={(pos) =>
                                    getPlaceOfSupply(pos.substring(0, 2))
                                  }
                                />
                                <Column
                                  title="Reverse Charge"
                                  dataIndex="reverse_charge"
                                  className="alignCenter"
                                  key="reverse_charge"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Matched Invoices (B2B)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.pr_b2b_items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "22") {
                        // console.log("INSIDE MISMATCHES");
                        return (
                          <div>
                            <Button
                              type="success"
                              style={{ width: "70px" }}
                              onClick={() => props.showModal(record.sl_no_b2b)}
                            >
                              {record.count_b2b}
                            </Button>
                            <Modal
                              title="Purchase - Mismatched Invoices (B2B)"
                              visible={props.visibleModal}
                              onCancel={props.hideModal}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                id="mismatchTABLE"
                                dataSource={props.recon_mismatches_b2b}
                                bordered
                                rowSelection={props.rowSelection_mismatch}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                loading={props.loading}
                                footer={() => (
                                  <div>
                                    <Button
                                      icon="download"
                                      style={{
                                        backgroundColor: "#2382c7",
                                        color: "white",
                                      }}
                                      onClick={() => props.fetchMissingNames()}
                                    >
                                      Fetch missing Supplier Name(s)
                                    </Button>
                                    <Button
                                      icon="download"
                                      style={{
                                        backgroundColor: "#921c26",
                                        color: "white",
                                      }}
                                      onClick={() =>
                                        props.handleMismatchIneligible()
                                      }
                                    >
                                      Mark as Ineligible
                                    </Button>
                                  </div>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Type"
                                  dataIndex="type"
                                  key="type"
                                  className="alignCenter"
                                  render={(text) =>
                                    text === "reconprb2b"
                                      ? "GSTN"
                                      : "Purchase Register"
                                  }
                                />
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search Invoice Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="misMatchInvoiceSearch_b2b"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_mismatch_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="Invoice Value"
                                  dataIndex="invoice_value"
                                  className="alignCenter"
                                  key="invoice_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="Invoice Type"
                                  dataIndex="invoice_type"
                                  className="alignCenter"
                                  key="invoice_type"
                                />
                                <Column
                                  title="POS"
                                  dataIndex="pos_id"
                                  key="pos_id"
                                  className="alignCenter"
                                  render={(pos) =>
                                    find({ pos_id: pos.substring(0, 2) })(
                                      stateArray
                                    ).state_name
                                  }
                                />
                                <Column
                                  title="Reverse Charge"
                                  dataIndex="reverse_charge"
                                  className="alignCenter"
                                  key="reverse_charge"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Mismatched Invoices (B2B)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="TYPE"
                                                dataIndex="item_type"
                                                className="alignCenter"
                                                key="item_type"
                                                render={(text) =>
                                                  text.toUpperCase() ===
                                                  "RECONPRB2B"
                                                    ? "GSTN"
                                                    : "Purchase Register"
                                                }
                                              />
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                                <Column
                                  title="Contact"
                                  key="contact"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="mail"
                                            style={{
                                              backgroundColor: "#148a5a",
                                              color: "white",
                                            }}
                                            onClick={() =>
                                              props.notifyViaEmail(record)
                                            }
                                          >
                                            {" "}
                                            Email{" "}
                                          </Button>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "33") {
                        return (
                          <div>
                            <Button
                              type="success"
                              style={{ width: "70px" }}
                              onClick={() => props.showModal(record.sl_no_b2b)}
                            >
                              {props.recon_one_b2b.length}
                            </Button>
                            <Modal
                              title="Only in Purchase Register, but not in GST System (B2B)"
                              visible={props.visibleOneModal}
                              onCancel={props.hideOneModal}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_one_b2b}
                                bordered
                                rowSelection={props.rowSelection}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                footer={
                                  () => (
                                    <div>
                                      <Button
                                        icon="download"
                                        style={{
                                          backgroundColor: "#2382c7",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          props.fetchMissingNames()
                                        }
                                      >
                                        Fetch missing Supplier Name(s)
                                      </Button>
                                      <Button
                                        icon="close-circle"
                                        style={{
                                          backgroundColor: "#921c26",
                                          color: "white",
                                        }}
                                        onClick={() => props.markAsIneligible()}
                                      >
                                        Mark as Ineligible
                                      </Button>
                                    </div>
                                  )
                                  //   <Button
                                  //   icon="check-circle"
                                  //   style={{ backgroundColor: '#34714a', color: 'white' }}
                                  //   onClick={() => props.availPRCredit()}>
                                  //   Avail Credit
                                  //   </Button>
                                  //   <Button
                                  //   icon="close-circle"
                                  //   style={{ backgroundColor: '#b9280f', color: 'white' }}
                                  //   onClick={() => props.removePRCredit()}>
                                  //   Remove Credit
                                  //  </Button>
                                  // </div>
                                }
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="prInvoiceSearch_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_opr_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  defaultSortOrder="descend"
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="Invoice Value"
                                  dataIndex="invoice_value"
                                  className="alignCenter"
                                  key="invoice_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="Invoice Type"
                                  dataIndex="invoice_type"
                                  className="alignCenter"
                                  key="invoice_type"
                                />
                                <Column
                                  title="POS"
                                  dataIndex="pos_id"
                                  key="pos_id"
                                  className="alignCenter"
                                  render={(pos) =>
                                    find({ pos_id: pos.substring(0, 2) })(
                                      stateArray
                                    ).state_name
                                  }
                                />
                                <Column
                                  title="Reverse Charge"
                                  dataIndex="reverse_charge"
                                  className="alignCenter"
                                  key="reverse_charge"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Purchase Register Invoices (B2B)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.pr_b2b_items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                                <Column
                                  title="Contact"
                                  key="contact"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="mail"
                                            style={{
                                              backgroundColor: "#148a5a",
                                              color: "white",
                                            }}
                                            onClick={() =>
                                              props.notifyViaEmail(record)
                                            }
                                          >
                                            {" "}
                                            Email{" "}
                                          </Button>
                                        </Col>{" "}
                                      </SRow>
                                    );

                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "44") {
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_b2b)}
                            >
                              {props.recon_two_b2b.length}
                            </Button>
                            <Modal
                              title="Only in GST System, but not in Purchase Register (B2B)"
                              visible={props.visibleTwoModal}
                              onCancel={props.hideTwoModal}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_two_b2b}
                                bordered
                                rowSelection={props.rowSelection}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                footer={() => (
                                  <div>
                                    <Button
                                      icon="download"
                                      style={{
                                        backgroundColor: "#2382c7",
                                        color: "white",
                                      }}
                                      onClick={() => props.fetchMissingNames()}
                                    >
                                      Fetch missing Supplier Name(s)
                                    </Button>
                                    <Button
                                      icon="close-circle"
                                      style={{
                                        backgroundColor: "#921c26",
                                        color: "white",
                                      }}
                                      onClick={() => props.markAsIneligible()}
                                    >
                                      Mark as Ineligible
                                    </Button>
                                  </div>
                                )}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnInvoiceSearch_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_recon_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  defaultSortOrder="descend"
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="Invoice Value"
                                  dataIndex="invoice_value"
                                  className="alignCenter"
                                  key="invoice_value"
                                  width="130"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="Invoice Type"
                                  dataIndex="invoice_type"
                                  className="alignCenter"
                                  key="invoice_type"
                                />
                                <Column
                                  title="POS"
                                  dataIndex="pos_id"
                                  key="pos_id"
                                  className="alignCenter"
                                  render={(pos) =>
                                    find({ pos_id: pos + "" })(stateArray)
                                      .state_name
                                  }
                                />
                                <Column
                                  title="Reverse Charge"
                                  dataIndex="reverse_charge"
                                  className="alignCenter"
                                  key="reverse_charge"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  width="130"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Button
                                          icon="bars"
                                          type="primary"
                                          onClick={() =>
                                            props.showItemModal(record)
                                          }
                                        >
                                          Items
                                        </Button>
                                        <Modal
                                          title="List Items Summary - GST System Invoices (B2B)"
                                          visible={
                                            props.visibleItemModal === record.id
                                              ? true
                                              : false
                                          }
                                          onCancel={props.hideItemModal}
                                          footer={null}
                                          width="920"
                                        >
                                          <Table
                                            dataSource={
                                              record.recon_pr_b2b_items
                                            }
                                            bordered
                                            loading={props.loading}
                                            scroll={scroll}
                                            pagination={{
                                              className: "my-pagination",
                                              defaultPageSize: 6,
                                            }}
                                          >
                                            <Column
                                              title="Rate"
                                              dataIndex="gst_rate"
                                              className="alignCenter"
                                              key="gst_rate"
                                            />
                                            <Column
                                              title="Taxable Value"
                                              className="alignCenter"
                                              dataIndex="taxable_value"
                                              key="taxable_value"
                                              render={(val) => (
                                                <p>
                                                  {props.formatNumber(
                                                    val === null ? 0 : val
                                                  )}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="IGST"
                                              dataIndex="igst"
                                              className="alignCenter"
                                              key="igst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="CGST"
                                              dataIndex="cgst"
                                              className="alignCenter"
                                              key="cgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="SGST"
                                              dataIndex="sgst"
                                              className="alignCenter"
                                              key="sgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="Cess"
                                              dataIndex="cess"
                                              className="alignCenter"
                                              key="cess"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                          </Table>
                                        </Modal>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "55") {
                        // console.log("INSIDE LIKELY");
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_b2b)}
                            >
                              {record.count_b2b}
                            </Button>
                            <Modal
                              title="Likely Matched Invoices (B2B)3"
                              visible={props.visibleLikelyModal}
                              onCancel={props.hideLikelyModal}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_likely_b2b}
                                bordered
                                // rowSelection={props.rowSelection_likely}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                loading={props.loading}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Type"
                                  dataIndex="type"
                                  key="type"
                                  className="alignCenter"
                                  render={(text) =>
                                    text === "reconprb2b"
                                      ? "GSTN"
                                      : "Purchase Register"
                                  }
                                />
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search Invoice Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="likelyInvoiceSearch_b2b"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_likely_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="Invoice Value"
                                  dataIndex="invoice_value"
                                  className="alignCenter"
                                  key="invoice_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="Invoice Type"
                                  dataIndex="invoice_type"
                                  className="alignCenter"
                                  key="invoice_type"
                                />
                                <Column
                                  title="POS"
                                  dataIndex="pos_id"
                                  key="pos_id"
                                  className="alignCenter"
                                  render={(pos) =>
                                    find({ pos_id: pos.substring(0, 2) })(
                                      stateArray
                                    ).state_name
                                  }
                                />
                                <Column
                                  title="Reverse Charge"
                                  dataIndex="reverse_charge"
                                  className="alignCenter"
                                  key="reverse_charge"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Likely Matched Invoices (B2B)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="TYPE"
                                                dataIndex="item_type"
                                                className="alignCenter"
                                                key="item_type"
                                                render={(text) =>
                                                  text.toUpperCase() ===
                                                  "RECONPRB2B"
                                                    ? "GSTN"
                                                    : "Purchase Register"
                                                }
                                              />
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                                <Column
                                  title="Match Percentile"
                                  key="mp"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but_1 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="green">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    var but_2 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="orange">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    var but_3 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="purple">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );

                                    const obj_1 = {
                                      children: but_1,
                                      props: { rowSpan: 2 },
                                    };
                                    const obj_2 = {
                                      children: but_2,
                                      props: { rowSpan: 2 },
                                    };
                                    const obj_3 = {
                                      children: but_3,
                                      props: { rowSpan: 2 },
                                    };

                                    var temp = index % 2;
                                    if (temp === 0) {
                                      //  if(!record.threshold){
                                      //  return but_1, obj_1
                                      // }
                                      if (
                                        record.threshold === "95%" ||
                                        record.threshold === "90%"
                                      ) {
                                        return but_1, obj_1;
                                      }
                                      if (
                                        record.threshold === "85%" ||
                                        record.threshold === "80%"
                                      ) {
                                        return but_2, obj_2;
                                      }
                                      if (record.threshold === "75%") {
                                        return but_3, obj_3;
                                      }
                                    }
                                  }}
                                />
                                {/*<Column
                                  title="Match Percentile"
                                  key="mp"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    if(!record.threshold){
                                    return (<Tag color='green'>99%</Tag>)
                                   }
                                   if (record.threshold === '95%' || record.threshold === '90%') 
                                    { 
                                      return (<Tag color='lime'>{record.threshold}</Tag>)
                                    }
                                    if (record.threshold === '85%' || record.threshold === '80%') 
                                    { 
                                      return (<Tag color='orange'>{record.threshold}</Tag>)
                                    }
                                    if (record.threshold === '70%') 
                                    { 
                                      return (<Tag color='red'>{record.threshold}</Tag>)
                                    }
  
                                    }
                                  }
                                /> */}
                                {/* <Column 
                                  title="Contact" 
                                  key="contact" 
                                  className="alignCenter" 
                                  rowSpan="2" 
                                  render={(text, record, index) => {
                                   var but = ( 
                                    <SRow gutter={24}> 
                                      <Col span="8"> 
                                        <Button icon="mail" type="primary" onClick={() => props.notifyViaEmail(record)} > Email </Button> 
                                      </Col> </SRow> 
                                      ); 
                                   const obj = { children: but, props: { rowSpan: 2 } }; 
                                   var temp = index % 2; 
                                   if (temp === 0) { return but, obj; } 
                                  }} 
                                /> */}
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "66") {
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_b2b)}
                            >
                              {props.recon_ineligible_b2b.length}
                            </Button>
                            <Modal
                              title="Ineligible Transactions (B2B)"
                              visible={props.visibleIneligibleModal}
                              onCancel={props.hideIneligibleModal}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_ineligible_b2b}
                                bordered
                                rowSelection={props.rowSelection}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                footer={() => (
                                  <div>
                                    <Button
                                      icon="download"
                                      style={{
                                        backgroundColor: "#2382c7",
                                        color: "white",
                                      }}
                                      onClick={() => props.fetchMissingNames()}
                                    >
                                      Fetch missing Supplier Name(s)
                                    </Button>
                                    <Button
                                      icon="close-circle"
                                      style={{
                                        backgroundColor: "#3f5a0a",
                                        color: "white",
                                      }}
                                      onClick={() => props.markAsEligible()}
                                    >
                                      Mark as Eligible and Reconcile
                                    </Button>
                                  </div>
                                )}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="ineligibleInvoiceSearch_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="ineligibleSearch_recon_b2b"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  defaultSortOrder="descend"
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="Invoice Value"
                                  dataIndex="invoice_value"
                                  className="alignCenter"
                                  key="invoice_value"
                                  width="130"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="Invoice Type"
                                  dataIndex="invoice_type"
                                  className="alignCenter"
                                  key="invoice_type"
                                />
                                <Column
                                  title="POS"
                                  dataIndex="pos_id"
                                  key="pos_id"
                                  className="alignCenter"
                                  render={(pos) =>
                                    pos.length > 2
                                      ? pos
                                      : find({ pos_id: pos + "" })(stateArray)
                                          .state_name
                                  }
                                />
                                <Column
                                  title="Reverse Charge"
                                  dataIndex="reverse_charge"
                                  className="alignCenter"
                                  key="reverse_charge"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  width="130"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Button
                                          icon="bars"
                                          type="primary"
                                          onClick={() =>
                                            props.showItemModal(record)
                                          }
                                        >
                                          Items
                                        </Button>
                                        <Modal
                                          title="List Items Summary - Ineligible Invoices (B2B)"
                                          visible={
                                            props.visibleItemModal === record.id
                                              ? true
                                              : false
                                          }
                                          onCancel={props.hideItemModal}
                                          footer={null}
                                          width="920"
                                        >
                                          <Table
                                            dataSource={
                                              record.recon_pr_b2b_items
                                            }
                                            bordered
                                            loading={props.loading}
                                            scroll={scroll}
                                            pagination={{
                                              className: "my-pagination",
                                              defaultPageSize: 6,
                                            }}
                                          >
                                            <Column
                                              title="Rate"
                                              dataIndex="gst_rate"
                                              className="alignCenter"
                                              key="gst_rate"
                                            />
                                            <Column
                                              title="Taxable Value"
                                              className="alignCenter"
                                              dataIndex="taxable_value"
                                              key="taxable_value"
                                              render={(val) => (
                                                <p>
                                                  {props.formatNumber(
                                                    val === null ? 0 : val
                                                  )}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="IGST"
                                              dataIndex="igst"
                                              className="alignCenter"
                                              key="igst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="CGST"
                                              dataIndex="cgst"
                                              className="alignCenter"
                                              key="cgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="SGST"
                                              dataIndex="sgst"
                                              className="alignCenter"
                                              key="sgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="Cess"
                                              dataIndex="cess"
                                              className="alignCenter"
                                              key="cess"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                          </Table>
                                        </Modal>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      }
                    }}
                  />
                )}
                {props.showCDNR && (
                  <Column
                    title="CDNR"
                    key="cdnr"
                    className="alignCenter"
                    render={(text, record) => {
                      if (record.action === "11") {
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Purchase - Matched Invoices (CDNR)"
                              visible={props.visibleMatchModal_cdnr}
                              onCancel={props.hideMatchModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_matches_cdnr}
                                bordered
                                // rowSelection={props.rowSelection}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="matchInvoiceSearch_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_match_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  // defaultSortOrder='descend'
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="matchCDNRSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  defaultSortOrder="descend"
                                  sorter={(a, b) =>
                                    props.sortDate(a.cdnr_date, b.cdnr_date)
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Matched Invoices (CDNR)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.pr_cdnr_items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "22") {
                        // console.log("INSIDE MISMATCHES");
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Purchase - Mismatched Invoices (CDNR)"
                              visible={props.visibleModal_cdnr}
                              onCancel={props.hideModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_mismatches_cdnr}
                                bordered
                                // rowSelection={props.rowSelection_mismatch}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                loading={props.loading}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Type"
                                  dataIndex="type"
                                  key="type"
                                  className="alignCenter"
                                  render={(text) =>
                                    text === "reconprcdnr"
                                      ? "GSTN"
                                      : "Purchase Register"
                                  }
                                />
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search Invoice Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="misMatchInvoiceSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_mismatch_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="misMatchCDNRSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Mismatched Invoices (CDNR)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="TYPE"
                                                dataIndex="item_type"
                                                className="alignCenter"
                                                key="item_type"
                                                render={(text) =>
                                                  text.toUpperCase() ===
                                                  "RECONPRCDNR"
                                                    ? "GSTN"
                                                    : "Purchase Register"
                                                }
                                              />
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                                <Column
                                  title="Contact"
                                  key="contact"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="mail"
                                            style={{
                                              backgroundColor: "#148a5a",
                                              color: "white",
                                            }}
                                            onClick={() =>
                                              props.notifyViaEmail_CDNR(record)
                                            }
                                          >
                                            {" "}
                                            Email{" "}
                                          </Button>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "33") {
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Only in Purchase Register, but not in GST System (CDNR)"
                              visible={props.visibleOneModal_cdnr}
                              onCancel={props.hideOneModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_one_cdnr}
                                bordered
                                // rowSelection={props.rowSelection}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="prInvoiceSearch_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_opr_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  // defaultSortOrder='descend'
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="prCDNRSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  defaultSortOrder="descend"
                                  sorter={(a, b) =>
                                    props.sortDate(a.cdnr_date, b.cdnr_date)
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Purchase Register Invoices (CDNR)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.pr_cdnr_items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === null
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                                <Column
                                  title="Contact"
                                  key="contact"
                                  className="alignCenter"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="mail"
                                            style={{
                                              backgroundColor: "#148a5a",
                                              color: "white",
                                            }}
                                            onClick={() =>
                                              props.notifyViaEmail_CDNR(record)
                                            }
                                          >
                                            {" "}
                                            Email{" "}
                                          </Button>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "44") {
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Only in GST System, but not in Purchase Register (CDNR)"
                              visible={props.visibleTwoModal_cdnr}
                              onCancel={props.hideTwoModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_two_cdnr}
                                bordered
                                // rowSelection={props.rowSelection}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnInvoiceSearch_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_recon_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  // defaultSortOrder='descend'
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="gstnCDNRSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  defaultSortOrder="descend"
                                  sorter={(a, b) =>
                                    props.sortDate(a.cdnr_date, b.cdnr_date)
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  width="130"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Button
                                          icon="bars"
                                          type="primary"
                                          onClick={() =>
                                            props.showItemModal(record)
                                          }
                                        >
                                          Items
                                        </Button>
                                        <Modal
                                          title="List Items Summary - GST System Invoices (CDNR)"
                                          visible={
                                            props.visibleItemModal === record.id
                                              ? true
                                              : false
                                          }
                                          onCancel={props.hideItemModal}
                                          footer={null}
                                          width="920"
                                        >
                                          <Table
                                            dataSource={
                                              record.recon_pr_cdnr_items
                                            }
                                            bordered
                                            loading={props.loading}
                                            scroll={scroll}
                                            pagination={{
                                              className: "my-pagination",
                                              defaultPageSize: 6,
                                            }}
                                          >
                                            <Column
                                              title="Rate"
                                              dataIndex="gst_rate"
                                              className="alignCenter"
                                              key="gst_rate"
                                            />
                                            <Column
                                              title="Taxable Value"
                                              className="alignCenter"
                                              dataIndex="taxable_value"
                                              key="taxable_value"
                                              render={(val) => (
                                                <p>
                                                  {props.formatNumber(
                                                    val === null ? 0 : val
                                                  )}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="IGST"
                                              dataIndex="igst"
                                              className="alignCenter"
                                              key="igst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="CGST"
                                              dataIndex="cgst"
                                              className="alignCenter"
                                              key="cgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="SGST"
                                              dataIndex="sgst"
                                              className="alignCenter"
                                              key="sgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="Cess"
                                              dataIndex="cess"
                                              className="alignCenter"
                                              key="cess"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                          </Table>
                                        </Modal>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "55") {
                        // console.log("INSIDE LIKELY");
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Likely Matched Invoices (CDNR)"
                              visible={props.visibleLikelyModal_cdnr}
                              onCancel={props.hideLikelyModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_likely_cdnr}
                                bordered
                                // rowSelection={props.rowSelection_likely}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                loading={props.loading}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Type"
                                  dataIndex="type"
                                  key="type"
                                  className="alignCenter"
                                  render={(text) =>
                                    text === "reconprcdnr"
                                      ? "GSTN"
                                      : "Purchase Register"
                                  }
                                />
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search Invoice Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="likelyInvoiceSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_likely_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  // defaultSortOrder='descend'
                                  // sorter={(a, b) => props.sortDate(a.invoice_date, b.invoice_date)}
                                  // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="likelyCDNRSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  // defaultSortOrder='descend'
                                  // sorter={(a, b) => props.sortDate(a.cdnr_date, b.cdnr_date)}
                                  // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Likely Matched Invoices (CDNR)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="TYPE"
                                                dataIndex="item_type"
                                                className="alignCenter"
                                                key="item_type"
                                                render={(text) =>
                                                  text.toUpperCase() ===
                                                  "RECONPRCDNR"
                                                    ? "GSTN"
                                                    : "Purchase Register"
                                                }
                                              />
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                                <Column
                                  title="Match Percentile"
                                  key="mp"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but_1 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="green">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    var but_2 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="orange">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    var but_3 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="purple">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );

                                    const obj_1 = {
                                      children: but_1,
                                      props: { rowSpan: 2 },
                                    };
                                    const obj_2 = {
                                      children: but_2,
                                      props: { rowSpan: 2 },
                                    };
                                    const obj_3 = {
                                      children: but_3,
                                      props: { rowSpan: 2 },
                                    };

                                    var temp = index % 2;
                                    if (temp === 0) {
                                      //  if(!record.threshold){
                                      //  return but_1, obj_1
                                      // }
                                      if (
                                        record.threshold === "95%" ||
                                        record.threshold === "90%"
                                      ) {
                                        return but_1, obj_1;
                                      }
                                      if (
                                        record.threshold === "85%" ||
                                        record.threshold === "80%"
                                      ) {
                                        return but_2, obj_2;
                                      }
                                      if (record.threshold === "75%") {
                                        return but_3, obj_3;
                                      }
                                    }
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "66") {
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Ineligible Transactions (CDNR)"
                              visible={props.visibleIneligibleModal_cdnr}
                              onCancel={props.hideIneligibleModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_ineligible_cdnr}
                                bordered
                                // rowSelection={props.rowSelection}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                              >
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search Invoice Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="ineligibleInvoiceSearch_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_ineligible_recon_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  // defaultSortOrder='descend'
                                  sorter={(a, b) =>
                                    props.sortDate(
                                      a.invoice_date,
                                      b.invoice_date
                                    )
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="gstnCDNRSearchIneligible_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  defaultSortOrder="descend"
                                  sorter={(a, b) =>
                                    props.sortDate(a.cdnr_date, b.cdnr_date)
                                  }
                                  render={(text) =>
                                    text
                                      ? moment(text, "DD-MM-YYYY").format(
                                          "DD-MMM-YY"
                                        )
                                      : ""
                                  }
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  width="130"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Button
                                          icon="bars"
                                          type="primary"
                                          onClick={() =>
                                            props.showItemModal(record)
                                          }
                                        >
                                          Items
                                        </Button>
                                        <Modal
                                          title="List Items Summary - Ineligible Invoices (CDNR)"
                                          visible={
                                            props.visibleItemModal === record.id
                                              ? true
                                              : false
                                          }
                                          onCancel={props.hideItemModal}
                                          footer={null}
                                          width="920"
                                        >
                                          <Table
                                            dataSource={
                                              record.recon_pr_cdnr_items
                                            }
                                            bordered
                                            loading={props.loading}
                                            scroll={scroll}
                                            pagination={{
                                              className: "my-pagination",
                                              defaultPageSize: 6,
                                            }}
                                          >
                                            <Column
                                              title="Rate"
                                              dataIndex="gst_rate"
                                              className="alignCenter"
                                              key="gst_rate"
                                            />
                                            <Column
                                              title="Taxable Value"
                                              className="alignCenter"
                                              dataIndex="taxable_value"
                                              key="taxable_value"
                                              render={(val) => (
                                                <p>
                                                  {props.formatNumber(
                                                    val === null ? 0 : val
                                                  )}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="IGST"
                                              dataIndex="igst"
                                              className="alignCenter"
                                              key="igst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="CGST"
                                              dataIndex="cgst"
                                              className="alignCenter"
                                              key="cgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="SGST"
                                              dataIndex="sgst"
                                              className="alignCenter"
                                              key="sgst"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                            <Column
                                              title="Cess"
                                              dataIndex="cess"
                                              className="alignCenter"
                                              key="cess"
                                              render={(val) => (
                                                <p>
                                                  {val === null
                                                    ? " - - - "
                                                    : props.formatNumber(val)}
                                                </p>
                                              )}
                                            />
                                          </Table>
                                        </Modal>
                                      </SRow>
                                    );
                                    return but;
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      } else if (record.action === "55") {
                        // console.log("INSIDE LIKELY");
                        return (
                          <div>
                            <Button
                              style={{ width: "70px" }}
                              type="success"
                              onClick={() => props.showModal(record.sl_no_cdnr)}
                            >
                              {record.count_cdnr}
                            </Button>
                            <Modal
                              title="Likely Matched Invoices (CDNR)"
                              visible={props.visibleLikelyModal_cdnr}
                              onCancel={props.hideLikelyModal_cdnr}
                              footer={null}
                              width="96%"
                            >
                              <Table
                                dataSource={props.recon_likely_cdnr}
                                bordered
                                // rowSelection={props.rowSelection_likely}
                                rowKey="id"
                                title={() => (
                                  <span style={{ marginLeft: 8 }}>
                                    {props.hasSelected
                                      ? `Selected ${props.selectedRowKeys.length} Invoices`
                                      : ""}
                                  </span>
                                )}
                                loading={props.loading}
                                footer={() => (
                                  <Button
                                    icon="download"
                                    style={{
                                      backgroundColor: "#2382c7",
                                      color: "white",
                                    }}
                                    onClick={() => props.fetchMissingNames()}
                                  >
                                    Fetch missing Supplier Name(s)
                                  </Button>
                                )}
                                pagination={{
                                  className: "my-pagination",
                                  defaultPageSize: 6,
                                }}
                              >
                                <Column
                                  title="Type"
                                  dataIndex="type"
                                  key="type"
                                  className="alignCenter"
                                  render={(text) =>
                                    text === "reconprcdnr"
                                      ? "GSTN"
                                      : "Purchase Register"
                                  }
                                />
                                <Column
                                  title="Invoice Number"
                                  dataIndex="invoice_number"
                                  className="alignCenter"
                                  key="invoice_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search Invoice Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="likelyInvoiceSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.invoice_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="GSTIN"
                                  dataIndex="supplier_gstin"
                                  className="alignCenter"
                                  key="supplier_gstin"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <div className="custom-filter-dropdown">
                                      <Input
                                        placeholder="Search GSTIN Number"
                                        value={selectedKeys[0]}
                                        onChange={(e) =>
                                          setSelectedKeys(
                                            e.target.value
                                              ? [e.target.value]
                                              : []
                                          )
                                        }
                                        onPressEnter={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={props.handleSearch(
                                          selectedKeys,
                                          confirm
                                        )}
                                      >
                                        Search
                                      </Button>
                                      <Button
                                        id="gstnSearch_likely_cdnr"
                                        onClick={props.handleReset(
                                          clearFilters
                                        )}
                                      >
                                        Reset
                                      </Button>
                                    </div>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.supplier_gstin
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="Supplier Name"
                                  dataIndex="supplier_name"
                                  className="alignCenter"
                                  key="supplier_name"
                                />
                                <Column
                                  title="Invoice Date"
                                  dataIndex="invoice_date"
                                  className="alignCenter"
                                  key="invoice_date"
                                  width="120"
                                  // defaultSortOrder='descend'
                                  // sorter={(a, b) => props.sortDate(a.invoice_date, b.invoice_date)}
                                  // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
                                />
                                <Column
                                  title="CDNR Number"
                                  dataIndex="cdnr_number"
                                  className="alignCenter"
                                  key="cdnr_number"
                                  filterDropdown={({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                  }) => (
                                    <span>
                                      <div className="custom-filter-dropdown">
                                        <Input
                                          placeholder="Search CDNR Number"
                                          value={selectedKeys[0]}
                                          onChange={(e) =>
                                            setSelectedKeys(
                                              e.target.value
                                                ? [e.target.value]
                                                : []
                                            )
                                          }
                                          onPressEnter={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        />
                                        <Button
                                          type="primary"
                                          onClick={props.handleSearch(
                                            selectedKeys,
                                            confirm
                                          )}
                                        >
                                          Search
                                        </Button>
                                        <Button
                                          id="likelyCDNRSearch_cdnr"
                                          onClick={props.handleReset(
                                            clearFilters
                                          )}
                                        >
                                          Reset
                                        </Button>
                                      </div>
                                    </span>
                                  )}
                                  filterIcon={(filtered) => (
                                    <Icon
                                      type="search"
                                      style={{
                                        color: filtered ? "#108ee9" : "#aaa",
                                      }}
                                    />
                                  )}
                                  onFilter={(value, record) =>
                                    record.cdnr_number
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  }
                                  render={(text) => {
                                    return props.searchText ? (
                                      <span>
                                        {text
                                          .split(
                                            new RegExp(
                                              `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                                              "i"
                                            )
                                          )
                                          .map(
                                            (fragment, i) =>
                                              fragment.toLowerCase() ===
                                              props.searchText.toLowerCase() ? (
                                                <span
                                                  key={i}
                                                  className="highlight"
                                                >
                                                  {fragment}
                                                </span>
                                              ) : (
                                                fragment
                                              ) // eslint-disable-line
                                          )}
                                      </span>
                                    ) : (
                                      text
                                    );
                                  }}
                                />
                                <Column
                                  title="CDNR Date"
                                  dataIndex="cdnr_date"
                                  className="alignCenter"
                                  key="cdnr_date"
                                  // defaultSortOrder='descend'
                                  // sorter={(a, b) => props.sortDate(a.cdnr_date, b.cdnr_date)}
                                  // render={text => (text ? moment(text, "DD-MM-YYYY").format('DD-MMM-YY') : '')}
                                />
                                <Column
                                  title="CDNR Value"
                                  dataIndex="cdnr_value"
                                  className="alignCenter"
                                  key="cdnr_value"
                                  render={(val) => (
                                    <p>
                                      {props.formatNumber(
                                        val === null ? 0 : val
                                      )}
                                    </p>
                                  )}
                                />
                                <Column
                                  title="CDNR Type"
                                  dataIndex="cdnr_type"
                                  className="alignCenter"
                                  key="cdnr_type"
                                  render={(val) => (
                                    <p>
                                      {val === "C"
                                        ? "Credit"
                                        : val === "D"
                                        ? "Debit"
                                        : ""}
                                    </p>
                                  )}
                                />

                                <Column
                                  title="Pre-GST"
                                  dataIndex="pre_gst"
                                  className="alignCenter"
                                  key="pre_gst"
                                />

                                <Column
                                  title="Items"
                                  key="action"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Button
                                            icon="bars"
                                            type="primary"
                                            onClick={() =>
                                              props.showItemModal(record)
                                            }
                                          >
                                            Items
                                          </Button>
                                          <Modal
                                            title="List Items Summary - Likely Matched Invoices (CDNR)"
                                            visible={
                                              props.visibleItemModal ===
                                              record.id
                                                ? true
                                                : false
                                            }
                                            onCancel={props.hideItemModal}
                                            footer={null}
                                            width="920"
                                          >
                                            <Table
                                              dataSource={record.items}
                                              bordered
                                              loading={props.loading}
                                              scroll={scroll}
                                              pagination={{
                                                className: "my-pagination",
                                                defaultPageSize: 6,
                                              }}
                                            >
                                              <Column
                                                title="TYPE"
                                                dataIndex="item_type"
                                                className="alignCenter"
                                                key="item_type"
                                                render={(text) =>
                                                  text.toUpperCase() ===
                                                  "RECONPRCDNR"
                                                    ? "GSTN"
                                                    : "Purchase Register"
                                                }
                                              />
                                              <Column
                                                title="Rate"
                                                dataIndex="gst_rate"
                                                className="alignCenter"
                                                key="gst_rate"
                                              />
                                              <Column
                                                title="Taxable Value"
                                                className="alignCenter"
                                                dataIndex="taxable_value"
                                                key="taxable_value"
                                                render={(val) => (
                                                  <p>
                                                    {props.formatNumber(
                                                      val === null ? 0 : val
                                                    )}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="IGST"
                                                dataIndex="igst"
                                                className="alignCenter"
                                                key="igst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="CGST"
                                                dataIndex="cgst"
                                                className="alignCenter"
                                                key="cgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="SGST"
                                                dataIndex="sgst"
                                                className="alignCenter"
                                                key="sgst"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                              <Column
                                                title="Cess"
                                                dataIndex="cess"
                                                className="alignCenter"
                                                key="cess"
                                                render={(val) => (
                                                  <p>
                                                    {val === " - - - "
                                                      ? " - - - "
                                                      : props.formatNumber(val)}
                                                  </p>
                                                )}
                                              />
                                            </Table>
                                          </Modal>
                                        </Col>
                                      </SRow>
                                    );
                                    const obj = {
                                      children: but,
                                      props: { rowSpan: 2 },
                                    };
                                    var temp = index % 2;
                                    if (temp === 0) {
                                      return but, obj;
                                    }
                                  }}
                                />
                                <Column
                                  title="Match Percentile"
                                  key="mp"
                                  className="alignCenter"
                                  rowSpan="2"
                                  render={(text, record, index) => {
                                    var but_1 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="green">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    var but_2 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="orange">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );
                                    var but_3 = (
                                      <SRow gutter={24}>
                                        <Col span="8">
                                          <Tag color="purple">
                                            {record.threshold}
                                          </Tag>
                                        </Col>{" "}
                                      </SRow>
                                    );

                                    const obj_1 = {
                                      children: but_1,
                                      props: { rowSpan: 2 },
                                    };
                                    const obj_2 = {
                                      children: but_2,
                                      props: { rowSpan: 2 },
                                    };
                                    const obj_3 = {
                                      children: but_3,
                                      props: { rowSpan: 2 },
                                    };

                                    var temp = index % 2;
                                    if (temp === 0) {
                                      //  if(!record.threshold){
                                      //  return but_1, obj_1
                                      // }
                                      if (
                                        record.threshold === "95%" ||
                                        record.threshold === "90%"
                                      ) {
                                        return but_1, obj_1;
                                      }
                                      if (
                                        record.threshold === "85%" ||
                                        record.threshold === "80%"
                                      ) {
                                        return but_2, obj_2;
                                      }
                                      if (record.threshold === "75%") {
                                        return but_3, obj_3;
                                      }
                                    }
                                  }}
                                />
                              </Table>
                            </Modal>
                          </div>
                        );
                      }
                    }}
                  />
                )}
              </Table>
            </Col>
          </SRow>
          <OTPForm
            wrappedComponentRef={props.saveFormRef}
            visible={props.requestOTP}
            onSubmit={props.handleOTPSubmit}
            onCancel={props.handleCancel}
          />
          <Modal
            title="Advanced Reconcilation"
            okText="Reconcile"
            width="34%"
            visible={props.showModalPrompt}
            onOk={props.compareSales}
            okButtonProps={{ type: "success" }}
            onCancel={props.handleCancelPrompt}
          >
            <div>
              <Row>
                <style>
                  {`
                    .ant-checkbox-group-item {
                      display:block;
                    }
                  `}
                </style>
                {/*<Row span={24}>
                <Col span={8}><label htmlFor="year">Financial Year</label></Col>
                <Col span={16}>
                <select value={props.finYear} style={{ width: '95px' }} id="year" name="year" onChange={props.handleYearChange} onLoad={props.handleYearChange}>
                  <option value="2017-18">2017-18</option>
                  <option value="2018-19">2018-19</option>
                  <option value="2019-20">2019-20</option>
                  <option value="2020-21">2020-21</option>
                </select>
                </Col>
                </Row>
                <Row span={24} style={{ marginTop: '10px' }}>
                  <Col span="8"><label htmlFor="financialMonth">Return Filing Period</label></Col>
                  <Col span="7">
                    <select value={props.finMonth} style={{ width: '110px'}} id="financialMonthFrom" name="financialMonthFrom" onChange={props.handleMonthChange} onLoad={props.handleMonthChange}>
                      <option value='1'>January '{props.finYear.split('-')[1]}</option>
                      <option value='2'>Feburary '{props.finYear.split('-')[1]}</option>
                      <option value='3'>March '{props.finYear.split('-')[1]}</option>
                      <option value='4'>April '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='5'>May '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='6'>June '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='7'>July '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='8'>August '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='9'>September '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='10'>October '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='11'>November '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='12'>December '{props.finYear.split('-')[0].substring(2,4)}</option>
                    </select>
                  </Col>
                  <Col span="2" ><label htmlFor="toField">to</label></Col>
                  <Col span="7">
                    <select value={props.finMonth} style={{ width: '110px'}} id="financialMonthTo" name="financialMonthTo" onChange={props.handleMonthChange} onLoad={props.handleMonthChange}>
                      <option value='1'>January '{props.finYear.split('-')[1]}</option>
                      <option value='2'>Feburary '{props.finYear.split('-')[1]}</option>
                      <option value='3'>March '{props.finYear.split('-')[1]}</option>
                      <option value='4'>April '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='5'>May '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='6'>June '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='7'>July '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='8'>August '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='9'>September '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='10'>October '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='11'>November '{props.finYear.split('-')[0].substring(2,4)}</option>
                      <option value='12'>December '{props.finYear.split('-')[0].substring(2,4)}</option>
                    </select>
                  </Col>
                </Row>*/}
                <div style={{ marginTop: "10px" }}>
                  <Row>
                    <Col span={8}>
                      <p>
                        <b>Financial Year</b>
                      </p>
                      <Select
                        defaultValue={props.recon_year}
                        style={{ width: 120, marginTop: "10px" }}
                        onChange={props.handleYearChangeRecon}
                      >
                        <Select.Option value="2017-18">2017-18</Select.Option>
                        <Select.Option value="2018-19">2018-19</Select.Option>
                        <Select.Option value="2019-20">2019-20</Select.Option>
                        <Select.Option value="2020-21" disabled>
                          2020-21
                        </Select.Option>
                      </Select>
                    </Col>
                    <Col span={16}>
                      <p>
                        <b>Return Filing Period</b>
                      </p>
                      <Row>
                        <Col span={11}>
                          <Select
                            id="fromMonth"
                            value={props.recon_from_month.toString()}
                            style={{ marginTop: "10px" }}
                            onSelect={props.handleFromMonthChangeRecon}
                          >
                            <Select.Option value="4">
                              April '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="5">
                              May '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="6">
                              June '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="7">
                              July '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="8">
                              August '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="9">
                              September '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="10">
                              October '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="11">
                              November '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="12">
                              December '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="1">
                              January '{props.recon_year.split("-")[1]}
                            </Select.Option>
                            <Select.Option value="2">
                              Feburary '{props.recon_year.split("-")[1]}
                            </Select.Option>
                            <Select.Option value="3">
                              March '{props.recon_year.split("-")[1]}
                            </Select.Option>
                          </Select>
                        </Col>
                        <Col span={2}>
                          <p style={{ marginTop: "14px", marginLeft: "-11px" }}>
                            To
                          </p>
                        </Col>
                        <Col span={11}>
                          <Select
                            id="toMonth"
                            value={props.recon_to_month.toString()}
                            style={{ marginTop: "10px" }}
                            onSelect={props.handleToMonthChangeRecon}
                          >
                            <Select.Option value="4">
                              April '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="5">
                              May '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="6">
                              June '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="7">
                              July '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="8">
                              August '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="9">
                              September '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="10">
                              October '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="11">
                              November '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="12">
                              December '
                              {props.recon_year.split("-")[0].substring(2, 4)}
                            </Select.Option>
                            <Select.Option value="1">
                              January '{props.recon_year.split("-")[1]}
                            </Select.Option>
                            <Select.Option value="2">
                              Feburary '{props.recon_year.split("-")[1]}
                            </Select.Option>
                            <Select.Option value="3">
                              March '{props.recon_year.split("-")[1]}
                            </Select.Option>
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  <Col span={12}>
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <p style={{ textAlign: "left" }}>
                        <b>B2B</b>
                      </p>
                      <br />
                      <Checkbox
                        indeterminate={props.indeterminate_b2b}
                        onChange={props.onCheckAllChange_b2b}
                        checked={props.checkAll_b2b}
                      >
                        Select all
                      </Checkbox>

                      <br />
                      <br />
                      <CheckboxGroup
                        options={plainOptions_b2b}
                        value={props.checkedList_b2b}
                        onChange={props.onChangeCB_b2b}
                      >
                        <Checkbox value="Invoice Date">Invoice Date</Checkbox>
                        <Checkbox value="Invoice Value">Invoice Value</Checkbox>
                        <Checkbox value="Taxable Value">Taxable Value</Checkbox>
                        <Checkbox value="Taxes (IGST, CGST, SGST, CESS)">
                          Taxes (IGST, CGST, SGST, CESS)
                        </Checkbox>
                      </CheckboxGroup>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <p style={{ textAlign: "left" }}>
                        <b>CDNR</b>
                      </p>
                      <br />
                      <Checkbox
                        indeterminate={props.indeterminate_cdnr}
                        onChange={props.onCheckAllChange_cdnr}
                        checked={props.checkAll_cdnr}
                      >
                        Select all
                      </Checkbox>

                      <br />
                      <br />
                      <CheckboxGroup
                        options={plainOptions_cdnr}
                        value={props.checkedList_cdnr}
                        onChange={props.onChangeCB_cdnr}
                      >
                        <Checkbox value="CDNR Value">CDNR Value</Checkbox>
                        <Checkbox value="CDNR Date">CDNR Date</Checkbox>
                        <Checkbox value="Taxable Value">Taxable Value</Checkbox>
                        <Checkbox value="Taxes (IGST, CGST, SGST, CESS)">
                          Taxes (IGST, CGST, SGST, CESS)
                        </Checkbox>
                      </CheckboxGroup>
                    </div>
                  </Col>
                </div>
              </Row>
              <br />
              <p>
                <b>Roundoff</b>
              </p>
              <br />
              <div>
                <Row>
                  <Col>
                    <Radio.Group
                      defaultValue="0"
                      buttonStyle="solid"
                      onChange={(e) => props.onRoundOffChange(e)}
                    >
                      <Radio.Button value="0">Exact</Radio.Button>
                      <Radio.Button value="1">Roundoff to ₹ 1</Radio.Button>
                      <Radio.Button value="n">Enter Value</Radio.Button>
                    </Radio.Group>
                    <InputNumber
                      id="roundOffInput"
                      style={{ marginLeft: "5px" }}
                      disabled={props.disableRoundOffInput}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>

          {/* Mismatch B2B */}

          <Modal
            title={`Notify for GSTN (${props.mailPayload &&
              props.mailPayload[0].supplier_gstin})`}
            okText="Send Mail"
            width="45%"
            destroyOnClose={true}
            visible={props.showModalMail_Mismatch}
            onOk={props.notifyViaEmailSend}
            okButtonProps={{ backgroundColor: "#8a241c" }}
            onCancel={props.handleCancelEmail}
          >
            <div>
              {/*<p>Enter To Mail (Maximum 3 Mail-ID)</p>*/}
              <div>
                {props.emailTags &&
                  props.emailTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        afterClose={() => props.handleCloseEmail(tag)}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                {props.inputVisibleEmail && (
                  <Input
                    ref={props.saveInputRefEmail}
                    type="text"
                    size="small"
                    style={{ width: 300 }}
                    value={props.inputValueEmail}
                    onChange={props.handleInputChangeEmail}
                    onBlur={props.handleInputConfirmEmail}
                    onPressEnter={props.handleInputConfirmEmail}
                  />
                )}
                {!props.inputVisibleEmail &&
                  props.emailTags &&
                  props.emailTags.length !== 5 && (
                    <Tag
                      onClick={props.showInputEmail}
                      style={{ background: "#fff", borderStyle: "dashed" }}
                    >
                      <Icon type="plus" /> Add MailID
                    </Tag>
                  )}
              </div>
              <br />
              {/*
            <Input
              style={{ marginTop: "5px" }}
              id="mailIDcc"
              placeholder="Enter CC Mail ID"
            />
            <br />
            <Input
              style={{ marginTop: "5px" }}
              id="mailID_ReplyTo"
              placeholder="Receive Replies in Mail ID"
            />
            <br /> */}
              <TextArea
                style={{ marginTop: "10px" }}
                id="mailMessage"
                rows={5}
                placeholder="Message"
                defaultValue={props.mailTextBox}
                // value={props.mailTextBox}
              />
              {/* <br />
            <br />
            <Tag>{props.mailPayload && props.mailPayload.invoice_number}</Tag> */}
              <br />
              <br />
              {props.invoiceTags &&
                props.invoiceTags.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={index !== 0}
                      afterClose={() => props.handleCloseInvoiceTags(tag)}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

              <br />

              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#255c83",
                  color: "white",
                }}
                // type="primary"
                onClick={() => props.mailReportDownload(props.mailPayload, 1)}
              >
                Attached Reconciliation Report
              </Button>
              <Button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#06560e",
                  color: "white",
                }}
                // type="success"
                onClick={() => props.addSimilar(props.mailPayload)}
              >
                Add other records from this GSTN
              </Button>
              <br />
              <br />
              <p>
                You will receive replies @{" "}
                <Tag>{props.userMailID && props.userMailID.email}</Tag>
              </p>
            </div>
          </Modal>

          {/* Mismatch CDNR */}

          <Modal
            title={`Notify for GSTN (${props.mailPayload &&
              props.mailPayload[0].supplier_gstin})`}
            okText="Send Mail"
            width="45%"
            destroyOnClose={true}
            visible={props.showModalMail_Mismatch_CDNR}
            onOk={props.notifyViaEmailSend}
            okButtonProps={{ backgroundColor: "#8a241c" }}
            onCancel={props.handleCancelEmail}
          >
            <div>
              {/*<p>Enter To Mail (Maximum 3 Mail-ID)</p>*/}
              <div>
                {props.emailTags &&
                  props.emailTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        afterClose={() => props.handleCloseEmail(tag)}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                {props.inputVisibleEmail && (
                  <Input
                    ref={props.saveInputRefEmail}
                    type="text"
                    size="small"
                    style={{ width: 300 }}
                    value={props.inputValueEmail}
                    onChange={props.handleInputChangeEmail}
                    onBlur={props.handleInputConfirmEmail}
                    onPressEnter={props.handleInputConfirmEmail}
                  />
                )}
                {!props.inputVisibleEmail &&
                  props.emailTags &&
                  props.emailTags.length !== 5 && (
                    <Tag
                      onClick={props.showInputEmail}
                      style={{ background: "#fff", borderStyle: "dashed" }}
                    >
                      <Icon type="plus" /> Add MailID
                    </Tag>
                  )}
              </div>
              <br />
              {/*
            <Input
              style={{ marginTop: "5px" }}
              id="mailIDcc"
              placeholder="Enter CC Mail ID"
            />
            <br />
            <Input
              style={{ marginTop: "5px" }}
              id="mailID_ReplyTo"
              placeholder="Receive Replies in Mail ID"
            />
            <br /> */}
              <TextArea
                style={{ marginTop: "10px" }}
                id="mailMessage"
                rows={5}
                placeholder="Message"
                defaultValue={props.mailTextBox}
                // value={props.mailTextBox}
              />
              {/* <br />
            <br />
            <Tag>{props.mailPayload && props.mailPayload.invoice_number}</Tag> */}
              <br />
              <br />
              {props.invoiceTags &&
                props.invoiceTags.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={index !== 0}
                      afterClose={() => props.handleCloseInvoiceTags(tag)}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

              <br />

              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#255c83",
                  color: "white",
                }}
                // type="primary"
                onClick={() => props.mailReportDownload(props.mailPayload, 1)}
              >
                Attached Reconciliation Report
              </Button>
              <Button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#06560e",
                  color: "white",
                }}
                // type="success"
                onClick={() => props.addSimilar(props.mailPayload)}
              >
                Add other records from this GSTN
              </Button>
              <br />
              <br />
              <p>
                You will receive replies @{" "}
                <Tag>{props.userMailID && props.userMailID.email}</Tag>
              </p>
            </div>
          </Modal>

          {/* onlyInPR B2B */}

          <Modal
            title={`Notify for GSTN (${props.mailPayload &&
              props.mailPayload[0].supplier_gstin})`}
            okText="Send Mail"
            width="45%"
            destroyOnClose={true}
            visible={props.showModalMail_PR}
            onOk={props.notifyViaEmailSend}
            onCancel={props.handleCancelEmail}
          >
            <div>
              <div>
                {props.emailTags &&
                  props.emailTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        afterClose={() => props.handleCloseEmail(tag)}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                {props.inputVisibleEmail && (
                  <Input
                    ref={props.saveInputRefEmail}
                    placeholder="To Mail"
                    type="text"
                    size="small"
                    style={{ width: 300 }}
                    value={props.inputValueEmail}
                    onChange={props.handleInputChangeEmail}
                    onBlur={props.handleInputConfirmEmail}
                    onPressEnter={props.handleInputConfirmEmail}
                  />
                )}
                {!props.inputVisibleEmail &&
                  props.emailTags &&
                  props.emailTags.length !== 5 && (
                    <Tag
                      onClick={props.showInputEmail}
                      style={{ background: "#fff", borderStyle: "dashed" }}
                    >
                      <Icon type="plus" /> Add MailID
                    </Tag>
                  )}
              </div>
              <br />
              {/*
            <Input
              style={{ marginTop: "5px" }}
              id="mailIDcc"
              placeholder="Enter CC Mail ID"
            />
            <br />
            <Input
              style={{ marginTop: "5px" }}
              id="mailID_ReplyTo"
              placeholder="Receive Replies in Mail ID"
            />
            <br /> */}
              <TextArea
                style={{ marginTop: "10px" }}
                id="mailMessage"
                rows={5}
                placeholder="Message"
                defaultValue={props.mailTextBox}
                // value={props.mailTextBox}
                // onChange={props.mailContentChange}
              />
              {/* <br />
            <br />
            <Tag>{props.mailPayload && props.mailPayload.invoice_number}</Tag> */}
              <br />
              <br />
              {props.invoiceTags &&
                props.invoiceTags.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={index !== 0}
                      afterClose={() => props.handleCloseInvoiceTags(tag)}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

              <br />

              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#255c83",
                  color: "white",
                }}
                // type="primary"
                onClick={() => props.mailReportDownload(props.mailPayload, 1)}
              >
                Attached Reconciliation Report
              </Button>
              <Button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#06560e",
                  color: "white",
                }}
                // type="success"
                onClick={() => props.addSimilar(props.mailPayload)}
              >
                Add other records from this GSTN
              </Button>
              <br />
              <br />
              <p>
                You will receive replies @{" "}
                <Tag>{props.userMailID && props.userMailID.email}</Tag>
              </p>
            </div>
          </Modal>

          {/* onlyInPR CDNR*/}

          <Modal
            title={`Notify for GSTN (${props.mailPayload &&
              props.mailPayload[0].supplier_gstin})`}
            okText="Send Mail"
            width="45%"
            destroyOnClose={true}
            visible={props.showModalMail_PR_CDNR}
            onOk={props.notifyViaEmailSend}
            okButtonProps={{ backgroundColor: "#8a241c" }}
            onCancel={props.handleCancelEmail}
          >
            <div>
              <div>
                {props.emailTags &&
                  props.emailTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        afterClose={() => props.handleCloseEmail(tag)}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                {props.inputVisibleEmail && (
                  <Input
                    ref={props.saveInputRefEmail}
                    placeholder="To Mail"
                    type="text"
                    size="small"
                    style={{ width: 300 }}
                    value={props.inputValueEmail}
                    onChange={props.handleInputChangeEmail}
                    onBlur={props.handleInputConfirmEmail}
                    onPressEnter={props.handleInputConfirmEmail}
                  />
                )}
                {!props.inputVisibleEmail &&
                  props.emailTags &&
                  props.emailTags.length !== 3 && (
                    <Tag
                      onClick={props.showInputEmail}
                      style={{ background: "#fff", borderStyle: "dashed" }}
                    >
                      <Icon type="plus" /> Add MailID
                    </Tag>
                  )}
              </div>
              <br />
              {/*
            <Input
              style={{ marginTop: "5px" }}
              id="mailIDcc"
              placeholder="Enter CC Mail ID"
            />
            <br />
            <Input
              style={{ marginTop: "5px" }}
              id="mailID_ReplyTo"
              placeholder="Receive Replies in Mail ID"
            />
            <br /> */}
              <TextArea
                style={{ marginTop: "10px" }}
                id="mailMessage"
                rows={5}
                placeholder="Message"
                defaultValue={props.mailTextBox}
                // value={props.mailTextBox}
              />
              {/* <br />
            <br />
            <Tag>{props.mailPayload && props.mailPayload.invoice_number}</Tag> */}
              <br />
              <br />
              {props.invoiceTags &&
                props.invoiceTags.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={index !== 0}
                      afterClose={() => props.handleCloseInvoiceTags(tag)}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

              <br />

              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#255c83",
                  color: "white",
                }}
                // type="primary"
                onClick={() => props.mailReportDownload(props.mailPayload, 1)}
              >
                Attached Reconciliation Report
              </Button>
              <Button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#06560e",
                  color: "white",
                }}
                // type="success"
                onClick={() => props.addSimilar(props.mailPayload)}
              >
                Add other records from this GSTN
              </Button>
              <br />
              <br />
              <p>
                You will receive replies @{" "}
                <Tag>{props.userMailID && props.userMailID.email}</Tag>
              </p>
            </div>
          </Modal>

          {/* Likely */}

          <Modal
            title={`Notify for GSTN (${props.mailPayload &&
              props.mailPayload[0].supplier_gstin})`}
            okText="Send Mail"
            width="45%"
            destroyOnClose={true}
            forceRender={true}
            visible={props.showModalMail_likely}
            onOk={props.notifyViaEmailSend}
            okButtonProps={{ backgroundColor: "#8a241c" }}
            onCancel={props.handleCancelEmail}
          >
            <div>
              <br />
              <div>
                {props.emailTags &&
                  props.emailTags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        afterClose={() => props.handleCloseEmail(tag)}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </Tag>
                    );
                    return isLongTag ? (
                      <Tooltip title={tag} key={tag}>
                        {tagElem}
                      </Tooltip>
                    ) : (
                      tagElem
                    );
                  })}
                {props.inputVisibleEmail && (
                  <Input
                    ref={props.saveInputRefEmail}
                    type="text"
                    size="small"
                    style={{ width: 300 }}
                    value={props.inputValueEmail}
                    onChange={props.handleInputChangeEmail}
                    onBlur={props.handleInputConfirmEmail}
                    onPressEnter={props.handleInputConfirmEmail}
                  />
                )}
                {!props.inputVisibleEmail &&
                  props.emailTags &&
                  props.emailTags.length !== 3 && (
                    <Tag
                      onClick={props.showInputEmail}
                      style={{ background: "#fff", borderStyle: "dashed" }}
                    >
                      <Icon type="plus" /> Add MailID
                    </Tag>
                  )}
              </div>
              <br />
              {/*
            <Input
              style={{ marginTop: "5px" }}
              id="mailIDcc"
              placeholder="Enter CC Mail ID"
            />
            <br />
            <Input
              style={{ marginTop: "5px" }}
              id="mailID_ReplyTo"
              placeholder="Receive Replies in Mail ID"
            />
            <br /> */}
              <TextArea
                style={{ marginTop: "10px" }}
                id="mailMessage"
                rows={5}
                placeholder="Message"
                defaultValue={props.mailTextBox}
                // value={props.mailTextBox}
              />
              {/* <br />
            <br />
            <Tag>{props.mailPayload && props.mailPayload.invoice_number}</Tag> */}
              <br />
              <br />
              {props.invoiceTags &&
                props.invoiceTags.map((tag, index) => {
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      key={tag}
                      closable={index !== 0}
                      afterClose={() => props.handleCloseInvoiceTags(tag)}
                    >
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}

              <br />

              <Button
                style={{
                  marginTop: "10px",
                  backgroundColor: "#255c83",
                  color: "white",
                }}
                // type="primary"
                onClick={() => props.mailReportDownload(props.mailPayload, 1)}
              >
                Attached Reconciliation Report
              </Button>
              <Button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  backgroundColor: "#06560e",
                  color: "white",
                }}
                // type="success"
                onClick={() => props.addSimilar(props.mailPayload)}
              >
                Add other records from this GSTN
              </Button>
              <br />
              <br />
              <p>
                You will receive replies @{" "}
                <Tag>{props.userMailID && props.userMailID.email}</Tag>
              </p>
            </div>
          </Modal>

          <Modal
            title="Supplier Data Only (2A)"
            visible={props.visibleCP_Modal}
            onCancel={props.hideCPModal}
            footer={null}
            width="96%"
          >
            <Table
              dataSource={props.CP_DATA}
              bordered
              // rowSelection={props.rowSelection}
              pagination={{
                className: "my-pagination",
                defaultPageSize: 6,
              }}
              rowKey="id"
              title={() => (
                <span style={{ marginLeft: 8 }}>
                  {props.hasSelected
                    ? `Selected ${props.selectedRowKeys.length} Invoices`
                    : ""}
                </span>
              )}
              footer={() => (
                <div>
                  <Button
                    icon="download"
                    style={{
                      backgroundColor: "#2382c7",
                      color: "white",
                    }}
                    onClick={() => props.fetchMissingNames()}
                  >
                    Fetch missing Supplier Name(s)
                  </Button>

                  <Button
                    icon="file-excel"
                    style={{
                      backgroundColor: "#2382c7",
                      color: "white",
                    }}
                    onClick={() => props.cpExcelDownload()}
                  >
                    Download as Excel
                  </Button>

                  <Popconfirm
                    placement="bottomRight"
                    onVisibleChange={props.popupConfirmResetVisibleChange_CP}
                    visible={props.popupConfirmReset_CP}
                    title="Clicking this button will reset all Supplier for mentioned Tax Period!"
                    onConfirm={props.resetCPData}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      icon="close-circle"
                      style={{
                        backgroundColor: "#923011",
                        color: "white",
                      }}
                    >
                      {"Reset Supplier data for " + props.finYear}
                    </Button>
                  </Popconfirm>
                </div>
              )}
            >
              <Column
                title="Invoice Number"
                dataIndex="invoice_number"
                className="alignCenter"
                key="invoice_number"
                filterDropdown={({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }) => (
                  <div className="custom-filter-dropdown">
                    <Input
                      placeholder="Search Invoice Number"
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={props.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                      type="primary"
                      onClick={props.handleSearch(selectedKeys, confirm)}
                    >
                      Search
                    </Button>
                    <Button
                      id="gstnInvoiceSearch_b2b_CP"
                      onClick={props.handleReset(clearFilters)}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                filterIcon={(filtered) => (
                  <Icon
                    type="search"
                    style={{
                      color: filtered ? "#108ee9" : "#aaa",
                    }}
                  />
                )}
                onFilter={(value, record) =>
                  record.invoice_number
                    .toLowerCase()
                    .includes(value.toLowerCase())
                }
                render={(text) => {
                  return props.searchText ? (
                    <span>
                      {text
                        .split(
                          new RegExp(
                            `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                            "i"
                          )
                        )
                        .map(
                          (fragment, i) =>
                            fragment.toLowerCase() ===
                            props.searchText.toLowerCase() ? (
                              <span key={i} className="highlight">
                                {fragment}
                              </span>
                            ) : (
                              fragment
                            ) // eslint-disable-line
                        )}
                    </span>
                  ) : (
                    text
                  );
                }}
              />
              <Column
                title="GSTIN"
                dataIndex="supplier_gstin"
                className="alignCenter"
                key="supplier_gstin"
                filterDropdown={({
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                }) => (
                  <div className="custom-filter-dropdown">
                    <Input
                      placeholder="Search GSTIN Number"
                      value={selectedKeys[0]}
                      onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                      }
                      onPressEnter={props.handleSearch(selectedKeys, confirm)}
                    />
                    <Button
                      type="primary"
                      onClick={props.handleSearch(selectedKeys, confirm)}
                    >
                      Search
                    </Button>
                    <Button
                      id="gstnSearch_recon_b2b_CP"
                      onClick={props.handleReset(clearFilters)}
                    >
                      Reset
                    </Button>
                  </div>
                )}
                filterIcon={(filtered) => (
                  <Icon
                    type="search"
                    style={{
                      color: filtered ? "#108ee9" : "#aaa",
                    }}
                  />
                )}
                onFilter={(value, record) =>
                  record.supplier_gstin
                    .toLowerCase()
                    .includes(value.toLowerCase())
                }
                render={(text) => {
                  return props.searchText ? (
                    <span>
                      {text
                        .split(
                          new RegExp(
                            `(${props.searchText})(?![^<]*>|[^<>]*</)`,
                            "i"
                          )
                        )
                        .map(
                          (fragment, i) =>
                            fragment.toLowerCase() ===
                            props.searchText.toLowerCase() ? (
                              <span key={i} className="highlight">
                                {fragment}
                              </span>
                            ) : (
                              fragment
                            ) // eslint-disable-line
                        )}
                    </span>
                  ) : (
                    text
                  );
                }}
              />
              <Column
                title="Supplier Name"
                dataIndex="supplier_name"
                className="alignCenter"
                key="supplier_name"
              />
              <Column
                title="Invoice Date"
                dataIndex="invoice_date"
                className="alignCenter"
                key="invoice_date"
                width="120"
                defaultSortOrder="descend"
                sorter={(a, b) =>
                  props.sortDate(a.invoice_date, b.invoice_date)
                }
                render={(text) =>
                  text ? moment(text, "DD-MM-YYYY").format("DD-MMM-YY") : ""
                }
              />
              <Column
                title="Invoice Value"
                dataIndex="invoice_value"
                className="alignCenter"
                key="invoice_value"
                width="130"
                render={(val) => (
                  <p>{props.formatNumber(val === null ? 0 : val)}</p>
                )}
              />
              <Column
                title="Invoice Type"
                dataIndex="invoice_type"
                className="alignCenter"
                key="invoice_type"
              />
              <Column
                title="POS"
                dataIndex="pos_id"
                key="pos_id"
                className="alignCenter"
                render={(pos) =>
                  find({ pos_id: pos + "" })(stateArray).state_name
                }
              />
              <Column
                title="Reverse Charge"
                dataIndex="reverse_charge"
                className="alignCenter"
                key="reverse_charge"
              />

              <Column
                title="Items"
                key="action"
                className="alignCenter"
                width="130"
                rowSpan="2"
                render={(text, record, index) => {
                  var but = (
                    <SRow gutter={24}>
                      <Button
                        icon="bars"
                        type="primary"
                        onClick={() => props.showItemModal(record)}
                      >
                        Items
                      </Button>
                      <Modal
                        title="List Items Summary - GST System Invoices (B2B)"
                        visible={
                          props.visibleItemModal === record.id ? true : false
                        }
                        onCancel={props.hideItemModal}
                        footer={null}
                        width="920"
                      >
                        <Table
                          dataSource={record.recon_pr_b2b_items}
                          bordered
                          loading={props.loading}
                          scroll={scroll}
                          pagination={{
                            className: "my-pagination",
                            defaultPageSize: 6,
                          }}
                        >
                          <Column
                            title="Rate"
                            dataIndex="gst_rate"
                            className="alignCenter"
                            key="gst_rate"
                          />
                          <Column
                            title="Taxable Value"
                            className="alignCenter"
                            dataIndex="taxable_value"
                            key="taxable_value"
                            render={(val) => (
                              <p>
                                {props.formatNumber(val === null ? 0 : val)}
                              </p>
                            )}
                          />
                          <Column
                            title="IGST"
                            dataIndex="igst"
                            className="alignCenter"
                            key="igst"
                            render={(val) => (
                              <p>
                                {val === null
                                  ? " - - - "
                                  : props.formatNumber(val)}
                              </p>
                            )}
                          />
                          <Column
                            title="CGST"
                            dataIndex="cgst"
                            className="alignCenter"
                            key="cgst"
                            render={(val) => (
                              <p>
                                {val === null
                                  ? " - - - "
                                  : props.formatNumber(val)}
                              </p>
                            )}
                          />
                          <Column
                            title="SGST"
                            dataIndex="sgst"
                            className="alignCenter"
                            key="sgst"
                            render={(val) => (
                              <p>
                                {val === null
                                  ? " - - - "
                                  : props.formatNumber(val)}
                              </p>
                            )}
                          />
                          <Column
                            title="Cess"
                            dataIndex="cess"
                            className="alignCenter"
                            key="cess"
                            render={(val) => (
                              <p>
                                {val === null
                                  ? " - - - "
                                  : props.formatNumber(val)}
                              </p>
                            )}
                          />
                        </Table>
                      </Modal>
                    </SRow>
                  );
                  return but;
                }}
              />
            </Table>
          </Modal>

          <script type="text/javascript" src="FileSaver.js" />
        </Content>
      </Root>
    );
  }
}

class ReconcileContainer extends React.Component {
  constructor(props) {
    super(props);
    var d = new Date();
    var n = d.getMonth();
    var y = d.getYear();
    var curyear = 1900 + y;
    var nextyear = curyear + 1 - 2000;
    var year = curyear + "-" + nextyear;
    // var rod = 0;
    if (props.gspDetails !== undefined && props.gspDetails !== null) {
      this.gsp = {
        access_token: props.gspDetails.gspToken,
        otp: props.gspDetails.otp,
        response: null,
        response_cdnr: null,
      };
    } else {
      this.gsp = {};
    }
    // this.downloadSales = this.downloadSales.bind(this);
    // this.takeGST = this.takeGST.bind(this);
    this.onDropPR = this.onDropPR.bind(this);
    this.onDropTallyPR = this.onDropTallyPR.bind(this);
    // this.mailContentChange = this.mailContentChange.bind(this);
    // this.keepPending = this.keepPending.bind(this);
    // this.GkeepPending = this.GkeepPending.bind(this);
    // this.PkeepPending = this.PkeepPending.bind(this);
    this.mailReportDownload = this.mailReportDownload.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.state = {
      visible2a: false,

      visibleModal: null,
      visibleItemModal: false,
      visibleMatchModal: null,
      visibleMatchItemModal: null,
      visibleOneModal: null,
      visibleOneItemModal: null,
      visibleTwoModal: null,
      visibleTwoItemModal: null,
      visibleLikelyModal: null,
      visibleLikelyItemModal: null,
      visibleIneligibleModal: null,
      visibleIneligibleItemModal: null,

      visibleModal_cdnr: null,
      visibleMatchModal_cdnr: null,
      visibleOneModal_cdnr: null,
      visibleTwoModal_cdnr: null,
      visibleLikelyModal_cdnr: null,
      visibleIneligibleModal_cdnr: null,
      visibleIneligibleItemModal_cdnr: null,

      finMonth: props.finMonth,
      finYear: props.finYear,
      curFinMonth: n + 1,
      curFinYear: year,
      companyId: props.selectedCompany,
      output_b2b: [],
      recon_mismatches_b2b: [],
      recon_matches_b2b: [],
      recon_one_b2b: [],
      recon_two_b2b: [],
      recon_likely_b2b: [],
      recon_ineligible_b2b: [],
      markedAsIneligible_cdnr: [],
      output_cdnr: [],
      recon_mismatches_cdnr: [],
      recon_matches_cdnr: [],
      recon_one_cdnr: [],
      recon_two_cdnr: [],
      recon_likely_cdnr: [],
      downloadLoading: false,
      resetLoading: false,
      takePurchase: null,
      recon_loading: false,
      sales_loading: false,
      gst_loading: false,
      pending_load: false,
      requestOTP: false,
      pull2A: false,
      otp: null,
      fromPeriod: null,
      toPeriod: null,
      fromPullData: null,
      searchText: "",
      run: false,
      fromReconcile: null,
      visible_notifyViaEmail: null,
      itcCounter_IGST: 0,
      itcCounter_CGST: 0,
      itcCounter_SGST: 0,
      itcCounter_CESS: 0,
      twoA_FinYear_Dependent_Value: 0,
      twoA_PrevFinYear_Dependent_Value: 0,
      threeB_FinYear_Dependent_Value: 0,
      threeB_Total_Value: 0,
      threeB_PrevFinYear_Dependent_Value: 0,
      prevYearTitle: "",
      currentYearTitle: "",
      prevFinYearThreeB: 0,
      currentFinYearThreeB: 0,
      balanceValues_PrevYear: 0,
      balanceValues_CurrentYear: 0,
      selectedRowKeys: [],
      noCreditTakenPrevYear: 0,
      RIC_Load: true,
      popupConfirmReset: false,
      popupConfirmReset_CP: false,
      checkedList_b2b: defaultCheckedList,
      indeterminate_b2b: true,
      checkAll_b2b: false,
      checkedList_cdnr: defaultCheckedList,
      indeterminate_cdnr: true,
      checkAll_cdnr: false,
      showModalPrompt: false,
      showModalMail_Mismatch: false,
      showModalMail_PR: false,
      showModalMail_Mismatch_CDNR: false,
      showModalMail_PR_CDNR: false,
      showModalMail_likely: false,
      roundoffState: 0,
      disableRoundOffInput: true,
      showB2B: true,
      showCDNR: true,
      mailText: "",
      mailPayload: null,
      invoiceTags: [],
      emailTags: [],
      inputVisibleEmail: false,
      inputValueEmail: "",
      CP_DATA: null,
      visibleCP_Modal: false,
      recon_year: props.finYear,
      recon_from_month: props.finMonth.toString(),
      recon_to_month: props.finMonth.toString(),
    };
    comp_id = props.selectedCompany;
  }

  //email

  handleCloseEmail = (removedTag) => {
    const tags = this.state.emailTags.filter((tag) => tag !== removedTag);
    console.log(tags);
    this.setState({ emailTags: tags });
  };

  showInputEmail = () => {
    this.setState({ inputVisibleEmail: true }, () => this.inputEmail.focus());
  };

  handleInputChangeEmail = (e) => {
    if (
      e.target.value.substr(-1) === "," ||
      e.target.value.substr(-1) === ";"
    ) {
      this.handleInputConfirmEmail();
    }
    this.setState({ inputValueEmail: e.target.value });
  };

  handleInputConfirmEmail = () => {
    const state = this.state;
    const inputValue = state.inputValueEmail;
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    let tags = state.emailTags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      if (reg.test(inputValue) === false) {
        message.warning("Invalid Email Address");
      } else {
        tags = [...tags, inputValue];
      }
    }
    console.log(tags);
    // if(this.state.emailTags && this.state.emailTags.length === 3){
    // this.setState({
    //   emailTags: tags,
    //   inputVisibleEmail: true,
    //   inputValueEmail: '',
    // });
    // }else{
    this.setState({
      emailTags: tags,
      inputVisibleEmail: false,
      inputValueEmail: "",
    });
    // }
  };

  saveInputRefEmail = (input) => (this.inputEmail = input);

  reconcilePrompt = () => {
    this.setState({
      showModalPrompt: true,
    });
  };

  onChangeCB_b2b = (checkedList_b2b) => {
    this.setState({
      checkedList_b2b,
      indeterminate_b2b:
        !!checkedList_b2b.length &&
        checkedList_b2b.length < plainOptions_b2b.length,
      checkAll_b2b: checkedList_b2b.length === plainOptions_b2b.length,
    });
  };

  onCheckAllChange_b2b = (e) => {
    this.setState({
      checkedList_b2b: e.target.checked ? plainOptions_b2b : [],
      indeterminate_b2b: false,
      checkAll_b2b: e.target.checked,
    });
  };

  onChangeCB_cdnr = (checkedList_cdnr) => {
    this.setState({
      checkedList_cdnr,
      indeterminate_cdnr:
        !!checkedList_cdnr.length &&
        checkedList_cdnr.length < plainOptions_cdnr.length,
      checkAll_cdnr: checkedList_cdnr.length === plainOptions_cdnr.length,
    });
  };

  onCheckAllChange_cdnr = (e) => {
    this.setState({
      checkedList_cdnr: e.target.checked ? plainOptions_cdnr : [],
      indeterminate_cdnr: false,
      checkAll_cdnr: e.target.checked,
    });
  };

  onRoundOffChange = (e) => {
    if (e.target.value === "n") {
      this.setState({
        roundoffState: e.target.value,
        disableRoundOffInput: false,
      });
    } else {
      this.setState({
        roundoffState: e.target.value,
        disableRoundOffInput: true,
      });
    }
  };

  handleOkPrompt = (e) => {
    // console.log(e);
    this.setState({
      showModalPrompt: false,
    });
  };

  handleCancelPrompt = (e) => {
    // console.log(e);
    this.setState({
      showModalPrompt: false,
    });
  };

  handleCancelEmail = (e) => {
    // console.log(e);

    if (this.state.visibleModal) {
      this.setState({
        showModalMail_Mismatch: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleOneModal) {
      this.setState({
        showModalMail_PR: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleModal_cdnr) {
      this.setState({
        showModalMail_Mismatch_CDNR: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleOneModal_cdnr) {
      this.setState({
        showModalMail_PR_CDNR: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
    if (this.state.visibleLikelyModal) {
      this.setState({
        showModalMail_likely: false,
        invoiceTags: [],
        emailTags: [],
      });
    }
  };

  handleSearch = (selectedKeys, confirm) => () => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => () => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidUpdate = () => {
    if (this.props.gspDetails !== undefined && this.props.gspDetails !== null) {
      this.gsp.access_token = this.props.gspDetails.gspToken;
      this.gsp.otp = this.props.gspDetails.otp;
    }
  };

  otherGSTNInformation = (companyDetails) => {
    const state_cd = companyDetails["gstin"].substr(0, 2);
    companyDetails["state"] = state_cd;
    return companyDetails;
  };

  periodArrayValues = (a, b) => {
    var dateStart = moment(a, "MMYYYY");
    var dateEnd = moment(b, "MMYYYY");
    // console.log("dateStart :" + dateStart + "---" + "dateEnd :" + dateEnd);
    var period = [];

    while (
      dateEnd > dateStart ||
      dateStart.format("M") === dateEnd.format("M")
    ) {
      period.push(dateStart.format("MMYYYY"));
      dateStart.add(1, "month");
    }
    // console.log(period);
    return period;
  };

  getCounterPartyData = async (fromPeriod, toPeriod) => {
    var year_trim = this.props.finYear.split("-");
    var fy = "";
    if (this.props.finMonth < 4) {
      fy = "20" + year_trim[1];
    } else {
      fy = year_trim[0];
    }

    var period = toString(this.props.finMonth).padStart(2, "0") + "" + fy;

    const companyDetails = flow(
      pick(["gstin", "id", "gstnUserName"]),
      this.otherGSTNInformation
    )(this.props.company);

    const periodArrayValuesfor2A = this.periodArrayValues(fromPeriod, toPeriod);

    // console.log(periodArrayValuesfor2A);

    const reqData = {
      companyDetails: companyDetails,
      gspDetails: {
        gspToken: this.gsp.access_token,
        otp: this.gsp.otp,
      },
      period: period,
    };

    // console.log(reqData);

    this.gsp.response = await gspAuth.generateLedgerBAL(reqData);

    // console.log(this.gsp.response);

    // message.destroy();

    if (!this.gsp.response.data.success || !this.gsp.response.success) {
      if (
        this.gsp.response.data.errorCode &&
        this.gsp.response.data.errorCode.toUpperCase() === "RETOTPREQUEST"
      ) {
        message.destroy();
        this.setState({
          requestOTP: true,
          fromPeriod: fromPeriod,
          toPeriod: toPeriod,
          fromPullData: true,
          fromReconcile: null,
        });
      }
    }

    if (this.gsp.response.data.success || this.gsp.response.success) {
      // console.log('Done Ledger');
      const gstr2A_B2B_Loop_Action = map(async (d) => {
        // console.log(d);
        const reqData = {
          companyDetails: companyDetails,
          gspDetails: {
            gspToken: this.gsp.response.gspAuth.access_token,
            otp: this.gsp.otp,
          },
          toPeriod: toPeriod,
          period: d,
        };
        // console.log(reqData);
        this.gsp.response = await gspAuth.getCounterPartyData(reqData, "B2B");
        // console.log(this.gsp.response);

        if (this.gsp.response.status === true) {
          message.destroy();
          message.loading("Fetching CDNR data from GSTN...", 0);

          const gstr2A_CDNR_Loop_Action = map(async (d) => {
            // console.log(d);
            const reqData = {
              companyDetails: companyDetails,
              gspDetails: {
                gspToken: this.gsp.access_token,
                otp: this.gsp.otp,
              },
              toPeriod: toPeriod,
              period: d,
            };

            this.gsp.response_cdnr = await gspAuth.getCounterPartyData(
              reqData,
              "CDN"
            );

            if (this.gsp.response_cdnr.status === true) {
              message.destroy();

              notification.open({
                message: "Success",
                description:
                  "Supplier Invoices fetched successfully!! You can now reconcile.",
                duration: 0,
                icon: <Icon type="check-circle" style={{ color: "#008000" }} />,
              });
            }
          })(periodArrayValuesfor2A);
        }
      })(periodArrayValuesfor2A);
    }
  };


  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      run: true,
    });
  };

  compareSales = async () => {
    if (
      this.state.checkedList_b2b.length === 0 &&
      this.state.checkedList_cdnr.length === 0
    ) {
      message.warning("Select atleast one field in B2B or CDNR to reconcile!");
    } else {
      if (this.state.checkedList_b2b.length === 0) {
        this.setState({
          showB2B: false,
          showCDNR: true,
        });
        // this.forceUpdate();
      }

      if (this.state.checkedList_cdnr.length === 0) {
        this.setState({
          showB2B: true,
          showCDNR: false,
        });
        // this.forceUpdate();
      }

      if (
        this.state.checkedList_b2b.length !== 0 &&
        this.state.checkedList_cdnr.length !== 0
      ) {
        this.setState({
          showB2B: true,
          showCDNR: true,
        });
        // this.forceUpdate();
      }

      // console.log(this.props.finToMonth);

      var downloaddate =
        this.state.recon_from_month + "/" + this.state.recon_year;
      var downloaddateTo =
        this.state.recon_to_month + "/" + this.state.recon_year;

      if (this.state.roundoffState === "n") {
        this.rod = document.getElementById("roundOffInput").value;

        if (
          this.rod === "" ||
          this.rod === undefined ||
          parseInt(this.rod) > parseInt(99999999999999) ||
          parseInt(this.rod) < 0
        ) {
          return message.warning("Enter valid value to Roundoff!");
        }

        if (typeof this.rod === "number" && this.rod % 1 === 0) {
          return message.warning("Enter valid value to Roundoff!");
        }
      } else {
        this.rod = this.state.roundoffState;
      }

      this.setState({
        showModalPrompt: false,
      });

      notification.destroy();
      message.loading("Reconciling...", 0);
      fetch(
        `${BASE_URL}/export/compareSales?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}&downloaddateTo=${downloaddateTo}&rod=${this.rod}&feildsToRecon_b2b=${this.state.checkedList_b2b}&feildsToRecon_cdnr=${this.state.checkedList_cdnr}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then(async (result) => {
          this.setState({
            output_b2b: result.final.Summary_B2B,
            recon_mismatches_b2b: result.final.MISMATCHES_b2b,
            recon_matches_b2b: result.final.MATCHES_b2b,
            recon_one_b2b: result.final.PR_b2b,
            recon_two_b2b: result.final.RECON_PR_b2b,
            recon_likely_b2b: result.final.LIKELY_b2b,
            recon_ineligible_b2b: result.final.markedAsIneligible_b2b,

            output_cdnr: result.final.Summary_CDNR,
            recon_mismatches_cdnr: result.final.MISMATCHES_cdnr,
            recon_matches_cdnr: result.final.MATCHES_cdnr,
            recon_one_cdnr: result.final.PR_cdnr,
            recon_two_cdnr: result.final.RECON_PR_cdnr,
            recon_likely_cdnr: result.final.LIKELY_cdnr,
            markedAsIneligible_cdnr: result.final.markedAsIneligible_cdnr,

            recon_loading: false,
          });
          document.getElementsByClassName(
            "ant-table-wrapper"
          )[0].style.display = "block";
          document.getElementsByClassName("showAlert")[0].style.display =
            "block";
          if (document.getElementsByClassName("showEmpty")[0]) {
            document.getElementsByClassName("showEmpty")[0].style.display =
              "none";
          }

          // document.getElementsByClassName("showITC-Counter")[0].style.display =
          //   "block";
          // document.getElementsByClassName("showITC-Counter")[1].style.display =
          //   "block";
          // this.finYearTitle();
          // await this.itcCounterValues(result.final.MATCHES, result.final.MISMATCHES, result.final.PR, result.final.RECON_PR);
          // await this.calcFY2Avs3B();
          message.destroy();
          message.success("Reconciled");
          this.props.data.refetch();
        });
      // await this.calcFY2Avs3B();
    }
  };

  // finYearTitle = () => {
  //   var prevYearTitle;``
  //   var currentYearTitle = "FY " + this.state.finYear;

  //   var tempYear1 = this.state.finYear.split("-")[0];
  //   var tempYear2 = this.state.finYear.split("-")[1];

  //   var tempYear3 = (parseInt(tempYear1) - 1).toString();
  //   var tempYear4 = (parseInt(tempYear2) - 1).toString();

  //   prevYearTitle = "FY " + tempYear3 + "-" + tempYear4;

  //   // console.log(prevYearTitle);
  //   // console.log(currentYearTitle);
  //   this.setState({
  //     prevYearTitle: prevYearTitle,
  //     currentYearTitle: currentYearTitle
  //   });
  // };

  // calcFY2Avs3B = () => {
  //   this.setState({ RIC_Load: true });

  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;

  //   fetch(
  //     `${BASE_URL}/export/calcFY2Avs3B?companyId=${
  //       this.state.companyId
  //     }&downloaddate=${downloaddate}`,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(result => {
  // console.log(result)
  // console.log("HELLO");
  //   var prevFinYearThreeBValue;
  //   var currentFinYearThreeBValue;
  //   var tempDifference;
  //   // tempDifference = (parseFloat(result.twoA_PrevFinYear_Dependent_Value) - parseFloat(result.threeB_PrevFinYear_Dependent_Value));
  //   const tempDifferenceCalc = mergeWith((objV, srcV) => {
  //     return parseFloat(objV) - parseFloat(srcV);
  //   }, result.twoA_PrevFinYear_Dependent_Value)(result.threeB_PrevFinYear_Dependent_Value)
  //   console.log(tempDifferenceCalc);
  //   tempDifference = (parseFloat(tempDifferenceCalc.igst) + parseFloat(tempDifferenceCalc.cgst) + parseFloat(tempDifferenceCalc.sgst) + parseFloat(tempDifferenceCalc.cess));
  //   console.log(tempDifference);
  //   var threeB_PrevFinYear_Dependent_Value_TOTAL = (parseFloat(result.threeB_PrevFinYear_Dependent_Value.igst) + parseFloat(result.threeB_PrevFinYear_Dependent_Value.cgst) + parseFloat(result.threeB_PrevFinYear_Dependent_Value.sgst) + parseFloat(result.threeB_PrevFinYear_Dependent_Value.cess));
  //   var threeB_FinYear_Dependent_Value_TOTAL = (parseFloat(result.threeB_FinYear_Dependent_Value.igst) + parseFloat(result.threeB_FinYear_Dependent_Value.cgst) + parseFloat(result.threeB_FinYear_Dependent_Value.sgst) + parseFloat(result.threeB_FinYear_Dependent_Value.cess));

  //   this.setState({
  //         currentFinYearThreeB: result.threeB_FinYear_Dependent_Value,
  //         prevFinYearThreeB: tempDifference
  //       });

  //   // if(tempDifference > 0){
  //   //   console.log("A-X is greater than ZERO");
  //   //   if((threeB_FinYear_Dependent_Value_TOTAL - tempDifference) > 0){
  //   //     console.log("D-(A-X) is greater than ZERO");

  //   //     prevFinYearThreeBValue = mergeWith((objV, srcV) => {
  //   //       return parseFloat(objV) + parseFloat(srcV);
  //   //     }, tempDifferenceCalc)(result.threeB_PrevFinYear_Dependent_Value)

  //   //     currentFinYearThreeBValue = mergeWith((objV, srcV) => {
  //   //       return parseFloat(srcV) - parseFloat(objV);
  //   //     }, tempDifferenceCalc)(result.threeB_FinYear_Dependent_Value)

  //   //     this.setState({
  //   //       prevFinYearThreeB : prevFinYearThreeBValue,
  //   //       currentFinYearThreeB: currentFinYearThreeBValue
  //   //     });
  //   //   }else{
  //   //     this.setState({
  //   //       currentFinYearThreeB: result.threeB_FinYear_Dependent_Value
  //   //     });
  //   //   }
  //   // }else{
  //   //   this.setState({
  //   //     currentFinYearThreeB: result.threeB_FinYear_Dependent_Value
  //   //   });
  //   // }

  //   if(this.state.prevYearTitle === 'FY 2016-17'){
  //     var currentYearbalanceValues = mergeWith((objV, srcV) => {
  //     return parseFloat(srcV) - parseFloat(objV);
  //   }, result.threeB_FinYear_Dependent_Value)(result.twoA_FinYear_Dependent_Value)
  // }else{
  //   var prevYearbalanceValues = mergeWith((objV, srcV) => {
  //     return parseFloat(srcV) - parseFloat(objV);
  //   }, result.threeB_PrevFinYear_Dependent_Value)(result.twoA_PrevFinYear_Dependent_Value)

  //   // var currentYearbalanceValues = mergeWith((objV, srcV) => {
  //   //   return parseFloat(srcV) + parseFloat(objV);
  //   // }, result.twoA_FinYear_Dependent_Value)(prevYearbalanceValues)

  //   var currentYearbalanceValues = mergeWith((objV, srcV) => {
  //     return parseFloat(srcV) - parseFloat(objV);
  //   }, result.threeB_FinYear_Dependent_Value)(result.twoA_FinYear_Dependent_Value)
  // }

  //   // var currentYearbalanceValues = mergeWith((objV, srcV) => {
  //   //   return parseFloat(srcV) - parseFloat(objV);
  //   // }, result.threeB_FinYear_Dependent_Value)(result.twoA_FinYear_Dependent_Value)

  //   // var balanceValues = {
  //   //   prevYear: prevYearbalanceValues,
  //   //   currentYear: currentYearbalanceValues
  //   // }
  //       this.setState({
  //         twoA_FinYear_Dependent_Value: result.twoA_FinYear_Dependent_Value,
  //         twoA_PrevFinYear_Dependent_Value:
  //           result.twoA_PrevFinYear_Dependent_Value,
  //         threeB_FinYear_Dependent_Value: result.threeB_FinYear_Dependent_Value,
  //         threeB_PrevFinYear_Dependent_Value:
  //           result.threeB_PrevFinYear_Dependent_Value,
  //         threeB_Total_Value: result.threeB_Total_Value
  //         // balanceValues_PrevYear: prevYearbalanceValues,
  //         // balanceValues_CurrentYear: currentYearbalanceValues,
  //         // noCreditTakenPrevYear: tempDifference,
  //       });
  //     });

  //   this.setState({ RIC_Load: false });
  // };

  // itcCounterValues = async (match, mismatch, pr, gst) => {

  //   var idARRAY = [];

  //   const takeCreditAllMatch = map(
  //      d => {
  //       idARRAY.push(d.id)
  //        return {
  //         ...d,
  //         CT : 'Y',
  //         ret_period_id: 'Y'
  //      }
  //      }
  //   )(match)

  //   // console.log(idARRAY);
  //   this.setState({ recon_matches: takeCreditAllMatch });

  //   const addCredit = await this.updateDB(idARRAY, 'P_TC');

  //   await this.calcFY2Avs3B();

  //   setTimeout(() => {
  //     this.setState({ RIC_Load: false });
  //   }, 500);

  //   // const match_igst = flow(
  //   //                     flatMap(get('pr_b2b_items')),
  //   //                     reduce((curr, next) => {
  //   //                       curr = parseFloat(curr) + parseFloat(next.igst ? next.igst : 0, 2);
  //   //                       var temp = parseFloat(curr);
  //   //                       temp = parseFloat(temp).toFixed(2);
  //   //                       return(temp)}, 0))(match)
  //   //
  //   // const match_cgst = flow(
  //   //                     flatMap(get('pr_b2b_items')),
  //   //                     reduce((curr, next) => {
  //   //                       curr = parseFloat(curr) + parseFloat(next.cgst ? next.cgst : 0, 2);
  //   //                       var temp = parseFloat(curr);
  //   //                       temp = parseFloat(temp).toFixed(2);
  //   //                       return(temp)}, 0))(match)
  //   //
  //   // const match_sgst = flow(
  //   //                     flatMap(get('pr_b2b_items')),
  //   //                     reduce((curr, next) => {
  //   //                       curr = parseFloat(curr) + parseFloat(next.sgst ? next.sgst : 0, 2);
  //   //                       var temp = parseFloat(curr);
  //   //                       temp = parseFloat(temp).toFixed(2);
  //   //                       return(temp)}, 0))(match)
  //   //
  //   // const match_cess = flow(
  //   //                     flatMap(get('pr_b2b_items')),
  //   //                     reduce((curr, next) => {
  //   //                       curr = parseFloat(curr) + parseFloat(next.cess ? next.cess : 0, 2);
  //   //                       var temp = parseFloat(curr);
  //   //                       temp = parseFloat(temp).toFixed(2);
  //   //                       return(temp)}, 0))(match)
  //   //
  //   // console.log(match_igst);
  //   // console.log(match_cgst);
  //   // console.log(match_sgst);
  //   // console.log(match_cess);
  //   //
  //   // var itc_igst = match_igst;
  //   // var itc_cgst = match_cgst;
  //   // var itc_sgst = match_sgst;
  //   // var itc_cess = match_cess;
  //   //
  //   // this.setState({
  //   //   itcCounter_IGST: itc_igst,
  //   //   itcCounter_CGST: itc_cgst,
  //   //   itcCounter_SGST: itc_sgst,
  //   //   itcCounter_CESS: itc_cess,
  //   // });
  // }

  // updateDB = (record, action) => {
  //   console.log(record);

  //   console.log(action);

  //   var idJSON = {};
  //   idJSON['id'] = record

  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;

  //   return fetch(
  //     `${BASE_URL}/export/calcFY2Avs3BUpdateDB?companyId=${this.state.companyId}&downloaddate=${downloaddate}&action=${action}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(record)
  //     }
  //   )

  // }

  // availMatchCredit = async () => {

  //   const mapRecords = map(
  //    async d => {
  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_matches));

  //           if(findINDEX !== -1){
  //           this.state.recon_matches[findINDEX]['CT'] = 'Y'
  //           this.state.recon_matches[findINDEX]['ret_period_id'] = 'Y'

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   const addCredit = await this.updateDB(this.state.selectedRowKeys, 'P_TC');

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();

  //   message.success('Credit Added Successfully',1)

  // }

  // removeMatchCredit = async () => {

  //   const mapRecords = map(
  //    async d => {
  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_matches));

  //           if(findINDEX !== -1){
  //           this.state.recon_matches[findINDEX]['CT'] = 'N'
  //           this.state.recon_matches[findINDEX]['ret_period_id'] = 'N'

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   const removeCredit = await this.updateDB(this.state.selectedRowKeys, 'P_RC');

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();

  //   message.success('Credit Removed Successfully',1)
  // }

  // availPRCredit = async () => {

  //   const mapRecords = map(
  //     d => {
  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_one));

  //           if(findINDEX !== -1){
  //           this.state.recon_one[findINDEX]['CT'] = 'Y'
  //           this.state.recon_one[findINDEX]['ret_period_id'] = 'Y'

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   const addCredit = await this.updateDB(this.state.selectedRowKeys, 'P_TC');

  //   console.log(addCredit);

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();
  //   console.log();
  // }

  // removePRCredit = async () => {

  //   const mapRecords = map(
  //     d => {
  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_one));

  //           if(findINDEX !== -1){
  //           this.state.recon_one[findINDEX]['CT'] = 'N'
  //           this.state.recon_one[findINDEX]['ret_period_id'] = 'N'

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   const removeCredit = await this.updateDB(this.state.selectedRowKeys, 'P_RC');

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();
  // }

  // handleGSTN_CREDIT = async () => {

  //   const mapRecords = map(
  //    async d => {

  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_two));

  //           if(findINDEX !== -1){
  //           this.state.recon_two[findINDEX]['CT'] = 'Y'
  //           this.state.recon_two[findINDEX]['ret_period_id'] = 'Y'

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   const addCredit = await this.updateDB(this.state.selectedRowKeys, 'R_TC');

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();

  //   message.success('Credit Added Successfully',1)

  // }

  // removeGSTNCredit = async () => {

  //   const mapRecords = map(
  //    async d => {
  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_two));

  //           if(findINDEX !== -1){
  //           this.state.recon_two[findINDEX]['CT'] = 'N'
  //           this.state.recon_two[findINDEX]['ret_period_id'] = 'N'

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   const removeCredit = await this.updateDB(this.state.selectedRowKeys, 'R_RC');

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();

  //   message.success('Credit Removed Successfully',1)

  // }

  // handleMismatchCreditSelect = async () => {

  //   var idARRAY_P = [];
  //   var idARRAY_G = [];
  //   var idARRAY_Warning = [];

  //   const mapRecords = map(
  //    async d => {

  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_mismatches));

  //           if(findINDEX !== -1){

  //           const filterAvailedArray = filter({ ret_period_id : 'Y' ,  invoice_number: this.state.recon_mismatches[findINDEX]['invoice_number'] })(this.state.recon_mismatches)
  //           console.log(filterAvailedArray);
  //           if(filterAvailedArray.length === 0){
  //           this.state.recon_mismatches[findINDEX]['CT'] = 'Y'
  //           this.state.recon_mismatches[findINDEX]['ret_period_id'] = 'Y'
  //              if(this.state.recon_mismatches[findINDEX]['type'] === 'prb2b'){
  //                 idARRAY_P.push(d);
  //              }else if(this.state.recon_mismatches[findINDEX]['type'] === 'reconprb2b'){
  //                 idARRAY_G.push(d);
  //              }

  //         }else {
  //           idARRAY_Warning = idARRAY_Warning.concat(filterAvailedArray)
  //         }
  //       }
  //     }
  //   )(this.state.selectedRowKeys)

  //   // const filterAvailedArray = filter({ ret_period_id : 'Y' ,  invoice_number: this.state.recon_mismatches[findINDEX]['invoice_number'] })(this.state.recon_mismatches);

  //   if(idARRAY_P.length !== 0){
  //      const addCredit_PR = await this.updateDB(idARRAY_P, 'P_TC');
  //   }else if(idARRAY_G.length !== 0){
  //      const addCredit_GSTN = await this.updateDB(idARRAY_G, 'R_TC');
  //   }

  //   // const addCredit = await this.updateDB(this.state.selectedRowKeys, 'R_TC');

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();

  //   console.log(idARRAY_Warning);

  //   if(idARRAY_Warning.length === 0){

  //     message.success('Credit Added Successfully',1)

  //   }else if(idARRAY_Warning.length > 0){

  //     const idARRAY_WarningList = map(d => {
  //         return pick(['invoice_number'])(d).invoice_number;
  //       })(idARRAY_Warning);

  //     message.warning(`Either Purchase Register or GSTN record can be added as credit for Invoice No/Nos: ${idARRAY_WarningList.toString()}`,2)
  //   }

  //   // console.log(misMatch);

  //   // console.log(this.state.recon_mismatches);

  //   // const findINDEX = (findIndex(g => misMatch.id === g.id, this.state.recon_mismatches));

  //   // const stateChange = this.state.recon_mismatches;

  //   // const filterAvailedArray = filter({ ret_period_id : 'Y' ,  invoice_number: misMatch.invoice_number })(this.state.recon_mismatches);

  //   // if(filterAvailedArray.length === 0){
  //   //   console.log(filterAvailedArray);

  //   //   stateChange[findINDEX]['CT'] = 'Y';
  //   //   stateChange[findINDEX]['ret_period_id'] = 'Y';

  //   //   this.setState({ recon_mismatches: stateChange});

  //   //   this.calcFY2Avs3B();

  //   //   if(misMatch.type === 'prb2b'){
  //   //     this.updateDB(misMatch, 'P_TC');
  //   //   }else if(misMatch.type === 'reconprb2b'){
  //   //     this.updateDB(misMatch, 'R_TC');
  //   //   }

  //   //   const recordTYPE = misMatch.type === 'prb2b' ? 'Purchase Register' : 'GSTN';

  //   //   message.success(`Credit Added Successfully for Invoice No: ${misMatch.invoice_number} from ${recordTYPE}`,2)
  //   // }else if(filterAvailedArray.length > 0){
  //   //   message.warning(`Either Purchase Register or GSTN record can be added as credit for Invoice No: ${misMatch.invoice_number}`,2)
  //   // }
  // }

  // removeMisMatchCredit = async () => {

  //   var idARRAY_P = [];
  //   var idARRAY_G = [];

  //   const mapRecords = map(
  //    async d => {

  //           const findINDEX = (findIndex(g => g.id === d, this.state.recon_mismatches));

  //           if(findINDEX !== -1){
  //           this.state.recon_mismatches[findINDEX]['CT'] = 'N'
  //           this.state.recon_mismatches[findINDEX]['ret_period_id'] = 'N'
  //              if(this.state.recon_mismatches[findINDEX]['type'] === 'prb2b'){
  //                 idARRAY_P.push(d);
  //              }else if(this.state.recon_mismatches[findINDEX]['type'] === 'reconprb2b'){
  //                 idARRAY_G.push(d);
  //              }

  //         }
  //     }
  //   )(this.state.selectedRowKeys)

  //   if(idARRAY_P.length !== 0){
  //      const addCredit = await this.updateDB(idARRAY_P, 'P_RC');
  //   }else if(idARRAY_G.length !== 0){
  //      const addCredit = await this.updateDB(idARRAY_G, 'R_RC');
  //   }

  //   this.forceUpdate();

  //   await this.calcFY2Avs3B();

  //   message.success('Credit Removed Successfully',1)

  //   // console.log(misMatch);

  //   // console.log(this.state.recon_mismatches);

  //   // const findINDEX = (findIndex(g => misMatch.id === g.id, this.state.recon_mismatches));

  //   // const stateChange = this.state.recon_mismatches;

  //   // stateChange[findINDEX]['CT'] = 'N';
  //   // stateChange[findINDEX]['ret_period_id'] = 'N';

  //   // this.setState({ recon_mismatches: stateChange});

  //   // this.calcFY2Avs3B();

  //   // if(misMatch.type === 'prb2b'){
  //   //   this.updateDB(misMatch, 'P_RC');
  //   // }else if(misMatch.type === 'reconprb2b'){
  //   //   this.updateDB(misMatch, 'R_RC');
  //   // }

  //   // const recordTYPE = misMatch.type === 'prb2b' ? 'Purchase Register' : 'GSTN';

  //   // message.success(`Credit Removed Successfully for Invoice No: ${misMatch.invoice_number} from ${recordTYPE}`,2)

  // }

  handleMismatchIneligible = async () => {
    if (this.state.selectedRowKeys.length !== 0) {
      console.log("HELLO");

      // var idARRAY_P = [];
      // var idARRAY_G = [];
      var idARRAY_Ineligible = [];

      const mapRecords = map(async (d) => {
        const findINDEX = findIndex(
          (g) => g.id === d,
          this.state.recon_mismatches_b2b
        );

        if (findINDEX !== -1) {
          const filterAvailedArray = filter({
            invoice_number: this.state.recon_mismatches_b2b[findINDEX][
              "invoice_number"
            ],
          })(this.state.recon_mismatches_b2b);
          await idARRAY_Ineligible.push(filterAvailedArray);
          // console.log(filterAvailedArray);
          //   if(filterAvailedArray.length === 0){
          //   this.state.recon_mismatches[findINDEX]['CT'] = 'Y'
          //   this.state.recon_mismatches[findINDEX]['ret_period_id'] = 'Y'
          //      if(this.state.recon_mismatches[findINDEX]['type'] === 'prb2b'){
          //         idARRAY_P.push(d);
          //      }else if(this.state.recon_mismatches[findINDEX]['type'] === 'reconprb2b'){
          //         idARRAY_G.push(d);
          //      }

          // }else {
          //   idARRAY_Warning = idARRAY_Warning.concat(filterAvailedArray)
          // }
        }
      })(this.state.selectedRowKeys);

      const changeRecords = flow(
        filter((g) => {
          return (
            findIndex(
              (pr) =>
                pr.invoice_number === g.invoice_number &&
                pr.supplier_gstin === g.supplier_gstin,
              idARRAY_Ineligible[0]
            ) === -1
          );
        }),
        map((g) => {
          return {
            ...g,
          };
        })
      )(this.state.recon_mismatches_b2b);

      console.log(changeRecords);

      await this.updateIneligibleDB(idARRAY_Ineligible[0], "R_MI");

      message.destroy();

      message.success("Selected invoice(s) have been made ineligible!");

      this.setState({
        recon_mismatches_b2b: changeRecords,
        selectedRowKeys: [],
        recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
          idARRAY_Ineligible[0]
        ),
      });

      return this.forceUpdate();
    } else {
      message.error(
        "Please select atleast one mismatched invoice to mark it as ineligible!!"
      );
    }

    // // const filterAvailedArray = filter({ ret_period_id : 'Y' ,  invoice_number: this.state.recon_mismatches[findINDEX]['invoice_number'] })(this.state.recon_mismatches);

    // if(idARRAY_P.length !== 0){
    //    const addCredit_PR = await this.updateDB(idARRAY_P, 'P_TC');
    // }else if(idARRAY_G.length !== 0){
    //    const addCredit_GSTN = await this.updateDB(idARRAY_G, 'R_TC');
    // }

    // // const addCredit = await this.updateDB(this.state.selectedRowKeys, 'R_TC');

    // this.forceUpdate();

    // await this.calcFY2Avs3B();

    // console.log(idARRAY_Warning);

    // if(idARRAY_Warning.length === 0){

    //   message.success('Credit Added Successfully',1)

    // }else if(idARRAY_Warning.length > 0){

    //   const idARRAY_WarningList = map(d => {
    //       return pick(['invoice_number'])(d).invoice_number;
    //     })(idARRAY_Warning);

    //   message.warning(`Either Purchase Register or GSTN record can be added as credit for Invoice No/Nos: ${idARRAY_WarningList.toString()}`,2)
    // }

    // console.log(misMatch);

    // console.log(this.state.recon_mismatches);

    // const findINDEX = (findIndex(g => misMatch.id === g.id, this.state.recon_mismatches));

    // const stateChange = this.state.recon_mismatches;

    // const filterAvailedArray = filter({ ret_period_id : 'Y' ,  invoice_number: misMatch.invoice_number })(this.state.recon_mismatches);

    // if(filterAvailedArray.length === 0){
    //   console.log(filterAvailedArray);

    //   stateChange[findINDEX]['CT'] = 'Y';
    //   stateChange[findINDEX]['ret_period_id'] = 'Y';

    //   this.setState({ recon_mismatches: stateChange});

    //   this.calcFY2Avs3B();

    //   if(misMatch.type === 'prb2b'){
    //     this.updateDB(misMatch, 'P_TC');
    //   }else if(misMatch.type === 'reconprb2b'){
    //     this.updateDB(misMatch, 'R_TC');
    //   }

    //   const recordTYPE = misMatch.type === 'prb2b' ? 'Purchase Register' : 'GSTN';

    //   message.success(`Credit Added Successfully for Invoice No: ${misMatch.invoice_number} from ${recordTYPE}`,2)
    // }else if(filterAvailedArray.length > 0){
    //   message.warning(`Either Purchase Register or GSTN record can be added as credit for Invoice No: ${misMatch.invoice_number}`,2)
    // }
  };

  updateIneligibleDB = (record, action) => {
    console.log(record);

    console.log(action);

    var downloaddate = this.state.finMonth + "/" + this.state.finYear;

    return fetch(
      `${BASE_URL}/export/ineligibleUpdateDB?companyId=${this.state.companyId}&downloaddate=${downloaddate}&action=${action}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(record),
      }
    );
  };

  markAsIneligible = async () => {
    if (this.state.selectedRowKeys.length !== 0) {
      message.destroy();
      message.loading("Marking invoices as Ineligible....");

      var ineligibleArray = [];
      var eligibleArray = [];
      var mapRecords;
      var changeRecords;

      if (this.state.visibleTwoModal) {
        mapRecords = await map(async (d) => {
          const findINDEX = findIndex(
            (g) => g.id === d,
            this.state.recon_two_b2b
          );
          console.log("TEST");
          console.log(findINDEX);
          console.log("TEST");
          if (findINDEX !== -1) {
            console.log(this.state.recon_two_b2b[findINDEX]);
            await ineligibleArray.push(this.state.recon_two_b2b[findINDEX]);
          }
        })(this.state.selectedRowKeys);

        changeRecords = flow(
          filter((g) => {
            return (
              findIndex((pr) => pr === g.id, this.state.selectedRowKeys) === -1
            );
          }),
          map((g) => {
            return {
              ...g,
            };
          })
        )(this.state.recon_two_b2b);

        Promise.all(mapRecords).then(async () => {
          await this.updateIneligibleDB(ineligibleArray, "R_MI");

          message.destroy();

          message.success("Selected invoice(s) have been made ineligible!");

          this.setState({
            recon_two_b2b: changeRecords,
            selectedRowKeys: [],
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              ineligibleArray
            ),
          });

          return this.forceUpdate();
        });
      }

      if (this.state.visibleMatchModal) {
        mapRecords = await map(async (d) => {
          const findINDEX = findIndex(
            (g) => g.id === d,
            this.state.recon_matches_b2b
          );
          console.log("TEST");
          console.log(findINDEX);
          console.log("TEST");
          if (findINDEX !== -1) {
            console.log(this.state.recon_matches_b2b[findINDEX]);
            await ineligibleArray.push(this.state.recon_matches_b2b[findINDEX]);
          }
        })(this.state.selectedRowKeys);

        changeRecords = flow(
          filter((g) => {
            return (
              findIndex((pr) => pr === g.id, this.state.selectedRowKeys) === -1
            );
          }),
          map((g) => {
            return {
              ...g,
            };
          })
        )(this.state.recon_matches_b2b);

        Promise.all(mapRecords).then(async () => {
          await this.updateIneligibleDB(ineligibleArray, "R_MI");

          message.destroy();

          message.success("Selected invoice(s) have been made ineligible!");

          this.setState({
            recon_matches_b2b: changeRecords,
            selectedRowKeys: [],
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              ineligibleArray
            ),
          });

          return this.forceUpdate();
        });
      }

      if (this.state.visibleOneModal) {
        mapRecords = await map(async (d) => {
          const findINDEX = findIndex(
            (g) => g.id === d,
            this.state.recon_one_b2b
          );
          console.log("TEST");
          console.log(findINDEX);
          console.log("TEST");
          if (findINDEX !== -1) {
            console.log(this.state.recon_one_b2b[findINDEX]);
            await ineligibleArray.push(this.state.recon_one_b2b[findINDEX]);
          }
        })(this.state.selectedRowKeys);

        changeRecords = flow(
          filter((g) => {
            return (
              findIndex((pr) => pr === g.id, this.state.selectedRowKeys) === -1
            );
          }),
          map((g) => {
            return {
              ...g,
            };
          })
        )(this.state.recon_one_b2b);

        Promise.all(mapRecords).then(async () => {
          await this.updateIneligibleDB(ineligibleArray, "R_MI");

          message.destroy();

          message.success("Selected invoice(s) have been made ineligible!");

          this.setState({
            recon_one_b2b: changeRecords,
            selectedRowKeys: [],
            recon_ineligible_b2b: this.state.recon_ineligible_b2b.concat(
              ineligibleArray
            ),
          });

          return this.forceUpdate();
        });
      }
    } else {
      message.error(
        "Please select atleast one invoice to mark it as ineligible!!"
      );
    }
  };

  markAsEligible = async () => {
    if (this.state.selectedRowKeys.length !== 0) {
      message.destroy();
      message.loading("Marking invoices as Eligible....");

      console.log(this.state.selectedRowKeys);

      var ineligibleArray = [];
      var eligibleArray = [];

      const mapRecords = await map(async (d) => {
        const findINDEX = findIndex(
          (g) => g.id === d,
          this.state.recon_ineligible_b2b
        );
        if (findINDEX !== -1) {
          await eligibleArray.push(this.state.recon_ineligible_b2b[findINDEX]);
        }
      })(this.state.selectedRowKeys);

      const changeRecords = flow(
        filter((g) => {
          return (
            findIndex((pr) => pr === g.id, this.state.selectedRowKeys) === -1
          );
        }),
        map((g) => {
          return {
            ...g,
          };
        })
      )(this.state.recon_ineligible_b2b);

      Promise.all(mapRecords).then(async () => {
        await this.updateIneligibleDB(eligibleArray, "R_ME");

        message.destroy();

        message.success(
          "Selected invoice(s) have been made eligible and reconciling now...!"
        );

        this.setState({ visibleIneligibleModal: null, selectedRowKeys: [] });

        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "none";

        document.getElementsByClassName("showAlert")[0].style.display = "none";

        this.compareSales();
      });
    } else {
      message.error(
        "Please select atleast one invoice to mark it as eligible!!"
      );
    }
  };

  compareSalesFetchNames = async () => {
    var downloaddate = this.state.finMonth + "/" + this.state.finYear;
    fetch(
      `${BASE_URL}/export/compareSales?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}&rod=${this.rod}&feildsToRecon_b2b=${this.state.checkedList_b2b}&feildsToRecon_cdnr=${this.state.checkedList_cdnr}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          output_b2b: result.final.Summary_B2B,
          recon_mismatches_b2b: result.final.MISMATCHES_b2b,
          recon_matches_b2b: result.final.MATCHES_b2b,
          recon_one_b2b: result.final.PR_b2b,
          recon_two_b2b: result.final.RECON_PR_b2b,

          output_cdnr: result.final.Summary_CDNR,
          recon_mismatches_cdnr: result.final.MISMATCHES_cdnr,
          recon_matches_cdnr: result.final.MATCHES_cdnr,
          recon_one_cdnr: result.final.PR_cdnr,
          recon_two_cdnr: result.final.RECON_PR_cdnr,
          recon_loading: false,
        });
        document.getElementsByClassName("ant-table-wrapper")[0].style.display =
          "block";
        // document.getElementsByClassName("showITC-Counter")[0].style.display =
        //   "block";
        message.destroy();
        message.success("Fetched");
        this.props.data.refetch();
      });
  };

  // takePurchase = key => {
  //   this.setState({ sales_loading: true });
  //   this.props.data.refetch();
  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;
  //   var invoice_number = key.nativeEvent.target.dataset.value;
  //   var type = key.nativeEvent.target.dataset.id;
  //   var cdnr_number = key.nativeEvent.target.dataset.number;
  //   fetch(
  //     `${BASE_URL}/export/takePurchase?invoice_number=${invoice_number}&cdnr_number=${cdnr_number}&type=${type}&companyId=${
  //       this.state.companyId
  //     }&downloaddate=${downloaddate}`,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(result => {
  //       // console.log("DATA FROM BACKEND TAKE-PURCHASE");
  //       // console.log(result);
  //       this.setState({
  //         output_b2b: result.final.Summary_B2B,
  //         recon_mismatches_b2b: result.final.MISMATCHES_b2b,
  //         recon_matches_b2b: result.final.MATCHES_b2b,
  //         recon_one_b2b: result.final.PR_b2b,
  //         recon_two_b2b: result.final.RECON_PR_b2b,

  //         output_cdnr: result.final.Summary_CDNR,
  //         recon_mismatches_cdnr: result.final.MISMATCHES_cdnr,
  //         recon_matches_cdnr: result.final.MATCHES_cdnr,
  //         recon_one_cdnr: result.final.PR_cdnr,
  //         recon_two_cdnr: result.final.RECON_PR_cdnr,
  //         sales_loading: false
  //       });
  //       this.props.data.refetch();
  //     });
  // };

  // takeGST = key => {
  //   this.setState({ gst_loading: true });
  //   this.props.data.refetch();
  //   var type;
  //   if (key.nativeEvent.target.dataset.id === "prb2b") {
  //     type = "reconprb2b";
  //   } else {
  //     type = "reconprcdnr";
  //   }
  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;
  //   var invoice_number = key.nativeEvent.target.dataset.value;
  //   var cdnr_number = key.nativeEvent.target.dataset.number;
  //   fetch(
  //     `${BASE_URL}/export/takePurchaseGST?invoice_number=${invoice_number}&cdnr_number=${cdnr_number}&type=${type}&companyId=${
  //       this.state.companyId
  //     }&downloaddate=${downloaddate}`,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(result => {
  //       // console.log("DATA FROM BACKEND TAKE-GST");
  //       // console.log(result);
  //       this.setState({
  //         output: result.final.Summary,
  //         recon_mismatches: result.final.MISMATCHES,
  //         recon_matches: result.final.MATCHES,
  //         recon_one: result.final.PR,
  //         recon_two: result.final.RECON_PR,
  //         gst_loading: false
  //       });
  //       this.props.data.refetch();
  //     });
  // };

  // keepPending = key => {
  //   this.setState({ pending_load: true });
  //   this.props.data.refetch();
  //   var type;
  //   if (key.nativeEvent.target.dataset.id === "prb2b") {
  //     type = "prb2b-reconprb2b";
  //   } else if (key.nativeEvent.target.dataset.id === "prcdnr") {
  //     type = "prcdnr-reconprcdnr";
  //   }
  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;
  //   var invoice_number = key.nativeEvent.target.dataset.value;
  //   fetch(
  //     `${BASE_URL}/export/PurchasekeepPending?invoice_number=${invoice_number}&type=${type}&companyId=${
  //       this.state.companyId
  //     }&downloaddate=${downloaddate}`,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(result => {
  //       // console.log("DATA FROM BACKEND TAKE-GST");
  //       // console.log(result);
  //       this.setState({
  //         output: result.final.Summary,
  //         recon_mismatches: result.final.MISMATCHES,
  //         recon_matches: result.final.MATCHES,
  //         recon_one: result.final.PR,
  //         recon_two: result.final.RECON_PR,
  //         pending_load: false
  //       });
  //       message.success("Invoice Pended successfully", 5);
  //       this.props.data.refetch();
  //     });
  // };

  // PkeepPending = key => {
  //   this.setState({ pending_load: true });
  //   this.props.data.refetch();
  //   var type;
  //   if (key.nativeEvent.target.dataset.id === "prb2b") {
  //     type = "prb2b";
  //   } else if (key.nativeEvent.target.dataset.id === "prcdnr") {
  //     type = "prcdnr";
  //   }
  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;
  //   var invoice_number = key.nativeEvent.target.dataset.value;
  //   fetch(
  //     `${BASE_URL}/export/PurchasekeepPending?invoice_number=${invoice_number}&type=${type}&companyId=${
  //       this.state.companyId
  //     }&downloaddate=${downloaddate}`,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(result => {
  //       // console.log("DATA FROM BACKEND TAKE-GST");
  //       // console.log(result);
  //       this.setState({
  //         output: result.final.Summary,
  //         recon_mismatches: result.final.MISMATCHES,
  //         recon_matches: result.final.MATCHES,
  //         recon_one: result.final.PR,
  //         recon_two: result.final.RECON_PR,
  //         pending_load: false
  //       });
  //       message.success("Invoice Pended successfully", 5);
  //       this.props.data.refetch();
  //     });
  // };

  // GkeepPending = key => {
  //   var type;
  //   if (key.nativeEvent.target.dataset.id === "reconprb2b") {
  //     type = "reconprb2b";
  //   } else if (key.nativeEvent.target.dataset.id === "reconprcdnr") {
  //     type = "reconprcdnr";
  //   }
  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;
  //   var invoice_number = key.nativeEvent.target.dataset.value;
  //   fetch(
  //     `${BASE_URL}/export/PurchasekeepPending?invoice_number=${invoice_number}&type=${type}&companyId=${
  //       this.state.companyId
  //     }&downloaddate=${downloaddate}`,
  //     {
  //       method: "GET"
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(result => {
  //       // console.log("DATA FROM BACKEND TAKE-GST");
  //       // console.log(result);
  //       this.setState({
  //         output: result.final.Summary,
  //         recon_mismatches: result.final.MISMATCHES,
  //         recon_matches: result.final.MATCHES,
  //         recon_one: result.final.PR,
  //         recon_two: result.final.RECON_PR
  //       });
  //       message.success("Invoice Pended successfully", 5);
  //       this.props.data.refetch();
  //     });
  // };

  resetPurchase = () => {
    this.setState({ resetLoading: true });
    var downloaddate = this.state.finMonth + "/" + this.state.finYear;
    fetch(
      `${BASE_URL}/export/resetPurchase?from=purchase&companyId=${this.state.companyId}&downloaddate=${downloaddate}`,
      {
        method: "GET",
      }
    ).then((result) => {
      // console.log(result);
      this.setState({ resetLoading: false });
      this.props.data.refetch();
      message.success("All Purchases been reset for selected tax period");
    });
  };

  // downloadSales = () => {
  //   this.setState({ downloadLoading: true });
  //   var downloaddate = this.state.finMonth + "/" + this.state.finYear;
  //   window.location.href = `${BASE_URL}/export/json?from=purchase&companyId=${comp_id}&downloaddate=${downloaddate}`;
  //   let that = this;
  //   setTimeout(function() {
  //     that.props.data.refetch();
  //     that.setState({ downloadLoading: false });
  //   }, 7000);
  // };

  onDropPR = async (acceptedFiles) => {
    var el = document.body,
      elChild = document.createElement("div");
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById("root").classList.add("blur");
    const result = await PURCHASE.upload({
      acceptedFiles: "text/csv,application/vnd.ms-excel",
      file: acceptedFiles[0],
      type: "prall",
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    });
    document.body.getElementsByClassName("root_div")[0].remove();
    document.getElementById("root").classList.remove("blur");
    // console.log(result);
    if (result.error_status !== "" && result.error_status !== undefined) {
      document.getElementById("purchase_error_status").click();
      // console.log("-----------------ERROR REPORT----------------");
      // console.log(result.error_status);
      // console.log("---------------------------------------------");
    }
    if (result.filestatus === true) {
      if (result.flag === true) {
        var dialogue_msg =
          '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' +
          result.total_errors +
          " Invoices Failed out of " +
          result.total +
          " Invoices</b></span>) Purchase data upload Failed!</h4></p><br><p>Error Excel Will be Downloaded. Please look at the Error Excel to correct the Errors!</p></div>";
        var div = document.getElementById("dialogue_append");
        if (document.getElementById("dialogue_append") !== null) {
          div.innerHTML += dialogue_msg;
        }
      }
      if (result.total_errors > 0) {
        //message.error('(' + total_negative + ' Invoices Failed out of ' + result.total + ' Invoices) Purchase data upload Failed!', 12)
      } else {
        message.success(
          "(" +
            result.total +
            "/" +
            result.total +
            ") Purchase Invoices uploaded successfully!",
          5
        );
      }
      this.props.data.refetch();
    } else if (result.file === false) {
      message.error("Uploaded file is not in ElixirGST Excel format!", 5);
    } else {
      message.error("Upload File Type is Not Allowed", 5);
    }
  };

  onDropTallyPR = async (acceptedFiles) => {
    var el = document.body,
      elChild = document.createElement("div");
    elChild.className = "root_div";
    elChild.innerHTML = '<div class="custom_loading loader"></div>';
    el.insertBefore(elChild, el.firstChild);
    document.getElementById("root").classList.add("blur");
    const result = await PURCHASE.upload({
      acceptedFiles: "text/csv,application/vnd.ms-excel",
      file: acceptedFiles[0],
      type: "prall",
      tally: "true",
      companyId: this.props.selectedCompany,
      isSR: this.props.isSR,
      finMonth: this.state.finMonth,
      finYear: this.state.finYear,
    });
    document.body.getElementsByClassName("root_div")[0].remove();
    document.getElementById("root").classList.remove("blur");
    // console.log(result);
    if (result.error_status !== "" && result.error_status !== undefined) {
      document.getElementById("purchase_error_status").click();
      // console.log("-----------------ERROR REPORT----------------");
      // console.log(result.error_status);
      // console.log("---------------------------------------------");
    }
    if (result.filestatus === true) {
      if (result.flag === true) {
        var dialogue_msg =
          '<div style="width: 20%; float:left"><img src="https://media.giphy.com/media/mh0zdNnHBV7z2/giphy.gif"/ style="width:90%;border: solid 1px #d0d0d0;border-radius: 25px 0px 25px 0px;"></div ><div style="width: 80%;"><br><p><h4> ( <span><b style="font-size: 12px;color: #0cde26;">' +
          result.total_errors +
          " Invoices Failed out of " +
          result.total +
          " Invoices</b></span>) Purchase data upload Failed!</h4></p><br><p>Error Excel Will be Downloaded. Please look at the Error Excel to correct the Errors!</p></div>";
        var div = document.getElementById("dialogue_append");
        if (document.getElementById("dialogue_append") !== null) {
          div.innerHTML += dialogue_msg;
        }
      }
      if (result.total_errors > 0) {
        //message.error('(' + total_negative + ' Invoices Failed out of ' + result.total + ' Invoices) Purchase data upload Failed!', 12)
      } else {
        message.success(
          "(" +
            result.total +
            "/" +
            result.total +
            ") Purchase Invoices uploaded successfully!",
          5
        );
      }
      this.props.data.refetch();
    } else if (result.file === false) {
      message.error("Uploaded file is not in Tally Excel format!", 5);
    } else {
      message.error("Upload File Type is Not Allowed", 5);
    }
  };

  // onDropPR_WS = async acceptedFiles => {
  //   var el = document.body,
  //     elChild = document.createElement("div");
  //   elChild.className = "root_div";
  //   elChild.innerHTML =
  //     '<div class="custom_loading loader"></div>';
  //   el.insertBefore(elChild, el.firstChild);
  //   document.getElementById("root").classList.add("blur");
  //   const result = await PURCHASE.upload_WS({
  //     acceptedFiles: "text/csv,application/vnd.ms-excel",
  //     file: acceptedFiles[0],
  //     type: "prall_ws",
  //     companyId: this.props.selectedCompany,
  //     isSR: this.props.isSR,
  //     finMonth: this.state.finMonth,
  //     finYear: this.state.finYear
  //   });
  //   document.body.getElementsByClassName("root_div")[0].remove();
  //   document.getElementById("root").classList.remove("blur");
  //   // console.log(result);

  //   if (result.filestatus === true) {
  //     message.success(
  //       "(" +
  //         result.total_records +
  //         "/" +
  //         result.total_records +
  //         ") Purchase Invoices from WonderSoft uploaded successfully!",
  //       5
  //     );
  //     return <Redirect to={ROUTES.PURCHASE_B2B} />;
  //   } else {
  //     message.error("Upload File Type is Not Allowed", 5);
  //   }
  // };

  // onDropReconPR = async acceptedFiles => {
  //   var el = document.body,
  //     elChild = document.createElement("div");
  //   elChild.className = "root_div";
  //   elChild.innerHTML =
  //     '<div class="custom_loading loader"></div>';
  //   el.insertBefore(elChild, el.firstChild);
  //   document.getElementById("root").classList.add("blur");
  //   const result = await RECON_PURCHASE.upload({
  //     file: acceptedFiles[0],
  //     type: "reconprall",
  //     companyId: this.props.selectedCompany,
  //     isSR: this.props.isSR,
  //     finMonth: this.state.finMonth,
  //     finYear: this.state.finYear
  //   });
  //   document.body.getElementsByClassName("root_div")[0].remove(); // Get the <ul> element with id="myList"
  //   document.getElementById("root").classList.remove("blur");
  //   // console.log(result);
  //   if (result.error_status !== "" && result.error_status !== undefined) {
  //     document.getElementById("purchase_error_status").click();
  //     if (result.error_status.trim() === "Mandatory Fields Missing") {
  //       document.getElementById("dynamic_error_msg").innerHTML =
  //         "Please check the file and Upload Correct JSON File";
  //     } else {
  //       document.getElementById("dynamic_error_msg").innerHTML =
  //         "Data being uploaded for a different month than the selected month";
  //     }
  //     // console.log("-----------------ERROR REPORT----------------");
  //     // console.log(result.error_status);
  //     // console.log("---------------------------------------------");
  //   }
  //   if (result.filestatus === true) {
  //     if (result.flag === false) {
  //       message.success(" JSON uploaded successfully!", 5);
  //       this.props.data.refetch();
  //     } else {
  //       message.error(" Something Went Wrong, Try with Correct File", 5);
  //       this.props.data.refetch();
  //     }
  //   } else {
  //     message.error("Not a valid GST System File", 5);
  //     this.props.data.refetch();
  //   }
  // };

  showModal = async (id) => {
    if (id === "1") {
      this.setState({ visibleMatchModal: true });
    } else if (id === "2") {
      this.setState({ visibleModal: true });
    } else if (id === "3") {
      this.setState({ visibleOneModal: true });
    } else if (id === "4") {
      this.setState({ visibleTwoModal: true });
    } else if (id === "5") {
      this.setState({ visibleLikelyModal: true });
    } else if (id === "6") {
      this.setState({ visibleIneligibleModal: true });
    }

    if (id === "11") {
      this.setState({ visibleMatchModal_cdnr: true });
    } else if (id === "22") {
      this.setState({ visibleModal_cdnr: true });
    } else if (id === "33") {
      this.setState({ visibleOneModal_cdnr: true });
    } else if (id === "44") {
      this.setState({ visibleTwoModal_cdnr: true });
    } else if (id === "55") {
      this.setState({ visibleLikelyModal_cdnr: true });
    } else if (id === "66") {
      this.setState({ visibleIneligibleModal_cdnr: true });
    }
  };

  TransGSTOnly = async (
    match_b2b,
    miss_b2b,
    only_pr_b2b,
    recon_pr_b2b,
    likely_b2b,
    match_cdnr,
    miss_cdnr,
    only_pr_cdnr,
    recon_pr_cdnr,
    likely_cdnr
  ) => {
    // console.log(recon_pr_b2b);

    //b2b

    const m_b2b = match_b2b.sort(function(a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const opr_b2b = only_pr_b2b.sort(function(a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_b2b = recon_pr_b2b.sort(function(a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    //cdnr

    const m_cdnr = match_cdnr.sort(function(a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const opr_cdnr = only_pr_cdnr.sort(function(a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const recon_cdnr = recon_pr_cdnr.sort(function(a, b) {
      const firstDateArray = a.cdnr_date.split("-");
      const secondDateArray = b.cdnr_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    // console.log(opr_b2b);
    const excelOutput = await excelUtility.reconPR(
      m_b2b,
      miss_b2b,
      opr_b2b,
      recon_b2b,
      likely_b2b,
      m_cdnr,
      miss_cdnr,
      opr_cdnr,
      recon_cdnr,
      likely_cdnr
    );

    if (excelOutput.status === "EMPTYSHEET") {
      message.warning("No invoices available to download!");
    }
  };

  showItemModal = async (id) => {
    // console.log(id);
    // console.log(this.state);

    if (id) {
      this.setState({
        visibleItemModal: id.id,
      });
      console.log(id);
    }
  };

  handleOk_notifyViaEmail = (record, val) => {
    // console.log("Done");
    // console.log(record);
    // console.log(val);
  };

  handleCancel_notifyViaEmail = () => {
    this.setState({ visible_notifyViaEmail: null });
  };

  mailReportDownload = (data, flag) => {
    var record;

    // if(this.state.visibleModal){

    record = this.state.mailPayload;

    // }
    // console.log(data);

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    var flagData = flag;

    // record = this.state.mailPayload;
    console.log(record);
    // record.push(data);
    var wb_new = XLSX.utils.book_new();
    if (record !== undefined && record !== null && record.length !== 0) {
      wb_new.SheetNames.push("Reconciliation Report");
    }

    if (this.state.visibleModal) {
      var MissMatchPRb2bDataList = [];
      if (record !== undefined && record !== null) {
        // console.log(record);
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              // console.log(Inv);
              // console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": find({ pos_id: Inv.pos_id + "" })(stateArray)
                  .state_name,
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                "Origin of Record":
                  InvoiceItems.item_type === "PRB2B"
                    ? "As per record of " + this.props.company.name
                    : "As per filing of " + record[0].supplier_name,
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prb2b" })(record));

        // console.log(MisMatchTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                "Invoice Number": Inv.invoice_number,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record": "Record that you have filed!",
              })(MisMatchTransOnly);
              map((d) => {
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Place Of Supply"] = find({ pos_id: Inv.pos_id + "" })(
                  stateArray
                ).state_name;
                d["Reverse Charge"] = Inv.reverse_charge;
                // d["Counter-Party Filing Status"] =
                //   Inv.status === "Y" ? "Submitted" : "Not Submitted";
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(record));
        MissMatchPRb2bDataList = MisMatchTransOnly;
      }
      // console.log(MissMatchPRb2bDataList);
      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          MissMatchPRb2bDataList
        );
      }
    }

    if (this.state.visibleModal_cdnr) {
      var MissMatchPRcdnrDataList = [];
      if (record !== undefined && record !== null) {
        const MisMatchTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              console.log(Inv.status);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                    ? "Credit"
                    : Inv.cdnr_type,

                // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.cess),
                From:
                  InvoiceItems.item_type === "PRCDNR"
                    ? "Purchase Register"
                    : "GSTN",
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prcdnr" })(record));

        console.log(MisMatchTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "GSTIN of Supplier": Inv.supplier_gstin,
                From: "GSTN",
              })(MisMatchTransOnly);
              map((d) => {
                d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                // d['Invoice Value'] = parseFloat(Inv.invoice_value);
                // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

                d["Note/Refund Voucher date"] = Inv.cdnr_date;
                d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
                d["Document Type"] =
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                    ? "Credit"
                    : Inv.cdnr_type;

                // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
                d["Pre GST"] = Inv.pre_gst;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprcdnr" })(record));
        MissMatchPRcdnrDataList = MisMatchTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          MissMatchPRcdnrDataList
        );
      }
    }

    if (this.state.visibleOneModal) {
      var onlyPRPRb2bDataList = [];
      if (record !== undefined && record !== null) {
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": find({ pos_id: Inv.pos_id.substring(0, 2) })(
                  stateArray
                ).state_name,
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_b2b_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(record);

        onlyPRPRb2bDataList = StandAlonePRTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          onlyPRPRb2bDataList
        );
      }
    }

    if (this.state.visibleOneModal_cdnr) {
      var onlyPRPRcdnrDataList = [];
      if (record !== undefined && record !== null) {
        const StandAlonePRTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems) => {
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
                "Invoice/Advance Payment Voucher date": Inv.invoice_date,

                "Note/Refund Voucher Number": Inv.cdnr_number,
                "Note/Refund Voucher date": Inv.cdnr_date,
                "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
                "Document Type":
                  Inv.cdnr_type === "D"
                    ? "Debit"
                    : Inv.cdnr_type === "C"
                    ? "Credit"
                    : Inv.cdnr_type,

                // 'Invoice Value': parseFloat(Inv.invoice_value),
                // 'Invoice Type': (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type),
                // 'Place Of Supply': find({pos_id: Inv.pos_id.substring(0,2)})(stateArray).state_name,
                "Pre GST": Inv.pre_gst,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === null
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === null
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === null
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === null
                    ? ""
                    : parseFloat(InvoiceItems.cess),
              };
            })(Inv.pr_cdnr_items);
            return InvoiceItems;
          }),
          flattenDeep
        )(record);

        onlyPRPRcdnrDataList = StandAlonePRTransOnly;
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          onlyPRPRcdnrDataList
        );
      }
    }

    if (this.state.visibleLikelyModal) {
      var likelyPRb2bDataList = [];
      if (record !== undefined && record !== null) {
        const LikelyTransOnly = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              console.log(Inv);
              return {
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Supplier Name": Inv.supplier_name,
                "Invoice Number": Inv.invoice_number,
                "Invoice date": Inv.invoice_date,
                "Invoice Value": parseFloat(Inv.invoice_value),
                "Invoice Type":
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type,
                "Place Of Supply": find({ pos_id: Inv.pos_id + "" })(stateArray)
                  .state_name,
                "Reverse Charge": Inv.reverse_charge,
                Rate: parseFloat(InvoiceItems.gst_rate),
                "Taxable Value": parseFloat(InvoiceItems.taxable_value),
                "Integrated Tax Paid":
                  InvoiceItems.igst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.igst),
                "Central Tax Paid":
                  InvoiceItems.cgst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.cgst),
                "State/UT Tax Paid":
                  InvoiceItems.sgst === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.sgst),
                "Cess Paid":
                  InvoiceItems.cess === " - - - "
                    ? ""
                    : parseFloat(InvoiceItems.cess),

                // 'From': (InvoiceItems.item_type == "PRB2B" ? "Purchase Register" : "GSTN"),

                "Origin of Record":
                  InvoiceItems.item_type == "PRB2B"
                    ? "Record that I Possess!"
                    : "Record that you have filed!",

                tempID: Inv.tempID,
                // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRB2B' ? '***' : '')),
              };
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "prb2b" })(record));

        // console.log(LikelyTransOnly);

        const MisMatchTransOnly_Recon = flow(
          map((Inv) => {
            const InvoiceItems = map((InvoiceItems, item, index) => {
              const prInvoice = filter({
                tempID: Inv.tempID,
                "GSTIN of Supplier": Inv.supplier_gstin,
                "Origin of Record": "Record that you have filed!",
              })(LikelyTransOnly);
              map((d) => {
                d["Invoice Number"] = Inv.invoice_number;
                d["Invoice date"] = Inv.invoice_date;
                d["GSTIN of Supplier"] = Inv.supplier_gstin;
                d["Invoice Value"] = parseFloat(Inv.invoice_value);
                d["Invoice Type"] =
                  Inv.invoice_type === "R" ? "Regular" : Inv.invoice_type;
                d["Place Of Supply"] = find({ pos_id: Inv.pos_id + "" })(
                  stateArray
                ).state_name;
                d["Reverse Charge"] = Inv.reverse_charge;
                // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
              })(prInvoice);
              return {};
            })(Inv.items);
            return InvoiceItems;
          }),
          flattenDeep
        )(filter({ type: "reconprb2b" })(record));

        likelyPRb2bDataList = map((d) => {
          return omit("tempID")(d);
        })(LikelyTransOnly);
      }

      if (record !== undefined && record !== null && record.length !== 0) {
        wb_new.Sheets["Reconciliation Report"] = XLSX.utils.json_to_sheet(
          likelyPRb2bDataList
        );
      }
    }

    var wbout = XLSX.write(wb_new, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    // console.log(wbout);

    fileName =
      "Reconciliation Report - " +
      (data[0] ? data[0].supplier_gstin : "") +
      ".xlsx";

    return flagData === 1
      ? fileSaver.saveAs(
          blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
          "Reconciliation Report - " +
            (data[0] ? data[0].supplier_gstin : "") +
            ".xlsx"
        )
      : this.state.visibleModal
      ? MissMatchPRb2bDataList
      : this.state.visibleOneModal
      ? onlyPRPRb2bDataList
      : this.state.visibleLikelyModal
      ? likelyPRb2bDataList
      : this.state.visibleModal_cdnr
      ? MissMatchPRcdnrDataList
      : this.state.visibleOneModal_cdnr
      ? onlyPRPRcdnrDataList
      : [];
  };

  mailReportDownload_CDNR = (data, flag) => {
    // console.log(data);
    var record = [];

    var flagData = flag;
    // console.log(flagData);
    record.push(data);
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    var wb_new = XLSX.utils.book_new();
    if (record !== undefined && record !== null && record.length !== 0) {
      wb_new.SheetNames.push("Reconcilation Report");
    }

    //Mismatched Purchase Transactions
    var MissMatchPRcdnrDataList = [];
    if (record !== undefined && record !== null) {
      const MisMatchTransOnly = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems, item, index) => {
            // console.log(Inv);
            // console.log(Inv.status);
            return {
              "GSTIN of Supplier": Inv.supplier_gstin,
              "Supplier Name": Inv.supplier_name,
              "Invoice/Advance Payment Voucher Number": Inv.invoice_number,
              "Invoice/Advance Payment Voucher date": Inv.invoice_date,

              "Note/Refund Voucher Number": Inv.cdnr_number,
              "Note/Refund Voucher date": Inv.cdnr_date,
              "Note/Refund Voucher Value": parseFloat(Inv.cdnr_value),
              "Document Type":
                Inv.cdnr_type === "D"
                  ? "Debit"
                  : Inv.cdnr_type === "C"
                  ? "Credit"
                  : Inv.cdnr_type,

              // 'Place Of Supply': find({pos_id: Inv.pos_id+""})(stateArray).state_name,
              "Pre GST": Inv.pre_gst,
              Rate: parseFloat(InvoiceItems.gst_rate),
              "Taxable Value": parseFloat(InvoiceItems.taxable_value),
              "Integrated Tax Paid":
                InvoiceItems.igst === " - - - "
                  ? ""
                  : parseFloat(InvoiceItems.igst),
              "Central Tax Paid":
                InvoiceItems.cgst === " - - - "
                  ? ""
                  : parseFloat(InvoiceItems.cgst),
              "State/UT Tax Paid":
                InvoiceItems.sgst === " - - - "
                  ? ""
                  : parseFloat(InvoiceItems.sgst),
              "Cess Paid":
                InvoiceItems.cess === " - - - "
                  ? ""
                  : parseFloat(InvoiceItems.cess),
              From:
                InvoiceItems.item_type == "PRCDNR"
                  ? "Purchase Register"
                  : "GSTN",
              // 'Counter-Party Filing Status': ((InvoiceItems.item_type === 'PRCDNR' ? '***' : '')),
            };
          })(Inv.items);
          return InvoiceItems;
        }),
        flattenDeep
      )(filter({ type: "prcdnr" })(record));

      // console.log(MisMatchTransOnly);

      const MisMatchTransOnly_Recon = flow(
        map((Inv) => {
          const InvoiceItems = map((InvoiceItems, item, index) => {
            const prInvoice = filter({
              "Note/Refund Voucher Number": Inv.cdnr_number,
              "Invoice Number": Inv.invoice_number,
              "GSTIN of Supplier": Inv.supplier_gstin,
              From: "GSTN",
            })(MisMatchTransOnly);
            map((d) => {
              d["Invoice/Advance Payment Voucher date"] = Inv.invoice_date;
              d["GSTIN of Supplier"] = Inv.supplier_gstin;
              // d['Invoice Value'] = parseFloat(Inv.invoice_value);
              // d['Invoice Type'] = (Inv.invoice_type === 'R' ? 'Regular' : Inv.invoice_type);

              d["Note/Refund Voucher date"] = Inv.cdnr_date;
              d["Note/Refund Voucher Value"] = parseFloat(Inv.cdnr_value);
              d["Document Type"] =
                Inv.cdnr_type === "D"
                  ? "Debit"
                  : Inv.cdnr_type === "C"
                  ? "Credit"
                  : Inv.cdnr_type;

              // d['Place Of Supply'] = find({pos_id: Inv.pos_id+""})(stateArray).state_name;
              d["Pre GST"] = Inv.pre_gst;
              // d['Counter-Party Filing Status'] = (Inv.status === 'Y' ? 'Submitted' : 'Not Submitted')
            })(prInvoice);
            return {};
          })(Inv.items);
          return InvoiceItems;
        }),
        flattenDeep
      )(filter({ type: "reconprcdnr" })(record));
      MissMatchPRcdnrDataList = MisMatchTransOnly;
    }
    // console.log(MissMatchPRcdnrDataList);
    if (record !== undefined && record !== null && record.length !== 0) {
      wb_new.Sheets["Reconcilation Report"] = XLSX.utils.json_to_sheet(
        MissMatchPRcdnrDataList
      );
    }
    var wbout = XLSX.write(wb_new, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    // console.log(wbout);

    fileName =
      "Reconciliation Report - " +
      (record ? record.supplier_gstin : "") +
      ".xlsx";

    return flagData === 1
      ? fileSaver.saveAs(
          blob.createBlob([s2ab(wbout)], { type: "application/octet-stream" }),
          "Reconciliation Report - " +
            (record ? record[0].supplier_gstin : "") +
            ".xlsx"
        )
      : MissMatchPRcdnrDataList;
  };

  addSimilar = (record) => {
    console.log(record[0].supplier_gstin);

    var similarGSTN;
    var invoiceMailList;
    var sortInvoiceNumber = record[0].invoice_number;
    var likelyGSTN_InvNumber;

    if (this.state.visibleModal) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_mismatches_b2b
      );
      console.log(similarGSTN);

      // this.mailReportDownload(similarGSTN, 1)

      invoiceMailList = flow(
        map((d) => {
          return pick(["invoice_number"])(d).invoice_number;
        }),
        uniq
      )(similarGSTN);

      invoiceMailList.sort(function(x, y) {
        return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
      });

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found mismatches in these invoices: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function(x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
    if (this.state.visibleOneModal) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_one_b2b
      );
      console.log(similarGSTN);

      invoiceMailList = flow(
        map((d) => {
          return pick(["invoice_number"])(d).invoice_number;
        }),
        uniq
      )(similarGSTN);

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found that these invoices have not been filed: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function(x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
    if (this.state.visibleLikelyModal) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_likely_b2b
      );
      // likelyGSTN_InvNumber = filter({ tempID : record[0].tempID, type: 'reconprb2b'})(this.state.recon_likely_b2b);
      console.log(similarGSTN);

      invoiceMailList = flow(
        map((d) => {
          if (d.type === "reconprb2b") {
            return pick(["invoice_number"])(d).invoice_number;
          }
        }),
        compact,
        uniq
      )(similarGSTN);

      console.log(invoiceMailList);

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `I have reconciled the data with ThoughtGST and found that there is some issue with the following invoice(s) ${invoiceMailList.toString()}. Kindly respond.`,
          mailPayload: similarGSTN,
          invoiceTags: invoiceMailList,
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }

    if (this.state.visibleModal_cdnr) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_mismatches_cdnr
      );
      console.log(similarGSTN);

      // this.mailReportDownload(similarGSTN, 1)

      invoiceMailList = flow(
        map((d) => {
          return pick(["cdnr_number"])(d).cdnr_number;
        }),
        uniq
      )(similarGSTN);

      invoiceMailList.sort(function(x, y) {
        return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
      });

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found mismatches in these credit/debit notes: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function(x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
    if (this.state.visibleOneModal_cdnr) {
      similarGSTN = filter({ supplier_gstin: record[0].supplier_gstin })(
        this.state.recon_one_cdnr
      );
      console.log(similarGSTN);

      invoiceMailList = flow(
        map((d) => {
          return pick(["cdnr_number"])(d).cdnr_number;
        }),
        uniq
      )(similarGSTN);

      if (invoiceMailList.length > 1) {
        this.setState({
          mailText: `Upon reconciliation of our Purchases, we found that these credit/debit notes have not been filed: ${invoiceMailList.toString()}. Kindly respond.`,
          invoiceTags: invoiceMailList,
          mailPayload: similarGSTN.sort(function(x, y) {
            return x == sortInvoiceNumber ? -1 : y == sortInvoiceNumber ? 1 : 0;
          }),
        });

        this.forceUpdate();
      } else {
        message.warning(
          `No similar records from GSTN ${record[0].supplier_gstin}`
        );
      }
    }
  };

  notifyViaEmailSend = () => {
    var subjectMessage;

    const greetingsMessage = "Greetings from " + this.props.company.name + "!";
    const sendData = this.mailReportDownload(this.state.mailPayload);

    const EmailDetails = this.state.emailTags.toString();
    const EmailMessage = document.getElementById("mailMessage").value;

    const EmailDetails_CC =
      this.props.users_list_me && this.props.users_list_me.email;
    const EmailDetails_RT =
      this.props.users_list_me && this.props.users_list_me.email;

    if (this.state.visibleModal) {
      subjectMessage = "Mismatch in Invoices - " + this.props.company.name;
    }
    if (this.state.visibleOneModal) {
      subjectMessage = "Missing Invoices - " + this.props.company.name;
    }
    if (this.state.visibleLikelyModal) {
      subjectMessage = "Invoice Number Mismatches - " + this.props.company.name;
    }

    // const EmailDetails_CC = document.getElementById("mailIDcc").value;
    // const EmailDetails_RT = document.getElementById("mailID_ReplyTo").value;

    if (this.state.emailTags && this.state.emailTags.length === 0) {
      message.warning("Kindly enter atleast one To Mail ID", 2);
    } else {
      const emailContent = {
        emailDetails: EmailDetails,
        emailMessage: EmailMessage,
        fileName: fileName,
        emailDetails_CC: EmailDetails_CC,
        emailDetails_RT: EmailDetails_RT,
        greetingsMessage: greetingsMessage,
        emailSubject: subjectMessage,
        record: sendData,
      };

      return fetch(`${BASE_URL}/export/sendNotifyMail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailContent),
      }).then((result) => {
        if (this.state.visibleModal) {
          this.setState({
            showModalMail_Mismatch: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleOneModal) {
          this.setState({
            showModalMail_PR: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleModal_cdnr) {
          this.setState({
            showModalMail_Mismatch_CDNR: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleOneModal_cdnr) {
          this.setState({
            showModalMail_PR_CDNR: false,
            invoiceTags: [],
            emailTags: [],
          });
        }
        if (this.state.visibleLikelyModal) {
          this.setState({
            showModalMail_likely: false,
            invoiceTags: [],
            emailTags: [],
          });
        }

        // document.getElementById("mailID").value = ""

        message.destroy();
        message.success("Mail Sent Successfully!");
      });
    }
  };

  handleCloseInvoiceTags = (removedTag) => {
    const tags = this.state.invoiceTags.filter((tag) => tag !== removedTag);
    console.log(tags);
    this.setState({
      invoiceTags: tags,
      mailText: `Upon reconciliation of our Purchases, we found mismatches in these invoices: ${tags.toString()}. Kindly respond.`,
      mailPayload: this.state.mailPayload.filter(
        (tag) => tag.invoice_number !== removedTag
      ),
    });
  };

  notifyViaEmail = async (record) => {
    // if (this.state.visibleLikelyModal) {
    //   var likelyGSTN_InvNumberMail = filter({
    //     tempID: record.tempID,
    //     type: "reconprb2b"
    //   })(this.state.recon_likely_b2b);

    //   console.log(likelyGSTN_InvNumberMail);

    //   this.setState({
    //     showModalMail_likely: true,
    //     mailPayload: filter({ tempID: record.tempID })(
    //       this.state.recon_likely_b2b
    //     )
    //   });

    //   this.setState({
    //     mailText:
    //       "I have reconciled the data with ThoughtGST and found that there is some issue with this Invoice Number : " +
    //       likelyGSTN_InvNumberMail[0].invoice_number +
    //       ". Kindly respond."
    //   });
    // }
    if (this.state.visibleOneModal) {
      this.setState({
        showModalMail_PR: true,
        mailPayload: filter({
          invoice_number: record.invoice_number,
          supplier_gstin: record.supplier_gstin,
        })(this.state.recon_one_b2b),
      });

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found that these invoices have not been filed: " +
          record.invoice_number +
          ". Kindly respond.",
      });
    }

    if (this.state.visibleModal) {
      this.setState({
        showModalMail_Mismatch: true,
        mailPayload: filter({
          invoice_number: record.invoice_number,
          supplier_gstin: record.supplier_gstin,
        })(this.state.recon_mismatches_b2b),
      });

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found mismatches in these invoices: " +
          record.invoice_number +
          ". Kindly respond.",
      });

      // Upon reconciliation of our Purchases, we found mismatches in these invoices: {inv_num}. Kindly respond.
    }
  };

  notifyViaEmail_CDNR = async (record) => {
    if (this.state.visibleOneModal_cdnr) {
      this.setState({
        showModalMail_PR_CDNR: true,
        mailPayload: filter({
          cdnr_number: record.invoice_number,
          supplier_gstin: record.supplier_gstin,
        })(this.state.recon_one_cdnr),
      });

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found that these credit/debit notes have not been filed: " +
          record.cdnr_number +
          ". Kindly respond.",
      });
    }

    if (this.state.visibleModal_cdnr) {
      this.setState({
        showModalMail_Mismatch_CDNR: true,
        mailPayload: filter({
          cdnr_number: record.invoice_number,
          supplier_gstin: record.supplier_gstin,
        })(this.state.recon_mismatches_cdnr),
      });

      this.setState({
        mailText:
          "Upon reconciliation of our Purchases, we found mismatches in these credit/debit notes: " +
          record.cdnr_number +
          ". Kindly respond.",
      });
    }
  };

  onTwoA = () => {
    this.setState({ pull2A: true });
  };

  hideItemModal = () => {
    // console.log(this.state);
    this.setState({ visibleItemModal: false });
  };

  hideModal = () => {
    this.setState({ visibleModal: null });
    document.getElementById("misMatchInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_mismatch_b2b").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideLikelyModal = () => {
    this.setState({ visibleLikelyModal: null });
    document.getElementById("likelyInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_likely_b2b").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideIneligibleModal = () => {
    this.setState({ visibleIneligibleModal: null });
    document.getElementById("ineligibleInvoiceSearch_b2b").click();
    document.getElementById("ineligibleSearch_recon_b2b").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideMatchModal = () => {
    // console.log("Indide Match model cacel");
    this.setState({ visibleMatchModal: false });
    document.getElementById("matchInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_match_b2b").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideOneModal = () => {
    // console.log("Indide one model cacel");
    this.setState({ visibleOneModal: false });
    document.getElementById("prInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_opr_b2b").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideTwoModal = () => {
    // console.log("Indide two model cacel");
    this.setState({ visibleTwoModal: false });
    document.getElementById("gstnInvoiceSearch_b2b").click();
    document.getElementById("gstnSearch_recon_b2b").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideCPModal = () => {
    // console.log("Indide two model cacel");
    this.setState({ visibleCP_Modal: false });
    document.getElementById("gstnInvoiceSearch_b2b_CP").click();
    document.getElementById("gstnSearch_recon_b2b_CP").click();
    this.setState({ selectedRowKeys: [] });
  };

  //cdnr

  hideModal_cdnr = () => {
    this.setState({ visibleModal_cdnr: null });
    document.getElementById("misMatchInvoiceSearch_cdnr").click();
    document.getElementById("misMatchCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_mismatch_cdnr").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideLikelyModal_cdnr = () => {
    this.setState({ visibleLikelyModal_cdnr: null });
    document.getElementById("gstnSearch_likely_cdnr").click();
    document.getElementById("likelyInvoiceSearch_cdnr").click();
    document.getElementById("likelyCDNRSearch_cdnr").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideIneligibleModal_cdnr = () => {
    this.setState({ visibleIneligibleModal_cdnr: null });
    document.getElementById("ineligibleInvoiceSearch_cdnr").click();
    document.getElementById("gstnSearch_ineligible_recon_cdnr").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideMatchModal_cdnr = () => {
    // console.log("Indide Match model cacel");
    this.setState({ visibleMatchModal_cdnr: false });
    document.getElementById("matchInvoiceSearch_cdnr").click();
    document.getElementById("matchCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_match_cdnr").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideOneModal_cdnr = () => {
    // console.log("Indide one model cacel");
    this.setState({ visibleOneModal_cdnr: false });
    document.getElementById("prInvoiceSearch_cdnr").click();
    document.getElementById("prCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_opr_cdnr").click();
    this.setState({ selectedRowKeys: [] });
  };

  hideTwoModal_cdnr = () => {
    // console.log("Indide two model cacel");
    this.setState({ visibleTwoModal_cdnr: false });
    document.getElementById("gstnInvoiceSearch_cdnr").click();
    document.getElementById("gstnCDNRSearch_cdnr").click();
    document.getElementById("gstnSearch_recon_cdnr").click();
    this.setState({ selectedRowKeys: [] });
  };

  changeSelectedFinancialMonth = (name) => {
    this.props.changeSelectedFinancialMonth(name);
  };

  handleCancel = (e) => {
    this.setState({ requestOTP: false });
  };

  handleGet2ACancel = () => {
    this.setState({ pull2A: false });
  };

  handleOTPSubmit = (val) => {
    const form = this.formRef.props.form;
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      form.resetFields();
      message.destroy();
      message.loading("Fetching B2B data from GSTN...", 0);
      this.gsp.otp = values.otp.trim();
      this.setState({ requestOTP: false });
      gspAuth.saveOTP(this.gsp.otp, this.state.companyId);

      const cpData = await this.getCounterPartyData(
        this.state.fromPeriod,
        this.state.toPeriod
      );
    });
  };

  handleGet2ASubmit = () => {
    message.destroy();
    message.loading("Fetching B2B data from GSTN...", 0);
    const form = this.form2ARef.props.form;
    form.validateFields(async (err, fieldsValue) => {
      if (err) {
        return;
      }
      form.resetFields();
      // console.log(fieldsValue);
      const fromDate = fieldsValue["from_month"].format("MMYYYY");
      const toDate = fieldsValue["to_month"].format("MMYYYY");
      this.setState({ pull2A: false });
      const cpData = await this.getCounterPartyData(fromDate, toDate);
    });
  };

  fetchMissingNames = async () => {
    notification.destroy();
    message.loading("Fetching Names...", 0);

    var missingCompanyName = new Array();
    missingCompanyName = missingCompanyName.concat(this.state.recon_one_b2b);
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_b2b);
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_b2b
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_b2b
    );
    missingCompanyName = missingCompanyName.concat(this.state.recon_one_cdnr);
    missingCompanyName = missingCompanyName.concat(this.state.recon_two_cdnr);
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_matches_cdnr
    );
    missingCompanyName = missingCompanyName.concat(
      this.state.recon_mismatches_cdnr
    );
    missingCompanyName = missingCompanyName.concat(this.state.CP_DATA);

    const listMissingCompanyName = filter({ supplier_name: "..." })(
      missingCompanyName
    );

    const gstinList = flow(
      map((d) => {
        return pick(["supplier_gstin"])(d).supplier_gstin;
      }),
      uniq
    )(listMissingCompanyName);

    // console.log(gstinList);

    const isSuccess = await gspAuth.getListOfCompanyNames(
      gstinList,
      this.props.selectedCompany
    );

    if (isSuccess.data.success === true) {
      this.compareSalesFetchNames();
      // message.destroy();
      // message.success('Fetched');
    } else if (isSuccess.data.success === false) {
      message.destroy();
      notification.destroy();
      notification.open({
        message: "Fetch Unsuccessful",
        description: "Unable to process your request, please try again later.",
        duration: 0,
        icon: <Icon type="close-circle" style={{ color: "#db0f20" }} />,
      });
    }
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  save2AFormRef = (formRef) => {
    this.form2ARef = formRef;
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log("selectedRowKeys changed: ", selectedRowKeys);
    // console.log(selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  popupConfirmResetVisibleChange = (e) => {
    if (!e) {
      this.setState({ popupConfirmReset: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset: true });
      } else {
        this.setState({ popupConfirmReset: false });
      }
    }
  };

  popupConfirmResetVisibleChange_CP = (e) => {
    if (!e) {
      this.setState({ popupConfirmReset_CP: false });
      return;
    }
    if (this.props.company) {
      if (this.props.company.role === "admin") {
        this.setState({ popupConfirmReset_CP: true });
      } else {
        this.setState({ popupConfirmReset_CP: false });
      }
    }
  };

  viewCPdata = () => {
    message.destroy();
    message.loading("Fetching Supplier data....", 0);

    var downloaddate =
      this.state.recon_from_month + "/" + this.state.recon_year;

    const getInvoices = fetch(
      `${BASE_URL}/export/getCPInvoices?&companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log("=======DATA=====");
        // console.log(result);

        this.setState({ CP_DATA: result });

        message.destroy();

        this.setState({ visibleCP_Modal: true });
      });
  };

  resetCPData = () => {
    message.destroy();
    message.loading("Resetting Supplier data....", 0);

    var downloaddate =
      this.state.recon_from_month + "/" + this.state.recon_year;

    const getInvoices = fetch(
      `${BASE_URL}/export/deleteCPInvoices?&companyId=${this.props.selectedCompany}&downloaddate=${downloaddate}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log("=======DATA=====");
        console.log(result);

        message.destroy();
        message.success("Supplier Data Reset Successful");

        this.setState({ visibleCP_Modal: false });
      });
  };

  cpExcelDownload = async () => {
    const sortCP = this.state.CP_DATA.sort(function(a, b) {
      const firstDateArray = a.invoice_date.split("-");
      const secondDateArray = b.invoice_date.split("-");
      return (
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0]) -
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0])
      );
    });

    const excelOutputCP = await excelUtility.CP_Excel(sortCP);

    if (excelOutputCP.status === "EMPTYSHEET") {
      message.warning("No invoices available to download!");
    }
  };

  validateMonths = (val) => {
    if (val === 1) {
      return 10;
    }
    if (val === 2) {
      return 11;
    }
    if (val === 3) {
      return 12;
    }
    if (val === 4) {
      return 1;
    }
    if (val === 5) {
      return 2;
    }
    if (val === 6) {
      return 3;
    }
    if (val === 7) {
      return 4;
    }
    if (val === 8) {
      return 5;
    }
    if (val === 9) {
      return 6;
    }
    if (val === 10) {
      return 7;
    }
    if (val === 11) {
      return 8;
    }
    if (val === 12) {
      return 9;
    }
  };

  handleYearChangeRecon = (val) => {
    this.setState({ recon_year: val });
  };

  handleFromMonthChangeRecon = (val) => {
    var properMonth1 = this.validateMonths(parseInt(val));
    var properMonth2 = this.validateMonths(parseInt(this.state.recon_to_month));

    if (properMonth1 > properMonth2) {
      this.setState({ recon_from_month: this.props.finMonth.toString() });
      return message.error("From period cannot be greater than To period!");
    } else {
      this.setState({ recon_from_month: val });
    }
  };

  handleToMonthChangeRecon = (val) => {
    var properMonth1 = this.validateMonths(parseInt(val));
    var properMonth2 = this.validateMonths(
      parseInt(this.state.recon_from_month)
    );

    if (properMonth1 < properMonth2) {
      this.setState({ recon_to_month: this.props.finMonth.toString() });
      return message.error("To period cannot be lesser than From period!");
    } else {
      this.setState({ recon_to_month: val });
    }
  };

  render() {
    console.log(this.state);
    console.log(this.props);
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      selections: [
        {
          key: "all-data",
          text: "Select All",
          onSelect: () => {
            if (this.state.visibleMatchModal === true) {
              this.setState({
                selectedRowKeys: this.state.recon_matches_b2b.map((a) => a.id),
              });
            }
            if (this.state.visibleOneModal === true) {
              this.setState({
                selectedRowKeys: this.state.recon_one_b2b.map((a) => a.id),
              });
            }
            if (this.state.visibleTwoModal === true) {
              this.setState({
                selectedRowKeys: this.state.recon_two_b2b.map((a) => a.id),
              });
            }
            if (this.state.visibleIneligibleModal === true) {
              this.setState({
                selectedRowKeys: this.state.recon_ineligible_b2b.map(
                  (a) => a.id
                ),
              });
            }
          },
        },
      ],
    };

    const rowSelection_mismatch = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      selections: [
        {
          key: "odd",
          text: "Select All PR",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = this.state.recon_mismatches.filter(
              (key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              }
            );
            this.setState({
              selectedRowKeys: newSelectedRowKeys.map((a) => a.id),
            });
          },
        },
        {
          key: "even",
          text: "Select All GSTN",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = this.state.recon_mismatches.filter(
              (key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              }
            );
            this.setState({
              selectedRowKeys: newSelectedRowKeys.map((a) => a.id),
            });
          },
        },
      ],
    };

    function sortDate(a, b) {
      const firstDateArray = a.split("-");
      const secondDateArray = b.split("-");
      // console.log(new Date(firstDateArray[2],firstDateArray[1],firstDateArray[0]))
      // console.log(new Date(secondDateArray[2],secondDateArray[1],secondDateArray[0]));
      // console.log();
      return (
        new Date(firstDateArray[2], firstDateArray[1], firstDateArray[0]) -
        new Date(secondDateArray[2], secondDateArray[1], secondDateArray[0])
      );
    }

    const rowSelection_likely = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onSelectChange.bind(this),
      hideDefaultSelections: true,
      selections: [
        {
          key: "odd",
          text: "Select All PR",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = this.state.recon_likely.filter(
              (key, index) => {
                if (index % 2 !== 0) {
                  return false;
                }
                return true;
              }
            );
            this.setState({
              selectedRowKeys: newSelectedRowKeys.map((a) => a.id),
            });
          },
        },
        {
          key: "even",
          text: "Select All GSTN",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = this.state.recon_likely.filter(
              (key, index) => {
                if (index % 2 !== 0) {
                  return true;
                }
                return false;
              }
            );
            this.setState({
              selectedRowKeys: newSelectedRowKeys.map((a) => a.id),
            });
          },
        },
      ],
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <Content>
        <ReconcileSummary
          pr_ret_period_masters={get("viewer.pr_ret_period_masters.edges")(
            this.props.data
          )}
          role={get("company.role", this.props)}
          finMonth={this.state.finMonth}
          finYear={this.state.finYear}
          showConfirm={this.showConfirm}
          onDropPR={this.onDropPR}
          onDropPR_WS={this.onDropPR_WS}
          onDropReconPR={this.onDropReconPR}
          onTwoA={this.onTwoA}
          visibleTwoA={this.state.visible2a}
          handleCancelTwoA={this.handleCancelTwoA}
          loading={false}
          recon_loading={this.state.recon_loading}
          sales_loading={this.state.sales_loading}
          gst_loading={this.state.gst_loading}
          pending_load={this.state.pending_load}
          showModal={this.showModal}
          downExcel={this.TransGSTOnly}
          downExcel_cdnr={this.cdnrExcelDownload}
          fetchMissingNames={this.fetchMissingNames}
          hideModal={this.hideModal}
          hideLikelyModal={this.hideLikelyModal}
          hideMatchModal={this.hideMatchModal}
          hideOneModal={this.hideOneModal}
          hideTwoModal={this.hideTwoModal}
          hideModal_cdnr={this.hideModal_cdnr}
          hideLikelyModal_cdnr={this.hideLikelyModal_cdnr}
          hideMatchModal_cdnr={this.hideMatchModal_cdnr}
          hideOneModal_cdnr={this.hideOneModal_cdnr}
          hideTwoModal_cdnr={this.hideTwoModal_cdnr}
          showItemModal={this.showItemModal}
          notifyViaEmail={this.notifyViaEmail}
          notifyViaEmail_CDNR={this.notifyViaEmail_CDNR}
          hideItemModal={this.hideItemModal}
          compareSales={this.compareSales}
          // takePurchase={this.takePurchase}
          // takeGST={this.takeGST}
          // keepPending={this.keepPending}
          // GkeepPending={this.GkeepPending}
          // PkeepPending={this.PkeepPending}
          // downloadSales={this.downloadSales}
          resetPurchase={this.resetPurchase}
          output_b2b={this.state.output_b2b}
          recon_mismatches_b2b={this.state.recon_mismatches_b2b}
          recon_matches_b2b={this.state.recon_matches_b2b}
          recon_one_b2b={this.state.recon_one_b2b}
          recon_two_b2b={this.state.recon_two_b2b}
          recon_likely_b2b={this.state.recon_likely_b2b}
          output_cdnr={this.state.output_cdnr}
          recon_mismatches_cdnr={this.state.recon_mismatches_cdnr}
          recon_matches_cdnr={this.state.recon_matches_cdnr}
          recon_one_cdnr={this.state.recon_one_cdnr}
          recon_two_cdnr={this.state.recon_two_cdnr}
          recon_likely_cdnr={this.state.recon_likely_cdnr}
          visibleModal={this.state.visibleModal}
          visibleLikelyModal={this.state.visibleLikelyModal}
          visibleItemModal={this.state.visibleItemModal}
          visibleMatchModal={this.state.visibleMatchModal}
          visibleMatchItemModal={this.state.visibleMatchItemModal}
          visibleOneModal={this.state.visibleOneModal}
          visibleOneItemModal={this.state.visibleOneItemModal}
          visibleTwoModal={this.state.visibleTwoModal}
          visibleModal_cdnr={this.state.visibleModal_cdnr}
          visibleLikelyModal_cdnr={this.state.visibleLikelyModal_cdnr}
          visibleMatchModal_cdnr={this.state.visibleMatchModal_cdnr}
          visibleOneModal_cdnr={this.state.visibleOneModal_cdnr}
          visibleTwoModal_cdnr={this.state.visibleTwoModal_cdnr}
          visibleTwoItemModal={this.state.visibleTwoItemModal}
          isSR={this.props.isSR}
          downloadLoading={this.state.downloadLoading}
          resetLoading={this.state.resetLoading}
          requestOTP={this.state.requestOTP}
          handleOTPSubmit={this.handleOTPSubmit}
          handleCancel={this.handleCancel}
          saveFormRef={this.saveFormRef}
          save2AFormRef={this.save2AFormRef}
          pull2A={this.state.pull2A}
          handleGet2ASubmit={this.handleGet2ASubmit}
          handleGet2ACancel={this.handleGet2ACancel}
          formatNumber={formatNumber}
          handleSearch={this.handleSearch}
          handleReset={this.handleReset}
          searchText={this.state.searchText}
          // itcCounterIGST_Val={this.state.itcCounter_IGST}
          // itcCounterCGST_Val={this.state.itcCounter_CGST}
          // itcCounterSGST_Val={this.state.itcCounter_SGST}
          // itcCounterCESS_Val={this.state.itcCounter_CESS}
          // availGSTNCredit={this.handleGSTN_CREDIT}
          // removeGSTNCredit={this.removeGSTNCredit}
          // availMisMatchCredit={this.handleMismatchCreditSelect}
          // removeMisMatchCredit={this.removeMisMatchCredit}
          // twoA_FinYear_Dependent_Value={this.state.twoA_FinYear_Dependent_Value}
          // twoA_PrevFinYear_Dependent_Value={
          //   this.state.twoA_PrevFinYear_Dependent_Value
          // }
          // threeB_FinYear_Dependent_Value={
          //   this.state.threeB_FinYear_Dependent_Value
          // }
          // threeB_Total_Value={this.state.threeB_Total_Value}
          // prevYearTitle={this.state.prevYearTitle}
          // currentYearTitle={this.state.currentYearTitle}
          // removeMatchCredit={this.removeMatchCredit}
          // availMatchCredit={this.availMatchCredit}
          // removePRCredit={this.removePRCredit}
          // availPRCredit={this.availPRCredit}
          // prevFinYearThreeB={this.state.prevFinYearThreeB}
          // currentFinYearThreeB={this.state.currentFinYearThreeB}
          // balanceValues_PrevYear={this.state.balanceValues_PrevYear}
          // balanceValues_CurrentYear={this.state.balanceValues_CurrentYear}
          rowSelection={rowSelection}
          rowSelection_mismatch={rowSelection_mismatch}
          rowSelection_likely={rowSelection_likely}
          hasSelected={hasSelected}
          selectedRowKeys={this.state.selectedRowKeys}
          // threeB_PrevFinYear_Dependent_Value={
          //   this.state.threeB_PrevFinYear_Dependent_Value
          // }
          // noCreditTakenPrevYear={this.state.noCreditTakenPrevYear}
          // RIC_Load={this.state.RIC_Load}
          sortDate={sortDate}
          popupConfirmResetVisibleChange={this.popupConfirmResetVisibleChange}
          popupConfirmReset={this.state.popupConfirmReset}
          reconcilePrompt={this.reconcilePrompt}
          showModalMail_Mismatch={this.state.showModalMail_Mismatch}
          showModalMail_Mismatch_CDNR={this.state.showModalMail_Mismatch_CDNR}
          showModalMail_likely={this.state.showModalMail_likely}
          showModalMail_PR={this.state.showModalMail_PR}
          showModalMail_PR_CDNR={this.state.showModalMail_PR_CDNR}
          handleCancelEmail={this.handleCancelEmail}
          mailTextBox={this.state.mailText}
          mailReportDownload={this.mailReportDownload}
          addSimilar={this.addSimilar}
          mailPayload={this.state.mailPayload}
          handleCloseInvoiceTags={this.handleCloseInvoiceTags}
          invoiceTags={this.state.invoiceTags}
          notifyViaEmailSend={this.notifyViaEmailSend}
          userMailID={this.props.users_list_me}
          emailTags={this.state.emailTags}
          inputVisibleEmail={this.state.inputVisibleEmail}
          inputValueEmail={this.state.inputValueEmail}
          handleCloseEmail={this.handleCloseEmail}
          showInputEmail={this.showInputEmail}
          handleInputChangeEmail={this.handleInputChangeEmail}
          handleInputConfirmEmail={this.handleInputConfirmEmail}
          saveInputRefEmail={this.saveInputRefEmail}
          showModalPrompt={this.state.showModalPrompt}
          handleOkPrompt={this.handleOkPrompt}
          handleCancelPrompt={this.handleCancelPrompt}
          indeterminate_b2b={this.state.indeterminate_b2b}
          onCheckAllChange_b2b={this.onCheckAllChange_b2b}
          checkAll_b2b={this.state.checkAll_b2b}
          checkedList_b2b={this.state.checkedList_b2b}
          onChangeCB_b2b={this.onChangeCB_b2b}
          indeterminate_cdnr={this.state.indeterminate_cdnr}
          onCheckAllChange_cdnr={this.onCheckAllChange_cdnr}
          checkAll_cdnr={this.state.checkAll_cdnr}
          checkedList_cdnr={this.state.checkedList_cdnr}
          onChangeCB_cdnr={this.onChangeCB_cdnr}
          onRoundOffChange={this.onRoundOffChange}
          disableRoundOffInput={this.state.disableRoundOffInput}
          showCDNR={this.state.showCDNR}
          showB2B={this.state.showB2B}
          onDropTallyPR={this.onDropTallyPR}
          mailContentChange={this.mailContentChange}
          viewCPdata={this.viewCPdata}
          visibleCP_Modal={this.state.visibleCP_Modal}
          hideCPModal={this.hideCPModal}
          CP_DATA={this.state.CP_DATA}
          resetCPData={this.resetCPData}
          popupConfirmResetVisibleChange_CP={
            this.popupConfirmResetVisibleChange_CP
          }
          popupConfirmReset_CP={this.state.popupConfirmReset_CP}
          cpExcelDownload={this.cpExcelDownload}
          recon_ineligible_b2b={this.state.recon_ineligible_b2b}
          recon_ineligible_cdnr={this.state.recon_ineligible_cdnr}
          visibleIneligibleModal={this.state.visibleIneligibleModal}
          visibleIneligibleModal_cdnr={this.state.visibleIneligibleModal_cdnr}
          hideIneligibleModal={this.hideIneligibleModal}
          hideIneligibleModal_cdnr={this.hideIneligibleModal_cdnr}
          markAsIneligible={this.markAsIneligible}
          markAsEligible={this.markAsEligible}
          handleYearChangeRecon={this.handleYearChangeRecon}
          handleFromMonthChangeRecon={this.handleFromMonthChangeRecon}
          handleToMonthChangeRecon={this.handleToMonthChangeRecon}
          recon_year={this.state.recon_year}
          recon_from_month={this.state.recon_from_month}
          recon_to_month={this.state.recon_to_month}
          handleMismatchIneligible={this.handleMismatchIneligible}
          handleClickStart={this.handleClickStart}
        />
      </Content>
    );
  }
}

const queryConfig = {
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth.toString(),
      finyear: props.finYear,
    },
  }),
};

const usersQueryConfig = {
  name: "company_users",
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
    },
  }),
  props: ({ company_users }) => {
    return {
      company_users: company_users,
      users_list_me: get("viewer.me", company_users),
      users_list: get("viewer.companyUsers.edges", company_users),
    };
  },
};

const companyQueryConfig = {
  options: (props) => ({
    variables: {
      companyId: props.selectedCompany,
      finmonth: props.finMonth,
      finyear: props.finYear,
    },
  }),
  props: ({ data }) => {
    return {
      company: get("viewer.company", data),
      isEdit: true,
      gspDetails: get("viewer.gspInfo", data),
    };
  },
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    selectedCompany: state.local.selectedCompany,
    finMonth: state.local.finMonth,
    finYear: state.local.finYear,
    output_b2b: state.output_b2b,
    recon_mismatches_b2b: state.recon_mismatches_b2b,
    recon_matches_b2b: state.recon_matches_b2b,
    recon_one_b2b: state.recon_one_b2b,
    recon_two_b2b: state.recon_two_b2b,
    recon_likely_b2b: state.recon_likely_b2b,
    output_cdnr: state.output_cdnr,
    recon_mismatches_cdnr: state.recon_mismatches_cdnr,
    recon_matches_cdnr: state.recon_matches_cdnr,
    recon_one_cdnr: state.recon_one_cdnr,
    recon_two_cdnr: state.recon_two_cdnr,
    recon_likely_cdnr: state.recon_likely_cdnr,
    gspDetails: state.gsp,
  };
};

export default compose(
  connect(mapStateToProps, {
    setGspToken: gspActions.setGspToken,
    setGspOtp: gspActions.setGspOtp,
    setGspDetails: gspActions.setGspDetails,
  }),
  graphql(gql(CompanyUsersQuery), usersQueryConfig),
  graphql(gql(PR_RET_Period_Query), queryConfig),
  graphql(gql(CompanyByIdQuery), companyQueryConfig)
)(ReconcileContainer);

// <Card title="Invoice Upload Tracker">
//     <Card.Grid style={gridStyle}>April<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>May<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>June<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>July<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>August<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>September<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>October<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>November<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>December<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>January<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>Febraury<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//     <Card.Grid style={gridStyle}>March<br /><br /><Row><Col span={12}>B2B</Col><Col span={12}> 100 </Col><br /><Col span={12}>CDNR</Col> <Col span={12}>10</Col></Row></Card.Grid>
//   </Card>
