import React from "react";
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
import { Root, ContentWrapper as Content } from "../../shared/styles";
const { ColumnGroup, Column } = Table;

class ReturnOne3B extends React.Component {

    render() {
        return (
            <Root >
                <Content>
                    <Table dataSource={this.props.inwardsupplies} bordered title={() => '3B. Details Of Inward Supplies Attracting Reverse Charge'} footer={() =>
                        <div style={{ overflow: "auto" }}>
                            <Button size="default" icon="save" style={{ float: "right", width: "150px", fontSize: '14px', padding: '0 10px', backgroundColor: 'rgba(4, 136, 15, 0.88)', color: 'white' }}>
                                Save</Button>
                            <Button size="default" icon="caret-right" id="next" style={{ float: "right", width: "150px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}
                                onClick={this.props.navigateNext}
                            >
                                Next</Button>
                            <Button size="default" icon="caret-left" id="next" style={{ float: "right", width: "150px", fontSize: '14px', padding: '0 10px', backgroundColor: '#2382c7', color: 'white' }}
                                onClick={this.props.navigatePrev}
                            >
                                Previous</Button>
                        </div>
                    }>
                        <Column title="Sr No." dataIndex="sno"></Column>
                        <Column title="Type of Supply" dataIndex="type"></Column>
                        <Column title="Value (₹)" dataIndex="txval"></Column>
                        <ColumnGroup title="Amount of Tax (₹)">
                            <Column title='Integrated tax' dataIndex='igst' />
                            <Column title='Central tax' dataIndex='cgst' />
                            <Column title='State/UT tax' dataIndex='sgst' />
                            <Column title='Cess' dataIndex='cess' />
                        </ColumnGroup>
                    </Table>
                </Content>
            </Root>
        )
    }
}
export default ReturnOne3B;