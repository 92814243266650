import { Col, DatePicker, Form, Row, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import Category_B2B from './Category_B2B';
import Category_CDNR from './Category_CDNR';
import Category_ISD from './Category_ISD';
const { RangePicker } = DatePicker;
const { Option } = Select;

const VolumeBySupplier = props => {

    const categories = ["B2B", "CDNR", "ISD"];
    const [selectedCategory, setSelectedCategory] = useState("B2B");
    const [selectedDates, setSelectedDates] = useState([moment().subtract(1, 'month').add(1, 'day'), moment()]);
    const [selectedRankRange, setSelectedRankRange] = useState(undefined);
    const [purchaseVolume, setPurchaseVolume] = useState([]);
    const PurchaseCategoryComponents = {
        "B2B": Category_B2B,
        "CDNR": Category_CDNR,
        "ISD": Category_ISD
    };
    const PurchaseCategoryComponent = PurchaseCategoryComponents[selectedCategory];

    function getCompanyRankRanges() {
        var returnOptions = [];
        const pageSize = 10;
        if (purchaseVolume?.length > 0) {
            const noOfDivisions = (purchaseVolume?.length % pageSize == 0) ? purchaseVolume?.length / pageSize : (purchaseVolume?.length / pageSize) + 1;
            returnOptions = [];
            for (let i = 0; i < noOfDivisions - 1; i++) {
                returnOptions.push(<Option value={`${i * pageSize + 1} - ${(i + 1) * pageSize}`}>{`Rank ${i * pageSize + 1} - ${(i + 1) * pageSize}`}</Option>)
            }
        }
        return returnOptions;
    }

    function disabledDate(current) {
        return current && (current < moment("01-Apr-2017", "DD-MMM-YYYY") || current > moment().endOf('day'));
    }

    return (
        <div>
            <Form>
                <Row style={{ margin: 12 }}>
                    <Col span={8}>
                        <Form.Item label={<b>Category</b>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Select
                                style={{ width: '100%', paddingRight: 12 }}
                                value={selectedCategory}
                                onChange={value => {
                                    setSelectedCategory(value);
                                }}
                            >
                                {categories && categories.map(category => <Option key={category}>{category}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={<b>Date Range</b>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <RangePicker
                                style={{ width: '100%', paddingRight: 12 }}
                                defaultValue={selectedDates}
                                disabledDate={disabledDate}
                                format="DD-MMM-YYYY"
                                onChange={dates => setSelectedDates(dates)}
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label={<b>Supplier Ranking (Based on Invoice Value)</b>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Select
                                placeholder="Please select Supplier Rank Range"
                                style={{ width: '100%' }}
                                value={selectedRankRange}
                                disabled={purchaseVolume?.length == 0}
                                onChange={value => {
                                    setSelectedRankRange(value);
                                }}>
                                {
                                    getCompanyRankRanges()
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <PurchaseCategoryComponent
                selectedDates={selectedDates}
                selectedRankRange={selectedRankRange}
                setSelectedRankRange={setSelectedRankRange}
                setPurchaseVolume={setPurchaseVolume}
            />
        </div>
    )
}

export default VolumeBySupplier;