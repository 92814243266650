import fetch from 'isomorphic-fetch'
import { LOCAL_STORAGE, BASE_URL } from '../shared/constants'
 
const Auth = {
  isLoggedIn: () => {
    const isLoggedIn = localStorage.getItem(LOCAL_STORAGE.IS_LOGGED_IN)
    return typeof isLoggedIn !== 'undefined' && isLoggedIn === 'yes'
  },
  login: async creds => {
    const result = await fetch(BASE_URL + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(creds),
    })
    if (result.status !== 200) {
      return {
        success: false,
        error: await result.json(),
      }
    }
    const {accessToken} = await result.json()
    localStorage.setItem(LOCAL_STORAGE.IS_LOGGED_IN, 'yes')
    localStorage.setItem(LOCAL_STORAGE.TOKEN, accessToken)

    return {
      success: true,
    }
  },
  register: async creds => {
    const result = await fetch(BASE_URL + '/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(creds),
    })
    if (result.status !== 200) {
      return {
        success: false,
        error: await result.json(),
      }
    }
    return {
      success: true,
    }
  },
  forgotPassword: async (creds) => {
    const result = await fetch(BASE_URL + '/auth/forgotPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(creds),
    })
    if (result.status !== 200) {
      return {
        success: false,
        error: await result.json(),
      }
    }
    return {
      success: true,
    }
  },
  sendOTP: async email => {
    const result = await fetch(BASE_URL + '/auth/sendOTP', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email)
    });
    const {userid} = await result.json();
    return userid;

  },
  sendResetPasswordOTP: async email => {
    const result = await fetch(BASE_URL + '/auth/sendResetPasswordOTP', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email)
    });
    const {userid} = await result.json();
    return userid;

  },
  validateOTP: async values => {
    const objValidatedRecords = await fetch(BASE_URL + '/auth/validateOTP', {
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
    return await objValidatedRecords.json();
  },
  logout: async () => {
    // localStorage.clear()
    localStorage.removeItem(LOCAL_STORAGE.IS_LOGGED_IN)
    return {}
  },
}

export default Auth
