import glamorous from 'glamorous'
import B from 'antd/lib/button'
import Row from 'antd/lib/row'

const CenterAlignWrapper = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const Root = glamorous.div({
  background:'white',
  
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
})

const ContentWrapper = glamorous.div({
  background: '#fff',
  padding: 15,
  margin: '0px 0',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

const MarginedRow = glamorous(Row)({
  margin: '15px 0',
})

const button = glamorous(B)({
  margin: '0 10px 0 0',
  background:'darkred',
  border:'1px solid darkred',
  color:'white'

})

const Button = glamorous(B)({
  margin: '0 10px 0 0',
})
export { CenterAlignWrapper, Root, ContentWrapper, MarginedRow, Button, button }
