import React from 'react'
import { Row, Col, Card, Steps, Popconfirm, Button } from 'antd'
import { ContentWrapper } from '../../shared/styles'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../shared/constants'
const Step = Steps.Step;

const add_button = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', 
                      touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', 
                      whiteSpace: 'nowrap', lineHeight: '1.5', padding: '0 15px', fontSize: '13.5px', borderRadius: '5px', height: '54px', 
                      mozUserSelect: 'none', position: 'relative', color: '#fafafa', backgroundColor: '#22618e', 
                      borderColor: '#d9d9d9', paddingTop: '5px', whiteSpace: 'initial'};

const add_button_h = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', 
                      touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', 
                      whiteSpace: 'nowrap', lineHeight: '1.5', padding: '0 15px', fontSize: '13.5px', borderRadius: '5px', height: '74px', 
                      mozUserSelect: 'none', position: 'relative', color: '#fafafa', backgroundColor: '#22618e', 
                      borderColor: '#d9d9d9', paddingTop: '5px', whiteSpace: 'initial'};                      

const add_button_one = { outline: '0', display: 'inline-block', marginBottom: '0', fontWeight: '500', textAlign: 'center', 
                      touchAction: 'manipulation', cursor: 'pointer', backgroundImage: 'none', border: '1px solid transparent', 
                      whiteSpace: 'nowrap', lineHeight: '1.5', padding: '0 15px', fontSize: '13.5px', borderRadius: '5px', height: '34px', 
                      mozUserSelect: 'none', position: 'relative', color: '#fafafa', backgroundColor: '#22618e', 
                      borderColor: '#d9d9d9', paddingTop: '5px'};                      

class GSTR9Dashboard extends React.Component {

    render() {
      return(
        <ContentWrapper>
        <Steps current={[1, 2, 3]}>
          <Step title="Enter Data" description="Visit Each link in the below card section and complete entering all the details!" />

          <Step title={
            <Popconfirm placement="bottomRight" title="Clicking this button will SAVE your GSTR3B info in GSTN!" okText="Yes" cancelText="No">
              <Button style={{ backgroundColor: '#04880fe0', fontSize: '13px', color: 'white'}} icon="cloud-upload">Save GSTR9</Button>
            </Popconfirm>
            } description="Clicking this button will save your GSTR9 info to GST network." />

          <Step title={
            <Popconfirm placement="bottomRight" title="Clicking this button will SAVE your GSTR3B info in GSTN!" okText="Yes" cancelText="No">
              <Button style={{ backgroundColor: '#a01208de', fontSize: '13px', color: 'white'}} icon="cloud-upload">Submit GSTR9</Button>
            </Popconfirm>
            } description="Clicking this button will submit your GSTR9 info to GST network." />
        </Steps>
        
        <div style={{ background: '#a6a6af', padding: '30px', marginTop: '70px' }}>
          <Row gutter={16}>
            <Col span={8} style={{ textAlign: 'center' }}>
              <Card title="Pt. II" bordered={false}>
                <a><Link style={add_button} to={ROUTES.GST_FILINGS_GSTR9_P2}>Details of Outward and inward supplies declared during the financial year</Link></a>
              </Card>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <Card title="Pt. III" bordered={false}>
                <a><Link style={add_button} to={ROUTES.GST_FILINGS_GSTR9_P3}>Details of ITC as declared in returns filed during the financial year</Link></a>
              </Card>
            </Col>
            <Col span={8} style={{ textAlign: 'center' }}>
              <Card title="Pt. IV" bordered={false}>
                <a><Link style={add_button} to={ROUTES.GST_FILINGS_GSTR9_P4}>Details of tax paid as declared in returns filed during the financial year</Link></a>
              </Card>
            </Col>
          </Row>
          </div>
        <div style={{ background: '#a6a6af', padding: '30px', marginTop: '30px' }}>
          <Row gutter={16}>
            <Col span={12} style={{ textAlign: 'center' }}>
              <Card title="Pt. V" bordered={false} >
                <a><Link style={add_button_h} to={ROUTES.GST_FILINGS_GSTR9_P5}>Particulars of the transactions for the previous FY declared in returns of April to September of current FY
                or upto date of filing of annual return of previous FY whichever is earlier</Link></a>
               </Card>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <Card title="Pt. VI" bordered={false} >
              <div style={{ height: '74px' }}>
                <a><Link style={add_button_one} to={ROUTES.GST_FILINGS_GSTR9_P6}>Other Information</Link></a>
              </div>
              </Card>
            </Col>
          </Row>
        </div>
        </ContentWrapper>

        )
    }
  }

export default GSTR9Dashboard

// <h3 style={{ textAlign: "center", marginTop: '20px' }}> Visit Each link in the below list section and complete entering all the details! </h3>