import React from 'react'
import glamorous from 'glamorous'
import { Link } from 'react-router-dom'
import Icon from 'antd/lib/icon'

const SLink = glamorous(Link)({
  color: 'inherit',
  fontSize: 14,
  ':focus': {
    textDecoration: 'none',
  },
})

type props = {
  iconType: string,
  linkTo: string,
  linkText: string,
}
export default ({ iconType, linkTo, linkText }: props) =>
  <SLink to={linkTo} fontSize="14px">
    <Icon type={iconType} />
    <span>
    {linkText}
    </span>
  </SLink>
